import React from "react";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {DesktopExplorePage} from "./DesktopExplorePage";
import {MobileExplorePage} from "./MobileExplorePage";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";

export const ExplorePage = () => {
    const {isDesktop} = useIsDesktop();

    usePageTitle(PageTitles.EXPLORE);

    return isDesktop ? <DesktopExplorePage /> : <MobileExplorePage />;
};
