import {Image} from "react-native";
import type {FormikHelpers} from "formik";
import {array} from "yup";
import {useApolloClient} from "@apollo/client";
import {ErrorMessage, Form, Heading, Stack, SubmitButton, Text, View} from "@unibuddy/patron";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";
import {useIsStudentAppExperience} from "ubcommunity-shared/src/Hooks/useIsStudentAppExperience";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {EditCourse} from "ubcommunity-shared/src/Settings/AccountInformation/EditCourse";
import {EditNationality} from "ubcommunity-shared/src/Settings/AccountInformation/EditNationality";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {
    CountryField,
    DegreeField,
    useGetAccountInformationQuery,
    useSetUserProfileMutation,
    useUpdateApplicantDetailsMutation,
} from "ubcommunity-shared/src/types";

import mobileImage from "./assets/empty-state-carousel-mobile.png";
import desktopImage from "./assets/empty-state-carousel.png";
import userTray from "./assets/empty-state-user-tray.png";
import {ProfileFormValues} from "ubcommunity-shared/src/Settings/AccountInformation/AccountInformation";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {TrackEvents} from "ubcommunity-shared/src/constants";

type OnboardingFormValues = {
    selectCourse?: Array<DegreeField> | null;
    selectCountry: Array<CountryField>;
    serverError: string;
};

export enum OnboardingStrings {
    TITLE = "Find your people",
    SUBTITLE = "by telling us about you",
    BUTTON = "Reveal Matches",
}

type FormValues = Pick<ProfileFormValues, "selectCourse" | "selectCountry">;

type OnboardingProps = {
    onRevealMatches?: () => void;
};

const MOBILE_HEIGHT = 362;
const DESKTOP_HEIGHT = 417;
export const CONTAINER_HEIGHTS = {
    MOBILE_HEIGHT,
    DESKTOP_HEIGHT,
};

export const Onboarding = ({onRevealMatches = () => {}}: OnboardingProps) => {
    const {loading: experienceLoading} = useIsStudentAppExperience();
    const {isDesktop} = useIsDesktop();
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();
    const {cache} = useApolloClient();
    const {user, setAuth, authState} = useAuth();
    const {data, loading: queryLoading} = useGetAccountInformationQuery();
    const [setUserProfile, {error: setUserProfileMutationError}] = useSetUserProfileMutation();
    const [setUpdateApplicant, {error: updateApplicantCountryMutationError}] =
        useUpdateApplicantDetailsMutation();

    const loading = experienceLoading || queryLoading;

    let containerHeight = CONTAINER_HEIGHTS.MOBILE_HEIGHT;
    let image = mobileImage;
    if (isDesktop) {
        containerHeight = CONTAINER_HEIGHTS.DESKTOP_HEIGHT;
        image = desktopImage;
    }

    if (loading) {
        return null;
    }

    const initialValues: OnboardingFormValues = {
        selectCourse: [],
        selectCountry: [],
        serverError: "",
    };

    const country = user?.country;
    if (country) {
        initialValues.selectCountry = [country];
    }

    const course = data?.getUserProfile?.areaOfStudy;
    if (course) {
        initialValues.selectCourse = data?.getUserProfile.areaOfStudy;
    }

    const mutationError = setUserProfileMutationError || updateApplicantCountryMutationError;

    const onSubmit = async (
        {selectCourse, selectCountry}: FormValues,
        formActions: FormikHelpers<FormValues>,
    ) => {
        try {
            const areaOfStudyId = selectCourse[0]?.id;
            const countryName = selectCountry[0]?.name;

            const promises = [];
            const updateProfilePromise = setUserProfile({
                variables: {
                    data: {
                        areaOfStudy: [areaOfStudyId],
                    },
                },
            });
            promises.push(updateProfilePromise);

            const updateApplicantDetails = setUpdateApplicant({
                variables: {
                    country: countryName,
                },
            });
            promises.push(updateApplicantDetails);
            await Promise.all(promises);

            trackEvent(TrackEvents.EXPLORE_PAGE_REVEAL_MATCHES_CLICKED, {
                areaOfStudy: areaOfStudyId,
                country: countryName,
            });

            // evict root query on recommendations for fresh results
            cache.evict({id: "ROOT_QUERY", fieldName: "getRecommendedUsers"});
        } catch (error) {
            console.log(error);
            reportError(error);
            formActions.setFieldError("serverError", "An error occurred. Please try again later.");
        } finally {
            formActions.setSubmitting(false);
            setAuth({
                ...authState,
                me: {
                    ...authState.me,
                    anyUser: {
                        ...authState.me.anyUser,
                        country: selectCountry[0],
                    },
                },
            });
            onRevealMatches();
        }
    };

    return (
        <View
            w="100%"
            minH={containerHeight}
            alignItems="center"
            data-testid="no-matches-yet"
            borderRadius="xsmall"
            position="relative"
            paddingLeft={["small", "none"]}
            overflow="hidden"
        >
            <Image
                style={{
                    width: "100%",
                    height: 417,
                    resizeMode: "contain",
                    position: "absolute",
                }}
                source={image}
                testID="empty-state-image"
                aria-hidden
            />
            <View
                position="relative"
                zIndex={10}
                w={isDesktop ? "60%" : "90%"}
                bgColor="body"
                padding="medium"
                borderWidth={1}
                borderColor="grey400"
                borderRadius="medium"
            >
                <Stack space="small-medium">
                    <Heading
                        align="center"
                        level="2"
                        size="xsmall"
                        // @ts-ignore
                        style={{
                            ...boldFontStyles,
                        }}
                    >
                        {OnboardingStrings.TITLE}
                    </Heading>
                    <Text align="center">{OnboardingStrings.SUBTITLE}</Text>
                    <View w="100%" alignItems="center">
                        <Image style={{width: 124, height: 28}} source={userTray} />
                    </View>
                    <Form
                        validationSchema={{
                            selectCountry: array().required().min(1, "This field is required"),
                            selectCourse: array().required().min(1, "This field is required"),
                        }}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    >
                        <Stack space="medium-large">
                            <EditCourse />
                            <EditNationality />
                            <ErrorMessage
                                name="serverError"
                                component={
                                    <MutationErrorHandler
                                        error={mutationError}
                                        meta={{
                                            component: "AccountInformation",
                                            mutation:
                                                "useSetUserProfileMutation | useUpdateApplicantCountryMutation",
                                        }}
                                    />
                                }
                            />
                            <SubmitButton block color="primary">
                                <Text color="white">{OnboardingStrings.BUTTON}</Text>
                            </SubmitButton>
                        </Stack>
                    </Form>
                </Stack>
            </View>
        </View>
    );
};
