import useAuth from "./useAuth";
import {authMeSelector} from "./selectors";

/**
 * The purpose of this hook is to provide the id of the user
 *
 * @export
 * @returns the id of the user
 */
export function useHasOneOfRoles(roles) {
    const auth = useAuth();
    const me = authMeSelector(auth);

    return roles.indexOf(me.anyUser.accountRole) > -1;
}

export function useRole() {
    const auth = useAuth();
    const me = authMeSelector(auth);

    return me.anyUser.accountRole;
}

export function useHasRole(role) {
    const auth = useAuth();
    const me = authMeSelector(auth);

    return role === me.anyUser.accountRole;
}
