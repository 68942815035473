import React from "react";
import {Platform, useWindowDimensions} from "react-native";
import {View} from "@unibuddy/patron";
import {Circle, Rect} from "react-native-svg";

import {Shimmer} from "ubcommunity-shared/src/General/Shimmer";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

export const ShimmerMemberListContainer = ({isDmsEnabled = false}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const numberOfRepetitions = 10;
    return (
        <View
            backgroundColor={darkModeEnabled ? "groupBgColor" : "white"}
            paddingTop="small-medium"
            position="absolute"
            flex={1}
            width="100%"
        >
            {Array(numberOfRepetitions)
                .fill(0)
                .map((_, i) => (
                    <View
                        paddingX="medium"
                        paddingY="xsmall"
                        key={`members-list-shimmer-item-${i}`}
                    >
                        <ShimmerListMember isDmsEnabled={isDmsEnabled} />
                    </View>
                ))}
        </View>
    );
};

export const ShimmerListMember = ({isDmsEnabled = false}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const {width} = useWindowDimensions();

    const isWeb = Platform.OS === "web";
    const containerWidth = isWeb ? 350 : width;
    return (
        <Shimmer
            height={50}
            speed={2}
            viewBox={`0 0 ${containerWidth} 50`}
            backgroundColor={darkModeEnabled ? "#3F3E4F" : "#e7e7e7"}
            foregroundColor={darkModeEnabled ? "#525167" : "#f2f2f2"}
        >
            <Circle cx="25" cy="25" r="25" />
            <Rect x="60" y="10" rx="2" ry="2" width="200" height="12" />
            <Rect x="60" y="28" rx="2" ry="2" width="16" height="12" />
            <Rect x="85" y="30" rx="2" ry="2" width="100" height="8" />
            {isDmsEnabled ? <Circle cx={`${containerWidth - 23}`} cy="25" r="20" /> : null}
        </Shimmer>
    );
};
