import * as React from "react";

const ZoomoutIcon = (props) => {
    const {color = "currentColor", size = 24, ...rest} = props;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke={color}
            strokeWidth="2"
            {...rest}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7"
            />
        </svg>
    );
};

export default ZoomoutIcon;
