import React from "react";
import {TouchableOpacity} from "react-native";
import {Box, Inline, Stack, Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {Interest} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {usePeopleFilters} from "../PeopleFiltersProvider";

type Props = {
    callback?: () => void;
    interestsData: Interest[];
};

const Chip = ({
    interest,
    selected,
    onSelect,
}: {
    interest: Interest;
    selected: boolean;
    onSelect: (interest: Interest) => void;
}) => {
    return (
        <Box
            padding="small"
            borderWidth={1}
            borderColor={selected ? "teal800" : "grey100"}
            borderRadius="full"
            bgColor={selected ? "teal100" : "transparent"}
        >
            <TouchableOpacity
                accessibilityLabel={
                    selected ? `Deselect ${interest.name}` : `Select ${interest.name}`
                }
                onPress={() => onSelect(interest)}
            >
                <Text size="small">{interest.name}</Text>
            </TouchableOpacity>
        </Box>
    );
};

export const InterestsSelector = ({callback, interestsData}: Props) => {
    const {filters, setFilters} = usePeopleFilters();
    const {trackEvent} = useAnalytics();

    const onSelect = (interestSelected: Interest) => {
        const interestId = interestSelected.id;
        const oldFilters = filters.interests || [];
        if (oldFilters.find((id) => id === interestId)) {
            trackEvent(TrackEvents.PEOPLE_INTEREST_FILTER_DESELECTED, {
                interestId: interestSelected.id,
                interestName: interestSelected.name,
            });
            setFilters({
                ...filters,
                interests: oldFilters.filter((id) => id !== interestId),
            });
            return;
        }
        trackEvent(TrackEvents.PEOPLE_INTEREST_FILTER_SELECTED, {
            interestId: interestSelected.id,
            interestName: interestSelected.name,
        });
        setFilters({
            ...filters,
            interests: [...oldFilters, interestId],
        });
    };

    return (
        <Stack space="small">
            <Text size="large" weight="bold">
                Interests
            </Text>
            <Inline space="small">
                {interestsData.map((interest) => (
                    <Chip
                        key={interest.id}
                        interest={interest}
                        selected={filters.interests?.includes(interest.id)}
                        onSelect={onSelect}
                    />
                ))}
            </Inline>
        </Stack>
    );
};
