import React from "react";
import {View, Stack, Heading, Box, Text} from "@unibuddy/patron";
import {useChatTheme} from "@unibuddy/chat-ui";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {WelcomeLettering} from "ubcommunity-shared/src/Svgs/WelcomeLettering";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";

type positioningProp = "bottom" | "center";

export const NewsEmptyStateComponent = ({
    positioning = "bottom",
}: {
    positioning?: positioningProp;
}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = useChatTheme();
    const {university} = useCommunity();

    return (
        <View
            flex="1"
            paddingX="medium"
            paddingTop="large"
            backgroundColor="chatBody"
            justifyContent={positioning === "center" ? "center" : "flex-end"}
        >
            <View
                backgroundColor={
                    darkModeEnabled ? theme?.chat?.message?.altBackgroundColor : "#fff"
                }
                borderRadius="xsmall"
                padding="medium"
            >
                <Stack space="large">
                    <View justifyContent="center" alignItems="center" flex={1}>
                        <WelcomeLettering />
                    </View>
                    <Heading level="5" align="center" style={{...boldFontStyles}}>
                        Welcome to Updates!
                    </Heading>
                    <Box>
                        <Text size="small">
                            {`Here you'll get all the latest news and important information from ${university?.name}. \nCheck back to stay on track!`}
                        </Text>
                    </Box>
                </Stack>
            </View>
        </View>
    );
};

export const NewsEmptyState = () => {
    const {isDesktop} = useIsDesktop();

    if (isDesktop) {
        return (
            <View flex={1} backgroundColor="chatBody" alignItems="center">
                <View flex={1} width="50%">
                    <NewsEmptyStateComponent positioning="center" />
                </View>
            </View>
        );
    }

    return <NewsEmptyStateComponent />;
};
