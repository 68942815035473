import React, {PropsWithChildren} from "react";
import {View} from "@unibuddy/patron";

export const SidePanel = ({children}: PropsWithChildren) => {
    return (
        <View
            w={320}
            shadow="medium"
            display={["none", "none", "none", "flex"]}
            zIndex={1}
            position="relative"
        >
            {children}
        </View>
    );
};
