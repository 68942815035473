import React from "react";
import {Image} from "react-native";
import {View, Text, Box, usePatronTheme} from "@unibuddy/patron";

import {AvatarBeam} from "ubcommunity-shared/src/General/AvatarBeam/AvatarBeam";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

import {GROUPS_LIST_PROFILE_PHOTO_PREVIEWS_STRINGS} from "./strings";
import {NUMBER_OF_GROUP_USERS_PREVIEW} from "./GroupList";
import {GetCommunityConversationsGroupsQuery} from "ubcommunity-shared/src/types";

const THUMB_SIZE = 24;
const SPACE = THUMB_SIZE - 8;
export const AVATAR_BEEN_TEST_ID = "avatar-been";
export const PROFILE_PHOTO_TEST_ID = "profile-photo";

const avatarPlaceholder = "avatar-placeholder";

type profiles =
    GetCommunityConversationsGroupsQuery["getCommunityConversations"][0]["communityChatMembers"][0];

interface GroupsListProfilePhotosPreviewProps {
    profiles: profiles[];
    numberOfUsers: number;
}

const colorsArray = ["#3E3E48", "#2913B9", "#6852FB", "#CFC8FF", "#01D4CF"];

// Shuffle the colors array to make sure the avatars order is different every time
const shuffle = (array: string[]) =>
    array
        .map((a) => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);

export const GroupsListProfilePhotosPreviews = ({
    profiles,
    numberOfUsers,
}: GroupsListProfilePhotosPreviewProps) => {
    const theme = usePatronTheme();
    const {darkModeEnabled} = useCommunityTheme();
    const profilesToDisplay: Array<profiles | string> = profiles;

    while (profilesToDisplay?.length < NUMBER_OF_GROUP_USERS_PREVIEW) {
        profilesToDisplay.push(avatarPlaceholder);
    }

    return (
        <View
            position="relative"
            height={THUMB_SIZE}
            accessible
            accessibilityLabel={
                GROUPS_LIST_PROFILE_PHOTO_PREVIEWS_STRINGS.IMAGE_ACCESSIBILITY_LABEL
            }
        >
            {profilesToDisplay?.map((profile, index) => (
                <Box
                    position="absolute"
                    borderRadius="full"
                    height={THUMB_SIZE}
                    width={THUMB_SIZE}
                    left={SPACE * index}
                    borderWidth={1}
                    borderColor={darkModeEnabled ? "black" : "white"}
                    key={`groups-list-profile-photo-${index}`}
                    overflow="hidden"
                >
                    {typeof profile === "string" ? (
                        <AvatarBeam
                            id={`avatar-been-id-${index}`}
                            name={`avatar-been-name-${index}`}
                            data-testid={AVATAR_BEEN_TEST_ID}
                            size={THUMB_SIZE}
                            colors={shuffle(colorsArray)}
                        />
                    ) : (
                        <Image
                            style={{width: THUMB_SIZE, height: THUMB_SIZE}}
                            source={{uri: profile?.communityProfile.profilePhoto}}
                            data-testid={PROFILE_PHOTO_TEST_ID}
                        />
                    )}
                </Box>
            ))}
            {numberOfUsers ? (
                <Box
                    position="absolute"
                    backgroundColor={theme.colors.groupBgColor}
                    borderRadius="full"
                    height={THUMB_SIZE}
                    width={THUMB_SIZE}
                    left={SPACE * profiles?.length}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    data-testId="member-count"
                >
                    <Text size="xxsmall" style={{fontSize: 8}}>
                        {`+${numberOfUsers}`}
                    </Text>
                </Box>
            ) : null}
        </View>
    );
};
