import {useHistory} from "react-router";
import {useApolloClient} from "@apollo/client";
import {useLocalStorage} from "@unibuddy/patron";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useApiClient} from "ubcommunity-shared/src/ApiProvider/useApiClient";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {useAsyncLocalStorage} from "../AuthProvider/useAsyncLocalStorage";
import useAuth from "./useAuth";
import {useClearLocalState} from "./useClearLocalState";

export const useLogout = () => {
    const {clear} = useClearLocalState();
    const {push} = useHistory();
    const {value: ssoFlowValue} = useAsyncLocalStorage(LocalStorageNames.SSO_FLOW, undefined);
    // eslint-disable-next-line
    const [_, setEmailInvite] = useLocalStorage(LocalStorageNames.EMAIL_INVITE, "");

    const logout = async () => {
        if (ssoFlowValue?.slug) {
            push(`/u/${ssoFlowValue.slug}`);
        }
        setEmailInvite(undefined);
        await clear();
    };

    return {logout};
};

export const useLogoutWithoutSideEffects = () => {
    const {setAuth} = useAuth();
    const {select} = useCommunity();
    const client = useApolloClient();
    const {clearCache} = useApiClient();
    const logout = async () => {
        await client.clearStore();
        clearCache();
        select();
        setAuth({});
    };

    return {logout};
};
