import {Platform} from "react-native";
import {Box, View, Text, usePatronTheme} from "@unibuddy/patron";

import {CircularProgressionBarWithCounter} from "ubcommunity-shared/src/General/CircularProgressionBar";
import {PROFILE_AVATAR_SIZE} from "ubcommunity-shared/src/Settings/ProfilePhoto/utils";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {AccountRoles} from "ubcommunity-shared/src/constants";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

import {ProfilePhotoEditor} from "../../ProfilePhoto/ProfilePhotoEditor";
import {SettingsStudentAppWrapper} from "../SettingsStudentAppWrapper/SettingsStudentAppWrapper";
import {useUserProfile} from "ubcommunity-shared/src/Hooks/useUserProfile";

export const ProfilePicture = ({accountRole}: {accountRole: AccountRoles}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();
    const {profileCompletion: globalProfileCompletion} = useUserProfile();
    const isWeb = Platform.OS === "web";

    const profilePercentage = globalProfileCompletion;

    const OFFSET_VALUE = -10;
    const RING_GAP = 2;

    const yellowBanana = "#FED401";

    if (accountRole !== AccountRoles.STUDENT) return null;

    return (
        <View
            paddingBottom="large"
            position="relative"
            alignItems="center"
            width={isWeb ? "fit-content" : null}
            minH={isWeb ? "fit-content" : 0}
            style={{alignSelf: "center"}}
        >
            {/** these values are needed because the profile image here is not centralised */}
            <SettingsStudentAppWrapper>
                <View
                    zIndex={0}
                    position="absolute"
                    style={{top: OFFSET_VALUE, left: OFFSET_VALUE}}
                >
                    <CircularProgressionBarWithCounter
                        size={PROFILE_AVATAR_SIZE + RING_GAP}
                        progressPercentage={profilePercentage}
                        progressionBarStyle={{
                            lineColor: darkModeEnabled ? theme.colors.darkGrey : "#F3EBFE",
                            activeLineColor: yellowBanana,
                            strokeWidth: 2,
                            activeStrokeWidth: 8,
                        }}
                    />
                </View>
            </SettingsStudentAppWrapper>
            <View zIndex={0}>
                <ProfilePhotoEditor />
            </View>
            {profilePercentage !== 100 ? (
                <Box marginTop="xlarge">
                    <Text size="xxsmall" style={{...boldFontStyles}}>
                        Your profile is incomplete
                    </Text>
                </Box>
            ) : (
                <View marginTop="small" />
            )}
        </View>
    );
};
