import useAuth from "../Auth/hooks/useAuth";
import {useGetSpacesQuery} from "../types";

export type IsStudentAppExperience = {
    loading: boolean;
    isStudentExperience: boolean;
    hasCommunitiesORInvites: boolean;
    hasUniversityLinks: boolean;
};
export const useIsStudentAppExperience = () => {
    const {user} = useAuth();

    const {data, loading} = useGetSpacesQuery({
        skip: !user,
        variables: {
            userId: user?.id ?? "",
            email: user?.email ?? "",
        },
    });

    const hasCommunitiesORInvites =
        !!data?.getUser?.communities?.length || !!data?.getInvitesForEmail?.length;

    const isApplicantUniversityLinked = Boolean(data?.me?.applicant?.applicantUniversities?.length);

    return {
        loading,
        hasCommunitiesORInvites,
        hasUniversityLinks: isApplicantUniversityLinked,
    };
};
