import {PropsWithChildren, ReactNode} from "react";
import {OptionFormGroup, OptionItem, OptionLabel, useOption, View} from "@unibuddy/patron";
import styled from "styled-components";
import {UniversityQuestionOption} from "ubcommunity-shared/src/types";

const StyledLabel = styled(OptionLabel)`
    align-items: center;
    background: ${({theme, isSelected}) => (isSelected ? theme.colors.primaryColor : "#fff")};
    border: ${({isSelected}) => (isSelected ? "1px solid #5140C9" : "1px solid #D0D6DC")};
    box-shadow: ${({isFocused}) => (isFocused ? "0 0 0 2px white, 0 0 0 5px #5140C9" : "none")};
    color: ${({theme, isSelected}) => (isSelected ? theme.colors.white : theme.colors.darkGrey)};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 45px;
    line-height: 45px;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    &:hover {
        background: ${({theme, isSelected}) =>
            isSelected ? theme.colors.primaryColor : theme.colors.navBorderColor};
    }

    &:focus {
        box-shadow: 0 0 0 3px #2672bd;
        outline: 0 !important;
        border: 1px solid #21409a;
    }

    &:nth-of-type(1) {
        border-radius: 5px 0 0 5px;
        border-right: ${({isSelected}) => !isSelected && 0};
    }

    &:nth-last-of-type(1) {
        border-radius: 0 5px 5px 0;
    }

    &:not(:nth-of-type(1), :nth-last-of-type(1)) {
        border: ${({isSelected}) => (isSelected ? "1px solid #5140C9" : "1px solid #D0D6DC")};
        border-right: ${({isSelected}) => !isSelected && 0};
    }
    fontsize: 16;
    fontweight: 600;
    fontfamily: ${({theme}) => theme?.TextBlock?.fontFamily};
`;

const OptionButtonRound = ({children}: PropsWithChildren) => {
    const {isSelected, isFocused} = useOption();
    return (
        <StyledLabel isSelected={isSelected} isFocused={isFocused}>
            {children}
        </StyledLabel>
    );
};

export const Rating = ({
    name,
    options,
    label,
}: {
    name: string;
    options: Partial<UniversityQuestionOption>[];
    label: ReactNode;
}) => {
    return (
        <OptionFormGroup name={name} label={label}>
            <View flexDirection={"row"}>
                {options.map((option) => (
                    <OptionItem value={option.id} key={option.id}>
                        <OptionButtonRound>{option.text}</OptionButtonRound>
                    </OptionItem>
                ))}
            </View>
        </OptionFormGroup>
    );
};
