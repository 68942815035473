import React from "react";
import {useWindowDimensions, Platform} from "react-native";
import {View, usePatronTheme} from "@unibuddy/patron";
import {Rect} from "react-native-svg";

import {Shimmer} from "ubcommunity-shared/src/General/Shimmer";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

const DARK_BG_COLOR = "#3F3E4F";

export const GroupsListShimmer = () => {
    const numberOfRepetitions = 4;
    const {width} = useWindowDimensions();
    const {darkModeEnabled} = useCommunityTheme();

    const isWeb = Platform.OS === "web";
    const containerWidth = isWeb ? 768 : width;
    const theme = usePatronTheme();

    return (
        <View
            paddingX="small-medium"
            flex={1}
            marginTop="medium-large"
            accessible
            accessibilityLabel="loading"
        >
            <Shimmer
                height={50}
                viewBox={`0 0 ${containerWidth} 50`}
                backgroundColor={darkModeEnabled ? DARK_BG_COLOR : theme.colors.grey100}
                foregroundColor={darkModeEnabled ? theme.colors.grey700 : theme.colors.grey90}
            >
                <Rect x="0" y="0" rx="25" ry="25" width={`${containerWidth}`} height="50" />
            </Shimmer>
            <View marginTop="small" flexDirection="row" height={50} gap="small">
                {Array(5)
                    .fill(0)
                    .map((_, i) => (
                        <View width={80} key={`group-list-shimmer${i}`}>
                            <Shimmer
                                height={30}
                                viewBox={`0 0 80 30`}
                                backgroundColor={
                                    darkModeEnabled ? DARK_BG_COLOR : theme.colors.grey100
                                }
                                foregroundColor={
                                    darkModeEnabled ? theme.colors.grey700 : theme.colors.grey90
                                }
                            >
                                <Rect x="0" y="0" rx="15" ry="15" width="80" height="30" />
                            </Shimmer>
                        </View>
                    ))}
            </View>
            <View
                padding="small"
                paddingTop="small"
                gap="small"
                flexWrap="wrap"
                flexDirection="row"
            >
                <Shimmer
                    height={30}
                    backgroundColor={darkModeEnabled ? DARK_BG_COLOR : theme.colors.grey100}
                    foregroundColor={darkModeEnabled ? theme.colors.grey700 : theme.colors.grey90}
                >
                    <Rect x="0" y="0" rx="2" ry="2" width="100" height="20" />
                </Shimmer>
                {Array(numberOfRepetitions)
                    .fill(0)
                    .map((_, i) => (
                        <SkeletonGroupItem index={i} key={`group-list-shimmer${i}`} />
                    ))}
            </View>
        </View>
    );
};

const SkeletonGroupItem = ({index}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();

    return (
        <View
            minW="40%"
            flex={1}
            height={170}
            borderRadius="small"
            overflow="hidden"
            key={`group-list-shimmer${index}`}
        >
            <Shimmer
                width="100%"
                height="100%"
                backgroundColor={darkModeEnabled ? DARK_BG_COLOR : theme.colors.grey100}
                foregroundColor={darkModeEnabled ? theme.colors.grey700 : theme.colors.grey90}
            >
                <Rect x="0" y="0" width="100%" height="100%" />
            </Shimmer>
        </View>
    );
};
