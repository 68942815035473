import React, {FC} from "react";
import {placeholderAvatar} from "@unibuddy/chat-ui";
import {AvatarBeam} from "../AvatarBeam/AvatarBeam";
import {MentorAvatar} from "./MentorAvatar";
import {UniversityAvatar} from "./UniversityAvatar";
import {ApplicantAvatar} from "./ApplicantAvatar";
import {UserProfile} from "ubcommunity-shared/src/types";

export type UserAvatarUser = {
    id: string;
    firstName: string;
    accountRole: string;
    profilePhoto: string;
    communityProfile?: UserProfile;
};

export const UserAvatar: FC<{
    user: UserAvatarUser;
    size: number;
    isBlocked?: boolean;
}> = ({user, size, isBlocked}) => {
    if (!user?.firstName) return null;

    if (isBlocked) return <AvatarBeam id={user.id} name={user.firstName} size={size} />;

    if (user.accountRole === "university")
        return <UniversityAvatar user={user} size={size} style={{borderRadius: size}} />;

    if (user.accountRole === "applicant")
        return <ApplicantAvatar user={user} size={size} style={{borderRadius: size}} />;

    if (!user.profilePhoto || user.accountRole === "admin")
        return <AvatarBeam id={user.id} name={user.firstName} size={size} />;

    if (user.accountRole === "mentor" || user.accountRole === "staff")
        return <MentorAvatar user={user} width={size} height={size} style={{borderRadius: size}} />;

    return placeholderAvatar;
};
