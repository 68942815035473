import {Product, useGetCommunityConversationQuery} from "../types";
import {useGetConversationId} from "./useGetConversationId";

export enum ConversationType {
    WIDGET = "Widget",
    DIRECT = "Direct",
    PRIVATE = "Private",
    NEWS_FEED = "NewsFeed",
    PUBLIC = "Public",
}

export const useConversation = () => {
    const {id} = useGetConversationId();
    const {data, loading, error, refetch} = useGetCommunityConversationQuery({
        variables: {
            id,
        },
        skip: !id,
    });

    const isWidget = data?.getChatConversation?.product === Product.Widget;
    const isDirect = data?.getChatConversation?.isDirect;
    const isPrivate = data?.getChatConversation?.isPrivate;
    const isNewsFeed = data?.getChatConversation?.isNewsFeed;

    // priority is widget > direct > private > news feed > public
    const conversationType = isWidget
        ? ConversationType.WIDGET
        : isDirect
        ? ConversationType.DIRECT
        : isPrivate
        ? ConversationType.PRIVATE
        : isNewsFeed
        ? ConversationType.NEWS_FEED
        : ConversationType.PUBLIC;

    return {
        data,
        loading,
        error,
        refetch,
        conversationType,
    };
};
