import {View} from "@unibuddy/patron";
import React from "react";
import {useParams} from "react-router";
import {JoinGroupContainer} from "ubcommunity-shared/src/Community/JoinGroupContainer/JoinGroupContainer";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {PageTitles} from "ubcommunity-shared/src/constants";
import {CommunityChatGroup} from "ubcommunity-shared/src/types";

type ChatGroup = Pick<CommunityChatGroup, "id" | "title" | "description" | "isPrivate">;

export const JoinGroupPage = () => {
    const {conversationId, communityId} = useParams<{
        conversationId: string;
        communityId: string;
        universitySlug: string;
    }>();
    const {push} = useRelativeRouter();

    usePageTitle(PageTitles.JOIN_GROUP);

    const onJoinHandler = ({id, title, description, isPrivate}: ChatGroup) => {
        push(`/chat/${id}`, {title, description, isPrivate});
    };

    const onDeclineHandler = () => {
        push("/");
    };

    return (
        <View flex="1" justifyContent="center" alignItems="center">
            <JoinGroupContainer
                conversationId={conversationId}
                communityId={communityId}
                onJoin={onJoinHandler}
                onDecline={onDeclineHandler}
            />
        </View>
    );
};
