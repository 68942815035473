import React from "react";
import {Platform} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";

import {TrackEvents, TrackSources} from "ubcommunity-shared/src/constants";
import {UserField} from "ubcommunity-shared/src/types";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {MEMBERS_LIST_BUTTON_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import {useCreateDirectConversation} from "ubcommunity-shared/src/Hooks/graphql/useCreateDirectConversation";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {MixpanelMetadataProps} from "../UserSummary/UserSummary";
import {PeopleCell} from "ubcommunity-shared/src/People/PeopleCell";

export interface IMember extends UserField {
    isOnline: boolean;
}

export function Member({
    member,
    drawerRef,
    onShowUserSummary,
    isDmsEnabled = false,
    source,
}: {
    member: IMember;
    drawerRef?: React.RefObject<DrawerLayout>;
    onShowUserSummary: (userId: string, mixpanelMetadata: MixpanelMetadataProps) => void;
    isDmsEnabled?: boolean;
    source?: TrackSources;
}): JSX.Element {
    const membersListButtonFlag = useIsFeatureEnabledBoolean(MEMBERS_LIST_BUTTON_FEATURE_FLAG);
    const [navigate] = useNavigate();
    const {trackEvent} = useAnalytics();
    const {createDirectConversation, loading: dmLoading} = useCreateDirectConversation({
        senderId: member?.id,
        source,
    });
    const buttonViewed = membersListButtonFlag ? "View Profile" : "Chat Button";
    const isWeb = Platform.OS === "web";

    const handleDirectMessage = async (e, source: string) => {
        if (Platform.OS === "web") {
            e.stopPropagation();
        }
        const response = await createDirectConversation();
        const newConversationId = response?.data?.createDirectConversation?.id;

        trackEvent(TrackEvents.MEMBER_LIST_BUTTON_CLICKED, {
            source: source,
        });

        navigate({
            path: Routes.CHAT,
            options: {
                screen: isWeb ? newConversationId : Routes.CHAT_HOME,
                params: {
                    conversationId: newConversationId,
                },
            },
        });

        if (drawerRef) {
            drawerRef?.current?.closeDrawer();
        }
    };
    const onPress = (source?: string) => {
        onShowUserSummary(member?.id, {
            isOnline: member.isOnline,
            buttonViewed,
            areaOfStudy: member?.communityProfile?.areaOfStudy?.[0]?.name,
            mixpanelMetadata: {openSource: source},
        });
        if (source) {
            trackEvent(TrackEvents.MEMBER_LIST_BUTTON_CLICKED, {
                source: source,
            });
        }
    };

    return (
        <PeopleCell
            user={{
                id: member?.id ?? "",
                firstName: member?.firstName ?? "",
                lastName: member?.lastName ?? "",
                accountRole: member?.accountRole ?? "",
                profilePhoto: member?.profilePhoto ?? "",
                country: member?.country ?? undefined,
                isOnline: member?.isOnline ?? false,
                communityProfile: member?.communityProfile ?? undefined,
                areaOfStudy: member?.communityProfile?.areaOfStudy?.[0]?.name ?? "",
            }}
            openProfile={onPress}
            handleDirectMessage={handleDirectMessage}
            dmLoading={dmLoading}
            isDmsEnabled={isDmsEnabled}
        ></PeopleCell>
    );
}
