import React from "react";
import {Box} from "@unibuddy/patron";
import {UserAvatar, UserAvatarUser} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";

const sizes = {
    medium: 40,
    small: 28,
};

const getContainerSize = (size: number): number => Math.round(size + size * 0.3);

export const ChatMembersAvatars = ({
    members = [],
    size,
}: {
    members: UserAvatarUser[];
    size: keyof typeof sizes;
}) => {
    if (members.length === 1) {
        const sizePx = sizes[size] || sizes.medium;
        return <UserAvatar user={members[0]} size={getContainerSize(sizePx)} />;
    }

    const sizePx = sizes[size];
    const containerSize = getContainerSize(sizePx);
    const [member1, member2] = members;

    return (
        <Box w={containerSize} h={containerSize} position="relative" flexShrink={0}>
            <Box position="absolute" bottom={0} left={0}>
                <UserAvatar user={member1} size={sizePx} />
            </Box>
            <Box
                position="absolute"
                top={0}
                right={0}
                zIndex={1}
                borderWidth={2}
                borderColor="navbarColor"
                borderRadius="full"
            >
                <UserAvatar user={member2} size={sizePx} />
            </Box>
        </Box>
    );
};

ChatMembersAvatars.defaultProps = {
    size: "medium",
};
