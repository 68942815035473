import {useCallback} from "react";
import {FlatList, FlatListProps} from "react-native";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {ListFooter, PeopleLoading} from "ubcommunity-shared/src/People/PeopleLoading";
import {BuddyField} from "ubcommunity-shared/src/types";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {PeopleEmptyResults} from "ubcommunity-shared/src/People/PeopleEmptyResults";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {UserAccountRoles} from "ubcommunity-shared/src/constants";
import {AmbassadorItem} from "./AmbassadorItem";
import {useStaffMentorList} from "./useStaffMentorList";

export const CONVERSATION_INITIATION_ERROR_MESSAGE =
    "An error occurred while creating conversation. Please try again later.";

export const StaffList = () => {
    const keyExtractor = useCallback(
        (item: BuddyField, index: number) => `item-${item.id}-${index}`,
        [],
    );

    const {loading, data, error, fetchNextLoading, refetch, fetchNext, total} =
        useStaffMentorList();
    const {isDesktop} = useIsDesktop();
    const {open} = usePrompt();
    const {reportError} = useErrorReporting();

    const onConversationError = useCallback(
        (error?: Error) => {
            open({
                title: "Oops!",
                message: CONVERSATION_INITIATION_ERROR_MESSAGE,
                buttons: [{text: "OK"}],
            });
            reportError({
                name: "Staff Ambassador Listing",
                message:
                    error?.message ?? "Conversation initiation failed in Staff Ambassador Listing",
            });
        },
        [reportError, open],
    );

    const handleEndReached = () => {
        if (!total || data?.length === total) return;
        fetchNext();
    };

    if (loading) return <PeopleLoading isDmsEnabled={true} />;

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{
                    component: "StaffList",
                    query: "useStaffMentorList",
                }}
                layout="center"
            />
        );
    }

    const desktopProps: Partial<FlatListProps<BuddyField | null>> = {};

    if (isDesktop) {
        desktopProps.numColumns = 3;
        desktopProps.contentContainerStyle = {
            alignItems: "center",
        };
    }

    return (
        <FlatList
            keyExtractor={keyExtractor}
            onEndReached={handleEndReached}
            data={data ?? []}
            ListEmptyComponent={
                <PeopleEmptyResults
                    title="No Staff ambassadors are added for your university."
                    description="Please contact the admin for more information."
                />
            }
            ListFooterComponent={<ListFooter loading={fetchNextLoading} />}
            renderItem={({item}) => (
                <AmbassadorItem
                    key={item?.id}
                    ambassador={{
                        id: item?.id,
                        firstName: item?.firstName,
                        profilePhoto: item?.profilePhoto,
                        degree: {
                            name: item?.staffFields?.role,
                        },
                        bio: item?.staffFields?.bio,
                        accountRole: UserAccountRoles.STAFF,
                        department: item?.staffFields?.department,
                    }}
                    isDmsEnabled
                    onError={onConversationError}
                />
            )}
            {...desktopProps}
        />
    );
};
