import React, {useState} from "react";
import mixpanel from "mixpanel-browser";
import {
    Stack,
    Text,
    Columns,
    Collapsible,
    Column,
    Switch,
    Button,
    useLocalStorage,
    Heading,
    useToast,
} from "@unibuddy/patron";
import {useEditCookieConsentPreferencesMutation} from "ubcommunity-shared/src/types";

export const CookiesSettings = () => {
    const {addToast} = useToast();
    const [preferencesCookies, setPreferencesCookies] = useLocalStorage("analytics-cookies.v2");
    const [analyticsSelected, setAnalyticsSelected] = useState(
        preferencesCookies ? preferencesCookies.preferences.analytics : false,
    );
    const [initialState] = useState(preferencesCookies ? preferencesCookies.preferences : {});
    const [savePreferences, {loading}] = useEditCookieConsentPreferencesMutation();

    const handleSelection = async ({analytics}: {analytics: boolean}) => {
        const hasChanged = initialState.analytics !== analytics;

        const uniqueId = preferencesCookies.uniqueId;
        const payload = {
            uniqueId,
            preferences: {analyticsCookies: analytics as boolean},
        };

        if (hasChanged) {
            try {
                setPreferencesCookies({
                    uniqueId,
                    preferences: {
                        analytics,
                    },
                });
                savePreferences({
                    variables: {input: payload},
                });
            } catch (err) {
                console.log(err);
            }

            if (analytics) {
                window.location.reload();
            } else {
                mixpanel.opt_out_tracking();
                window.location.reload();
            }
        } else {
            addToast({title: "Cookies", text: "Analytics cookies have been updated", ttl: 3000});
        }
    };

    return (
        <Stack dividers space="medium">
            <Stack space="medium">
                <Heading level="2" size="xsmall" weight="400">
                    Cookies
                </Heading>

                <Text size="small">
                    When you visit any website, it may store or retrieve information on your
                    browser, mostly in the form of cookies. This information might be about you,
                    your preferences or your device and is mostly used to make the site work as you
                    expect it to. The information does not usually directly identify you, but it can
                    give you a more personalized web experience. Because we respect your right to
                    privacy, you can choose not to allow some types of cookies.
                </Text>

                <Stack space="medium">
                    <Stack dividers>
                        <Collapsible trigger="Strictly Necessary Cookies">
                            <Columns space="large">
                                <Column>
                                    <Text size="small" id="essential-description">
                                        These cookies are necessary for the website to function and
                                        cannot be switched off in our systems. They are usually only
                                        set in response to actions made by you which amount to a
                                        request for services, such as setting your privacy
                                        preferences, logging in or filling in forms. You can set
                                        your browser to block or alert you about these cookies, but
                                        some parts of the site will not then work. These cookies do
                                        not store any personally identifiable information.
                                    </Text>
                                </Column>
                                <Column width="content">
                                    <Switch
                                        checked
                                        disabled
                                        aria-labelledby="essential-description"
                                    />
                                </Column>
                            </Columns>
                        </Collapsible>
                        <Collapsible trigger="Analytics Cookies">
                            <Columns space="large">
                                <Column>
                                    <Text id="analytics-description" size="small">
                                        Unibuddy Analytics Cookies
                                    </Text>
                                </Column>
                                <Column width="content">
                                    <Switch
                                        aria-labelledby="analytics-description"
                                        checked={analyticsSelected}
                                        onChange={() => {
                                            setAnalyticsSelected(!analyticsSelected);
                                        }}
                                    />
                                </Column>
                            </Columns>
                        </Collapsible>
                    </Stack>

                    <Text size="small">
                        Click on the different category headings to find out more and change our
                        default settings. However, blocking some types of cookies may impact your
                        experience of the site and the services we are able to offer. More
                        information
                    </Text>
                </Stack>
            </Stack>

            <Columns space="medium" collapseBelow="tablet">
                <Column>
                    <Button
                        size="sm"
                        color="primary"
                        ghost
                        block
                        round
                        disabled={loading}
                        onClick={() => {
                            setAnalyticsSelected(true);
                            handleSelection({analytics: true});
                        }}
                    >
                        Consent to all
                    </Button>
                </Column>
                <Column>
                    <Button
                        size="sm"
                        color="primary"
                        block
                        round
                        disabled={loading}
                        onClick={() => {
                            handleSelection({analytics: analyticsSelected});
                        }}
                    >
                        Confirm choices
                    </Button>
                </Column>
            </Columns>
        </Stack>
    );
};
