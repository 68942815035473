import {View} from "@unibuddy/patron";
import React, {PropsWithChildren} from "react";
import {createFeatureFlaggingProvider, User} from "ub-feature-flagging-react";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";

const FeatureFlaggingProvider = createFeatureFlaggingProvider(
    process.env.LAUNCH_DARKLY_CLIENT_ID as string,
);

const LDProvider = ({children}: PropsWithChildren) => {
    const {isLoggedIn, user} = useAuth();
    const {university} = useCommunity();

    function getLaunchDarklyUser() {
        if (isLoggedIn()) {
            return new User(user.id, {
                email: user?.email,
                accountRole: user.accountRole,
                university: university ? university : user.university,
            });
        }

        return new User("anonymousId", {anonymous: true});
    }

    return (
        <FeatureFlaggingProvider user={getLaunchDarklyUser()}>{children}</FeatureFlaggingProvider>
    );
};

const LHCIProvider = ({children}: PropsWithChildren) => {
    return <View flex="1">{children}</View>;
};

const Provider = ({children}: PropsWithChildren) => {
    if (process.env.LHCI) {
        return <LHCIProvider>{children}</LHCIProvider>;
    }

    return <LDProvider>{children}</LDProvider>;
};

export default Provider;
