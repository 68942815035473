import useAuth from "../Auth/hooks/useAuth";
import {UserAccountRoles} from "../constants";
import {FeatureFlags} from "../featureFlags";
import {useIsFeatureEnabledBoolean} from "../Hooks/useIsFeatureEnabledBoolean";

export const useIsAdminExperience = () => {
    const {user} = useAuth();
    const isAdminExperienceEnabled = useIsFeatureEnabledBoolean(
        FeatureFlags.ADMIN_INBOX_EXPERIENCE,
    );

    const isAdminExperience =
        isAdminExperienceEnabled && user?.accountRole === UserAccountRoles.UNIVERSITY;

    return {
        isAdminExperience,
    };
};
