import React, {useEffect, useState} from "react";
import {Pressable} from "react-native";
import {Column, Columns, Inline, Stack, Text, View, usePatronTheme} from "@unibuddy/patron";
import {useApolloClient} from "@apollo/client";
import {useErrorReporting} from "@unibuddy/error-reporting";

import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import UploadService from "ubcommunity-shared/src/Utils/UploadService/UploadService";
import WarningIcon from "ubcommunity-shared/src/Icons/WarningIcon";

import {IFile, convertToMb} from "../useAttachments";
import PdfIcon from "./PdfIcon";

type FileUploadProps = {
    item: IFile;
    onFileRemove: (item: IFile) => void;
    onUpload: (item: IFile) => void;
};

const FileUpload = ({item, onFileRemove, onUpload}: FileUploadProps) => {
    const client = useApolloClient();
    const uploadService = new UploadService(client);
    const {reportError} = useErrorReporting();
    const theme = usePatronTheme();

    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const uploadFile = async () => {
            setUploading(true);
            try {
                const response = await uploadService.uploadFileSafe(
                    uploadService.prepImageParams(item),
                );
                onUpload({...item, s3Url: response.mediaUrl + response.fileName});
            } catch (error) {
                console.error(error);
                setError(true);
                reportError(error);
                onUpload({...item, error: true});
            } finally {
                setUploading(false);
            }
        };

        if (item.error) setError(true);
        if (item.s3Url || item.error) return;

        uploadFile();
    }, [item, onUpload, reportError, uploadService]);

    return (
        <View
            position="relative"
            overflow="hidden"
            borderRadius="xsmall"
            borderWidth={1}
            borderColor={error ? "error" : "#D0D9D9"}
            padding="small"
            justifyContent="center"
            w={260}
            h={72}
            bgColor={uploading ? "groupBgColor" : error ? "errorBackground" : undefined}
        >
            <Columns space="small" verticalAlign="center">
                <Column width="content">
                    <PdfIcon />
                </Column>
                <Column>
                    <Stack space="xsmall">
                        <Columns verticalAlign="center">
                            <Column>
                                <Text
                                    truncate
                                    size="medium"
                                    {...{style: {...boldFontStyles}}}
                                    numberOfLines={1}
                                >
                                    {item.name}
                                </Text>
                            </Column>
                            <Column width="content">
                                <Pressable
                                    accessibilityLabel={`Remove ${item.name} file`}
                                    onPress={() => onFileRemove(item)}
                                >
                                    <CloseIcon size={20} color={theme.colors.lightGrey} />
                                </Pressable>
                            </Column>
                        </Columns>
                        <Columns verticalAlign="center">
                            <Column>
                                <Text color="altTextColor" size="small">
                                    {convertToMb(item.size)} MB
                                </Text>
                            </Column>
                            <Column width="content">
                                <View h={20} justifyContent="center" alignItems="center">
                                    {uploading ? (
                                        <ActivityIndicator
                                            accessibilityLabel={`Uploading ${item.name} file`}
                                            size={16}
                                        />
                                    ) : null}
                                    {!uploading && error ? (
                                        <View role="alert">
                                            <Inline verticalAlign="center" space="xxsmall">
                                                <Text size="small" color="error">
                                                    Failed
                                                </Text>
                                                <WarningIcon size={16} color={theme.colors.error} />
                                            </Inline>
                                        </View>
                                    ) : null}
                                </View>
                            </Column>
                        </Columns>
                    </Stack>
                </Column>
            </Columns>
        </View>
    );
};

export const FilesPreview = ({
    selectedFiles,
    setSelectedFiles,
}: {
    selectedFiles: IFile[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
}) => {
    const onFileRemove = (file: IFile) => {
        setSelectedFiles((prev) => prev.filter((i) => i.uri !== file.uri));
    };

    const onUpload = (file: IFile) => {
        setSelectedFiles((prev) => {
            return prev.map((i) => {
                if (i.uri === file.uri) {
                    return file;
                }
                return i;
            });
        });
    };

    if (!selectedFiles.length) return null;

    return (
        <Inline space="xsmall">
            {selectedFiles.map((item) => {
                return (
                    <FileUpload
                        key={item.uri}
                        item={item}
                        onFileRemove={onFileRemove}
                        onUpload={onUpload}
                    />
                );
            })}
        </Inline>
    );
};
