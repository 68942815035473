import React from "react";
import {Image} from "react-native";
import {Box} from "@unibuddy/patron";

export function InstitutionLogo({
    src,
    name = "Institution",
    width = 40,
    height = 40,
}: {
    src?: string | null;
    name?: string | null;
    width?: number;
    height?: number;
}) {
    return (
        // this wrapper allows us to reserve the space if the
        // logo is slow or doesn't load at all
        <Box width={width} height={height}>
            <Image
                source={{uri: src ?? "", width, height}}
                style={{
                    borderRadius: 4,
                }}
                alt={`${name} logo`}
                accessibilityLabel={`${name} logo`}
            />
        </Box>
    );
}
