import {useEffect, useState} from "react";
import {useGetAccountInformationQuery} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

const COMPLETE_USER_PROFILE_FIELDS = [
    "country",
    "profilePhoto",
    "bio",
    "areaOfStudy",
    "interests",
    "housing",
];
const ITEM_PERCENTAGE = 100 / COMPLETE_USER_PROFILE_FIELDS.length;

export const useGetUserProfile = () => {
    const [shouldUpdateCompletionProfileCounter, setShouldUpdateCompletionProfileCounter] =
        useState(false);
    const {user} = useAuth();
    const {data, refetch} = useGetAccountInformationQuery({
        fetchPolicy: "cache-first",
    });

    const userProfile = {
        ...user,
        profilePhoto: data?.getUserProfile.profilePhoto,
        bio: data?.getUserProfile.bio,
        areaOfStudy: data?.getUserProfile.areaOfStudy,
        interests: data?.getInterestsForUser, // To avoid using the interest from getUserProfile we will initialise with the data from getInterestsForUser
        housing: data?.getUserProfile.housing?.category,
    };

    useEffect(() => {
        if (shouldUpdateCompletionProfileCounter) {
            refetch();
            setShouldUpdateCompletionProfileCounter(false);
        }
    }, [shouldUpdateCompletionProfileCounter]);

    const profileCompletion = () => {
        let percentageOfCompletion = 0;

        COMPLETE_USER_PROFILE_FIELDS.forEach((item) => {
            if (Array.isArray(userProfile[item]) && userProfile[item]?.length > 0) {
                percentageOfCompletion += ITEM_PERCENTAGE;
            }
            if (!Array.isArray(userProfile[item]) && userProfile[item]) {
                percentageOfCompletion += ITEM_PERCENTAGE;
            }
        });

        return Math.round(percentageOfCompletion);
    };

    return {userProfile, profileCompletion, setShouldUpdateCompletionProfileCounter};
};
