export const WEB_SCALE_FACTOR = 0.2;
export const WEB_ROTATION_FACTOR = 90;
export const IMAGE_UPLOAD_SIZE_MAX = 10485760; // 10MB
export const ALLOWED_IMAGE_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
export const PROFILE_AVATAR_SIZE = 208; // accounts settings page
export const PROFILE_AVATAR_SIZE_LARGE = 264;
export const CROPPER_SIZE = 250;
export const AVATAR_DISCLAIMER =
    "Upload a photo to let other students know a bit more about who you are!";

export const validateImage = (image) => {
    const isValidImageSize = image.size <= IMAGE_UPLOAD_SIZE_MAX;
    const isValidImageFormat = ALLOWED_IMAGE_FORMATS.includes(image.type || image.mime);
    const isValid = isValidImageSize && isValidImageFormat;
    return isValid;
};
