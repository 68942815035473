import {AccountRoles} from "../constants";

const accountRoleMapping = {
    applicant: AccountRoles.STUDENT,
    mentor: AccountRoles.AMBASSADOR,
    staff: AccountRoles.STAFF,
    university: AccountRoles.UNIVERSITY_ADMIN,
    admin: AccountRoles.UNIBUDDY_ADMIN,
};

export function getAccountRoleName(accountRole?: string) {
    if (!accountRole) return;
    return accountRoleMapping[accountRole];
}
