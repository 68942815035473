import React from "react";

export const usePageTitle = (title?: string) => {
    const appName = "Unibuddy";

    React.useEffect(() => {
        if (!title || !title.length) document.title = appName;
        else document.title = `${appName} - ${title}`;
    }, [title]);

    return null;
};
