import React from "react";
import {Platform} from "react-native";
import {Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {
    Tile,
    TileMedia,
    TileHeader,
    TileAvatar,
    TileInfo,
    TileBody,
    TileActions,
    TileDescription,
    MediaType,
} from "@unibuddy/content-ui";
import {BlogPostField} from "ubcommunity-shared/src/types";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {ASSETS_URL_V2, COMMUNITY_APP_URL, TrackEvents} from "ubcommunity-shared/src/constants";
import {ContentViewMoreSvg} from "./ContentViewMoreSvg";

const TILE_WIDTH = 288;
const TILE_HEIGHT = 412;

const removeHtmlTags = (string: string) => string.replaceAll(/<\/?[^>]+(>|$)/gi, "");

export const ContentCards = ({post}: {post: BlogPostField}) => {
    const [navigate] = useNavigate();
    const {university} = useCommunity();
    const {trackEvent} = useAnalytics();

    const isVideo = !!post.videoContent;
    const isYoutube = !!post.youtubeVideo;

    const handleClick = () => {
        trackEvent(TrackEvents.CONTENT_VIEW_POST_CLICKED, {
            type: isVideo ? MediaType.VIDEO : MediaType.IMAGE,
            topic: post.topic.name,
        });
        navigate({
            path: Routes.CONTENT_VIEW,
            options: {
                url: Platform.select({
                    web: `${COMMUNITY_APP_URL}/u/${university.slug}/blog/post/${post.id}`,
                    native: `${COMMUNITY_APP_URL}/u/${university.slug}/native/blog/post/${post.id}`,
                }),
                screen: `post/${post.id}`,
            },
        });
    };

    if (isVideo) {
        return <VideoCard post={post} handleClick={handleClick} />;
    }

    if (isYoutube) {
        return <YoutubeCard post={post} handleClick={handleClick} />;
    }

    return <ImageCard post={post} handleClick={handleClick} />;
};

type CardProps = {
    post: BlogPostField;
    handleClick: () => void;
    mediaType?: MediaType;
};

const ImageCard = ({post, handleClick}: CardProps) => {
    const {darkModeEnabled} = useCommunityTheme();

    const media =
        post.coverImages.length > 1
            ? post.coverImages.map((image) => `${ASSETS_URL_V2}/${image}`)
            : `${ASSETS_URL_V2}/${post.coverImages[0]}`;

    return (
        <Tile
            width={TILE_WIDTH}
            height={TILE_HEIGHT}
            borderColor={darkModeEnabled ? "grey600" : "grey150"}
        >
            <TileMedia autoPlay={false} mediaType={MediaType.IMAGE} media={media}>
                <TileHeader>
                    <TileAvatar alt="Image" url={post.author.profilePhoto} />
                    <TileInfo
                        name={post.author.firstName}
                        country={{
                            code: post.author.country?.code ?? "",
                            name: post.author.country?.name,
                        }}
                        areaOfStudy={post.author.degree?.name}
                    />
                </TileHeader>
            </TileMedia>
            <TileBody bgColor={darkModeEnabled ? "body" : "white"}>
                <TileDescription
                    title={
                        <Text
                            color={darkModeEnabled ? "white" : "black"}
                            style={{
                                ...boldFontStyles,
                            }}
                            truncate={2}
                            numberOfLines={2}
                        >
                            {post.title}
                        </Text>
                    }
                    description={
                        <Text
                            color={darkModeEnabled ? "textLinkColorInactive" : "black"}
                            truncate={3}
                            numberOfLines={3}
                        >
                            {removeHtmlTags(post.body)}
                        </Text>
                    }
                />
                <TileActions callback={handleClick} theme={darkModeEnabled ? "light" : "dark"}>
                    <Text color={darkModeEnabled ? "black" : "white"}>View Post</Text>
                </TileActions>
            </TileBody>
        </Tile>
    );
};

const YoutubeCard = ({post, handleClick}: CardProps) => {
    const youtube = post.youtubeVideo;

    const newPost = {
        ...post,
        videoContent: {
            ...post.videoContent,
            url: {
                url: youtube,
            },
        },
    };

    return <VideoCard post={newPost} handleClick={handleClick} mediaType={MediaType.YOUTUBE} />;
};

const VideoCard = ({post, handleClick, mediaType = MediaType.VIDEO}: CardProps) => {
    const media = post.videoContent.url.url;
    const {darkModeEnabled} = useCommunityTheme();

    return (
        <Tile
            width={TILE_WIDTH}
            height={TILE_HEIGHT}
            borderColor={darkModeEnabled ? "grey600" : "grey150"}
        >
            <TileMedia mediaType={mediaType} media={media} autoPlay={false}>
                <TileHeader>
                    <TileAvatar alt="Image" url={post.author.profilePhoto} />
                    <TileInfo
                        name={post.author.firstName}
                        country={{
                            code: post.author.country?.code ?? "",
                            name: post.author.country?.name,
                        }}
                        areaOfStudy={post.author.degree?.name}
                    />
                </TileHeader>
                <TileBody justifyContent="flex-end" bgColor="transparent">
                    <TileDescription
                        title={
                            <Text
                                color="white"
                                style={{
                                    ...boldFontStyles,
                                }}
                                truncate={2}
                                numberOfLines={2}
                            >
                                {post.title}
                            </Text>
                        }
                        description={
                            <Text color="white" truncate={3} numberOfLines={3}>
                                {removeHtmlTags(post.body)}
                            </Text>
                        }
                    />
                    <TileActions callback={handleClick} theme="light">
                        <Text color="black">View Post</Text>
                    </TileActions>
                </TileBody>
            </TileMedia>
        </Tile>
    );
};

export const ContentViewMoreCard = () => {
    const {darkModeEnabled} = useCommunityTheme();
    const {university} = useCommunity();
    const [navigate] = useNavigate();
    const {trackEvent} = useAnalytics();

    const handleViewMore = () => {
        trackEvent(TrackEvents.CONTENT_VIEW_ALL_CLICKED, {
            type: "Tile Link",
        });
        navigate({
            path: Routes.CONTENT_VIEW,
            options: {
                url: Platform.select({
                    web: `${COMMUNITY_APP_URL}/u/${university.slug}/blog`,
                    native: `${COMMUNITY_APP_URL}/u/${university.slug}/native/blog`,
                }),
            },
        });
    };

    return (
        <Tile
            width={TILE_WIDTH}
            height={TILE_HEIGHT}
            borderColor={darkModeEnabled ? "grey600" : "grey150"}
        >
            <View flex="1" bgColor={darkModeEnabled ? "body" : "white"}>
                <View flex="1" justifyContent="center" alignItems="center">
                    <ContentViewMoreSvg />
                </View>
                <View>
                    <TileActions
                        callback={handleViewMore}
                        theme={darkModeEnabled ? "light" : "dark"}
                    >
                        <Text color={darkModeEnabled ? "black" : "white"}>View all</Text>
                    </TileActions>
                </View>
            </View>
        </Tile>
    );
};
