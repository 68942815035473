import {Box, Text} from "@unibuddy/patron";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export const SectionHeading = ({children}) => (
    <Box paddingX="medium" paddingTop="medium-large" style={{userSelect: "none"}}>
        <Text size="medium" style={{...boldFontStyles}}>
            {children}
        </Text>
    </Box>
);
