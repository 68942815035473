import {useEffect, useState} from "react";

export function useIsTouchDevice() {
    const [media] = useState(() => window.matchMedia("(hover: hover)"));
    const [isTouchDevice, setIsTouchDevice] = useState(!media.matches);
    useEffect(() => {
        const listener = () => {
            setIsTouchDevice(!media.matches);
        };
        media.addListener(listener);
        return () => {
            media.removeListener(listener);
        };
    }, [media]);

    return {isTouchDevice};
}
