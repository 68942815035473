import useAuth from "./useAuth";
import {authMeSelector} from "./selectors";

/**
 * The purpose of this hook is to provide the id of the user
 *
 * @export
 * @returns the id of the user
 */
export default function useMyId() {
    const auth = useAuth();
    const me = authMeSelector(auth);

    return me.anyUser.id;
}
