import {Inline, Text, usePatronTheme, colors} from "@unibuddy/patron";
import styled from "styled-components";
import {
    OptionFormGroup,
    OptionItem,
    useOption,
    OptionLabel,
} from "ubcommunity-shared/src/Patron/OptionGroup/OptionGroup";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {Interest} from "ubcommunity-shared/src/types";

const StyledChip = styled(OptionLabel)`
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-radius: 999px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    pointer-events: ${({isEditing}) => {
        return isEditing ? "auto" : "none";
    }};
    background: ${({isSelected, isEditing, darkModeEnabled, theme}) => {
        if (!isEditing) return theme.TextInput.backgroundColor;
        if (isSelected) return darkModeEnabled ? theme.colors.textLinkColor : colors.teal200;
        return darkModeEnabled ? theme.colors.calypso : "white";
    }};
    border-width: ${({isFocused}) => {
        if (isFocused) return "2px";
        return "1px";
    }};
    border-color: ${({isSelected, isFocused, darkModeEnabled, theme}) => {
        if (isFocused) return theme.colors.primaryColor;
        if (isSelected) return "transparent";
        return darkModeEnabled ? theme.colors.lightGrey : theme.colors.darkGrey;
    }};
    color: ${({isSelected, theme}) => {
        return isSelected ? theme.colors.black : theme.TextBlock.color;
    }};
    padding: ${({theme}) => {
        return `${theme.space.small} ${theme.space.small}`;
    }};
    font-size: ${({theme}) => theme.TextBlock.sizes.small.fontSize}px;
    &:hover {
        background: ${({theme, isSelected}) => {
            return isSelected ? colors.teal400 : theme.TextInput.backgroundColor;
        }};
    }
`;

const Option = ({children, isEditing, ...rest}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();
    const {isSelected, isFocused} = useOption();

    let textColor = "black";

    if (darkModeEnabled && (!isSelected || !isEditing)) {
        textColor = theme.colors.placeHolderColor;
    }

    return (
        <StyledChip
            darkModeEnabled={darkModeEnabled}
            isSelected={isSelected}
            isFocused={isFocused}
            isEditing={isEditing}
            {...rest}
        >
            <Text color={textColor}>{children}</Text>
        </StyledChip>
    );
};

type Props = {
    label: string;
    allInterests: Interest[];
    isEditing: boolean;
};

export const InterestsFormGroup = ({label, allInterests, isEditing}: Props) => {
    return (
        <OptionFormGroup label={label} name="interests" multiple>
            <Inline space="xsmall">
                {allInterests?.map((value) => (
                    <OptionItem key={value.id} value={value.id}>
                        <Option isEditing={isEditing}>{value.name}</Option>
                    </OptionItem>
                ))}
            </Inline>
        </OptionFormGroup>
    );
};
