import * as React from "react";
const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
        <path
            stroke={props.color ?? "#000"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17.25 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75M7.5 21.75H18a2.25 2.25 0 0 0 2.25-2.25v-6.75A2.25 2.25 0 0 0 18 10.5H7.5a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
        />
    </svg>
);
export default SvgComponent;
