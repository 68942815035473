import * as React from "react";

const ExitIcon = (props) => {
    const {color = "currentColor", size = 24, ...rest} = props;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M19.0515 16.9757C18.8916 16.9761 18.7353 16.9291 18.6021 16.8407C18.4689 16.7523 18.3649 16.6264 18.3032 16.479C18.2415 16.3315 18.2249 16.1691 18.2554 16.0122C18.2859 15.8553 18.3622 15.711 18.4747 15.5974L21.0455 12.993L18.4801 10.4427C18.4025 10.3681 18.3406 10.2789 18.2979 10.1802C18.2553 10.0814 18.2327 9.97517 18.2315 9.86761C18.2303 9.76006 18.2506 9.65334 18.2911 9.55369C18.3316 9.45405 18.3915 9.36346 18.4674 9.28722C18.5433 9.21098 18.6336 9.15062 18.733 9.10965C18.8325 9.06868 18.9391 9.04793 19.0467 9.0486C19.1542 9.04927 19.2606 9.07136 19.3595 9.11357C19.4584 9.15578 19.548 9.21727 19.6229 9.29445L22.7609 12.4162C22.912 12.5682 22.9968 12.7738 22.9968 12.9882C22.9968 13.2025 22.912 13.4081 22.7609 13.5601L19.6283 16.7381C19.5526 16.8138 19.4627 16.8738 19.3637 16.9146C19.2647 16.9554 19.1586 16.9762 19.0515 16.9757V16.9757Z"
                fill={color}
            />
            <path
                d="M22.1897 13.7975L10.7516 13.7975C10.5367 13.7975 10.3306 13.7122 10.1787 13.5603C10.0268 13.4083 9.94141 13.2023 9.94141 12.9874C9.94141 12.7725 10.0268 12.5665 10.1787 12.4145C10.3306 12.2626 10.5367 12.1772 10.7516 12.1772L22.1897 12.1772C22.4046 12.1772 22.6107 12.2626 22.7626 12.4145C22.9145 12.5665 22.9999 12.7725 22.9999 12.9874C22.9999 13.2023 22.9145 13.4083 22.7626 13.5602C22.6107 13.7122 22.4046 13.7975 22.1897 13.7975Z"
                fill={color}
            />
            <path
                d="M7.97754 2.99995L12.06 2.99995C13.3811 3.00138 14.6477 3.52682 15.5819 4.46099C16.5161 5.39516 17.0415 6.66177 17.043 7.98288L17.043 9.7328C17.043 9.94766 16.9576 10.1537 16.8057 10.3057C16.6537 10.4576 16.4477 10.5429 16.2328 10.5429C16.018 10.5429 15.8119 10.4576 15.66 10.3057C15.508 10.1537 15.4227 9.94766 15.4227 9.7328L15.4227 7.98288C15.4218 7.09132 15.0673 6.23651 14.4368 5.60608C13.8064 4.97565 12.9516 4.6211 12.06 4.62024L7.97754 4.62024C7.08691 4.62253 6.23349 4.97771 5.60423 5.60799C4.97496 6.23826 4.62115 7.09225 4.62029 7.98288L4.62029 17.3946C4.62115 18.2851 4.975 19.139 5.60429 19.7691C6.23359 20.3992 7.08701 20.7542 7.97754 20.7562L12.0516 20.7562C12.943 20.7553 13.7976 20.4009 14.428 19.7707C15.0584 19.1405 15.4131 18.286 15.4142 17.3946L15.4142 15.7322C15.4142 15.5173 15.4996 15.3113 15.6515 15.1593C15.8035 15.0074 16.0095 14.922 16.2244 14.922C16.4392 14.922 16.6453 15.0074 16.7972 15.1593C16.9492 15.3113 17.0345 15.5173 17.0345 15.7322L17.0345 17.3946C17.0328 18.7155 16.5072 19.9819 15.5731 20.9158C14.639 21.8497 13.3725 22.375 12.0516 22.3765L7.97754 22.3765C6.65746 22.3739 5.39224 21.8481 4.45921 20.9142C3.52617 19.9804 3.00143 18.7147 3 17.3946L3 7.98288C3.00143 6.6627 3.52613 5.39692 4.45914 4.4629C5.39214 3.52888 6.65736 3.00281 7.97754 2.99995Z"
                fill={color}
            />
        </svg>
    );
};

export default ExitIcon;
