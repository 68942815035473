import React, { forwardRef } from "react";
import styled from "styled-components";
import { View } from "@unibuddy/patron";
import { CarouselProps } from "./types";

const CarouselContainer = styled(View)`
  margin: 0;
  width: 100%;
`;
const CarouselView = styled(View)`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const CarouselListContainer = styled(View)`
  display: flex;
  flex-direction: row;
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

type Ref = HTMLElement;
export const CarouselRaw = forwardRef<Ref, CarouselProps>(function CarouselRaw(
  {
    data,
    renderFirstItem,
    renderItem,
    renderLastItem,
    onScroll,
    itemGap,
    offset,
  },
  ref,
): JSX.Element {
  if (!data) {
    throw new Error("data is required");
  }
  if (!renderItem) {
    throw new Error("renderItem is required");
  }

  return (
    <CarouselContainer>
      <CarouselView>
        <CarouselListContainer
          onScroll={onScroll}
          gap={itemGap || "xsmall"}
          paddingX={offset}
          ref={ref}
          role="list"
        >
          {renderFirstItem ? renderFirstItem() : null}
          {data.map((item) => renderItem(item))}
          {renderLastItem ? renderLastItem() : null}
        </CarouselListContainer>
      </CarouselView>
    </CarouselContainer>
  );
});
