import React, {FC, useState} from "react";
import {Image} from "react-native";
import {View} from "@unibuddy/patron";

import {UserProfile, useGetUserProfileQuery} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {getDynamicImageUrlV2} from "ubcommunity-shared/src/Patron/getDynamicUrlV2";

import AvatarBeam from "../AvatarBeam/AvatarBeam";

function getApplicantImageSrc(imageUrl) {
    let profilePhoto = "";
    if (typeof imageUrl === "string") {
        const urlParts = imageUrl.split("/");
        profilePhoto = urlParts[urlParts.length - 1];
    }
    return profilePhoto;
}

export const ApplicantImage = ({url, size, id, firstName, ...props}) => {
    const profilePhoto = getApplicantImageSrc(url);
    const [showAvatarBeam, setShowAvatarBeam] = useState(true);

    // TODO: add some loading state
    // currently the downtime on the lambda parsing the request and sending
    // an appropriate sized image is about 2-3 seconds
    // this holds true for avatars of different sizes in the app which
    // leads to a lot of white images when the image is being processed
    const uri = getDynamicImageUrlV2(profilePhoto, {
        width: size,
        height: size,
    });

    return (
        <View position="relative">
            {showAvatarBeam ? (
                <AvatarBeam
                    style={{position: "absolute", zIndex: 1}}
                    id={id}
                    name={firstName}
                    size={size}
                />
            ) : null}
            <Image
                {...props}
                width={size}
                height={size}
                style={{...props.style, width: size, height: size, backgroundColor: "#eee"}}
                aria-label={firstName}
                accessibilityLabel={firstName}
                source={{
                    width: size,
                    height: size,
                    uri,
                }}
                onLoad={() => setShowAvatarBeam(false)}
                onError={() => setShowAvatarBeam(true)}
            />
        </View>
    );
};

// we only ever use this component for the user using the app
// this is because we don't have "communityProfile" anywhere in the client
// and this needs to be requested
export const ApplicantMeAvatar: FC<{
    user: {firstName?: string; profilePhoto?: string; id?: string};
    size: number;
    [key: string]: any;
}> = ({user, size, ...props}) => {
    const {data, loading} = useGetUserProfileQuery({
        fetchPolicy: "cache-first",
    });

    if (!data) return <AvatarBeam id={user.id} name={user.firstName} size={size} />;

    if (loading || !data.getUserProfile?.profilePhoto)
        return <AvatarBeam id={user.id} name={user.firstName} size={size} />;

    return (
        <ApplicantImage
            id={user.id}
            url={data.getUserProfile.profilePhoto}
            size={size}
            firstName={user.firstName}
            {...props}
        />
    );
};

export const ApplicantAvatar: FC<{
    user: {
        firstName?: string;
        profilePhoto?: string;
        id?: string;
        communityProfile?: UserProfile;
    };
    size: number;
    [key: string]: any;
}> = ({user, size, ...props}) => {
    const {user: meUser} = useAuth();

    // if a profile photo exists return it
    if (user.communityProfile?.profilePhoto)
        return (
            <ApplicantImage
                id={user.id}
                url={user.communityProfile?.profilePhoto}
                size={size}
                firstName={user.firstName}
                {...props}
            />
        );

    // if the request is made by the user using the app, we need to make a network request to fetch it
    if (user.id === meUser?.id) return <ApplicantMeAvatar user={meUser} size={size} {...props} />;

    // return default avatar if no profile photo exists
    return <AvatarBeam id={user.id} name={user.firstName} size={size} />;
};
