import React from "react";
import {Image, TouchableOpacity} from "react-native";
import {View} from "@unibuddy/patron";

import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";

export const ImagesPreviewItem = ({item, onImageRemove}: any) => {
    return (
        <TouchableOpacity accessibilityLabel={item.name} onPress={() => onImageRemove(item)}>
            <View position="relative" borderRadius="xxsmall" overflow="hidden">
                <View position="absolute" top={4} right={4} zIndex={2}>
                    <CloseIcon width={20} height={20} color="white" />
                </View>
                <View
                    w="100%"
                    h="100%"
                    position="absolute"
                    backgroundColor="#222"
                    zIndex={1}
                    opacity={0.3}
                />
                <Image
                    source={{
                        uri: item.uri,
                        height: 100,
                        width: 100,
                    }}
                />
            </View>
        </TouchableOpacity>
    );
};
