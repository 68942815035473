import {ComponentType, useState} from "react";
import styled from "styled-components";
import {View, Inline, Button, Text} from "@unibuddy/patron";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {useMediaQuery} from "ubcommunity-shared/src/Hooks/useMediaQuery";
import {MediaQueries} from "ubcommunity-shared/src/constants";

export type Tab = {
    label: string;
    icon: React.FC;
    component: ComponentType<any>;
    onTabChange?: () => void;
};

const StyledTab = styled(Button)<{active: boolean; matches: boolean}>`
    width: 100%;
    height: ${({theme, matches}) => (matches ? theme?.Button?.heights?.default : "30px")};
    border-radius: 0;
    border-bottom: 2px solid
        ${({theme, active}) => (active ? theme?.colors?.primaryColor : "transparent")};
    color: ${({theme, active}) => (active ? theme?.colors?.primaryColor : undefined)};
`;

export function TabNav({tabs}: {tabs: Tab[]}) {
    const [index, setIndex] = useState<number>(0);
    const {isDesktop} = useIsDesktop();
    const matches = useMediaQuery(MediaQueries.HEIGHT_GREATER_THAN_420PX);

    const ActiveComponent = tabs[index].component;

    return (
        <View flex="1" flexDirection="column">
            <View alignItems="center" borderBottomWidth={1} borderColor="grey100" flexShrink="0">
                <View role="tablist" width={isDesktop ? "30%" : "100%"} flexDirection="row">
                    {tabs.map((tab, i) => {
                        const Icon = tab.icon;
                        const isActive = index === i;
                        return (
                            <StyledTab
                                key={tab.label}
                                clear
                                role="tab"
                                active={isActive}
                                onClick={() => {
                                    setIndex(i);
                                    tab.onTabChange?.();
                                }}
                                aria-selected={isActive}
                                matches={matches}
                            >
                                <Inline verticalAlign="center" wrap="nowrap">
                                    {matches ? <Icon /> : null}
                                    <Text
                                        style={isActive ? boldFontStyles : undefined}
                                        color={isActive ? "primaryColor" : "textColor"}
                                    >
                                        {tab.label}
                                    </Text>
                                </Inline>
                            </StyledTab>
                        );
                    })}
                </View>
            </View>

            <ActiveComponent />
        </View>
    );
}
