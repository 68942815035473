import React, {useEffect, useRef, useState} from "react";
import capitalize from "lodash/capitalize";
import debounce from "lodash/debounce";
import {GiphyFetch} from "@giphy/js-fetch-api";
import {Grid} from "@giphy/react-components";
import {Box, CloseButton, FormField, TextInput, useMeasureDOM, View} from "@unibuddy/patron";
import {apiKey} from "./utils";

const gf = new GiphyFetch(apiKey);

const focusableElementsQuery = `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`;

export const Gifs = ({onSelect, isOpen, onDismiss}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isAnimated, setIsAnimated] = useState(false);

    const debouncedSearch = debounce(setSearchTerm, 500);

    const inputRef = useRef<HTMLInputElement & {value: string}>(null);
    const currentActiveElementRef = useRef<HTMLElement | null>(null);

    const modalId = "gif-search";

    // TODO: move this implementation to a generic hook in patron
    useEffect(() => {
        const getFocusableElements = (ref: HTMLElement) => {
            const focusableElements = ref.querySelectorAll(focusableElementsQuery);
            return focusableElements;
        };

        if (isOpen) {
            const modal = document.querySelector(`#${modalId}`) as HTMLElement;
            currentActiveElementRef.current = document.activeElement as HTMLElement;

            if (isAnimated) {
                inputRef.current?.focus();
            }

            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Tab") {
                    const focusableElements = getFocusableElements(modal);
                    const firstFocusable = focusableElements[0] as HTMLElement;
                    const lastFocusable = focusableElements[
                        focusableElements.length - 1
                    ] as HTMLElement;

                    if (event.shiftKey) {
                        // shift + tab moves the focus to the previous element
                        if (document.activeElement === firstFocusable) {
                            // if the focus is on the first element, move it to the last
                            event.preventDefault();
                            lastFocusable.focus();
                        }
                    } else if (document.activeElement === lastFocusable) {
                        // if at last element, move focus to first element
                        event.preventDefault();
                        firstFocusable.focus();
                    }
                }

                if (event.key === "Escape") {
                    if (typeof onDismiss === "function") {
                        onDismiss();
                    }
                }
            };

            document.addEventListener("keydown", handleKeyDown);

            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }

        if (!isOpen && currentActiveElementRef?.current) {
            currentActiveElementRef.current.focus();
        }
    }, [isOpen, isAnimated, onDismiss]);

    const onChange = (e) => {
        debouncedSearch(e.target.value);
    };
    const fetchGifs = (offset) => {
        if (!searchTerm) return gf.trending({rating: "pg-13", offset});
        return gf.search(searchTerm, {rating: "pg-13", offset});
    };

    return (
        <View
            id={modalId}
            role="dialog"
            aria-label="Gif search modal"
            aria-modal="true"
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            bgColor="white"
            // on android the keyboard squishes the view so we need to make sure that the gif drawer
            // does not grow larger that the available screen space minus the height of the header (60px)
            maxH="calc(100vh - 60px)"
            zIndex={10}
            transition=".3s ease transform"
            transform={isOpen ? "none" : "translate3d(0, 100%, 0)"}
            paddingTop="large"
            paddingX="small"
            brt="small"
            onTransitionEnd={() => setIsAnimated(true)}
        >
            <CloseButton onClick={onDismiss} style={{top: 5, right: 5}} />
            <Box role="search" paddingBottom="medium">
                <FormField label="Search gifs">
                    <TextInput ref={inputRef} type="search" onChange={onChange} />
                </FormField>
            </Box>
            {/** 
                key will recreate the component, 
                this is important for when you change fetchGifs 
                e.g. changing from search term dogs to cats or type gifs to stickers
                you want to restart the gifs from the beginning and changing a component's key does that 
            **/}
            <GifGrid
                key={searchTerm}
                fetchGifs={fetchGifs}
                onGifClick={(gif) => {
                    onDismiss();
                    onSelect({
                        id: gif.id,
                        type: capitalize(gif.type),
                        width: gif.images.original.width,
                        height: gif.images.original.height,
                        aspectRatio: gif.images.original.width / gif.images.original.height,
                    });
                }}
            />
        </View>
    );
};

export const GifGrid = ({fetchGifs, onGifClick}) => {
    const ref = useRef();
    const [width, setWidth] = useState(-1);
    useMeasureDOM(ref, (e) => {
        setWidth(e.width);
    });

    return (
        <Box ref={ref} h={400} overflow="auto">
            {width > -1 ? (
                <Grid
                    noLink
                    onGifClick={onGifClick}
                    overlay={null}
                    width={width}
                    columns={3}
                    fetchGifs={fetchGifs}
                />
            ) : null}
        </Box>
    );
};
