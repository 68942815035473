import React from "react";
import {Image} from "react-native";
import {boolean} from "yup";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";
import {useApolloClient} from "@apollo/client";
import {Box, CheckBox, Heading, Stack, Text, usePatronTheme, View} from "@unibuddy/patron";
import Form from "ubcommunity-shared/src/Forms/Form/Form";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {Link} from "ubcommunity-shared/src/General/Link/Link";
import {useSpaces} from "ubcommunity-shared/src/StudentApp/SpacesProvider";
import {
    Community,
    CommunityFragmentDoc,
    useGetSpacesQuery,
    useJoinCommunityMutation,
    useUpdateApplicantSignupSourceMutation,
} from "ubcommunity-shared/src/types";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";

export const ConfirmInvite = () => {
    const client = useApolloClient();
    const theme = usePatronTheme();
    const {user} = useAuth();
    const {trackEvent} = useAnalytics();
    const {selectedSpace, selectCommunitySpace} = useSpaces();
    const {open} = usePrompt();
    const {reportError} = useErrorReporting();

    const communityId = selectedSpace?.id;
    const community = client.readFragment<Community>({
        id: `Community:${communityId}`,
        fragment: CommunityFragmentDoc,
        fragmentName: "Community",
    });

    const {updateQuery} = useGetSpacesQuery({
        variables: {
            email: user?.email ?? "",
            userId: user?.id ?? "",
        },
    });

    const [joinCommunity] = useJoinCommunityMutation({
        update: (_, {data}) => {
            if (!data?.joinCommunity?.joined) return;
            updateQuery((cacheData) => {
                if (!cacheData?.getInvitesForEmail) return cacheData;
                if (!community) return cacheData;
                const updatedInvites = cacheData.getInvitesForEmail.filter(
                    (invite) => invite.community.id !== communityId,
                );
                const updatedCommunities = [...(cacheData.getUser?.communities ?? []), community];

                return {
                    ...cacheData,
                    getInvitesForEmail: updatedInvites,
                    getUser: {
                        ...cacheData.getUser,
                        communities: updatedCommunities,
                    },
                };
            });
        },
    });
    const [updateApplicantSignupSourceMutation] = useUpdateApplicantSignupSourceMutation();

    const university = community?.institutions?.length ? community?.institutions[0] : null;

    const handleSubmit = async (
        _: any,
        {
            setSubmitting,
        }: {
            setSubmitting: (submitting: boolean) => void;
        },
    ) => {
        try {
            const result = await joinCommunity({
                variables: {communityId: community?.id ?? ""},
            });
            const {data} = result;

            if (user?.accountRole === "applicant") {
                await updateApplicantSignupSourceMutation({
                    variables: {communityId: community?.id ?? ""},
                });
            }

            if (data?.joinCommunity?.joined) {
                trackEvent(TrackEvents.COMMUNITY_JOINED, {communityId: community?.id});
                selectCommunitySpace(communityId ?? "");
            } else {
                open({
                    title: "Error",
                    message: "There was an error joining the community. Please try again.",
                    buttons: [{text: "Ok"}],
                });
                reportError(new Error("User was not able to join the community"));
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            reportError(error);
            open({
                title: "Error",
                message: "There was an error joining the community. Please try again.",
                buttons: [{text: "Ok"}],
            });
        }
    };

    return (
        <Form
            footer={
                <SubmitButton color="primary" block>
                    <Text style={{...boldFontStyles}} size="large" color="white">
                        Join now
                    </Text>
                </SubmitButton>
            }
            onSubmit={handleSubmit}
            initialValues={{confirmPrivacy: false}}
            validationSchema={{
                confirmPrivacy: boolean()
                    .required()
                    .oneOf([true], "Privacy policy must be checked"),
            }}
        >
            <View paddingX="large" paddingTop="large" paddingBottom="small">
                <Stack space="medium" align="center">
                    <Image
                        source={{
                            width: 100,
                            height: 100,
                            uri: university?.squareLogo ?? "",
                        }}
                        style={{borderRadius: 6}}
                    />
                    <Heading level="1" size="xxsmall" weight="700" align="center">
                        {university?.name}
                    </Heading>
                    <Text size="medium" align="center">
                        Welcome to {community?.title}
                    </Text>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CheckBox name="confirmPrivacy">
                            I agree to the institution's{" "}
                            <Link
                                external
                                href={university?.privacyPolicyUrl}
                                style={{
                                    textDecorationLine: "underline",
                                    textDecorationColor: theme?.colors?.textLinkColor,
                                }}
                            >
                                <Text color="textLinkColor">Privacy Policy</Text>
                            </Link>
                        </CheckBox>
                    </Box>
                </Stack>
            </View>
        </Form>
    );
};
