import React, {useState} from "react";
import styled from "styled-components";
import {Box, Stack, Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {IconUbInbox, IconUbHome, IconBuildingLibrary, IconCheckCircle} from "@unibuddy/icons";

import {Routes} from "ubcommunity-shared/src/constants/routes";
import RelativeNavLink from "ubcommunity-shared/src/Navigation/RelativeNavLink/RelativeNavLink";
import {MediaQueries, TrackEvents} from "ubcommunity-shared/src/constants";
import {BottomTabUserAvatar} from "ubcommunity-shared/src/Community/BottomTabUserAvatar/BottomTabUserAvatar";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {useShowDiscoverTab} from "ubcommunity-shared/src/Discover/useShowDiscoverTab";
import {useTodos} from "ubcommunity-shared/src/Community/Todos/useTodos";
import {useMediaQuery} from "ubcommunity-shared/src/Hooks/useMediaQuery";

const StyledRelativeNavLink = styled(RelativeNavLink)`
    flex-shrink: 0;
    text-decoration: none;
    color: #757575;
    transition: 0.2s ease-in-out;
    margin: 0 8px 0 8px;

    &.active {
        color: ${({theme}) => theme.colors?.primaryColor};
        font-weight: bold;
    }

    // mobile
    @media only screen and (max-width: 768px) {
        width: 100;
        border-top: 2px solid rgba(0, 0, 0, 0);
        &.active {
            color: ${({theme}) => theme.colors?.primaryColor};
            border-top: 2px solid ${({theme}) => theme.colors?.primaryColor};
        }
    }

    // desktop
    @media only screen and (min-width: 768px) {
        width: 78px;
        margin-top: 12px;
        border-radius: 6px;
        &.active {
            background-color: rgba(104, 82, 251, 0.1);
        }
    }
`;

const StudentTabs = {
    home: {
        key: "HOME",
        text: "Home",
        icon: <IconUbHome />,
    },
    chat: {
        key: "CHAT",
        text: "Inbox",
        icon: <IconUbInbox />,
    },
    discover: {
        key: "DISCOVER",
        text: "Discover",
        icon: <IconBuildingLibrary />,
    },
    todo: {
        key: "TODO",
        text: "To-Do",
        icon: <IconCheckCircle />,
    },
    settings: {
        key: "SETTINGS",
        text: "Profile",
        icon: <BottomTabUserAvatar isActive={false} />,
    },
};

type NavButtonProps = {
    tab: {
        key: string;
        text: string;
        icon: React.ReactElement;
    };
    routeMapper: Record<string, string>;
    onClick?: (param: string) => void;
    activeTab?: string;
};

const NavButton = ({tab, routeMapper, onClick, activeTab}: NavButtonProps) => {
    const {trackEvent} = useAnalytics();
    const matches = useMediaQuery(MediaQueries.HEIGHT_GREATER_THAN_420PX);

    const handleClick = () => {
        onClick && onClick(tab.key);
        trackEvent(TrackEvents.TAB_PRESSED, {
            tab: tab.text,
        });
    };
    const isSettingsActive = activeTab === StudentTabs.settings.key;

    return (
        <StyledRelativeNavLink key={tab.key} to={routeMapper[tab.key]} onClick={handleClick}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="xsmall"
            >
                <Stack align="center" space="xxsmall">
                    {matches ? (
                        <>
                            {tab.key === "SETTINGS"
                                ? // Profile tab Icon (BottomTabUserAvatar) is accepting isActive prop to toggle the active state
                                  React.cloneElement(tab.icon, {
                                      isActive: isSettingsActive,
                                  })
                                : tab.icon}
                        </>
                    ) : null}
                    <Text size="small" color="inherit">
                        {tab.text}
                    </Text>
                </Stack>
            </Box>
        </StyledRelativeNavLink>
    );
};

const StudentNavigationTabs = () => {
    const [selectedTab, setSelectedTab] = useState<string>();
    const {isDesktop} = useIsDesktop();
    const {showDiscoverTab} = useShowDiscoverTab();
    const {showTab: shouldRenderTodo} = useTodos();

    const shouldShowDiscover = showDiscoverTab;

    const content = [StudentTabs.home, StudentTabs.chat];
    if (shouldShowDiscover) {
        content.push(StudentTabs.discover);
    }
    if (shouldRenderTodo) {
        content.push(StudentTabs.todo);
    }
    if (!isDesktop) {
        content.push(StudentTabs.settings);
    }

    const onClick = (param: string) => {
        setSelectedTab(param);
    };
    return content.map((tab) => {
        return (
            <NavButton
                key={tab.key}
                tab={tab}
                routeMapper={Routes}
                onClick={onClick}
                activeTab={selectedTab}
            />
        );
    });
};

export const NavigationTabs = () => {
    return <StudentNavigationTabs />;
};

const BottomContainer = styled(Box)`
    box-shadow: 0 -2px 8px rgba(70, 70, 70, 0.1);
`;

export const BottomNavigation = () => {
    return (
        <BottomContainer
            minW="100%"
            display="flex"
            justifyContent="space-evenly"
            backgroundColor="#fff"
            position="relative"
        >
            <NavigationTabs />
        </BottomContainer>
    );
};
