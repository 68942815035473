import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {UserAccountRoles} from "ubcommunity-shared/src/constants";
import {FeatureFlags} from "ubcommunity-shared/src/featureFlags";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useIsStudentAppExperience} from "../../Hooks/useIsStudentAppExperience";
import {useGetTodosForUserQuery} from "ubcommunity-shared/src/types";

export const useTodos = () => {
    const {user} = useAuth();
    const isFeatureFlagEnabled = useIsFeatureEnabledBoolean(FeatureFlags.TODO_LIST_TAB);
    const isApplicant = user?.accountRole === UserAccountRoles.APPLICANT;
    const {loading: experienceLoading, hasCommunitiesORInvites} = useIsStudentAppExperience();
    const {data, loading: todosLoading} = useGetTodosForUserQuery();

    const loading = experienceLoading || todosLoading;

    const hasTodos = data?.getTodosForUser?.length && data.getTodosForUser.length > 0;

    const showTab =
        isApplicant && isFeatureFlagEnabled && !loading && hasCommunitiesORInvites && hasTodos;

    return {
        showTab,
        todos: data?.getTodosForUser,
    };
};
