import {Platform, ScrollView} from "react-native";
import {Inline, Stack, Text, View} from "@unibuddy/patron";
import {IconUbInbox} from "@unibuddy/icons";
import {useAnalytics} from "@unibuddy/tracking";

import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {useIsUserBlock} from "ubcommunity-shared/src/Chat/useIsUserBlock";
import {useGetConversationId} from "ubcommunity-shared/src/Conversation/useGetConversationId";
import {UserInfo} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummaryUserInfo";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {
    ConversationType,
    useConversation,
} from "ubcommunity-shared/src/Conversation/useConversation";
import {ProfileItem} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/ProfileItems";
import {useGetAmbassadorProfileFields} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/Profile/useGetAmbassadorProfileFields";
import {AmbassadorFlags} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/Profile/AmbassadorFlags";
import {Routes, TrackEvents} from "ubcommunity-shared/src/constants";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {LoadingButtonWithIcon} from "ubcommunity-shared/src/Patron/Button/LoadingButtonWithIcon";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";
import {useGetPartialUserSummaryData} from "../useGetPartialUserSummaryData";
import {AmbassadorProfileActions} from "../ProfileActions/AmbassadorProfileActions";
import {useCreateConversation} from "ubcommunity-shared/src/Hooks/useCreateConversation";

type AmbassadorProfileProps = {
    id: string;
    mixpanelMetadata?: MixpanelMetadataProps;
};

export enum AmbassadorProfileStrings {
    loading = "Loading Student ambassador profile",
    loadingFields = "Loading Student ambassador profile information",
    errorMessage = "Failed to load Student ambassador profile",
    sendMessage = "Send message",
}

export const AmbassadorProfile = ({id, mixpanelMetadata}: AmbassadorProfileProps) => {
    const {data, items, loading, error, refetch} = useGetAmbassadorProfileFields(id);
    const {closeUserSummary} = useOpenUserSummary({});
    const {id: conversationId} = useGetConversationId();
    const {conversationType} = useConversation();
    const [isBlocked] = useIsUserBlock({senderId: id, conversationId});
    const {trackEvent} = useAnalytics();
    const [navigate] = useNavigate();
    const {user: meUser} = useAuth();
    const {
        loading: wLoading,
        error: wError,
        createConversation,
    } = useCreateConversation({senderId: id});
    const isMe = meUser?.id === id;

    const {
        userData: partialUserData,
        countryData: partialCountryData,
        mentorFlagsData,
    } = useGetPartialUserSummaryData(id);

    let partialUser = {
        ...partialUserData,
        ...partialCountryData,
        ...mentorFlagsData,
        interests: [],
        degree: {},
    };

    if (data && data.publicMentor) {
        partialUser = {
            ...partialUser,
            firstName: data.publicMentor.firstName,
            accountRole: data.publicMentor.accountRole,
            university: data.publicMentor.university,
            country: {
                ...data.publicMentor.country,
                name: data.publicMentor.country?.name,
                code: data.publicMentor.country?.code,
            },
            mentorFields: data.publicMentor.mentorFields,
            degree: data?.publicMentor?.degree ?? {
                id: "",
                name: "",
            },
        };
    }

    const isPartialDataPopulated =
        Object.keys(partialUser).filter(
            (key) => key !== "__typename" && key !== "interests" && key !== "degree", // We remove fields which are not part of the partial user data (cached data)
        ).length > 0;
    const isWeb = Platform.OS === "web";

    const handleDirectMessage = async () => {
        const newConversationId = await createConversation();
        trackEvent(TrackEvents.USER_PROFILE_ACTION_DIRECT_MESSAGE, {
            conversationId: newConversationId,
            selectedUserId: id,
            accountType: partialUser.accountRole,
            groupType: conversationType,
            source: mixpanelMetadata?.openSource ?? "",
            ...mixpanelMetadata,
        });

        navigate({
            path: Routes.CHAT,
            options: {
                screen: isWeb ? newConversationId : Routes.CHAT_HOME,
                params: {
                    conversationId: newConversationId,
                },
            },
        });
    };

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                errorMessage={AmbassadorProfileStrings.errorMessage}
                meta={{
                    component: "AmbassadorProfile",
                    query: "GetAmbassadorProfile",
                }}
            />
        );
    }

    const hideBackButton =
        (conversationType === ConversationType.DIRECT ||
            conversationType === ConversationType.WIDGET) &&
        isWeb;

    const hideMessageButton =
        conversationType === ConversationType.DIRECT ||
        conversationType === ConversationType.WIDGET;

    const user = data?.publicMentor;

    return (
        <View flex="1" minH={0} height="100%">
            {hideBackButton ? null : (
                <BackButton paddingBottom="small" callback={closeUserSummary} />
            )}
            <ScrollView>
                <View paddingX="medium" paddingTop="large" flex={1}>
                    <View flex={1} paddingBottom="xsmall" marginBottom="medium">
                        {loading && !data && !isPartialDataPopulated ? (
                            <ActivityIndicator
                                accessibilityLabel={AmbassadorProfileStrings.loading}
                                style={{
                                    // approx height of UserInfo
                                    minHeight: 300,
                                }}
                            />
                        ) : (
                            <Stack space="gutter">
                                <UserInfo user={partialUser} isBlocked={isBlocked} />
                                {hideMessageButton ? null : (
                                    <LoadingButtonWithIcon
                                        color="primary"
                                        onClick={handleDirectMessage}
                                        block
                                        size="xs"
                                        loading={wLoading}
                                        icon={<IconUbInbox color="white" width={18} height={18} />}
                                    >
                                        <Text color="white" size="small">
                                            {AmbassadorProfileStrings.sendMessage}
                                        </Text>
                                    </LoadingButtonWithIcon>
                                )}

                                {wError ? <MutationErrorHandler error={wError} /> : null}
                            </Stack>
                        )}
                    </View>

                    <Stack space="medium">
                        {loading ? (
                            <View flex="1" alignItems="center">
                                <ActivityIndicator
                                    accessibilityLabel={AmbassadorProfileStrings.loadingFields}
                                />
                            </View>
                        ) : null}
                        {partialUser?.university?.name ? (
                            <ProfileItem
                                title="Institution"
                                text={partialUser?.university?.name ?? ""}
                                trimAfter={150}
                            />
                        ) : null}

                        {user?.placeName ? (
                            <Inline space="xsmall" verticalAlign="bottom">
                                <ProfileItem
                                    title="I Come From"
                                    text={user?.placeName ?? ""}
                                    textIcon={<AmbassadorFlags user={user} hideSecondary />}
                                />
                            </Inline>
                        ) : null}

                        {items.map((item, index) => (
                            <ProfileItem key={index} title={item.title} text={item.text} />
                        ))}

                        {user?.bio ? (
                            <ProfileItem title="About me" text={user?.bio} trimAfter={150} />
                        ) : null}
                    </Stack>
                </View>
                <View paddingY="medium" />
                {!loading && !isMe ? (
                    <AmbassadorProfileActions id={id} conversationId={conversationId} />
                ) : null}
                <View paddingY="medium" />
            </ScrollView>
        </View>
    );
};
