import React from "react";
import {Inline, Stack, View, Text, usePatronTheme} from "@unibuddy/patron";

import {AnyUserField} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import PersonIcon from "ubcommunity-shared/src/Icons/PersonIcon";
import {getAccountRoleName} from "ubcommunity-shared/src/General/getAccountRoleName";
import {GetFullName} from "ubcommunity-shared/src/General/GetFullName";

export const ProfileInfo = ({user}: {user: AnyUserField}) => {
    const theme = usePatronTheme();
    const accountRole = getAccountRoleName(user.accountRole);

    {
        /**
         * Problem: Stack component: Align prop doesn't work on mobile since the Inherent View component's width is not 100%
         * Workaround: Wrap the Stack component with View Component with  100% width
         */
    }
    return (
        <View w="100%">
            <Stack space="small" align="center">
                <Text size="medium" style={{...boldFontStyles}}>
                    <GetFullName user={user} />
                </Text>
                <Text size="small">{user.email}</Text>
                <Inline>
                    <View
                        bgColor="transparent"
                        padding="xxsmall"
                        paddingX="small"
                        borderRadius="small"
                        borderColor={theme.colors.textColor}
                        borderWidth={0.5}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <PersonIcon
                            size={parseInt(theme.space.small, 10)}
                            color={theme.colors.textColor}
                        />
                        <View>
                            <Text size="xxsmall" align="center" color={theme.colors.textColor}>
                                {accountRole}
                            </Text>
                        </View>
                    </View>
                </Inline>
            </Stack>
        </View>
    );
};
