import React from "react";
import {Column, Columns, Text, usePatronTheme, View} from "@unibuddy/patron";
import {Pressable} from "react-native";

import EditIcon from "ubcommunity-shared/src/Icons/EditIcon";
import {UserAvatar} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {UserField} from "ubcommunity-shared/src/types";
import InfoIcon from "ubcommunity-shared/src/Icons/InfoIcon";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {AVATAR_DISCLAIMER} from "./utils";

type Props = {
    user: UserField;
    size: number;
    onPress: () => void;
    borderRadius?: string;
    hideEditIcon?: boolean;
    loading?: boolean;
};

export const AvatarDisclaimer = () => (
    <Columns space="xsmall">
        <Column width="content">
            <InfoIcon color="grey" style={{position: "relative", top: -4}} />
        </Column>
        <Column>
            <Text>{AVATAR_DISCLAIMER}</Text>
        </Column>
    </Columns>
);

export const ProfilePhotoAvatar = ({user, size, onPress, hideEditIcon, loading}: Props) => {
    const theme = usePatronTheme();
    const {darkModeEnabled} = useCommunityTheme();

    return (
        <Pressable
            onPress={onPress}
            accessibilityRole="button"
            accessibilityLabel="Change profile photo"
            disabled={loading}
        >
            <View
                w={size}
                h={size}
                bgColor="#ddd"
                borderRadius="full"
                position="relative"
                justifyContent="center"
                alignItems="center"
                aria-live="polite"
            >
                {loading ? (
                    <ActivityIndicator accessibilityLabel="Loading profile picture" />
                ) : (
                    <>
                        {!hideEditIcon ? (
                            <View
                                position="absolute"
                                bgColor="navbarColor"
                                borderRadius="full"
                                padding="medium"
                                bottom="0px"
                                right="0px"
                                zIndex={999}
                                shadow="large"
                            >
                                <EditIcon
                                    width={size / 7}
                                    height={size / 7}
                                    color={
                                        darkModeEnabled
                                            ? theme.colors.textColor
                                            : theme.colors.primaryColor
                                    }
                                />
                            </View>
                        ) : null}

                        <UserAvatar user={user} size={size} />
                    </>
                )}
            </View>
        </Pressable>
    );
};
