import {useCallback, useState} from "react";
import {useParams} from "react-router";
import {Box} from "@unibuddy/patron";
import {ChatData} from "ubcommunity-shared/src/Chat/ChatData";
import {Attachment} from "ubcommunity-shared/src/types";
import {ChatSideDrawer} from "./ChatSideDrawer";
import {BlockModal} from "./BlockModal";
import {UserBlockModal} from "./UserBlockModal";
import {ReportMessageModal as ReportModal} from "./ReportMessageModal";
import {SpotlightModal} from "ubcommunity-web/src/General/Spotlight/SpotlightModal";
import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import {useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {UserSummary} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary";
import useFindSenderInPair from "ubcommunity-shared/src/Auth/hooks/useFindSenderInPair";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {SidePanel} from "ubcommunity-shared/src/General/SidePanel/SidePanel";
import {useIsGroupMember} from "ubcommunity-shared/src/Hooks/useIsGroupMember";
import {
    ConversationType,
    useConversation,
} from "ubcommunity-shared/src/Conversation/useConversation";
import {handleBlockOrReportProps, handleUserBlockProps} from "ubcommunity-shared/src/Chat/types";
import {UniversitySlugAnalyticsValue} from "ubcommunity-shared/src/Utils/Analytics/UniversitySlugAnalyticsValue";
import useSetActiveCommunity from "ubcommunity-shared/src/Hooks/useSetActiveCommunity";

export const ChatPage = () => {
    const {findSenderInPair} = useFindSenderInPair();
    const {id} = useParams<{id: string}>();
    const [spotlight, setSpotlight] = useState<{
        images: IImagePreview[];
        selectedImage: IImagePreview | null;
    }>({images: [], selectedImage: null});
    const {openModal, dismissModal} = useModal();
    const {openUserSummary, closeUserSummary} = useOpenUserSummary({
        openSource: "Chat page",
    });
    const {data, loading, error, refetch, conversationType} = useConversation();
    useSetActiveCommunity();

    const isWidgetConversation = conversationType === ConversationType.WIDGET;

    const {isMember, loading: membersLoading} = useIsGroupMember(id);

    const handleBlockOrReport = useCallback(
        ({conversationId, message, type}: handleBlockOrReportProps) => {
            if (type === "block") {
                openModal({
                    component: (
                        <BlockModal
                            onDismiss={dismissModal}
                            message={message}
                            conversationId={conversationId}
                        />
                    ),
                });
            } else {
                openModal({
                    component: (
                        <ReportModal
                            onDismiss={dismissModal}
                            message={message}
                            conversationId={conversationId}
                        />
                    ),
                });
            }
        },
        [openModal, dismissModal],
    );

    const handleUserBlock = useCallback(
        ({senderId}: handleUserBlockProps) => {
            closeUserSummary();
            openModal({
                component: <UserBlockModal senderId={senderId} onDismiss={dismissModal} />,
            });
        },
        [openModal, dismissModal, closeUserSummary],
    );

    const handleShowUserSummary = useCallback(
        (userId: string, mixpanelMetadata?: MixpanelMetadataProps): void => {
            openUserSummary(userId, id, mixpanelMetadata);
        },
        [openUserSummary, id],
    );

    const handleSpotlight = useCallback((images: IImagePreview[], selectedImage: IImagePreview) => {
        setSpotlight({images, selectedImage});
    }, []);

    const handlePreview = useCallback((file: Attachment) => {
        // open pdf in new tab
        window.open(file.link, "_blank");
    }, []);

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{component: "DesktopChatPage", query: "useGetCommunityConversationQuery"}}
                layout="center"
            />
        );
    }

    return (
        <Box bgColor="white" display="flex" flex="1">
            <UniversitySlugAnalyticsValue
                universitySlug={data?.getChatConversation?.university?.slug}
            />
            <ChatData
                key={id}
                onBlockOrReport={handleBlockOrReport}
                conversationId={id}
                onShowUserSummary={handleShowUserSummary}
                onSpotlight={handleSpotlight}
                onPreview={handlePreview}
                onUserBlock={handleUserBlock}
                isMember={isMember}
                membersLoading={membersLoading}
            />

            <SpotlightModal
                isOpen={!!spotlight.selectedImage}
                onDismiss={() => setSpotlight({images: [], selectedImage: null})}
                images={spotlight.images}
                selectedImage={spotlight.selectedImage}
            />

            <SidePanel>
                {data?.getChatConversation.isDirect || isWidgetConversation ? (
                    <UserSummary
                        senderId={
                            findSenderInPair(data?.getChatConversation.communityChatMembers)?.id
                        }
                        conversationId={id}
                        mixpanelMetadata={{
                            openSource: "Chat page",
                        }}
                        isStatic
                    />
                ) : (
                    <ChatSideDrawer
                        conversation={data?.getChatConversation}
                        loading={loading}
                        onShowUserSummary={(userId, mixpanelMetadata = {}) => {
                            mixpanelMetadata.openSource = "Members List";
                            handleShowUserSummary(userId, mixpanelMetadata);
                        }}
                    />
                )}
            </SidePanel>
        </Box>
    );
};
