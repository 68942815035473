import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useConversation} from "ubcommunity-shared/src/Conversation/useConversation";

export const useGetChatTitleInfo = () => {
    const {data} = useConversation();
    const {user} = useAuth();

    let title: string | null | undefined = "";

    if (data?.getChatConversation.communityChatTitle) {
        title = data?.getChatConversation.communityChatTitle;
    } else {
        title = data?.getChatConversation.communityChatMembers
            ?.filter((member) => member.id !== user?.id)
            ?.map((member) => member.firstName)
            ?.join(", ");
    }

    return {
        title,
    };
};
