import React, {FC} from "react";
import {string} from "yup";
import {ScrollView} from "react-native";
import {Stack, Button, Text, TextArea, Form, FormGroup, Box} from "@unibuddy/patron";
import {ChatMessage, useBlockCommunityUserMutation} from "ubcommunity-shared/src/types";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton";
import {MutationErrorHandler} from "../General/Errors/MutationErrorHandler";

interface Props {
    message: ChatMessage;
    conversationId: string;
    onDismiss: () => void;
    onSuccess: () => void;
}

export const BlockModalForm: FC<Props> = ({
    message,
    conversationId,
    onDismiss,
    onSuccess,
    ...props
}) => {
    const [block, {error}] = useBlockCommunityUserMutation();
    const handleSubmit = async ({comment}, {setSubmitting}) => {
        try {
            await block({
                variables: {
                    conversationId,
                    userId: message.sender.id,
                    comment,
                },
            });
            onSuccess();
        } catch (error) {
            console.log(error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Form
            initialValues={{comment: ""}}
            onSubmit={handleSubmit}
            validationSchema={{comment: string().required().max(500)}}
            {...props}
        >
            <ScrollView>
                <Stack space="large">
                    <Text>
                        We're sorry that you've had a bad experience. This action will block this
                        user from chatting with you and remove them from this community.
                    </Text>
                    <Text>Please provide more information for this report:</Text>
                    <FormGroup label="Comment">
                        <TextArea name="comment" />
                    </FormGroup>
                </Stack>
            </ScrollView>
            <Box paddingTop="large">
                <Stack space="medium">
                    {error ? (
                        <MutationErrorHandler
                            error={error}
                            meta={{
                                component: "BlockModalForm",
                                mutation: "useBlockCommunityUserMutation",
                            }}
                        />
                    ) : null}
                    <SubmitButton color="danger" block>
                        Block User
                    </SubmitButton>
                    <Button color="light" block onClick={onDismiss}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Form>
    );
};
