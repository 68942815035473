import {
    Maybe,
    Product,
    useBlockedUsersListQuery,
    useGetCommunityConversationQuery,
} from "ubcommunity-shared/src/types";
import useAuth from "../Auth/hooks/useAuth";

interface Props {
    conversationId?: Maybe<string>;
    senderId?: Maybe<string>;
}

export enum BlockType {
    Block = "block",
    Unblock = "unblock",
}

export function useIsUserBlock({conversationId, senderId}: Props) {
    const {user} = useAuth();
    const {data} = useGetCommunityConversationQuery({
        variables: {id: conversationId ?? ""},
        fetchPolicy: "cache-only",
        skip: !conversationId,
    });

    const {data: blockedUsers, loading} = useBlockedUsersListQuery();

    const blockedUsersByMe = blockedUsers?.blockedUsersByMe ?? [];
    const conversation = data?.getChatConversation;

    let isBlocked = false;

    if (!blockedUsersByMe.length) {
        return [false, loading];
    }

    if (conversation && !conversation?.isDirect && !(conversation?.product === Product.Widget)) {
        return [false, loading];
    }

    const members = conversation?.communityChatMembers ?? [];

    let opponentId = senderId;
    if (!senderId) {
        opponentId = members.filter((member) => member?.id !== user?.id)[0]?.id ?? "";
    }

    isBlocked = members.some((member) => blockedUsersByMe.includes(member?.id ?? ""));
    isBlocked = !!blockedUsersByMe?.includes(opponentId ?? "");
    // TODO: refactor this to be returned as an object
    return [isBlocked, loading];
}
