import React, {useEffect, useState} from "react";
import {Platform, ScrollView} from "react-native";
import {getReadableVersion} from "react-native-device-info";
import {Stack, Text, Button, useClipboard, TextInput, TextLine} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {API_URL} from "ubcommunity-shared/src/constants";
import {CodePushManagerWrapper} from "./CodePushManagerWrapper";
import {
    clearLocalStorage,
    messaging,
    getMixpanelId,
    flushMixpanelQueue,
    getMixpanelTrackingStatus,
} from "./util";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {getTrackingStatus} from "react-native-tracking-transparency";

export const DeveloperOptions = () => {
    const {copyToClipboard} = useClipboard();
    const [mixpanelDistinctId, setMixpanelDistinctId] = useState("");
    const [iosTrackingStatus, setIosTrackingStatus] = useState("");
    const [mixpanelTrackingAllowed, setMixpanelTrackingAllowed] = useState(false);
    const [mixpanelTextInput, setMixpanelTextInput] = useState("");
    const {user} = useAuth();
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();

    const handleOnCopy = async () => {
        const token = await messaging().getToken();
        copyToClipboard(token);
    };

    useEffect(() => {
        const getTrackingInfo = async () => {
            const mixpanelId = await getMixpanelId();
            const iosTracking = await getTrackingStatus();
            const mixpanelTracking = await getMixpanelTrackingStatus();
            setMixpanelDistinctId(mixpanelId);
            setIosTrackingStatus(iosTracking);
            setMixpanelTrackingAllowed(mixpanelTracking);
        };
        getTrackingInfo();
    }, []);

    const handleReset = () => {
        try {
            clearLocalStorage();
        } catch (err) {
            console.log(err);
        }
    };

    const handleTestMixpanelEvent = () => {
        const textToSend = mixpanelTextInput.length
            ? mixpanelTextInput
            : "Test event sent from devtools";
        trackEvent(textToSend);
        flushMixpanelQueue();
        setMixpanelTextInput("");
    };

    const handleSendSentryError = () => {
        reportError(new Error("Test error from Community"));
        alert("Error sent");
    };

    return (
        <ScrollView>
            <Stack space="medium" dividers>
                <Stack space="small">
                    <Text size="medium" weight="500">
                        Api Url
                    </Text>
                    <Text size="medium" tone="secondary">
                        {API_URL}
                    </Text>
                </Stack>
                {Platform.select({
                    default: (
                        <Stack space="small">
                            <Text size="medium" weight="500">
                                Build Version
                            </Text>
                            <Text size="medium" tone="secondary">
                                {getReadableVersion()}
                            </Text>
                        </Stack>
                    ),
                    web: null,
                })}
                <Stack space="small">
                    <Text size="medium" weight="500">
                        Tracking Information
                    </Text>

                    {Platform.select({
                        ios: (
                            <Text size="medium" tone="secondary">
                                iOS tracking: <TextLine weight="500">{iosTrackingStatus}</TextLine>
                            </Text>
                        ),
                    })}

                    <Text size="medium" tone="secondary">
                        Mixpanel tracking allowed:{" "}
                        <TextLine weight="500">{mixpanelTrackingAllowed ? "yes" : "no"}</TextLine>
                    </Text>
                </Stack>
                <Stack space="small">
                    <Text size="medium" weight="500">
                        Mixpanel Distinct ID
                    </Text>
                    <Text size="medium" tone="secondary">
                        {mixpanelDistinctId}
                    </Text>
                    {Platform.select({
                        default: (
                            <Button size="sm" onPress={() => copyToClipboard(mixpanelDistinctId)}>
                                Copy
                            </Button>
                        ),
                        web: null,
                    })}
                </Stack>
                {Platform.select({
                    default: (
                        <Stack space="small">
                            <Text size="medium" weight="500">
                                Send test event to mixpanel
                            </Text>
                            <TextInput
                                placeholder="Test event sent from devtools"
                                onChangeText={setMixpanelTextInput}
                                value={mixpanelTextInput}
                            />
                            <Button size="sm" onPress={handleTestMixpanelEvent}>
                                Send
                            </Button>
                            <Text size="small">
                                This will also flush 🚽 the event queue which forces queued events
                                to send immediately 🎉.
                            </Text>
                        </Stack>
                    ),
                    web: null,
                })}
                <Stack space="small">
                    <Text size="medium" weight="500">
                        Unibuddy User ID
                    </Text>
                    <Text size="medium" tone="secondary">
                        {user.id}
                    </Text>
                    {Platform.select({
                        default: (
                            <Button size="sm" onPress={() => copyToClipboard(user.id)}>
                                Copy
                            </Button>
                        ),
                        web: null,
                    })}
                </Stack>
                <Stack space="small">
                    <Text size="medium" weight="500">
                        Send test error to Sentry
                    </Text>
                    <Button size="sm" onClick={handleSendSentryError}>
                        Send
                    </Button>
                </Stack>
                <Stack space="small">
                    <Text size="medium" weight="500">
                        Reset Local Storage
                    </Text>
                    <Button size="sm" onClick={handleReset}>
                        Reset
                    </Button>
                </Stack>
                {Platform.select({
                    default: (
                        <Stack space="small">
                            <CodePushManagerWrapper />
                        </Stack>
                    ),
                    web: null,
                })}
                {Platform.select({
                    default: (
                        <Stack space="small">
                            <Text size="medium" weight="500">
                                Firebase device id
                            </Text>
                            <Button size="sm" onPress={handleOnCopy}>
                                Copy
                            </Button>
                        </Stack>
                    ),
                    web: null,
                })}
            </Stack>
        </ScrollView>
    );
};
