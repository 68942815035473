import {ScrollView, TouchableOpacity} from "react-native";
import {Box, Button, TextInput, Text, View, usePatronTheme, colors} from "@unibuddy/patron";
import {IconXMark} from "@unibuddy/icons";

import SearchIcon from "ubcommunity-shared/src/Icons/SearchIcon";
import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";
import {themeUnitToNativeValue, capitalize} from "ubcommunity-shared/src/Utils";
import {semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {GetLabelsQuery} from "ubcommunity-shared/src/types";

type ExploreSearchBarProps = {
    onClick?(): void;
    onChangeText(text: string): void;
    value: string;
    selectedFilter: {id: string; name: string} | null;
    setSelectedFilter: (filter: {id: string; name: string}) => void;
    labels?: GetLabelsQuery["getLabels"];
};

export const ExploreSearchBar = ({
    onClick,
    onChangeText,
    value,
    selectedFilter,
    setSelectedFilter,
    labels,
}: ExploreSearchBarProps) => {
    const theme = usePatronTheme();

    return (
        <View paddingTop="small-medium" position="relative" flexShrink={0}>
            <View position="relative" marginX="small-medium">
                <TextInput
                    style={{
                        borderRadius: 9999,
                        paddingLeft: 20,
                        paddingRight: 48,
                        backgroundColor: theme.colors.white,
                        color: theme.colors.grey800,
                        borderColor: theme.colors.grey150,
                        fontFamily: theme.TextBlock.fontFamily,
                    }}
                    onFocus={onClick}
                    onChangeText={onChangeText}
                    placeholder="Search groups..."
                    // @ts-ignore - RN types are wrong
                    placeholderTextColor={theme.colors.grey800}
                    value={value}
                    accessibilityLabel="Search chat groups"
                />
                <View
                    position="absolute"
                    right={0}
                    top={0}
                    bottom={0}
                    justifyContent="center"
                    paddingX="small"
                >
                    {value ? (
                        <Button
                            accessibilityLabel="Clear search"
                            onClick={() => onChangeText("")}
                            clear
                            iconOnly
                            round
                        >
                            <CloseIcon color={theme.colors.lightGrey} />
                        </Button>
                    ) : (
                        <Box paddingRight="xsmall">
                            <SearchIcon
                                color={theme.colors.lightGrey}
                                aria-hidden
                                fontSize="28px"
                            />
                        </Box>
                    )}
                </View>
            </View>
            <ScrollView
                style={{
                    marginTop: themeUnitToNativeValue(theme.space["small-medium"]),
                }}
                contentContainerStyle={{
                    paddingLeft: themeUnitToNativeValue(theme.space["small-medium"]),
                    paddingRight: themeUnitToNativeValue(theme.space.small),
                    paddingBottom: themeUnitToNativeValue(theme.space.xsmall),
                }}
                horizontal
                showsHorizontalScrollIndicator={false}
            >
                {labels?.map((item, index) => {
                    const isSelected = selectedFilter?.id === item.id;
                    const borderColor = colors.grey150;
                    const fontColor = colors.grey800;

                    return (
                        <View key={`item-${item.id}`} style={{marginRight: "xsmall"}}>
                            <TouchableOpacity
                                accessibilityRole="button"
                                accessibilityState={{selected: isSelected}}
                                onPress={() =>
                                    setSelectedFilter({id: item.id, name: item?.name ?? ""})
                                }
                                style={{
                                    height: 35,
                                    borderWidth: 1,
                                    borderRadius: 100,
                                    backgroundColor: isSelected ? colors.teal200 : colors.grey50,
                                    borderColor: isSelected ? "transparent" : borderColor,
                                    marginRight: themeUnitToNativeValue(theme.space.xsmall),
                                }}
                            >
                                <View
                                    flexDirection="row"
                                    alignItems="center"
                                    paddingY="xxsmall"
                                    paddingX="small"
                                    gap="xxsmall"
                                    height={35}
                                >
                                    {isSelected ? (
                                        <IconXMark
                                            width="16px"
                                            height="16px"
                                            color={colors.grey900}
                                        />
                                    ) : null}
                                    <Text
                                        size="small"
                                        color={isSelected ? colors.grey900 : fontColor}
                                        // @ts-ignore
                                        style={{...semiBoldFontStyles}}
                                    >
                                        {capitalize(item?.name ?? "")}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    );
                })}
            </ScrollView>
        </View>
    );
};
