interface ExtensionType {
    code: string;
    serviceName: string;
    query: string;
}

interface GQLErrorType {
    extensions: ExtensionType;
    message: string;
    path: string[];
}

export const parseFirstGQLError = (errors: GQLErrorType[]) => {
    if (!errors || !errors.length) return null;
    return {
        code: errors[0].extensions.code,
        message: errors[0].message,
    };
};
