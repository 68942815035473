import React from "react";
import Svg, {SvgProps, Path} from "react-native-svg";

export const FilterHorizontalIcon = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <Path
            d="M10.5 6.00003L20.25 6.00003M10.5 6.00003C10.5 6.82846 9.82843 7.50003 9 7.50003C8.17157 7.50003 7.5 6.82846 7.5 6.00003M10.5 6.00003C10.5 5.1716 9.82843 4.50003 9 4.50003C8.17157 4.50003 7.5 5.1716 7.5 6.00003M3.75 6.00003H7.5M10.5 18H20.25M10.5 18C10.5 18.8285 9.82843 19.5 9 19.5C8.17157 19.5 7.5 18.8285 7.5 18M10.5 18C10.5 17.1716 9.82843 16.5 9 16.5C8.17157 16.5 7.5 17.1716 7.5 18M3.75 18L7.5 18M16.5 12L20.25 12M16.5 12C16.5 12.8285 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8285 13.5 12M16.5 12C16.5 11.1716 15.8284 10.5 15 10.5C14.1716 10.5 13.5 11.1716 13.5 12M3.75 12H13.5"
            stroke={props.color ?? "currentColor"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={props.color ?? "currentColor"}
        />
    </Svg>
);
