import React from "react";
import Svg, {Path} from "react-native-svg";

function NotificationIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <Svg
            width={props?.size || 24}
            height={props?.size || 24}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M20.567 17.459l-.522-.454a5.507 5.507 0 01-1.846-4.161v0-3.681a6.41 6.41 0 00-6.34-6.672c-3.366.109-5.96 3.297-5.96 6.952v3.4a5.507 5.507 0 01-1.844 4.161l-.522.454 17.034.001zM14.407 20.33a2.624 2.624 0 01-2.325 1.181 2.63 2.63 0 01-2.316-1.167"
                stroke={props.color || "#3E3E48"}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default NotificationIcon;
