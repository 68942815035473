import React, {useCallback} from "react";
import {View} from "@unibuddy/patron";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles, Routes} from "ubcommunity-shared/src/constants";
import {UniversalInbox} from "ubcommunity-shared/src/UniversalInbox/UniversalInbox";
import {UniversalInboxHeader} from "ubcommunity-shared/src/UniversalInbox/UniversalInboxHeader";

export function InboxLayout() {
    const {push} = useRelativeRouter();

    usePageTitle(PageTitles.INBOX);

    const handleSelectChatGroup = useCallback(
        ({id}: {id: string}) => {
            push(`${Routes.CHAT}/${id}`);
        },
        [push],
    );

    return (
        <View flex="1">
            <UniversalInboxHeader />
            <UniversalInbox onSelect={handleSelectChatGroup} />
        </View>
    );
}
