import React from "react";
import {Image} from "react-native";

const FLAG_RATIO = 1.4;

export const Flag = ({label, code, size}: {label: string; code: string; size: number}) => (
    <Image
        style={{
            width: size * FLAG_RATIO,
            height: size,
        }}
        aria-label={`flag ${label}`}
        accessibilityLabel={`flag ${label}`}
        source={{
            uri: `https://flagcdn.com/h60/${code.toLowerCase()}.png`,
        }}
        resizeMode="cover"
    />
);
