import {useEffect, useState} from "react";
import {GiphyFetch, GifResult} from "@giphy/js-fetch-api";
import {apiKey} from "../Gifs/utils";

const gf = new GiphyFetch(apiKey);

export const useFetchGif = ({id}) => {
    const [gif, setGif] = useState<GifResult["data"]>(null);
    useEffect(() => {
        gf.gif(id).then((res) => {
            if (res.data.rating === "r") return;
            setGif(res.data);
        });
    }, [id]);

    return gif;
};
