import {useState} from "react";

import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {GroupTypes} from "ubcommunity-shared/src/Hooks/useGetGroupType";

export const IMAGE_UPLOAD_SIZE_MAX = 10485760; // 10MB
export const ALLOWED_IMAGE_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
export const MAXIMUM_ALLOWED_IMAGES = 5;

export const FILE_UPLOAD_SIZE_MAX = 10485760; // 10MB
export const ALLOWED_FILE_FORMATS = ["application/pdf"];
export const MAXIMUM_ALLOWED_FILES = 5;

export enum AttachmentErrors {
    ATTACHMENT_NOT_SUPPORTED_TITLE = "File not supported",
    ATTACHMENT_NOT_SUPPORTED_MESSAGE = `One of the attachments you provided is not supported. We accept only .pdf, .jpg, .jpeg, and .png formats.`,
    EXCLUSIVITY_NOT_MET_TITLE = "Oops!",
    EXCLUSIVITY_NOT_MET_MESSAGE = "Images and files cannot be sent together.",

    IMAGE_SIZE_EXCEEDED_TITLE = "Image too big",
    IMAGE_SIZE_EXCEEDED_MESSAGE = "One of the images you provided is larger than the 10MB limit.",
    IMAGE_NOT_SUPPORTED_TITLE = "Image not supported",
    IMAGE_NOT_SUPPORTED_MESSAGE = "One of the images you provided is not supported. We accept only .png, .jpg and .jpeg files.",
    IMAGE_LIMIT_EXCEEDED_TITLE = "Too many images",
    IMAGE_LIMIT_EXCEEDED_MESSAGE = `You can only send up to 5 images at a time.`,

    FILE_SIZE_EXCEEDED_TITLE = "File too big",
    FILE_SIZE_EXCEEDED_MESSAGE = "One of the files you provided is larger than the 10MB limit.",
    FILE_NOT_SUPPORTED_TITLE = "File not supported",
    FILE_NOT_SUPPORTED_MESSAGE = "One of the files you provided is not supported. We accept only .pdf files.",
    FILE_LIMIT_EXCEEDED_TITLE = "Too many files",
    FILE_LIMIT_EXCEEDED_MESSAGE = `You can only send up to 5 files at a time.`,
}

// enum with all error values including title and messages
export enum ErrorValues {}

type IAttachment = {
    uri: string;
    name: string;
    type: string;
    size?: number;
    file?: any;
};

export type IImage = IAttachment;

export type IFile = IAttachment & {
    s3Url?: string;
    error?: boolean;
};

export type IAttachmentProps = {
    groupType: GroupTypes;

    isAttachmentOpen: boolean;
    setIsAttachmentOpen: React.Dispatch<React.SetStateAction<boolean>>;

    selectedImages: IImage[];
    setSelectedImages: React.Dispatch<React.SetStateAction<IImage[]>>;

    selectedFiles: IFile[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
};

export const convertToMb = (bytes: number) => {
    return (bytes / 1048576).toFixed(2);
};

export const getImageSrc = (imageUrl: string) => {
    let image = "";
    if (typeof imageUrl === "string") {
        const urlParts = imageUrl.split("/");
        image = urlParts[urlParts.length - 1];
    }
    return image;
};

export const useAttachments = () => {
    const {open} = usePrompt();

    const [isAttachmentOpen, setIsAttachmentOpen] = useState<boolean>(null);

    const validateExclusivity = (refs: any) => {
        const areAllValid = refs.every((ref) => {
            return [...ALLOWED_FILE_FORMATS, ...ALLOWED_IMAGE_FORMATS].includes(ref.type);
        });

        if (!areAllValid) {
            open({
                title: AttachmentErrors.ATTACHMENT_NOT_SUPPORTED_TITLE,
                message: AttachmentErrors.ATTACHMENT_NOT_SUPPORTED_MESSAGE,
                buttons: [{text: "Ok"}],
            });
            return {
                isAllFiles: false,
                isAllImages: false,
            };
        }

        const isAllImages = refs.every((ref) => ref.type.startsWith("image/"));

        // check if all refs are files (pdfs)
        const isAllFiles = refs.every(
            (ref) =>
                // ought to change in the future with more file types
                ref.type === "application/pdf",
        );

        if (!(isAllImages || isAllFiles)) {
            open({
                title: AttachmentErrors.EXCLUSIVITY_NOT_MET_TITLE,
                message: AttachmentErrors.EXCLUSIVITY_NOT_MET_MESSAGE,
                buttons: [{text: "Ok"}],
            });
            return {
                isAllFiles: false,
                isAllImages: false,
            };
        }

        return {
            isAllImages,
            isAllFiles,
        };
    };

    // Images
    const [selectedImages, setSelectedImages] = useState<IImage[]>([]);
    const validateImageFormat = (image: IImage) => ALLOWED_IMAGE_FORMATS.includes(image.type);
    const validateImageSize = (image: IImage) => image.size <= IMAGE_UPLOAD_SIZE_MAX;
    const validateImageCount = (images: IImage[]) => images.length <= MAXIMUM_ALLOWED_IMAGES;
    const validateImages = (images: IImage[]) => {
        for (const image of images) {
            if (!validateImageSize(image)) {
                open({
                    title: AttachmentErrors.IMAGE_SIZE_EXCEEDED_TITLE,
                    message: AttachmentErrors.IMAGE_SIZE_EXCEEDED_MESSAGE,
                    buttons: [{text: "Ok"}],
                });
                return {
                    isValid: false,
                };
            }
            if (!validateImageFormat(image)) {
                open({
                    title: AttachmentErrors.IMAGE_NOT_SUPPORTED_TITLE,
                    message: AttachmentErrors.IMAGE_NOT_SUPPORTED_MESSAGE,
                    buttons: [{text: "Ok"}],
                });
                return {
                    isValid: false,
                };
            }
        }

        if (!validateImageCount([...selectedImages, ...images])) {
            open({
                title: AttachmentErrors.IMAGE_LIMIT_EXCEEDED_TITLE,
                message: AttachmentErrors.IMAGE_LIMIT_EXCEEDED_MESSAGE,
                buttons: [{text: "Ok"}],
            });
            return {
                isValid: false,
            };
        }

        return {
            isValid: true,
        };
    };

    // Files
    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
    const validateFileFormat = (file: IFile) => ALLOWED_FILE_FORMATS.includes(file.type);
    const validateFileSize = (file: IFile) => file.size <= FILE_UPLOAD_SIZE_MAX;
    const validateFileCount = (files: IFile[]) => files.length <= MAXIMUM_ALLOWED_FILES;
    const validateFiles = (files: IFile[]) => {
        for (const file of files) {
            if (!validateFileSize(file)) {
                open({
                    title: AttachmentErrors.FILE_SIZE_EXCEEDED_TITLE,
                    message: AttachmentErrors.FILE_SIZE_EXCEEDED_MESSAGE,
                    buttons: [{text: "Ok"}],
                });
                return {
                    isValid: false,
                };
            }
            if (!validateFileFormat(file)) {
                open({
                    title: AttachmentErrors.FILE_NOT_SUPPORTED_TITLE,
                    message: AttachmentErrors.FILE_NOT_SUPPORTED_MESSAGE,
                    buttons: [{text: "Ok"}],
                });
                return {
                    isValid: false,
                };
            }
        }

        if (!validateFileCount([...selectedFiles, ...files])) {
            open({
                title: AttachmentErrors.FILE_LIMIT_EXCEEDED_TITLE,
                message: AttachmentErrors.FILE_LIMIT_EXCEEDED_MESSAGE,
                buttons: [{text: "Ok"}],
            });
            return {
                isValid: false,
            };
        }

        return {
            isValid: true,
        };
    };

    return {
        isAttachmentOpen,
        setIsAttachmentOpen,
        validateExclusivity,
        // Images
        selectedImages,
        setSelectedImages,
        validateImageFormat,
        validateImageSize,
        validateImageCount,
        validateImages,
        // Files
        selectedFiles,
        setSelectedFiles,
        validateFileFormat,
        validateFileSize,
        validateFileCount,
        validateFiles,
    };
};
