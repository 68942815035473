import {useCallback, useEffect, useMemo, useRef} from "react";
import {AppState, LayoutAnimation} from "react-native";
import {NetworkStatus, gql} from "@apollo/client";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {
    useSocketListener,
    SocketChannel,
    SocketEvent,
} from "ubcommunity-shared/src/Sockets/SocketProvider";
import {Sentry} from "ubcommunity-shared/src/Utils/Telemetry/Sentry";
import {
    useCommunityChatMessagesQuery,
    ChatMessage,
    useSendCommunityChatMessageMutation,
    useDeleteConversationMessageMutation,
    useCommunityConversationReadMutation,
    useGetCommunityConversationQuery,
    SendCommunityChatMessageMutationVariables,
    GifDto,
    AttachmentType,
    Product,
} from "../types";
import {useLike} from "./useLike";
import UploadService from "../Utils/UploadService/UploadService";
import {usePrompt} from "../General/usePrompt/usePrompt";
import {TrackEvents} from "../constants";
import {IFile, IImage} from "./Attachments/useAttachments";

const conversationChannel = new SocketChannel("conversation", {private: true});
export const sendMessageEvent = new SocketEvent("send-message", conversationChannel);
export const deleteMessageEvent = new SocketEvent("delete-message", conversationChannel);
export const userJoinedEvent = new SocketEvent("user-joined-conversation", conversationChannel);
export const userLeftEvent = new SocketEvent("user-left-conversation", conversationChannel);

interface IUser {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: string;
    accountRole: string;
    country: {
        id: string;
        name: string;
        code: string;
    };
    __typename: string;
}

type CommunityChatMessage = Omit<ChatMessage, "resolved">;

export const formatChatMessage = (message): CommunityChatMessage => {
    const {
        id,
        created,
        text,
        sender,
        deleted = false,
        likes,
        likesCount,
        richContent,
        reactions,
    } = message;
    const newMessage: CommunityChatMessage = {
        id,
        created,
        text,
        likes: likes || [],
        likesCount: likesCount || 0,
        reactions: reactions || [],
        sender: {...sender, __typename: "UserField"},
        deleted,
        richContent: richContent
            ? ({
                  reply: null,
                  giphy: null,
                  images: null,
                  poll: null,
                  attachments: null,
                  ...(richContent.reply
                      ? ({
                            reply: {
                                ...richContent.reply,
                                // Because of the original request is reusing the ChatMessageFragment in the reply we have to match
                                // the type here even tho we don't care about the number of likes on the reply.
                                likes: [],
                                likesCount: 0,
                                // Only giphy or images is requested in the reply, not the reply to a reply
                                richContent: richContent.reply.richContent
                                    ? {
                                          giphy: richContent.reply.richContent.giphy
                                              ? {
                                                    ...richContent.reply.richContent.giphy,
                                                    __typename: "Giphy",
                                                }
                                              : null,
                                          images: richContent.reply.richContent.images
                                              ? richContent.reply.richContent.images.map(
                                                    (image) => ({
                                                        url: image.url,
                                                        __typename: "Image",
                                                    }),
                                                )
                                              : null,
                                          attachments: richContent.reply.richContent.attachments
                                              ? richContent.reply.richContent.attachments.map(
                                                    (attachment) => ({
                                                        ...attachment,
                                                        __typename: "Attachment",
                                                    }),
                                                )
                                              : null,
                                          __typename: "RichMessageContent",
                                      }
                                    : null,
                                sender: {
                                    ...richContent.reply.sender,
                                    __typename: "UserField",
                                },
                                __typename: "ChatMessage",
                            },
                        } as const)
                      : {}),
                  ...(richContent.giphy
                      ? ({
                            giphy: {
                                ...richContent.giphy,
                                __typename: "Giphy",
                            },
                        } as const)
                      : {}),
                  ...(richContent.images
                      ? ({
                            images: richContent.images.map((image) => ({
                                url: image.url,
                                __typename: "Image",
                            })),
                        } as const)
                      : {}),
                  ...(richContent.attachments
                      ? ({
                            attachments: richContent.attachments.map((attachment) => ({
                                ...attachment,
                                __typename: "Attachment",
                            })),
                        } as const)
                      : {}),
                  __typename: "RichMessageContent",
              } as const)
            : null,
        __typename: "ChatMessage",
    } as const;
    // We must delete the id fields of all nested reply objects because they are not
    // queried by default. If we leave them in, the optimistic and socket messages
    // will cause a mismatch with the cache and the chat query will be reloaded by apollo.
    delete newMessage.richContent?.reply?.id;
    delete newMessage.richContent?.reply?.richContent?.id;
    return newMessage;
};

const deleteMessageFragment = gql`
    fragment PartialDeleteChatMessage on ChatMessage {
        id
        text
        deleted
    }
`;

const membersFragment = gql`
    fragment Members on ChatConversation {
        communityChatMembers: members(offsetPagination: {limit: 15}) {
            id
            profilePhoto
            firstName
            accountRole
        }
        totalMemberCount
    }
`;

export enum GroupTypes {
    DIRECT = "Direct",
    PRIVATE = "Private",
    NEWS_FEED = "NewsFeed",
    PUBLIC = "Public",
}

// extract this to a constant to preserve reference between renders
const EMPTY_MESSAGES = [];

export function useChat(conversationId: string, user: IUser) {
    const queue = useRef([]);
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();
    const queueProcessed = useRef(false);
    const firstLoadRef = useRef(false);
    const [readConversation] = useCommunityConversationReadMutation();
    const [sendMessage] = useSendCommunityChatMessageMutation();
    const {data} = useGetCommunityConversationQuery({
        variables: {id: conversationId},
        fetchPolicy: "cache-only",
    });
    const {open} = usePrompt();
    const conversationTitle = data?.getChatConversation?.communityChatTitle;
    const isPrivate = data?.getChatConversation?.isPrivate;
    const isDirect = data?.getChatConversation?.isDirect;
    const isNewsFeed = data?.getChatConversation?.isNewsFeed;
    const product = data?.getChatConversation?.product;

    const groupType: GroupTypes =
        // All Widget type conversations are considered Direct
        isDirect || product === Product.Widget
            ? GroupTypes.DIRECT
            : isPrivate
            ? GroupTypes.PRIVATE
            : isNewsFeed
            ? GroupTypes.NEWS_FEED
            : GroupTypes.PUBLIC;

    useEffect(() => {
        if (!conversationTitle || !conversationId || typeof isPrivate === "undefined") return;
        trackEvent("Group Chat Opened", {
            conversationId,
            groupType,
        });
    }, [conversationId, trackEvent, conversationTitle, groupType, isPrivate]);

    const markAsRead = (queryMessages: CommunityChatMessage[] = []) => {
        const lastMessage = queryMessages[queryMessages.length - 1];
        if (!lastMessage) return;
        client.writeFragment({
            id: `ChatConversation:${conversationId}`,
            fragment: gql`
                fragment PartialChatConversation on ChatConversation {
                    unreadMessageCount
                }
            `,
            data: {
                unreadMessageCount: 0,
            },
        });

        readConversation({
            variables: {
                conversationId,
                messageId: lastMessage.id,
            },
        });
    };

    const chatMessages = useCommunityChatMessagesQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        onCompleted: (data) => {
            if (firstLoadRef.current) return;
            firstLoadRef.current = true;
            const queryMessages = data?.getChatConversationMessages?.messages ?? [];
            if (!queryMessages.length) return;

            markAsRead(queryMessages);
        },
        variables: {getMessageDto: {conversationId, limit: 30}},
    });
    const [deleteMessage] = useDeleteConversationMessageMutation();
    const {networkStatus, updateQuery, client, fetchMore: apolloFetchMore, refetch} = chatMessages;
    const messagesInitialLoading: boolean = networkStatus === NetworkStatus.loading;

    // const handleError = (err: Error) => {
    //         if (err.message.includes("Can't find field messages")) return;
    //         reportError(err);
    //     }

    const appState = useRef(AppState.currentState);
    useEffect(() => {
        const subscription = AppState.addEventListener("change", (nextAppState) => {
            if (appState.current.match(/inactive|background/) && nextAppState === "active") {
                // we refresh messages when the app comes back to foreground
                // this is because pusher may error out / be too slow to respond to new messages
                // TODO: think of alternate methods to fetch data when app is in the background
                // rehydrate via background fetching / use notifications to populate data
                // pusher is designed for real time updates when the app is open
                // the OS can decide to kill the background network connection and pusher may not
                // get new events when a connection is re-established upon coming to foreground
                // TODO: use https://github.com/pusher/pusher-websocket-react-native for native
                // support for native has been deprecated in the JS package and we should move to this

                // P.S. this has a side effect where if the user has scrolled to previous messages
                // and they bring the app to foreground, the refetch causes only the latest 30 messages to show
                // effectively losing the scrolled up messages
                refetch();
            }
            appState.current = nextAppState;
        });

        return () => {
            if (subscription) subscription.remove();
        };
    }, [refetch]);

    const fetchMore = useCallback(
        (options) => {
            apolloFetchMore({
                ...options,
                updateQuery: (cache, {fetchMoreResult}) => {
                    return {
                        ...fetchMoreResult,
                        getChatConversationMessages: {
                            ...fetchMoreResult.getChatConversationMessages,
                            messages: [
                                ...fetchMoreResult.getChatConversationMessages.messages,
                                ...cache.getChatConversationMessages.messages,
                            ],
                        },
                    };
                },
            });
        },
        [apolloFetchMore],
    );

    const filterDuplicateMessages = (messages) => {
        const ids = new Set();
        return messages.filter((message) => {
            if (ids.has(message.id)) return false;
            ids.add(message.id);
            return true;
        });
    };

    const addChatMessageToApolloCache = useCallback(
        (messages: CommunityChatMessage[]) => {
            updateQuery((cacheData) => {
                if (!cacheData?.getChatConversationMessages) return cacheData;
                return {
                    ...cacheData,
                    getChatConversationMessages: {
                        ...cacheData.getChatConversationMessages,
                        messages: filterDuplicateMessages([
                            ...cacheData.getChatConversationMessages.messages,
                            ...messages.map(formatChatMessage),
                        ]),
                    },
                };
            });
        },
        [updateQuery],
    );

    const deleteChatMessageFromApolloCache = useCallback(
        (message: CommunityChatMessage) => {
            updateQuery((cacheData) => {
                if (!cacheData?.getChatConversationMessages) return cacheData;
                return {
                    ...cacheData,
                    getChatConversationMessages: {
                        ...cacheData.getChatConversationMessages,
                        messages: cacheData.getChatConversationMessages.messages.filter(
                            (cachedMessage) => cachedMessage.id !== message.id,
                        ),
                    },
                };
            });
        },
        [updateQuery],
    );

    const onDelete = useCallback(
        async (message: CommunityChatMessage) => {
            const originalText = message.text;
            function undoDelete() {
                client.writeFragment({
                    id: `ChatMessage:${message.id}`,
                    fragment: deleteMessageFragment,
                    data: {
                        text: originalText,
                        deleted: false,
                    },
                });
            }
            try {
                client.writeFragment({
                    id: `ChatMessage:${message.id}`,
                    fragment: deleteMessageFragment,
                    data: {
                        text: "This message has been deleted",
                        deleted: true,
                    },
                });
                const res = await deleteMessage({
                    variables: {
                        deleteMessageDto: {
                            conversationId,
                            messageId: message.id,
                        },
                    },
                });
                if (!res.data?.deleteConversationMessage.deleted) {
                    undoDelete();
                }
            } catch (sendError) {
                reportError(sendError);
                undoDelete();
            }
        },
        [client, conversationId, deleteMessage, reportError],
    );

    const onNewMessage = (message) => {
        if (message.sender.id === user.id) return;
        markAsRead([message]);
        if (messagesInitialLoading) {
            queue.current.push(message);
        } else {
            try {
                LayoutAnimation.easeInEaseOut();
                addChatMessageToApolloCache([message]);
            } catch (e) {
                reportError(e);
            }
        }
    };

    const onSocketDeleteMessage = useCallback(
        ({id}) => {
            client.writeFragment({
                id: `ChatMessage:${id}`,
                fragment: deleteMessageFragment,
                data: {
                    text: "This message has been deleted",
                    deleted: true,
                },
            });
        },
        [client],
    );

    const onUserJoinedConversation = useCallback(
        ({id, profilePhoto, firstName, accountRole}) => {
            const {communityChatMembers, totalMemberCount} = client.readFragment({
                id: `ChatConversation:${conversationId}`,
                fragment: membersFragment,
            });
            const newChatMembers = [
                ...communityChatMembers,
                {
                    __typename: "UserField",
                    id: id,
                    profilePhoto: profilePhoto,
                    firstName: firstName,
                    accountRole: accountRole,
                },
            ];
            client.writeFragment({
                id: `ChatConversation:${conversationId}`,
                fragment: membersFragment,
                data: {
                    communityChatMembers: newChatMembers,
                    totalMemberCount: totalMemberCount + 1,
                },
            });
        },
        [client, conversationId],
    );

    const onUserLeftConversation = useCallback(
        ({id}) => {
            const {communityChatMembers, totalMemberCount} = client.readFragment({
                id: `ChatConversation:${conversationId}`,
                fragment: membersFragment,
            });
            const newChatMembers = communityChatMembers.filter((member) => member.id !== id);
            client.writeFragment({
                id: `ChatConversation:${conversationId}`,
                fragment: membersFragment,
                data: {
                    communityChatMembers: newChatMembers,
                    totalMemberCount: totalMemberCount - 1,
                },
            });
        },
        [client, conversationId],
    );

    useSocketListener(sendMessageEvent, conversationId, onNewMessage);
    useSocketListener(deleteMessageEvent, conversationId, onSocketDeleteMessage);
    useSocketListener(userJoinedEvent, conversationId, onUserJoinedConversation);
    useSocketListener(userLeftEvent, conversationId, onUserLeftConversation);

    // once the messagesQuery first load is complete we process all of the queued up messages
    useEffect(() => {
        if (messagesInitialLoading || queueProcessed.current || !queue.current.length) return;
        try {
            addChatMessageToApolloCache(queue.current);
            queueProcessed.current = true;
        } catch (e) {
            reportError(e);
        }
    }, [messagesInitialLoading, reportError, addChatMessageToApolloCache]);

    const onSend = useCallback(
        async ({
            text,
            reply,
            giphy,
            images,
            files,
        }: {
            text: string;
            reply?: CommunityChatMessage;
            giphy: GifDto;
            images?: IImage[];
            files?: IFile[];
        }) => {
            try {
                await Sentry.startSpan({name: "onSend", op: "function"}, async () => {
                    const userData = client.readFragment({
                        id: `UserField:${user.id}`,
                        fragment: gql`
                            fragment UserProfileData on UserField {
                                communityProfile {
                                    id
                                    profilePhoto
                                }
                            }
                        `,
                    });

                    const created = new Date().toISOString();
                    const optimisticMessage: CommunityChatMessage = {
                        id: `offline-id-${created}`,
                        text,
                        created,
                        deleted: false,
                        likesCount: 0,
                        likes: [],
                        sender: {
                            id: user.id,
                            firstName: user.firstName,
                            accountRole: user.accountRole,
                            profilePhoto: user.profilePhoto,
                            country: user.country,
                            communityProfile: userData.communityProfile,
                        },
                        richContent:
                            reply || giphy || images || files
                                ? {
                                      __typename: "RichMessageContent",
                                  }
                                : null,
                    };
                    const variables: SendCommunityChatMessageMutationVariables = {
                        messageDto: {conversationId, text},
                    };

                    if (reply || giphy || images || files) {
                        variables.messageDto.richContent = {};
                    }

                    if (reply) {
                        optimisticMessage.richContent.reply = {
                            ...reply,
                            sender: {
                                ...reply.sender,
                            },
                        };
                        variables.messageDto.richContent.reply = {
                            id: reply.id,
                        };
                    }

                    if (giphy) {
                        optimisticMessage.richContent.giphy = {
                            ...giphy,
                        };
                        variables.messageDto.richContent.giphy = {
                            ...giphy,
                        };
                    }

                    if (images) {
                        optimisticMessage.richContent.images = images.map((image) => ({
                            url: image.uri,
                        }));
                    }

                    if (files) {
                        optimisticMessage.richContent.attachments = files.map((file) => ({
                            link: file.s3Url,
                            type: AttachmentType.Pdf,
                            size: file.size.toString(),
                            fileName: file.name,
                        }));
                        variables.messageDto.richContent.attachments = files.map((file) => ({
                            link: file.s3Url,
                            type: AttachmentType.Pdf,
                            size: file.size.toString(),
                            fileName: file.name,
                        }));
                    }

                    Sentry.startSpan({name: "addChatMessageToApolloCache", op: "function"}, () => {
                        addChatMessageToApolloCache([optimisticMessage]);
                    });

                    if (images) {
                        try {
                            const uploadService = new UploadService(client);
                            const promises = images.map((image) => {
                                return uploadService.uploadFileSafe(
                                    uploadService.prepImageParams(image),
                                );
                            });
                            const response = await Promise.all(promises);
                            variables.messageDto.richContent.images = response.map((image) => ({
                                url: image.mediaUrl + image.fileName,
                            }));
                        } catch (error) {
                            deleteChatMessageFromApolloCache(optimisticMessage);
                            open({
                                title: "Oh no...",
                                message:
                                    "Something went wrong when uploading your image(s). Please try again later.",
                                buttons: [{text: "Ok"}],
                            });
                            throw error;
                        }
                    }

                    const result = await Sentry.startSpan(
                        {name: "sendMessage", op: "function"},
                        () => {
                            return sendMessage({variables});
                        },
                    );

                    // We must use more verbose updateQuery instead of writeFragment because writeFragment doesn't work
                    // when the ids are changing in the cache like it it the case with updating the optimistic message.
                    Sentry.startSpan({name: "updateQuery", op: "function"}, () => {
                        updateQuery((cacheData) => {
                            if (!cacheData?.getChatConversationMessages) return cacheData;
                            return {
                                ...cacheData,
                                getChatConversationMessages: {
                                    ...cacheData?.getChatConversationMessages,
                                    messages: [
                                        ...cacheData.getChatConversationMessages.messages.map(
                                            (message: CommunityChatMessage) => {
                                                if (message.id !== optimisticMessage.id)
                                                    return message;
                                                if (
                                                    result.data.sendConversationMessage.richContent
                                                        ?.images
                                                ) {
                                                    return {
                                                        ...message,
                                                        id: result.data.sendConversationMessage.id,
                                                        richContent: {
                                                            ...message.richContent,
                                                            images: result.data
                                                                .sendConversationMessage.richContent
                                                                .images,
                                                        },
                                                    };
                                                }
                                                return {
                                                    ...message,
                                                    id: result.data.sendConversationMessage.id,
                                                };
                                            },
                                        ),
                                    ],
                                },
                            };
                        });
                    });

                    Sentry.startSpan({name: "trackEvent", op: "function"}, () => {
                        trackEvent(TrackEvents.MESSAGE_SENT, {
                            conversationId,
                            giphy: giphy ? true : undefined,
                            groupType,
                            images: images ? `${images.length} image(s)` : undefined,
                            files: files ? `${files.length} file(s)` : undefined,
                            accountRole: user.accountRole,
                            communityId: data?.getChatConversation?.communityId,
                        });
                    });
                });
            } catch (sendError) {
                reportError(sendError);
            }
        },
        [
            user,
            addChatMessageToApolloCache,
            deleteChatMessageFromApolloCache,
            open,
            sendMessage,
            conversationId,
            updateQuery,
            trackEvent,
            reportError,
            groupType,
            client,
            data?.getChatConversation?.communityId,
        ],
    );

    const {like, unlike} = useLike({conversationId, user, groupType});

    return useMemo(
        () => ({
            fetchMore,
            networkStatus,
            messages: chatMessages.data?.getChatConversationMessages?.messages || EMPTY_MESSAGES,
            loading: chatMessages.loading,
            hasMore: chatMessages.data?.getChatConversationMessages?.hasMore || false,
            error: chatMessages.error,
            refetch: chatMessages.refetch,
            onSend,
            onDelete,
            like,
            unlike,
            groupType,
        }),
        [chatMessages, onSend, fetchMore, networkStatus, onDelete, like, unlike, groupType],
    );
}
