import {View, Text} from "@unibuddy/patron";

import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {PrivateGroupsFFWrapper} from "ubcommunity-shared/src/FeatureFlags/Wrappers/PrivateGroupsFFWrapper";

import {CreateGroupButton} from "./CreateGroupButton";

export const ExploreCreateGroupSection = ({handleCreate}) => {
    const {isDesktop} = useIsDesktop();
    const {darkModeEnabled} = useCommunityTheme();

    return (
        <PrivateGroupsFFWrapper>
            <View
                margin="medium"
                padding="medium"
                flexDirection={isDesktop ? "row" : "column"}
                justifyContent="space-between"
                alignItems={isDesktop ? "flex-start" : "center"}
                borderColor={darkModeEnabled ? "darkGrey" : "grey"}
                borderWidth={1}
                borderRadius="xsmall"
            >
                <View
                    paddingBottom={isDesktop ? undefined : "medium"}
                    alignItems={isDesktop ? "flex-start" : "center"}
                >
                    <View paddingBottom={isDesktop ? "small" : "medium"}>
                        <Text size="medium" weight="bold">
                            Haven't found what you are looking for?
                        </Text>
                    </View>

                    <Text size="medium" color="altTextColor">
                        Create a new group
                    </Text>
                </View>

                <CreateGroupButton handleCreate={handleCreate} />
            </View>
        </PrivateGroupsFFWrapper>
    );
};
