import React from "react";
import {AvatarBeam} from "../AvatarBeam/AvatarBeam";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {Image} from "react-native";

export const UniversityAvatar = ({user, size, ...props}) => {
    const {university} = useCommunity();

    if (university?.squareLogo)
        return (
            <Image
                {...props}
                width={size}
                height={size}
                style={{...props.style, width: size, height: size, backgroundColor: "#eee"}}
                accessibilityLabel={user.firstName}
                source={{
                    width: size,
                    height: size,
                    uri: university.squareLogo,
                }}
            />
        );

    return <AvatarBeam id={user.id} name={user.firstName} size={size} />;
};
