import {PropsWithChildren, useEffect} from "react";
import {Image, ScrollView} from "react-native";
import type {FormikHelpers} from "formik";
import moment from "moment";
import {array} from "yup";
import {Form, Stack, SubmitButton, Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";

import {boldFontStyles} from "../Styles/fontStyles";
import {EditNationality} from "../Settings/AccountInformation/EditNationality";
import useAuth from "../Auth/hooks/useAuth";
import {EditCourse} from "../Settings/AccountInformation/EditCourse";
import {useDiscoverableUniversity} from "./useDiscoverableUniversity";
import ActivityIndicator from "../General/ActivityIndicator/ActivityIndicator";
import {QueryErrorHandler} from "../General/Errors/QueryErrorHandler";
import {EditYearOfEntry} from "../Settings/AccountInformation/EditYearOfEntry/EditYearOfEntry";
import {useUpdateUniversityLinkStep2Mutation} from "../types";
import {MutationErrorHandler} from "../General/Errors/MutationErrorHandler";
import {SpaceType, useSpaces} from "../StudentApp/SpacesProvider";
import {useNavigate} from "../Hooks/useNavigate";
import {Routes, TrackEvents} from "../constants";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";

interface SignUpStepTwoProps {
    universityId: string;
    onDismiss: () => void;
}

export enum SignUpStepTwoStrings {
    Title = "Tell us a bit about you to start chatting",
    Loading = "Loading university ...",
    Submit = "Start Chatting",
}

const CenterWrapper = ({children}: PropsWithChildren) => {
    return (
        <View flex="1" justifyContent="center" alignItems="center">
            {children}
        </View>
    );
};

type FormValues = {
    selectCountry: {name: string; id: string; code: string}[];
    selectCourse: {name: string; id: string}[];
    selectYearOfEntry?: {name: string; id: string}[];
};

export const SignUpStepTwo = ({universityId, onDismiss}: SignUpStepTwoProps) => {
    const {user, authState, setAuth} = useAuth();
    const {isDesktop} = useIsDesktop();
    const {university, loading, error, refetch, showYearOfEntry} =
        useDiscoverableUniversity(universityId);
    const [updateLink, {loading: mutationLoading, error: mutationError}] =
        useUpdateUniversityLinkStep2Mutation();
    const {refetch: refetchSpaces} = useSpaces();
    const {navigate} = useNavigate();
    const {trackEvent} = useAnalytics();

    useEffect(() => {
        trackEvent(TrackEvents.DISCOVER_SIGNUP_STEP_TWO_SCREEN_VIEWED, {
            universityId,
            universityName: university?.name,
            universitySlug: university?.slug,
        });
    }, [trackEvent, universityId, university]);

    const handleLink = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
        const variables = {
            universitySlug: university?.slug,
            countryId: values.selectCountry[0].id,
            degreesInterested: [values.selectCourse[0].id],
            dateOfEntry: showYearOfEntry
                ? values.selectYearOfEntry &&
                  moment(values.selectYearOfEntry[0].id, "MMMM YYYY").format("YYYY-MM")
                : undefined,
            sourceTrackingParams: JSON.stringify({
                ub_source: "Unibuddy App",
            }),
        };

        try {
            await updateLink({
                variables,
            });
            trackEvent(TrackEvents.DISCOVER_SIGNUP_STEP_TWO_FORM_SUBMITTED, {
                universityId,
                universityName: university?.name,
                universitySlug: university?.slug,
                countryName: values.selectCountry[0].name,
                degreeName: values.selectCourse[0].name,
                dateOfEntry: variables.dateOfEntry,
            });
            setAuth({
                ...authState,
                me: {
                    ...authState.me,
                    anyUser: {
                        ...authState.me.anyUser,
                        country: values.selectCountry[0],
                    },
                },
            });
            await refetchSpaces();
            navigate({
                path: Routes.HOME,
                options: {
                    selected: university?.slug,
                    type: SpaceType.UNIVERSITY,
                },
            });
        } catch (error) {
            console.error(error);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const validationSchema = {
        selectCountry: array().required().min(1, "This field is required"),
        selectCourse: array().required().min(1, "This field is required"),
        selectYearOfEntry: showYearOfEntry
            ? array().required().min(1, "This field is required")
            : undefined,
    };

    let initialValues = {};
    const country = user?.country;
    initialValues = country
        ? {
              ...initialValues,
              selectCountry: [
                  {
                      name: country.name,
                      id: country.id,
                      code: country.code,
                  },
              ],
          }
        : {
              ...initialValues,
              selectCountry: [],
          };

    initialValues = {
        ...initialValues,
        selectCourse: [],
    };

    if (showYearOfEntry) {
        initialValues = {
            ...initialValues,
            selectYearOfEntry: [],
        };
    }

    if (loading) {
        return (
            <CenterWrapper>
                <ActivityIndicator accessibilityLabel={SignUpStepTwoStrings.Loading} />
            </CenterWrapper>
        );
    }

    if (error) {
        return (
            <CenterWrapper>
                <QueryErrorHandler
                    error={error}
                    retryCallback={refetch}
                    meta={{
                        component: "SignUpStepTwo",
                        query: "useGetUniversityQuery",
                    }}
                />
            </CenterWrapper>
        );
    }

    return (
        <ScrollView style={{flex: 1}}>
            <Form
                onSubmit={handleLink}
                validationSchema={validationSchema}
                initialValues={initialValues}
            >
                <BackButton paddingBottom="xsmall" callback={onDismiss} />
                <View flex="1" gap="large" alignItems="center" padding="medium">
                    <Stack align="center" space="small-medium">
                        <Image
                            style={{width: 48, height: 48, borderRadius: 6}}
                            source={{
                                uri: university?.squareLogo ?? "",
                            }}
                            width={70}
                            height={70}
                        />
                        <Text
                            size="large"
                            style={{
                                ...boldFontStyles,
                            }}
                        >
                            {university?.name}
                        </Text>
                    </Stack>
                    <Text>{SignUpStepTwoStrings.Title}</Text>
                    <View w={isDesktop ? "60%" : "100%"} maxW="500px" gap="large">
                        <EditNationality />
                        <EditCourse universitySlug={university?.slug ?? ""} />
                        {showYearOfEntry ? <EditYearOfEntry universityId={universityId} /> : null}
                        <Stack space="small">
                            <SubmitButton block color="primary" loading={mutationLoading}>
                                <Text color="white">{SignUpStepTwoStrings.Submit}</Text>
                            </SubmitButton>
                            {mutationError ? (
                                <MutationErrorHandler
                                    error={mutationError}
                                    meta={{
                                        component: "SignUpStepTwo",
                                        mutation: "useUpdateUniversityLinkStep2Mutation",
                                    }}
                                />
                            ) : null}
                        </Stack>
                    </View>
                </View>
            </Form>
        </ScrollView>
    );
};
