import {useCallback} from "react";
import {ChatMessage} from "ubcommunity-shared/src/types";

export const useReactionUtils = () => {
    const getUserIdsForReaction = useCallback((message: ChatMessage, unicode) => {
        return message?.reactions?.find((r) => r.reactionUnicode === unicode)?.userIds ?? [];
    }, []);

    const getUserIdsForLikes = useCallback((message: ChatMessage) => {
        return message?.likes ?? [];
    }, []);

    return {
        getUserIdsForReaction,
        getUserIdsForLikes,
    };
};
