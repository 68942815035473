import {ReactElement} from "react";
import {View, Heading, usePatronTheme} from "@unibuddy/patron";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useMediaQuery} from "ubcommunity-shared/src/Hooks/useMediaQuery";
import {MediaQueries} from "ubcommunity-shared/src/constants";
import {HEADER_TITLE} from "./PeopleSubHeader";

export const PeopleSubHeader = ({
    noOfPeople = "0",
    addOnRight,
    addonBottom,
}: {
    noOfPeople: string;
    addOnRight?: ReactElement;
    addonBottom?: ReactElement;
}) => {
    const theme = usePatronTheme();
    const matches = useMediaQuery(MediaQueries.HEIGHT_GREATER_THAN_420PX);

    return (
        <View flexShrink="0" borderBottomWidth={1} borderColor={theme?.colors?.navbarBorderColor}>
            <View
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingX="small-medium"
                paddingY={matches ? "small-medium" : "none"}
            >
                <Heading level="1" size="xxsmall" style={{...boldFontStyles}}>
                    {HEADER_TITLE}
                </Heading>
                {addOnRight}
            </View>
            {addonBottom}
            {/* <View h={33} paddingX="small-medium" borderBottomWidth={1} borderColor="grey50">
                <Text
                    size="small"
                    style={{...semiBoldFontStyles}}
                    color={darkModeEnabled ? "white" : "grey600"}
                >
                    {noOfPeople} total people
                </Text>
            </View> */}
        </View>
    );
};
