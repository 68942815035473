import {Stack, TextArea, Text, PatronThemeProvider, FormGroup} from "@unibuddy/patron";
import {UniversityQuestion, UniversityQuestionOption} from "ubcommunity-shared/src/types";
import {CheckBoxOptions} from "./CheckBoxOptions";

export const FeelConfidentQuestion = ({
    question,
    options,
}: {
    question: UniversityQuestion["text"];
    options: UniversityQuestionOption[];
}) => {
    const a11yLabel =
        "What would make you feel more confident in your decision? Other things you’d like to know (optional)";

    return (
        <Stack space="medium">
            <PatronThemeProvider
                theme={{
                    TextInput: {
                        backgroundColor: "#fff",
                    },
                }}
            >
                <CheckBoxOptions question={question} options={options} />
            </PatronThemeProvider>
            <Stack space="xsmall">
                <Text size="small" color="grey700">
                    (Optional) Other things you’d like to know
                </Text>
                <FormGroup>
                    <TextArea
                        name="others"
                        style={{
                            borderRadius: 5,
                            backgroundColor: "white",
                            resize: "vertical",
                        }}
                        aria-label={a11yLabel}
                        //@ts-ignore valid in RN
                        accessibilityLabel={a11yLabel}
                    />
                </FormGroup>
            </Stack>
        </Stack>
    );
};
