import * as React from "react";
import {SVGProps} from "react";

const PinnedMessageFilledIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.071 24.071"
        {...props}
    >
        <path
            d="m3.747 6.915 4.506-4.506a1.592 1.592 0 0 1 1.151-.456c.927 0 1.952.666 2.332 1.726l1.246 3.482 3.482 1.246c1.552.555 2.259 2.494 1.27 3.483l-1.723 1.723 5.798 5.798a.75.75 0 0 1-1.06 1.061l-5.798-5.798-1.723 1.723a1.592 1.592 0 0 1-1.151.456c-.927 0-1.952-.666-2.332-1.726l-1.246-3.482-3.482-1.246c-1.552-.555-2.259-2.494-1.27-3.483Z"
            fill="currentColor"
        />
    </svg>
);

export default PinnedMessageFilledIcon;
