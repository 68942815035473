import React, {useCallback, useEffect, useRef} from "react";
import {ScrollView} from "react-native";
import {View} from "@unibuddy/patron";
import {NetworkStatus} from "@apollo/client";

import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {useGetCommunityConversationsGroupsQuery} from "ubcommunity-shared/src/types";
import {Matches} from "ubcommunity-shared/src/Community/Matches";
import {Content} from "ubcommunity-shared/src/Community/Content";
import {
    QueryErrorHandler,
    useQueryErrorHandler,
} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {ExploreCreateGroupSection} from "ubcommunity-shared/src/Community/CreateGroup/ExploreCreateGroupSection";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {StudentConfidenceSurveyCTA} from "ubcommunity-shared/src/Explore/StudentConfidenceSurvey/StudentConfidenceSurveyCTA";
import {ExploreGroupsAndSearch} from "ubcommunity-shared/src/Explore/ExploreGroupsAndSearch";
import {GroupsListShimmer} from "ubcommunity-shared/src/Community/GroupList/GroupsListShimmer";
import {StudentEnrolledQuestionsCTA} from "ubcommunity-shared/src/Explore/StudentEnrolledQuestions";
import {useQueryParams} from "ubcommunity-shared/src/Hooks/useQueryParams";
import {ExploreGroupsListHeader} from "ubcommunity-shared/src/Explore/General/ExploreGroupsListHeader";

export const MobileExplorePage = () => {
    const scrollViewRef = useRef<ScrollView>(null);
    const groupsViewRef = useRef<HTMLDivElement>(null);
    const {selectedCommunity, select} = useCommunity();
    const {push} = useRelativeRouter();

    const {handleForbiddenError} = useQueryErrorHandler();

    const {getParams} = useQueryParams();

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (getParams("scrollToGroups")) {
            timeout = setTimeout(() => {
                scrollViewRef.current?.scrollTo({
                    y: groupsViewRef.current.getBoundingClientRect().y,
                    animated: true,
                });
            }, 700);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [getParams]);

    // when user clicks on a group
    const handleSelectChatGroup = useCallback(
        (id: string) => {
            push(`/chat/${id}`);
        },
        [push],
    );

    // fetch all chat groups for this community
    const {data, networkStatus, refetch, error} = useGetCommunityConversationsGroupsQuery({
        notifyOnNetworkStatusChange: true,
        variables: {communityId: selectedCommunity.id, offsetPagination: {limit: 15}},
    });

    React.useEffect(() => {
        handleForbiddenError(error, select);
    }, [error, select, handleForbiddenError]);

    if (!data && error) {
        return (
            <QueryErrorHandler
                retryCallback={refetch}
                error={error}
                meta={{
                    component: "MobileExplorePage",
                    query: "useAllCommunityChatGroupsQuery",
                }}
                layout="center"
                hideRedirectButton
            />
        );
    }

    return (
        <View flex="1" bgColor="#fff">
            <ScrollView ref={scrollViewRef}>
                <View>
                    <StudentEnrolledQuestionsCTA />
                    <StudentConfidenceSurveyCTA />
                    <Matches />
                    <Content />
                    <View marginTop="xlarge" ref={groupsViewRef}>
                        <View paddingX="small-medium">
                            <ExploreGroupsListHeader
                                handleCreate={() => push(Routes.CREATE_GROUP)}
                            />
                        </View>
                        {networkStatus === NetworkStatus.loading ? (
                            <GroupsListShimmer />
                        ) : (
                            <>
                                <ExploreGroupsAndSearch
                                    data={data}
                                    handleSelectChatGroup={handleSelectChatGroup}
                                />

                                <ExploreCreateGroupSection
                                    handleCreate={() => push(Routes.CREATE_GROUP)}
                                />
                            </>
                        )}
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};
