import React from "react";

const ChevronRightIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size || 10}
        height={props.size || 14}
        fill="none"
        {...props}
        viewBox="0 0 10 18"
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M.75 1.5 8.25 9l-7.5 7.5"
        />
    </svg>
);
export default ChevronRightIcon;
