import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {TrackEvents} from "ubcommunity-shared/src/constants";

import {
    IAttachmentProps,
    useAttachments,
    ALLOWED_FILE_FORMATS,
    ALLOWED_IMAGE_FORMATS,
} from "./useAttachments";
import {DropzoneOverlay} from "./DropzoneOverlay";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

export const Attachments = ({
    children,
    groupType,
    isAttachmentOpen,
    setIsAttachmentOpen,
    selectedImages,
    setSelectedImages,
    selectedFiles,
    setSelectedFiles,
}: PropsWithChildren<IAttachmentProps>) => {
    const {trackEvent} = useAnalytics();
    const {user} = useAuth();

    const [selectedType, setSelectedType] = useState<"image" | "file" | null>(null);
    const {validateExclusivity, validateImages, validateFiles} = useAttachments();

    const handleImages = useCallback(
        (refs) => {
            const images = refs.map((file) => ({
                uri: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                size: file.size,
                file,
            }));

            const allImages = [...selectedImages, ...images];

            const {isValid} = validateImages(allImages);

            if (!isValid) return;

            trackEvent(TrackEvents.IMAGES_SELECTED, {
                count: allImages.length,
                groupType,
                accountRole: user?.accountRole,
            });
            setSelectedImages(allImages);
        },
        [selectedImages, setSelectedImages, validateImages, trackEvent, groupType, user],
    );

    const handleFiles = useCallback(
        (refs) => {
            const files = refs.map((file) => ({
                uri: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                size: file.size,
                file,
            }));

            const allFiles = [...selectedFiles, ...files];

            const {isValid} = validateFiles(allFiles);

            if (!isValid) return;

            trackEvent(TrackEvents.ATTACHMENTS_SELECTED, {
                count: allFiles.length,
                groupType,
                accountRole: user?.accountRole,
            });
            setSelectedFiles(allFiles);
        },
        [selectedFiles, setSelectedFiles, validateFiles, trackEvent, groupType, user],
    );

    const handleAttachments = useCallback(
        (refs) => {
            const allRefs = [...selectedImages, ...selectedFiles, ...refs];

            const {isAllFiles, isAllImages} = validateExclusivity(allRefs);

            if (isAllImages) {
                setSelectedType("image");
            }
            if (isAllFiles) {
                setSelectedType("file");
            }

            if (isAllImages) handleImages(refs);
            if (isAllFiles) handleFiles(refs);

            setIsAttachmentOpen(null);
        },
        [
            handleFiles,
            handleImages,
            setIsAttachmentOpen,
            selectedFiles,
            selectedImages,
            validateExclusivity,
        ],
    );

    const onAttachmentDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            const refs = [...acceptedFiles, ...rejectedFiles.map((file) => file.file)];

            trackEvent(TrackEvents.ATTACHMENTS_DROPPED, {
                count: refs.length,
                groupType,
                accountRole: user?.accountRole,
            });

            handleAttachments(refs);
        },
        [handleAttachments, trackEvent, groupType, user],
    );

    const acceptedFormats =
        selectedType === "image"
            ? ALLOWED_IMAGE_FORMATS
            : selectedType === "file"
            ? ALLOWED_FILE_FORMATS
            : [...ALLOWED_IMAGE_FORMATS, ...ALLOWED_FILE_FORMATS];
    // create map with keys as values of ALLOWED_IMAGE_FORMATS and value as []
    // this is required by react-dropzone as per
    // https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
    const accept = Object.fromEntries(new Map(acceptedFormats.map((key) => [key, []])));

    const {
        isDragActive,
        getRootProps,
        getInputProps,
        open: openFilePicker,
    } = useDropzone({
        onDrop: onAttachmentDrop,
        noClick: true,
        noKeyboard: true,
        accept,
    });

    useEffect(() => {
        if (isAttachmentOpen) {
            openFilePicker();
            setIsAttachmentOpen(null);
        }
    }, [isAttachmentOpen, setIsAttachmentOpen, openFilePicker]);

    useEffect(() => {
        if (!selectedImages.length && !selectedFiles.length) {
            setSelectedType(null);
        }
    }, [selectedImages, selectedFiles]);

    return (
        <View flex="1" {...getRootProps()}>
            {isDragActive ? <DropzoneOverlay /> : null}
            <input {...getInputProps()} />
            {children}
        </View>
    );
};
