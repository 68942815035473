import styled from "styled-components";
import {useState} from "react";
import {
    Text,
    View,
    PatronThemeProvider,
    colors,
    Box,
    Button,
    useLocalStorage,
} from "@unibuddy/patron";
import {differenceInCalendarDays} from "date-fns";
import {useAnalytics} from "@unibuddy/tracking";
import {IconXMark} from "@unibuddy/icons";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";

import {MobileAppDownloadDialog} from "./MobileAppDownloadDialog";
import Logo from "ubcommunity-web/src/General/MobileAppDownloadPrompt/images/ub-logo-store.png";

const Bold = styled.span`
    font-weight: 700;
`;

const ClearButton = styled(Button)`
    &:hover {
        color: #000;
    }
`;

export const MobileAppDownloadPrompt = () => {
    const {trackEvent} = useAnalytics();
    const {isDesktop} = useIsDesktop();

    const [downloadAppDialog, setDownloadAppDialog] = useState(false);
    const [showNewAppDownloadDialog, setShowNewAppDownloadDialog] = useLocalStorage(
        LocalStorageNames.MOBILE_DOWNLOAD_BANNER,
        null,
    );

    const handleDismiss = () => {
        trackEvent(TrackEvents.APP_INSTALL_BANNER_DISMISSED);
        setShowNewAppDownloadDialog(new Date().toISOString());
    };

    const handleDownloadButton = () => {
        trackEvent(TrackEvents.APP_INSTALL_BANNER_DESKTOP_MODAL_OPEN);
        setDownloadAppDialog(true);
    };

    const lastSeenLessThanTwoDays =
        differenceInCalendarDays(new Date(), new Date(showNewAppDownloadDialog as string)) < 2;

    if (lastSeenLessThanTwoDays) {
        return null;
    }

    return (
        <PatronThemeProvider
            theme={{
                Button: {
                    colors: {
                        custom: {
                            bgColor: colors.teal150,
                            outlineColor: "white",
                            clearColor: "#F3F5F5",
                            clearHoverBgColor: colors.teal150,
                        },
                    },
                },
            }}
        >
            <View padding="small" paddingRight={["none", "small"]} bgColor="#272B3D">
                <View
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={["none", "small"]}
                >
                    <View
                        flexGrow="1"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={["small", "small", "large"]}
                    >
                        <View flexDirection="row" gap="small" alignItems="center">
                            <Box
                                flexShrink="0"
                                display={["none", "block"]}
                                as="img"
                                width="48px"
                                height="48px"
                                src={Logo}
                                aria-label="Unibuddy app store icon"
                            />
                            {isDesktop ? (
                                <Text color="white" lineHeight="large">
                                    <Bold>Download the Unibuddy app</Bold> to chat on the go and
                                    stay notified of messages
                                </Text>
                            ) : (
                                <Text color="white" lineHeight="large">
                                    Chat on the go in the <Bold>Unibuddy app</Bold>
                                </Text>
                            )}
                        </View>

                        <Button color="custom" onClick={handleDownloadButton} size="lg">
                            <Text size="small" color="black" weight="bold">
                                {isDesktop ? "Get the Unibuddy app" : "Download"}
                            </Text>
                        </Button>
                    </View>
                    <Box flexShrink="0" padding={["xxsmall", "none"]}>
                        <ClearButton
                            color="custom"
                            aria-label="Close download app prompt"
                            iconOnly
                            clear
                            onClick={handleDismiss}
                        >
                            <IconXMark />
                        </ClearButton>
                    </Box>
                </View>
            </View>
            <MobileAppDownloadDialog
                isOpen={downloadAppDialog}
                onDismiss={() => {
                    setDownloadAppDialog(false);
                }}
            />
        </PatronThemeProvider>
    );
};
