import React from "react";
import {Box, Text} from "@unibuddy/patron";

export const FilterSelectionCount = ({count}: {count: number}) => {
    return (
        <Box
            data-testid="filter-selection-count"
            style={{
                opacity: count > 0 ? 1 : 0,
            }}
            bgColor="teal100"
            padding="xsmall"
            borderRadius="xsmall"
        >
            <Text size="small" color="teal900">
                {count} selected
            </Text>
        </Box>
    );
};
