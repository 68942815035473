import {useGetUniversityFeaturesQuery} from "../types";
import {useSpaces} from "../StudentApp/SpacesProvider";

export function useUniversityFeature() {
    const {selectedSpace} = useSpaces();

    const {data} = useGetUniversityFeaturesQuery({
        variables: {
            universitySlug: selectedSpace?.slug,
        },
    });

    const isFeatureEnabled = (feature: string) => {
        if (!feature || !data?.university?.config?.features) return false;

        const universityContentValues = data?.university?.config?.features?.find(
            (item) => item?.feature?.name === feature,
        );
        return (
            universityContentValues?.enabledByUnibuddy &&
            universityContentValues?.enabledByUniversity
        );
    };

    return {
        isFeatureEnabled,
    };
}
