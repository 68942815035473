import React from "react";
import {FlatList, TouchableOpacity} from "react-native";
import {Column, Columns, Inline, Text, View, usePatronTheme} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {DegreeField} from "ubcommunity-shared/src/types";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import CheckboxSvg from "ubcommunity-shared/src/Svgs/CheckboxSvg";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {themeUnitToNativeValue} from "ubcommunity-shared/src/Utils";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {Filter, FilterGroups, usePeopleFilters} from "../PeopleFiltersProvider";

type Props = {
    callback: () => void;
    degreesData: (DegreeField | null)[];
};

export const CourseSelector = ({callback, degreesData}: Props) => {
    const {darkModeEnabled} = useCommunityTheme();
    const {filters, setFilters} = usePeopleFilters();
    const theme = usePatronTheme();
    const {trackEvent} = useAnalytics();

    const onSelect = (courseSelected: DegreeField) => {
        const degreeId = courseSelected.id;
        const oldFilters = (filters.areaOfStudy as Filter[FilterGroups.AREA_OF_STUDY]) || [];
        if (oldFilters.find((id) => id === degreeId)) {
            trackEvent(TrackEvents.PEOPLE_AREA_OF_STUDY_FILTER_DESELECTED, {
                degreeId: courseSelected.id,
                degreeName: courseSelected.name,
            });
            setFilters({
                ...filters,
                areaOfStudy: oldFilters.filter((id) => id !== degreeId),
            });
            return;
        }
        trackEvent(TrackEvents.PEOPLE_AREA_OF_STUDY_FILTER_SELECTED, {
            degreeId: courseSelected.id,
            degreeName: courseSelected.name,
        });
        setFilters({
            ...filters,
            areaOfStudy: [...oldFilters, degreeId],
        });
    };

    return (
        <View flex="1">
            <Columns space="medium" verticalAlign="center">
                <Column>
                    <BackButton
                        borderWidth={0}
                        paddingBottom="xsmall"
                        callback={callback}
                        text="Area of study"
                    />
                </Column>
                {/* <Column width="content">
                    <FilterSelectionCount count={filters["areaOfStudy"]?.length} />
                </Column> */}
            </Columns>

            <FlatList
                data={degreesData}
                keyExtractor={(item) => item?.id}
                renderItem={({item}) => {
                    const selected = filters.areaOfStudy?.includes(item?.id) || false;

                    return (
                        <TouchableOpacity
                            style={{
                                padding: themeUnitToNativeValue(theme.space.small),
                            }}
                            onPress={() => onSelect(item)}
                            accessibilityLabel={`${selected ? "Deselect" : "Select"}
                    ${item?.name}
                    `}
                        >
                            <Inline verticalAlign="center" space="small">
                                <CheckboxSvg
                                    filled={selected || false}
                                    color={darkModeEnabled ? "white" : theme.colors.primaryColor}
                                />
                                <Text>{item?.name}</Text>
                            </Inline>
                        </TouchableOpacity>
                    );
                }}
            />
        </View>
    );
};
