import {View, Heading} from "@unibuddy/patron";

export const HEADER_TITLE = "Filter people";

export const PeopleSubHeader = ({noOfPeople = "0"}: {noOfPeople: string}) => {
    return (
        <>
            <View h={70} w={650} paddingY="large" flexDirection="row" alignItems="center">
                <Heading level="1" align="center" size="small" weight="600">
                    {HEADER_TITLE}
                </Heading>
            </View>
            {/* <View h={33} paddingX="small-medium" borderBottomWidth={1} borderColor="grey50">
                <Text
                    size="small"
                    style={{...semiBoldFontStyles}}
                    color={darkModeEnabled ? "white" : "grey600"}
                >
                    {noOfPeople} total people
                </Text>
            </View> */}
        </>
    );
};
