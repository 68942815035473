import React, {useRef} from "react";
import styled from "styled-components";
import {Box, Heading, Inline, Stack, usePatronTheme} from "@unibuddy/patron";
import ChevronRightIcon from "ubcommunity-shared/src/Icons/ChevronRightIcon";
import {CarouselProps} from "./types";
import {CarouselRaw} from "./CarouselRaw";

const MOVE_SCROLL_BUTTON = 200;

const CarouselNavigationButton = styled.button`
    cursor: pointer;
    border: none;
    background-color: transparent;
`;

export const Carousel = ({
    data,
    renderFirstItem,
    renderItem,
    renderLastItem,
    onScroll,
    itemGap,
    offset,
    title,
}: Readonly<CarouselProps>): JSX.Element => {
    const theme = usePatronTheme();
    const scrollViewRef = useRef<HTMLElement>(null);
    return (
        <Stack space="medium">
            <Box
                justifyContent="space-between"
                alignItems="center"
                flexDirection="row"
                width="100%"
                display="flex"
                paddingX={offset}
            >
                <Heading level="2" size="xxsmall" weight="bold" color="grey900">
                    {title}
                </Heading>
                <Inline space="small">
                    <CarouselNavigationButton
                        aria-label="Previous slide button"
                        onClick={() => {
                            if (scrollViewRef.current) {
                                scrollViewRef.current.scrollLeft -= MOVE_SCROLL_BUTTON;
                            }
                        }}
                    >
                        <ChevronRightIcon
                            color={theme.colors["purple600"]}
                            style={{transform: "rotate(180deg)"}}
                        />
                    </CarouselNavigationButton>
                    <CarouselNavigationButton
                        aria-label="Next slide button"
                        onClick={() => {
                            if (scrollViewRef.current) {
                                scrollViewRef.current.scrollLeft += MOVE_SCROLL_BUTTON;
                            }
                        }}
                    >
                        <ChevronRightIcon color={theme.colors["purple600"]} />
                    </CarouselNavigationButton>
                </Inline>
            </Box>

            <CarouselRaw
                data={data}
                renderFirstItem={renderFirstItem}
                renderItem={renderItem}
                renderLastItem={renderLastItem}
                onScroll={onScroll}
                itemGap={itemGap}
                offset={offset}
                ref={scrollViewRef}
            />
        </Stack>
    );
};
