import React from "react";
import {generateData, SIZE} from "./utils";

export const AvatarBeam = (props: {id: string; name: string; size?: number; colors?: string[]}) => {
    const name = props.name ?? "";

    const data = generateData(name, props.colors);

    return (
        <svg
            viewBox={"0 0 " + SIZE + " " + SIZE}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            {...props}
        >
            <mask
                id={`mask__beam${props.id}`}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={SIZE}
                height={SIZE}
            >
                <rect width={SIZE} height={SIZE} rx={20} fill="white" />
            </mask>
            <g mask={`url(#mask__beam${props.id})`} fill="transparent">
                <rect width={SIZE} height={SIZE} rx={20} fill={data.backgroundColor} />
                <rect
                    x="0"
                    y="0"
                    width={SIZE}
                    height={SIZE}
                    transform={
                        "translate(" +
                        data.wrapperTranslateX +
                        " " +
                        data.wrapperTranslateY +
                        ") rotate(" +
                        data.wrapperRotate +
                        " " +
                        SIZE / 2 +
                        " " +
                        SIZE / 2 +
                        ") scale(" +
                        data.wrapperScale +
                        ")"
                    }
                    fill={data.wrapperColor}
                    rx={data.isCircle ? SIZE : SIZE / 6}
                />
                <g
                    transform={
                        "translate(" +
                        data.faceTranslateX +
                        " " +
                        data.faceTranslateY +
                        ") rotate(" +
                        data.faceRotate +
                        " " +
                        SIZE / 2 +
                        " " +
                        SIZE / 2 +
                        ")"
                    }
                >
                    {data.isMouthOpen ? (
                        <path
                            d={"M15 " + (19 + data.mouthSpread) + "c2 1 4 1 6 0"}
                            stroke={data.faceColor}
                            fill="none"
                            strokeLinecap="round"
                        />
                    ) : (
                        <path
                            d={"M13," + (19 + data.mouthSpread) + " a1,0.75 0 0,0 10,0"}
                            fill={data.faceColor}
                        />
                    )}
                    <rect
                        x={14 - data.eyeSpread}
                        y={14}
                        width={1.5}
                        height={2}
                        rx={1}
                        stroke="none"
                        fill={data.faceColor}
                    />
                    <rect
                        x={20 + data.eyeSpread}
                        y={14}
                        width={1.5}
                        height={2}
                        rx={1}
                        stroke="none"
                        fill={data.faceColor}
                    />
                </g>
            </g>
        </svg>
    );
};

AvatarBeam.defaultProps = {
    colors: ["#3E3E48", "#2913B9", "#6852FB", "#CFC8FF", "#01D4CF"],
    size: 40,
};

export default AvatarBeam;
