import React, {memo, useState} from "react";
import {Text, View} from "@unibuddy/patron";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {Image} from "react-native";
import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {getDynamicImageUrlV2} from "ubcommunity-shared/src/Patron/getDynamicUrlV2";

export const SpotlightItem = memo(({image}: {image: IImagePreview}) => {
    const {reportError} = useErrorReporting();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    const onLoaded = () => {
        setLoading(false);
    };

    const onError = (error) => {
        setError(error);
    };

    if (error) {
        reportError(error);
        return (
            <Text weight="bold" color="white">
                An error occurred.
            </Text>
        );
    }

    return (
        <>
            {loading ? (
                <View
                    position="absolute"
                    w="100%"
                    h="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <ActivityIndicator />
                </View>
            ) : null}
            <Image
                source={{
                    uri: getDynamicImageUrlV2(image.url),
                }}
                style={{
                    width: "98%",
                    height: 900,
                }}
                resizeMode="contain"
                onLoad={onLoaded}
                onError={onError}
            />
        </>
    );
});
