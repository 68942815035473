import React, {memo, useDeferredValue, useEffect, useMemo, useRef, useState} from "react";
import {View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {
    GetCommunityConversationsGroupsQuery,
    useGetLabelsQuery,
    GetLabelsQuery,
} from "ubcommunity-shared/src/types";
import {ExploreGroupNames} from "ubcommunity-shared/src/constants";
import {
    GroupListMemoized as GroupList,
    TrackingKey,
    filterGroupByName,
} from "ubcommunity-shared/src/Community/GroupList/GroupList";
import {SectionHeading} from "ubcommunity-shared/src/General/SectionHeading";
import {TrackEvents} from "ubcommunity-shared/src/constants";

import {ExploreSearchBar} from "./ExploreSearchBar";

type Props = {
    data: GetCommunityConversationsGroupsQuery | undefined;
    handleSelectChatGroup: (id: string) => void;
};

type ParsedDataProps = {
    data: GetCommunityConversationsGroupsQuery["getCommunityConversations"];
    handleSelectChatGroup: (id: string) => void;
};

const POPULAR_GROUPS_NUMBER = 2;
const PopularGroups = ({data, handleSelectChatGroup}: ParsedDataProps) => {
    if (data.length < 6) {
        return null;
    }

    return (
        <>
            <SectionHeading>{ExploreGroupNames.POPULAR_GROUPS}</SectionHeading>
            <GroupList
                trackingKey={TrackingKey.POPULAR}
                onJoin={handleSelectChatGroup}
                groups={data
                    .slice()
                    .sort((a, b) => b.communityChatMembers!.length - a.communityChatMembers!.length)
                    .slice(0, POPULAR_GROUPS_NUMBER)}
            />
        </>
    );
};

type AllGroupsProps = ParsedDataProps & {hasFilterApplied: boolean};

const AllGroups = ({data, handleSelectChatGroup, hasFilterApplied}: AllGroupsProps) => (
    <>
        {!hasFilterApplied ? <SectionHeading>{ExploreGroupNames.ALL_GROUPS}</SectionHeading> : null}
        <GroupList trackingKey={TrackingKey.ANY} onJoin={handleSelectChatGroup} groups={data} />
    </>
);

const ResultGroups = memo(
    ({
        data,
        handleSelectChatGroup,
        searchValue,
    }: ParsedDataProps & {
        searchValue: string;
    }) => {
        const filteredGroups = filterGroupByName(searchValue, data);
        const deferredGroups = useDeferredValue(filteredGroups);

        return (
            <>
                <SectionHeading>Results</SectionHeading>

                <GroupList
                    trackingKey={TrackingKey.SEARCH_RESULT}
                    onJoin={handleSelectChatGroup}
                    groups={deferredGroups}
                />
            </>
        );
    },
);

const arrayOrder = {
    "Area of study": 0,
    Housing: 1,
    "University life": 2,
    "Hobbies & interests": 3,
    International: 4,
};

// Tried to push back with Product but they said it is a requirement to have in this specific order 🤷‍♂️
const sortLabelsArray = (labels: GetLabelsQuery["getLabels"]) => {
    if (!labels) {
        return null;
    }

    const labelsArray = [];

    labels.forEach((label) => {
        labelsArray[arrayOrder[label.name]] = label;
    });

    return labelsArray;
};

export const ExploreGroupsAndSearch = ({data, handleSelectChatGroup}: Props) => {
    const [searchValue, setSearchValue] = useState("");
    const [deferredSearchValue, setDeferredSearchValue] = useState("");
    // eslint-disable-next-line no-restricted-globals
    const [selectedFilter, setSelectedFilter] = useState<{id: string; name: string} | null>(null);
    const {trackEvent} = useAnalytics();

    const filteredData = useMemo(() => {
        if (!selectedFilter) {
            return data?.getCommunityConversations;
        }

        return data?.getCommunityConversations.filter((group) =>
            group.labels?.find((label) => label.id === selectedFilter?.id),
        );
    }, [data?.getCommunityConversations, selectedFilter]);

    const lastOnChange = useRef(0);

    const {data: labels} = useGetLabelsQuery();

    const handleSelectFilter = (filter: {id: string; name: string}) => {
        if (filter.id === selectedFilter?.id) {
            setSelectedFilter(null);
            return;
        }

        trackEvent(TrackEvents.GROUPS_FILTER_CLICKED, {label: filter.name});
        setSelectedFilter(filter);
    };

    const onChangeText = (text: string) => {
        setSearchValue(text);
        lastOnChange.current = Date.now();
    };

    useEffect(() => {
        if (searchValue === "") {
            setDeferredSearchValue("");
            return;
        }

        const timeout = setTimeout(() => {
            if (Date.now() - lastOnChange.current > 500) {
                setDeferredSearchValue(searchValue);
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [searchValue]);

    if (!filteredData) {
        return null;
    }

    return (
        <View aria-live="polite">
            <ExploreSearchBar
                onChangeText={onChangeText}
                value={searchValue}
                selectedFilter={selectedFilter}
                setSelectedFilter={handleSelectFilter}
                labels={sortLabelsArray(labels?.getLabels)}
            />
            {searchValue === "" && !selectedFilter ? (
                <PopularGroups data={filteredData} handleSelectChatGroup={handleSelectChatGroup} />
            ) : null}

            {searchValue === "" ? (
                <AllGroups
                    data={filteredData}
                    handleSelectChatGroup={handleSelectChatGroup}
                    hasFilterApplied={!!selectedFilter}
                />
            ) : (
                <ResultGroups
                    data={filteredData}
                    handleSelectChatGroup={handleSelectChatGroup}
                    searchValue={deferredSearchValue}
                />
            )}
        </View>
    );
};
