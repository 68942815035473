import React from "react";
import {Platform} from "react-native";
import {LoadingButton, View} from "@unibuddy/patron";

type LoadingButtonWithIconProps = {
    children: React.ReactNode;
    icon: React.ReactNode;
} & React.ComponentProps<typeof LoadingButton>;

/**
 * This component exists to work around a patron issue that makes
 * it hard to center the icon and the text in a loading button.
 * This needs to be fixed in patron!
 */

export function LoadingButtonWithIcon(props: LoadingButtonWithIconProps) {
    const isIos = Platform.OS === "ios";

    return (
        <LoadingButton {...props}>
            <View
                flexDirection="row"
                alignItems="center"
                flex={1}
                paddingTop={isIos ? "xxsmall" : "none"} // For some reason, the container is not vertically centered on Loading Button on mobile
            >
                <View marginRight="xxsmall">{props.icon}</View>
                {props.children}
            </View>
        </LoadingButton>
    );
}
