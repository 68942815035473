import React, {
    memo,
    useState,
    useRef,
    useTransition,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";
import noop from "lodash/noop";
import {useFormikContext} from "formik";
import {
    Button,
    Column,
    Columns,
    Divider,
    Inline,
    InlineItem,
    Stack,
    Text,
    TextInput,
    usePatronTheme,
    View,
} from "@unibuddy/patron";

import ChevronDownIcon from "ubcommunity-shared/src/Icons/ChevronDownIcon";
import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

type BaseItemType = {id: string};

// make query string bold
// const SuggestiveResult = memo(({text}: {text: string}) => {
//     const queryIndex = text.toLowerCase().indexOf(query.toLowerCase());
//     const queryLength = query.length;
//     const firstPart = text.slice(0, queryIndex);
//     const secondPart = text.slice(queryIndex, queryIndex + queryLength);
//     const thirdPart = text.slice(queryIndex + queryLength);

//     return (
//         <Inline>
//             <Text size="medium">{firstPart}</Text>
//             <Text size="medium" weight="bold">
//                 {secondPart}
//             </Text>
//             <Text size="medium">{thirdPart}</Text>
//         </Inline>
//     );
// });

export enum SelectionType {
    ADD = "add",
    REMOVE = "remove",
    CLEAR = "clear",
}

export interface IAutocompleteProps {
    data: Array<BaseItemType>;
    renderItem: (item: BaseItemType, onRemoveItem: (item: BaseItemType) => void) => React.ReactNode;
    resultsRenderItem?: (item: BaseItemType) => React.ReactNode;
    onSelect?: (e: Array<BaseItemType>, SelectionType) => void;
    onDropdownOpened: () => void;
    name?: string;
    id?: string;
    multiple?: boolean;
    placeholder?: string;
    disabled?: boolean;
    resultKey?: string;
    required?: boolean;
    showResultsOnRender?: boolean;
    resultsContainerHeight?: number;
}

export const AutocompleteV2 = memo(
    forwardRef(
        (
            {
                data,
                renderItem,
                onSelect = noop,
                onDropdownOpened = noop,
                multiple,
                name,
                id,
                placeholder = "",
                disabled = false,
                resultKey = "title",
                showResultsOnRender = false,
                resultsContainerHeight = 220,
                resultsRenderItem = (item) => <Text size="medium">{item[resultKey]}</Text>,
            }: IAutocompleteProps,
            ref,
        ) => {
            const formik = useFormikContext();
            const LIST_ID = `results-${id}`;

            const inputRef = useRef(null);
            const theme = usePatronTheme();
            const {darkModeEnabled} = useCommunityTheme();

            const [query, setQuery] = useState("");
            const [showResults, setShowResults] = useState(showResultsOnRender);
            const [selected, setSelected] = useState<IAutocompleteProps["data"]>(() => {
                if (formik && !formik.isSubmitting) {
                    return formik.values[name] || [];
                }
                return [];
            });

            // To handle focus on keyboard events.
            const focusedItemIndexRef = useRef(-1);
            const [_, startTransition] = useTransition(); // eslint-disable-line @typescript-eslint/no-unused-vars

            useImperativeHandle(ref, () => ({
                selected,
                onClear,
            }));

            useEffect(() => {
                if (showResults) {
                    onDropdownOpened();
                }
            }, [showResults, onDropdownOpened]);

            const onChange = (e) => {
                startTransition(() => {
                    setQuery(e);
                });
            };

            const onAddItem = (item: BaseItemType) => {
                const newItems = [...selected, item];

                const output = multiple ? newItems : [item];

                onSelect(output, SelectionType.ADD);

                if (formik) {
                    const {setFieldValue} = formik;
                    setFieldValue(name, output);
                }

                setSelected(newItems);
                setShowResults(false);
                focusedItemIndexRef.current = -1;
                setQuery("");

                inputRef.value = "";
            };

            const onRemoveItem = (item: BaseItemType) => {
                const newItems = selected.filter((i) => i.id !== item.id);

                const output = newItems;

                onSelect(output, SelectionType.REMOVE);

                if (formik) {
                    const {setFieldValue} = formik;
                    setFieldValue(name, output);
                }
                setSelected(newItems);
                focusedItemIndexRef.current = -1;
            };

            const onClear = () => {
                setSelected([]);
                focusedItemIndexRef.current = -1;
                setQuery("");

                onSelect([], SelectionType.CLEAR);

                if (formik) {
                    const {setFieldValue} = formik;
                    setFieldValue(name, []);
                }
            };

            const cannotSelectMore = !multiple && selected.length > 0;

            const hideResults = disabled || !showResults;

            const results =
                data &&
                data.length &&
                data.filter((item) => {
                    if (selected.find((i) => i.id === item.id)) return false;
                    if (item[resultKey].toLowerCase().includes(query.toLowerCase())) return true;
                    return false;
                });
            const resultsLen = results ? results.length : 0;

            const setFocusedItemIndex = (index: number) => {
                const itemToFocus = document.getElementById(`result-item-${index}`);
                itemToFocus?.focus();
            };

            const handleKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
                if (!showResults || !resultsLen) return;
                if (event.key === "ArrowDown") {
                    event.preventDefault();
                    const focusIndex =
                        focusedItemIndexRef.current < resultsLen - 1
                            ? focusedItemIndexRef.current + 1
                            : 0;
                    focusedItemIndexRef.current = focusIndex;
                    setFocusedItemIndex(focusIndex);
                } else if (event.key === "ArrowUp") {
                    event.preventDefault();
                    const focusIndex =
                        focusedItemIndexRef.current > 0
                            ? focusedItemIndexRef.current - 1
                            : resultsLen - 1;
                    focusedItemIndexRef.current = focusIndex;
                    setFocusedItemIndex(focusIndex);
                } else if (event.key === "Escape") {
                    event.preventDefault();
                    setFocusedItemIndex(-1);
                    focusedItemIndexRef.current = -1;
                    setShowResults(false);
                }
            };

            return (
                <Stack>
                    <View
                        width="100%"
                        bgColor={theme.TextInput?.backgroundColor}
                        minH={theme.TextInput?.height}
                        style={{
                            borderRadius: parseInt(theme.TextInput?.borderRadius.slice(0, 1)),
                            borderColor:
                                document.activeElement.id === id
                                    ? theme.colors.primaryColor
                                    : "transparent",
                            borderWidth: 1,
                        }}
                    >
                        <Columns verticalAlign="center">
                            <Column>
                                <Inline verticalAlign="center">
                                    {selected.map((item) => (
                                        <View
                                            key={item.id}
                                            justifyContent="center"
                                            paddingX="small"
                                            h={theme.TextInput?.height}
                                        >
                                            {renderItem(item, onRemoveItem)}
                                        </View>
                                    ))}
                                    {cannotSelectMore ? null : (
                                        <InlineItem flexGrow={1}>
                                            <TextInput
                                                id={id}
                                                ref={inputRef}
                                                type="search"
                                                placeholder={placeholder}
                                                placeholderTextColor={
                                                    darkModeEnabled ? "#D0D9D9" : "#bfc8cf"
                                                }
                                                onChangeText={onChange}
                                                onFocus={() => setShowResults(true)}
                                                style={{
                                                    borderColor: "transparent",
                                                }}
                                                onBlur={() => inputRef.current?.blur()}
                                                data-testid="autocompleteInput"
                                                pointerEvents={disabled ? "none" : "auto"}
                                                title="Search for an item"
                                                role="combobox"
                                                aria-autocomplete="both"
                                                aria-expanded={showResults}
                                                aria-controls={`results-${id}`}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </InlineItem>
                                    )}
                                </Inline>
                            </Column>
                            <Column width="content">
                                {cannotSelectMore ? (
                                    <Button
                                        disabled={disabled}
                                        aria-label="Clear selection"
                                        iconOnly
                                        clear
                                        onClick={onClear}
                                    >
                                        <CloseIcon color={theme?.TextBlock?.color} size={18} />
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={disabled}
                                        aria-label="Show options"
                                        aria-expanded={showResults}
                                        iconOnly
                                        clear
                                        onClick={() => {
                                            setShowResults(!showResults);
                                            focusedItemIndexRef.current = -1;
                                        }}
                                        id={`show-options-${id}`}
                                        onKeyDown={handleKeyDown}
                                    >
                                        <ChevronDownIcon
                                            color={theme?.TextBlock?.color}
                                            size={12}
                                            style={{
                                                transform: `rotate(${
                                                    showResults ? "180deg" : "0deg"
                                                })`,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    </Button>
                                )}
                            </Column>
                        </Columns>
                    </View>

                    <View
                        w="100%"
                        maxH={hideResults ? 0 : resultsContainerHeight}
                        bgColor={theme?.colors?.navbarColor}
                        borderColor={theme?.Divider?.bgColor}
                        borderWidth={1}
                        style={{overflowY: "scroll"}}
                    >
                        <ul
                            id={LIST_ID}
                            aria-label="Options"
                            style={{
                                listStyle: "none",
                                padding: 0,
                                margin: 0,
                            }}
                            tabIndex={-1}
                            onKeyDown={handleKeyDown}
                        >
                            {showResults ? (
                                <>
                                    {resultsLen ? (
                                        results.map((item, index) => (
                                            <li key={item.id}>
                                                <button
                                                    style={{
                                                        padding: "20px 16px",
                                                        width: "100%",
                                                        background: "transparent",
                                                        border: "none",
                                                        cursor: "pointer",
                                                        textAlign: "left",
                                                    }}
                                                    id={`result-item-${index}`}
                                                    onClick={() => onAddItem(item)}
                                                    tabIndex={-1}
                                                >
                                                    {resultsRenderItem(item)}
                                                </button>
                                                <Divider />
                                            </li>
                                        ))
                                    ) : (
                                        <View paddingX="small" paddingY="medium">
                                            <Text align="center" size="small" fontStyle="italic">
                                                No results found
                                            </Text>
                                        </View>
                                    )}
                                </>
                            ) : null}
                        </ul>
                    </View>
                </Stack>
            );
        },
    ),
);
