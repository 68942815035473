import * as React from "react";
import Svg, {Path} from "react-native-svg";

export default function DownloadIcon(props) {
    const {color = "currentColor", ...rest} = props;

    return (
        <Svg
            width={props.size || 40}
            height={props.size || 41}
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <Path
                d="M1 10.5V12C1 12.3978 1.15804 12.7794 1.43934 13.0607C1.72064 13.342 2.10218 13.5 2.5 13.5H11.5C11.8978 13.5 12.2794 13.342 12.5607 13.0607C12.842 12.7794 13 12.3978 13 12V10.5M10 7.5L7 10.5M7 10.5L4 7.5M7 10.5V1.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}
