import React from "react";
import {View} from "@unibuddy/patron";

import {UserAvatar} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {getAccountRoleName} from "ubcommunity-shared/src/General/getAccountRoleName";
import {AccountRoles} from "ubcommunity-shared/src/constants";

import {CircularProgressionBar} from "./CircularProgressionBar";

export type progressionBarStyle = {
    lineColor: string;
    activeLineColor: string;
    strokeWidth: number;
    activeStrokeWidth: number;
};

type AvatarWithCircularProgressionBarProps = {
    progressPercentage: number;
    avatarSize: number;
    circularProgressionBarSize: number;
    user: {id?: string; firstName?: string; accountRole?: string; profilePhoto?: string};
    progressionBarStyle: progressionBarStyle;
};

export const AvatarWithCircularProgressionBar = ({
    progressPercentage,
    avatarSize,
    circularProgressionBarSize,
    user,
    progressionBarStyle,
}: AvatarWithCircularProgressionBarProps) => {
    const accountRole = getAccountRoleName(user.accountRole);

    return (
        <View position="relative" alignItems="center" justifyContent="center">
            {accountRole !== AccountRoles.UNIVERSITY_ADMIN ? (
                <CircularProgressionBar
                    progressPercentage={progressPercentage}
                    size={circularProgressionBarSize}
                    progressionBarStyle={{...progressionBarStyle}}
                />
            ) : null}

            <UserAvatar user={user} size={avatarSize} />
        </View>
    );
};
