import React from "react";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {DesktopSettingsPage} from "../DesktopSettingsPage/DesktopSettingsPage";
import {MobileSettingsPage} from "../MobileSettingsPage/MobileSettingsPage";

export function SettingsPage() {
    const {isDesktop} = useIsDesktop();
    const {user} = useAuth();
    const {university, select} = useCommunity();

    return isDesktop ? (
        <DesktopSettingsPage user={user} university={university} select={select} />
    ) : (
        <MobileSettingsPage user={user} university={university} select={select} />
    );
}
