import * as React from "react";
import styled from "styled-components";
import {SVGProps} from "react";

const PinnedMessageSvg = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", ...rest} = props;
    return (
        <svg
            height={24}
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24.071 24.071"
            {...rest}
        >
            <path
                d="M15.916 3.485c.035 0 .079.005.09.017l4.506 4.506c.014.014.029.096 0 .222a1.16 1.16 0 0 1-.716.788l-3.482 1.246a1.5 1.5 0 0 0-.907.907l-1.246 3.482c-.171.477-.644.732-.92.732-.035 0-.079-.005-.09-.017l-4.506-4.506c-.014-.014-.029-.096 0-.222a1.16 1.16 0 0 1 .716-.788l3.482-1.246a1.5 1.5 0 0 0 .907-.907l1.246-3.482c.171-.477.644-.732.92-.732m0-1.5c-.927 0-1.952.666-2.332 1.726l-1.246 3.482-3.482 1.246c-1.552.555-2.259 2.494-1.27 3.483l4.506 4.506c.313.313.721.456 1.151.456.927 0 1.952-.666 2.332-1.726l1.246-3.482 3.482-1.246c1.552-.555 2.259-2.494 1.27-3.483l-4.506-4.506a1.592 1.592 0 0 0-1.151-.456Z"
                fill={color}
            />
            <path
                style={{
                    fill: "none",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "1.5px",
                }}
                stroke={color}
                d="m4.041 19.973 5.798-5.798"
            />
        </svg>
    );
};

const PinnedMessageIcon = styled(PinnedMessageSvg)`
    transform: scaleX(-1);
`;

export default PinnedMessageIcon;
