import * as React from "react";
const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
        <path
            stroke={props.color ?? "#000"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17.31 15.521a3 3 0 0 1 4.681 2.72c-1.2.42-2.474.582-3.74.479a5.995 5.995 0 0 0-6-5.97 5.996 5.996 0 0 0-5.059 2.772m11.057 3.197.001.031c0 .225-.012.447-.037.666A11.945 11.945 0 0 1 12.25 21c-2.17 0-4.207-.576-5.963-1.584a6.062 6.062 0 0 1-.037-.697m0 0a8.98 8.98 0 0 1-3.739-.477 3 3 0 0 1 4.681-2.72m-.942 3.197a5.973 5.973 0 0 1 .942-3.197M15.25 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.501 0 2.25 2.25 0 0 1 4.501 0Zm-13.5 0a2.25 2.25 0 1 1-4.499 0 2.25 2.25 0 0 1 4.5 0Z"
        />
    </svg>
);
export default SvgComponent;
