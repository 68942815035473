import {useEffect} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Button, Stack, Text, useLocalStorage, View} from "@unibuddy/patron";
import {LocalStorageNames, TrackEvents} from "ubcommunity-shared/src/constants";
import {ModalTypes, useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useLogout} from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";

export enum EmailInvitePromptStrings {
    Title = "It looks like you’ve signed up with the wrong email",
    Signup = "Sign up with invited email",
    Continue = "Continue with wrong email",
}

export const EmailInvitePrompt = () => {
    const {openModal, dismissModal} = useModal();
    const {logout} = useLogout();
    const {user} = useAuth();
    const {trackEvent} = useAnalytics();

    const [emailInvite, setEmailInvite] = useLocalStorage(
        LocalStorageNames.EMAIL_INVITE,
        undefined,
    );

    const onLogout = () => {
        trackEvent(TrackEvents.SIGNUP_INVITED_EMAIL_WARNING_LOGOUT_PRESSED);
        logout();
        dismissModal();
    };

    const onContinue = () => {
        trackEvent(TrackEvents.SIGNUP_INVITED_EMAIL_WARNING_CONTINUE_PRESSED);
        setEmailInvite(undefined);
        dismissModal();
    };

    useEffect(() => {
        if (!emailInvite) return;

        if (emailInvite === user?.email) return;

        openModal({
            component: (
                <View
                    paddingTop="xlarge"
                    alignItems="center"
                    paddingX="medium"
                    paddingBottom="medium"
                >
                    <View paddingTop="medium" />
                    <Stack space="large">
                        <Text size="xlarge" align="center" style={{...boldFontStyles}}>
                            {EmailInvitePromptStrings.Title}
                        </Text>
                        <Text align="center">
                            You were invited with the email <b>{emailInvite}</b> Please sign up or
                            log in with this email to access your community.
                        </Text>
                        <Stack space="xsmall">
                            <Button block color="primary" onClick={onLogout}>
                                <Text color="white">{EmailInvitePromptStrings.Signup}</Text>
                            </Button>
                            <Button block color="grey" ghost onClick={onContinue}>
                                <Text>{EmailInvitePromptStrings.Continue}</Text>
                            </Button>
                        </Stack>
                    </Stack>
                </View>
            ),
            type: ModalTypes.DIALOG,
            size: "xxsmall",
        });
        trackEvent(TrackEvents.SIGNUP_INVITED_EMAIL_WARNING_SHOWN);
    }, []);

    return null;
};
