import React, {FC, PropsWithChildren} from "react";
import {Heading} from "@unibuddy/patron";

export const ScreenTitle: FC<PropsWithChildren<{align?: "center" | "left" | "right"}>> = ({
    children,
    ...props
}) => {
    if (!children) return null;

    return (
        <Heading
            data-testid="ScreenTitle"
            level="1"
            size="xxsmall"
            weight="500"
            {...props}
            truncate
            nowrap
            numberOfLines={1}
        >
            {children}
        </Heading>
    );
};
