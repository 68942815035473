import {Image, TouchableOpacity} from "react-native";
import {Box, Column, Columns, Inline, Stack, Text, usePatronTheme, View} from "@unibuddy/patron";
import {IconChevronRight} from "@unibuddy/icons";
import {UniversityField} from "../types";
import {boldFontStyles} from "../Styles/fontStyles";
import {Flag} from "../Patron/Flag";

type DiscoverableUniversityItemProps = {
    item: UniversityField | null;
    onPress: (arg0: UniversityField) => void;
};

export const DiscoverableUniversityItem = ({item, onPress}: DiscoverableUniversityItemProps) => {
    const theme = usePatronTheme();

    if (!item) {
        return null;
    }

    return (
        <TouchableOpacity
            accessibilityRole="button"
            accessibilityLabel={`Select ${item.name} from ${item.country?.name ?? "Country"}`}
            onPress={() => onPress(item)}
        >
            <View paddingY="medium">
                <Columns verticalAlign="center">
                    <Column width="content">
                        <Image
                            source={{
                                width: 64,
                                height: 64,
                                uri: item.squareLogo ?? undefined,
                            }}
                            style={{borderRadius: 6}}
                        />
                    </Column>
                    <Column>
                        <View paddingX="small">
                            <Stack space="small-medium">
                                <Text
                                    style={{
                                        ...boldFontStyles,
                                    }}
                                    truncate
                                    numberOfLines={1}
                                >
                                    {item.name}
                                </Text>
                                <Inline space="xxsmall" verticalAlign="center">
                                    <Flag
                                        code={item.country?.code ?? ""}
                                        label={item.country?.name ?? "Country"}
                                        size={12}
                                    />
                                    <Text>{item.country?.name ?? "Country"}</Text>
                                </Inline>
                            </Stack>
                        </View>
                    </Column>
                    <Column width="content">
                        <IconChevronRight color={theme?.colors.grey800} />
                    </Column>
                </Columns>
            </View>
            <Box borderBottomWidth={1} borderColor="grey50" />
        </TouchableOpacity>
    );
};
