import {PropsWithChildren} from "react";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {TodoListItemWrapper as WrapperWeb} from "./TodoListItemWrapper.web";
import {TodoListItemWrapper as WrapperMobile} from "./TodoListItemWrapper.mobile";

export const TodoListItemWrapper = ({children}: PropsWithChildren) => {
    const {isDesktop} = useIsDesktop();

    return isDesktop ? (
        <WrapperWeb>{children}</WrapperWeb>
    ) : (
        <WrapperMobile>{children}</WrapperMobile>
    );
};
