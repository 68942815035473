export enum AccountRoles {
    STUDENT = "Student",
    AMBASSADOR = "Student ambassador",
    UNIVERSITY_ADMIN = "University Admin",
    UNIBUDDY_ADMIN = "Unibuddy Admin",
    STAFF = "Staff ambassador",
}

// users account roles in backend
// Todo: Use this enum for magic strings, add more values to this enum as we keep removing the magic strings
export const enum UserAccountRoles {
    APPLICANT = "applicant",
    UNIVERSITY = "university",
    MENTOR = "mentor",
    STAFF = "staff",
}
