import {Button, Dialog, Inline, View} from "@unibuddy/patron";
import React, {useRef, useState, useEffect} from "react";
import {Dimensions, FlatList} from "react-native";
import styled, {createGlobalStyle} from "styled-components";
import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";
import {SpotlightItem} from "ubcommunity-shared/src/Chat/Attachments/Images/SpotlightItem";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import ArrowBackIcon from "ubcommunity-shared/src/Icons/ArrowBackIcon";

const SpotlightModalStyle = createGlobalStyle`
    [data-reach-dialog-overlay] {
        z-index: 999 !important;
    }
`;

const Overlay = styled(Dialog)`
    && {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const SpotlightModal = ({isOpen, onDismiss, images, selectedImage}) => {
    const ref = useRef<any>();
    const [index, setIndex] = useState(0);
    const {isDesktop} = useIsDesktop();

    useEffect(() => {
        setIndex(images.findIndex((image) => image.url === selectedImage.url));
    }, [images, selectedImage]);

    const screenWidth = Dimensions.get("window").width;

    const nextItem = () => {
        if (index === images.length - 1) return;
        ref.current.scrollToIndex({
            index: index + 1,
            animated: true,
        });
        setIndex(index + 1);
    };

    const previousItem = () => {
        if (index === 0) return;
        ref.current.scrollToIndex({
            index: index - 1,
            animated: true,
        });
        setIndex(index - 1);
    };

    const FooterControls = () => {
        if (!isDesktop) return null;
        return (
            <View w="100%" alignItems="center" paddingBottom="medium">
                <Inline space="medium" verticalAlign="center">
                    <Button round iconOnly color="grey" onClick={previousItem}>
                        <ArrowBackIcon color="grey" />
                    </Button>
                    <Button round iconOnly color="grey" onClick={nextItem}>
                        <ArrowBackIcon style={{transform: "rotate(180deg)"}} color="grey" />
                    </Button>
                </Inline>
            </View>
        );
    };

    return (
        <>
            <SpotlightModalStyle />
            <Overlay size="fill" onDismiss={onDismiss} isOpen={isOpen} CloseButton={null}>
                <View height="100%" position="relative">
                    <View position="absolute" top={20} right={20} zIndex={2} onClick={onDismiss}>
                        <Button color="grey" iconOnly round aria-label="Close image viewer">
                            <CloseIcon color="grey" />
                        </Button>
                    </View>
                    <FlatList
                        ref={ref}
                        scrollEnabled={!isDesktop}
                        horizontal
                        data={images}
                        renderItem={({item}) => (
                            <View
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                w={screenWidth}
                            >
                                <SpotlightItem image={item} />
                            </View>
                        )}
                        keyExtractor={(item) => item.url}
                        snapToAlignment="start"
                        decelerationRate={"fast"}
                        bounces={true}
                        snapToInterval={screenWidth}
                        getItemLayout={(_, index) => ({
                            length: screenWidth,
                            offset: screenWidth * index,
                            index,
                        })}
                        initialScrollIndex={images.findIndex(
                            (image) => image.url === selectedImage.url,
                        )}
                        showsHorizontalScrollIndicator={false}
                        disableIntervalMomentum
                    />
                    <FooterControls />
                </View>
            </Overlay>
        </>
    );
};
