import {Stack, View} from "@unibuddy/patron";
import {Shimmer} from "ubcommunity-shared/src/General/Shimmer/Shimmer";
import {Rect} from "react-native-svg";

const ShimmerStack = () => {
    return (
        <Stack space="medium">
            <Shimmer width={180} height={20}>
                <Rect x="0" y="0" rx="4" ry="4" width="180" height="20" />
            </Shimmer>
            <Shimmer width={700} height={370}>
                <Rect x="0" y="0" rx="12" ry="12" width="200" height="370" />
                <Rect x="212" y="0" rx="12" ry="12" width="200" height="370" />
                <Rect x="424" y="0" rx="12" ry="12" width="200" height="370" />
                <Rect x="636" y="0" rx="12" ry="12" width="200" height="370" />
            </Shimmer>
        </Stack>
    );
};

export const MatchesCarousalShimmer = () => {
    return (
        <View marginTop="small-medium" paddingLeft="small-medium" accessibilityLabel="Loading matches">
            <Stack space="small-medium">
                <ShimmerStack />
                <ShimmerStack />
            </Stack>
        </View>
    );
};
