import React from "react";
import {View} from "@unibuddy/patron";

export default function OverlayPhotoGradient({imageSize}: {imageSize: number}) {
    return (
        <View
            style={{
                position: "absolute",
                zIndex: 10,
                height: imageSize,
                width: imageSize,
                background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0) 34.58%, rgba(0, 0, 0, 0.9) 100%)",
            }}
        />
    );
}
