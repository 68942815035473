import React from "react";
import {Shimmer as PatronShimmer} from "@unibuddy/patron";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

//Responsive Shimmer with dark mode support
export const Shimmer = ({children, ...props}) => {
    const {darkModeEnabled} = useCommunityTheme();

    return (
        <PatronShimmer
            style={{width: "100%"}}
            backgroundColor={darkModeEnabled ? "#2B2D3F" : "#f5f6f7"}
            foregroundColor={darkModeEnabled ? "#424157" : "#eeeeee"}
            {...props}
        >
            {children}
        </PatronShimmer>
    );
};
