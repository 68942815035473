import React from "react";

import {Box, Stack, Text, View} from "@unibuddy/patron";

import {UserAvatarWithFlag} from "ubcommunity-shared/src/General/UserAvatar/UserAvatarWithFlag";
import {getAccountRoleName} from "ubcommunity-shared/src/General/getAccountRoleName";
import {PublicMentorField, UserField} from "ubcommunity-shared/src/types";
import {capitalize} from "ubcommunity-shared/src/Utils/capitalize";
import {UserAccountRoles} from "ubcommunity-shared/src/constants";

export const UserInfo = ({
    user,
    isBlocked,
}: {
    user: UserField | PublicMentorField;
    isBlocked?: boolean;
}) => (
    <View data-testid="user-info" w="100%" justifyContent="center">
        <Stack space="small" align="center">
            <Box marginBottom="small">
                <UserAvatarWithFlag
                    size={150}
                    user={user}
                    flagOptions={{size: 25, top: 120, right: 0}}
                    isBlocked={isBlocked}
                />
            </Box>
            <Text size="large" weight="600">
                {capitalize(user?.firstName ?? "")}
            </Text>
            {user.accountRole === "mentor" && user?.degree?.name ? (
                <Text size="small">{user?.degree?.name}</Text>
            ) : null}
            {user.accountRole === UserAccountRoles.STAFF && user?.staffFields?.department ? (
                <Stack space="xsmall" align="center">
                    <Text size="small" truncate>
                        {user?.staffFields?.role ?? ""}
                    </Text>
                    <Text size="small">{user?.staffFields?.department}</Text>
                </Stack>
            ) : null}
            {user.accountRole !== "admin" ? (
                <Box display="flex">
                    <View
                        bgColor="#CFC8FF"
                        padding="xsmall"
                        paddingX="xsmall"
                        borderRadius="xsmall"
                    >
                        <Text size="xxsmall" color="primaryColor">
                            {isBlocked
                                ? "You have blocked this user"
                                : getAccountRoleName(user.accountRole)}
                        </Text>
                    </View>
                </Box>
            ) : null}
        </Stack>
    </View>
);
