import React from "react";
import {View, Columns, Column} from "@unibuddy/patron";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {HousingFilter} from "./HousingFilter";
import {Platform, ScrollView} from "react-native";

interface HousingSelectorProps {
    callback: () => void;
}

export const HousingSelector: React.FC<HousingSelectorProps> = ({callback}) => {
    return (
        <View>
            <Columns space="medium" verticalAlign="center">
                <Column>
                    <BackButton
                        borderWidth={0}
                        paddingBottom="xsmall"
                        callback={callback}
                        text="Student housing"
                    />
                </Column>
            </Columns>
            <View marginTop="medium" marginX="medium">
                {Platform.OS === "ios" || Platform.OS === "android" ? (
                    <ScrollView automaticallyAdjustKeyboardInsets>
                        <HousingFilter />
                    </ScrollView>
                ) : (
                    <HousingFilter />
                )}
            </View>
        </View>
    );
};
