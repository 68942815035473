import {ApolloClient, gql} from "@apollo/client";
import {IImage} from "ubcommunity-shared/src/Chat/Attachments/useAttachments";

export const is2XXResponse = (status) => /2[0-9][0-9]/g.test(status);
export const is4XXResponse = (status) => /4[0-9][0-9]/g.test(status);

const getSignedS3PostFieldsQuery = gql`
    mutation GetSignedS3PostFields($objectName: String) {
        getSignedS3PostFields(objectName: $objectName) {
            postFieldsObject {
                url
                fileName
                mediaUrl
                fields {
                    acl
                    contentType
                    key
                    awsAccessKeyId
                    policy
                    signature
                    xAmzSecurityToken
                }
            }
        }
    }
`;

export default class UploadService {
    private client;

    constructor(client: ApolloClient<any>) {
        this.client = client;
    }

    private postFileToS3 = (file, url, fields) => {
        // These form fields should correspond to the post fields signed by S3
        const formData = new FormData();
        formData.append("acl", fields.acl);
        formData.append("Content-Type", fields.contentType);
        formData.append("key", fields.key);
        formData.append("AWSAccessKeyId", fields.awsAccessKeyId);
        formData.append("policy", fields.policy);
        formData.append("signature", fields.signature);
        formData.append("x-amz-security-token", fields.xAmzSecurityToken);
        formData.append("file", file);

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.open("POST", url, true);
            xhr.send(formData);

            xhr.addEventListener("readystatechange", () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    // 204 with no content
                    if (is2XXResponse(xhr.status)) {
                        resolve(null);
                    }

                    // 4XX status (Forbidden, Upload exceeded limits)
                    if (is4XXResponse(xhr.status)) {
                        reject("An upload error occurred.");
                    }

                    reject("An upload error occurred.");
                }
            });
            xhr.addEventListener("error", () => {
                reject("An upload error occurred.");
            });
        });
    };

    /**
     * Upload a file to S3 bucket with limits set on the file size.
     * For current file size limits, see getSignedS3PostFields mutation impl.
     * @param file {File}
     */
    public async uploadFileSafe(file: File) {
        try {
            const {url, fileName, mediaUrl, fields} = await this.getS3PostFields(file.name);
            await this.postFileToS3(file, url, fields);
            return {fileName, mediaUrl};
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public prepImageParams(image: IImage) {
        return image.file;
    }

    private async getS3PostFields(fileName: string) {
        const result = await this.client.mutate({
            mutation: getSignedS3PostFieldsQuery,
            variables: {
                objectName: fileName,
            },
        });

        return result.data.getSignedS3PostFields.postFieldsObject;
    }
}
