import React from "react";
import {string} from "yup";
import styled from "styled-components";
import type {FormikHelpers} from "formik";

import {
    TextInput,
    TextArea,
    FormGroup,
    SubmitButton,
    Stack,
    Form,
    Text,
    usePatronTheme,
    ErrorMessage,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {useCommunityCreatePrivateGroupMutation} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useCommunity} from "../CommunityProvider/CommunityProvider";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {useUpdateInboxQuery} from "ubcommunity-shared/src/Hooks/graphql/useUpdateInboxQuery";

const StyledTextArea = styled(TextArea)`
    resize: none;
    min-height: 100px;
    font-family: ${({theme}) => theme?.TextBlock?.fontFamily};
`;

interface CreatePrivateGroupFormValues {
    email: string;
    password: string;
}

export const CreatePrivateGroupV2 = ({handleSuccess}) => {
    const [createPrivateGroupMutation, {error}] = useCommunityCreatePrivateGroupMutation();
    const {selectedCommunity} = useCommunity();
    const {authState} = useAuth();
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();
    const theme = usePatronTheme();
    const {addConversationToInbox} = useUpdateInboxQuery();

    const handleSubmit = async (
        {groupName, description}: {groupName: string; description: string},
        formActions: FormikHelpers<CreatePrivateGroupFormValues>,
    ) => {
        const trimmedDesc = description.trim();
        try {
            const {data} = await createPrivateGroupMutation({
                variables: {
                    createCommunityConversationDTO: {
                        title: groupName,
                        communityId: selectedCommunity.id,
                        description: trimmedDesc,
                        isPrivate: true,
                    },
                },
            });

            if (data) {
                addConversationToInbox(data.createCommunityConversation);
            }

            trackEvent(TrackEvents.PRIVATE_GROUP_CREATED, {
                conversationId: data?.createCommunityConversation?.id,
                accountRole: authState.me.anyUser.accountRole,
            });

            formActions.resetForm();

            handleSuccess({
                id: data?.createCommunityConversation?.id,
            });
        } catch (error) {
            formActions.setFieldError("serverError", error.message);
            reportError(error);
        } finally {
            formActions.setSubmitting(false);
        }
    };

    return (
        <Form
            name="createPrivateGroup"
            onSubmit={handleSubmit}
            validationSchema={{
                groupName: string().required("Group name is a required field"),
                description: string().required(),
            }}
            initialValues={{
                groupName: "",
                description: "",
                serverError: "",
            }}
        >
            <Stack space="medium">
                <FormGroup label="What is the name of your group?">
                    <TextInput
                        required
                        name="groupName"
                        placeholder="E.g. English Literature Students"
                        placeholderTextColor={theme?.colors?.placeholderColor}
                    />
                </FormGroup>
                <FormGroup label="What is your group about?">
                    <StyledTextArea
                        required
                        rows={5}
                        name="description"
                        placeholder="E.g. A group for everyone studying English Literature to get to know each other and chat before we get to University!"
                        placeholderTextColor={theme?.colors?.placeholderColor}
                    />
                </FormGroup>
                <ErrorMessage
                    name="serverError"
                    component={
                        <MutationErrorHandler
                            error={error}
                            meta={{
                                component: "CreatePrivateGroupV2",
                                mutation: "useCommunityCreatePrivateGroupMutation",
                            }}
                        />
                    }
                />
                <SubmitButton size="sm" color="primary" block>
                    <Text size="medium" color="white" style={{...boldFontStyles}}>
                        Create group
                    </Text>
                </SubmitButton>
            </Stack>
        </Form>
    );
};
