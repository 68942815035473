import React from "react";
import {Pressable} from "react-native";
import {Box, Column, Columns, Stack, Text, Divider} from "@unibuddy/patron";

import {UserAvatar} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {AnyUserField} from "ubcommunity-shared/src/types";
import {GetFullName} from "ubcommunity-shared/src/General/GetFullName";

export const ProfileHero = ({user, onPress}: {user: AnyUserField; onPress?: () => void}) => (
    <Pressable
        onPress={onPress}
        accessible
        accessibilityRole="button"
        accessibilityLabel={`Open your profile (${user.firstName} ${user.lastName} ${user.email})`}
    >
        <Box paddingBottom="medium">
            <Stack space="large">
                <Columns space="medium" verticalAlign="center">
                    <Column width="content">
                        <UserAvatar size={72} user={user} />
                    </Column>
                    <Column>
                        <Stack space="small">
                            <Text size="large" nowrap truncate>
                                <GetFullName user={user} />
                            </Text>
                            <Text nowrap truncate color="secondaryText" size="small">
                                {user.email}
                            </Text>
                        </Stack>
                    </Column>
                </Columns>
                <Divider />
            </Stack>
        </Box>
    </Pressable>
);
