import React, {useState} from "react";
import {differenceInCalendarDays} from "date-fns";

import {useStringFeatureFlagWrapper} from "ubcommunity-shared/src/FeatureFlags/StringFlag/useStringFeatureFlagWrapper";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {POPUP_MANAGER} from "ubcommunity-shared/src/featureFlags";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {FeedbackPopup} from "../FeedbackPopup/FeedbackPopup";
import {PromoteEnhancedProfilesPopup} from "./PromoteEnhancedProfilesPopup";
import {StudentEnrollmentStage} from "./StudentEnrollmentStage";
import {FeelPreparedQuestions} from "./FeelPreparedQuestions";
import {RecruitStudentPopup} from "./RecruitStudentPopup";

const popupListComponents = {
    [POPUP_MANAGER.RECRUIT_STUDENTS]: {featureFlag: null, component: null},
    [POPUP_MANAGER.PROMOTE_ENHANCED_PROFILES]: {featureFlag: null, component: null},
    [POPUP_MANAGER.USER_FEEDBACK]: {featureFlag: null, component: null},
    [POPUP_MANAGER.STUDENT_ENROLLMENT_STAGE]: {featureFlag: null, component: null},
    [POPUP_MANAGER.FEEL_PREPARED_QUESTIONS]: {featureFlag: null, component: null},
};

function flagsListStringToArray(flagsList: string) {
    const flags = {};

    flagsList
        .trim()
        .split(",")
        .forEach((flag) => {
            if (popupListComponents[flag]) {
                flags[flag] = true;
            }
        });

    return flags;
}

const DAY_DIFF_LAST_SHOWN_POPUP = 2;

function hasPopupShownToday(lastShowDate: string) {
    if (!lastShowDate) {
        return false;
    }

    const differenceInDays = differenceInCalendarDays(new Date(Date.now()), new Date(lastShowDate));

    if (differenceInDays < DAY_DIFF_LAST_SHOWN_POPUP) {
        return true;
    }
    return false;
}

/**
 * @author
 * @function @PopupManager
 **/
export const PopupManager = () => {
    const [selectedPopup, setSelectedPopup] = useState<number>(0);
    const [isPopupActive, setIsPopupActive] = useState<boolean>(false);

    const {
        value: lastShown,
        setValue: setShown,
        loading,
    } = useAsyncLocalStorage(LocalStorageNames.HAS_SHOWN_POPUP_TODAY, null);

    const activeCommunityPopupTest = useStringFeatureFlagWrapper(
        POPUP_MANAGER.COMMUNITY_POPUP,
        POPUP_MANAGER.USER_FEEDBACK,
    );

    const activeFlags = flagsListStringToArray(activeCommunityPopupTest);
    const popupList = Object.keys(activeFlags).map((flag) => flag);

    const handleLastShowPopup = () => {
        setShown(new Date());
        setIsPopupActive(false);
    };

    const handleNextPopup = () => {
        if (
            !hasPopupShownToday(lastShown) &&
            !isPopupActive &&
            popupList.length > selectedPopup + 1
        ) {
            setSelectedPopup((state) => state + 1);
        }
    };

    if ((hasPopupShownToday(lastShown) && !isPopupActive) || loading) {
        return null;
    }

    popupListComponents[POPUP_MANAGER.RECRUIT_STUDENTS]["component"] = (
        <RecruitStudentPopup
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );

    popupListComponents[POPUP_MANAGER.PROMOTE_ENHANCED_PROFILES]["component"] = (
        <PromoteEnhancedProfilesPopup
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );
    popupListComponents[POPUP_MANAGER.USER_FEEDBACK]["component"] = (
        <FeedbackPopup
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );
    popupListComponents[POPUP_MANAGER.STUDENT_ENROLLMENT_STAGE]["component"] = (
        <StudentEnrollmentStage
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );
    popupListComponents[POPUP_MANAGER.FEEL_PREPARED_QUESTIONS]["component"] = (
        <FeelPreparedQuestions
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );
    popupListComponents[POPUP_MANAGER.RECRUIT_STUDENTS]["featureFlag"] =
        activeFlags[POPUP_MANAGER.RECRUIT_STUDENTS];
    popupListComponents[POPUP_MANAGER.PROMOTE_ENHANCED_PROFILES]["featureFlag"] =
        activeFlags[POPUP_MANAGER.PROMOTE_ENHANCED_PROFILES];
    popupListComponents[POPUP_MANAGER.USER_FEEDBACK]["featureFlag"] =
        activeFlags[POPUP_MANAGER.USER_FEEDBACK];
    popupListComponents[POPUP_MANAGER.STUDENT_ENROLLMENT_STAGE]["featureFlag"] =
        activeFlags[POPUP_MANAGER.STUDENT_ENROLLMENT_STAGE];
    popupListComponents[POPUP_MANAGER.FEEL_PREPARED_QUESTIONS]["featureFlag"] =
        activeFlags[POPUP_MANAGER.FEEL_PREPARED_QUESTIONS];

    return (
        <>
            {popupListComponents[popupList[selectedPopup]]?.featureFlag
                ? popupListComponents[popupList[selectedPopup]].component
                : null}
        </>
    );
};
