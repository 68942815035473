import React from "react";
import {View} from "@unibuddy/patron";
import {placeholderAvatar} from "@unibuddy/chat-ui";
import {UserProfile} from "ubcommunity-shared/src/types";
import {AvatarBeam} from "../General/AvatarBeam/AvatarBeam";
import {MentorAvatar} from "../General/UserAvatar/MentorAvatar";
import {UniversityAvatar} from "../General/UserAvatar/UniversityAvatar";
import {ApplicantAvatar} from "../General/UserAvatar/ApplicantAvatar";

type Sender = {
    id: string;
    firstName: string;
    accountRole: string;
    profilePhoto?: string;
    communityProfile?: UserProfile;
};

type ChatAvatarProps = {
    sender: Sender;
    size: number;
};

export const ChatAvatar = ({sender, size}: ChatAvatarProps) => {
    if (sender.accountRole === "university") {
        return <UniversityAvatar user={sender} size={size} style={{borderRadius: size}} />;
    }

    if (sender.accountRole === "applicant") {
        return (
            <View style={{borderRadius: size, overflow: "hidden"}}>
                <ApplicantAvatar user={sender} size={size} />
            </View>
        );
    }

    if (!sender.profilePhoto || sender.accountRole === "admin") {
        return <AvatarBeam id={sender.id} name={sender.firstName} size={size} />;
    }

    if (sender.accountRole === "mentor" || sender.accountRole === "staff") {
        return (
            <MentorAvatar user={sender} width={size} height={size} style={{borderRadius: size}} />
        );
    }

    return placeholderAvatar;
};
