import React from "react";
import {View, Stack, Heading, Text, Button} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useCommunityTheme} from "../Theme/CommunityThemeProvider";
import {boldFontStyles} from "../Styles/fontStyles";

const iceBreakers = [
    {
        key: "how-are-you",
        text: "Hey! 👋 How are you?",
    },
    {
        key: "movie",
        text: "Who would play you in a movie of your life? 🎬",
    },
    {
        key: "favourite-food",
        text: "What's your favourite food? 🍕",
    },
    {
        key: "karaoke-song",
        text: "What song would you pick for karaoke? 🎤",
    },
];

const DirectGroupEmptyState = ({handleSend}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const {trackEvent} = useAnalytics();

    const onSend = (iceBreaker) => {
        trackEvent("Ice breaker selected", {
            name: iceBreaker.key,
        });
        handleSend({
            text: iceBreaker.text,
        });
    };

    return (
        <View flex="1" justifyContent="flex-end" paddingTop="large">
            <View borderRadius="xsmall" padding="medium">
                <Stack space="large">
                    <Heading level="5" align="center">
                        Let's get this conversation started 🎉
                    </Heading>
                    <Text size="medium" align="center">
                        Starting a conversation doesn't need to be scary! Use one of our ice
                        breakers below:
                    </Text>
                    <Stack space="small">
                        {iceBreakers.map((iceBreaker) => (
                            <Button
                                key={iceBreaker.key}
                                block
                                ghost
                                color="primary"
                                onClick={() => onSend(iceBreaker)}
                            >
                                <Text
                                    align="center"
                                    size="small"
                                    style={{...boldFontStyles}}
                                    color={darkModeEnabled ? "white" : "primary"}
                                >
                                    {iceBreaker.text}
                                </Text>
                            </Button>
                        ))}
                    </Stack>
                </Stack>
            </View>
        </View>
    );
};

export default DirectGroupEmptyState;
