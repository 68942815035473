import {useGetUniversityQuery} from "../types";

export const enum CustomAttributeType {
    YEAR_OF_ENTRY = "static.year_of_entry",
}

export const useDiscoverableUniversity = (universityId: string) => {
    const {data, loading, error, refetch} = useGetUniversityQuery({
        variables: {
            id: universityId,
        },
    });

    const university = data?.university;

    const customAttributes = university?.customAttributes;

    const yearOfEntry = customAttributes?.find(
        (attr) => attr?.type === CustomAttributeType.YEAR_OF_ENTRY,
    );

    return {
        university,
        loading,
        error,
        refetch,
        showYearOfEntry: !!yearOfEntry,
    };
};
