import {useState} from "react";
import {Image, TouchableOpacity} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {Heading, LoadingButton, Stack, Text, View} from "@unibuddy/patron";
import {IconXMark} from "@unibuddy/icons";
import {Link} from "ubcommunity-shared/src/General/Link/Link";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {UniversityField, useUpdateUniversityLinkMutation} from "../types";
import {SpaceType, useSpaces} from "../StudentApp/SpacesProvider";
import {MutationErrorHandler} from "../General/Errors/MutationErrorHandler";
import {DiscoverEnterDialogAnimation} from "./DiscoverEnterDialogAnimation";
import {TrackEvents} from "../constants";
import {useNavigate} from "../Hooks/useNavigate";
import {useIsFeatureEnabledBoolean} from "../Hooks/useIsFeatureEnabledBoolean";
import {FeatureFlags} from "../featureFlags";

type DiscoverEnterDialogProps = {
    university: UniversityField | null;
    onClose: () => void;
};

export enum DiscoverEnterDialogStrings {
    ButtonText = "Start Chatting",
    Title = "Join the conversations with our current students and staff members",
    AgreeToTerms = "By clicking continuing you are agreeing to this",
    Error = "An error occurred! Please try again later.",
}

export const DiscoverEnterDialog = ({university, onClose}: DiscoverEnterDialogProps) => {
    const {trackEvent} = useAnalytics();
    const [loading, setLoading] = useState(false);
    const [updateLink, {error}] = useUpdateUniversityLinkMutation();
    const {refetch: refetchSpaces} = useSpaces();
    const {navigate} = useNavigate();
    const isEnhancedRedirectEnabled = useIsFeatureEnabledBoolean(
        FeatureFlags.STUDENT_APP_ENHANCED_LEADS_REDIRECT,
    );

    if (!university) return null;

    const handleLink = async () => {
        trackEvent(TrackEvents.DISCOVER_UNIVERSITY_LINK_CREATION_STARTED, {
            universityId: university.id,
            universityName: university.name,
            universitySlug: university.slug,
        });
        setLoading(true);
        try {
            await updateLink({
                variables: {
                    universitySlug: university.slug,
                    sourceTrackingParams: JSON.stringify({
                        ub_source: "Unibuddy App",
                    }),
                },
            });
            trackEvent(TrackEvents.DISCOVER_UNIVERSITY_LINK_CREATION_COMPLETED, {
                universityId: university.id,
                universityName: university.name,
                universitySlug: university.slug,
            });

            if (isEnhancedRedirectEnabled) {
                navigate({
                    path: Routes.SIGNUP_STEP_2,
                    options: {
                        id: university.id,
                    },
                });
            } else {
                await refetchSpaces();
                navigate({
                    path: Routes.HOME,
                    options: {
                        selected: university.slug,
                        type: SpaceType.UNIVERSITY,
                    },
                });
            }
            onClose();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <View
            position="absolute"
            zIndex={1}
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
        >
            <DiscoverEnterDialogAnimation trigger={!!university}>
                <View w="100%" alignItems="flex-end">
                    <TouchableOpacity
                        accessibilityRole="button"
                        accessibilityLabel="Close Dialog"
                        onPress={onClose}
                    >
                        <IconXMark />
                    </TouchableOpacity>
                </View>
                <Stack space="medium-large" align="center">
                    <Image
                        style={{width: 80, height: 80, borderRadius: 6}}
                        source={{uri: university.squareLogo ?? ""}}
                        width={80}
                        height={80}
                    />
                    <Heading level="3" size="xsmall">
                        {university.name}
                    </Heading>
                    <Text align="center" size="small" color="grey600">
                        {DiscoverEnterDialogStrings.Title}
                    </Text>
                    <Stack space="small-medium">
                        <LoadingButton block color="primary" onClick={handleLink} loading={loading}>
                            <Text color="white">{DiscoverEnterDialogStrings.ButtonText}</Text>
                        </LoadingButton>
                        {error ? (
                            <MutationErrorHandler
                                error={error}
                                message={DiscoverEnterDialogStrings.Error}
                            />
                        ) : null}
                        <Text align="center" size="xxsmall">
                            {DiscoverEnterDialogStrings.AgreeToTerms}{" "}
                            <Link
                                external
                                href={university.privacyPolicyUrl ?? ""}
                                style={{
                                    textDecorationLine: "underline",
                                }}
                                size="xsmall"
                            >
                                institution's T&Cs
                            </Link>
                        </Text>
                    </Stack>
                </Stack>
            </DiscoverEnterDialogAnimation>
        </View>
    );
};
