import React from "react";
import {ContextMenuReaction, ContextMenuReactions} from "@unibuddy/chat-ui";
import {Text} from "@unibuddy/patron";

import {ChatMessage} from "ubcommunity-shared/src/types";

import {ReactionsList, ReactionsMap} from "./useReaction";
import {useReactionUtils} from "./utils";

type Props = {
    userId: string;
    selectedMessage: ChatMessage;
    onReacted: (
        message: ChatMessage,
        reaction: typeof ReactionsList[0],
        action: "reacted" | "unreacted",
    ) => void;
};

const Reaction = ({
    reaction,
    selectedMessage,
    userId,
    onReacted,
}: {
    reaction: typeof ReactionsList[0];
} & Pick<Props, "selectedMessage" | "userId" | "onReacted">) => {
    const {getUserIdsForLikes, getUserIdsForReaction} = useReactionUtils();

    if (!selectedMessage) return null;

    // transform likes into emojis
    let userIdsForReaction = [];
    const isLike = reaction.label === ReactionsMap["Like"].label;
    if (isLike) {
        userIdsForReaction = getUserIdsForLikes(selectedMessage);
    } else {
        userIdsForReaction = getUserIdsForReaction(selectedMessage, reaction.reactionUnicode);
    }

    const hasReacted = userIdsForReaction.includes(userId);

    const handleClick = () => {
        if (!hasReacted) {
            onReacted(selectedMessage, reaction, "reacted");
        } else {
            onReacted(selectedMessage, reaction, "unreacted");
        }
    };

    return (
        <ContextMenuReaction
            onClick={handleClick}
            key={isLike ? ReactionsMap["Like"].reactionUnicode : reaction.reactionUnicode}
            label={isLike ? ReactionsMap["Like"].label : reaction.label}
            hasReacted={hasReacted}
        >
            <Text size="xlarge">{isLike ? ReactionsMap["Like"].emoji : reaction.emoji}</Text>
        </ContextMenuReaction>
    );
};

export const Reactions = ({userId, selectedMessage, onReacted}: Props) => {
    return (
        <ContextMenuReactions>
            {ReactionsList.map((reaction) => (
                <Reaction
                    key={reaction.reactionUnicode}
                    reaction={reaction}
                    selectedMessage={selectedMessage}
                    userId={userId}
                    onReacted={onReacted}
                />
            ))}
        </ContextMenuReactions>
    );
};
