import React from "react";

export const Squares = () => {
    return (
        <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M89.4199 30H30V89.42H89.4199V30Z" fill="#CFC8FF" />
            <path d="M30 0H0V30H30V0Z" fill="#6852FB" />
        </svg>
    );
};
