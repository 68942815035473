import {
    View,
    Divider,
    Heading,
    Stack,
    Form,
    TextInput,
    FormGroup,
    SubmitButton,
    Button,
} from "@unibuddy/patron";
import {IconXMark} from "@unibuddy/icons";
import {ActionSheetRef} from "react-native-actions-sheet";
import {Platform} from "react-native";
import {string} from "yup";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";

export enum RequestInstitutionStrings {
    MODAL_HEADING = "Request an institution",
    INPUT_LABEL = "Name of institution",
    INPUT_REQUIRED_ERROR = "Institution name is required",
    PRIMARY_CTA = "Send request",
}

export type FormValues = {institution: string};

export type RequestInstitutionProps = {
    searchText?: string;
    actionSheetRef?: React.RefObject<ActionSheetRef>;
    handleSubmit: (arg0: FormValues) => void;
    handleDismiss: () => void;
};

const modalHeight = 300;

const DeviceSpecificWrapper = ({children}: {children: React.ReactNode}) => {
    const {isDesktop} = useIsDesktop();

    return isDesktop ? (
        <View flex={1}>
            <Stack space="medium">{children}</Stack>
        </View>
    ) : (
        <View flexGrow={1} justifyContent="space-between">
            {children}
        </View>
    );
};

export function RequestInstitution({
    searchText = "",
    handleSubmit,
    handleDismiss,
}: RequestInstitutionProps) {
    const {bottom} = useSafeAreaInsets();
    const {isDesktop} = useIsDesktop();

    return (
        <View
            height={isDesktop ? "auto" : modalHeight}
            paddingTop="small"
            style={{paddingBottom: bottom}}
        >
            {Platform.OS === "web" ? (
                <View>
                    <Stack space="small">
                        <View paddingX="medium" paddingY="xsmall">
                            <Heading size="xsmall" level="3">
                                {RequestInstitutionStrings.MODAL_HEADING}
                            </Heading>
                        </View>
                        <Divider />
                    </Stack>
                </View>
            ) : (
                <Stack space="small">
                    <View
                        width="full"
                        flexDirection="row"
                        paddingX="medium"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Heading size="xsmall" level="3">
                            {RequestInstitutionStrings.MODAL_HEADING}
                        </Heading>
                        <Button iconOnly round clear onClick={handleDismiss}>
                            <IconXMark />
                        </Button>
                    </View>
                    <Divider />
                </Stack>
            )}

            <View flexGrow={1}>
                <View padding="medium" paddingBottom="large" flexGrow={1}>
                    <Form
                        onSubmit={handleSubmit}
                        style={{display: "flex", flexGrow: 1}}
                        initialValues={{institution: searchText}}
                        validationSchema={{
                            institution: string().required(
                                RequestInstitutionStrings.INPUT_REQUIRED_ERROR,
                            ),
                        }}
                    >
                        {({values}: {values: FormValues}) => (
                            <DeviceSpecificWrapper>
                                <FormGroup label={RequestInstitutionStrings.INPUT_LABEL}>
                                    <TextInput name="institution" required />
                                </FormGroup>
                                <SubmitButton color="primary" block disabled={!values.institution}>
                                    {RequestInstitutionStrings.PRIMARY_CTA}
                                </SubmitButton>
                            </DeviceSpecificWrapper>
                        )}
                    </Form>
                </View>
            </View>
        </View>
    );
}
