import React, {memo, PropsWithChildren} from "react";
import {Platform} from "react-native";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import {Heading, Stack, View, Box} from "@unibuddy/patron";

import {LoadingCover} from "ubcommunity-shared/src/General/LoadingCover/LoadingCover";
import {
    ChatConversation,
    CommunityProfileFragment,
    CountryFragment,
    UserField,
} from "ubcommunity-shared/src/types";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {truncateString} from "ubcommunity-shared/src/Utils";
import {PresenceMembers} from "ubcommunity-shared/src/General/ConversationPresenceProvider";

import {ChatMembersAvatars} from "../ChatMembersAvatars/ChatMembersAvatars";
import {MemberRoleCount} from "ubcommunity-shared/src/Community/MemberRoleCount/MemberRoleCount";

type ChatDrawerProps = PropsWithChildren<{
    conversation:
        | (Pick<
              ChatConversation,
              "communityChatMembers" | "communityChatTitle" | "totalMemberCount"
          > & {
              allMembers?: any;
              id: string;
              totalMemberCount: number;
          })
        | undefined;
    loading: boolean;
    drawerRef?: React.RefObject<DrawerLayout>;
}>;

type MembersListProps = Array<
    {__typename?: "UserField"} & Pick<
        UserField,
        "id" | "profilePhoto" | "firstName" | "lastName" | "accountRole"
    > &
        CountryFragment &
        CommunityProfileFragment
>;

export const ChatDrawer = memo(ChatDrawerImpl);

export const orderMembers = (members): UserField[] =>
    [...members].sort((a, b) =>
        a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0,
    );

export function sortMembersByOnline(members: MembersListProps, presenceMembers: PresenceMembers) {
    const onlineMembers = [];
    const offlineMembers = [];
    for (const member of members) {
        if (member.id in presenceMembers.members) {
            onlineMembers.push({...member, isOnline: true});
        } else {
            offlineMembers.push(member);
        }
    }

    const membersSortedByOnline = [...onlineMembers, ...offlineMembers];
    return membersSortedByOnline;
}

function ChatDrawerImpl({conversation, loading, children, drawerRef}: ChatDrawerProps) {
    const chatGroupName = conversation?.communityChatTitle;

    // Depending on whether the user have just arrived from inbox, or reloaded, or via link
    // we may or may not have chatGroupName as a prop because we are using location state to
    // store that information, and location state is only persisted for as long as browser
    // deems it necessary.
    // If we do have it we want to display something as soon as possible,
    // and the rest of the query information whenever it resolves. Otherwise, we want to
    // display a loading indicator.
    if (!conversation && loading) {
        return <LoadingCover loading backgroundColor="body" />;
    }

    // Finally, when skip is true data is undefined so we have to guard against that.
    if (!conversation) {
        return null;
    }

    const conversationTitle = conversation?.communityChatTitle ?? chatGroupName;
    const members = conversation?.communityChatMembers ?? [];
    return (
        <View
            {...Platform.select({
                web: {
                    height: "100%",
                },
                native: {
                    flex: 1,
                    contentContainerStyle: {flexGrow: 1},
                },
            })}
        >
            <View maxH="30%" paddingY="small" overflow="auto">
                {Platform.OS !== "web" ? (
                    <BackButton callback={() => drawerRef.current?.closeDrawer()} />
                ) : null}

                <Stack space="medium" align="center">
                    {conversation?.communityChatMembers?.length ? (
                        <ChatMembersAvatars members={members} />
                    ) : null}

                    <Box paddingX="small">
                        <Heading level="2" weight="400" size="xxsmall" align="center">
                            {truncateString(conversationTitle, 120)}
                        </Heading>
                    </Box>
                    {(conversation?.totalMemberCount ?? 0) >= 10 ? (
                        <MemberRoleCount membersCount={conversation?.totalMemberCount ?? 0} />
                    ) : null}
                </Stack>
            </View>

            <View flex="1">{children}</View>
        </View>
    );
}
