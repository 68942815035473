import React, {useMemo} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Box, Stack, View} from "@unibuddy/patron";

import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {AdminFeatureFlags, TrackEvents} from "ubcommunity-shared/src/constants";
import {UserSummaryActionButton} from "./UserSummaryActionButton";
import AddToGroupIcon from "ubcommunity-shared/src/Icons/AddToGroupIcon";
import BlockIcon from "ubcommunity-shared/src/Icons/BlockIcon";
import ReportIcon from "ubcommunity-shared/src/Icons/ReportIcon";
import {useCreateDirectConversation} from "ubcommunity-shared/src/Hooks/graphql/useCreateDirectConversation";

import {useIsUserBlock} from "../../useIsUserBlock";
import {MixpanelMetadataProps} from "./UserSummary";
import {AvailableViews} from "./Profile/StudentProfile";
import {useGetUserByUserIdQuery} from "ubcommunity-shared/src/types";
import {useConversation} from "ubcommunity-shared/src/Conversation/useConversation";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

type Props = {
    conversationId: string | null;
    senderId: string;
    setView: React.Dispatch<React.SetStateAction<AvailableViews>>;
    mixpanelMetadata?: MixpanelMetadataProps;
};

export enum UserSummaryActionsStrings {
    addToPrivateGroup = "Add to private group",
    blockUser = "Block user",
    unblockUser = "Unblock user",
    reportUser = "Report user",
}

/**
 * Component that controls all the actions a user can take when they open a profile
 * @param conversationId "id" of the conversations the profile if associated with if applicable
 * @param senderId "id" of the user associated with the profile opened
 * @param setView State setter to control the view that is displayed in the summary window. Applicable to AvailableViews
 * @param mixpanelMetadata
 */
export const UserSummaryActions = ({
    conversationId,
    senderId,
    setView,
    mixpanelMetadata = {},
}: Props) => {
    const {trackEvent} = useAnalytics();
    const {user} = useAuth();
    const {handleBlock, handleReport} = useOpenUserSummary({});
    const isPrivateGroupsEnabled = useIsFeatureAdminEnabled(
        AdminFeatureFlags.PRIVATE_GROUPS_SETTINGS,
    );
    const [isBlocked] = useIsUserBlock({senderId, conversationId: conversationId ?? undefined});
    const {loading: dmLoading} = useCreateDirectConversation({senderId});
    const {data} = useGetUserByUserIdQuery({
        variables: {
            id: senderId,
        },
    });
    const {conversationType} = useConversation();

    const commonProps = useMemo(
        () => ({
            conversationId: conversationId,
            selectedUserId: senderId,
            accountType: data?.getUser?.accountRole,
            groupType: conversationType,
            ...mixpanelMetadata,
        }),
        [conversationId, senderId, data?.getUser?.accountRole, conversationType, mixpanelMetadata],
    );

    const handleBlockUser = async () => {
        if (isBlocked) trackEvent(TrackEvents.USER_PROFILE_ACTION_BLOCK, commonProps);
        else trackEvent(TrackEvents.USER_PROFILE_ACTION_UNBLOCK, commonProps);
        handleBlock(senderId);
    };

    const handleReportUser = async () => {
        trackEvent(TrackEvents.USER_PROFILE_ACTION_REPORT, commonProps);
        handleReport(senderId);
    };

    const handleAddToPrivateGroup = () => {
        trackEvent(TrackEvents.USER_PROFILE_ACTION_PRIVATE_GROUP, commonProps);
        setView(AvailableViews.PRIVATE_GROUPS_VIEW);
    };

    return (
        <View marginY="small">
            <Box
                borderBottomWidth={1}
                borderColor="groupBgColor"
                marginX="medium"
                marginY="small"
            />

            <Stack space="small">
                {isPrivateGroupsEnabled && !isBlocked ? (
                    <UserSummaryActionButton
                        title={UserSummaryActionsStrings.addToPrivateGroup}
                        onClick={handleAddToPrivateGroup}
                        Icon={AddToGroupIcon}
                        disabled={dmLoading}
                    />
                ) : null}

                {/* we check for conversationId to ensure that the user opened from a chat as the mutation requires an id for the same */}
                {conversationId && user?.accountRole !== "university" ? (
                    <UserSummaryActionButton
                        title={
                            isBlocked
                                ? UserSummaryActionsStrings.unblockUser
                                : UserSummaryActionsStrings.blockUser
                        }
                        onClick={handleBlockUser}
                        Icon={BlockIcon}
                        disabled={dmLoading}
                    />
                ) : null}

                <UserSummaryActionButton
                    title={UserSummaryActionsStrings.reportUser}
                    onClick={handleReportUser}
                    Icon={ReportIcon}
                    disabled={dmLoading}
                />
            </Stack>
        </View>
    );
};
