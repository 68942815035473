import React from "react";
import {Svg, Rect, Path} from "react-native-svg";

const CheckboxSvg = ({size, color, filled}: {size?: number; color?: string; filled?: boolean}) => {
    return (
        <Svg
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {!filled ? (
                <Rect x="4" y="4" width="16" height="16" rx="1" stroke={color} stroke-width="2" />
            ) : (
                <>
                    <Path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 0H24V24H0V0Z"
                        fill="transparent"
                        fill-opacity="0.01"
                    />
                    <Path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                        fill={color}
                    />
                </>
            )}
        </Svg>
    );
};

export default CheckboxSvg;
