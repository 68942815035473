import React, {FC} from "react";
import {ScrollView} from "react-native";
import {
    Stack,
    Button,
    Text,
    Form,
    Box,
    Columns,
    Column,
    View,
    usePatronTheme,
    Heading,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {useSetBlockUserV2Mutation, useBlockedUsersListQuery} from "ubcommunity-shared/src/types";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton";
import InfoIcon from "ubcommunity-shared/src/Icons/InfoIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";
import {useIsUserBlock} from "../../useIsUserBlock";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";

interface Props {
    senderId: string;
    conversationId?: string;
    onDismiss: () => void;
    onSuccess: () => void;
}

export const SetBlockUserForm: FC<Props> = ({
    senderId,
    onDismiss,
    onSuccess,
    conversationId,
    ...props
}) => {
    const theme = usePatronTheme();
    const {trackEvent} = useAnalytics();
    const {updateQuery} = useBlockedUsersListQuery({
        fetchPolicy: "cache-only",
    });

    const [isBlocked] = useIsUserBlock({senderId, conversationId});
    const setBlock = !isBlocked;

    const [block, {error}] = useSetBlockUserV2Mutation({
        update() {
            updateQuery((cacheData) => {
                if (!cacheData.blockedUsersByMe) return cacheData;

                let updatedBlockedUsers;
                if (setBlock) {
                    updatedBlockedUsers = [...cacheData.blockedUsersByMe, senderId];
                } else {
                    updatedBlockedUsers = cacheData.blockedUsersByMe.filter(
                        (userId) => userId !== senderId,
                    );
                }
                return {
                    ...cacheData,
                    blockedUsersByMe: updatedBlockedUsers,
                };
            });
        },
    });

    const handleSubmit = async (_, {setSubmitting}) => {
        try {
            await block({
                variables: {
                    userId: senderId,
                    setBlocked: setBlock,
                },
            });
            trackEvent(setBlock ? TrackEvents.USER_BLOCK : TrackEvents.USER_UNBLOCK, {
                senderId: senderId,
            });
            onSuccess();
        } catch (error) {
            console.log(error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit} initialValues={{}} {...props}>
            <Box paddingBottom="large">
                <Heading level="1" size="xsmall">
                    {setBlock
                        ? `Are you sure you want to block this user?`
                        : `Are you sure you want to unblock this user?`}
                </Heading>
            </Box>
            <ScrollView>
                <Stack space="large">
                    <Text>
                        {setBlock
                            ? `We're sorry that you've had a bad experience and want to block someone.`
                            : `Once you unblock this user you will be able to send and receive messages from them.`}
                    </Text>

                    {setBlock ? (
                        <View
                            width="100%"
                            bgColor="#ECE9FF"
                            padding="medium"
                            borderRadius="xxsmall"
                        >
                            <Columns space="small">
                                <Column width="content">
                                    <InfoIcon
                                        color={theme?.colors.primaryColor}
                                        height={20}
                                        width={20}
                                    />
                                </Column>
                                <Column>
                                    <Text color="primaryColor">
                                        Once you block this user, they will not be able to send you
                                        any more direct messages. You can unblock them at any time
                                    </Text>
                                </Column>
                            </Columns>
                        </View>
                    ) : null}
                </Stack>
            </ScrollView>
            <Box paddingTop="large">
                <Stack space="medium">
                    {error ? (
                        <MutationErrorHandler
                            error={error}
                            meta={{
                                component: "SetBlockUserForm",
                                mutation: "useSetBlockUserV2Mutation",
                            }}
                        />
                    ) : null}
                    {setBlock ? (
                        <SubmitButton color="danger" block>
                            Block User
                        </SubmitButton>
                    ) : (
                        <SubmitButton color="primary" block>
                            Unblock User
                        </SubmitButton>
                    )}
                    <Button color="light" block onClick={onDismiss}>
                        <Text style={{...boldFontStyles}} color="black">
                            Cancel
                        </Text>
                    </Button>
                </Stack>
            </Box>
        </Form>
    );
};
