import React from "react";

const TrashIcon = (props) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4.26611 4.20031H13.6808"
                stroke="currentColor"
                strokeWidth="1.10915"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M7.18475 3.81821C7.19821 3.35277 7.39257 2.9109 7.72655 2.58644C8.06053 2.26198 8.50783 2.08048 8.97346 2.08048C9.4391 2.08048 9.8864 2.26198 10.2204 2.58644C10.5544 2.9109 10.7487 3.35277 10.7622 3.81821"
                stroke="currentColor"
                strokeWidth="1.10915"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M13.6845 6.46091L12.678 14.0644C12.516 14.6387 12.1592 15.1388 11.6688 15.4788C11.1785 15.8188 10.5851 15.9776 9.99043 15.9279H8.00131C7.40694 15.9778 6.81374 15.8192 6.32349 15.4795C5.83325 15.1397 5.47652 14.6399 5.31451 14.0659L4.26611 6.46091"
                stroke="currentColor"
                strokeWidth="1.10915"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TrashIcon;
