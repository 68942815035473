import * as React from "react";
import Svg, {Path, G, Defs, ClipPath, Rect} from "react-native-svg";

export default function PlusIcon(props) {
    const {color = "currentColor", ...rest} = props;

    return (
        <Svg
            width={props.size || 32}
            height={props.size || 32}
            viewBox="0 0 32 32"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <G clipPath="url(#clip0_52_29593)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 5C16.5523 5 17 5.44772 17 6V15H26C26.5523 15 27 15.4477 27 16C27 16.5523 26.5523 17 26 17H17V26C17 26.5523 16.5523 27 16 27C15.4477 27 15 26.5523 15 26V17H6C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15H15V6C15 5.44772 15.4477 5 16 5Z"
                    fill={color}
                />
            </G>
            <Defs>
                <ClipPath id="clip0_52_29593">
                    <Rect width="32" height="32" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
