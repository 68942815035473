import {useEffect} from "react";
import {SpaceType, useSpaces} from "./SpacesProvider";

export const useIdentifyUser = (adaptor: any) => {
    const {spaces} = useSpaces();

    const numberOfInvites = spaces.filter((space) => space.type === SpaceType.INVITE).length;
    const numberOfCommunities = spaces.filter((space) => space.type === SpaceType.COMMUNITY).length;
    const numberOfLinks = spaces.filter((space) => space.type === SpaceType.UNIVERSITY).length;

    useEffect(() => {
        if (!adaptor.addUserAttributes) {
            return;
        }
        adaptor.addUserAttributes({
            numberOfInvites,
            numberOfCommunities,
            numberOfLinks,
        });
    }, [numberOfInvites, numberOfCommunities, numberOfLinks, adaptor]);
};
