import {useCallback} from "react";
import {NetworkStatus} from "@apollo/client";
import {useBuddiesQuery} from "ubcommunity-shared/src/types";
import {
    SpaceType,
    useSpaces,
} from "ubcommunity-shared/src/StudentApp/SpacesProvider/SpacesProvider";

const STAFF_AMBO_LIST_LIMIT = 15;

export const useStaffMentorList = () => {
    const {selectedSpace, loading: spaceLoading} = useSpaces();
    const {data, networkStatus, loading, error, refetch, fetchMore} = useBuddiesQuery({
        variables: {
            universitySlug: selectedSpace?.slug,
            role: "staff",
            limit: STAFF_AMBO_LIST_LIMIT,
            offset: 0,
        },
        notifyOnNetworkStatusChange: true,
        skip: !selectedSpace || selectedSpace.type !== SpaceType.UNIVERSITY || spaceLoading,
    });

    const isLoading = loading && networkStatus !== NetworkStatus.fetchMore;
    const isFetchingNext = networkStatus === NetworkStatus.fetchMore;

    const fetchNext = useCallback(() => {
        fetchMore({
            variables: {
                offset: data?.buddies?.users?.length ?? 0,
            },
        });
    }, [fetchMore, data?.buddies?.users?.length]);

    return {
        loading: isLoading,
        fetchNextLoading: isFetchingNext,
        error,
        data: data?.buddies?.users ?? [],
        refetch,
        fetchNext,
        total: data?.buddies?.count,
    };
};
