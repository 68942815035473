import React, {useCallback, useEffect, useRef, useState} from "react";
import {useFormikContext} from "formik";
import {Button, Column, Columns, usePatronTheme} from "@unibuddy/patron";
import {Interest} from "ubcommunity-shared/src/types";
import EditIcon from "ubcommunity-shared/src/Icons/EditIcon";
import {ProfileFormValues} from "../AccountInformation";
import {InterestsFormGroup} from "ubcommunity-shared/src/Explore/AddInterestsCTA/InterestsFormGroup";

type Props = {
    allInterests: Interest[];
};

export const EditInterests = ({allInterests}: Props) => {
    const {values, submitCount, isSubmitting} = useFormikContext<ProfileFormValues>();
    const submitCountRef = useRef(submitCount);
    const [isEditing, setIsEditing] = useState(false);
    const theme = usePatronTheme();

    useEffect(() => {
        if (submitCount > 0) {
            submitCountRef.current = submitCount;
        }
        if (submitCountRef.current > submitCount && !isSubmitting) {
            setIsEditing(false);
            submitCountRef.current = 0;
        }
        if (values.interests?.length === 0) {
            setIsEditing(true);
        }
    }, [submitCount, values.interests, isSubmitting]);

    const filteredValues = useCallback(() => {
        return allInterests.filter((interest) => values.interests?.includes(interest.id));
    }, [allInterests, values.interests]);

    return (
        <Columns>
            <Column>
                <InterestsFormGroup
                    label="My interests"
                    allInterests={isEditing ? allInterests : filteredValues()}
                    isEditing={isEditing}
                />
            </Column>
            <Column width="content">
                {!isEditing ? (
                    <Button
                        type="button"
                        onClick={() => setIsEditing(!isEditing)}
                        size="sm"
                        iconOnly
                        clear
                        round
                        aria-label="Change your interests"
                    >
                        <EditIcon color={theme.TextBlock.color} />
                    </Button>
                ) : null}
            </Column>
        </Columns>
    );
};
