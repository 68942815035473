import React from "react";
import type {FormikHelpers} from "formik";
import {string} from "yup";
import styled from "styled-components";

import {
    TextInput,
    TextArea,
    FormGroup,
    SubmitButton,
    Stack,
    Form,
    View,
    Text,
    usePatronTheme,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useRequestPublicGroupMutation} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import ThumbsUp from "ubcommunity-shared/src/Community/Images/ThumbsUp";
import {useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";

const StyledTextArea = styled(TextArea)`
    resize: none;
    min-height: 100px;
    font-family: ${({theme}) => theme?.TextBlock?.fontFamily};
`;

interface RequestPublicGroupFormValues {
    groupName: string;
    description: string;
}

type RequestPublicGroupProps = {
    handleSuccess: () => void;
};

export const RequestPublicGroup = ({handleSuccess}: RequestPublicGroupProps) => {
    const {authState} = useAuth();
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();
    const {openModal} = useModal();
    const {selectedCommunity} = useCommunity();
    const [requestPublicGroup, {error}] = useRequestPublicGroupMutation();
    const theme = usePatronTheme();

    const handleSubmit = async (
        {groupName, description}: {groupName: string; description: string},
        formActions: FormikHelpers<RequestPublicGroupFormValues>,
    ) => {
        const trimmedDesc = description.trim();
        try {
            await requestPublicGroup({
                variables: {
                    requestPublicGroupInput: {
                        communityId: selectedCommunity.id,
                        title: groupName,
                        description: trimmedDesc,
                    },
                },
            });

            trackEvent(TrackEvents.PUBLIC_GROUP_REQUESTED, {
                accountRole: authState.me.anyUser.accountRole,
            });

            handleSuccess();

            openModal({
                component: (
                    <View padding="xlarge" alignItems="center">
                        <View paddingBottom="large">
                            <ThumbsUp />
                        </View>

                        <Text size="xlarge" align="center" style={{...boldFontStyles}}>
                            Thanks! Check back soon to see if your group has been created
                        </Text>
                    </View>
                ),
            });
        } catch (error) {
            formActions.setFieldError("serverError", error.message);
            reportError(error);
        } finally {
            formActions.setSubmitting(false);
        }
    };

    return (
        <Stack space="xlarge">
            <Form
                name="createPrivateGroup"
                onSubmit={handleSubmit}
                validationSchema={{
                    groupName: string().required("Group name is a required field"),
                    description: string().required(),
                }}
                initialValues={{
                    groupName: "",
                    description: "",
                }}
            >
                <Stack space="medium">
                    <FormGroup label="What is the name of your group?">
                        <TextInput
                            required
                            name="groupName"
                            placeholder="E.g. English Literature Students"
                            placeholderTextColor={theme?.colors?.placeholderColor}
                        />
                    </FormGroup>
                    <FormGroup label="What is your group about?">
                        <StyledTextArea
                            required
                            rows={5}
                            name="description"
                            placeholder="E.g. A group for everyone studying English Literature to get to know each other and chat before we get to University!"
                            placeholderTextColor={theme?.colors?.placeholderColor}
                        />
                    </FormGroup>

                    <Text size="small" color="altTextColor" align="center">
                        Ask your university admin to create this group
                    </Text>
                    {error ? (
                        <MutationErrorHandler
                            error={error}
                            message={"Something went wrong, please try again later!"}
                            meta={{
                                component: "RequestPublicGroup",
                                mutation: "useRequestPublicGroupMutation",
                            }}
                        />
                    ) : null}

                    <SubmitButton size="sm" color="primary" block>
                        <Text size="medium" color="white" style={{...boldFontStyles}}>
                            Request group
                        </Text>
                    </SubmitButton>
                </Stack>
            </Form>
        </Stack>
    );
};
