import {useCallback, useMemo, useEffect} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {
    useSocketListener,
    SocketChannel,
    SocketEvent,
} from "ubcommunity-shared/src/Sockets/SocketProvider";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useRole} from "../Auth/hooks/useHasRole";
import {
    useCommunityPinnedMessagesQuery,
    useCommunityPinMessageMutation,
    useCommunityUnpinMessageMutation,
    ChatMessage,
    UserField,
} from "../types";
import {formatChatMessage} from "./useChat";

const conversationChannel = new SocketChannel("conversation", {private: true});
export const conversationPinMessage = new SocketEvent("pin-message", conversationChannel);
export const conversationUnpinMessage = new SocketEvent("unpin-message", conversationChannel);

type UsePin = {
    onPin: (message: ChatMessage) => Promise<void>;
    loading: boolean;
    pinnedMessageIds: string[];
    pinnedMessages?: Array<
        {__typename?: "ChatMessage"} & Pick<ChatMessage, "id" | "text" | "created"> & {
                sender: {__typename?: "UserField"} & Pick<
                    UserField,
                    "id" | "firstName" | "accountRole" | "profilePhoto"
                >;
            }
    >;
};

export function usePin(conversationId: string): UsePin {
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();
    const [pinMessage] = useCommunityPinMessageMutation();
    const [unpinMessage] = useCommunityUnpinMessageMutation();
    const accountRole = useRole();

    const {data, loading, error, updateQuery} = useCommunityPinnedMessagesQuery({
        variables: {
            conversationId,
        },
    });

    const conversationTitle = data?.getChatConversation?.communityChatTitle;

    useEffect(() => {
        if (error) reportError(error);
    }, [reportError, error]);

    const pinnedMessageIds = useMemo(
        () => data?.getChatConversation?.pinnedMessages.map((message) => message.id),
        [data?.getChatConversation?.pinnedMessages],
    );

    const addPinnedMessageToCache = useCallback(
        ({message}: {message: ChatMessage}) => {
            updateQuery((cacheData) => {
                if (!cacheData) return;

                if (
                    cacheData.getChatConversation.pinnedMessages.find(({id}) => id === message?.id)
                ) {
                    return;
                }

                const formattedMessage = formatChatMessage(message);

                return {
                    getChatConversation: {
                        ...cacheData.getChatConversation,
                        pinnedMessages: [
                            ...cacheData.getChatConversation.pinnedMessages,
                            formattedMessage,
                        ],
                    },
                };
            });
        },
        [updateQuery],
    );

    useSocketListener(conversationPinMessage, conversationId, addPinnedMessageToCache);

    const removePinnedMessageFromCache = useCallback(
        ({id: messageId}: {id: string}) => {
            updateQuery((cacheData) => {
                if (!cacheData) return;

                return {
                    getChatConversation: {
                        ...cacheData.getChatConversation,
                        pinnedMessages: cacheData.getChatConversation.pinnedMessages.filter(
                            (message) => message.id !== messageId,
                        ),
                        __typename: "ChatConversation",
                    },
                };
            });
        },
        [updateQuery],
    );

    useSocketListener(conversationUnpinMessage, conversationId, removePinnedMessageFromCache);

    const trackPinMessage = useCallback(
        async (isPinning: boolean) => {
            trackEvent(isPinning ? "Pin Message" : "Un-pin message", {
                accountRole,
                conversationTitle,
                conversationId,
            });
        },
        [conversationTitle, conversationId, accountRole, trackEvent],
    );

    const onPin = useCallback(
        async (message: ChatMessage) => {
            try {
                const isPinning = !pinnedMessageIds?.includes(message?.id);

                const variables = {conversationId, messageId: message?.id};

                if (isPinning) {
                    addPinnedMessageToCache({message});
                    await pinMessage({variables: {pinMessageDTO: variables}});
                } else {
                    removePinnedMessageFromCache({id: message?.id});
                    await unpinMessage({variables: {unpinMessageDTO: variables}});
                }

                trackPinMessage(isPinning);
            } catch (sendError) {
                console.error(sendError);
                reportError(sendError);
            }
        },
        [
            pinnedMessageIds,
            conversationId,
            pinMessage,
            addPinnedMessageToCache,
            unpinMessage,
            removePinnedMessageFromCache,
            reportError,
            trackPinMessage,
        ],
    );

    return useMemo(
        () => ({
            onPin,
            pinnedMessageIds: pinnedMessageIds || [],
            pinnedMessages: data?.getChatConversation?.pinnedMessages,
            loading,
        }),
        [onPin, pinnedMessageIds, data?.getChatConversation?.pinnedMessages, loading],
    );
}
