export enum FeatureFlags {
    ADMIN_INBOX_EXPERIENCE = "student-app-admin-experience-inbox",
    APP_STABLE_VERSION = "community-stable-version",
    CONTENT = "community-content",
    DIRECT_MESSAGING_FEATURE_FLAG = "community-direct-messaging",
    DISCOVER = "student-app-discover",
    DEMO_FAKE_GROUP_VALUES = "community-demo-fake-group-values",
    HIDE_LAST_NAME = "community-hide-lastnames",
    FEEDBACK_DIALOG_FEATURE_FLAG = "community-feedback-dialog",
    IMAGES_IN_CHAT = "community-images-in-chat",
    INBOX_V2 = "community-inbox-v2",
    MEMBERS_LIST_BUTTON_FEATURE_FLAG = "community-members-list-button",
    MATCHES_FLAG = "community-matches-toggle",
    PEOPLE_AOS_FILTER = "community-people-aos-filter",
    PEOPLE_INTERESTS_FILTER = "community-people-interests-filter",
    PEOPLE_HOUSING_FILTER = "community-people-housing-filter",
    SSO_LOGIN_FEATURE_FLAG = "ub-community-sso",
    SSO_FEATURE_FLAG = "ub-community-social-sign-on",
    SSO_FEATURE_FLAG_NATIVE = "ub-community-social-sign-on-native",
    STUDENT_CONFIDENCE_CTA_RECOMMENDATION = "community-student-confidence-cta",
    STUDENT_HAS_ENROLLED_CTA = "community-student-has-enrolled-cta",
    STUDENTS_CAN_UPDATE_INTERESTS = "community-update-interests",
    STUDENTS_CAN_UPDATE_NAME = "community-update-name",
    TODO_LIST = "community-todo-list",
    TODO_LIST_TAB = "community-todo-list-tab",
    PEOPLE_TAB = "community-people-tab",
    FEEDBACK_URL = "community-feedback-url",
    SHOW_DEBUG = "community-show-debug",
    // ref https://www.figma.com/board/svRmlWoztNDaIqsTNeNYpD/Student-App-Feature-Flag-Logic
    STUDENT_APP_COMMUNITY_USER_EXPERIENCE = "student-app-community-user-experience",
    STUDENT_APP_KTU_ORGANIC = "student-app-ktu-organic",
    STUDENT_APP_PROSPECT_EXPERIENCE = "student-app-experience",
    STUDENT_APP_PROFILE_PHOTO_IN_TAB = "student-app-profile-photo-in-tab",
    STUDENT_APP_CHAT_TO_STAFF = "student-app-chat-to-staff",
    STUDENT_APP_DISCOVER_UNIVERSITY_KILL_SWITCH = "student-app-discover-university-kill-switch",
    STUDENT_APP_ENHANCED_LEADS_REDIRECT = "student-app-enhanced-leads-redirect",
    //
    DID_COMMUNITY_HELPED_QUESTION = "ub-community-enable-did-community-helped-enrollment-Q-V2",
}

// Maintain backward compatibility with existing exports
// DO NOT add new values here
export const APP_STABLE_VERSION = FeatureFlags.APP_STABLE_VERSION;
export const CONTENT = FeatureFlags.CONTENT;
export const DIRECT_MESSAGING_FEATURE_FLAG = FeatureFlags.DIRECT_MESSAGING_FEATURE_FLAG;
export const DEMO_FAKE_GROUP_VALUES = FeatureFlags.DEMO_FAKE_GROUP_VALUES;
export const HIDE_LAST_NAME = FeatureFlags.HIDE_LAST_NAME;
export const FEEDBACK_DIALOG_FEATURE_FLAG = FeatureFlags.FEEDBACK_DIALOG_FEATURE_FLAG;
export const IMAGES_IN_CHAT = FeatureFlags.IMAGES_IN_CHAT;
export const INBOX_V2 = FeatureFlags.INBOX_V2;
export const MEMBERS_LIST_BUTTON_FEATURE_FLAG = FeatureFlags.MEMBERS_LIST_BUTTON_FEATURE_FLAG;
export const MATCHES_FLAG = FeatureFlags.MATCHES_FLAG;
export const PEOPLE_AOS_FILTER = FeatureFlags.PEOPLE_AOS_FILTER;
export const PEOPLE_INTERESTS_FILTER = FeatureFlags.PEOPLE_INTERESTS_FILTER;
export const PEOPLE_HOUSING_FILTER = FeatureFlags.PEOPLE_HOUSING_FILTER;
export const SSO_LOGIN_FEATURE_FLAG = FeatureFlags.SSO_LOGIN_FEATURE_FLAG;
export const SSO_FEATURE_FLAG = FeatureFlags.SSO_FEATURE_FLAG;
export const SSO_FEATURE_FLAG_NATIVE = FeatureFlags.SSO_FEATURE_FLAG_NATIVE;
export const STUDENT_CONFIDENCE_CTA_RECOMMENDATION =
    FeatureFlags.STUDENT_CONFIDENCE_CTA_RECOMMENDATION;
export const STUDENT_HAS_ENROLLED_CTA = FeatureFlags.STUDENT_HAS_ENROLLED_CTA;
export const STUDENTS_CAN_UPDATE_INTERESTS = FeatureFlags.STUDENTS_CAN_UPDATE_INTERESTS;
export const STUDENTS_CAN_UPDATE_NAME = FeatureFlags.STUDENTS_CAN_UPDATE_NAME;
export const TODO_LIST = FeatureFlags.TODO_LIST;
export const TODO_LIST_TAB = FeatureFlags.TODO_LIST_TAB;
export const PEOPLE_TAB = FeatureFlags.PEOPLE_TAB;
export const FEEDBACK_URL = FeatureFlags.FEEDBACK_URL;
export const SHOW_DEBUG = FeatureFlags.SHOW_DEBUG;
export const STUDENT_APP_PROSPECT_EXPERIENCE = FeatureFlags.STUDENT_APP_PROSPECT_EXPERIENCE;
export const DID_COMMUNITY_HELPED_QUESTION = FeatureFlags.DID_COMMUNITY_HELPED_QUESTION;
export const STUDENT_APP_PROFILE_PHOTO_IN_TAB = FeatureFlags.STUDENT_APP_PROFILE_PHOTO_IN_TAB;

// Export POPUP_MANAGER as is, since it doesn't fit in the enum structure
export const POPUP_MANAGER = {
    COMMUNITY_POPUP: "community-popup",
    USER_FEEDBACK: "user-feedback",
    PROFILE_PICTURE: "profile-pictures",
    STUDENT_ENROLLMENT_STAGE: "student-enrollment-stage",
    PROMOTE_ENHANCED_PROFILES: "promote-enhanced-profiles",
    RECRUIT_STUDENTS: "user-research-cta",
    FEEL_PREPARED_QUESTIONS: "feel-prepared-questions",
};
