import React from "react";
import {View} from "@unibuddy/patron";

/**
 * We need the web version of this component in a separate file because the mobile version is using reanimated View.
 */
export const ReplyQuoteContainer = ({children}) => {
    return (
        <View
            padding="small"
            paddingBottom="none"
            position="relative"
            data-testid="reply"
            backgroundColor="navbarColor"
        >
            {children}
        </View>
    );
};
