import React, {memo} from "react";
import {useHistory, useParams} from "react-router";
import {Text, Stack} from "@unibuddy/patron";
import {IconInformationCircle, IconUbPin, IconUser} from "@unibuddy/icons";
import {useAnalytics} from "@unibuddy/tracking";

import {ChatDrawer} from "ubcommunity-shared/src/Chat/ChatDrawer/ChatDrawer";
import {MembersList} from "ubcommunity-shared/src/Chat/ChatDrawer/MembersList";
import {ChatPageAbout} from "ubcommunity-shared/src/Chat/ChatDrawer/ChatPageAbout";
import {PinnedMessages} from "ubcommunity-shared/src/Chat/ChatDrawer/PinnedMessages";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";

import {Tabs, TabNav, TabLink, TabContent} from "../../Layout/Tabs/Tabs";
import {GetCommunityConversationQuery} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {LoadingCover} from "ubcommunity-shared/src/General/LoadingCover/LoadingCover";

type TabItemProps = {
    title: string;
    index: number;
};

enum TABS_NAMES {
    ABOUT = "About",
    MEMBERS = "Members",
    PINNED = "Pinned",
}

const icon = {
    [TABS_NAMES.ABOUT]: <IconInformationCircle height={20} width={20} />,
    [TABS_NAMES.MEMBERS]: <IconUser height={20} width={20} />,
    [TABS_NAMES.PINNED]: <IconUbPin transform="rotate(270)" height={20} width={20} />,
};

const TabItem = ({title, index}: TabItemProps) => (
    <TabLink index={index}>
        <Stack align="center" space="xsmall">
            {icon[title]}
            <Text color="inherit" size="xsmall" nowrap>
                {title}
            </Text>
        </Stack>
    </TabLink>
);

type tabsContentType = {
    title: string;
    index: number;
};

const tabsContent = (showPinnedMsg: boolean): tabsContentType[] => {
    const tabsToRender = [
        {
            title: TABS_NAMES.ABOUT,
            index: 0,
        },
        {
            title: TABS_NAMES.MEMBERS,
            index: 1,
        },
    ];

    if (showPinnedMsg) {
        tabsToRender.push({
            title: TABS_NAMES.PINNED,
            index: 2,
        });
    }
    return tabsToRender;
};

const ChatSideDrawerImpl = ({
    conversation,
    loading,
    onShowUserSummary,
}: {
    conversation: GetCommunityConversationQuery["getChatConversation"] | undefined;
    loading: boolean;
    onShowUserSummary: (userId: string, mixpanelMetadata: MixpanelMetadataProps) => void;
}) => {
    const history = useHistory();
    const {universitySlug} = useParams<{universitySlug?: string}>();

    const {trackEvent} = useAnalytics();
    const handleTracking = React.useCallback(
        (index) => {
            const selectedEvent = {
                0: TrackEvents.ABOUT_TAB_SELECTED,
                1: TrackEvents.MEMBERS_TAB_SELECTED,
                2: TrackEvents.PINNED_MSG_TAB_SELECTED,
            }[index];
            trackEvent(selectedEvent);
        },
        [trackEvent],
    );

    const showPinnedMsg = !(conversation?.isPrivate || conversation?.isDirect);
    const tabsToRender = tabsContent(showPinnedMsg);

    if (loading) {
        return <LoadingCover loading backgroundColor="body" />;
    }

    return (
        <ChatDrawer conversation={conversation} loading={loading}>
            <Tabs
                tabs={
                    <TabNav>
                        {tabsToRender.map((item) => (
                            <TabItem key={item.index} title={item.title} index={item.index} />
                        ))}
                    </TabNav>
                }
                numberOfTabs={tabsToRender.length}
                defaultTabIndex={1}
                callback={handleTracking}
            >
                <TabContent>
                    <ChatPageAbout
                        conversation={conversation}
                        onLeaveGroupSuccess={() => history.replace(`/u/${universitySlug}/chat`)}
                    />
                </TabContent>
                <TabContent>
                    <MembersList
                        conversation={conversation}
                        onShowUserSummary={onShowUserSummary}
                    />
                </TabContent>
                {showPinnedMsg ? (
                    <TabContent>
                        <PinnedMessages conversationId={conversation?.id} />
                    </TabContent>
                ) : null}
            </Tabs>
        </ChatDrawer>
    );
};

export const ChatSideDrawer = memo(ChatSideDrawerImpl);
