import {PropsWithChildren, useEffect} from "react";
import {ScrollView} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {Text, usePatronTheme, View} from "@unibuddy/patron";
import {SpaceType, useSpaces} from "../StudentApp/SpacesProvider";
import {Invite} from "../Community/Invite/Invite";
import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";
import {TrackEvents} from "../constants";
import {Ambassadors} from "../StudentApp/Ambos/Ambassadors";
import {HomeEmptyState} from "./HomeEmptyState";
import {useNavigate} from "../Hooks/useNavigate";

export const Home = ({children}: PropsWithChildren) => {
    const {selectedSpace, spaces, setSelectedSpace} = useSpaces();
    const {selectedCommunity} = useCommunity();
    const {trackEvent} = useAnalytics();
    const theme = usePatronTheme();

    const {parse} = useNavigate();

    useEffect(() => {
        trackEvent(TrackEvents.HOME_SCREEN_VIEWED, {
            spaceId: selectedSpace?.id,
            spaceType: selectedSpace?.type,
            spaceName: selectedSpace?.name,
            spaceTitle: selectedSpace?.title,
            spaceSlug: selectedSpace?.slug,
        });
    }, [trackEvent, selectedSpace]);

    useEffect(() => {
        const {selected, type} = parse();
        if (!selected || !type) return;
        const spaceToSelect = spaces?.find(
            (space) => space.slug === selected && space.type === type.toUpperCase(),
        );
        if (spaceToSelect) {
            setSelectedSpace(spaceToSelect);
        }
    }, [parse, spaces, setSelectedSpace]);

    if (selectedSpace?.type === SpaceType.UNIVERSITY) {
        return <Ambassadors />;
    }

    if (selectedSpace?.type === SpaceType.INVITE) {
        return (
            <View flex="1" alignItems="center">
                <ScrollView
                    contentContainerStyle={{
                        paddingTop: parseInt(theme.space.small),
                        paddingBottom: parseInt(theme.space.small),
                    }}
                >
                    <Invite />
                </ScrollView>
            </View>
        );
    }

    if (selectedSpace?.type === SpaceType.COMMUNITY && selectedCommunity) {
        return children;
    }

    if (spaces?.length === 0) {
        return (
            <View flex="1" justifyContent="center">
                <HomeEmptyState />
            </View>
        );
    }

    return (
        <View padding="medium">
            <Text align="center">Something went wrong</Text>
        </View>
    );
};
