import * as React from "react";

const RotateIcon = (props) => {
    const {color = "currentColor", size = 24, ...rest} = props;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M12.3897 20.4132C11.4286 20.4386 10.4721 20.2711 9.57668 19.9208C8.68129 19.5705 7.86513 19.0444 7.17635 18.3736C6.48758 17.7027 5.94015 16.9007 5.56636 16.0148C5.19258 15.129 5 14.1772 5 13.2157C5 12.2543 5.19258 11.3025 5.56636 10.4167C5.94015 9.53081 6.48758 8.72879 7.17635 8.05793C7.86513 7.38708 8.68129 6.86097 9.57668 6.51066C10.4721 6.16034 11.4286 5.99291 12.3897 6.01824L15.8697 5.99024H15.8757C16.0746 5.98945 16.2657 6.0677 16.4069 6.20779C16.5481 6.34788 16.6279 6.53833 16.6287 6.73724C16.6295 6.93616 16.5513 7.12724 16.4112 7.26845C16.2711 7.40967 16.0806 7.48945 15.8817 7.49024L12.3947 7.51824C11.267 7.51666 10.1642 7.84961 9.22579 8.47498C8.28738 9.10034 7.55555 9.99 7.12291 11.0314C6.69026 12.0728 6.57624 13.2191 6.79527 14.3253C7.0143 15.4316 7.55654 16.448 8.35338 17.2459C9.15022 18.0439 10.1658 18.5875 11.2718 18.8081C12.3777 19.0287 13.5242 18.9163 14.5662 18.4851C15.6082 18.0539 16.4989 17.3234 17.1255 16.3858C17.7522 15.4483 18.0867 14.3459 18.0867 13.2182C18.0867 13.0193 18.1657 12.8286 18.3064 12.6879C18.447 12.5473 18.6378 12.4682 18.8367 12.4682C19.0356 12.4682 19.2264 12.5473 19.3671 12.6879C19.5077 12.8286 19.5867 13.0193 19.5867 13.2182C19.5838 15.1259 18.8246 16.9546 17.4754 18.3034C16.1263 19.6521 14.2974 20.4109 12.3897 20.4132Z"
                fill={color}
            />
            <path
                d="M12.8685 10.5362C12.7709 10.5366 12.6742 10.5178 12.5839 10.4807C12.4937 10.4436 12.4116 10.3891 12.3425 10.3202C12.2724 10.251 12.2166 10.1686 12.1784 10.0778C12.1402 9.98698 12.1202 9.88952 12.1196 9.79099C12.1191 9.69245 12.1379 9.59478 12.1751 9.50354C12.2124 9.4123 12.2672 9.32929 12.3365 9.25924L14.8165 6.74524L12.3365 4.28224C12.1954 4.14194 12.1158 3.95134 12.1152 3.75236C12.1147 3.55339 12.1932 3.36234 12.3335 3.22124C12.4738 3.08015 12.6644 3.00057 12.8634 3C13.0623 2.99944 13.2534 3.07794 13.3945 3.21824L16.4015 6.20824C16.5414 6.34896 16.6199 6.53932 16.6199 6.73774C16.6199 6.93617 16.5414 7.12653 16.4015 7.26724L13.4025 10.3132C13.3327 10.384 13.2496 10.4402 13.1579 10.4785C13.0662 10.5168 12.9678 10.5364 12.8685 10.5362Z"
                fill={color}
            />
        </svg>
    );
};

export default RotateIcon;
