import {useMemo} from "react";
import moment from "moment";

import {Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import {AutocompleteModal} from "ubcommunity-shared/src/General/AutocompleteModal/AutocompleteModal";
import {useGetUniversityQuery} from "ubcommunity-shared/src/types";

type EditCourseProps = {
    universityId: string;
};

const YEARS_OFFSETS = [0, 1, 2, 3, 4];

const dateOfEntryMap = (yearOffset: number) => (month: number) =>
    moment()
        .month(month - 1)
        .endOf("month")
        .add(yearOffset, "year");

export const EditYearOfEntry = ({universityId}: EditCourseProps) => {
    const {trackEvent} = useAnalytics();

    const {data} = useGetUniversityQuery({
        variables: {
            id: universityId,
        },
    });
    const university = data?.university;

    const formattedEntries = useMemo(() => {
        const entryMonths = university?.entries?.entryMonths || [];
        return YEARS_OFFSETS.flatMap((yearOffset) => {
            return entryMonths
                .map(dateOfEntryMap(yearOffset))
                .filter((date) => moment().isBefore(date))
                .map((date) => {
                    const label = date.format("MMMM YYYY");
                    return {id: label, name: label};
                });
        });
    }, [university]);

    if (!university) {
        return null;
    }

    const handleOnSelect = (selectedItems: Array<{id: string}>) => {
        if (selectedItems.length) {
            const rawValue = selectedItems[0].id;
            trackEvent(TrackEvents.YEAR_OF_ENTRY_SELECTED, {
                yearOfEntry: rawValue,
            });
        }
    };

    const handleOnDropdownOpened = () => {
        trackEvent(TrackEvents.YEAR_OF_ENTRY_DROPDOWN_OPENED);
    };

    return (
        <AutocompleteModal
            name="selectYearOfEntry"
            label="My start date is"
            data={formattedEntries}
            renderItem={(item) => <Text size="medium">{item.id}</Text>}
            resultKey="id"
            onSelect={handleOnSelect}
            onDropdownOpened={handleOnDropdownOpened}
            showResultsOnRender
            required
        />
    );
};
