import React from "react";
import {FlatList, TouchableOpacity} from "react-native";
import {Column, Columns, Inline, Text, View, usePatronTheme} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import CheckboxSvg from "ubcommunity-shared/src/Svgs/CheckboxSvg";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {Interest} from "ubcommunity-shared/src/types";
import {themeUnitToNativeValue} from "ubcommunity-shared/src/Utils";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {usePeopleFilters} from "../PeopleFiltersProvider";

type Props = {
    callback: () => void;
    interestsData: Interest[];
};

export const InterestsSelector = ({callback, interestsData}: Props) => {
    const {darkModeEnabled} = useCommunityTheme();
    const {filters, setFilters} = usePeopleFilters();
    const theme = usePatronTheme();
    const {trackEvent} = useAnalytics();

    const onSelect = (interestSelected: Interest) => {
        const interestId = interestSelected.id;
        const oldFilters = filters.interests || [];
        if (oldFilters.find((id) => id === interestId)) {
            trackEvent(TrackEvents.PEOPLE_INTEREST_FILTER_DESELECTED, {
                interestId: interestSelected.id,
                interestName: interestSelected.name,
            });
            setFilters({
                ...filters,
                interests: oldFilters.filter((id) => id !== interestId),
            });
            return;
        }
        trackEvent(TrackEvents.PEOPLE_INTEREST_FILTER_SELECTED, {
            interestId: interestSelected.id,
            interestName: interestSelected.name,
        });
        setFilters({
            ...filters,
            interests: [...oldFilters, interestId],
        });
    };

    return (
        <View flex="1">
            <Columns space="medium" verticalAlign="center">
                <Column>
                    <BackButton
                        borderWidth={0}
                        paddingBottom="xsmall"
                        callback={callback}
                        text="Interests"
                    />
                </Column>
                {/* <Column width="content">
                    <FilterSelectionCount count={filters["interests"]?.length} />
                </Column> */}
            </Columns>

            <FlatList
                data={interestsData}
                renderItem={({item}) => {
                    const selected = filters.interests?.includes(item.id) || false;

                    return (
                        <TouchableOpacity
                            style={{
                                padding: themeUnitToNativeValue(theme.space.small),
                            }}
                            key={item.id}
                            onPress={() => onSelect(item)}
                            accessibilityLabel={`${selected ? "Deselect" : "Select"}
                    ${item.name}
                    `}
                        >
                            <Inline verticalAlign="center" space="small">
                                <CheckboxSvg
                                    filled={selected || false}
                                    color={darkModeEnabled ? "white" : theme.colors.primaryColor}
                                />
                                <Text>{item.name}</Text>
                            </Inline>
                        </TouchableOpacity>
                    );
                }}
            />
        </View>
    );
};
