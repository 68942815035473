import {View} from "@unibuddy/patron";
import {Home} from "ubcommunity-shared/src/Home/Home";
import {HomeBar} from "ubcommunity-shared/src/Home/HomeBar";
import {CommunityHome} from "./CommunityHome";
import {useIsAdminExperience} from "ubcommunity-shared/src/AdminInbox/useIsAdminExperience";

export const HomePage = () => {
    const {isAdminExperience} = useIsAdminExperience();

    return (
        <View flex={1}>
            {isAdminExperience ? null : <HomeBar />}
            <Home>
                <CommunityHome />
            </Home>
        </View>
    );
};
