import {Button, View, Text} from "@unibuddy/patron";
import {SimpleFallback} from "ubcommunity-shared/src/General/Errors/SimpleFallback";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {Link} from "ubcommunity-shared/src/General/Link/Link";
import {SUPPORT_EMAIL} from "ubcommunity-shared/src/constants/constants";

export const ErrorWithRetry = ({retryCallback}: {retryCallback: () => void}) => {
    return (
        <View flex={1} marginTop="xlarge">
            <SimpleFallback
                hideIllustration={true}
                errorMessage="Oh no ! Something went wrong trying to find people !"
            >
                <Text size="medium" align="center">
                    If you continue to experience this issue, please contact{" "}
                    <Link
                        style={{...boldFontStyles}}
                        color="textLinkColor"
                        href="mailto:support@unibuddy.com"
                    >
                        {SUPPORT_EMAIL}
                    </Link>{" "}
                </Text>
                <Button block color="primary" onClick={() => retryCallback()}>
                    <Text color="white" size="medium" style={{...boldFontStyles}}>
                        Try again
                    </Text>
                </Button>
            </SimpleFallback>
        </View>
    );
};
