import React from "react";
import {TouchableOpacity} from "react-native";
import {IconSpeakerXMark, IconLockClosed} from "@unibuddy/icons";
import {ErrorBoundary} from "@unibuddy/error-reporting";
import {
    Stack,
    View,
    Text,
    Box,
    Inline,
    Columns,
    Column,
    colors,
    InlineItem,
} from "@unibuddy/patron";
import {formatDistanceToNowStrict} from "date-fns";
import {
    LastMessageFragmentFragment,
    UniversalInboxConversationFragment,
} from "ubcommunity-shared/src/types";
import {VisuallyHidden} from "ubcommunity-shared/src/Patron/VisuallyHidden/VisuallyHidden";
import {boldFontStyles, semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import useMyId from "ubcommunity-shared/src/Auth/hooks/useMyId";
import {useUnreadMessageListener} from "./useUnreadMessageListener";
import {UniversalInboxUserRole} from "./utils";

const UnreadCount = ({count}: {count: number}) => {
    if (count < 1) return;

    const label = "You have unread message(s)";

    return (
        <View
            // @ts-ignore
            accessibilityLabel={label}
            aria-label={label}
            backgroundColor="primaryColor"
            padding="xxsmall"
            borderRadius="full"
            justifyContent="center"
            alignItems="center"
            style={{aspectRatio: 1}}
        />
    );
};

export function UniversalInboxItemTitle({children}: {children: React.ReactNode}) {
    return (
        <Text
            nowrap
            truncate
            numberOfLines={1}
            style={{
                ...boldFontStyles,
            }}
        >
            {children}
        </Text>
    );
}

type UniversalInboxItemProps = {
    a11yLabel: string;
    onPress: () => void;
    item: UniversalInboxConversationFragment & LastMessageFragmentFragment;
    avatar: React.ReactNode;
    title: React.ReactNode;
    tag?: UniversalInboxUserRole;
    isActive?: boolean;
};

export const MessageTime = ({lastMessageTime}: {lastMessageTime?: string}) => {
    if (!lastMessageTime) return "";

    return (
        <Text size="xsmall" color="grey600">
            {formatDistanceToNowStrict(new Date(lastMessageTime), {
                addSuffix: true,
            })}
        </Text>
    );
};

const formatLastMessage = (lastMessage?: LastMessageFragmentFragment["lastMessage"]): string => {
    if (!lastMessage) {
        return "";
    }
    if (lastMessage?.text) {
        return lastMessage.text;
    }
    return "sent an attachment";
};

export function UniversalInboxItem({
    a11yLabel,
    onPress,
    item,
    avatar,
    title,
    tag,
    isActive = false,
}: UniversalInboxItemProps) {
    const userId: string = useMyId();
    const unreadCount = item.unreadMessageCount ?? 0;
    const lastMessage = item.lastMessage;
    const isMuted = item.mutedBy?.filter((mutedBy) => mutedBy === userId).length > 0;
    const isPrivate = item.isPrivate;

    useUnreadMessageListener({conversationId: item.id, isActive});

    return (
        <View borderRadius="xxsmall" backgroundColor={isActive ? "grey50" : "white"}>
            <TouchableOpacity accessibilityLabel={a11yLabel} onPress={onPress}>
                <View paddingX="small" paddingY="small-medium">
                    <Columns space="xsmall">
                        <Column width="content">
                            <View width={50} alignItems="center">
                                {avatar}
                            </View>
                        </Column>
                        <Column>
                            <Box minW={0} flexGrow="1">
                                <Stack space="small">
                                    <View width="100%">
                                        <Stack space="small">
                                            <Columns verticalAlign="center">
                                                <Column>
                                                    <Box alignItems="flex-start">
                                                        <Inline
                                                            wrap="nowrap"
                                                            space="xxsmall"
                                                            verticalAlign="center"
                                                        >
                                                            <InlineItem flexShrink={1}>
                                                                {title}
                                                            </InlineItem>
                                                            <InlineItem flexShrink={0}>
                                                                {tag ? (
                                                                    <View
                                                                        backgroundColor="grey150"
                                                                        padding="xxsmall"
                                                                        borderRadius="xlarge"
                                                                        marginRight="xsmall"
                                                                    >
                                                                        <Text
                                                                            size="xxsmall"
                                                                            nowrap
                                                                            truncate
                                                                            color="grey900"
                                                                            style={{
                                                                                ...boldFontStyles,
                                                                            }}
                                                                        >
                                                                            {tag}
                                                                        </Text>
                                                                    </View>
                                                                ) : null}
                                                            </InlineItem>
                                                        </Inline>
                                                    </Box>
                                                </Column>
                                                <Column width="content">
                                                    {lastMessage ? (
                                                        <ErrorBoundary fallback={""}>
                                                            <MessageTime
                                                                lastMessageTime={
                                                                    lastMessage.created
                                                                }
                                                            />
                                                        </ErrorBoundary>
                                                    ) : null}
                                                </Column>
                                            </Columns>

                                            <Text nowrap truncate size="small">
                                                {formatLastMessage(lastMessage)}
                                            </Text>

                                            <Columns verticalAlign="center" space="xsmall">
                                                <Column>
                                                    <Box display="flex" alignItems="flex-start">
                                                        <Box
                                                            backgroundColor={
                                                                isActive ? "grey100" : "grey50"
                                                            }
                                                            borderRadius="xxsmall"
                                                            paddingX="xsmall"
                                                            paddingY="xsmall"
                                                        >
                                                            <Inline
                                                                verticalAlign="center"
                                                                space="xxsmall"
                                                                wrap="nowrap"
                                                            >
                                                                <Text
                                                                    nowrap
                                                                    truncate
                                                                    size="xsmall"
                                                                    color={colors.grey800}
                                                                    style={{
                                                                        ...semiBoldFontStyles,
                                                                    }}
                                                                >
                                                                    {item.university?.name ?? ""}
                                                                </Text>
                                                            </Inline>
                                                        </Box>
                                                    </Box>
                                                </Column>
                                                <Column width="content">
                                                    <Inline space="xxsmall" verticalAlign="center">
                                                        {isMuted ? (
                                                            <View>
                                                                <VisuallyHidden>
                                                                    <Text>
                                                                        New message notifications
                                                                        turned off
                                                                    </Text>
                                                                </VisuallyHidden>
                                                                <IconSpeakerXMark
                                                                    width="16"
                                                                    height="16"
                                                                />
                                                            </View>
                                                        ) : null}
                                                        {isPrivate ? (
                                                            <View>
                                                                <VisuallyHidden>
                                                                    <Text>
                                                                        Private conversation
                                                                    </Text>
                                                                </VisuallyHidden>
                                                                <IconLockClosed
                                                                    width="16"
                                                                    height="16"
                                                                />
                                                            </View>
                                                        ) : null}
                                                        {unreadCount > 0 ? (
                                                            <UnreadCount count={unreadCount} />
                                                        ) : null}
                                                    </Inline>
                                                </Column>
                                            </Columns>
                                        </Stack>
                                    </View>
                                </Stack>
                            </Box>
                        </Column>
                    </Columns>
                </View>

                <View marginX="small" borderBottomWidth={1} borderColor="grey50" />
            </TouchableOpacity>
        </View>
    );
}
