import React, {useEffect} from "react";
import {noop} from "lodash";
import {TouchableOpacity} from "react-native";
import {ApolloError} from "@apollo/client";
import {Button, Stack, Text, View} from "@unibuddy/patron";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";

import {SUPPORT_EMAIL} from "ubcommunity-shared/src/constants/constants";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {TrackEvents} from "ubcommunity-shared/src/constants";

import {ErrorSvg} from "./ErrorSvg";
import {Link} from "../Link/Link";

export type QueryErrorHandlerProps = {
    error: Error;
    retryCallback: () => void;
    svgSize?: number;
    layout?: "center" | "initial";
    hideRedirectButton?: boolean;
    hideIllustration?: boolean;
    errorMessage?: string;
    meta?: {
        component?: string;
        query?: string;
    };
};

const getTypeOfError = (error: Error) => {
    if (typeof error === "string") {
        return "string";
    }
    if (
        error instanceof Error &&
        (!error.message || error.message === "undefined") &&
        typeof (error as ApolloError).networkError === "string"
    ) {
        return "partial-with-network-error";
    }
    if (error instanceof Error && (!error.message || error.message === "undefined")) {
        return "partial";
    }

    return undefined;
};

/*
    Network errors from devices are not instances of ApolloError or Error.
    They are just returned as strings and sentry picks them up as `undefined`
    as error.message is `undefined`.
    To handle this, we need to create an instance of Error with the error message
    and report it to sentry.
*/
const createSentryError = (error: Error) => {
    const errorType = getTypeOfError(error);
    if (errorType === "string") {
        return new Error(error as unknown as string);
    }
    if (errorType === "partial") {
        return new Error("An unidentified network error occurred");
    }
    if (errorType === "partial-with-network-error") {
        return new Error((error as ApolloError).networkError as unknown as string);
    }

    return error;
};

export const useQueryErrorHandler = () => {
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    const reportErrorOnly = (
        error: QueryErrorHandlerProps["error"],
        meta: QueryErrorHandlerProps["meta"],
    ) => {
        reportError(error, {
            tags: {
                component: meta?.component ?? "unknown",
                query: meta?.query ?? "unknown",
            },
            level: "error",
        });
    };

    const reportErrorAndTrackEvent = (
        error: QueryErrorHandlerProps["error"],
        meta: QueryErrorHandlerProps["meta"],
    ) => {
        if (!getTypeOfError(error)) {
            trackEvent(TrackEvents.QUERY_ERROR_CAPTURED, {
                component: meta?.component,
                query: meta?.query,
            });
        }

        const formedError = createSentryError(error);
        reportErrorOnly(formedError, meta);
        try {
            // stringify error because sentry doesn't show nested objects
            // this may fail if the error is circular
            console.error("A query error was captured, stringified:", JSON.stringify(error));
        } catch (e) {
            // if stringify fails, log the error object as is
            console.error("A query error was captured: ", error);
        }
    };

    const handleForbiddenError = (error: ApolloError, callback: () => void) => {
        if (
            error &&
            Array.isArray(error.graphQLErrors) &&
            error.graphQLErrors.find((gqlError) => gqlError.extensions.code === "FORBIDDEN")
        ) {
            callback();
        }
    };

    return {
        reportErrorAndTrackEvent,
        reportErrorOnly,
        handleForbiddenError,
    };
};

export enum QueryErrorHandlerStrings {
    ERROR_MESSAGE = "Oh no! Something went wrong.",
    NETWORK_ERROR_MESSAGE = "It seems like you're offline.",
    BUTTON_TEXT = "Try again",
    BODY_TEXT = "If you continue to experience this issue, please contact",
    NETWORK_BODY_TEXT = "Please check your internet connection and try again.",
    FALLBACK_TEXT = "Take me to safety",
}

export const QueryErrorHandler = ({
    svgSize = 200,
    retryCallback = noop,
    error,
    hideRedirectButton = false,
    hideIllustration = false,
    errorMessage = QueryErrorHandlerStrings.ERROR_MESSAGE,
    layout = "initial",
    meta = {
        component: "NotSpecified",
        query: "notSpecified",
    },
}: QueryErrorHandlerProps) => {
    const [navigate] = useNavigate();

    const {reportErrorAndTrackEvent} = useQueryErrorHandler();

    useEffect(() => {
        reportErrorAndTrackEvent(error, meta);
    }, [error, meta, reportErrorAndTrackEvent]);

    const handleRedirect = () => {
        navigate({
            path: Routes.ROOT,
        });
    };

    const errorMessageTitle = getTypeOfError(error)
        ? QueryErrorHandlerStrings.NETWORK_ERROR_MESSAGE
        : errorMessage;
    const errorMessageBody = getTypeOfError(error)
        ? QueryErrorHandlerStrings.NETWORK_BODY_TEXT
        : QueryErrorHandlerStrings.BODY_TEXT;

    return (
        <View
            paddingY={layout === "initial" ? "medium" : "none"}
            paddingX="medium"
            justifyContent={layout === "initial" ? "flex-start" : "center"}
            alignItems="center"
            flex={layout === "initial" ? "none" : "1"}
        >
            <Stack space="medium" align="center">
                {hideIllustration ? null : <ErrorSvg size={svgSize} />}
                <View paddingTop="small" flex={1}>
                    <Text
                        size="large"
                        // @ts-ignore
                        style={{...boldFontStyles}}
                        align="center"
                    >
                        {errorMessageTitle}
                    </Text>
                </View>
                <Text size="medium" align="center">
                    {errorMessageBody}{" "}
                    {getTypeOfError(error) ? null : (
                        <Link
                            style={{...boldFontStyles}}
                            color="textLinkColor"
                            href="mailto:support@unibuddy.com"
                        >
                            {SUPPORT_EMAIL}
                        </Link>
                    )}
                </Text>
                <View paddingTop="small">
                    <Stack space="medium">
                        <Button block color="primary" onClick={() => retryCallback()}>
                            <Text
                                color="white"
                                size="medium"
                                // @ts-ignore
                                style={{...boldFontStyles}}
                            >
                                {QueryErrorHandlerStrings.BUTTON_TEXT}
                            </Text>
                        </Button>
                        {!hideRedirectButton ? (
                            <View w="100%" alignItems="center">
                                <TouchableOpacity onPress={handleRedirect}>
                                    <Text
                                        color="textLinkColor"
                                        size="medium"
                                        // @ts-ignore
                                        style={{
                                            ...boldFontStyles,
                                            textDecorationLine: "underline",
                                            width: "100%",
                                        }}
                                    >
                                        {QueryErrorHandlerStrings.FALLBACK_TEXT}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        ) : null}
                    </Stack>
                </View>
            </Stack>
        </View>
    );
};
