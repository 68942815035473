import {useGetOrCreateWidgetConversationMutation} from "ubcommunity-shared/src/types";
import {SpaceType, useSpaces} from "ubcommunity-shared/src/StudentApp/SpacesProvider";
import {useUpdateInboxQuery} from "ubcommunity-shared/src/Hooks/graphql/useUpdateInboxQuery";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";

// Instead use useCreateConversation hook
export const useGetOrCreateWidgetConversation = () => {
    const [mutation, {loading, error}] = useGetOrCreateWidgetConversationMutation();
    const {selectedSpace} = useSpaces();
    const {addConversationToInbox} = useUpdateInboxQuery();
    const {open} = usePrompt();

    const createWidgetConversation = async (id: string) => {
        // you should never be in this state as this button should only be visible
        // in the prospect experience where you can create widget conversations
        if (!selectedSpace || selectedSpace.type !== SpaceType.UNIVERSITY) {
            open({
                title: "Error",
                message: "You must select a university to start a conversation.",
                buttons: [{text: "Ok"}],
            });
            return;
        }

        const response = await mutation({
            variables: {
                widgetConversationDto: {
                    ambassadorId: id,
                    universityId: selectedSpace.id,
                },
            },
        });
        if (response) {
            addConversationToInbox(response.data?.getOrCreateWidgetConversation);
        }

        return response;
    };

    return {createWidgetConversation, loading, error};
};
