import React, {useRef, useEffect} from "react";
import {ScrollView} from "react-native";
import {View} from "@unibuddy/patron";
import {NetworkStatus} from "@apollo/client";

import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useGetCommunityConversationsGroupsQuery} from "ubcommunity-shared/src/types";
import {Matches} from "ubcommunity-shared/src/Community/Matches";
import {Content} from "ubcommunity-shared/src/Community/Content";
import {ExploreCreateGroupSection} from "ubcommunity-shared/src/Community/CreateGroup/ExploreCreateGroupSection";
import {StudentConfidenceSurveyCTA} from "ubcommunity-shared/src/Explore/StudentConfidenceSurvey/StudentConfidenceSurveyCTA";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {
    QueryErrorHandler,
    useQueryErrorHandler,
} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {ExploreGroupsAndSearch} from "ubcommunity-shared/src/Explore/ExploreGroupsAndSearch";
import {GroupsListShimmer} from "ubcommunity-shared/src/Community/GroupList/GroupsListShimmer";
import {StudentEnrolledQuestionsCTA} from "ubcommunity-shared/src/Explore/StudentEnrolledQuestions";
import {useQueryParams} from "ubcommunity-shared/src/Hooks/useQueryParams";
import {ExploreGroupsListHeader} from "ubcommunity-shared/src/Explore/General/ExploreGroupsListHeader";

export const DesktopExplorePage = () => {
    const {selectedCommunity, select} = useCommunity();
    const scrollViewRef = useRef<ScrollView>(null);
    const groupsViewRef = useRef<HTMLDivElement>(null);
    const {user} = useAuth();
    const [navigate] = useNavigate();
    const {push} = useRelativeRouter();
    const {handleForbiddenError} = useQueryErrorHandler();
    const {getParams} = useQueryParams();

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (getParams("scrollToGroups")) {
            timeout = setTimeout(() => {
                scrollViewRef.current?.scrollTo({
                    y: groupsViewRef.current.getBoundingClientRect().y,
                    animated: true,
                });
            }, 700);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [getParams]);

    // fetch all chat groups for this community
    const {data, networkStatus, refetch, error} = useGetCommunityConversationsGroupsQuery({
        notifyOnNetworkStatusChange: true,
        variables: {communityId: selectedCommunity.id, offsetPagination: {limit: 15}},
    });

    // when user clicks on a group
    const handleSelectChatGroup = (id: string) => {
        const selectedGroup = data.getCommunityConversations.find((group) => group.id === id);
        if (selectedGroup?.communityChatMembers.findIndex((member) => member.id === user.id) >= 0) {
            navigate({
                path: Routes.CHAT,
                options: {
                    conversationId: id,
                    screen: id,
                },
            });
        } else {
            push(`/chatpreview/${id}`);
        }
    };

    React.useEffect(() => {
        handleForbiddenError(error, select);
    }, [error, select, handleForbiddenError]);

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{
                    component: "DesktopExplorePage",
                    query: "useAllCommunityChatGroupsQuery",
                }}
                layout="center"
                hideRedirectButton
            />
        );
    }

    return (
        <View flex="1" alignItems="center">
            <ScrollView style={{width: "100%"}} ref={scrollViewRef}>
                <View width="95%" maxW={768} style={{alignSelf: "center"}}>
                    <StudentEnrolledQuestionsCTA />
                    <StudentConfidenceSurveyCTA />
                    <Matches />
                    <Content />
                    <View
                        marginTop="xlarge"
                        borderRadius="xsmall"
                        id="explore-groups"
                        ref={groupsViewRef}
                    >
                        <View paddingX="small-medium">
                            <ExploreGroupsListHeader
                                handleCreate={() => push(Routes.CREATE_GROUP)}
                            />
                        </View>

                        {networkStatus === NetworkStatus.loading ? (
                            <GroupsListShimmer />
                        ) : (
                            <>
                                <ExploreGroupsAndSearch
                                    data={data}
                                    handleSelectChatGroup={handleSelectChatGroup}
                                />

                                <ExploreCreateGroupSection
                                    handleCreate={() => push(Routes.CREATE_GROUP)}
                                />
                            </>
                        )}
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};
