import {useCallback} from "react";
import {useApolloClient} from "@apollo/client";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {
    ChatMessage,
    ReactionsFragmentDoc,
    UserField,
    useAddReactionToMessageMutation,
    useRemoveReactionFromMessageMutation,
} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useGetGroupType} from "ubcommunity-shared/src/Hooks/useGetGroupType";
import {
    SocketChannel,
    SocketEvent,
    useSocketListener,
} from "ubcommunity-shared/src/Sockets/SocketProvider";

import {useLike} from "../useLike";
import {useReactionUtils} from "./utils";

export const ReactionsMap = {
    Like: {
        emoji: "👍",
        reactionUnicode: "U+1F44D",
        label: "Like",
    },
    Love: {
        emoji: "❤️",
        reactionUnicode: "U+2764",
        label: "Love",
    },
    Laughing: {
        emoji: "😂",
        reactionUnicode: "U+1F602",
        label: "Laughing",
    },
    Praying: {
        emoji: "🙏",
        reactionUnicode: "U+1F64F",
        label: "Praying",
    },
    Dislike: {
        emoji: "👎",
        reactionUnicode: "U+1F44E",
        label: "Dislike",
    },
    Thinking: {
        emoji: "🤔",
        reactionUnicode: "U+1F914",
        label: "Thinking",
    },
};

export const ReactionsList = Object.values(ReactionsMap);

type Props = {
    conversationId: string;
    user: UserField;
};

const conversationChannel = new SocketChannel("conversation", {private: true});
export const reactedMessageEvent = new SocketEvent("reacted-message", conversationChannel);
export const unreactedMessageEvent = new SocketEvent("unreacted-message", conversationChannel);

export const useReaction = ({conversationId, user}: Props) => {
    const client = useApolloClient();
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();
    const {getUserIdsForReaction} = useReactionUtils();
    const groupType = useGetGroupType({conversationId});

    const [addReactionToMessageMutation] = useAddReactionToMessageMutation();
    const [removeReactionFromMessageMutation] = useRemoveReactionFromMessageMutation();

    const {like, unlike} = useLike({user, conversationId, groupType});

    const addReactionToCache = useCallback(
        (messageId: string, reaction: string, reactionUnicode: string, userId: string) => {
            const message = client.readFragment({
                id: `ChatMessage:${messageId}`,
                fragment: ReactionsFragmentDoc,
            });
            if (!message) return;

            const newUserIdsForReaction = [
                ...getUserIdsForReaction(message, reactionUnicode),
                userId,
            ];

            const isFirstReaction = newUserIdsForReaction.length === 1;
            let newReactions = [];
            if (isFirstReaction) {
                newReactions = [
                    ...message.reactions,
                    {
                        reaction,
                        reactionUnicode: reactionUnicode,
                        userIds: newUserIdsForReaction,
                    },
                ];
            } else {
                newReactions = message.reactions?.map((r) => {
                    if (r.reactionUnicode === reactionUnicode) {
                        return {
                            ...r,
                            userIds: newUserIdsForReaction,
                        };
                    }

                    return r;
                });
            }

            client.writeFragment({
                id: `ChatMessage:${messageId}`,
                fragment: ReactionsFragmentDoc,
                data: {
                    reactions: newReactions,
                },
            });
        },
        [client, getUserIdsForReaction],
    );

    const removeReactionFromCache = useCallback(
        (messageId: string, reactionUnicode: string, userId: string) => {
            const message = client.readFragment({
                id: `ChatMessage:${messageId}`,
                fragment: ReactionsFragmentDoc,
            });
            if (!message) return;

            const newUserIdsForReaction = getUserIdsForReaction(message, reactionUnicode).filter(
                (id) => id !== userId,
            );

            const newReactions = message.reactions?.map((r) => {
                if (r.reactionUnicode === reactionUnicode) {
                    return {
                        ...r,
                        userIds: newUserIdsForReaction,
                    };
                }

                return r;
            });

            client.writeFragment({
                id: `ChatMessage:${messageId}`,
                fragment: ReactionsFragmentDoc,
                data: {
                    reactions: newReactions,
                },
            });
        },
        [client, getUserIdsForReaction],
    );

    const addReactionToMessage = useCallback(
        async (message: ChatMessage, reaction: typeof ReactionsList[0]) => {
            try {
                // check if reaction is "Like"
                if (reaction.reactionUnicode === ReactionsMap["Like"].reactionUnicode) {
                    like({
                        message,
                        conversationId,
                        userId: user.id,
                    });
                    return;
                }

                addReactionToCache(message.id, reaction.emoji, reaction.reactionUnicode, user.id);

                await addReactionToMessageMutation({
                    variables: {
                        reactionDto: {
                            conversationId,
                            messageId: message.id,
                            reactionUnicode: reaction.reactionUnicode,
                            reaction: reaction.emoji,
                        },
                    },
                });

                trackEvent(TrackEvents.MESSAGE_REACTION_ADDED, {
                    emoji: reaction.emoji,
                    reactionUnicode: reaction.reactionUnicode,
                    conversationId,
                    messageId: message.id,
                    groupType,
                });
            } catch (error) {
                console.error(error);
                reportError(error);
            }
        },
        [
            addReactionToMessageMutation,
            conversationId,
            like,
            reportError,
            trackEvent,
            user?.id,
            addReactionToCache,
            groupType,
        ],
    );

    const removeReactionFromMessage = useCallback(
        async (message: ChatMessage, reaction: typeof ReactionsList[0]) => {
            try {
                // check if reaction is "Like"
                if (reaction.reactionUnicode === ReactionsMap["Like"].reactionUnicode) {
                    unlike({
                        message,
                        conversationId,
                        userId: user.id,
                    });
                    return;
                }

                removeReactionFromCache(message.id, reaction.reactionUnicode, user.id);

                await removeReactionFromMessageMutation({
                    variables: {
                        reactionDto: {
                            conversationId,
                            messageId: message.id,
                            reactionUnicode: reaction.reactionUnicode,
                            reaction: reaction.emoji,
                        },
                    },
                });

                trackEvent(TrackEvents.MESSAGE_REACTION_REMOVED, {
                    emoji: reaction.emoji,
                    reactionUnicode: reaction.reactionUnicode,
                    conversationId,
                    messageId: message.id,
                    groupType,
                });
            } catch (error) {
                console.error(error);
                reportError(error);
            }
        },
        [
            conversationId,
            removeReactionFromMessageMutation,
            reportError,
            trackEvent,
            unlike,
            user?.id,
            removeReactionFromCache,
            groupType,
        ],
    );

    const onReactedMessageEvent = ({userId, messageId, reaction, reactionUnicode}) => {
        if (userId === user.id) return;
        addReactionToCache(messageId, reaction, reactionUnicode, userId);
    };

    const onUnreactedMessageEvent = ({userId, messageId, reactionUnicode}) => {
        if (userId === user.id) return;
        removeReactionFromCache(messageId, reactionUnicode, userId);
    };

    useSocketListener(reactedMessageEvent, conversationId, onReactedMessageEvent);
    useSocketListener(unreactedMessageEvent, conversationId, onUnreactedMessageEvent);

    return {
        addReactionToMessage,
        removeReactionFromMessage,
    };
};
