import React, {Children} from "react";
import {
    View,
    Text,
    Inline,
    InlineItem,
    TextBlock,
    Stack,
    usePatronTheme,
    LoadingButton,
} from "@unibuddy/patron";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {triggerHaptic} from "ubcommunity-shared/src/General/Haptic/triggerHaptic";
import {
    useJoinCommunityConversationMutation,
    useGetConversationPermissionQuery,
} from "ubcommunity-shared/src/types";
import {useAnalytics} from "@unibuddy/tracking";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {useUpdateInboxQuery} from "ubcommunity-shared/src/Hooks/graphql/useUpdateInboxQuery";

const Container = ({isDesktop, children}: React.PropsWithChildren<{isDesktop: boolean}>) => {
    return isDesktop ? (
        <Inline space="medium" verticalAlign="center" wrap="nowrap">
            {Children.map(children, (child) => (
                <InlineItem flexShrink={0}>{child}</InlineItem>
            ))}
        </Inline>
    ) : (
        <Stack align={"center"} space="small">
            {Children.map(children, (child) => child)}
        </Stack>
    );
};

interface JoinGroupProps {
    conversationId: string;
}

export const JoinGroup = ({conversationId}: JoinGroupProps) => {
    const {trackEvent} = useAnalytics();
    const theme = usePatronTheme();
    const {isDesktop} = useIsDesktop();
    const [join, {loading: loadingJoin}] = useJoinCommunityConversationMutation();
    const [navigate] = useNavigate();
    const {addConversationToInbox} = useUpdateInboxQuery();
    const {bottom} = useSafeAreaInsets();
    const {updateQuery: updateIsMember} = useGetConversationPermissionQuery({
        fetchPolicy: "cache-only",
        variables: {conversationId},
    });
    const {darkModeEnabled} = useCommunityTheme();
    const onJoin = async () => {
        const {data} = await join({
            variables: {
                id: conversationId,
            },
        });

        if (data) {
            addConversationToInbox(data?.joinCommunityConversation);
        }

        updateIsMember((cachedData) => {
            return {
                ...cachedData,
                getConversationPermission: {
                    ...cachedData.getConversationPermission,
                    isMember: true,
                },
            };
        });

        trackEvent(TrackEvents.GROUP_JOINED, {
            conversationId,
        });

        triggerHaptic("notificationSuccess");
        navigate({
            path: Routes.CHAT,
            options: {
                screen: Routes.CHAT_HOME,
                params: {
                    conversationId: conversationId,
                    previousScreen: Routes.JOIN_GROUP,
                },
            },
        });
    };

    return (
        <View
            width="100%"
            padding="medium"
            borderColor={"navbarBorderColor"}
            borderTopWidth={1}
            bgColor={theme.colors.navbarColor}
            position="absolute"
            bottom={-bottom}
            paddingBottom="medium"
        >
            <Container isDesktop={isDesktop}>
                <LoadingButton
                    color="primary"
                    onClick={() => {
                        onJoin();
                    }}
                    loading={loadingJoin}
                    accessibilityLabel="Join group"
                    style={{width: 140}}
                >
                    <Text style={{...boldFontStyles}} color="white">
                        Join group
                    </Text>
                </LoadingButton>
                <TextBlock color={darkModeEnabled ? "lightGrey" : "darkPink"} size="small">
                    Send messages and stay notified
                </TextBlock>
            </Container>
        </View>
    );
};
