import React from "react";
import {useFormikContext} from "formik";
import {Text, LoadingButton} from "@unibuddy/patron";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export default function SubmitButton({children, color}) {
    const {submitForm, isSubmitting} = useFormikContext();

    return (
        <LoadingButton color={color} block onClick={submitForm} loading={isSubmitting}>
            <Text style={{...boldFontStyles, color: "white"}}>{children}</Text>
        </LoadingButton>
    );
}
