export const INVITE_CONTAINER_STRINGS = {
    WELCOME_TO: "Welcome to",
    JOIN_THE_COMMUNITY:
        "Join Community to start chatting with other students and connecting with your classmates!",
    NOT_LOGGED_IN:
        "If this is your first time using Community, you’ll need to create an account on the next page.",
    BUTTON_TEXT_LETS_GO: "Let's go!",
    BUTTON_TEXT_CREATE_ACCOUNT: "Create an account",
    BUTTON_TEXT_INVALID_LINK: "This Link is Invalid",
    GOT_ACCOUNT_ALREADY: "Got an account?",
    BUTTON_TEXT_LOGIN: "Login",
};

export const INVITE_CONTAINER_ERROR_MESSAGE_STRINGS = {
    DEFAULT_ERROR_MESSAGE: "An error occurred. Try reloading the page.",
    COMMUNITY_NOT_FOUND_MESSAGE:
        "Community cannot be found; it may have been deleted, does not exist, or has been archived.",
    INVITE_CODE_INVALID_MESSAGE: "This link is broken.",
    DEFAULT_CONTACT_SUPPORT_MESSAGE:
        "If the problem persists please contact us at support@unibuddy.com",
    CONTACT_UNIVERSITY_MESSAGE:
        "Please contact your university administrator if you think this is a mistake.",
    CONTACT_UNIVERSITY_NEW_LINK_MESSAGE:
        "Please contact your university administrator to request an updated link.",
};
