import React, {useEffect, useState} from "react";
import {gql, useApolloClient} from "@apollo/client";
import {Stack, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {
    useCreateDirectConversationMutation,
    useGetUserProfileQuery,
    MatchingCriteriaEnum,
    useGetRecommendedUsersByNationalityCriterionQuery,
    useGetCommunityUserSearchLazyQuery,
    MatchedUser,
} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {ViewMoreCard} from "ubcommunity-shared/src/General/ViewMoreCard";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {MATCHES_FLAG} from "ubcommunity-shared/src/featureFlags";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {NoMatches} from "./EmptyStates/NoMatches";

import {MatchesCarousalShimmer} from "./MatchesCarousalShimmer";
import {MatchesSection} from "./MatchesSection";
import {ErrorWithRetry} from "./ErrorStates/ErrorWithRetry";
import {useUpdateInboxQuery} from "ubcommunity-shared/src/Hooks/graphql/useUpdateInboxQuery";
import {Onboarding} from "./EmptyStates/Onboarding";
import {MatchesHeader} from "./MatchesHeader";
import {MatchesType} from "./MatchesCarousel";

export const enum MatchFilters {
    ALL = "all",
    AREA_OF_STUDY = "areaOfStudy",
    COUNTRY = "country",
}

export const ErrorMessages = {
    AOS: "Oh no! Something went wrong trying to find people from your area of study",
    Country: "Oh no! Something went wrong trying to find people from your country",
};

type MatchesComponentProps = {
    handleNavigation: (url: string, params?: Record<string, any>) => void;
    handleMatchFilter: (filter: MatchFilters) => void;
};

export const MatchesComponent = ({handleNavigation, handleMatchFilter}: MatchesComponentProps) => {
    const client = useApolloClient();
    const {selectedCommunity} = useCommunity();
    const {user} = useAuth();
    const {trackEvent} = useAnalytics();
    const [createDirectConversationMutation] = useCreateDirectConversationMutation();
    const {addConversationToInbox} = useUpdateInboxQuery();
    const {data: meUser, loading: meUserLoading} = useGetUserProfileQuery({
        fetchPolicy: "cache-only",
    });
    const [editing, setEditing] = useState(false);

    const [
        getAOSMatches,
        {
            data: matchesBasedOnAOS,
            loading: aosMatchesLoading,
            error: matchesBasedOnAOSError,
            refetch: refetchAOSMatches,
        },
    ] = useGetCommunityUserSearchLazyQuery();

    useEffect(() => {
        if (meUser?.getUserProfile?.areaOfStudy && selectedCommunity?.id) {
            getAOSMatches({
                variables: {
                    pagination: {
                        offset: 0,
                        limit: 6,
                    },
                    filterUsers: {
                        communityId: selectedCommunity?.id,
                        areaOfStudy: meUser?.getUserProfile?.areaOfStudy?.map((aos) => aos.id),
                        hideSelf: true,
                    },
                },
            });
        }
    }, [meUser?.getUserProfile?.areaOfStudy, getAOSMatches, selectedCommunity?.id]);

    const {
        data: userRecommendations,
        error: matchesBasedOnCountryError,
        loading,
        refetch: refetchCountryMatches,
    } = useGetRecommendedUsersByNationalityCriterionQuery({
        variables: {
            communityId: selectedCommunity.id,
            limit: 6,
            nationalityCriterion: MatchingCriteriaEnum.Nationality,
        },
        fetchPolicy: "cache-and-network",
    });

    const {reportError} = useErrorReporting();
    const isMatchesEnabled = useIsFeatureEnabledBoolean(MATCHES_FLAG);

    const handleCreateDirectConversation = (position: number) => async (senderId: string) => {
        const userData = client.readFragment({
            id: `UserField:${senderId}`,
            fragment: gql`
                fragment UserFieldFragment on UserField {
                    accountRole
                    id
                    firstName
                }
            `,
        });

        try {
            const response = await createDirectConversationMutation({
                variables: {
                    communityId: selectedCommunity.id,
                    userToConverseWith: userData.id,
                    source: "userInitiatedMatchedConversation",
                },
            });

            if (!response.data) {
                return;
            }

            addConversationToInbox(response.data.createDirectConversation);

            const {id} = response.data.createDirectConversation;

            trackEvent(TrackEvents.CLICK_SEND_MATCH_MESSAGE, {
                conversationId: response.data.createDirectConversation.id,
                senderId: userData.id,
                isDirect: true,
                userId: user?.id,
                position,
            });

            handleNavigation(Routes.CHAT, {
                screen: Routes.CHAT_HOME,
                params: {
                    conversationId: id,
                },
            });
        } catch (error: any) {
            console.log(error);
            reportError(error);
        }
    };

    const handleViewMatches = React.useCallback(
        (filter: MatchFilters) => {
            trackEvent(TrackEvents.MATCHES_LIST_OPENED, {
                source: "Explore page",
                filter,
            });
            handleMatchFilter(filter);
        },
        [trackEvent, handleMatchFilter],
    );

    const renderHeader = React.useCallback(
        (title: string, name: string, filter: MatchFilters) => {
            return (
                <MatchesHeader
                    title={title}
                    name={name}
                    filter={filter}
                    handleViewMatches={handleViewMatches}
                    onEditPressed={() => setEditing(true)}
                />
            );
        },
        [handleViewMatches],
    );

    const retryBothQuery = () => {
        refetchAOSMatches();
        refetchCountryMatches();
    };

    const countryMatches = userRecommendations?.nationalityRecommendedUsers
        ?.matches as MatchedUser[];
    const aosMatches = matchesBasedOnAOS?.communityUserSearch?.result?.map((user) => ({
        user,
    })) as MatchedUser[];
    const totalCountryMatches = userRecommendations?.nationalityRecommendedUsers?.totalMatches;
    const totalAOSMatches = matchesBasedOnAOS?.communityUserSearch?.totalUsers;

    const hasCountryMatches = countryMatches?.length > 0;
    const hasAOSMatches = aosMatches?.length > 0;

    const hasNoMatches = !hasCountryMatches && !hasAOSMatches;

    const hasNotUpdatedProfile = !!!(
        meUser?.getUserProfile?.areaOfStudy?.[0]?.name && user?.country?.name
    );
    const showOnboarding = hasNotUpdatedProfile || editing;
    const showLoadingState = loading || aosMatchesLoading || meUserLoading;

    if (showLoadingState) {
        return <MatchesCarousalShimmer />;
    }

    if (!isMatchesEnabled) {
        return null;
    }
    if (
        !userRecommendations &&
        matchesBasedOnCountryError &&
        !matchesBasedOnAOS &&
        matchesBasedOnAOSError
    ) {
        reportError(matchesBasedOnCountryError);
        reportError(matchesBasedOnAOSError);
        return <ErrorWithRetry retryCallback={retryBothQuery} />;
    }

    if (hasNoMatches && !showOnboarding) {
        return (
            <View marginTop="small-medium">
                {renderHeader(
                    "Studying",
                    meUser?.getUserProfile?.areaOfStudy?.[0]?.name ?? "",
                    MatchFilters.AREA_OF_STUDY,
                )}
                <NoMatches />
            </View>
        );
    }

    return (
        <View marginTop="small-medium">
            {showOnboarding ? (
                <Onboarding onRevealMatches={() => setEditing(false)} />
            ) : (
                <Stack space="small-medium">
                    <MatchesSection
                        hasData={hasAOSMatches}
                        openSource="Area Of Study Carousel"
                        viewMore={
                            totalAOSMatches && totalAOSMatches > 6 ? (
                                <ViewMoreCard
                                    onClick={() => {
                                        trackEvent(TrackEvents.VIEW_MORE_CARD_CLICKED, {
                                            source: "Explore page",
                                        });
                                        handleViewMatches(MatchFilters.AREA_OF_STUDY);
                                    }}
                                    count={totalAOSMatches - 6}
                                />
                            ) : null
                        }
                        matches={aosMatches}
                        type={MatchesType.Aos}
                        handleCreateDirectConversation={handleCreateDirectConversation}
                        header={renderHeader(
                            "Studying",
                            meUser?.getUserProfile?.areaOfStudy?.[0]?.name ?? "",
                            MatchFilters.AREA_OF_STUDY,
                        )}
                        error={matchesBasedOnAOSError}
                        retryCallback={refetchAOSMatches}
                        meta={{
                            component: "MatchesComponent",
                            query: "useGetCommunityUserSearchQuery",
                        }}
                        errorMessage={ErrorMessages["AOS"]}
                    />

                    <MatchesSection
                        hasData={hasCountryMatches}
                        openSource="Nationality Carousel"
                        viewMore={
                            totalCountryMatches && totalCountryMatches > 6 ? (
                                <ViewMoreCard
                                    onClick={() => {
                                        trackEvent(TrackEvents.VIEW_MORE_CARD_CLICKED, {
                                            source: "Explore page",
                                        });
                                        handleViewMatches(MatchFilters.COUNTRY);
                                    }}
                                    count={totalCountryMatches - 6}
                                />
                            ) : null
                        }
                        matches={countryMatches}
                        type={MatchesType.Country}
                        handleCreateDirectConversation={handleCreateDirectConversation}
                        header={renderHeader(
                            "People from",
                            user?.country?.name,
                            MatchFilters.COUNTRY,
                        )}
                        error={matchesBasedOnCountryError}
                        retryCallback={refetchCountryMatches}
                        meta={{
                            component: "MatchesComponent",
                            query: "useGetRecommendedUsersByCriterionQuery",
                        }}
                        errorMessage={ErrorMessages["Country"]}
                    />
                </Stack>
            )}
        </View>
    );
};
