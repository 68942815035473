import {useEffect} from "react";
import {useAnalytics} from "@unibuddy/tracking";

import {ProfileFormValues} from "./AccountInformation";
import {TrackEvents, TrackSources} from "ubcommunity-shared/src/constants";
import {AnyUserField, GetAccountInformationQuery} from "ubcommunity-shared/src/types";
import {AccountInformationSource} from "ubcommunity-shared/src/nativeNavigationParamsList/SettingsStackParamList";
import {useQueryParams} from "ubcommunity-shared/src/Hooks/useQueryParams";

export const useAccountInformationTracking = () => {
    const {trackEvent: mixpanelTrackEvent} = useAnalytics();
    const {getParams} = useQueryParams();
    const accountInformationSource = getParams("source") as AccountInformationSource;

    useEffect(() => {
        mixpanelTrackEvent(TrackEvents.ACCOUNT_INFORMATION_VIEWED, {
            source: accountInformationSource,
        });
    }, [accountInformationSource, mixpanelTrackEvent]);

    const trackEvent = (
        data: GetAccountInformationQuery,
        user: AnyUserField,
        {firstName, lastName, bio, selectCountry, interests, pronouns}: ProfileFormValues,
    ) => {
        const countryName = selectCountry[0]?.name;
        const hasCountryChanged = countryName !== user?.country?.name;

        const hasBioChanged = bio !== data?.getUserProfile?.bio;

        const hasPronounsChanged = pronouns !== data?.getUserProfile?.pronouns;

        const hasFirstNameChanged = firstName !== user?.firstName;
        const hasLastNameChanged = lastName !== user?.lastName;

        let hasInterestsChanged = false;
        const sortedInterests = interests.sort();
        const sortedQueryInterests = data?.getInterestsForUser
            .map((interest) => interest.id)
            .sort();
        for (let i = 0; i < sortedInterests.length; i++) {
            if (sortedInterests.length !== sortedQueryInterests.length) {
                hasInterestsChanged = true;
                break;
            }

            if (sortedInterests[i] !== sortedQueryInterests[i]) {
                hasInterestsChanged = true;
                break;
            }
        }

        const allInterests = data?.getInterests;
        const interestsNames = interests.map(
            (interest) =>
                allInterests.find((initialInterest) => initialInterest.id === interest)?.name,
        );

        const valuesChanged = [];
        const dataChanged = [];
        if (hasBioChanged) valuesChanged.push("bio");
        if (hasPronounsChanged) {
            valuesChanged.push("pronouns");
            dataChanged.push({
                pronouns,
            });
            mixpanelTrackEvent(TrackEvents.PRONOUNS_UPDATED, {
                pronouns,
                source: TrackSources.ACCOUNT_INFO,
            });
        }
        if (hasCountryChanged) valuesChanged.push("country");
        if (hasInterestsChanged) {
            valuesChanged.push("interests");
            dataChanged.push({
                interests: interestsNames,
            });
            mixpanelTrackEvent(TrackEvents.INTERESTS_UPDATED, {
                interests: interestsNames,
                source: TrackSources.ACCOUNT_INFO,
            });
        }
        if (hasFirstNameChanged) {
            valuesChanged.push("firstName");
        }
        if (hasLastNameChanged) {
            valuesChanged.push("lastName");
        }

        mixpanelTrackEvent(TrackEvents.PROFILE_SAVED, {
            valuesChanged,
            dataChanged,
        });
    };

    return {
        trackEvent,
    };
};
