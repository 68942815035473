import React, {PropsWithChildren, useState} from "react";
import {Button, Stack, Text, TextBlock, View} from "@unibuddy/patron";
import {useApolloClient} from "@apollo/client";

import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useSpaces} from "ubcommunity-shared/src/StudentApp/SpacesProvider";
import {Community, CommunityFragmentDoc} from "ubcommunity-shared/src/types";
import {ConfirmInvite} from "./ConfirmInvite";
import {BalloonsSvg} from "./BalloonsSvg";

const Wrapper = ({children}: PropsWithChildren) => {
    const {isDesktop} = useIsDesktop();

    return (
        <View
            maxW={isDesktop ? 360 : "100%"}
            borderWidth={isDesktop ? 1 : 0}
            borderColor="grey100"
            borderRadius="xsmall"
        >
            {children}
        </View>
    );
};

export const Invite = () => {
    const client = useApolloClient();
    const [proceed, setProceed] = useState<boolean>();
    const {selectedSpace} = useSpaces();

    const communityId = selectedSpace?.id;
    const community = client.readFragment<Community>({
        id: `Community:${communityId}`,
        fragment: CommunityFragmentDoc,
        fragmentName: "Community",
    });

    if (proceed) {
        return (
            <Wrapper>
                <ConfirmInvite />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <View padding="medium">
                <Stack space="large" align="center">
                    <Text
                        style={{
                            ...boldFontStyles,
                        }}
                        size="xlarge"
                    >
                        Congratulations!
                    </Text>

                    <BalloonsSvg />

                    <Text align="center">
                        You’ve been invited to join{" "}
                        <TextBlock
                            style={{
                                ...boldFontStyles,
                            }}
                        >
                            {community?.title}
                        </TextBlock>{" "}
                        where you can connect with other students!
                    </Text>

                    <Button block color="primary" onClick={() => setProceed(true)}>
                        <Text color="white">Unlock community</Text>
                    </Button>
                </Stack>
            </View>
        </Wrapper>
    );
};
