import {ChatConversation, useUniversalInboxQuery} from "ubcommunity-shared/src/types";

export const useUpdateInboxQuery = () => {
    const {updateQuery: updateUniversalInboxQuery} = useUniversalInboxQuery({
        fetchPolicy: "cache-only",
    });

    const key = "getChatConversationsForUser";

    const updateQuery = updateUniversalInboxQuery;

    const addConversationToInbox = (conversation?: ChatConversation) => {
        if (!conversation) return;

        updateQuery((cacheData) => {
            if (!cacheData) return cacheData;
            if (!cacheData[key]) return cacheData;
            if (cacheData[key].find((cg) => cg.id === conversation.id)) return cacheData;
            return {
                ...cacheData,
                [key]: [...cacheData[key], conversation],
            };
        });
    };

    const removeConversationFromInbox = (conversationId?: ChatConversation["id"]) => {
        if (!conversationId) return;

        updateQuery((cacheData) => {
            if (!cacheData) return cacheData;
            if (!cacheData[key]) return cacheData;
            const newData = cacheData[key].filter((cg) => cg.id !== conversationId);
            return {
                ...cacheData,
                [key]: newData,
            };
        });
    };

    return {
        addConversationToInbox,
        removeConversationFromInbox,
    };
};
