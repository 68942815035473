import React, {PropsWithChildren} from "react";
import {Box, Button, Heading, Stack, Text, View} from "@unibuddy/patron";
import styled from "styled-components";
import {useAnalytics} from "@unibuddy/tracking";
import {AnyUserField, Community, UniversityField} from "ubcommunity-shared/src/types";
import {SettingsLink} from "ubcommunity-shared/src/Settings/SettingsLink/SettingsLink";
import {ProfileHero} from "ubcommunity-shared/src/Settings/ProfileHero/ProfileHero";
import RelativeSwitch from "ubcommunity-shared/src/Navigation/RelativeSwitch/RelativeSwitch";
import RelativeRoute from "ubcommunity-shared/src/Navigation/RelativeRoute/RelativeRoute";
import {AccountInformation} from "ubcommunity-shared/src/Settings/AccountInformation/AccountInformation";
import {DeveloperOptions} from "ubcommunity-shared/src/Settings/DeveloperOptions/DeveloperOptions";
import {SecurityAndPrivacy} from "ubcommunity-shared/src/Settings/SecurityAndPrivacy/SecurityAndPrivacy";
import RelativeNavLink from "ubcommunity-shared/src/Navigation/RelativeNavLink/RelativeNavLink";
import {useLogout} from "ubcommunity-shared/src/Auth/hooks/useLogout";
import SettingsIcon from "ubcommunity-shared/src/Icons/SettingsIcon";
import AccountInfoIcon from "ubcommunity-shared/src/Icons/AccountInfoIcon";
import PrivacyIcon from "ubcommunity-shared/src/Icons/PrivacyIcon";
import SwitcherIcon from "ubcommunity-shared/src/Icons/SwitcherIcon";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {AccountInformationSource} from "ubcommunity-shared/src/nativeNavigationParamsList/SettingsStackParamList";
import {
    LabelProps,
    mapSettingsEvents,
} from "ubcommunity-shared/src/Settings/SettingsLink/SettingsLinkTracking";
import {CookiesSettings} from "../CookiesSettings/CookiesSettings";
import {SettingsStudentAppWrapper} from "ubcommunity-shared/src/Settings/AccountInformation/SettingsStudentAppWrapper/SettingsStudentAppWrapper";

const DesktopNavLink = styled(RelativeNavLink)`
    &.active {
        background-color: #f2f5f7;
    }
`;

function SettingsPageLayout({title, children}: PropsWithChildren<{title: string}>) {
    return (
        <View padding="large" paddingY="xlarge" overflow="auto">
            <Stack space="xlarge">
                <Heading level="2" size="small" weight="400">
                    {title}
                </Heading>

                {children}
            </Stack>
        </View>
    );
}

export function DesktopSettingsPage({
    user,
    university,
    select,
}: {
    user: AnyUserField;
    university: UniversityField;
    select(community: Community): void;
}) {
    const {push} = useRelativeRouter();

    const onHeroPress = () => {
        push("/settings/account");
    };

    return (
        <Box display="flex" flex="1">
            <View
                w={["50%", "40%", "30%"]}
                maxW={480}
                padding={["small", "small", "medium"]}
                paddingY="xlarge"
                borderRightWidth={1}
                borderColor="grey50"
                overflow="auto"
            >
                <ProfileHero user={user} onPress={onHeroPress} />
                <Navigation user={user} select={select} />
            </View>
            <View flex="1">
                <RelativeSwitch>
                    <RelativeRoute path="/settings/account">
                        <SettingsPageLayout title="Your Profile">
                            <View display="flex" alignItems="center">
                                <View w={["100%", "100%", "40%"]}>
                                    <AccountInformation />
                                </View>
                            </View>
                        </SettingsPageLayout>
                    </RelativeRoute>
                    <RelativeRoute path="/settings/notifications">
                        <SettingsPageLayout title="Notifications" />
                    </RelativeRoute>
                    <RelativeRoute path="/settings/privacy">
                        <SettingsPageLayout title="Security and Privacy">
                            <SecurityAndPrivacy university={university} />
                            <CookiesSettings />
                        </SettingsPageLayout>
                    </RelativeRoute>
                    <RelativeRoute path="/settings/developer">
                        <SettingsPageLayout title="Developer Options">
                            <DeveloperOptions />
                        </SettingsPageLayout>
                    </RelativeRoute>

                    <RelativeRoute path="/settings/manage">
                        <SettingsPageLayout title="Manage account">
                            <Stack space="large">
                                <Text>
                                    Deleting your account will remove all of your data from
                                    Unibuddy’s system, as well as removing any data related to your
                                    account that is being stored with an Institution. This action is
                                    irreversible.
                                </Text>
                                <Text weight="700">This action is irreversible.</Text>
                                <Button block color="danger">
                                    Delete my account
                                </Button>
                            </Stack>
                        </SettingsPageLayout>
                    </RelativeRoute>

                    <RelativeRoute>
                        <SettingsPageLayout title="Account Information">
                            <View w={["100%", "40%"]}>
                                <AccountInformation />
                            </View>
                        </SettingsPageLayout>
                    </RelativeRoute>
                </RelativeSwitch>
            </View>
        </Box>
    );
}

function Navigation({select, user}) {
    const {logout} = useLogout();
    const {push} = useRelativeRouter();
    const {trackEvent} = useAnalytics();

    return (
        <>
            <View marginBottom="medium" flexShrink={0}>
                <Stack space="small">
                    <SettingsLink
                        to={`/settings/account?source=${AccountInformationSource.PANEL}`}
                        as={DesktopNavLink}
                        label="Your profile"
                        icon={<AccountInfoIcon />}
                    />
                    <SettingsLink
                        to="/settings/privacy"
                        as={DesktopNavLink}
                        label="Security and privacy"
                        icon={<PrivacyIcon />}
                    />
                    {user.email.endsWith("@unibuddy.com") ? (
                        <SettingsLink
                            to="/settings/developer"
                            as={DesktopNavLink}
                            label="Developer options"
                            icon={<SettingsIcon />}
                        />
                    ) : null}
                    <SettingsStudentAppWrapper>
                        <SettingsLink
                            label="Switch community"
                            as={Button}
                            icon={<SwitcherIcon />}
                            onClick={() => {
                                trackEvent(mapSettingsEvents[LabelProps.SWITCH_COMMUNITY]);
                                push("/");
                                select();
                            }}
                        />
                    </SettingsStudentAppWrapper>
                </Stack>
            </View>

            <Box marginTop="auto" paddingX="medium">
                <Button color="light" block onClick={logout}>
                    Logout
                </Button>
            </Box>
        </>
    );
}
