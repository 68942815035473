import React from "react";
import {Stack, Text, View} from "@unibuddy/patron";

import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";
import {boldFontStyles} from "../Styles/fontStyles";

export function NewsFeedAbout() {
    const {selectedCommunity} = useCommunity();

    const university = selectedCommunity?.institutions?.length
        ? selectedCommunity.institutions[0]
        : null;

    return (
        <View flex="1" padding="medium">
            <Stack space="medium">
                <Text size="medium" style={{...boldFontStyles}}>
                    Welcome to Updates!
                </Text>
                <Text size="medium">
                    Here you'll get all the latest news and important information from{" "}
                    {university?.name || "this university"}. Check back to stay on track!
                </Text>
            </Stack>
        </View>
    );
}
