import {useAnalytics} from "@unibuddy/tracking";
import {useHistory} from "react-router";
import {PageTitles, TrackEvents} from "ubcommunity-shared/src/constants";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {SignUpStepTwo} from "ubcommunity-shared/src/SignUpStepTwo/SignUpStepTwo";

export const SignUpStepTwoPage = () => {
    usePageTitle(PageTitles.SIGNUP_STEP_2);

    const {trackEvent} = useAnalytics();
    const history = useHistory();
    const id = new URLSearchParams(window.location.search).get("id");

    const handleDismiss = () => {
        trackEvent(TrackEvents.DISCOVER_SIGNUP_STEP_TWO_DISMISSED);
        history.goBack();
    };

    return <SignUpStepTwo onDismiss={handleDismiss} universityId={id ?? ""} />;
};
