import {useCallback} from "react";
import {gql, useApolloClient} from "@apollo/client";
import {useAnalytics} from "@unibuddy/tracking";

import {
    useSocketListener,
    SocketChannel,
    SocketEvent,
} from "ubcommunity-shared/src/Sockets/SocketProvider";
import {
    useCommunityLikeMessageMutation,
    useCommunityUnlikeMessageMutation,
} from "ubcommunity-shared/src/types";

import {TrackEvents} from "../constants";
import {GroupTypes} from "./useChat";

const conversationChannel = new SocketChannel("conversation", {private: true});
export const likeMessageEvent = new SocketEvent("like-message", conversationChannel);
export const unlikeMessageEvent = new SocketEvent("unlike-message", conversationChannel);

const likeFragment = gql`
    fragment PartialLikeMessage on ChatMessage {
        likes
        likesCount
    }
`;

type Props = {
    user: any;
    conversationId: string;
    groupType: GroupTypes;
};

export const useLike = ({user, conversationId, groupType}: Props) => {
    const client = useApolloClient();
    const {trackEvent} = useAnalytics();
    const [likeMutation] = useCommunityLikeMessageMutation();
    const [unlikeMutation] = useCommunityUnlikeMessageMutation();

    const like = useCallback(
        async ({message, userId, conversationId}) => {
            try {
                const newLikes = [...message.likes, userId];
                client.writeFragment({
                    id: `ChatMessage:${message.id}`,
                    fragment: likeFragment,
                    data: {
                        likes: newLikes,
                        likesCount: newLikes.length,
                    },
                });
                await likeMutation({
                    variables: {
                        userId,
                        conversationId,
                        messageId: message.id,
                    },
                });
                trackEvent(TrackEvents.MESSAGE_LIKED, {
                    groupType,
                });
            } catch (error) {
                console.log(error);
            }
        },
        [client, likeMutation, trackEvent, groupType],
    );

    const unlike = useCallback(
        async ({message, userId, conversationId}) => {
            try {
                const newLikes = message.likes.filter((id) => id !== userId);
                client.writeFragment({
                    id: `ChatMessage:${message.id}`,
                    fragment: likeFragment,
                    data: {
                        likes: newLikes,
                        likesCount: newLikes.length,
                    },
                });
                await unlikeMutation({
                    variables: {
                        userId,
                        conversationId,
                        messageId: message.id,
                    },
                });
                trackEvent(TrackEvents.MESSAGE_UNLIKED, {
                    groupType,
                });
            } catch (error) {
                console.log(error);
            }
        },
        [client, unlikeMutation, trackEvent, groupType],
    );

    const onLikeMessageEvent = ({userId, messageId}) => {
        if (userId === user.id) return;
        const message = client.readFragment({
            id: `ChatMessage:${messageId}`,
            fragment: likeFragment,
        });
        if (!message) return;
        const newLikes = [...message.likes, userId];
        client.writeFragment({
            id: `ChatMessage:${messageId}`,
            fragment: likeFragment,
            data: {
                likes: newLikes,
                likesCount: newLikes.length,
            },
        });
    };
    const onUnlikeMessageEvent = ({userId, messageId}) => {
        if (userId === user.id) return;
        const message = client.readFragment({
            id: `ChatMessage:${messageId}`,
            fragment: likeFragment,
        });
        if (!message) return;
        const newLikes = message.likes.filter((id) => id !== userId);
        client.writeFragment({
            id: `ChatMessage:${messageId}`,
            fragment: likeFragment,
            data: {
                likes: newLikes,
                likesCount: newLikes.length,
            },
        });
    };

    useSocketListener(likeMessageEvent, conversationId, onLikeMessageEvent);
    useSocketListener(unlikeMessageEvent, conversationId, onUnlikeMessageEvent);

    return {like, unlike};
};
