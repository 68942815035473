import React from "react";
import {TouchableOpacity} from "react-native";
import {
    View,
    Box,
    Text,
    Inline,
    Button,
    LoadingButton,
    usePatronTheme,
    InlineItem,
} from "@unibuddy/patron";

import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {CountryField, FlagField, UserProfile} from "ubcommunity-shared/src/types";
import {UserAvatar} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {MEMBERS_LIST_BUTTON_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import MessageIcon from "ubcommunity-shared/src/Icons/MessageIcon";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {TrackSources} from "ubcommunity-shared/src/constants/trackingConstants";
import {CountryFlag} from "ubcommunity-shared/src/General/CountryFlag";
import {GetFullName} from "ubcommunity-shared/src/General/GetFullName";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {UserAccountRoles} from "../constants";

const AVATAR_SIZE_MOBILE = 64;
const AVATAR_SIZE_DESKTOP = 50;

export interface IUserDetails {
    id: string;
    firstName: string;
    profilePhoto: string;
    lastName?: string;
    accountRole?: string;
    isOnline?: boolean;
    country?: CountryField | FlagField;
    communityProfile?: UserProfile;
    areaOfStudy?: string;
    department?: string;
}

const getDisplayName = (user: IUserDetails) => {
    if (!user.firstName && !user.lastName) return "this user";
    if (!user.firstName) return user.lastName;
    if (!user.lastName) return user.firstName;
    return `${user.firstName} ${user.lastName}`;
};

/*
 ** This component renders a cell for a user in various listings,
 ** such as Conversation members, Peoples tab, and Ambassador listing.
 */
export const PeopleCell = ({
    user,
    openProfile,
    handleDirectMessage,
    dmLoading = false,
    isDmsEnabled = false,
}: {
    user: IUserDetails;
    openProfile: (source?: string) => void;
    handleDirectMessage: (e: React.TouchEvent, source: string) => void;
    isDmsEnabled?: boolean;
    dmLoading?: boolean;
}) => {
    const {user: authUser} = useAuth();
    const {isDesktop} = useIsDesktop();
    const theme = usePatronTheme();
    const {darkModeEnabled} = useCommunityTheme();
    const membersListButtonFlag = useIsFeatureEnabledBoolean(MEMBERS_LIST_BUTTON_FEATURE_FLAG);

    const isMe = user.id === authUser?.id;
    const userCountryAvailable = Boolean(user.country?.code && user.country?.name);
    return (
        <View flexDirection="row" alignItems="center" width="100%" justifyContent="space-between">
            <View flexShrink={1}>
                <TouchableOpacity
                    accessible
                    onPress={() => openProfile()}
                    accessibilityRole="button"
                    accessibilityLabel={`Open ${getDisplayName(user)}'s profile`}
                >
                    <View flexDirection="row" alignItems="center" gap="small">
                        <Box
                            position="relative"
                            minW={isDesktop ? AVATAR_SIZE_DESKTOP : AVATAR_SIZE_MOBILE}
                        >
                            <UserAvatar
                                size={isDesktop ? AVATAR_SIZE_DESKTOP : AVATAR_SIZE_MOBILE}
                                user={{
                                    id: user?.id,
                                    firstName: user?.firstName,
                                    accountRole: user?.accountRole ?? "",
                                    profilePhoto: user?.profilePhoto,
                                    communityProfile: user?.communityProfile,
                                }}
                            />

                            {user.isOnline ? (
                                <Box
                                    w={12}
                                    h={12}
                                    position="absolute"
                                    bottom={-2}
                                    right={-2}
                                    bgColor={theme?.colors?.green700}
                                    borderRadius="full"
                                    borderWidth={2}
                                    borderColor={theme.colors.body}
                                />
                            ) : null}
                        </Box>
                        <View justifyContent="center" paddingY="xxsmall" flexShrink={1}>
                            <Text
                                style={{...boldFontStyles}}
                                data-testid="user"
                                truncate
                                numberOfLines={1}
                            >
                                <GetFullName user={user} capitalize />
                                {isMe ? " (You)" : null}
                            </Text>
                            {user.areaOfStudy && (
                                <View paddingTop="small">
                                    <Text size="small" truncate numberOfLines={1} color="grey900">
                                        {user.areaOfStudy}
                                    </Text>
                                </View>
                            )}

                            {userCountryAvailable ? (
                                <View paddingTop="small">
                                    <Inline verticalAlign="center" wrap="nowrap">
                                        <InlineItem flexShrink={0}>
                                            <CountryFlag
                                                code={user.country?.code ?? ""}
                                                size={11}
                                                label={user.country?.name ?? ""}
                                                isRound={false}
                                            />
                                        </InlineItem>
                                        <InlineItem flexShrink={1}>
                                            <Text
                                                size="small"
                                                truncate
                                                numberOfLines={1}
                                                color="grey900"
                                            >
                                                {user.country?.name}
                                            </Text>
                                        </InlineItem>
                                    </Inline>
                                </View>
                            ) : user.accountRole === UserAccountRoles.STAFF ? (
                                <View paddingTop="small">
                                    <Text size="small" truncate numberOfLines={1} color="grey900">
                                        {user.department}
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                    </View>
                </TouchableOpacity>
            </View>

            <View>
                {!isMe ? (
                    membersListButtonFlag ? (
                        <Button
                            color="primary"
                            onClick={() => (isMe ? null : openProfile(TrackSources.VIEW_PROFILE))}
                            style={{
                                padding: 8,
                                height: 23,
                            }}
                        >
                            <Text
                                size="xxsmall"
                                color="primary"
                                style={{...boldFontStyles, fontSize: 10}}
                            >
                                View profile
                            </Text>
                        </Button>
                    ) : isDmsEnabled ? (
                        <LoadingButton
                            clear
                            iconOnly
                            round
                            color="light"
                            onClick={(e: React.TouchEvent) =>
                                handleDirectMessage(e, TrackSources.CHAT)
                            }
                            loading={dmLoading}
                            disabled={isMe}
                            aria-label={`Send a direct message to ${getDisplayName(user)}`}
                            accessibilityLabel={`Send a direct message to ${getDisplayName(user)}`}
                        >
                            <MessageIcon
                                size={24}
                                color={darkModeEnabled ? theme.colors.grey : theme.colors.grey600}
                            />
                        </LoadingButton>
                    ) : null
                ) : null}
            </View>
        </View>
    );
};
