import React from "react";
import styled from "styled-components";

import {progressionBarStyle} from "./AvatarWithCircularProgressionBar";

const Circle = styled.circle`
    transform-origin: center;
    animation: dash 1s linear alternate;

    @keyframes dash {
        from {
            stroke-dashoffset: ${(props) => props.strokeDasharray};
        }
        to {
            stroke-dashoffset: ${(props) => props.strokeDashoffset};
        }
    }
`;

type CircularProgressionBarProps = {
    progressPercentage: number;
    size: number;
    progressionBarStyle: progressionBarStyle;
};

export const CircularProgressionBar = ({
    progressPercentage,
    size,
    progressionBarStyle,
}: CircularProgressionBarProps) => {
    const radius = (size - progressionBarStyle.activeStrokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference * (1 - progressPercentage / 100);

    return (
        <svg width={size} height={size} style={{position: "absolute"}}>
            <circle
                stroke={progressionBarStyle.lineColor}
                fill="none"
                cy={size / 2}
                cx={size / 2}
                r={radius}
                strokeWidth={progressionBarStyle.strokeWidth}
            />
            <Circle
                stroke={progressionBarStyle.activeLineColor}
                fill="none"
                cy={size / 2}
                cx={size / 2}
                r={radius}
                strokeWidth={progressionBarStyle.activeStrokeWidth}
                strokeDashoffset={strokeDashoffset}
                strokeDasharray={circumference}
                strokeLinecap="round"
                transform="rotate(90)"
            />
        </svg>
    );
};
