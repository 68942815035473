import React, {useState} from "react";
import styled from "styled-components";
import {useAnalytics} from "@unibuddy/tracking";
import {Button, View, usePatronTheme} from "@unibuddy/patron";
import {useHasOneOfRoles} from "ubcommunity-shared/src/Auth/hooks/useHasRole";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {GroupTypes, useGetGroupType} from "ubcommunity-shared/src/Hooks/useGetGroupType";
import {useGetNewsFeedGroupId} from "ubcommunity-shared/src/NewsFeed/useGetNewsFeedGroupId";
import PlusIcon from "ubcommunity-shared/src/Icons/PlusIcon";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {IMAGES_IN_CHAT} from "ubcommunity-shared/src/featureFlags";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants/adminFeatureFlags";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {adminAccountRoles} from "../ChatDrawer/PinnedMessages";

interface ChatOptionsMenuProps {
    onAttachmentOpen(): void;
    conversationId: string;
    showAttachmentIcon: boolean;
}

const AnimatedIcon = styled(PlusIcon)`
    transition: 200ms ease;
    transform: rotate(${({$isOpen}) => ($isOpen ? "135deg" : "0deg")});
`;

const PlusIconThemed = ({isOpen = false}: {isOpen?: boolean}) => {
    const theme = usePatronTheme();

    return <AnimatedIcon size={30} color={theme?.colors.grey700} $isOpen={isOpen} />;
};

export function ChatOptionsMenu({
    onAttachmentOpen,
    conversationId,
    showAttachmentIcon,
}: ChatOptionsMenuProps) {
    const {trackEvent} = useAnalytics();
    const {push} = useRelativeRouter();
    const {id: newsFeedId} = useGetNewsFeedGroupId();
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
    const imagesInChatFeatureFlag = useIsFeatureEnabledBoolean(IMAGES_IN_CHAT);
    const imagesInChatAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.IMAGES_SETTINGS);
    const {isDesktop} = useIsDesktop();

    const imagesEnabled = imagesInChatFeatureFlag && imagesInChatAdminEnabled;
    const isNews = newsFeedId === conversationId;
    const groupType = useGetGroupType({conversationId});
    const isPublicOrNews = [GroupTypes.PUBLIC, GroupTypes.NEWS_FEED].includes(groupType);
    const isAdminOrAmbo = useHasOneOfRoles(adminAccountRoles);
    const pollsAllowed = isPublicOrNews && isAdminOrAmbo && isDesktop;

    const handleClick = () => {
        const CHAT_ROUTE = `/chat/${conversationId}/poll/create`;

        push(CHAT_ROUTE, {isNews});
    };

    const SINGLE_BUTTON_HEIGHT = 38;
    const BUTTON_WIDTH = 130;
    const JUST_ABOVE_TYPING_INPUT = 65;

    const menuButtons = [
        ...(imagesEnabled
            ? [
                  <Button
                      clear
                      full
                      size="sm"
                      onClick={() => {
                          onAttachmentOpen();
                          trackEvent(TrackEvents.ATTACHMENT_ICON_CLICKED);
                          setIsOptionsMenuOpen(false);
                      }}
                      style={{
                          justifyContent: "flex-start",
                      }}
                  >
                      Share media
                  </Button>,
              ]
            : []),
        ...(pollsAllowed
            ? [
                  <Button
                      clear
                      full
                      size="sm"
                      onClick={handleClick}
                      style={{
                          justifyContent: "flex-start",
                      }}
                  >
                      Create poll
                  </Button>,
              ]
            : []),
    ];

    if (!isAdminOrAmbo) {
        return showAttachmentIcon && imagesEnabled ? (
            <Button
                aria-label="Send attachments"
                iconOnly
                clear
                round
                onClick={() => {
                    onAttachmentOpen();
                    trackEvent(TrackEvents.ATTACHMENT_ICON_CLICKED);
                }}
            >
                <PlusIconThemed />
            </Button>
        ) : null;
    }

    return menuButtons.length > 0 ? (
        <>
            <View
                position="absolute"
                overflow="hidden"
                bottom={JUST_ABOVE_TYPING_INPUT}
                width={BUTTON_WIDTH}
                height={SINGLE_BUTTON_HEIGHT * menuButtons.length}
            >
                <View
                    role="dialog"
                    aria-label="Chat options menu"
                    aria-modal="true"
                    bgColor="white"
                    width={BUTTON_WIDTH}
                    zIndex={10}
                    transition="200ms ease transform"
                    transform={isOptionsMenuOpen ? "translateY(0)" : "translateY(100%)"}
                    borderRadius="small"
                >
                    {menuButtons}
                </View>
            </View>
            {showAttachmentIcon ? (
                <Button
                    aria-label={`${isOptionsMenuOpen ? "Close" : "Open"} chat options menu`}
                    iconOnly
                    clear
                    round
                    onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}
                >
                    <PlusIconThemed isOpen={isOptionsMenuOpen} />
                </Button>
            ) : null}
        </>
    ) : null;
}
