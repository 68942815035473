import React, {memo} from "react";
import {Platform, Pressable} from "react-native";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {Box, Stack, Text, View, VisuallyHidden} from "@unibuddy/patron";
import {
    MessageRow,
    MessageBubbleDesktop,
    MessageBubbleMobile,
    MessageBubblePrimaryButton,
    MessageText,
    MessageQuote,
    Swipe,
} from "@unibuddy/chat-ui";
import {Position} from "@unibuddy/chat-ui/dist/Message/types";

import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {TODO_LIST} from "ubcommunity-shared/src/featureFlags";

import {ChatListProps} from "./ChatList";
import {MessageTodo} from "../MessageTodo";
import {ReplyWithRichContent} from "../ChatInputWithTypingIndicator/ChatInputWithTypingIndicator";
import {MessageReactions} from "../Reactions";
import {ImageThumbnail} from "../Attachments/Images/ImageThumbnail";
import {FileThumbnail} from "../Attachments/Files/FileThumbnail";
import {GroupTypes} from "../useChat";
import {ChatAvatar} from "../ChatAvatar";
import {MessageTop} from "../MessageTop";
import {Gif} from "../Gif/Gif";
import {ChatMessage} from "../../types";
import ReportIcon from "../../Icons/ReportIcon";
import TrashIcon from "../../Icons/TrashIcon";
import PinnedMessage from "../../Icons/PinnedMessage";
import PinnedMessageFilled from "../../Icons/PinnedMessageFilled";
import ReplyIcon from "../../Icons/ReplyIcon";
import BlockIcon from "../../Icons/BlockIcon";
import {Poll} from "../Poll";

type MessageReplyProps = {
    message: ChatMessage;
    userId: string;
    isMe: boolean;
    align: "left" | "right";
};

function MessageReply({message, userId, isMe, align}: MessageReplyProps) {
    const originalMessage = message.richContent.reply;
    return (
        <MessageQuote
            highlight={!isMe && originalMessage.sender.id === userId}
            align={align}
            author={originalMessage.sender?.firstName}
        >
            <ReplyWithRichContent message={originalMessage} />
        </MessageQuote>
    );
}

const avatarSpacer = (
    <Box flexShrink={0} paddingX={["xxsmall", "xxsmall", "xsmall"]}>
        <Box w={[35, 35, 40]} />
    </Box>
);

export const shouldShowAvatar = ({
    position,
    align,
    avatar,
}: {
    position: Position;
    align: "left" | "right";
    avatar: React.ReactNode;
}) => {
    if (align === "right") {
        return null;
    } else if (position !== "top" && position !== "orphan") {
        return avatarSpacer;
    }
    return avatar;
};

interface MessageProps {
    isDesktop: boolean;
    item: ChatMessage;
    avatarSize: number;
    position: Position;
    onUnlike: Pick<ChatListProps, "onUnlike">["onUnlike"];
    onLike: Pick<ChatListProps, "onLike">["onLike"];
    isTouchDevice: boolean;
    hasAdminPrivileges: boolean;
    onBlockOrReport: Pick<ChatListProps, "onBlockOrReport">["onBlockOrReport"];
    onDelete: Pick<ChatListProps, "onDelete">["onDelete"];
    userId: string;
    onSwipe(message: ChatMessage): void;
    onReply(message: ChatMessage): void;
    onLongPress(message: ChatMessage): void;
    onPin: Pick<ChatListProps, "onPin">["onPin"];
    canPin: boolean;
    pinnedMessageIds: string[];
    onShowUserSummary: Pick<ChatListProps, "onShowUserSummary">["onShowUserSummary"];
    onSpotlight: Pick<ChatListProps, "onSpotlight">["onSpotlight"];
    onPreview: Pick<ChatListProps, "onPreview">["onPreview"];
    groupType: GroupTypes;
    conversationId: string;
    onReacted: Pick<ChatListProps, "onReacted">["onReacted"];
    isMember: boolean;
}

export const Message = memo<MessageProps>(
    ({
        isDesktop,
        item,
        avatarSize,
        position,
        onUnlike,
        onLike,
        isTouchDevice,
        hasAdminPrivileges,
        onBlockOrReport,
        onDelete,
        userId,
        onSwipe,
        onReply,
        onPin,
        canPin,
        onLongPress,
        pinnedMessageIds,
        onShowUserSummary,
        onSpotlight,
        onPreview,
        groupType,
        onReacted,
        isMember,
    }) => {
        const ChatBubbleComponent = isTouchDevice ? MessageBubbleMobile : MessageBubbleDesktop;
        const isMe = item.sender.id === userId;
        const align = isMe ? "right" : "left";
        const created = new Date(item.created);
        const isLiked = item.likes.includes(userId);
        const isPinned = pinnedMessageIds?.includes(item?.id);
        const replyText = `${item.sender.firstName} says: `;

        const isTodoListFeatureEnabled = useIsFeatureEnabledBoolean(TODO_LIST);
        const shouldShowTodoList =
            isTodoListFeatureEnabled && groupType === GroupTypes.NEWS_FEED && !item.deleted;
        const messageTime = formatDistanceToNow(created, {
            addSuffix: true,
        });
        return (
            <Swipe
                as={Platform.OS === "web" ? "li" : undefined}
                enabled={
                    !isDesktop && groupType !== GroupTypes.NEWS_FEED && !item?.richContent?.poll
                }
                align={align}
                onSwipe={() => onSwipe(item)}
                // The reason we are not using `message.id` here for the key is because
                // it changes between the optimistic and "updated" message. By creating
                // a compound key that uses a user.id and created timestamp we are preserving
                // the key between updates. Even tho `created` changes as well, we don't particularly
                // care, and can keep using the fake "offline" created. Not using the offline id is
                // important because we might use the id in delete message, pin, etc.
                key={`${item.created}-${item.sender.id}`}
            >
                <MessageRow
                    avatar={shouldShowAvatar({
                        align,
                        avatar: (
                            <Box flexShrink={0} paddingX={["xxsmall", "xxsmall", "xsmall"]}>
                                <Pressable onPress={() => onShowUserSummary(item.sender.id)}>
                                    <ChatAvatar
                                        size={avatarSize}
                                        sender={{
                                            id: item.sender.id,
                                            firstName: item.sender.firstName,
                                            accountRole: item.sender.accountRole,
                                            profilePhoto: item.sender.profilePhoto,
                                            communityProfile: item.sender.communityProfile,
                                        }}
                                    />
                                </Pressable>
                            </Box>
                        ),
                        position,
                    })}
                    align={align}
                    position={position}
                    addonTop={
                        !isMe && (position === "top" || position === "orphan") ? (
                            <Box aria-hidden>
                                <Pressable onPress={() => onShowUserSummary(item.sender.id)}>
                                    <MessageTop sender={item.sender} />
                                </Pressable>
                            </Box>
                        ) : null
                    }
                    addonBottom={
                        <Stack>
                            {shouldShowTodoList ? <MessageTodo item={item} /> : null}
                            {position === "bottom" || position === "orphan" ? (
                                <Box aria-hidden paddingTop="xxsmall">
                                    <Text size="xxsmall">
                                        {messageTime === "less than a minute ago"
                                            ? "a few seconds ago"
                                            : messageTime}
                                    </Text>
                                </Box>
                            ) : null}
                        </Stack>
                    }
                    reactions={
                        <MessageReactions
                            item={item}
                            pinnedMessageIds={pinnedMessageIds}
                            userId={userId}
                            onReacted={onReacted}
                        />
                    }
                >
                    {Platform.OS === "web" ? (
                        <VisuallyHidden>
                            <Text>{isMe ? "I say: " : replyText}</Text>
                        </VisuallyHidden>
                    ) : null}
                    <ChatBubbleComponent
                        deleted={item.deleted}
                        onDoublePress={() =>
                            isMember ? (isLiked ? onUnlike(item) : onLike(item)) : null
                        }
                        onLongPress={() => (isMember ? onLongPress(item) : null)}
                        primaryButtons={
                            isTouchDevice || item.deleted
                                ? null
                                : isMember
                                ? [
                                      groupType !== GroupTypes.NEWS_FEED &&
                                      !item?.richContent?.poll ? (
                                          <MessageBubblePrimaryButton
                                              key="reply"
                                              label="Reply"
                                              onClick={() => onReply(item)}
                                              icon={<ReplyIcon />}
                                          />
                                      ) : null,
                                      canPin ? (
                                          <MessageBubblePrimaryButton
                                              key="pin"
                                              label={isPinned ? "Un-pin" : "Pin"}
                                              onClick={() => onPin(item)}
                                              icon={
                                                  isPinned ? (
                                                      <PinnedMessageFilled
                                                          width="22px"
                                                          height="22px"
                                                      />
                                                  ) : (
                                                      <PinnedMessage width="22px" height="22px" />
                                                  )
                                              }
                                          />
                                      ) : null,
                                      !item?.deleted &&
                                      !item?.id.startsWith("optimistic") &&
                                      (hasAdminPrivileges || isMe) ? (
                                          <MessageBubblePrimaryButton
                                              key="delete"
                                              label="Delete"
                                              onClick={() => onDelete(item)}
                                              icon={<TrashIcon width="22px" height="22px" />}
                                          />
                                      ) : null,
                                      item.sender.accountRole === "applicant" &&
                                      item?.sender?.id !== userId ? (
                                          <MessageBubblePrimaryButton
                                              key="report"
                                              label="Report"
                                              onClick={() => onBlockOrReport(item, "report")}
                                              icon={<ReportIcon width="16px" height="16px" />}
                                          />
                                      ) : null,
                                      hasAdminPrivileges &&
                                      item.sender.accountRole === "applicant" ? (
                                          <MessageBubblePrimaryButton
                                              key="block"
                                              label="Block"
                                              onClick={() => onBlockOrReport(item, "block")}
                                              icon={<BlockIcon width="22px" height="22px" />}
                                          />
                                      ) : null,
                                  ].filter(Boolean)
                                : []
                        }
                        position={position}
                        align={align}
                    >
                        {item.deleted ? (
                            <MessageText deleted={item.deleted} align={align}>
                                {item.text}
                            </MessageText>
                        ) : (
                            <Stack space="small">
                                {item.richContent?.reply ? (
                                    <MessageReply
                                        message={item}
                                        userId={userId}
                                        isMe={isMe}
                                        align={align}
                                    />
                                ) : null}
                                {item.richContent?.giphy ? (
                                    <View borderRadius="xsmall" overflow="hidden">
                                        <Gif
                                            id={item.richContent?.giphy.id}
                                            aspectRatio={item.richContent?.giphy.aspectRatio}
                                        />
                                    </View>
                                ) : null}
                                {item.richContent?.images ? (
                                    <ImageThumbnail
                                        onLongPress={() => onLongPress(item)}
                                        onSpotlight={onSpotlight}
                                        images={item.richContent?.images}
                                    />
                                ) : null}
                                {item.richContent?.attachments ? (
                                    <FileThumbnail
                                        onLongPress={() => onLongPress(item)}
                                        onPreview={onPreview}
                                        files={item.richContent?.attachments}
                                    />
                                ) : null}
                                {item.richContent?.poll ? (
                                    <Poll
                                        messageId={item.id}
                                        isMe={isMe}
                                        poll={item.richContent.poll}
                                    />
                                ) : null}
                                {item.text ? (
                                    <MessageText deleted={item.deleted} align={align}>
                                        {item.text}
                                    </MessageText>
                                ) : null}
                            </Stack>
                        )}
                    </ChatBubbleComponent>
                </MessageRow>
            </Swipe>
        );
    },
);
