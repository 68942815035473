import React from "react";

import {Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useGetAllCountriesQuery} from "ubcommunity-shared/src/types";
import {CountryFlag} from "ubcommunity-shared/src/General/CountryFlag";
import {AutocompleteModal} from "ubcommunity-shared/src/General/AutocompleteModal/AutocompleteModal";
import {capitalize} from "ubcommunity-shared/src/Utils/capitalize";

const CountryFlagWithName = ({country, size}: any) => {
    return (
        <View alignItems="center" flexDirection="row">
            <CountryFlag size={size} label={country.name} code={country.code} isRound={false} />
            <Text size="medium">{capitalize(country.name)}</Text>
        </View>
    );
};

export const EditNationality = () => {
    const {trackEvent} = useAnalytics();
    const {data} = useGetAllCountriesQuery({
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const handleOnDialogOpened = () => {
        trackEvent(TrackEvents.SELECT_NATIONALITY_OPEN);
    };

    const handleOnDialogDismiss = () => {
        trackEvent(TrackEvents.SELECT_NATIONALITY_DISMISSED);
    };

    return (
        <AutocompleteModal
            name="selectCountry"
            label="I'm from"
            data={data?.allCountries}
            renderItem={(item) => <CountryFlagWithName country={item} size={25} />}
            resultKey="name"
            onDropdownOpened={handleOnDialogOpened}
            showResultsOnRender
            onDismiss={handleOnDialogDismiss}
            resultsRenderItem={(item) => <CountryFlagWithName country={item} size={20} />}
            required
        />
    );
};
