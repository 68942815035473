import React, {lazy, Suspense} from "react";
import {useRouteMatch} from "react-router";
import {View} from "@unibuddy/patron";

import RelativeRouter from "ubcommunity-shared/src/Navigation/RelativeRouter/RelativeRouter";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {EmailInviteProvider} from "ubcommunity-shared/src/Auth/EmailInviteProvider/EmailInviteProvider";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useIsStudentAppExperience} from "ubcommunity-shared/src/Hooks/useIsStudentAppExperience";
import {EmailInvitePrompt} from "ubcommunity-shared/src/Auth/EmailInviteProvider/EmailInvitePrompt";

import {StudentAppRoutes} from "./StudentAppRoutes";
const AuthRoutes = lazy(() => import("../Auth/AuthRoutes/AuthRoutes"));

export enum UniversityRoutesStrings {
    LoadingApp = "Loading app ...",
}

const UniversityRoutes: React.FC = () => {
    const match = useRouteMatch();
    const {isLoggedIn, isVerified, loading: authLoading} = useAuth();

    const {loading: experienceLoading} = useIsStudentAppExperience();

    if (authLoading || experienceLoading)
        return (
            <View flex="1" justifyContent="center">
                <ActivityIndicator accessibilityLabel="Loading app ..." />
            </View>
        );

    if (!isLoggedIn() || !isVerified()) {
        return (
            <Suspense fallback={null}>
                <RelativeRouter path={match.path} url={match.url}>
                    <EmailInviteProvider>
                        <AuthRoutes />
                    </EmailInviteProvider>
                </RelativeRouter>
            </Suspense>
        );
    }

    return (
        <Suspense fallback={null}>
            <EmailInvitePrompt />
            <RelativeRouter path={match.path} url={match.url}>
                <StudentAppRoutes />
            </RelativeRouter>
        </Suspense>
    );
};

export default UniversityRoutes;
