import React, {useState} from "react";
import {Platform, ScrollView} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {
    View,
    Text,
    Button,
    useClipboard,
    Stack,
    Divider,
    usePatronTheme,
    Column,
    Columns,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import CopyLinkIcon from "ubcommunity-shared/src/Icons/CopyLinkIcon";
import {GroupActions} from "ubcommunity-shared/src/Community/GroupActions/GroupActions";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import InfoIcon from "ubcommunity-shared/src/Icons/InfoIcon";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import ReportIcon from "ubcommunity-shared/src/Icons/ReportIcon";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";
import {GetChatConversation} from "ubcommunity-shared/@types/chat";
import {useIsGroupMember} from "ubcommunity-shared/src/Hooks/useIsGroupMember";
import {NewsFeedAbout} from "ubcommunity-shared/src/NewsFeed";
import {ToggleMute} from "./ToggleMute/ToggleMute";

export const DYNAMIC_LINK_BUTTON_TEXT = "Copy Invite Link";
export const COPIED_TO_CLIPBOARD_TEXT = "Link copied to clipboard!";

const DynamicLinkContainer = ({
    dynamicLink,
    conversationId,
}: {
    dynamicLink: string;
    conversationId: string;
}) => {
    const {copyToClipboard} = useClipboard();
    const theme = usePatronTheme();
    const {trackEvent} = useAnalytics();

    const [buttonText, setButtonText] = useState<string>(DYNAMIC_LINK_BUTTON_TEXT);

    const handleOnCopyDynamicLink = () => {
        copyToClipboard(dynamicLink);
        setButtonText(COPIED_TO_CLIPBOARD_TEXT);
        trackEvent("Group invite: Link copied", {
            conversationId,
        });
        setTimeout(() => setButtonText(DYNAMIC_LINK_BUTTON_TEXT), 2000);
    };

    return (
        // we hardcode the bgColor value here since the "secondaryColor" prop on the theme
        // is completely different than the usual color schemes we use in our designs.
        <Stack space="medium">
            <View padding="medium" bgColor="#CFC8FF" borderRadius="small">
                <Stack space="medium">
                    <Text color="primaryColor" style={{...boldFontStyles}}>
                        Invite others into your group!
                    </Text>

                    <Text size="small" color="primaryColor">
                        Copy a link to this group to invite others in! Only students that are
                        already in this community can join the group.
                    </Text>

                    <Button
                        block
                        color="primary"
                        onClick={handleOnCopyDynamicLink}
                        accessibilityLabel={DYNAMIC_LINK_BUTTON_TEXT}
                        aria-live="polite"
                    >
                        <View
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {buttonText === DYNAMIC_LINK_BUTTON_TEXT ? (
                                <CopyLinkIcon
                                    color={theme.colors?.white}
                                    style={{
                                        marginRight: 8,
                                        ...Platform.select({
                                            ios: {marginTop: 4},
                                        }),
                                    }}
                                />
                            ) : null}

                            <Text
                                color={theme.colors?.white}
                                style={{
                                    ...boldFontStyles,
                                    ...Platform.select({
                                        ios: {marginTop: 0},
                                    }),
                                }}
                            >
                                {buttonText}
                            </Text>
                        </View>
                    </Button>
                </Stack>
            </View>
        </Stack>
    );
};

export function ChatPageAbout({
    conversation,
    onLeaveGroupSuccess,
}: {
    conversation: GetChatConversation;
    onLeaveGroupSuccess: () => void;
}) {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();
    const isPrivateGroupsAdminEnabled = useIsFeatureAdminEnabled(
        AdminFeatureFlags.PRIVATE_GROUPS_SETTINGS,
    );
    const isPrivateGroupsEnabled = isPrivateGroupsAdminEnabled;

    const {bottom} = useSafeAreaInsets();

    const dynamicLink = conversation!.communityChatDynamicLink;
    const isDirect = conversation!.isDirect;
    const isPrivate = conversation!.isPrivate;
    const isNewsFeed = conversation!.isNewsFeed;
    const isPublic = !isPrivate && !isDirect && !isNewsFeed;
    const {isMember} = useIsGroupMember(conversation.id);

    const shouldShowLink = (isPublic || (isPrivate && isPrivateGroupsEnabled)) && !!dynamicLink;

    if (isNewsFeed) {
        return <NewsFeedAbout />;
    }

    return (
        <View
            {...Platform.select({
                web: {
                    height: "100%",
                    overflow: "auto",
                },
                native: {
                    as: ScrollView,
                    flex: 1,
                    contentContainerStyle: {flexGrow: 1, paddingBottom: bottom + 34},
                },
            })}
            padding="medium"
        >
            <Stack space="medium">
                {conversation?.university?.name ? (
                    <View w="100%" paddingTop="small">
                        <Stack space="small">
                            <Text size="small" style={{...boldFontStyles}}>
                                Institution
                            </Text>
                            <Text>{conversation?.university?.name}</Text>
                        </Stack>
                    </View>
                ) : null}

                {conversation?.communityChatDescription ? (
                    <View w="100%" paddingTop="small">
                        <Stack space="medium">
                            <Stack space="small">
                                <Text size="small" style={{...boldFontStyles}}>
                                    Description
                                </Text>
                                <Text>{conversation.communityChatDescription}</Text>
                            </Stack>
                            <Divider />
                        </Stack>
                    </View>
                ) : null}

                <View paddingBottom="xxlarge">
                    <Stack space="medium">
                        {isPrivate ? (
                            <Stack space="medium">
                                <Text style={{...boldFontStyles}}>This is a Private Group</Text>
                                <Columns space="xsmall">
                                    <Column width="content">
                                        <InfoIcon
                                            color={
                                                darkModeEnabled ? "#fff" : theme.colors?.darkGrey
                                            }
                                        />
                                    </Column>
                                    <Column>
                                        <View paddingTop="xxsmall">
                                            <Text>
                                                Unless you invite an admin or ambassador into the
                                                group, it will not be moderated.
                                            </Text>
                                        </View>
                                    </Column>
                                </Columns>
                            </Stack>
                        ) : null}

                        {isPrivateGroupsEnabled ? (
                            <Columns space="xsmall">
                                <Column width="content">
                                    <ReportIcon
                                        color={darkModeEnabled ? "#fff" : theme.colors?.darkGrey}
                                    />
                                </Column>
                                <Column>
                                    <View paddingTop="xxsmall">
                                        <Text>
                                            Group members can report messages to a university admin
                                        </Text>
                                    </View>
                                </Column>
                            </Columns>
                        ) : null}

                        {isPublic ? (
                            Platform.OS === "web" ? null : (
                                <Stack space="medium">
                                    <Divider />
                                    <ToggleMute
                                        conversationName={conversation.communityChatTitle}
                                        conversationId={conversation.id}
                                        mutedBy={conversation.mutedBy}
                                    />
                                    <Divider />
                                </Stack>
                            )
                        ) : null}

                        {shouldShowLink ? (
                            <DynamicLinkContainer
                                dynamicLink={dynamicLink}
                                conversationId={conversation.id}
                            />
                        ) : null}

                        {isPrivateGroupsEnabled ? <Divider /> : null}

                        {isMember && !isDirect ? (
                            <GroupActions
                                isPrivate={isPrivate}
                                conversationId={conversation.id}
                                onLeaveGroupSuccess={onLeaveGroupSuccess}
                            />
                        ) : null}
                    </Stack>
                </View>
            </Stack>
        </View>
    );
}
