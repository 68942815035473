import React from "react";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

import {Text, View, Columns, Column} from "@unibuddy/patron";

export const ContextMenuItem = ({icon, text}) => {
    const {darkModeEnabled} = useCommunityTheme();

    return (
        <Columns verticalAlign="center">
            {icon ? (
                <Column width="content">
                    <View paddingRight="small">
                        {React.cloneElement(
                            icon,
                            {
                                color: darkModeEnabled ? "white" : "grey",
                                fill: darkModeEnabled ? "white" : "grey",
                            },
                            null,
                        )}
                    </View>
                </Column>
            ) : null}
            <Column>
                <Text size="large">{text}</Text>
            </Column>
        </Columns>
    );
};
