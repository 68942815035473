import {View, Text, TextLine} from "@unibuddy/patron";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import NoMatchesIcon from "ubcommunity-shared/src/Icons/NoMatchesIcon.native";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

export const NoMatches = () => {
    const {isDesktop} = useIsDesktop();
    const {darkModeEnabled} = useCommunityTheme();
    const CONTAINER_HEIGHT = 120;
    return (
        <View
            h={CONTAINER_HEIGHT}
            borderColor={darkModeEnabled ? "darkGrey" : "grey150"}
            borderWidth={1}
            marginLeft="small-medium"
            marginRight={!isDesktop ? "small-medium" : undefined}
            borderRadius="xsmall"
        >
            <View flexDirection="row" alignItems="center" justifyContent="center" margin="medium">
                <View flex="2 0 auto" alignItems="flex-end">
                    <NoMatchesIcon />
                </View>
                <View flex="2 2 auto" marginLeft="medium">
                    <Text>
                        No matches found yet based on your profile.{" "}
                        <TextLine weight="bold">Check back soon!</TextLine>
                    </Text>
                </View>
            </View>
        </View>
    );
};
