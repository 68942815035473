import {useCallback, useRef} from "react";
import {FlatList, FlatListProps, Alert, Platform} from "react-native";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {ListFooter, PeopleLoading} from "ubcommunity-shared/src/People/PeopleLoading";
import {PublicMentorField} from "ubcommunity-shared/src/types";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {PeopleEmptyResults} from "ubcommunity-shared/src/People/PeopleEmptyResults";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {AmbassadorItem} from "./AmbassadorItem";
import {AMBASSADOR_LIST_LIMIT, useMentorList} from "./useMentorList";

export const CONVERSATION_INITIATION_ERROR_MESSAGE =
    "An error occurred while creating conversation. Please try again later.";

export const AmbassadorList = () => {
    const keyExtractor = useCallback(
        (item: PublicMentorField, index: number) => `item-${item.id}-${index}`,
        [],
    );

    const {loading, data, fetchNext, error, fetchNextLoading, refetch} = useMentorList();
    const {isDesktop} = useIsDesktop();
    const {open} = usePrompt();
    const previousItemsCount = useRef(0);
    const {reportError} = useErrorReporting();

    const onConversationError = useCallback(
        (error?: Error) => {
            if (Platform.OS === "web") {
                open({
                    title: "Oops!",
                    message: CONVERSATION_INITIATION_ERROR_MESSAGE,
                    buttons: [{text: "OK"}],
                });
            } else {
                Alert.alert("Error", CONVERSATION_INITIATION_ERROR_MESSAGE);
            }
            reportError({
                name: "Ambassador Listing",
                message: error?.message ?? "Conversation initiation failed in Ambassador Lisiting",
            });
        },
        [reportError, open],
    );

    if (loading) return <PeopleLoading isDmsEnabled={true} />;

    const onEndReached = () => {
        const currentItemsCount = data?.length;

        const difference = currentItemsCount - previousItemsCount.current;

        if (currentItemsCount !== 0 && difference < AMBASSADOR_LIST_LIMIT) {
            return;
        }

        if (difference === 0) {
            return;
        }

        previousItemsCount.current = currentItemsCount;

        fetchNext();
    };

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{
                    component: "AmbassadorLising",
                    query: "useSequenceFilterMentorListLazyQuery",
                }}
                layout="center"
            />
        );
    }

    const desktopProps: Partial<FlatListProps<PublicMentorField | null>> = {};

    if (isDesktop) {
        desktopProps.numColumns = 3;
        desktopProps.contentContainerStyle = {
            alignItems: "center",
        };
    }

    return (
        <FlatList
            keyExtractor={keyExtractor}
            data={data ?? []}
            onEndReached={onEndReached}
            onEndReachedThreshold={0.5}
            ListEmptyComponent={
                <PeopleEmptyResults
                    title="No mentors are added for your university."
                    description="Please contact the admin for more information."
                />
            }
            ListFooterComponent={<ListFooter loading={fetchNextLoading} />}
            renderItem={({item}) => (
                <AmbassadorItem
                    key={item?.id}
                    ambassador={item}
                    isDmsEnabled
                    onError={onConversationError}
                />
            )}
            {...desktopProps}
        />
    );
};
