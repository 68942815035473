import React from "react";
import {Inline} from "@unibuddy/patron";
import {Flag} from "ubcommunity-shared/src/Patron/Flag";
import {PublicMentorField} from "ubcommunity-shared/src/types";

type AmbassadorFlagsProps = {
    user: PublicMentorField;
    hideSecondary?: boolean;
};

export const AmbassadorFlags = ({user, hideSecondary = false}: AmbassadorFlagsProps) => {
    return (
        <Inline space="xxsmall">
            {user?.mentorFields?.primaryFlag ? (
                <Flag
                    label={user.mentorFields.primaryFlag.name ?? ""}
                    code={user.mentorFields.primaryFlag.code ?? ""}
                    size={12}
                />
            ) : null}
            {user?.mentorFields?.secondaryFlag && !hideSecondary ? (
                <Flag
                    label={user.mentorFields.secondaryFlag.name ?? ""}
                    code={user.mentorFields.secondaryFlag.code ?? ""}
                    size={12}
                />
            ) : null}
        </Inline>
    );
};
