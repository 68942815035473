import {useState} from "react";
import {Pressable} from "react-native";
import {Text, View, LoadingButton} from "@unibuddy/patron";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {Rocket} from "ubcommunity-shared/src/Svgs/Rocket";
import {CardContainer} from "ubcommunity-shared/src/People/PeopleCard";

interface ViewMoreCardProps {
    onClick: () => Promise<void>;
    count: number;
}

export const ViewMoreCard = ({onClick, count}: ViewMoreCardProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
    };

    return (
        <CardContainer>
            <View flex={1} padding="small-medium" paddingTop="large" gap="small">
                <Pressable
                    onPress={handleClick}
                    accessibilityRole="button"
                    accessibilityLabel="view more card, press to view more matches"
                    style={{
                        flex: 1,
                    }}
                >
                    <View flex={1} gap="small" alignItems="center">
                        <Rocket />
                        <View flexGrow={1} justifyContent="center">
                            <Text size="medium">
                                <Text align="center" style={{...boldFontStyles}}>
                                    {count}
                                </Text>
                                <Text>{` more profile${count > 1 ? "s" : ""}`}</Text>
                            </Text>
                        </View>
                    </View>
                </Pressable>
                <LoadingButton
                    color="primary"
                    block
                    onClick={handleClick}
                    disabled={loading}
                    loading={loading}
                    style={{
                        height: 33,
                    }}
                    accessibilityRole="button"
                >
                    <Text style={{...boldFontStyles}} size="small" color="white">
                        See all
                    </Text>
                </LoadingButton>
            </View>
        </CardContainer>
    );
};
