import React, {FC} from "react";
import {string} from "yup";
import {gql, useApolloClient} from "@apollo/client";
import {
    Stack,
    Button,
    Text,
    TextArea,
    Form,
    FormGroup,
    Box,
    View,
    Columns,
    Column,
    usePatronTheme,
    Heading,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useCreateUserReportMutation, Product} from "ubcommunity-shared/src/types";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton";
import {RadioButtonFormGroup} from "ubcommunity-shared/src/Patron/RadioButtonFormGroup";
import {RadioButtonGroupItem} from "ubcommunity-shared/src/Patron/RadioButtonGroupItem";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import InfoIcon from "ubcommunity-shared/src/Icons/InfoIcon";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";

interface Props {
    reporteeId: string;
    onDismiss: () => void;
    onSuccess: () => void;
}

export const validationSchema = {
    reporterReason: string().required("Why are you reporting this user is a required field"),
};

export const ReportUserForm: FC<Props> = ({reporteeId, onDismiss, onSuccess, ...props}) => {
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();
    const client = useApolloClient();

    const theme = usePatronTheme();
    const {university, selectedCommunity} = useCommunity();

    const [reportUser, {error}] = useCreateUserReportMutation();
    const handleSubmit = async ({reporterComment, reporterReason}, {setSubmitting}) => {
        try {
            await reportUser({
                variables: {
                    userReportUserInputDTO: {
                        reporteeId,
                        universityId: university.id,
                        reporterComment,
                        reporterReason,
                        product: Product.Community,
                        communityId: selectedCommunity?.id,
                    },
                },
            });

            trackEvent("Moderation: User reported", {
                userId: reporteeId,
            });

            onSuccess();
        } catch (error) {
            console.log(error);
            reportError(error);
        } finally {
            setSubmitting(false);
        }
    };

    const reporteeUserData = client.readFragment({
        id: `UserField:${reporteeId}`,
        fragment: gql`
            fragment ReportUserFormUserFieldFragment on UserField {
                firstName
            }
        `,
    });

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{reporterComment: "", reporterReason: ""}}
            validationSchema={validationSchema}
            {...props}
        >
            <View paddingX="medium" paddingTop="large" marginBottom="small">
                <Heading level="1" size="xsmall">
                    Report User
                </Heading>
            </View>
            <View padding="medium">
                <Stack space="large">
                    <Text size="medium">
                        We're sorry to hear you've had a bad experience in Community. We will
                        contact the institution's admin so that they can take further action if
                        required.
                    </Text>
                    <Stack space="small">
                        <Text size="medium">You are reporting the following user:</Text>
                        <View width="100%" padding="small" borderWidth={1} borderColor="grey">
                            <Text size="medium" fontStyle="italic">
                                {reporteeUserData?.firstName} {reporteeUserData?.lastName}
                            </Text>
                        </View>
                    </Stack>

                    <RadioButtonFormGroup
                        label="Why are you reporting this user?"
                        name="reporterReason"
                    >
                        <RadioButtonGroupItem value="Inappropriate profile picture">
                            Inappropriate profile picture
                        </RadioButtonGroupItem>
                        <RadioButtonGroupItem value="Inappropriate bio">
                            Inappropriate bio
                        </RadioButtonGroupItem>
                        <RadioButtonGroupItem value="Other">Other</RadioButtonGroupItem>
                    </RadioButtonFormGroup>

                    <View width="100%" bgColor="#ECE9FF" padding="medium" borderRadius="xxsmall">
                        <Columns space="small">
                            <Column width="content">
                                <InfoIcon
                                    color={theme?.colors.primaryColor}
                                    height={20}
                                    width={20}
                                />
                            </Column>
                            <Column>
                                <Text size="medium" color="primaryColor">
                                    Your report will not be visible to this user. Only your
                                    Community Admin will have visibility of the report.
                                </Text>
                            </Column>
                        </Columns>
                    </View>

                    <FormGroup label="Additional Comments">
                        <TextArea name="reporterComment" />
                    </FormGroup>
                </Stack>
                <Box paddingTop="large">
                    <Stack space="medium">
                        {error ? (
                            <MutationErrorHandler
                                error={error}
                                meta={{
                                    component: "ReportUserForm",
                                    mutation: "useCreateUserReportMutation",
                                }}
                            />
                        ) : null}
                        <SubmitButton color="primary" block>
                            <Text size="medium" color="white">
                                Report User
                            </Text>
                        </SubmitButton>
                        <Button color="light" block onClick={onDismiss}>
                            <Text color="darkGrey" size="medium">
                                Cancel
                            </Text>
                        </Button>
                    </Stack>
                </Box>
            </View>
        </Form>
    );
};
