import {PropsWithChildren} from "react";
import {useIsStudentAppExperience} from "ubcommunity-shared/src/Hooks/useIsStudentAppExperience";

export const SettingsStudentAppWrapper = ({children}: PropsWithChildren) => {
    const {loading} = useIsStudentAppExperience();

    if (loading) {
        return null;
    }

    return null;
};
