import {useAnalytics} from "@unibuddy/tracking";
import {IconMagnifyingGlass, IconXMark} from "@unibuddy/icons";
import {Box, Button, TextInput, usePatronTheme, View} from "@unibuddy/patron";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";
import {TrackEvents} from "../constants";

interface DiscoverSearchInputProps {
    searchText: string;
    setSearchText: (text: string) => void;
    placeholder?: string;
}

const DiscoverSearchInput = ({
    searchText,
    setSearchText,
    placeholder,
}: DiscoverSearchInputProps) => {
    const {trackEvent} = useAnalytics();
    const theme = usePatronTheme();
    const {isDesktop} = useIsDesktop();

    const onFocus = () => {
        trackEvent(TrackEvents.DISCOVER_SEARCH_FOCUSED);
    };

    return (
        <View
            position="relative"
            marginTop="medium"
            width={isDesktop ? 600 : "100%"}
            paddingX="small-medium"
        >
            <TextInput
                style={{
                    borderRadius: 9999,
                    borderColor: theme.colors.grey150,
                    height: 45,
                    backgroundColor: theme.colors.white,
                    color: theme.colors.darkGrey,
                    fontFamily: theme.TextBlock.fontFamily,
                }}
                name="searchTerm"
                placeholder={placeholder}
                value={searchText}
                onChangeText={setSearchText}
                // @ts-ignore
                placeholderTextColor={theme.colors.darkGrey}
                onFocus={onFocus}
            />
            <View
                position="absolute"
                right={10}
                top={0}
                bottom={0}
                justifyContent="center"
                paddingX="small"
            >
                {searchText ? (
                    <Button
                        name="Clear search"
                        onClick={() => setSearchText("")}
                        clear
                        iconOnly
                        round
                    >
                        <IconXMark color={theme.colors.grey900} />
                    </Button>
                ) : (
                    <Box paddingRight="xsmall">
                        <IconMagnifyingGlass color={theme.colors.grey900} />
                    </Box>
                )}
            </View>
        </View>
    );
};

export default DiscoverSearchInput;
