import React, {PropsWithChildren} from "react";
import {Dialog, View} from "@unibuddy/patron";
import {ContextMenu} from "@unibuddy/chat-ui";

import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";

type DefaultPopupWrapperProps = PropsWithChildren & {
    isContextMenuOpen: boolean;
    setIsContextMenuOpen: (p: boolean) => void;
};

/**
 * @author
 * @function @DefaultPopupWrapper
 **/
export const DefaultPopupWrapper = ({
    children,
    isContextMenuOpen,
    setIsContextMenuOpen,
}: DefaultPopupWrapperProps) => {
    const {isDesktop} = useIsDesktop();

    return (
        <>
            {isDesktop ? (
                <Dialog
                    size="medium"
                    onDismiss={() => setIsContextMenuOpen(false)}
                    isOpen={isContextMenuOpen}
                >
                    <View paddingY="medium">{children}</View>
                </Dialog>
            ) : (
                <ContextMenu
                    onDismiss={() => setIsContextMenuOpen(false)}
                    isOpen={isContextMenuOpen}
                >
                    {children}
                </ContextMenu>
            )}
        </>
    );
};
