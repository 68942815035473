import React from "react";

import {View} from "@unibuddy/patron";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import {GetChatConversation} from "ubcommunity-shared/@types/chat";

import {MixpanelMetadataProps} from "./UserSummary/UserSummary";
import {FullMembersList} from "./FullMembersList";

export const MembersListComponent = ({
    conversation,
    onShowUserSummary,
    drawerRef,
}: {
    conversation: GetChatConversation;
    onShowUserSummary: (userId: string, mixpanelMetadata: MixpanelMetadataProps) => void;
    drawerRef?: React.RefObject<DrawerLayout>;
}) => {
    if (!conversation) {
        return null;
    }

    return (
        <View flex="1">
            <FullMembersList
                conversationId={conversation.id}
                onShowUserSummary={onShowUserSummary}
                drawerRef={drawerRef}
            />
        </View>
    );
};

export const MembersList = React.memo(MembersListComponent);
