import React from "react";

export default function AddToGroupIcon(props) {
    return (
        <svg
            width={props.size || 20}
            height={props.size || 20}
            viewBox="-2 -2 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.84129 16.1793C6.77773 16.1792 6.71442 16.1711 6.65285 16.1553C6.18871 16.0353 5.73598 15.8748 5.29985 15.6758L2.63972 16.1155C2.38936 16.1574 2.13254 16.1379 1.89139 16.0586C1.65023 15.9793 1.43196 15.8426 1.25537 15.6602C1.07878 15.4779 0.949168 15.2553 0.877686 15.0117C0.806204 14.7681 0.794998 14.5108 0.845032 14.2619L1.33259 11.8301C0.859327 10.8681 0.580186 9.82225 0.511106 8.75233C0.442025 7.68241 0.584357 6.60936 0.929976 5.59445C1.62798 3.54474 3.11165 1.85626 5.05458 0.900466C6.99751 -0.0553313 9.24055 -0.200156 11.2903 0.497853C13.34 1.19586 15.0284 2.67953 15.9842 4.62246C16.0724 4.80016 16.0864 5.00561 16.0231 5.19362C15.9598 5.38163 15.8244 5.5368 15.6467 5.62499C15.469 5.71318 15.2636 5.72716 15.0756 5.66387C14.8876 5.60058 14.7324 5.46519 14.6442 5.28749C14.2503 4.49853 13.7038 3.79557 13.0363 3.21932C12.3688 2.64306 11.5936 2.20497 10.7556 1.93041C9.91759 1.65585 9.03344 1.55028 8.15436 1.61981C7.27528 1.68935 6.41873 1.9326 5.63432 2.33549C4.84991 2.73838 4.15323 3.2929 3.58463 3.96692C3.01603 4.64095 2.5868 5.42109 2.32182 6.26216C2.05684 7.10323 1.96136 7.98852 2.04091 8.86675C2.12047 9.74499 2.37348 10.5987 2.78529 11.3785C2.83312 11.4678 2.86221 11.566 2.87078 11.667C2.87935 11.7679 2.86723 11.8696 2.83514 11.9657L2.31568 14.5581L2.51509 15.3796L2.39345 14.6418L5.11839 14.1911C5.2129 14.1537 5.31424 14.1367 5.41579 14.1412C5.51733 14.1456 5.61679 14.1715 5.70765 14.2171C6.12816 14.4263 6.56921 14.5913 7.02375 14.7096C7.19969 14.7552 7.353 14.8633 7.45501 15.0138C7.55702 15.1642 7.60075 15.3466 7.57803 15.527C7.5553 15.7073 7.46768 15.8732 7.33154 15.9936C7.1954 16.114 7.02006 16.1807 6.8383 16.1813L6.84129 16.1793Z"
                fill="currentColor"
            />
            <path
                d="M14.0271 8.73728C14.8027 8.73728 15.5609 8.96729 16.2058 9.39822C16.8507 9.82915 17.3533 10.4416 17.6501 11.1583C17.9469 11.8749 18.0245 12.6634 17.8731 13.4241C17.7217 14.1848 17.3481 14.8835 16.7996 15.4319C16.2511 15.9803 15.5523 16.3537 14.7915 16.5048C14.0308 16.656 13.2423 16.5782 12.5258 16.2813C11.8092 15.9843 11.1969 15.4816 10.7661 14.8366C10.3353 14.1916 10.1055 13.4333 10.1057 12.6577C10.107 11.6182 10.5206 10.6216 11.2558 9.88664C11.9909 9.15168 12.9876 8.73833 14.0271 8.73728ZM14.0271 7.2417C12.9556 7.2415 11.9082 7.55906 11.0172 8.15421C10.1262 8.74936 9.43179 9.59536 9.02167 10.5852C8.61155 11.5751 8.50419 12.6643 8.71317 13.7152C8.92215 14.7661 9.43809 15.7314 10.1957 16.4891C10.9534 17.2467 11.9187 17.7626 12.9696 17.9716C14.0204 18.1806 15.1097 18.0732 16.0996 17.6631C17.0894 17.253 17.9354 16.5585 18.5306 15.6676C19.1257 14.7766 19.4433 13.7291 19.4431 12.6577C19.4428 11.2214 18.8721 9.84393 17.8565 8.82829C16.8409 7.81266 15.4634 7.24196 14.0271 7.2417Z"
                fill="currentColor"
            />
            <path
                d="M15.9563 13.3774H12.0329C11.8346 13.3774 11.6444 13.2986 11.5042 13.1584C11.3639 13.0182 11.2852 12.8279 11.2852 12.6296C11.2852 12.4313 11.3639 12.2411 11.5042 12.1009C11.6444 11.9606 11.8346 11.8818 12.0329 11.8818H15.9603C16.1586 11.8818 16.3489 11.9606 16.4891 12.1009C16.6293 12.2411 16.7081 12.4313 16.7081 12.6296C16.7081 12.8279 16.6293 13.0182 16.4891 13.1584C16.3489 13.2986 16.1586 13.3774 15.9603 13.3774H15.9563Z"
                fill="currentColor"
            />
            <path
                d="M13.9931 15.3368C13.7948 15.3368 13.6046 15.258 13.4644 15.1178C13.3241 14.9776 13.2454 14.7874 13.2454 14.589V10.6716C13.2454 10.4733 13.3241 10.2831 13.4644 10.1429C13.6046 10.0026 13.7948 9.92383 13.9931 9.92383C14.1915 9.92383 14.3817 10.0026 14.5219 10.1429C14.6622 10.2831 14.7409 10.4733 14.7409 10.6716V14.589C14.7409 14.7874 14.6622 14.9776 14.5219 15.1178C14.3817 15.258 14.1915 15.3368 13.9931 15.3368Z"
                fill="currentColor"
            />
        </svg>
    );
}
