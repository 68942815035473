import React, {useState} from "react";
import {Switch} from "react-native";
import {Column, Columns, Stack, Text, usePatronTheme} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useToggleMuteConversationMutation} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {TrackEvents} from "ubcommunity-shared/src/constants";

type Props = {
    conversationId: string;
    conversationName: string;
    mutedBy: string[];
};

export const ToggleMute = ({conversationId, conversationName, mutedBy}: Props) => {
    const theme = usePatronTheme();
    const {user} = useAuth();
    const [allowNotifications, setAllowNotifications] = useState<boolean>(
        !mutedBy.includes(user.id),
    );
    const [toggleMute, {loading, error}] = useToggleMuteConversationMutation();
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    const handleChange = async () => {
        const newAllowNotifications = !allowNotifications;
        setAllowNotifications(newAllowNotifications);
        try {
            const response = await toggleMute({
                variables: {
                    conversationId,
                    status: !newAllowNotifications,
                },
            });
            const mutedByList = response.data?.toggleCommunityChatGroupMute.mutedBy;
            setAllowNotifications(!mutedByList.includes(user.id));
            trackEvent(TrackEvents.GROUP_MUTE_TOGGLED, {
                conversationId,
                conversationName,
                allowNotifications: newAllowNotifications,
            });
        } catch (error) {
            reportError(error);
            setAllowNotifications(!newAllowNotifications);
        }
    };

    return (
        <Stack space="xxsmall">
            <Columns verticalAlign="center">
                <Column>
                    <Text
                        style={{
                            ...boldFontStyles,
                        }}
                    >
                        Allow notifications
                    </Text>
                </Column>
                <Column width="content">
                    <Switch
                        disabled={loading}
                        trackColor={{
                            false: theme?.colors?.grey700,
                            true: theme?.colors?.primaryColor,
                        }}
                        ios_backgroundColor={theme?.colors?.grey700}
                        onValueChange={handleChange}
                        value={allowNotifications}
                    />
                </Column>
            </Columns>
            {error ? (
                <Text color="error" size="small">
                    An error occurred. Please try again!
                </Text>
            ) : null}
        </Stack>
    );
};
