import React, {ReactNode, useEffect} from "react";
import {Platform, TouchableHighlight, StyleSheet, Pressable} from "react-native";
import {View, Text, Box, VisuallyHidden, usePatronTheme} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import ChevronRightIcon from "ubcommunity-shared/src/Icons/ChevronRightIcon";
import {GetCommunityConversationsGroupsQuery} from "ubcommunity-shared/src/types";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

import {GroupsListProfilePhotosPreviews} from "./GroupsListProfilePhotoPreviews";
import {GROUPS_LIST_STRINGS} from "./strings/strings";

export enum TrackingKey {
    POPULAR = "Popular",
    ANY = "Any",
    SEARCH_RESULT = "Search result",
    RECOMMENDED = "Recommended",
}

export const NUMBER_OF_GROUP_USERS_PREVIEW = 3;

interface Props {
    groups: GetCommunityConversationsGroupsQuery["getCommunityConversations"];
    onJoin: (id: string) => void;
    emptyState?: ReactNode;
    trackingKey: TrackingKey;
}

export const filterGroupByName = (searchValue: string, chatGroups: Props["groups"]) => {
    const value = searchValue.trim().toLowerCase();

    return chatGroups.filter((cg) => {
        const title = cg.communityChatTitle.toLowerCase();
        const description = cg.communityChatDescription?.toLowerCase() || "";
        return title.includes(value) || description.includes(value);
    });
};

const GroupList = ({groups, onJoin, emptyState, trackingKey}: Props) => {
    const theme = usePatronTheme();
    const {trackEvent} = useAnalytics();
    const {darkModeEnabled} = useCommunityTheme();

    useEffect(() => {
        if (trackingKey === TrackingKey.SEARCH_RESULT) {
            trackEvent(TrackEvents.GROUP_SEARCH_TRIGGERED, {
                numberOfGroups: groups.length,
            });
        }
    }, [groups?.length, trackEvent, trackingKey]);

    const onSelect = async (id: string) => {
        try {
            trackEvent(`${trackingKey} group selected.`);
            onJoin(id);
        } catch (err) {
            reportError(err);
        }
    };

    if (emptyState && !groups?.length) {
        return emptyState;
    }

    if (!groups?.length) {
        return (
            <View paddingY="medium">
                <Text size="medium" align="center">
                    {GROUPS_LIST_STRINGS.NO_GROUPS_FOUND}
                </Text>
            </View>
        );
    }
    const isWeb = Platform.OS === "web";
    const Button = isWeb ? Pressable : TouchableHighlight;
    const ButtonStyles = isWeb ? styles.TouchableHighlightCardWeb : styles.TouchableHighlightCard;

    return (
        <View
            padding="small-medium"
            paddingTop="small"
            gap="small"
            flexWrap="wrap"
            flexDirection="row"
        >
            {groups?.map((item) => {
                const profilesWithPhoto = item.communityChatMembers
                    .filter((member) => member.communityProfile.profilePhoto)
                    .slice(0, NUMBER_OF_GROUP_USERS_PREVIEW);

                return (
                    <Button
                        accessibilityRole="button"
                        onPress={() => onSelect(item.id)}
                        underlayColor={
                            theme?.chat?.contextMenu.underlayColor
                                ? theme?.chat?.contextMenu.underlayColor
                                : theme?.colors.swipeableItem
                        }
                        style={{
                            ...ButtonStyles,
                            backgroundColor: darkModeEnabled ? theme.colors.calypso : "#fff",
                            borderColor: darkModeEnabled
                                ? theme.colors.darkGrey
                                : theme.colors.placeholderColor,
                        }}
                        key={`explore-groups-item-${item.id}`}
                    >
                        <View justifyContent="flex-end" flex={1}>
                            <Box marginBottom="small-medium">
                                <Text
                                    truncate={2}
                                    numberOfLines={2}
                                    size="medium"
                                    weight="600"
                                    color={
                                        darkModeEnabled
                                            ? theme.colors.placeholderColor
                                            : theme.colors.darkGrey
                                    }
                                    style={{...semiBoldFontStyles}}
                                >
                                    {item.communityChatTitle}
                                </Text>
                            </Box>
                            <Box marginBottom="small-medium">
                                {typeof item.communityChatDescription === "string" ? (
                                    <Text
                                        truncate={3}
                                        numberOfLines={3}
                                        size="small"
                                        color={
                                            darkModeEnabled
                                                ? theme.colors.lightGrey
                                                : theme.colors.darkGrey
                                        }
                                    >
                                        {item.communityChatDescription}
                                    </Text>
                                ) : null}
                            </Box>

                            <View flexDirection="row" marginTop="auto">
                                {Platform.OS === "web" ? (
                                    <VisuallyHidden>
                                        <Text size="medium">
                                            {
                                                GROUPS_LIST_STRINGS.WEB_ACCESSIBILITY_MEMBERS_IN_THE_GROUP
                                            }
                                        </Text>
                                    </VisuallyHidden>
                                ) : null}

                                <View flex={3}>
                                    <GroupsListProfilePhotosPreviews
                                        profiles={profilesWithPhoto}
                                        numberOfUsers={
                                            item.totalMemberCount - profilesWithPhoto.length
                                        }
                                    />
                                </View>
                                <View width={22} alignItems="center" justifyContent="center">
                                    <ChevronRightIcon color={theme.colors.lightGrey} size={20} />
                                </View>
                            </View>
                        </View>
                    </Button>
                );
            })}
            {
                // adding a extra container to when Odd number of groups to avoid the last group to be stretched
                groups.length % 2 === 1 ? (
                    <View style={{...styles.TouchableHighlightCard, borderWidth: 0}} />
                ) : null
            }
        </View>
    );
};

const styles = StyleSheet.create({
    TouchableHighlightCard: {
        flexGrow: 1,
        borderWidth: 1,
        width: "40%",
        padding: 16,
        borderRadius: 16,
    },
    TouchableHighlightCardWeb: {
        flex: 1,
        borderWidth: 1,
        minWidth: "40%",
        padding: 16,
        borderRadius: 16,
    },
});

export const GroupListMemoized = React.memo(GroupList);
