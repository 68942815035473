import React, {PropsWithChildren, memo, useCallback, useRef, useState} from "react";
import {KeyboardAvoidingView, Platform, LayoutAnimation, ScrollView} from "react-native";
import {useApolloClient, gql} from "@apollo/client";
import {useAnalytics} from "@unibuddy/tracking";
import {Box, Text, useClipboard, View} from "@unibuddy/patron";

import {
    ChatFooter,
    getMessageSequencePosition,
    ChatContainer,
    ContextMenuButton,
    ContextMenuSeparator,
    useChatTheme,
} from "@unibuddy/chat-ui";

import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import {useIsUserBlock} from "ubcommunity-shared/src/Chat/useIsUserBlock";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {IsTyping} from "ubcommunity-shared/src/Chat/IsTyping/IsTypingDesktop";
import {withProfiler} from "ubcommunity-shared/src/Utils/Telemetry/withProfiler";
import {
    useConversation,
    ConversationType,
} from "ubcommunity-shared/src/Conversation/useConversation";

import {ContextMenuItem} from "./ContextMenuItem";
import {Message} from "./Message";
import {EmptyState} from "./EmptyState";
import {MessageList} from "../MessageList";
import {Attachment, ChatMessage} from "../../types";
import {useIsTouchDevice} from "../../General/useIsTouchDevice/useIsTouchDevice";
import ReportIcon from "../../Icons/ReportIcon";
import {useIsDesktop} from "../../General/useIsDesktop/useIsDesktop";
import TrashIcon from "../../Icons/TrashIcon";
import PinnedMessage from "../../Icons/PinnedMessage";
import PinnedMessageFilled from "../../Icons/PinnedMessageFilled";
import {ChatInputWithTypingIndicator} from "../ChatInputWithTypingIndicator/ChatInputWithTypingIndicator";
import {triggerHaptic} from "../../General/Haptic/triggerHaptic";
import ReplyIcon from "../../Icons/ReplyIcon";
import BlockIcon from "../../Icons/BlockIcon";
import {Attachments} from "../Attachments/Attachments";
import {ImagesPreview} from "../Attachments/Images/ImagesPreview";
import {FilesPreview} from "../Attachments/Files/FilesPreview";
import {IFile, IImage, useAttachments} from "../Attachments/useAttachments";
import {MixpanelMetadataProps} from "../ChatDrawer/UserSummary/UserSummary";
import {GroupTypes} from "../useChat";
import {TrackEvents} from "../../constants";
import {Reactions} from "../Reactions";
import {ReactionsList} from "../Reactions/useReaction";
import CopyIcon from "../../Icons/CopyIcon";
import {SenderBlockedState} from "../ChatFooter/SenderBlockedState";
import {JoinGroup} from "../ChatFooter/JoinGroup";
import {ContextMenu} from "../TEMP/ChatContextMenu";
import {SimpleMessage} from "./SimpleMessage";
import {ChatFooterWrapper} from "../ChatFooter/ChatFooterWrapper";

export interface ChatListProps {
    conversationId: string;
    userId: string;
    user?: {firstName: string};
    hasAdminPrivileges: boolean;
    canPin: boolean;
    onSend(args: {
        text: string;
        reply?: ChatMessage;
        giphy?: {id: string; type: "gif" | "sticker"};
        images?: IImage[];
        files?: IFile[];
    }): void;
    messages: any[];
    loading: boolean;
    onBlockOrReport(message: ChatMessage, type: "block" | "report"): void;
    onDelete(message: ChatMessage): void;
    onLike(message: ChatMessage): void;
    onUnlike(message: ChatMessage): void;
    onPin(message: ChatMessage): void;
    hasMore?: boolean;
    fetchMore?(): Promise<any>;
    pinnedMessageIds: string[];
    onShowUserSummary(senderId: string, mixpanelMetadata?: MixpanelMetadataProps): void;
    groupType: GroupTypes;
    onSpotlight(images: IImagePreview[], selectedImage: IImagePreview): void;
    onPreview(file: Attachment): void;
    onUserBlock: (params: {senderId: string; type: "block" | "unblock"}) => void;
    onReacted: (
        message: ChatMessage,
        reaction: (typeof ReactionsList)[0],
        action: "reacted" | "unreacted",
    ) => void;
    isMember: boolean;
}

const membersFragment = gql`
    fragment Members on ChatConversation {
        members(offsetPagination: {limit: 15}) {
            id
        }
    }
`;

const ChatFooterCommunity = ({
    children,
    groupType,
    hasAdminPrivileges,
}: PropsWithChildren<{groupType: GroupTypes; hasAdminPrivileges: boolean}>) =>
    groupType === GroupTypes.NEWS_FEED && !hasAdminPrivileges ? null : (
        <ChatFooter>{children}</ChatFooter>
    );

const MemoizedChatList = memo<ChatListProps>(
    ({
        userId,
        user,
        hasAdminPrivileges,
        loading,
        onBlockOrReport,
        messages,
        onSend,
        onDelete,
        onLike,
        onUnlike,
        onPin,
        canPin,
        onReacted,
        pinnedMessageIds,
        hasMore,
        fetchMore,
        conversationId,
        onShowUserSummary,
        groupType,
        onSpotlight,
        onPreview,
        onUserBlock,
        isMember,
    }) => {
        const client = useApolloClient();
        const {trackEvent} = useAnalytics();
        const inputRef = useRef<HTMLTextAreaElement>(null);
        const [selectedMessage, setSelectedMessage] = useState<null | ChatMessage>();
        const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
        const [reply, setReply] = useState<null | ChatMessage>();
        const {isTouchDevice} = useIsTouchDevice();
        const {isDesktop} = useIsDesktop();
        const avatarSize = isDesktop ? 40 : 35;
        const {copyToClipboard} = useClipboard();
        const {
            isAttachmentOpen,
            setIsAttachmentOpen,
            selectedImages,
            setSelectedImages,
            selectedFiles,
            setSelectedFiles,
        } = useAttachments();
        const {darkModeEnabled} = useCommunityTheme();
        const [isBlocked] = useIsUserBlock({conversationId});
        const {conversationType} = useConversation();

        const isWidgetConversation = conversationType === ConversationType.WIDGET;
        const isNewsFeed = conversationType === ConversationType.NEWS_FEED;

        const onBlockMessage = () => {
            triggerHaptic("contextClick");
            onBlockOrReport(selectedMessage, "block");
            setIsContextMenuOpen(false);
        };
        const onReportMessage = () => {
            triggerHaptic("contextClick");
            onBlockOrReport(selectedMessage, "report");
            setIsContextMenuOpen(false);
        };
        const onDeleteMessage = () => {
            triggerHaptic("contextClick");
            onDelete(selectedMessage);
            setIsContextMenuOpen(false);
        };
        const onCopy = () => {
            if (!selectedMessage) return;
            triggerHaptic("contextClick");
            copyToClipboard(selectedMessage.text);
            trackEvent(TrackEvents.MESSAGE_COPIED, {
                groupType,
            });
            setIsContextMenuOpen(false);
        };
        const onReply = () => {
            if (!selectedMessage) return;
            triggerHaptic("contextClick");
            inputRef?.current?.focus();
            setReply(selectedMessage);
            setIsContextMenuOpen(false);
        };
        const handleDismissReply = useCallback(() => {
            LayoutAnimation.easeInEaseOut();
            setReply(null);
        }, []);
        const handleSend = useCallback(
            ({text, gif}) => {
                LayoutAnimation.easeInEaseOut();

                if (selectedImages.length) {
                    onSend({text, reply, images: selectedImages});
                } else if (selectedFiles.length) {
                    onSend({text, reply, files: selectedFiles});
                } else {
                    onSend({text, reply, giphy: gif});
                }

                setReply(null);
                setSelectedImages([]);
                setSelectedFiles([]);
            },
            [onSend, reply, setSelectedImages, setSelectedFiles, selectedImages, selectedFiles],
        );
        const onSwipe = useCallback((item) => {
            triggerHaptic("impactMedium");
            inputRef?.current?.focus();
            LayoutAnimation.easeInEaseOut();
            setReply(item);
        }, []);
        const onReplyDesktop = useCallback((item) => {
            inputRef?.current?.focus();
            setReply(item);
        }, []);
        const onLongPress = useCallback((item) => {
            triggerHaptic("impactLight");
            setIsContextMenuOpen(true);
            setSelectedMessage(item);
        }, []);

        const handleUserUnblock = useCallback(() => {
            const members = client.readFragment({
                id: `ChatConversation:${conversationId}`,
                fragment: membersFragment,
            });

            if (members) {
                onUserBlock({
                    senderId: members.members.filter((member) => member.id !== userId)[0].id,
                    type: "unblock",
                });
            }
        }, [conversationId, userId, client, onUserBlock]);

        const handleOnReacted = useCallback(
            (message, reaction, action) => {
                setIsContextMenuOpen(false);
                onReacted(message, reaction, action);
                triggerHaptic("contextClick");
            },
            [onReacted],
        );

        const theme = useChatTheme();

        const allowSend = useCallback(() => {
            const imagesExist = selectedImages.length;
            if (imagesExist) return true;

            const filesExist = selectedFiles.length;
            if (filesExist) {
                // check if files are uploading
                const uploading = selectedFiles.some((file) => !file.s3Url);
                if (uploading) return false;
                return true;
            }
        }, [selectedImages, selectedFiles]);

        const handleAttachmentOpen = useCallback(() => {
            setIsAttachmentOpen(true);
        }, [setIsAttachmentOpen]);

        const attachmentsExist = !!(selectedImages.length || selectedFiles.length);

        const Wrapper = Platform.OS === "ios" ? KeyboardAvoidingView : View;
        const renderMessage = useCallback(
            ({item, index}) => {
                const position = Platform.select({
                    web: getMessageSequencePosition({
                        currentMessage: item,
                        previousMessage: messages[index - 1],
                        nextMessage: messages[index + 1],
                    }),
                    // RN MessageList inverses the display of messages
                    native: getMessageSequencePosition({
                        currentMessage: item,
                        previousMessage: messages[index + 1],
                        nextMessage: messages[index - 1],
                    }),
                });

                if (isWidgetConversation) {
                    return (
                        <SimpleMessage
                            key={item.id}
                            item={item}
                            avatarSize={avatarSize}
                            position={position}
                            userId={userId}
                            isTouchDevice={isTouchDevice}
                        />
                    );
                }

                return (
                    <Message
                        key={item.id}
                        onSwipe={onSwipe}
                        isDesktop={isDesktop}
                        item={item}
                        avatarSize={avatarSize}
                        position={position}
                        onUnlike={onUnlike}
                        onLike={onLike}
                        onLongPress={onLongPress}
                        isTouchDevice={isTouchDevice}
                        hasAdminPrivileges={hasAdminPrivileges}
                        onBlockOrReport={onBlockOrReport}
                        onDelete={onDelete}
                        userId={userId}
                        onReply={onReplyDesktop}
                        onPin={onPin}
                        canPin={canPin}
                        pinnedMessageIds={pinnedMessageIds}
                        onShowUserSummary={onShowUserSummary}
                        onSpotlight={onSpotlight}
                        groupType={groupType}
                        conversationId={conversationId}
                        onReacted={handleOnReacted}
                        onPreview={onPreview}
                        isMember={isMember}
                    />
                );
            },
            [
                avatarSize,
                hasAdminPrivileges,
                isDesktop,
                isTouchDevice,
                messages,
                onBlockOrReport,
                onLike,
                onLongPress,
                onReplyDesktop,
                onSwipe,
                onUnlike,
                userId,
                onPin,
                canPin,
                pinnedMessageIds,
                onDelete,
                onShowUserSummary,
                onSpotlight,
                onPreview,
                groupType,
                conversationId,
                handleOnReacted,
                isMember,
                isWidgetConversation,
            ],
        );

        return (
            <Wrapper
                keyboardVerticalOffset={112}
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={{
                    flex: 1,
                    position: "relative",
                }}
            >
                <Attachments
                    groupType={groupType}
                    isAttachmentOpen={isAttachmentOpen}
                    setIsAttachmentOpen={setIsAttachmentOpen}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                >
                    <ChatContainer bgColor={theme?.chat?.scroll?.backgroundColor}>
                        {!messages.length && !isWidgetConversation ? (
                            <ScrollView
                                contentContainerStyle={{
                                    flexGrow: 1,
                                }}
                            >
                                <EmptyState groupType={groupType} handleSend={handleSend} />
                            </ScrollView>
                        ) : (
                            <MessageList
                                hasMore={hasMore}
                                fetchMore={fetchMore}
                                messages={messages}
                                renderMessage={renderMessage}
                                loading={loading}
                                // @ts-ignore maxToRenderPerBatch only available for RN
                                maxToRenderPerBatch={5}
                            />
                        )}
                        <ChatFooterWrapper handleUserUnblock={handleUserUnblock}>
                            {isMember ? (
                                <View
                                    borderTopWidth={isNewsFeed ? 0 : 1}
                                    borderColor={darkModeEnabled ? "navbarBorderColor" : "grey100"}
                                    position="relative"
                                >
                                    {isDesktop ? (
                                        <IsTyping conversationId={conversationId} />
                                    ) : null}

                                    <ChatFooterCommunity
                                        groupType={groupType}
                                        hasAdminPrivileges={hasAdminPrivileges}
                                    >
                                        {!isBlocked ? (
                                            <React.Fragment>
                                                {!isNewsFeed || hasAdminPrivileges ? (
                                                    <ChatInputWithTypingIndicator
                                                        ref={inputRef}
                                                        reply={reply}
                                                        onDismissReply={handleDismissReply}
                                                        conversationId={conversationId}
                                                        onSend={handleSend}
                                                        userId={userId}
                                                        user={user}
                                                        allowSend={allowSend}
                                                        onAttachmentOpen={handleAttachmentOpen}
                                                        addonBottom={
                                                            attachmentsExist ? (
                                                                <View
                                                                    paddingBottom="small"
                                                                    paddingX="medium"
                                                                >
                                                                    <FilesPreview
                                                                        selectedFiles={
                                                                            selectedFiles
                                                                        }
                                                                        setSelectedFiles={
                                                                            setSelectedFiles
                                                                        }
                                                                    />
                                                                    <ImagesPreview
                                                                        selectedImages={
                                                                            selectedImages
                                                                        }
                                                                        setSelectedImages={
                                                                            setSelectedImages
                                                                        }
                                                                    />
                                                                </View>
                                                            ) : null
                                                        }
                                                        attachmentsExist={attachmentsExist}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        ) : (
                                            <SenderBlockedState
                                                handleUserUnblock={handleUserUnblock}
                                            />
                                        )}
                                    </ChatFooterCommunity>
                                </View>
                            ) : (
                                <JoinGroup conversationId={conversationId} />
                            )}
                        </ChatFooterWrapper>
                    </ChatContainer>
                </Attachments>

                {isTouchDevice ? (
                    // FIXME: Refactor this to use ModalProvider instead of using ContextMenu directly
                    <ContextMenu
                        hideCloseButton
                        onDismiss={() => setIsContextMenuOpen(false)}
                        isOpen={isContextMenuOpen}
                        sheetId="messageReactionSheet"
                    >
                        {/* Wrapping inside a view so that ActionSheet can calculate the height */}
                        <View marginBottom="small">
                            <Box padding="medium" justifyContent="center" display="flex">
                                <Text size="small">
                                    {new Date(
                                        selectedMessage?.created ?? new Date(),
                                    ).toLocaleDateString()}{" "}
                                    {new Date(
                                        selectedMessage?.created ?? new Date(),
                                    ).toLocaleTimeString()}
                                </Text>
                            </Box>

                            <Reactions
                                userId={userId}
                                selectedMessage={selectedMessage}
                                onReacted={handleOnReacted}
                            />

                            <View paddingY="small">
                                <ContextMenuSeparator />
                            </View>

                            <ContextMenuButton
                                onClick={onCopy}
                                accessibilityLabel="Copy to clipboard"
                            >
                                <ContextMenuItem icon={<CopyIcon />} text="Copy to clipboard" />
                            </ContextMenuButton>
                            {!isNewsFeed && !selectedMessage?.richContent?.poll ? (
                                <ContextMenuButton onClick={onReply} accessibilityLabel="Reply">
                                    <ContextMenuItem icon={<ReplyIcon />} text="Reply" />
                                </ContextMenuButton>
                            ) : null}

                            {canPin ? (
                                <ContextMenuButton
                                    onClick={() => {
                                        triggerHaptic("contextClick");
                                        setIsContextMenuOpen(false);
                                        onPin(selectedMessage);
                                    }}
                                    accessibilityLabel={
                                        pinnedMessageIds?.includes(selectedMessage?.id)
                                            ? "Un-pin message"
                                            : "Pin message"
                                    }
                                >
                                    {pinnedMessageIds?.includes(selectedMessage?.id) ? (
                                        <ContextMenuItem
                                            icon={
                                                <PinnedMessageFilled width="22px" height="22px" />
                                            }
                                            text="Un-pin"
                                        />
                                    ) : (
                                        <ContextMenuItem
                                            icon={<PinnedMessage width="22px" height="22px" />}
                                            text="Pin"
                                        />
                                    )}
                                </ContextMenuButton>
                            ) : null}

                            <View paddingY="small">
                                <ContextMenuSeparator />
                            </View>

                            {selectedMessage?.sender?.accountRole === "applicant" &&
                            selectedMessage?.sender?.id !== userId ? (
                                <ContextMenuButton
                                    onClick={onReportMessage}
                                    accessibilityLabel="Report message"
                                >
                                    {/* the report icon is larger as compared to other icons hence needs to be downsized and nudged a little */}
                                    <ContextMenuItem
                                        icon={
                                            <ReportIcon
                                                style={{marginLeft: 2}}
                                                width="18px"
                                                height="18px"
                                            />
                                        }
                                        text="Report"
                                    />
                                </ContextMenuButton>
                            ) : null}
                            {hasAdminPrivileges &&
                            selectedMessage?.sender?.accountRole === "applicant" ? (
                                <ContextMenuButton onClick={onBlockMessage}>
                                    <ContextMenuItem
                                        icon={<BlockIcon width="22px" height="22px" />}
                                        text="Block"
                                    />
                                </ContextMenuButton>
                            ) : null}
                            {!selectedMessage?.deleted &&
                            !selectedMessage?.id.startsWith("optimistic") &&
                            (hasAdminPrivileges || selectedMessage?.sender?.id === userId) ? (
                                <ContextMenuButton onClick={onDeleteMessage}>
                                    <ContextMenuItem
                                        icon={<TrashIcon width="22px" height="22px" />}
                                        text="Delete"
                                    />
                                </ContextMenuButton>
                            ) : null}
                        </View>
                    </ContextMenu>
                ) : null}
            </Wrapper>
        );
    },
);

export const ChatList = withProfiler(MemoizedChatList, {name: "ChatList"});
