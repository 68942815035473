import React from "react";

import {View, Text} from "@unibuddy/patron";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import ThumbsUp from "ubcommunity-shared/src/Community/Images/ThumbsUp";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {CTAWrapper} from "../General/CTAWrapper";

export const SuccessModal = ({handleDismiss}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const textColor = darkModeEnabled ? "white" : "black";

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            handleDismiss();
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [handleDismiss]);

    return (
        <CTAWrapper tagName="Survey" handleDismiss={handleDismiss} footer={null}>
            <View w="100%" alignItems="center" justifyContent={"center"}>
                <View paddingBottom={"medium"}>
                    <ThumbsUp />
                </View>
                <Text style={{...boldFontStyles}} color={textColor}>
                    Thanks for your feedback!
                </Text>
            </View>
        </CTAWrapper>
    );
};
