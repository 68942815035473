import React from "react";
import {useWindowDimensions, Platform} from "react-native";
import {View} from "@unibuddy/patron";

import {Circle, Rect} from "react-native-svg";

import {Shimmer} from "ubcommunity-shared/src/General/Shimmer";

export const InboxShimmer = () => {
    const numberOfRepetitions = 10;
    const {width} = useWindowDimensions();
    const isWeb = Platform.OS === "web";
    const containerWidth = isWeb ? 500 : width;

    return (
        <View paddingTop="small" flex={1} width="100%" accessible accessibilityLabel="loading">
            <Shimmer height={50} viewBox={`0 0 ${containerWidth} 50`}>
                <Rect x="24" y="8" rx="2" ry="2" width="150" height="16" />
            </Shimmer>
            {Array(numberOfRepetitions)
                .fill(0)
                .map((_, i) => (
                    <View key={i} paddingX="medium" paddingY="xsmall" flexShrink={0}>
                        <InboxShimmerItem />
                    </View>
                ))}
        </View>
    );
};

const InboxShimmerItem = () => {
    return (
        <Shimmer height={60} speed={2}>
            <Circle cx="30" cy="30" r="30" />
            <Rect x="80" y="12" rx="2" ry="2" width={180} height={16} />
            <Rect x="80" y="32" rx="2" ry="2" width={220} height={12} />
        </Shimmer>
    );
};
