import mixpanel from "mixpanel-browser";

export const clearLocalStorage = () => {
    window.localStorage.clear();
};

export const messaging = () => {
    return {
        getToken: () => null,
    };
};

export const getMixpanelId = () => mixpanel.get_distinct_id();
export const flushMixpanelQueue = () => null;
// We reverse the result here because of the double negative
// Eg hasOptedOutTracking = true means do NOT track this person.
export const getMixpanelTrackingStatus = () => !mixpanel.has_opted_out_tracking();
