import React, {useCallback} from "react";
import {FlatList} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {View} from "@unibuddy/patron";

import {IMember} from "ubcommunity-shared/src/Chat/ChatDrawer/Member";
import {ListFooter, PeopleLoading} from "ubcommunity-shared/src/People/PeopleLoading";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {DIRECT_MESSAGING_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import {AdminFeatureFlags, TrackSources} from "ubcommunity-shared/src/constants";

import {BuddyCardV2} from "../General/BuddyCardV2";
import {PeopleEmptyResults} from "./PeopleEmptyResults";
import {usePeopleFilters} from "./PeopleFiltersProvider";
import type {UserField} from "./PeopleFiltersProvider";

export const PeopleListing = () => {
    const keyExtractor = useCallback((item: IMember) => item.id ?? "", []);
    const {loading, results, totalCount, fetchNextLoading, fetchNext} = usePeopleFilters();

    const isDmsAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.DIRECT_MESSAGES_SETTINGS);
    const isDmsFFEnabled = useIsFeatureEnabledBoolean(DIRECT_MESSAGING_FEATURE_FLAG);
    const isDmsEnabled = isDmsFFEnabled && isDmsAdminEnabled;

    const {bottom} = useSafeAreaInsets();
    if (loading) return <PeopleLoading isDmsEnabled={isDmsEnabled} />;

    return (
        <FlatList
            keyExtractor={keyExtractor}
            data={results ?? []}
            contentContainerStyle={{
                paddingBottom: bottom,
                alignItems: "center",
            }}
            onEndReached={() => {
                if (results.length < totalCount) {
                    fetchNext();
                }
            }}
            onEndReachedThreshold={0.5}
            numColumns={3}
            ListEmptyComponent={<PeopleEmptyResults />}
            ListFooterComponent={<ListFooter loading={fetchNextLoading} />}
            renderItem={({item}: {item: UserField}) => (
                <View paddingY="small">
                    <BuddyCardV2
                        key={`buddy-card-${item?.id}`}
                        id={item?.id}
                        firstName={item?.firstName}
                        profilePhoto={
                            item?.accountRole === "applicant"
                                ? item?.communityProfile?.profilePhoto
                                : item?.profilePhoto
                        }
                        country={item?.country}
                        areaOfStudy={item?.communityProfile?.areaOfStudy[0]?.name}
                        bio={item?.communityProfile?.bio}
                        isDmsEnabled={isDmsEnabled}
                        source={TrackSources.PEOPLE_LIST}
                    />
                </View>
            )}
        />
    );
};
