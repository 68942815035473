import React, {useState} from "react";
import {Platform} from "react-native";

import {CountryField} from "ubcommunity-shared/src/types";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {useCreateDirectConversation} from "ubcommunity-shared/src/Hooks/graphql/useCreateDirectConversation";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {PeopleCard} from "ubcommunity-shared/src/People/PeopleCard";
import {TrackSources} from "ubcommunity-shared/src/constants";

export const CARD_MAX_WIDTH = 200;
export const CARD_MAX_HEIGHT = 370;

type BuddyCardV2Props = {
    areaOfStudy?: string;
    bio: string;
    country?: CountryField;
    firstName: string;
    id: string;
    profilePhoto: string;
    onClick?: (senderId: string) => void;
    openSource: string;
    isDmsEnabled?: boolean;
    source?: TrackSources;
};

export const BuddyCardV2 = ({
    id,
    firstName,
    profilePhoto,
    country,
    bio,
    areaOfStudy,
    onClick,
    openSource,
    isDmsEnabled = false,
    source,
}: BuddyCardV2Props) => {
    const {createDirectConversation} = useCreateDirectConversation({
        senderId: id,
        source,
    });
    const [navigate] = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const {openUserSummary} = useOpenUserSummary({
        openSource,
    });

    const handleProfileOpen = () => {
        openUserSummary(id, null, {type: "cardV2", openSource: source});
    };
    const isWeb = Platform.OS === "web";

    const handleDirectMessage = async () => {
        const response = await createDirectConversation();
        const newConversationId = response?.data?.createDirectConversation?.id;

        navigate({
            path: Routes.CHAT,
            options: {
                screen: isWeb ? newConversationId : Routes.CHAT_HOME,
                params: {
                    conversationId: newConversationId,
                },
            },
        });
    };

    const handleClick = async () => {
        setLoading(true);
        if (onClick) await onClick(id);
        else await handleDirectMessage();
        setLoading(false);
    };

    return (
        <PeopleCard
            user={{id, firstName, profilePhoto, country, bio, areaOfStudy}}
            isDmsEnabled={isDmsEnabled}
            dmLoading={loading}
            openProfile={handleProfileOpen}
            handleDirectMessage={handleClick}
        ></PeopleCard>
    );
};
