import React from "react";
import {Heading as PatronHeading, Text, View} from "@unibuddy/patron";
import {boldFontStyles} from "../Styles/fontStyles";
import {Platform} from "react-native";

export const enum UniversalInboxHeaderStrings {
    title = "Chats",
}

// `Heading` from patron overrides the `font-family` prop
// which is required on android to properly render bold text
// as opposed to the `weight` prop on iOS
const Heading = ({children}: {children: string}) => {
    if (Platform.OS === "web") {
        return <PatronHeading level="4">{children}</PatronHeading>;
    }

    return (
        <Text
            style={{
                ...boldFontStyles,
            }}
            size="xlarge"
        >
            {children}
        </Text>
    );
};

export const UniversalInboxHeader = () => {
    return (
        <View padding="medium" borderBottomWidth={1} borderColor="grey100">
            <Heading>{UniversalInboxHeaderStrings.title}</Heading>
        </View>
    );
};
