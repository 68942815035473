import {useEffect} from "react";
import {FlatList, Linking, Platform, TouchableOpacity} from "react-native";
import {format, parseISO} from "date-fns";
import {useAnalytics} from "@unibuddy/tracking";
import {Button, Column, Columns, Inline, Stack, Text, usePatronTheme, View} from "@unibuddy/patron";
import {semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {TodoAction, TodosEntity, useUpdateTodoActionMutation} from "ubcommunity-shared/src/types";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {LocalStorageNames, TrackEvents} from "ubcommunity-shared/src/constants";
import CheckboxSvg from "ubcommunity-shared/src/Svgs/CheckboxSvg";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";

import {useTodos} from "./useTodos";
import {TodoListItemWrapper} from "./TodoListItemWrapper";

type ITodo = Omit<TodosEntity, "createdBy" | "targetAudience" | "universityId">;

type TodoItemProps = {
    todo: ITodo;
    checked: boolean;
    handlePress: (todo: ITodo) => void;
};

const TodoItem = ({todo, checked, handlePress}: TodoItemProps) => {
    const {trackEvent} = useAnalytics();
    const theme = usePatronTheme();

    const openLink = async () => {
        if (!todo.externalLink) return;
        let link = todo.externalLink;
        if (!todo.externalLink.includes("http://") && !todo.externalLink.includes("https://")) {
            link = `http://${todo.externalLink}`;
        }
        trackEvent(TrackEvents.TODO_LINK_OPENED, {link});
        Linking.openURL(link);
    };

    const isWeb = Platform.OS === "web";
    const Checkbox = isWeb ? "button" : TouchableOpacity;

    return (
        <View paddingX="medium" paddingY="small-medium" opacity={checked ? 0.5 : 1}>
            <Stack space="small-medium">
                <Checkbox
                    onPress={() => handlePress(todo)}
                    accessible={true}
                    accessibilityRole="checkbox"
                    accessibilityLabel={todo.title}
                    accessibilityState={{checked: checked}}
                    aria-checked={checked}
                    role="checkbox"
                    aria-label={todo.title}
                    tabIndex={0}
                    onClick={() => handlePress(todo)}
                    style={{
                        all: "unset",
                        cursor: "pointer",
                    }}
                >
                    <Inline space="xxsmall" verticalAlign="center">
                        <CheckboxSvg
                            color={checked ? theme.colors.primaryColor : theme.colors.grey600}
                            filled={checked}
                        />
                        <Text
                            style={{
                                ...semiBoldFontStyles,
                            }}
                        >
                            {todo.title}
                        </Text>
                    </Inline>
                </Checkbox>
                {todo.description && <Text size="small">{todo.description}</Text>}
                {todo.deadline || todo.externalLink ? (
                    <Columns verticalAlign="center">
                        <Column>
                            {todo.deadline && (
                                <Text size="small">
                                    {format(parseISO(todo.deadline), "'On' EEEE, d MMM")}
                                </Text>
                            )}
                        </Column>
                        <Column width="content">
                            {todo.externalLink && (
                                <Button onClick={openLink} size="sm" ghost color="primary">
                                    <Text color="primaryColor">View</Text>
                                </Button>
                            )}
                        </Column>
                    </Columns>
                ) : null}
            </Stack>
        </View>
    );
};

export const TodosList = () => {
    const {todos} = useTodos();
    const {isDesktop} = useIsDesktop();
    const {value, setValue} = useAsyncLocalStorage(LocalStorageNames.TODO_LIST, []);
    const {trackEvent} = useAnalytics();
    const [updateTodo] = useUpdateTodoActionMutation();

    useEffect(() => {
        trackEvent(TrackEvents.TODO_LIST_SCREEN_VIEWED);
    }, [trackEvent]);

    const handleCheckboxPress = (todo: TodoItemProps["todo"]) => {
        const newValue = value.includes(todo.id)
            ? value.filter((id: string) => id !== todo.id)
            : [...value, todo.id];
        setValue(newValue);
        trackEvent(TrackEvents.TODO_LIST_ITEM_TOGGLED, {
            todoId: todo.id,
            checked: newValue.includes(todo.id),
        });
        // todo, update mutation once action to uncheck is available
        updateTodo({
            variables: {
                updateTodoActionId: todo.id,
                action: TodoAction.Completed,
            },
        });
    };

    if (!todos) return <Text>No todos present</Text>;

    if (!todos.length) return <Text>No todos present</Text>;

    const list = todos.reduce(
        (acc, todo) => {
            if (value.includes(todo.id)) {
                acc.checked.push(todo);
            } else {
                acc.unchecked.push(todo);
            }
            return acc;
        },
        {checked: [] as ITodo[], unchecked: [] as ITodo[]},
    );

    return (
        <View flex="1" alignItems="center">
            <FlatList
                data={[...list.unchecked, ...list.checked]}
                renderItem={({item}) => (
                    <TodoListItemWrapper>
                        <TodoItem
                            todo={item}
                            checked={value.includes(item.id)}
                            handlePress={handleCheckboxPress}
                        />
                    </TodoListItemWrapper>
                )}
                keyExtractor={(item) => item.id}
                numColumns={isDesktop ? 2 : 1}
                style={{width: "100%"}}
                contentContainerStyle={{
                    alignItems: isDesktop ? "center" : "flex-start",
                }}
            />
        </View>
    );
};
