import {useState} from "react";
import {string, array} from "yup";
import {
    Divider,
    Stack,
    Form,
    View,
    SubmitButton,
    ErrorMessage,
    Text,
    Button,
} from "@unibuddy/patron";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useApolloClient} from "@apollo/client";
import type {FormikHelpers} from "formik";

import {getAccountRoleName} from "ubcommunity-shared/src/General/getAccountRoleName";
import {
    useSetUserProfileMutation,
    useUpdateApplicantDetailsMutation,
    UpdateUserProfile,
    useGetAccountInformationQuery,
    useSetInterestsForUserMutation,
    HousingCategory,
} from "ubcommunity-shared/src/types";
import {PageTitles} from "ubcommunity-shared/src/constants";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {AccountRoles} from "ubcommunity-shared/src/constants/accountRoles";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {
    STUDENTS_CAN_UPDATE_INTERESTS,
    STUDENTS_CAN_UPDATE_NAME,
} from "ubcommunity-shared/src/featureFlags";
import {useGetUserProfile} from "ubcommunity-shared/src/Hooks/useGetUserProfile";

import {ProfilePicture, ProfileInfo} from "./ProfileHero";
import {UserDeletionContainer} from "./UserDeletion";
import {EditNationality} from "./EditNationality";
import {EditBio} from "./EditBio";
import {EditInterests} from "./EditInterests";
import {useAccountInformationTracking} from "./useAccountInformationTracking";
import {EditPronouns} from "./EditPronouns";
import {EditName} from "./EditName/EditName";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {useLogout} from "ubcommunity-shared/src/Auth/hooks/useLogout";

export interface ProfileFormValues {
    firstName: string;
    lastName: string;
    bio: string;
    selectCountry: Array<{name: string}>;
    interests: Array<string>;
    pronouns: string;
}

export const DEFAULT_BUTTON_TEXT = "Save";
export const SUBMITTED_BUTTON_TEXT = "Changes saved!";

export const AccountInformation = () => {
    const {setAuth, authState, user} = useAuth();
    const {reportError} = useErrorReporting();
    const accountRole = getAccountRoleName(user?.accountRole ?? "");
    const {trackEvent} = useAccountInformationTracking();
    const {setShouldUpdateCompletionProfileCounter} = useGetUserProfile();
    const {isDesktop} = useIsDesktop();
    const {logout} = useLogout();

    usePageTitle(PageTitles.ACCOUNT_INFORMATION);

    const {cache} = useApolloClient();

    const {data, error, refetch, loading} = useGetAccountInformationQuery({
        fetchPolicy: "cache-and-network",
    });
    const [setUserProfile, {error: setUserProfileMutationError}] = useSetUserProfileMutation();
    const [setUpdateApplicant, {error: updateApplicantCountryMutationError}] =
        useUpdateApplicantDetailsMutation();
    const [setInterests, {error: setInterestsError}] = useSetInterestsForUserMutation();

    const canUpdateInterests = useIsFeatureEnabledBoolean(STUDENTS_CAN_UPDATE_INTERESTS);
    const canUpdateName = useIsFeatureEnabledBoolean(STUDENTS_CAN_UPDATE_NAME);

    const mutationError =
        setUserProfileMutationError || updateApplicantCountryMutationError || setInterestsError;

    const [buttonText, setButtonText] = useState(DEFAULT_BUTTON_TEXT);

    let initialValues = {} as ProfileFormValues;

    // set initial values for country
    const country = user?.country;
    initialValues = country
        ? {
              ...initialValues,
              selectCountry: [
                  {
                      name: country.name,
                      id: country.id,
                      code: country.code,
                  },
              ],
          }
        : {
              ...initialValues,
              selectCountry: [],
          };

    // set initial values for interests
    const interests = data?.getInterestsForUser;
    if (interests?.length) {
        initialValues = {
            ...initialValues,
            interests: interests.map((interest) => interest.id),
        };
    } else {
        initialValues = {
            ...initialValues,
            interests: [],
        };
    }

    const onSubmit = async (
        {firstName, lastName, bio, selectCountry, interests, pronouns}: ProfileFormValues,
        formActions: FormikHelpers<ProfileFormValues>,
    ) => {
        try {
            const countryName = selectCountry[0]?.name;
            const useProfileData: UpdateUserProfile = {bio, pronouns};

            const promises = [];
            // network requests
            const updateProfilePromise = setUserProfile({
                variables: {
                    data: useProfileData,
                },
            });
            promises.push(updateProfilePromise);

            if (accountRole === AccountRoles.STUDENT) {
                const updateApplicantDetails = setUpdateApplicant({
                    variables: {
                        firstName,
                        lastName,
                        country: countryName,
                    },
                });
                promises.push(updateApplicantDetails);

                const updateInterestsPromise = setInterests({
                    variables: {
                        setUserInterestsInput: {
                            interestIds: interests,
                        },
                    },
                });
                promises.push(updateInterestsPromise);
            }
            await Promise.all(promises);

            // track events for changed values
            trackEvent(data, user, {
                firstName,
                lastName,
                bio,
                selectCountry,
                interests,
                pronouns,
            });

            // user feedback
            setButtonText(SUBMITTED_BUTTON_TEXT);
            setTimeout(() => setButtonText(DEFAULT_BUTTON_TEXT), 3000);

            // evict root query on recommendations for fresh results
            cache.evict({id: "ROOT_QUERY", fieldName: "getRecommendedUsers"});

            // update user profile completion
            setShouldUpdateCompletionProfileCounter(true);

            // reset form
            formActions.resetForm({
                values: {
                    firstName,
                    lastName,
                    pronouns,
                    bio,
                    selectCountry,
                    interests,
                },
            });
        } catch (error) {
            console.log(error);
            reportError(error);
            formActions.setFieldError("serverError", "An error occurred. Please try again later.");
        } finally {
            formActions.setSubmitting(false);
            setAuth({
                ...authState,
                me: {
                    ...authState.me,
                    anyUser: {
                        ...authState.me.anyUser,
                        firstName,
                        lastName,
                        country: selectCountry[0],
                    },
                },
            });
        }
    };

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{
                    component: "AccountInformation",
                    query: "useGetAccountInformationQuery",
                }}
            />
        );
    }

    const bio = string().max(200, "Your bio must be less than 200 characters");
    const validationSchema = {
        [AccountRoles.STUDENT]: {
            firstName: string().required("First name is required"),
            lastName: string().required("Last name is required"),
            selectCountry: array().required().min(1, "This field is required"),
            bio,
            housingName: string().when("housingCategory", {
                is: HousingCategory.University,
                then: string()
                    .required("This field is required")
                    .max(100, "Housing name must be less than 100 characters"),
            }),
        },
        [AccountRoles.AMBASSADOR]: {
            bio,
        },
        [AccountRoles.UNIBUDDY_ADMIN]: {
            bio,
        },
        [AccountRoles.UNIVERSITY_ADMIN]: {
            bio,
        },
    };

    if (loading)
        return (
            <View padding="large">
                <ActivityIndicator accessibilityLabel="Loading your profile" />
            </View>
        );

    return (
        <Stack space="large">
            <View w="100%">
                <ProfilePicture accountRole={accountRole} />
                <ProfileInfo user={user} />
            </View>
            <Form
                onSubmit={onSubmit}
                validationSchema={validationSchema[accountRole]}
                initialValues={{
                    ...initialValues,
                    firstName: authState.me.anyUser.firstName ?? "",
                    lastName: authState.me.anyUser.lastName ?? "",
                    bio: data?.getUserProfile?.bio || "",
                    pronouns: data?.getUserProfile?.pronouns || "",
                    serverError: "",
                }}
            >
                <Stack space="medium-large">
                    {authState.me.anyUser.accountRole === "applicant" ? (
                        <Stack space="medium-large">
                            {canUpdateName ? <EditName /> : null}
                            <EditNationality />
                        </Stack>
                    ) : null}

                    <EditBio />
                    <EditPronouns />
                    <View position="relative" w="100%">
                        {canUpdateInterests ? (
                            <EditInterests allInterests={data?.getInterests} />
                        ) : null}
                    </View>

                    <ErrorMessage
                        name="serverError"
                        component={
                            <MutationErrorHandler
                                error={mutationError}
                                meta={{
                                    component: "AccountInformation",
                                    mutation:
                                        "useSetUserProfileMutation | useUpdateApplicantCountryMutation",
                                }}
                            />
                        }
                    />

                    <SubmitButton block color="primary">
                        <Text size="medium" color="white">
                            {buttonText}
                        </Text>
                    </SubmitButton>
                </Stack>
            </Form>

            <Stack space="large">
                <Divider />
                {isDesktop ? null : (
                    <Button color="light" block onClick={logout}>
                        Logout
                    </Button>
                )}
                <UserDeletionContainer />
            </Stack>
        </Stack>
    );
};
