import React from "react";
import {Platform} from "react-native";
import {View} from "@unibuddy/patron";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";

import {useGetCommunityChatMembersQuery} from "ubcommunity-shared/src/types";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {DIRECT_MESSAGING_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";

import {MixpanelMetadataProps} from "../UserSummary/UserSummary";
import {FullMembersIterator} from "./FullMembersIteratorV2";
import {ShimmerMemberListContainer} from "./ShimmerLoader";

export const FETCH_LIMIT = 20;

export function FullMembersList({
    conversationId,
    onShowUserSummary,
    drawerRef,
}: {
    conversationId: string;
    drawerRef?: React.RefObject<DrawerLayout>;
    onShowUserSummary: (userId: string, mixpanelMetadata: MixpanelMetadataProps) => void;
}) {
    const {data, error, refetch, fetchMore, loading} = useGetCommunityChatMembersQuery({
        variables: {
            conversationId: conversationId,
            offsetPagination: {
                offset: 0,
                limit: FETCH_LIMIT,
            },
            idIn: [],
        },
        notifyOnNetworkStatusChange: true,
    });

    const updateQuery = () => {
        fetchMore({
            variables: {
                offsetPagination: {
                    offset: data?.getCommunityChatMembers.length,
                    limit: FETCH_LIMIT,
                },
            },
        });
    };
    const isDmsAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.DIRECT_MESSAGES_SETTINGS);
    const isDmsFFEnabled = useIsFeatureEnabledBoolean(DIRECT_MESSAGING_FEATURE_FLAG);
    const isDmsEnabled = isDmsFFEnabled && isDmsAdminEnabled;

    // type Users = Pick<UserField, "id" | "firstName" | "lastName" | "accountRole">;

    // const deduplicateArray = <T extends Users>(array: T[], property: keyof T) => {
    //     const uniqueValues = new Set();
    //     return array.reduce((result, obj) => {
    //         if (!uniqueValues.has(obj[property])) {
    //             uniqueValues.add(obj[property]);
    //             result.push(obj);
    //         }
    //         return result;
    //     }, []);
    // };

    // const allMembers = [...(data?.getCommunityChatMembers ?? []), ...(data?.getUserByIds ?? [])];
    // const allMembersDeduplicated = deduplicateArray(allMembers, "id");

    // const members = useMemo(() => {
    //     const membersList = allMembersDeduplicated;
    //     return sortMembersByOnline(membersList, presenceMembers);
    // }, [allMembersDeduplicated, presenceMembers]);

    if (!data && loading) {
        return <ShimmerMemberListContainer isDmsEnabled={isDmsEnabled} />;
    }

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{
                    component: "FullMembersList",
                    query: "useGetCommunityChatMembersQuery",
                }}
            />
        );
    }

    return (
        <View flex="1" height={Platform.OS === "web" ? "100%" : undefined}>
            <FullMembersIterator
                drawerRef={drawerRef}
                members={data?.getCommunityChatMembers ?? []}
                onShowUserSummary={onShowUserSummary}
                updateQuery={updateQuery}
                loading={loading}
                isDmsEnabled={isDmsEnabled}
            />
        </View>
    );
}
