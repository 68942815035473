import {useEffect, useMemo} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {useGetAmbassadorProfileQuery} from "ubcommunity-shared/src/types";
import {TrackEvents, TrackSources, UserAccountRoles} from "ubcommunity-shared/src/constants";

export type ProfileItem = {
    title: string;
    text: string;
};

export const useGetAmbassadorProfileFields = (id: string) => {
    const {trackEvent} = useAnalytics();

    const {data, loading, error, refetch} = useGetAmbassadorProfileQuery({
        variables: {
            id,
        },
    });

    const user = data?.publicMentor;
    const itemsMap = useMemo(() => new Map<string, ProfileItem>(), []);

    const languages = user?.languages?.map((language) => language?.name).join(", ");
    if (languages) {
        itemsMap.set("I Speak", {
            title: "I Speak",
            text: languages,
        });
    }

    const year = user?.year;
    if (year) {
        itemsMap.set("Year", {
            title: "Year",
            text: year,
        });
    }

    const favouriteCourses = user?.favouriteModules?.map((mod) => mod?.name).join(", ");
    if (favouriteCourses) {
        itemsMap.set("Favourite Courses", {
            title: "Favourite Courses",
            text: favouriteCourses,
        });
    }

    const pastQualification = user?.pastQualification;
    if (pastQualification) {
        itemsMap.set("Previous Qualification", {
            title: "Previous Qualification",
            text: pastQualification,
        });
    }

    const interests = user?.interests?.map((interest) => interest?.name).join(", ");
    if (interests) {
        itemsMap.set("Hobbies & Interests", {
            title: "Hobbies & Interests",
            text: interests,
        });
    }

    const societies = user?.societies?.map((society) => society?.name).join(", ");
    if (societies) {
        itemsMap.set("Clubs & Societies", {
            title: "Clubs & Societies",
            text: societies,
        });
    }

    const items = Array.from(itemsMap.values());

    useEffect(() => {
        if (!loading) {
            trackEvent(TrackEvents.USER_PROFILE_OPENED, {
                accountType: UserAccountRoles.MENTOR,
                profileItems: items.map((item) => item.title).join(", "),
                source: TrackSources.MENTOR_LIST,
            });
        }
    }, [items, trackEvent, loading]);

    return {
        loading,
        error,
        refetch,
        data,
        items,
    };
};
