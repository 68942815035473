import React, {forwardRef, memo, ReactElement, useCallback, useState} from "react";
import {Platform} from "react-native";
import throttle from "lodash/throttle";
import noop from "lodash/noop";
import {ChatInput, MessageQuote} from "@unibuddy/chat-ui";
import {useAnalytics} from "@unibuddy/tracking";
import {useSocketChannel} from "../../Sockets/useSocketChannel/useSocketChannel";
import {conversationChannel, typingEvent} from "../sharedEvents";
import {ChatMessage} from "ubcommunity-shared/src/types";
import {
    Box,
    Button,
    Column,
    Columns,
    Inline,
    Text,
    useDebounce,
    View,
    VisuallyHidden,
} from "@unibuddy/patron";
import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {IMAGES_IN_CHAT} from "ubcommunity-shared/src/featureFlags";
import {ReplyQuoteContainer} from "./ReplyQuoteContainer";
import {Gifs} from "../Gifs/Gifs";
import {Gif} from "../Gif/Gif";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {ImageThumbnailItem} from "../Attachments/Images/ImageThumbnail";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";
import {ChatOptionsMenu} from "../ChatOptionsMenu";

type InputProps = {
    conversationId: string;
    onSend(args: {text: string; gif?: any}): void;
    userId: string;
    user: any;
    reply?: ChatMessage;
    onDismissReply?(): void;
    onAttachmentOpen?(): void;
    addonBottom?: ReactElement;
    allowSend?: () => boolean;
    attachmentsExist?: boolean;
};

export const ChatInputWithTypingIndicator = memo(
    forwardRef<HTMLTextAreaElement, InputProps>(
        (
            {
                conversationId,
                onSend,
                userId,
                user,
                reply,
                onDismissReply,
                onAttachmentOpen,
                addonBottom,
                allowSend = () => true,
                attachmentsExist,
            },
            ref,
        ) => {
            const {trackEvent} = useAnalytics();

            const {selectedCommunity} = useCommunity();
            const [isOpen, setIsOpen] = useState(false);
            const [showAttachmentIcon, setShowAttachmentIcon] = useState(true);
            // We want to both conditionally render the gif drawer for performance reasons, and animate the transition.
            // The two need to be decoupled because css transitions don't work on initial render. So we use one of the
            // debounced values for animation and the other for rendering.
            const isOpenAnimated = useDebounce(isOpen, 10);
            const isOpenDelayed = useDebounce(isOpen, 300);
            const handleDismissGifs = useCallback(() => {
                setIsOpen(false);
            }, []);
            const [textValue, setTextValue] = useState("");
            const handleSend = useCallback(
                (text) => {
                    onSend({text});
                },
                [onSend],
            );
            const handleSendGif = useCallback(
                (gif) => {
                    onSend({text: textValue, gif});
                    setTextValue("");
                },
                [onSend, textValue],
            );
            const channel = useSocketChannel(conversationChannel, conversationId);
            const throttledTypingTrigger = useCallback(
                throttle(channel?.trigger ? (ch, event) => channel.trigger(ch, event) : noop, 300),
                [channel],
            );
            const onChangeText = (text) => {
                setTextValue(text);
                if (text !== "") {
                    setShowAttachmentIcon(false);
                } else {
                    setShowAttachmentIcon(true);
                }
                throttledTypingTrigger(typingEvent.name, {
                    userId,
                    userName: user?.firstName,
                    created: Date.now(),
                });
            };

            const shouldAllowSend = useCallback(() => {
                if (allowSend()) {
                    return true;
                }

                const value = textValue.trim();
                return typeof value === "string" && value.length > 0;
            }, [textValue, allowSend]);

            return (
                <>
                    <Columns verticalAlign="bottom">
                        <Column width="content">
                            <ChatOptionsMenu
                                showAttachmentIcon={showAttachmentIcon}
                                onAttachmentOpen={onAttachmentOpen}
                                conversationId={conversationId}
                            />
                        </Column>
                        <Column>
                            <View flex="1">
                                <ChatInput
                                    shouldAllowSend={shouldAllowSend}
                                    addonRight={
                                        selectedCommunity?.settings?.allowGifs &&
                                        !attachmentsExist ? (
                                            <View paddingRight="xsmall" justifyContent="center">
                                                <Button
                                                    color="gifButton"
                                                    size="xxsmall"
                                                    round
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        trackEvent("Giphy Drawer Opened");
                                                    }}
                                                    accessibilityLabel="Send GIF"
                                                >
                                                    GIF
                                                </Button>
                                            </View>
                                        ) : null
                                    }
                                    ref={ref}
                                    addonTop={
                                        reply ? (
                                            <ReplyQuote
                                                reply={reply}
                                                onDismissReply={onDismissReply}
                                            />
                                        ) : null
                                    }
                                    onChangeText={onChangeText}
                                    value={textValue}
                                    onSend={handleSend}
                                    addonBottom={addonBottom}
                                    placeholder={
                                        attachmentsExist
                                            ? "Add a caption..."
                                            : "Type your message..."
                                    }
                                />
                            </View>
                        </Column>
                    </Columns>
                    {isOpen || isOpenDelayed ? (
                        <Gifs
                            isOpen={isOpenAnimated}
                            onDismiss={handleDismissGifs}
                            onSelect={handleSendGif}
                        />
                    ) : null}
                </>
            );
        },
    ),
);

export const ReplyWithRichContent = memo(({message}: {message: ChatMessage}) => {
    const imagesInChatFeatureFlag = useIsFeatureEnabledBoolean(IMAGES_IN_CHAT);
    const imagesInChatAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.IMAGES_SETTINGS);
    const imagesEnabled = imagesInChatFeatureFlag && imagesInChatAdminEnabled;

    const trimMessage = (message) => {
        return message.text.length > 100 ? `${message.text.substring(0, 100)} ...` : message.text;
    };

    if (message.deleted) {
        return (
            <Text fontStyle="italic" size="small">
                {trimMessage(message)}
            </Text>
        );
    }

    if (message?.richContent?.giphy) {
        return (
            <Box paddingY="xxsmall" display="flex">
                <Box overflow="hidden" borderRadius="xsmall">
                    <Gif
                        id={message?.richContent?.giphy?.id}
                        aspectRatio={message?.richContent?.giphy?.aspectRatio}
                        width={100}
                        autoPlay={false}
                    />
                </Box>
            </Box>
        );
    }
    if (message?.richContent?.images) {
        const images = message?.richContent?.images;

        // android has a weird behavior where this component overlaps the author name and tends to overflow the container.
        // this may be because the MessageQuote component from chat-ui wraps everything in a Text component which
        // should not happen on native
        if (!imagesEnabled) {
            return (
                <Text size="xsmall" fontStyle="italic">
                    Images have been disabled.
                </Text>
            );
        }

        if (Platform.OS === "android") {
            return (
                <Text size="small" fontStyle="italic">
                    Sent {images.length} image(s).
                </Text>
            );
        }

        return (
            <Box paddingY="xxsmall" display="flex">
                <Inline space="xsmall">
                    {images.map((image) => (
                        <View key={image.url} borderRadius="xsmall" overflow="hidden">
                            <ImageThumbnailItem image={image} size={50} />
                        </View>
                    ))}
                </Inline>
            </Box>
        );
    }
    if (message?.richContent?.attachments) {
        const attachments = message?.richContent?.attachments;

        return (
            <Text size="small" fontStyle="italic">
                Sent {attachments.length} attachment(s).
            </Text>
        );
    }

    return <Text size="small">{trimMessage(message)}</Text>;
});

const ReplyQuote = memo<{reply: ChatMessage; onDismissReply: () => void}>(
    ({reply, onDismissReply}) => {
        const {darkModeEnabled} = useCommunityTheme();

        return (
            <ReplyQuoteContainer>
                <MessageQuote
                    align="left"
                    author={Platform.select<string | ReactElement>({
                        web: (
                            <>
                                {reply.sender.firstName}{" "}
                                <VisuallyHidden>
                                    <Text>said</Text>
                                </VisuallyHidden>
                            </>
                        ),
                        default: `${reply.sender.firstName} `,
                    })}
                >
                    <ReplyWithRichContent message={reply} />
                </MessageQuote>
                <Box position="absolute" top={12} right={12}>
                    <Button
                        iconOnly
                        type="button"
                        size="sm"
                        color="light"
                        round
                        clear
                        onClick={onDismissReply}
                        aria-label="Dismiss reply"
                        accessibilityLabel="Dismiss reply"
                    >
                        <CloseIcon size={18} color={!darkModeEnabled ? "#212121" : "#fff"} />
                    </Button>
                </Box>
            </ReplyQuoteContainer>
        );
    },
);
