import {View} from "@unibuddy/patron";
import {PageTitles} from "ubcommunity-shared/src/constants";
import {Discover} from "ubcommunity-shared/src/Discover/Discover";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";

export const DiscoverPage = () => {
    usePageTitle(PageTitles.DISCOVER);

    return (
        <View flex="1">
            <Discover />
        </View>
    );
};
