import {CheckBoxFormGroup, CheckBoxGroupItem} from "@unibuddy/patron";
import {UniversityQuestion, UniversityQuestionOption} from "ubcommunity-shared/src/types";
import {SurveyQuestionLabel} from "../StudentEnrolledQuestions/questions/Question1";

export const CheckBoxOptions = ({
    question,
    options,
}: {
    question: UniversityQuestion["text"];
    options: UniversityQuestionOption[];
}) => {
    return (
        <CheckBoxFormGroup
            label={<SurveyQuestionLabel>{question}</SurveyQuestionLabel>}
            name="webVersionAnswers"
        >
            {options.map((option) => (
                <CheckBoxGroupItem key={option.id} value={option.id}>
                    {option.text}
                </CheckBoxGroupItem>
            ))}
        </CheckBoxFormGroup>
    );
};
