import React from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Box, Button, Heading, Stack, View, Text} from "@unibuddy/patron";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes} from "ubcommunity-shared/src/constants/routes";

import {InboxIllustration} from "./InboxIllustration";

export const UniversalInboxEmptyStateStrings = {
    noConversations: "It looks like you haven't started any chats yet...",
    findPeopleToChat: "Find people to chat with now",
    findPeople: "Find people",
};

export const UniversalInboxEmptyState = () => {
    const [navigate] = useNavigate();
    const {trackEvent} = useAnalytics();

    const handlePress = () => {
        trackEvent(TrackEvents.UNIVERSALINBOX_EMPTY_STATE_CTA);
        navigate({
            path: Routes.HOME,
        });
    };

    return (
        <View
            flex="1"
            alignItems="center"
            padding="large"
            paddingTop="xlarge"
            paddingBottom="large"
        >
            <Stack space="large" align="center">
                <Heading level="2" align="center" size="small" weight="600">
                    {UniversalInboxEmptyStateStrings.noConversations}
                </Heading>
                <Box
                    color="universityColor"
                    as={InboxIllustration}
                    aria-hidden
                    w="60%"
                    maxW={340}
                    marginBottom="xlarge"
                />
                <Text size="medium" align="center" weight="400">
                    {UniversalInboxEmptyStateStrings.findPeopleToChat}
                </Text>
                <Button color="primary" onClick={handlePress}>
                    {UniversalInboxEmptyStateStrings.findPeople}
                </Button>
            </Stack>
        </View>
    );
};
