import React, {useEffect, useState, useCallback, useContext, createContext, useRef} from "react";
import pullAt from "lodash/pullAt";
import Pusher, {Channel} from "pusher-js";
import PusherBatchAuthorizer from "pusher-js-auth";
import {useErrorReporting} from "@unibuddy/error-reporting";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {getAuthorizationHeaderFor} from "ubcommunity-shared/src/Auth/utils";
import {PIKACHU_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY} from "ubcommunity-shared/src/constants";

export const SocketContext = createContext<{socket?: Socket; ready: boolean}>({
    ready: false,
    socket: undefined,
});

interface SocketChannelOptions {
    private?: boolean;
}

export class SocketChannel {
    name: string;
    private: boolean;

    constructor(name: string, options: SocketChannelOptions = {}) {
        this.name = name;
        this.private = options.private || false;
    }
    getName(channelId) {
        return `${this.private ? "private-" : ""}${this.name}-${channelId}`;
    }
}

export class SocketEvent {
    channel: SocketChannel;
    name: string;

    constructor(name: string, channel: SocketChannel) {
        this.channel = channel;
        this.name = name;
    }
    getChannel(channelId: string) {
        return this.channel.getName(channelId);
    }
}

export function useSocketListener(event, channelId, callback) {
    const {socket, ready} = useContext(SocketContext);
    const callbackRef = useRef<(...args: unknown[]) => void>();
    callbackRef.current = callback;
    useEffect(() => {
        if (ready && channelId) {
            const cb = (...args) => {
                callbackRef.current(...args);
            };
            // we don't want to use the socket if we don't know the id yet
            socket.subscribeEvent(event, channelId, cb);
            return () => {
                socket.unsubscribeEvent(event, channelId, cb);
            };
        }
    }, [socket, ready, event, channelId]);
}

export function useSocketData(event, channelId) {
    const [value, setValue] = useState();
    const callback = useCallback((socketData) => setValue(socketData), [setValue]);
    useSocketListener(event, channelId, callback);
    return value;
}

type SocketCallback = (data: any) => void;

export class Socket {
    private _pusher: Pusher;
    private _channels: {
        [channelId: string]: {
            channel: Channel;
            binded: SocketCallback[];
        };
    };

    constructor(token, PusherImplementation = Pusher, scheme: string) {
        this._pusher = new PusherImplementation(PUSHER_APP_KEY, {
            cluster: PUSHER_APP_CLUSTER,
            authorizer: PusherBatchAuthorizer,
            auth: {headers: {Authorization: getAuthorizationHeaderFor(scheme, token)}},
            authEndpoint: `${PIKACHU_URL}/pusher/batch-auth`,
            forceTLS: true,
        });
        this._pusher.connection.bind("error", function onError(error) {
            console.log("Pusher error", error);
        });
        this._channels = {};

        // we clear the channels when disconnected, because we are going to reconnect those
        this._pusher.connection.bind("unavailable", () => {
            console.log("Pusher unavailable");
            this._channels = {};
        });
        this._pusher.connection.bind("disconnected", () => {
            console.log("Pusher disconnected");
            this._channels = {};
        });
    }

    subscribeChannel(channel: SocketChannel, channelId: string) {
        const channelName = channel.getName(channelId);
        return this._pusher.subscribe(channelName);
    }

    subscribeEvent(event: SocketEvent, channelId: string, callback: SocketCallback) {
        if (this._pusher.connection.state !== "connected") {
            return; // can't subscribe, we are not connected
        }

        const channelName = event.getChannel(channelId);
        /** if we haven't already subscribed, we add it */
        if (!this._channels[channelName]) {
            this._channels[channelName] = {
                channel: this._pusher.subscribe(channelName),
                binded: [callback], // we keep track of the callback we bind
            };
        } else {
            this._channels[channelName].binded.push(callback); // we keep track of the callback we bind
        }
        this._channels[channelName].channel.bind(event.name, callback);

        return this._channels[channelName].channel;
    }

    unsubscribeEvent(event: SocketEvent, channelId: string, callback: SocketCallback) {
        if (this._pusher.connection.state !== "connected") {
            return; // can't subscribe, we are not connected
        }
        const channelName = event.getChannel(channelId);
        if (!this._channels[channelName]) {
            /** nothing to unsubscribe from */
            return;
        }

        /** we unbind the callback */
        this._channels[channelName].channel.unbind(event.name, callback);

        /** we remove it from the list of callback that exist */
        pullAt(
            this._channels[channelName].binded,
            this._channels[channelName].binded.lastIndexOf(callback),
        );

        /** if we don't have any listener left, unsubscribe channel */
        if (this._channels[channelName].binded.length === 0) {
            this._channels[channelName].channel.unsubscribe();
            delete this._channels[channelName];
        }
    }

    disconnect() {
        this._pusher.disconnect();
    }

    onConnected(callback) {
        this._pusher.connection.bind("connected", callback);
    }

    onDisconnected(callback) {
        this._pusher.connection.bind("unavailable", callback);
        this._pusher.connection.bind("disconnected", callback);
    }
}

export function SocketProvider({children, SocketImplementation = Socket}) {
    const {authState} = useAuth();
    const {reportError} = useErrorReporting();
    const token = authState && authState.accessToken;
    const getScheme = () => {
        if (authState) {
            if (authState?.tokenScheme && authState?.tokenScheme.includes("Bearer")) {
                return authState.tokenScheme;
            }
            return `JWT`;
        }
        return "";
    };
    const scheme = getScheme();

    const [value, setValue] = React.useState({ready: false, socket: undefined});
    React.useEffect(() => {
        if (token) {
            const socket = new SocketImplementation(token, Pusher, scheme);
            socket.onConnected(() => {
                setValue({
                    socket,
                    ready: true,
                });
            });
            socket.onDisconnected(() => {
                setValue({
                    socket,
                    ready: false,
                });
            });
            return () => socket.disconnect();
        }
    }, [token, SocketImplementation, reportError, scheme]);
    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}
