import {PropsWithChildren} from "react";
import {View} from "@unibuddy/patron";

type DiscoverEnterDialogAnimationProps = {
    trigger: boolean;
};

export const DiscoverEnterDialogAnimation = ({
    trigger,
    children,
}: PropsWithChildren<DiscoverEnterDialogAnimationProps>) => {
    return (
        <>
            <View
                position="absolute"
                backgroundColor="white"
                w="100%"
                h="100%"
                top={0}
                left={0}
                opacity={0.8}
            />
            <View
                borderWidth={1}
                borderColor="grey300"
                zIndex={2}
                alignItems="center"
                padding="medium-large"
                paddingTop="medium"
                maxW={350}
                bgColor="white"
                borderRadius="small"
                shadow="xlarge"
            >
                {children}
            </View>
        </>
    );
};
