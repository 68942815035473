import React from "react";
import styled from "styled-components";
import {Box, Column, Columns, Inline, Stack, Text, usePatronTheme, View} from "@unibuddy/patron";

import CheckboxSvg from "ubcommunity-shared/src/Svgs/CheckboxSvg";
import RadioButtonSvg from "ubcommunity-shared/src/Svgs/RadioButtonSvg";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {OptionLabel, useOption} from "ubcommunity-shared/src/Patron/OptionGroup/OptionGroupv2";

import {PollOptionProps} from "./types";

const StyledLabel = styled(OptionLabel)`
    &:hover {
        cursor: pointer;
    }
`;

export const PollOption = ({
    option,
    pollCount,
    totalCount,
    multiple,
    maxValue,
}: PollOptionProps) => {
    const {isSelected, isFocused} = useOption();
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();

    const pollWidth = totalCount === 0 ? 0 : Math.round((pollCount / maxValue) * 100);

    const controlColor = darkModeEnabled
        ? isSelected
            ? theme.colors.textLinkColor
            : theme.colors.grey150
        : isSelected
        ? theme.colors.primaryColor
        : theme.colors.grey500;

    const optionColor = darkModeEnabled
        ? isSelected
            ? theme.colors.white
            : theme.colors.grey150
        : theme.colors.textColor;

    return (
        <StyledLabel isSelected={isSelected} isFocused={isFocused}>
            <View data-testid="poll-option-label">
                <Stack space="xsmall">
                    <Columns verticalAlign="center">
                        <Column>
                            <Inline space="xxsmall" verticalAlign="center">
                                {multiple ? (
                                    <CheckboxSvg color={controlColor} filled={isSelected} />
                                ) : (
                                    <RadioButtonSvg color={controlColor} filled={isSelected} />
                                )}
                                <Text color={optionColor} weight={isSelected ? "600" : "400"}>
                                    {option.option}
                                </Text>
                            </Inline>
                        </Column>
                        <Column width="content">
                            <Text color={darkModeEnabled ? "grey150" : "textColor"} weight="400">
                                {pollCount}
                            </Text>
                        </Column>
                    </Columns>
                    <Box
                        width="100%"
                        height={4}
                        bgColor={darkModeEnabled ? "grey600" : "grey150"}
                        borderRadius="large"
                    >
                        <Box
                            width={`${pollWidth}%`}
                            height="100%"
                            bgColor={darkModeEnabled ? "textLinkColor" : "primaryColor"}
                            borderRadius="large"
                        />
                    </Box>
                </Stack>
            </View>
        </StyledLabel>
    );
};
