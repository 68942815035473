import React, {memo, useEffect, useState} from "react";
import {Text, View} from "@unibuddy/patron";
import {FlatList, Image, TouchableOpacity} from "react-native";
import {useErrorReporting} from "@unibuddy/error-reporting";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useIsTouchDevice} from "ubcommunity-shared/src/General/useIsTouchDevice/useIsTouchDevice";
import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {IMAGES_IN_CHAT} from "ubcommunity-shared/src/featureFlags";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {getDynamicImageUrlV2} from "ubcommunity-shared/src/Patron/getDynamicUrlV2";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";

export const ImageThumbnailItem = memo(
    ({
        image,
        size,
        onPress,
        onLongPress,
    }: {
        image: IImagePreview;
        size: number;
        onPress?: () => void;
        onLongPress?: () => void;
    }) => {
        const {reportError} = useErrorReporting();
        const [error, setError] = useState<any>(null);

        const width = size;
        const height = size;

        useEffect(() => {
            if (error) {
                reportError(error);
            }
        }, [error, reportError]);

        if (error) {
            return (
                <View w={100} h={100} padding="small" alignItems="center" justifyContent="center">
                    <Text align="center" size="small">
                        Failed to load image.
                    </Text>
                </View>
            );
        }

        return (
            <TouchableOpacity onPress={onPress} onLongPress={onLongPress}>
                <Image
                    source={{
                        uri: getDynamicImageUrlV2(image.url, {
                            width,
                            height,
                        }),
                        width: width,
                        height: height,
                    }}
                    onError={(err) => {
                        setError(err);
                    }}
                />
            </TouchableOpacity>
        );
    },
);

export const ImageThumbnail = memo(
    ({
        images,
        onSpotlight,
        onLongPress,
    }: {
        images: IImagePreview[];
        onSpotlight: (images: IImagePreview[], selectedImage: IImagePreview) => void;
        onLongPress: () => void;
    }) => {
        const {isTouchDevice} = useIsTouchDevice();

        const imagesInChatFeatureFlag = useIsFeatureEnabledBoolean(IMAGES_IN_CHAT);
        const imagesInChatAdminEnabled = useIsFeatureAdminEnabled(
            AdminFeatureFlags.IMAGES_SETTINGS,
        );
        const imagesEnabled = imagesInChatFeatureFlag && imagesInChatAdminEnabled;

        if (!imagesEnabled)
            return (
                <View>
                    <Text size="xsmall" fontStyle="italic">
                        Images have been disabled.
                    </Text>
                </View>
            );

        const renderItem = ({item}) => {
            if (item.url.startsWith("https"))
                return (
                    <View borderRadius="xxsmall" overflow="hidden" margin="xxsmall">
                        <ImageThumbnailItem
                            onLongPress={onLongPress}
                            onPress={() => onSpotlight(images, item)}
                            image={item}
                            size={images.length === 1 ? 200 : 100}
                        />
                    </View>
                );

            // optimistic image
            return (
                <View borderRadius="xxsmall" overflow="hidden" margin="xxsmall" position="relative">
                    <View
                        data-testid={`optimistic-image-${item.url}`}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        w="100%"
                        h="100%"
                        position="absolute"
                        zIndex={2}
                    >
                        <ActivityIndicator />
                    </View>
                    <Image
                        source={{
                            uri: item.url,
                            width: 100,
                            height: 100,
                        }}
                        blurRadius={6}
                    />
                </View>
            );
        };

        return (
            <FlatList
                data={images}
                numColumns={isTouchDevice ? 2 : 3}
                renderItem={renderItem}
                keyExtractor={(item) => item.url}
            />
        );
    },
);
