import * as React from "react";
import Svg, {Circle, SvgProps, G, Rect, Path, Defs, Mask, ClipPath} from "react-native-svg";

export function InboxIllustration(props: SvgProps) {
    return (
        <Svg
            width="243"
            height="240"
            viewBox="0 0 243 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <G clip-path="url(#clip0_38_15575)">
                <Rect x="0.926468" width="241.765" height="240" rx="5.64706" fill="white" />
                <Mask id="path-4-inside-1_38_15575" fill="white">
                    <Path d="M0.926468 0L242.691 0V48H0.926468L0.926468 0Z" />
                </Mask>
                <Path d="M0.926468 0L242.691 0V48H0.926468L0.926468 0Z" fill="white" />
                <Path
                    d="M242.691 46.9091H0.926468L0.926468 49.0909H242.691V46.9091Z"
                    fill="#F5F5F5"
                    mask="url(#path-4-inside-1_38_15575)"
                />
                <Circle cx="24.9264" cy="23.9999" r="12.7059" fill="#F5F5F5" />
                <Rect
                    x="46.1029"
                    y="11.2941"
                    width="70.5882"
                    height="11.2941"
                    rx="5.64706"
                    fill="#F0F0F0"
                />
                <Rect
                    x="46.1029"
                    y="28.2352"
                    width="121.412"
                    height="8.47059"
                    rx="4.23529"
                    fill="#F5F5F5"
                />
                <Rect
                    x="222.926"
                    y="19.7647"
                    width="8.47059"
                    height="8.47059"
                    rx="4.23529"
                    fill="#CBA4FE"
                />
                <Mask id="path-10-inside-2_38_15575" fill="white">
                    <Path d="M0.926468 47.9998H242.691V95.9998H0.926468L0.926468 47.9998Z" />
                </Mask>
                <Path
                    d="M0.926468 47.9998H242.691V95.9998H0.926468L0.926468 47.9998Z"
                    fill="white"
                />
                <Path
                    d="M242.691 94.9089H0.926468L0.926468 97.0907H242.691V94.9089Z"
                    fill="#F5F5F5"
                    mask="url(#path-10-inside-2_38_15575)"
                />
                <Circle cx="24.9264" cy="72" r="12.7059" fill="#F5F5F5" />
                <Rect
                    x="46.1029"
                    y="59.2939"
                    width="70.5882"
                    height="11.2941"
                    rx="5.64706"
                    fill="#F0F0F0"
                />
                <Rect
                    x="46.1029"
                    y="76.235"
                    width="158.118"
                    height="8.47059"
                    rx="4.23529"
                    fill="#F5F5F5"
                />
                <Rect
                    x="222.926"
                    y="67.7645"
                    width="8.47059"
                    height="8.47059"
                    rx="4.23529"
                    fill="#CBA4FE"
                />
                <Mask id="path-16-inside-3_38_15575" fill="white">
                    <Path d="M0.926468 95.9999H242.691V144H0.926468L0.926468 95.9999Z" />
                </Mask>
                <Path d="M0.926468 95.9999H242.691V144H0.926468L0.926468 95.9999Z" fill="white" />
                <Path
                    d="M242.691 142.909H0.926468L0.926468 145.091H242.691V142.909Z"
                    fill="#F5F5F5"
                    mask="url(#path-16-inside-3_38_15575)"
                />
                <Circle cx="24.9264" cy="120" r="12.7059" fill="#F5F5F5" />
                <Rect
                    x="46.1029"
                    y="107.294"
                    width="70.5882"
                    height="11.2941"
                    rx="5.64706"
                    fill="#F0F0F0"
                />
                <Rect
                    x="46.1029"
                    y="124.235"
                    width="129.176"
                    height="8.47059"
                    rx="4.23529"
                    fill="#F5F5F5"
                />
                <Mask id="path-21-inside-4_38_15575" fill="white">
                    <Path d="M0.926468 144H242.691V192H0.926468L0.926468 144Z" />
                </Mask>
                <Path d="M0.926468 144H242.691V192H0.926468L0.926468 144Z" fill="white" />
                <Path
                    d="M242.691 190.909H0.926468L0.926468 193.091H242.691V190.909Z"
                    fill="#F5F5F5"
                    mask="url(#path-21-inside-4_38_15575)"
                />
                <Circle cx="24.9264" cy="168" r="12.7059" fill="#F5F5F5" />
                <Rect
                    x="46.1029"
                    y="155.294"
                    width="70.5882"
                    height="11.2941"
                    rx="5.64706"
                    fill="#F0F0F0"
                />
                <Rect
                    x="46.1029"
                    y="172.235"
                    width="158.118"
                    height="8.47059"
                    rx="4.23529"
                    fill="#F5F5F5"
                />
                <Rect
                    x="222.926"
                    y="163.765"
                    width="8.47059"
                    height="8.47059"
                    rx="4.23529"
                    fill="#CBA4FE"
                />
                <Mask id="path-27-inside-5_38_15575" fill="white">
                    <Path d="M0.926468 192H242.691V240H0.926468L0.926468 192Z" />
                </Mask>
                <Path d="M0.926468 192H242.691V240H0.926468L0.926468 192Z" fill="white" />
                <Path
                    d="M242.691 238.909H0.926468L0.926468 241.091H242.691V238.909Z"
                    fill="#F5F5F5"
                    mask="url(#path-27-inside-5_38_15575)"
                />
                <Circle cx="24.9264" cy="216" r="12.7059" fill="#F5F5F5" />
                <Rect
                    x="46.1029"
                    y="203.294"
                    width="70.5882"
                    height="11.2941"
                    rx="5.64706"
                    fill="#F0F0F0"
                />
                <Rect
                    x="46.1029"
                    y="220.235"
                    width="158.118"
                    height="8.47059"
                    rx="4.23529"
                    fill="#F5F5F5"
                />
            </G>
            <Rect x="1.42647" y="0.5" width="240.765" height="239" rx="5.14706" stroke="#D9D9DD" />
            <Defs>
                <ClipPath id="clip0_38_15575">
                    <Rect x="0.926468" width="241.765" height="240" rx="5.64706" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
