import * as React from "react";
import {SVGProps, memo} from "react";
import {VisuallyHidden} from "@unibuddy/patron";

const BadEmoji = (props: SVGProps<SVGSVGElement>) => (
    <>
        <VisuallyHidden>😔</VisuallyHidden>
        <svg
            aria-hidden
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 72 72"
            width="1em"
            height="1em"
            {...props}
        >
            <circle
                cx={36}
                cy={36}
                r={23}
                fill="none"
                stroke="#000"
                strokeMiterlimit={10}
                strokeWidth={2}
            />
            <path d="M30 31a3.001 3.001 0 0 1-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3M48 31a3.001 3.001 0 0 1-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3" />
            <path
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M28 46c1.58-2.558 4.904-4.135 8.421-4.004C39.65 42.116 42.562 43.66 44 46"
            />
        </svg>
    </>
);

const Memo = memo(BadEmoji);
export default Memo;
