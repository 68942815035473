import React from "react";
import {Inline} from "@unibuddy/patron";
import {IImage} from "../useAttachments";
import {ImagesPreviewItem} from "./ImagesPreviewItem";

export const ImagesPreview = ({
    selectedImages,
    setSelectedImages,
}: {
    selectedImages: IImage[];
    setSelectedImages: React.Dispatch<React.SetStateAction<IImage[]>>;
}) => {
    const onImageRemove = (image: IImage) => {
        setSelectedImages((prev) => prev.filter((i) => i.uri !== image.uri));
    };

    if (!selectedImages.length) return null;

    return (
        <Inline space="xsmall">
            {selectedImages.map((item) => (
                <ImagesPreviewItem key={item.uri} item={item} onImageRemove={onImageRemove} />
            ))}
        </Inline>
    );
};
