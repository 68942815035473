import {IconUbExplore, IconUserGroup} from "@unibuddy/icons";
import {TabNav} from "ubcommunity-shared/src/StudentApp/TabbedNavigation/TabNav";
import {ExplorePage} from "../Community/ExplorePage/ExplorePage";
import {PeoplePage} from "../Community/PeoplePage/PeoplePage";

export const CommunityHome = () => {
    return (
        <TabNav
            tabs={[
                {
                    label: "Explore",
                    icon: IconUbExplore,
                    component: ExplorePage,
                },
                {
                    label: "People",
                    icon: IconUserGroup,
                    component: PeoplePage,
                },
            ]}
        />
    );
};
