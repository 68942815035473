import React from "react";

import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {DesktopMatchesListPage} from "./DesktopMatchesListPage";
import {MobileMatchesListPage} from "./MobileMatchesListPage";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";

export const MatchesListPage = () => {
    const {isDesktop} = useIsDesktop();

    usePageTitle(PageTitles.MATCHES);

    return isDesktop ? <DesktopMatchesListPage /> : <MobileMatchesListPage />;
};
