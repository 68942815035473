import React, {FC} from "react";
import {Box} from "@unibuddy/patron";

import {CountryFlag} from "ubcommunity-shared/src/General/CountryFlag";
import {PublicMentorField, UserField} from "ubcommunity-shared/src/types";
import {UserAccountRoles} from "ubcommunity-shared/src/constants";
import {UserAvatar} from "./UserAvatar";

interface FlagProps {
    top?: number;
    right?: number;
    left?: number;
    size?: number;
}

const FooterFlags = ({
    user,
    flagOptions,
}: {
    user: UserField | PublicMentorField;
    flagOptions: FlagProps;
}) => {
    if (
        user?.accountRole === UserAccountRoles.MENTOR &&
        "mentorFields" in user &&
        user.mentorFields?.primaryFlag
    ) {
        return (
            <>
                <Box
                    borderRadius="xxsmall"
                    position={"absolute"}
                    top={flagOptions?.top ? flagOptions.top : 0}
                    right={flagOptions?.right ? flagOptions.right : 0}
                    data-testid="mentor-primary-flag"
                >
                    <CountryFlag
                        size={flagOptions.size ?? 0}
                        label={user.mentorFields?.primaryFlag?.name ?? ""}
                        code={user.mentorFields?.primaryFlag?.code ?? ""}
                    />
                </Box>
                {user?.mentorFields?.secondaryFlag ? (
                    <Box
                        borderRadius="xxsmall"
                        position={"absolute"}
                        top={flagOptions?.top ? flagOptions.top : 0}
                        left={flagOptions?.left ? flagOptions.left : 0}
                        data-testid="mentor-secondary-flag"
                    >
                        <CountryFlag
                            size={flagOptions.size ?? 0}
                            label={user.mentorFields?.secondaryFlag?.name ?? ""}
                            code={user.mentorFields?.secondaryFlag?.code ?? ""}
                        />
                    </Box>
                ) : null}
            </>
        );
    } else if (user.country) {
        return (
            <Box
                borderRadius="xxsmall"
                position={"absolute"}
                top={flagOptions?.top ? flagOptions.top : 0}
                right={flagOptions?.right ? flagOptions.right : 0}
                data-testid="country-flag"
            >
                <CountryFlag
                    size={flagOptions.size ?? 0}
                    label={user.country.name ?? ""}
                    code={user.country.code ?? ""}
                />
            </Box>
        );
    }
    return null;
};

export const UserAvatarWithFlag: FC<{
    user: UserField | PublicMentorField;
    size: number;
    flagOptions: FlagProps;
    isBlocked?: boolean;
}> = ({user, size, flagOptions, isBlocked}) => {
    return (
        <Box position="relative">
            <UserAvatar
                size={size}
                user={{
                    id: user.id ?? "",
                    firstName: user.firstName ?? "",
                    accountRole: user.accountRole ?? UserAccountRoles.APPLICANT,
                    profilePhoto: user?.profilePhoto ?? "",
                    communityProfile:
                        "communityProfile" in user
                            ? user?.communityProfile ?? undefined
                            : undefined,
                }}
                isBlocked={isBlocked}
            />
            {!isBlocked ? <FooterFlags user={user} flagOptions={flagOptions} /> : null}
        </Box>
    );
};
