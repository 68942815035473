import {ASSETS_URL_V2} from "ubcommunity-shared/src/constants";

interface ResizeProps {
    width?: number;
    height?: number;
}

export const getDynamicImageUrlV2 = (url: string, resize?: ResizeProps) => {
    const getImageKey = () => {
        let key = "";
        if (typeof url === "string") {
            const urlParts = url.split("/");
            key = urlParts[urlParts.length - 1];
        }
        return key;
    };

    const fullImage = `${ASSETS_URL_V2}/${0}x${0}/${getImageKey()}`;

    if (!resize) return fullImage;

    const {width, height} = resize;

    if (!width || !height) return fullImage;

    const resizedImage = `${ASSETS_URL_V2}/${width}x${height}/${getImageKey()}`;

    return resizedImage;
};
