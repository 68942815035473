import React, {useEffect} from "react";
import {Linking} from "react-native";
import {View, Heading, Stack, Button, Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import PeerToPeerIcon from "ubcommunity-shared/src/Icons/PeerToPeerIcon";
import {FEEDBACK_URL} from "ubcommunity-shared/src/featureFlags";
import {useStringFeatureFlagWrapper} from "ubcommunity-shared/src/FeatureFlags/StringFlag/useStringFeatureFlagWrapper";

import {DefaultPopupWrapper} from "../DefaultPopup/DefaultPopupWrapper";
import {useIsDesktop} from "../../useIsDesktop/useIsDesktop";

export const popUpCopy = {
    title: "Help us help you!",
    text: "We want to know how we can improve your experience with the app. Answer our quick survey for your chance to win an exclusive prize!",
    buttonText: "Provide feedback",
    imageSize: 280,
};

const FALLBACK_FEEDBACK_URL = "https://tally.so/r/mZa47a";

type RecruitStudentPopupProps = {
    handleLastShowPopup: () => void;
    setIsPopupActive: (p: boolean) => void;
    handleNextPopup: () => void;
};

export const RecruitStudentPopup = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: RecruitStudentPopupProps) => {
    const {
        value: hasShown,
        setValue,
        loading,
    } = useAsyncLocalStorage(LocalStorageNames.HAS_SHOWN_STUDENT_RECRUIT_CTA, false);
    const {trackEvent} = useAnalytics();
    const {isDesktop} = useIsDesktop();
    const {darkModeEnabled} = useCommunityTheme();
    const CALENDLY_URL = useStringFeatureFlagWrapper(FEEDBACK_URL, FALLBACK_FEEDBACK_URL);

    useEffect(() => {
        if (!loading && !hasShown) {
            setIsPopupActive(true);
        }
        if (!loading && hasShown) {
            handleNextPopup();
        }
    }, [handleNextPopup, hasShown, loading, setIsPopupActive]);

    const handleDismiss = (value: boolean, eventSource?: string) => {
        trackEvent(TrackEvents.STUDENT_RECRUIT_CTA_DISMISSED, {
            eventSource: eventSource ? eventSource : "close (x) button",
        });
        setValue(true);
        handleLastShowPopup();
    };

    const handleSubmit = () => {
        trackEvent(TrackEvents.STUDENT_RECRUIT_CTA_CLICKED);
        setValue(true);
        handleLastShowPopup();
        Linking.openURL(CALENDLY_URL);
    };

    return (
        <DefaultPopupWrapper
            isContextMenuOpen={!loading && !hasShown}
            setIsContextMenuOpen={handleDismiss}
        >
            <View padding="large" marginTop="large">
                <Stack space="medium">
                    <View width="100%" alignItems={"center"}>
                        <PeerToPeerIcon width={180} height={180} />
                    </View>
                    <Heading level="4" align="center" color={darkModeEnabled ? "white" : "black"}>
                        {popUpCopy.title}
                    </Heading>
                    <Text
                        size="medium"
                        align="center"
                        color={darkModeEnabled ? "lightGrey" : "darkGrey"}
                    >
                        {popUpCopy.text}
                    </Text>
                    <Stack space="small" align={isDesktop ? "center" : "left"}>
                        <Button
                            accessibilityLabel={`confirm button`}
                            name={popUpCopy.buttonText}
                            key={`button-student-recruit-confirm`}
                            color="primary"
                            block={isDesktop ? false : true}
                            onClick={handleSubmit}
                        >
                            <Text size="large" style={{...boldFontStyles}} color={"primary"}>
                                {popUpCopy.buttonText}
                            </Text>
                        </Button>
                        <Button
                            accessibilityLabel={`cancel button`}
                            name={popUpCopy.buttonText}
                            clear
                            block
                            key={`button-student-recruit-cancel`}
                            color="white"
                            onClick={() => handleDismiss(false, "cancel button")}
                        >
                            <Text size="large" style={{...boldFontStyles}} color="altTextColor">
                                No thanks
                            </Text>
                        </Button>
                    </Stack>
                </Stack>
            </View>
        </DefaultPopupWrapper>
    );
};
