import React from "react";
import {Svg, Path, Rect} from "react-native-svg";

export const UBLogo = ({size, color}: {size?: number; color?: string}) => {
    return (
        <Svg
            width={size ?? 40}
            height={size ?? 40}
            viewBox={`0 0 40 40`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Rect width={size ?? 40} height={size ?? 40} rx="4" fill={color ?? "#01D4CF"} />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.2222 11V20.8541L25.8448 20.4358C26.1111 23.5584 24.1042 26.3405 21.2222 27.0701V31C26.6247 31 31 26.5253 31 21V11H21.2222ZM24.8936 18.4903C24.2183 18.4903 23.6667 17.9261 23.6667 17.2354C23.6667 16.5447 24.2183 15.9805 24.8936 15.9805C25.569 15.9805 26.1206 16.5447 26.1206 17.2354C26.1206 17.9261 25.569 18.4903 24.8936 18.4903ZM13.6226 21.535L18.7778 21.0778V11H9V21C9 26.5253 13.3753 31 18.7778 31V27.1868C16.0575 26.749 13.8699 24.463 13.6226 21.535ZM15.1254 15.9903C15.8007 15.9903 16.3524 16.5545 16.3524 17.2451C16.3524 17.9358 15.8007 18.5 15.1254 18.5C14.4501 18.5 13.8984 17.9358 13.8984 17.2451C13.8984 16.5545 14.4501 15.9903 15.1254 15.9903Z"
                fill="white"
            />
        </Svg>
    );
};
