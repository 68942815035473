import React from "react";
import {Svg, Circle} from "react-native-svg";

const RadioButtonSvg = ({
    size,
    color,
    filled,
}: {
    size?: number;
    color?: string;
    filled?: boolean;
}) => {
    return (
        <Svg
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Circle cx="12" cy="12" r="8" stroke={color || "#CFC8FF"} stroke-width="2" />
            {filled ? <Circle cx="12" cy="12" r="5" fill={color || "#CFC8FF"} /> : null}
        </Svg>
    );
};

export default RadioButtonSvg;
