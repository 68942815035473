import React from "react";

import {View} from "@unibuddy/patron";

import {UserSummary} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary";
import {useSideDrawer} from "ubcommunity-shared/src/General/SideDrawerProvider/SideDrawerProvider";
import {useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {SetBlockUserForm} from "ubcommunity-shared/src/Chat/ChatDrawer/BlockUser/SetBlockUserForm";
import {ReportUserForm} from "ubcommunity-shared/src/Chat/ChatDrawer/ReportUser";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";

export const useOpenUserSummary = ({openSource}: {openSource?: string}) => {
    const {openSideDrawer, closeSideDrawer} = useSideDrawer();
    const {openModal, dismissModal} = useModal();

    const handleBlock = (senderId: string, conversationId?: string) => {
        openModal({
            component: (
                <View padding="medium">
                    <SetBlockUserForm
                        onSuccess={dismissModal}
                        onDismiss={dismissModal}
                        senderId={senderId}
                        conversationId={conversationId}
                    />
                </View>
            ),
        });
    };

    const handleReport = (senderId: string) => {
        openModal({
            component: (
                <ReportUserForm
                    reporteeId={senderId}
                    onSuccess={dismissModal}
                    onDismiss={dismissModal}
                />
            ),
        });
    };

    const handleOpen = (
        senderId: string,
        conversationId?: string,
        mixpanelMetadata?: MixpanelMetadataProps,
    ) => {
        const {openSource: _, ...userSummaryMixpanelMetadata} = {
            ...mixpanelMetadata,
            source: mixpanelMetadata?.openSource ?? openSource,
            isOnline: mixpanelMetadata?.isOnline ?? false,
        };
        openSideDrawer({
            component: (
                <UserSummary
                    senderId={senderId}
                    conversationId={conversationId}
                    mixpanelMetadata={userSummaryMixpanelMetadata}
                />
            ),
            header: null,
        });
    };

    return {
        openUserSummary: handleOpen,
        closeUserSummary: closeSideDrawer,
        handleBlock,
        handleReport,
    };
};
