import * as React from "react";

const ZoominIcon = (props) => {
    const {color = "currentColor", size = 24, ...rest} = props;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke={color}
            strokeWidth="2"
            {...rest}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
            />
        </svg>
    );
};

export default ZoominIcon;
