import React, {PropsWithChildren} from "react";
import {View, Text, Stack} from "@unibuddy/patron";
import {HousingFilter} from "./HousingFilter";

export type HousingSelectorProps = {
    callback?: () => void;
    scrollFilterListToEnd?: () => void;
};

export const HousingSelector = ({
    scrollFilterListToEnd,
}: PropsWithChildren<HousingSelectorProps>) => {
    return (
        <Stack space="small">
            <Text size="large" weight="bold" id="filter-housing-selector">
                Student housing
            </Text>
            <View marginTop="medium">
                <HousingFilter scrollFilterListToEnd={scrollFilterListToEnd} />
            </View>
        </Stack>
    );
};
