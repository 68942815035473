import React, {useState} from "react";
import {Platform, ScrollView, TouchableOpacity} from "react-native";
import {
    Button,
    Column,
    Columns,
    Divider,
    Heading,
    Inline,
    Stack,
    Text,
    View,
    usePatronTheme,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import ChevronRightIcon from "ubcommunity-shared/src/Icons/ChevronRightIcon";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {themeUnitToNativeValue} from "ubcommunity-shared/src/Utils";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {
    PEOPLE_AOS_FILTER,
    PEOPLE_INTERESTS_FILTER,
    PEOPLE_HOUSING_FILTER,
} from "ubcommunity-shared/src/featureFlags";
import {CourseSelector} from "./CourseSelector.mobile";
import {usePeopleFilters} from "../PeopleFiltersProvider";
import {FilterSelectionCount} from "./FilterSelectionCount";
import {useGetFilters} from "./useGetFilters";
import {InterestsSelector} from "./InterestsSelector.mobile";
import {HousingSelector} from "./Housing/HousingSelector.mobile";

const filterKeys = {
    areaOfStudy: {
        key: "areaOfStudy",
        label: "Area of study",
    },
    interests: {
        key: "interests",
        label: "Interests",
    },
    housingTypes: {
        key: "housingTypes",
        label: "Student housing",
    },
};

const Views = ({
    view,
    setView,
}: {
    view: string;
    setView: React.Dispatch<React.SetStateAction<keyof typeof filterKeys | undefined>>;
}) => {
    const {degreesData, degreesLoading, interestsData, interestsLoading} = useGetFilters();

    if (view === filterKeys.areaOfStudy.key) {
        if (degreesLoading) {
            return (
                <View paddingY="large">
                    <ActivityIndicator accessibilityLabel="Loading area of study filters" />
                </View>
            );
        }
        return <CourseSelector degreesData={degreesData} callback={() => setView(undefined)} />;
    }

    if (view === filterKeys.interests.key) {
        if (interestsLoading) {
            return (
                <View paddingY="large">
                    <ActivityIndicator accessibilityLabel="Loading interests filters" />
                </View>
            );
        }
        return (
            <InterestsSelector interestsData={interestsData} callback={() => setView(undefined)} />
        );
    }

    if (view === filterKeys.housingTypes.key) {
        return <HousingSelector callback={() => setView(undefined)} />;
    }

    return null;
};

const Root = ({handleSelect}: {handleSelect: (key: keyof typeof filterKeys) => void}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();
    const {filters} = usePeopleFilters();

    const isAosFilterEnabled = useIsFeatureEnabledBoolean(PEOPLE_AOS_FILTER);
    const isInterestsFilterEnabled = useIsFeatureEnabledBoolean(PEOPLE_INTERESTS_FILTER);
    const isHousingFilterEnabled = useIsFeatureEnabledBoolean(PEOPLE_HOUSING_FILTER);

    return Object.keys(filterKeys).map((key: keyof typeof filterKeys) => {
        const filter = filterKeys[key];

        if (key === filterKeys.areaOfStudy.key && !isAosFilterEnabled) {
            return null;
        }

        if (key === filterKeys.interests.key && !isInterestsFilterEnabled) {
            return null;
        }

        if (key === filterKeys.housingTypes.key && !isHousingFilterEnabled) {
            return null;
        }

        return (
            <TouchableOpacity
                key={filter.key}
                style={{
                    paddingHorizontal: themeUnitToNativeValue(theme.space.medium),
                    paddingVertical: themeUnitToNativeValue(theme.space["medium-large"]),
                }}
                onPress={() => handleSelect(key)}
                accessibilityLabel={`Filter people by ${filter.label}`}
                accessibilityHint={`${
                    filters[key]?.length
                        ? filters[key]?.length + " already selected"
                        : "No filters selected"
                } `}
            >
                <Columns verticalAlign="center">
                    <Column>
                        <Text size="large">{filter.label}</Text>
                    </Column>
                    <Column width="content">
                        <Inline space="small" verticalAlign="center">
                            <FilterSelectionCount count={filters[key]?.length ?? 0} />
                            <ChevronRightIcon color={darkModeEnabled ? "white" : "grey"} />
                        </Inline>
                    </Column>
                </Columns>
            </TouchableOpacity>
        );
    });
};

export const Filters = () => {
    const {selectedCommunity} = useCommunity();
    const {trackEvent} = useAnalytics();
    const [view, setView] = useState<keyof typeof filterKeys | undefined>();
    const {clearAll, refetch, filters} = usePeopleFilters();
    const {dismissModal} = useModal();

    const handleSelect = (key: keyof typeof filterKeys) => {
        setView(key);
    };

    const handleResults = async () => {
        const res = await refetch({
            filterUsers: {
                communityId: selectedCommunity.id,
                ...filters,
            },
        });

        const resultsCount = res?.data?.communityUserSearch?.totalUsers ?? 0;
        trackEvent(TrackEvents.PEOPLE_SHOW_RESULTS_CLICKED, {
            communityId: selectedCommunity.id,
            resultsCount,
            ...filters,
        });
        dismissModal();
    };

    const handleClearAll = () => {
        clearAll();
        dismissModal();
    };

    return (
        <ScrollView
            contentContainerStyle={{
                minHeight: "85%",
                flexGrow: 1,
                marginTop: 15,
            }}
        >
            <Stack space="medium">
                <View paddingX="medium" paddingY={Platform.OS === "web" ? "xsmall" : "none"}>
                    <Heading size="xsmall" level="3" accessibilityRole="header">
                        Filter
                    </Heading>
                </View>
                <Divider />
            </Stack>
            <View flex={1} justifyContent="space-between">
                <View>
                    {view ? (
                        <Views view={view} setView={setView} />
                    ) : (
                        <Root handleSelect={handleSelect} />
                    )}
                </View>
                <View paddingTop="small">
                    <View paddingBottom="medium">
                        <Divider />
                    </View>
                    <View paddingX="medium" paddingBottom="xsmall">
                        <Columns>
                            <Column>
                                <Button
                                    ghost
                                    clear
                                    onClick={handleClearAll}
                                    accessibilityLabel="Clear all filters"
                                    accessibilityRole="button"
                                >
                                    <Text
                                        color="textLinkColor"
                                        // @ts-ignore
                                        style={{
                                            textDecorationLine: "underline",
                                        }}
                                    >
                                        Clear all
                                    </Text>
                                </Button>
                            </Column>
                            <Column width="content">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        handleResults();
                                    }}
                                    accessibilityLabel="Apply filters and show results"
                                    accessibilityRole="button"
                                >
                                    <Text color="white">Show results</Text>
                                </Button>
                            </Column>
                        </Columns>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};
