import {useEffect, useRef, useState} from "react";
import {useSocketListener} from "ubcommunity-shared/src/Sockets/SocketProvider";
import {typingEvent} from "../sharedEvents";

interface Options {
    conversationId: string;
}

type Event = {
    userId: string;
    userName: string;
    created: number;
};

export const TYPING_TIMEOUT = 3000;

export const useIsTyping = ({conversationId}: Options) => {
    const [activeTypists, setActiveTypists] = useState<Record<string, Event>>({});
    const typingTimeoutRefs = useRef<Record<string, NodeJS.Timeout>>({});

    const SINGLE_TYPIST = "is typing...";
    const PLURAL_TYPIST = "Several people are typing...";

    const isTyping = Object.keys(activeTypists).length > 0;
    const lastNameTyped = activeTypists[Object.keys(activeTypists)[0]]?.userName ?? "";
    const message =
        Object.keys(activeTypists).length > 1 ? PLURAL_TYPIST : `${lastNameTyped} ${SINGLE_TYPIST}`;
    const typingMessage = isTyping ? message : "";

    useEffect(() => {
        const cleanupTimeouts = () => {
            Object.values(typingTimeoutRefs.current).forEach((timeout) => {
                clearTimeout(timeout);
            });
        };
        return () => {
            cleanupTimeouts();
        };
    }, []);

    const startTypingTimeout = (userId: string) => {
        if (typingTimeoutRefs.current[userId]) {
            clearTimeout(typingTimeoutRefs.current[userId]);
        }

        typingTimeoutRefs.current[userId] = setTimeout(() => {
            setActiveTypists((prevTypingQueue) => {
                const newTypingQueue = {...prevTypingQueue};
                delete newTypingQueue[userId];
                return newTypingQueue;
            });
        }, TYPING_TIMEOUT);
    };

    useSocketListener(typingEvent, conversationId, (e: Event) => {
        startTypingTimeout(e.userId);
        setActiveTypists((prevTypingQueue) => {
            if (!prevTypingQueue[e.userId]) {
                return {
                    ...prevTypingQueue,
                    [e.userId]: {...e},
                };
            }
            return prevTypingQueue;
        });
    });

    return {
        isTyping,
        typingMessage,
    };
};
