import {PropsWithChildren, useEffect} from "react";
import {Text, Stack, Button, View, Heading} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {useNavigate} from "../Hooks/navigate";
import {Routes, TrackEvents} from "../constants";

import {HomeGradient} from "./HomeGradient";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";
import {HomeEmptyStateSvg} from "./HomeEmptyStateSvg";
import {useShowDiscoverTab} from "../Discover/useShowDiscoverTab";

export enum HomeEmptyStateStrings {
    ButtonCta = "Discover Institutions",
    Title = "Add institutions to your home to start chatting",
    Description = "If you were invited by an institution, please check that you're logged in with the correct email address",
}

const Wrapper = ({children}: PropsWithChildren) => {
    const {isDesktop} = useIsDesktop();

    if (isDesktop) {
        return children;
    }

    return (
        <HomeGradient>
            <View padding="small-medium">{children}</View>
        </HomeGradient>
    );
};

export const HomeEmptyState = () => {
    const {trackEvent} = useAnalytics();
    const [navigate] = useNavigate();
    const {showDiscoverTab} = useShowDiscoverTab();

    const handleNavigate = () => {
        trackEvent(TrackEvents.HOME_EMPTY_STATE_CTA_CLICKED);
        navigate({
            path: Routes.DISCOVER,
        });
    };

    useEffect(() => {
        trackEvent(TrackEvents.HOME_EMPTY_STATE_VIEWED);
    }, [trackEvent]);

    return (
        <Wrapper>
            <View bgColor="white" padding="medium-large" borderRadius="small">
                <Stack space="medium-large" align="center">
                    <HomeEmptyStateSvg />

                    <Heading level="3" size="xsmall" align="center">
                        {HomeEmptyStateStrings.Title}
                    </Heading>

                    <Text align="center">{HomeEmptyStateStrings.Description}</Text>
                    {showDiscoverTab ? (
                        <Button block color="primary" onClick={handleNavigate}>
                            <Text color="white">{HomeEmptyStateStrings.ButtonCta}</Text>
                        </Button>
                    ) : null}
                </Stack>
            </View>
        </Wrapper>
    );
};
