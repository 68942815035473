import React, {FC} from "react";
import {View} from "@unibuddy/patron";
import styled from "styled-components";
import {SetBlockUserForm} from "ubcommunity-shared/src/Chat/ChatDrawer/BlockUser/SetBlockUserForm";

interface Props {
    senderId: string;
    onDismiss: () => void;
}

const SetBlockForm = styled(SetBlockUserForm)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const UserBlockModal: FC<Props> = ({onDismiss, senderId}) => {
    return (
        <View padding="medium">
            <SetBlockForm onSuccess={onDismiss} onDismiss={onDismiss} senderId={senderId} />
        </View>
    );
};
