import React, {useEffect} from "react";
import {Platform} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {TrackEvents, LocalStorageNames} from "ubcommunity-shared/src/constants";

import enhancedProfileImage from "./image/enhanced-profile.png";

import {DefaultPopup, IMAGE_POSITION} from "../DefaultPopup";
import {Routes} from "ubcommunity-shared/src/constants/routes";

export const popUpCopy = {
    title: "Tell us more about you",
    text: "Update your profile so that we can recommend relevant students for you to connect with",
    buttonText: "Update my profile",
    imageSize: 280,
    defaultHeight: 180,
};

type FeedbackPopupProps = {handleLastShowPopup: () => void; setIsPopupActive: (p: boolean) => void};

/**
 * @author
 * @function @PromoteEnhancedProfilesPopup
 **/
export const PromoteEnhancedProfilesPopup = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: FeedbackPopupProps & {handleNextPopup: () => void}) => {
    const {
        value: {hasShown},
        setValue,
        loading,
    } = useAsyncLocalStorage(LocalStorageNames.HAS_SHOWN_PROMOTE_ENHANCED_PROFILE, {
        hasShown: false,
    });
    const isWeb = Platform.OS === "web";
    const [navigate] = useNavigate();
    const {trackEvent} = useAnalytics();

    useEffect(() => {
        if (!loading && !hasShown) {
            trackEvent(TrackEvents.ENHANCE_PROFILE_POPUP_VIEWED);
            setIsPopupActive(true);
        }
        if (!loading && hasShown) {
            handleNextPopup();
        }
    }, [hasShown, loading]);

    const handleClick = () => {
        handleSetIsContextMenuOpen(false);
        trackEvent(TrackEvents.ENHANCE_PROFILE_POPUP_CLICKED);
        navigate({
            path: Routes.SETTINGS,
            options: {screen: isWeb ? "account" : "Account Information"},
        });
    };

    const handleSetIsContextMenuOpen = (value: boolean) => {
        setValue(() => ({hasShown: !value, date: new Date()}));
        handleLastShowPopup();
    };

    return (
        <DefaultPopup
            title={popUpCopy.title}
            text={popUpCopy.text}
            buttonText={popUpCopy.buttonText}
            image={enhancedProfileImage}
            onClick={handleClick}
            imageSize={popUpCopy.imageSize}
            defaultHeight={popUpCopy.defaultHeight}
            setIsContextMenuOpen={handleSetIsContextMenuOpen}
            isContextMenuOpen={!loading && !hasShown}
            imagePosition={IMAGE_POSITION.top}
        />
    );
};
