import React from "react";
import {TextArea, FormGroup, usePatronTheme} from "@unibuddy/patron";

export const EditBio = () => {
    const theme = usePatronTheme();

    return (
        <FormGroup label="A bit about me">
            <TextArea
                rows={2}
                name="bio"
                aria-label="Edit bio"
                placeholderTextColor={theme?.colors?.placeholderColor}
            />
        </FormGroup>
    );
};
