import React, {useCallback} from "react";
import {View, usePatronTheme} from "@unibuddy/patron";
import styled from "styled-components";

import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {FETCH_LIMIT} from "./FullMembersList";
import {Member} from "../Member";

export type FullMembersIteratorProps = {
    members: any;
    onShowUserSummary: (userId: string, mixpanelMetadata: any) => void;
    drawerRef?: React.RefObject<any>;
    updateQuery: () => void;
    loading: boolean;
    isDmsEnabled: boolean;
};

const UnorderedList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const MATCH_SCROLL_HEIGHT = 10;
export const FullMembersIterator = ({
    members,
    onShowUserSummary,
    drawerRef,
    updateQuery,
    loading,
    isDmsEnabled,
}: FullMembersIteratorProps) => {
    const theme = usePatronTheme();

    const onEndReached = useCallback(() => updateQuery(), [members]);

    const onEnd = (e) => {
        if (
            e.target.scrollTop + e.target.clientHeight + MATCH_SCROLL_HEIGHT >=
                e.target.scrollHeight &&
            !loading &&
            members.length >= FETCH_LIMIT
        ) {
            onEndReached();
        }
    };

    const shouldShowLoading = loading && members.length > 0;
    return (
        <View overflow="scroll" onScroll={onEnd} flexGrow="1">
            <UnorderedList aria-label="Full members list">
                {members.map((item) => (
                    <li key={item.id}>
                        <View
                            marginX="medium"
                            paddingY="small"
                            borderBottomWidth={1}
                            borderColor={theme.Divider.bgColor}
                        >
                            <Member
                                member={item}
                                onShowUserSummary={onShowUserSummary}
                                drawerRef={drawerRef}
                                isDmsEnabled={isDmsEnabled}
                            />
                        </View>
                    </li>
                ))}
                <View as="li" justifyContent="center" height={70}>
                    {shouldShowLoading ? (
                        <ActivityIndicator accessibilityLabel="Loading members" />
                    ) : null}
                </View>
            </UnorderedList>
        </View>
    );
};
