import React from "react";
import {Stack, Text, View, Divider, Button} from "@unibuddy/patron";
import {gql, useApolloClient} from "@apollo/client";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {
    InboxConversationFragment,
    useAddUserToGroupMutation,
    useCommunityInboxPrivateQuery,
    UserField,
} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {truncateString} from "ubcommunity-shared/src/Utils";

export const UserSummaryGroupInvite = ({
    senderId,
    conversationId,
}: {
    senderId: string;
    conversationId: string;
}) => {
    const {selectedCommunity} = useCommunity();
    const {user} = useAuth();
    const {darkModeEnabled} = useCommunityTheme();
    const {trackEvent} = useAnalytics();
    const client = useApolloClient();
    const {data, loading, error, refetch} = useCommunityInboxPrivateQuery({
        // ideally, i would like the fetchPolicy here to be "cache-first" as this is an expensive query depending on the
        // size of the group. however, users may be invited to a group via a dynamic link in which case the information
        // may not be truthy always. the most we can do is prevent a network request when a user is added to the group.
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: {communityId: selectedCommunity.id},
        notifyOnNetworkStatusChange: true,
    });
    const userData = client.readFragment({
        id: `UserField:${senderId}`,
        fragment: gql`
            fragment UserFieldFragment on UserField {
                accountRole
            }
        `,
    });

    const [addUserToGroup, {error: addUserToGroupError, loading: addUserToGroupLoading}] =
        useAddUserToGroupMutation();

    const {reportError} = useErrorReporting();

    const addMemberToGroup = (newConversationId: string) => {
        try {
            addUserToGroup({
                variables: {
                    conversationId: newConversationId,
                    memberId: senderId,
                },
            });
            trackEvent("User Added To Group", {
                addedFrom: conversationId,
                addedToConversation: newConversationId,
                userRole: userData?.accountRole,
                addedByUserRole: user.accountRole,
            });
        } catch (error) {
            reportError(error);
        }
    };

    // check if member is part of the conversation already
    const isPartOfTheGroup = (
        conversation: InboxConversationFragment & {allMembers?: UserField[]},
    ) => {
        /** This is the only place all members is used;
         *  Can get rid of this if we find a way to check if user is member of that conversation without allMembers
         */ 
        const member = conversation.allMembers?.find((member) => senderId === member.id);
        return member ? true : false;
    };

    if (loading)
        return (
            <View paddingY="large">
                <ActivityIndicator />
            </View>
        );

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{component: "UserSummaryGroupInvite", query: "useCommunityInboxPrivateQuery"}}
                layout="center"
            />
        );
    }

    const conversations = data.getUsersCommunityPrivateConversations;

    if (!conversations.length)
        return (
            <View padding="medium">
                <Text size="medium" align="center">
                    You are not part of any private groups.
                </Text>
            </View>
        );

    return (
        <>
            <Divider />
            <View padding="medium">
                <Stack space="medium">
                    {addUserToGroupError ? (
                        <MutationErrorHandler
                            error={error}
                            meta={{
                                component: "UserSummaryGroupInvite",
                                mutation: "useAddUserToGroupMutation",
                            }}
                        />
                    ) : null}
                    <Text size="medium">
                        Bring fellow students into the groups you are a part of and get to know one
                        another!
                    </Text>
                    {conversations.map((conversation) => (
                        <View
                            key={conversation.id}
                            borderWidth={1}
                            borderRadius="xsmall"
                            borderColor={darkModeEnabled ? "navbarColor" : "grey"}
                            padding="medium"
                        >
                            <Stack space="medium">
                                <Text size="medium" weight="600">
                                    {truncateString(conversation.communityChatTitle, 120)}
                                </Text>
                                <Text size="medium">{conversation.communityChatDescription}</Text>
                                <Button
                                    style={
                                        isPartOfTheGroup(conversation)
                                            ? {
                                                  borderColor: "transparent",
                                              }
                                            : null
                                    }
                                    color={isPartOfTheGroup(conversation) ? "grey" : "primary"}
                                    disabled={
                                        isPartOfTheGroup(conversation) || addUserToGroupLoading
                                    }
                                    block
                                    onClick={() => addMemberToGroup(conversation.id)}
                                >
                                    <Text
                                        size="small"
                                        color={
                                            isPartOfTheGroup(conversation) ? "lightGrey" : "white"
                                        }
                                        style={{
                                            ...boldFontStyles,
                                        }}
                                    >
                                        {isPartOfTheGroup(conversation)
                                            ? "Added to group!"
                                            : "Add to group"}
                                    </Text>
                                </Button>
                            </Stack>
                        </View>
                    ))}
                </Stack>
            </View>
            <Divider />
        </>
    );
};
