import React, {useRef} from "react";
import {ScrollView} from "react-native";
import {Button, Divider, Heading, LoadingButton, Split, Stack, Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {
    PEOPLE_AOS_FILTER,
    PEOPLE_INTERESTS_FILTER,
    PEOPLE_HOUSING_FILTER,
} from "ubcommunity-shared/src/featureFlags";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {CourseSelector} from "./CourseSelector";
import {usePeopleFilters} from "../PeopleFiltersProvider";
import {useGetFilters} from "./useGetFilters";
import {InterestsSelector} from "./InterestsSelector";
import {HousingSelector} from "./Housing/HousingSelector";

const FiltersContainer = () => {
    const {degreesData, degreesLoading, interestsData, interestsLoading} = useGetFilters();

    const isAosFilterEnabled = useIsFeatureEnabledBoolean(PEOPLE_AOS_FILTER);
    const isInterestsFilterEnabled = useIsFeatureEnabledBoolean(PEOPLE_INTERESTS_FILTER);
    const isHousingFilterEnabled = useIsFeatureEnabledBoolean(PEOPLE_HOUSING_FILTER);

    const filtersScrollViewRef = useRef<ScrollView | null>(null);
    const scrollFilterToEnd = () => filtersScrollViewRef?.current?.scrollToEnd({animated: true});

    if (!isAosFilterEnabled && !isInterestsFilterEnabled && !isHousingFilterEnabled) {
        return null;
    }

    if (degreesLoading || interestsLoading) {
        return (
            <View paddingY="large">
                <ActivityIndicator accessibilityLabel="Loading filters" />
            </View>
        );
    }
    return (
        <ScrollView showsHorizontalScrollIndicator={false} ref={filtersScrollViewRef}>
            <Stack space="large">
                {isAosFilterEnabled ? <CourseSelector degreesData={degreesData} /> : null}

                {isInterestsFilterEnabled ? (
                    <InterestsSelector interestsData={interestsData} />
                ) : null}

                {isHousingFilterEnabled ? (
                    <HousingSelector scrollFilterListToEnd={scrollFilterToEnd} />
                ) : null}
            </Stack>
        </ScrollView>
    );
};

export const Filters = () => {
    const {refetch, clearAll, filters, loading} = usePeopleFilters();
    const {selectedCommunity} = useCommunity();
    const {trackEvent} = useAnalytics();

    const handleResults = async () => {
        const res = await refetch({
            filterUsers: {
                communityId: selectedCommunity.id,
                ...filters,
            },
        });

        const resultsCount = res?.data?.communityUserSearch?.totalUsers ?? 0;
        trackEvent(TrackEvents.PEOPLE_SHOW_RESULTS_CLICKED, {
            communityId: selectedCommunity.id,
            resultsCount,
            ...filters,
        });
    };

    return (
        <View flex="1" paddingY="large">
            <Stack space="large">
                <View paddingX="medium">
                    <Split verticalAlign="center">
                        <Heading size="xsmall" level="3">
                            Filter
                        </Heading>
                        <Button link onClick={clearAll}>
                            <Text size="small" color="textLinkColor">
                                Clear all filters
                            </Text>
                        </Button>
                    </Split>
                </View>
                <Divider />
            </Stack>
            <View flex="1" paddingTop="medium" paddingX="medium">
                <View flex="1" gap="small">
                    <FiltersContainer />
                    <View marginTop="auto" flexShrink={0}>
                        <LoadingButton loading={loading} onClick={handleResults} color="primary">
                            <Text color="white">Show results</Text>
                        </LoadingButton>
                    </View>
                </View>
            </View>
        </View>
    );
};
