import {View, Inline, Text} from "@unibuddy/patron";
import React from "react";

import PersonIcon from "ubcommunity-shared/src/Icons/PersonIcon";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

const colors = {
    bgColor: "#f1f1f1",
    textColor: "#404446",
};

const darkModeColors = {
    bgColor: "#6852FB",
    textColor: "#fff",
};

const MemberRoleCountWrapper = ({children, colorMap, ariaLabel}) => (
    <View
        bgColor={colorMap?.bgColor}
        color={colorMap?.textColor}
        paddingX="xxsmall"
        paddingY="xxsmall"
        borderRadius="xsmall"
        accessible
        accessibilityLabel={ariaLabel}
        accessibilityRole="text"
        aria-label={ariaLabel}
    >
        <Inline space="xxsmall" verticalAlign="center" align="center">
            {children}
        </Inline>
    </View>
);

export function MemberRoleCount({membersCount}: {membersCount: number}) {
    const {darkModeEnabled} = useCommunityTheme();
    const colorMap = darkModeEnabled ? darkModeColors : colors;
    return (
        <Inline space="small" verticalAlign="center" wrap="nowrap">
            <MemberRoleCountWrapper
                colorMap={colorMap}
                ariaLabel={`${membersCount} members in this group`}
            >
                <PersonIcon aria-hidden color={colorMap.textColor} size={16} />
                <View justifyContent="center" alignItems="center">
                    <Text size="xxsmall" color={colorMap.textColor}>
                        {membersCount}
                    </Text>
                </View>
            </MemberRoleCountWrapper>
        </Inline>
    );
}
