import {useGetConversationPermissionQuery} from "../types";

export const useIsGroupMember = (conversationId) => {
    const {data, loading} = useGetConversationPermissionQuery({
        variables: {conversationId},
        fetchPolicy: "cache-first",
    });

    if (conversationId && data?.getConversationPermission?.isMember) {
        return {isMember: true, loading};
    }

    return {isMember: false, loading};
};
