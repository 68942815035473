import {PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import {NetworkStatus} from "@apollo/client";
import {Box, Button, Column, Columns, Text, View} from "@unibuddy/patron";
import {ActionSheetRef} from "react-native-actions-sheet";
import {FlatList, Platform} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";

import ActivityIndicator from "../General/ActivityIndicator/ActivityIndicator";
import {QueryErrorHandler} from "../General/Errors/QueryErrorHandler";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";
import {ModalTypes, useModal} from "../General/ModalProvider/ModalProvider";
import {
    UniversityField,
    useGetDiscoverableUniversitiesQuery,
    useSaveUniversityInterestedMutation,
} from "../types";
import {boldFontStyles} from "../Styles/fontStyles";
import {DiscoverableUniversityItem} from "./DiscoverableUniversityItem";
import DiscoverSearchInput from "./DiscoverSearchInput";
import {RequestModal} from "./RequestModal";
import {DiscoverEnterDialog} from "./DiscoverEnterDialog";
import {TrackEvents} from "../constants";
import {FormValues} from "./RequestInstitution";

export const DISCOVER_LIST_LIMIT = 15;

export enum DiscoverStrings {
    LoadingMoreUniversities = "Loading more universities ...",
    SearchInputPlaceholder = "Search to find institutions",
    noResults = "No search results",
    NotFound = "Can't find an institution?",
    SendRequest = "Send a request",
    RequestSent = "Request sent!",
}

const Footer = ({loading}: {loading: boolean}) => {
    if (loading)
        return (
            <View padding="small">
                <ActivityIndicator accessibilityLabel={DiscoverStrings.LoadingMoreUniversities} />
            </View>
        );

    return null;
};

const Wrapper = ({
    children,
    error,
    refetch,
    loading,
}: PropsWithChildren<{
    error: Error | undefined;
    refetch: () => void;
    loading: boolean;
}>) => {
    if (loading) {
        return (
            <View flex="1" alignItems="center" width="full">
                <ActivityIndicator accessibilityLabel={DiscoverStrings.LoadingMoreUniversities} />
            </View>
        );
    }

    if (error) {
        return (
            <View flex="1" alignItems="center" width="full">
                <QueryErrorHandler error={error} retryCallback={refetch} />
            </View>
        );
    }

    return (
        <View flex="1" alignItems="center" width="full">
            {children}
        </View>
    );
};

const EmptyState = () => {
    const {trackEvent} = useAnalytics();

    useEffect(() => {
        trackEvent(TrackEvents.DISCOVER_NO_RESULTS_FOUND);
    }, [trackEvent]);

    return (
        <>
            <View padding="large">
                <Text align="center">{DiscoverStrings.noResults}</Text>
            </View>
            <Box borderBottomWidth={1} borderColor="grey50" />
        </>
    );
};

export const Discover = () => {
    const seed = useRef(Math.floor(Math.random() * 10000));
    const {trackEvent} = useAnalytics();

    useEffect(() => {
        trackEvent(TrackEvents.DISCOVER_SCREEN_VIEWED);
    }, [trackEvent]);

    const {data, loading, error, refetch, fetchMore, networkStatus} =
        useGetDiscoverableUniversitiesQuery({
            variables: {
                limit: DISCOVER_LIST_LIMIT,
                offset: 0,
                randomizeSeed: seed.current,
            },
            notifyOnNetworkStatusChange: true,
        });
    const [saveUniversity] = useSaveUniversityInterestedMutation();
    const [selectedUniversity, setSelectedUniversity] = useState<UniversityField | null>(null);
    const previousItemsCount = useRef(0);
    const {isDesktop} = useIsDesktop();
    const {openModal, dismissModal} = useModal();
    const actionSheetRef = useRef<ActionSheetRef>(null);
    const {reportError} = useErrorReporting();

    const isFetchingNext = networkStatus === NetworkStatus.fetchMore;
    const isLoading =
        loading &&
        networkStatus !== NetworkStatus.fetchMore &&
        networkStatus !== NetworkStatus.refetch;

    const [searchText, setSearchText] = useState("");
    const [requestCta, setRequestCta] = useState(DiscoverStrings.SendRequest);

    const keyExtractor = useCallback(
        (item: UniversityField, index: number) => `item-${item.id}-${index}`,
        [],
    );

    const submitRequest = async ({institution}: FormValues) => {
        setRequestCta(DiscoverStrings.RequestSent);

        try {
            await saveUniversity({
                variables: {
                    universityInterestedDto: {
                        name: institution,
                    },
                },
            });
        } catch (error) {
            reportError(error);
        } finally {
            setTimeout(() => {
                setRequestCta(DiscoverStrings.SendRequest);
            }, 2000);
        }
    };

    const handleSendRequestWeb = (formValues: FormValues) => {
        submitRequest(formValues);
        dismissModal();
    };

    const handleSendRequestNative = (formValues: FormValues) => {
        submitRequest(formValues);
        actionSheetRef.current?.hide();
    };

    const handleShowModal = () => {
        Platform.OS === "web"
            ? openModal({
                  component: (
                      <RequestModal
                          searchText={searchText}
                          handleSubmit={handleSendRequestWeb}
                          handleDismiss={dismissModal}
                      />
                  ),
                  type: isDesktop ? ModalTypes.DIALOG : ModalTypes.BOTTOM_SHEET,
              })
            : actionSheetRef.current?.show();
    };

    const debouncedSearch = useCallback(
        (name: string) => {
            const timeout = setTimeout(() => {
                refetch({offset: 0, limit: DISCOVER_LIST_LIMIT, name});
                previousItemsCount.current = 0;
            }, 500);
            return () => clearTimeout(timeout);
        },
        [refetch],
    );

    useEffect(() => {
        return debouncedSearch(searchText);
    }, [searchText, debouncedSearch]);

    const onEndReached = () => {
        const currentItemsCount = data?.discoverableUniversities?.length ?? 0;

        const difference = currentItemsCount - previousItemsCount.current;

        if (currentItemsCount !== 0 && difference < DISCOVER_LIST_LIMIT) {
            return;
        }

        if (difference === 0) {
            return;
        }

        previousItemsCount.current = currentItemsCount;

        fetchMore({
            variables: {
                offset: data?.discoverableUniversities?.length ?? 0,
                limit: DISCOVER_LIST_LIMIT,
                name: searchText,
            },
        });
    };

    const handleSelection = (item: UniversityField) => {
        trackEvent(TrackEvents.DISCOVER_UNIVERSITY_SELECTED, {
            universityId: item.id,
            universityName: item.name,
            universitySlug: item.slug,
        });
        setSelectedUniversity(item);
    };

    const handleOnClose = () => {
        trackEvent(TrackEvents.DISCOVER_UNIVERSITY_DIALOG_DISMISSED);
        setSelectedUniversity(null);
    };

    return (
        <>
            <View
                flexDirection="column"
                alignItems="center"
                height="100%"
                gap="small"
                position="relative"
            >
                <DiscoverEnterDialog university={selectedUniversity} onClose={handleOnClose} />
                <DiscoverSearchInput
                    searchText={searchText}
                    setSearchText={setSearchText}
                    placeholder={DiscoverStrings.SearchInputPlaceholder}
                />
                <Wrapper
                    error={error}
                    refetch={refetch}
                    loading={isLoading && !data?.discoverableUniversities}
                >
                    <View paddingX="small-medium" width="full">
                        <FlatList
                            keyExtractor={keyExtractor}
                            data={data?.discoverableUniversities ?? []}
                            onEndReached={onEndReached}
                            onEndReachedThreshold={0.5}
                            ListFooterComponent={
                                <>
                                    <Footer loading={isFetchingNext} />
                                    <View paddingY="small-medium">
                                        <Columns verticalAlign="center" space="small">
                                            <Column>
                                                <Text style={{...boldFontStyles}} color="grey800">
                                                    {DiscoverStrings.NotFound}
                                                </Text>
                                            </Column>
                                            <Column width="content">
                                                <Button
                                                    color="primary"
                                                    ghost
                                                    size="sm"
                                                    onClick={handleShowModal}
                                                >
                                                    {requestCta}
                                                </Button>
                                            </Column>
                                        </Columns>
                                    </View>
                                </>
                            }
                            renderItem={({item}) => (
                                <DiscoverableUniversityItem item={item} onPress={handleSelection} />
                            )}
                            contentContainerStyle={{
                                width: isDesktop ? 600 : "100%",
                                alignSelf: "center",
                            }}
                            ListEmptyComponent={!loading ? <EmptyState /> : null}
                        />
                    </View>
                </Wrapper>
            </View>
            {Platform.OS !== "web" && (
                <RequestModal
                    searchText={searchText}
                    handleSubmit={handleSendRequestNative}
                    actionSheetRef={actionSheetRef}
                    handleDismiss={() => actionSheetRef.current?.hide()}
                />
            )}
        </>
    );
};
