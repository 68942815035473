import React, {useRef, useState} from "react";
import {
    Button,
    Column,
    Columns,
    Dialog,
    Heading,
    Inline,
    Split,
    Stack,
    Text,
    View,
    VisuallyHidden,
} from "@unibuddy/patron";
import AvatarEditor from "react-avatar-editor";
import ZoominIcon from "ubcommunity-shared/src/Icons/ZoominIcon";
import ZoomoutIcon from "ubcommunity-shared/src/Icons/ZoomoutIcon";
import {AvatarDisclaimer, ProfilePhotoAvatar} from "./ProfilePhotoAvatar";
import RotateIcon from "ubcommunity-shared/src/Icons/RotateIcon";
import {
    WEB_SCALE_FACTOR,
    WEB_ROTATION_FACTOR,
    PROFILE_AVATAR_SIZE,
    validateImage,
    PROFILE_AVATAR_SIZE_LARGE,
} from "./utils";
import {useProfilePhoto} from "./useProfilePhoto";

export const ProfilePhotoEditor = () => {
    const {user, loading, onUpload, onRemove} = useProfilePhoto();

    const [isOpen, setIsOpen] = useState(false);
    const [isEditor, setIsEditor] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File>(null);
    const [error, setError] = useState<string | null>(null);
    const [editorParams, setEditorParams] = useState({
        width: 250,
        height: 250,
        border: 50,
        color: [255, 255, 255, 0.6],
        scale: 1.2,
        rotate: 0,
    });
    const imageRef = useRef(null);
    const editorRef = useRef(null);

    const onImageConfirm = () => {
        if (!editorRef) return;

        const canvas = editorRef.current.getImageScaledToCanvas();

        canvas.toBlob((blob) => {
            const file = new File([blob], selectedImage.name, {
                type: selectedImage.type,
            });
            if (!validateImage(file)) {
                setError("Image is too big or of invalid format");
                return;
            }
            onUpload(file);
            setIsOpen(false);
            setError(null);
            setIsEditor(false);
        });
    };

    const onImageSelect = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        setSelectedImage(file);
        setIsEditor(true);
        setIsOpen(true);
    };

    const handleRemove = () => {
        onRemove();
        setIsEditor(false);
    };

    const handleZoomIn = () => {
        setEditorParams({
            ...editorParams,
            scale: editorParams.scale + WEB_SCALE_FACTOR,
        });
    };

    const handleZoomOut = () => {
        setEditorParams({
            ...editorParams,
            scale: editorParams.scale - WEB_SCALE_FACTOR,
        });
    };

    const rotateLeft = () => {
        setEditorParams({
            ...editorParams,
            rotate: editorParams.rotate - WEB_ROTATION_FACTOR,
        });
    };

    const rotateRight = () => {
        setEditorParams({
            ...editorParams,
            rotate: editorParams.rotate + WEB_ROTATION_FACTOR,
        });
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsEditor(false);
        setError(null);
        imageRef.current.value = null;
    };

    return (
        <View>
            <ProfilePhotoAvatar
                user={user}
                size={PROFILE_AVATAR_SIZE}
                loading={loading}
                onPress={() => setIsOpen(true)}
            />

            <input
                hidden
                ref={imageRef}
                type="file"
                name="profileImage"
                onChange={onImageSelect}
                accept="image/*"
            />

            <Dialog size="medium" onDismiss={handleCloseModal} isOpen={isOpen}>
                {isEditor ? (
                    <View height="100%" padding="medium" paddingTop="large">
                        <Stack space="large">
                            <View paddingBottom="large">
                                <Heading level="1" size="xsmall">
                                    Set profile picture
                                </Heading>
                            </View>

                            <View width="100%" justifyContent="center" alignItems="center">
                                <View w="100%" alignItems="center">
                                    <Inline space="large">
                                        <Button
                                            iconOnly
                                            round
                                            clear
                                            onClick={handleZoomIn}
                                            aria-label="Zoom in"
                                        >
                                            <ZoominIcon color="grey" />
                                        </Button>
                                        <Button
                                            iconOnly
                                            round
                                            clear
                                            onClick={handleZoomOut}
                                            aria-label="Zoom out"
                                        >
                                            <ZoomoutIcon color="grey" />
                                        </Button>
                                    </Inline>
                                </View>
                                <View margin="small" borderWidth={1} borderColor="grey">
                                    <AvatarEditor
                                        role="img"
                                        alt="Your selected profile picture"
                                        aria-label="Your selected profile picture"
                                        ref={editorRef}
                                        image={selectedImage}
                                        {...editorParams}
                                    />
                                    <VisuallyHidden>
                                        Use this space to add or edit a profile photo. You can
                                        position the image, zoom using the buttons above the image
                                        or rotate using the buttons below
                                    </VisuallyHidden>
                                </View>
                                <View w="100%" alignItems="center">
                                    <Inline space="large">
                                        <Button
                                            iconOnly
                                            round
                                            clear
                                            onClick={rotateLeft}
                                            aria-label="Rotate left"
                                        >
                                            <RotateIcon
                                                style={{transform: "scale(1, -1) rotate(180deg)"}}
                                                color="grey"
                                            />
                                        </Button>
                                        <Button
                                            iconOnly
                                            round
                                            clear
                                            onClick={rotateRight}
                                            aria-label="Rotate right"
                                        >
                                            <RotateIcon color="grey" />
                                        </Button>
                                    </Inline>
                                </View>
                            </View>

                            <View>
                                {error ? (
                                    <Text align="center" color="red" size="small">
                                        {error}
                                    </Text>
                                ) : null}
                            </View>

                            <View paddingTop="small">
                                <Split>
                                    <Button onClick={handleCloseModal} clear>
                                        Cancel
                                    </Button>
                                    <Button onClick={onImageConfirm} color="primary">
                                        Update
                                    </Button>
                                </Split>
                            </View>
                        </Stack>
                    </View>
                ) : (
                    <View height="100%" padding="medium" paddingY="large">
                        <Stack space="large">
                            <Heading level="1" size="xsmall">
                                Change your profile photo
                            </Heading>
                            <AvatarDisclaimer />

                            <>
                                <View paddingBottom="large" alignItems="center">
                                    <ProfilePhotoAvatar
                                        hideEditIcon
                                        user={user}
                                        size={PROFILE_AVATAR_SIZE_LARGE}
                                        loading={loading}
                                        onPress={() => imageRef.current.click()}
                                    />
                                </View>

                                <Columns
                                    collapseBelow="tablet"
                                    space="large"
                                    verticalAlign="center"
                                >
                                    <Column>
                                        <Button block ghost onClick={handleRemove}>
                                            <Text size="medium">Remove Image</Text>
                                        </Button>
                                    </Column>
                                    <Column>
                                        <Button
                                            block
                                            color="primary"
                                            onClick={() => imageRef.current.click()}
                                        >
                                            <Text color="white" size="medium">
                                                Change Image
                                            </Text>
                                        </Button>
                                    </Column>
                                </Columns>
                            </>
                        </Stack>
                    </View>
                )}
            </Dialog>
        </View>
    );
};
