import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20.64 5.482l-.053-.433-8.268-3.006L12.195 2h-.252L3.558 5.049l-.054.433c-.015.12-1.382 12 8.266 16.452l.142.066h.319l.143-.066c9.643-4.453 8.281-16.333 8.266-16.452zm-8.569 15.014C4.563 16.806 4.73 8.144 4.881 6.088l7.19-2.614 7.191 2.614c.152 2.057.315 10.718-7.19 14.408zm3.57-9.925h-.713V9.143c0-2.097-1.71-2.857-2.857-2.857v.38l-.002-.38c-1.148 0-2.857.76-2.857 2.857v1.428h-.714a.715.715 0 00-.714.715v4.285c0 .395.32.715.714.715h7.143c.395 0 .714-.32.714-.715v-4.285a.714.714 0 00-.714-.715zm-5-1.428c0-1.303 1.094-1.423 1.432-1.429.242 0 1.426.072 1.426 1.429v1.428h-2.858V9.143zm4.286 5.714H9.212V12h5.715v2.857z"
                fill="#404446"
            />
        </svg>
    );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
