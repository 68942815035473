import React, {PropsWithChildren} from "react";
import {
    ConversationType,
    useConversation,
} from "ubcommunity-shared/src/Conversation/useConversation";
import {useGetConversationId} from "ubcommunity-shared/src/Conversation/useGetConversationId";
import {ChatInput} from "../ChatInput/ChatInput";
import {useIsUserBlock} from "../useIsUserBlock";
import {SenderBlockedState} from "./SenderBlockedState";

type Props = {
    // TODO: infer the information required to unblock users when getting rid of props
    // the difference arises with a modal opening on desktop and a new screen on native
    // useIsUserBlock can expose a method that handles all this centrally
    handleUserUnblock: () => void;
};

export const ChatFooterWrapper = ({children, handleUserUnblock}: PropsWithChildren<Props>) => {
    const {conversationType, loading} = useConversation();
    const {id} = useGetConversationId();
    const [isBlocked] = useIsUserBlock({conversationId: id});

    const isWidgetConversation = conversationType === ConversationType.WIDGET;

    if (loading) {
        return null;
    }

    if (isWidgetConversation && isBlocked) {
        return <SenderBlockedState handleUserUnblock={handleUserUnblock} />;
    }

    if (isWidgetConversation) {
        return <ChatInput conversationId={id} />;
    }

    return children;
};
