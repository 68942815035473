import {useApolloClient} from "@apollo/client";
import {ChatMessage, LastMessageFragmentFragment, LastMessageFragmentFragmentDoc} from "../types";
import {useSocketListener} from "../Sockets/SocketProvider";
import {sendMessageEvent} from "../Chat/useChat";
import useAuth from "../Auth/hooks/useAuth";

export const LAST_MESSAGE_FRAGMENT = LastMessageFragmentFragmentDoc;

type Props = {
    conversationId: string;
    isActive?: boolean;
};

export const useUnreadMessageListener = ({conversationId, isActive}: Props) => {
    const client = useApolloClient();
    const {user} = useAuth();

    const onNewMessage = (message: ChatMessage) => {
        client.writeFragment<LastMessageFragmentFragment>({
            fragment: LAST_MESSAGE_FRAGMENT,
            id: `ChatConversation:${conversationId}`,
            data: {
                id: conversationId,
                unreadMessageCount: isActive || message.sender.id === user?.id ? 0 : 1,
                lastMessage: {
                    __typename: "ChatMessage",
                    id: message.id,
                    created: message.created,
                    text: message.text,
                    sender: {
                        __typename: "UserField",
                        firstName: message.sender.firstName,
                        id: message.sender.id,
                    },
                },
            },
        });
    };
    useSocketListener(sendMessageEvent, conversationId, onNewMessage);
};
