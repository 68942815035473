import React from "react";
import {View} from "@unibuddy/patron";
import {ChatFooter, ChatInput as ChatUiChatInput} from "@unibuddy/chat-ui";
import {useChat} from "../useChat";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

type InputProps = {
    conversationId: string;
};

export function ChatInput({conversationId}: InputProps) {
    const {user} = useAuth();
    const {onSend} = useChat(conversationId, user);

    const handleSend = (text: string) => {
        onSend({text});
    };

    return (
        <View borderTopWidth={1} borderColor="grey100">
            <ChatFooter>
                <ChatUiChatInput onSend={handleSend} />
            </ChatFooter>
        </View>
    );
}
