import {useCallback} from "react";
import {useLocation} from "react-router-dom";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";

export type NavigateParams = {
    path: string;
    options?: Record<string, any>;
};

export const useNavigate = () => {
    const {push} = useRelativeRouter();
    const {search} = useLocation();

    const navigate = useCallback(
        ({path, options}: NavigateParams) => {
            if (options) {
                const keys = Object.keys(options);
                const values = Object.values(options);

                const query = keys
                    .map((key, index) => {
                        return `${key}=${values[index]}`;
                    })
                    .join("&");

                push(`${path}?${query.toLowerCase()}`);
                return;
            }

            push(path);
        },
        [push],
    );

    const parse = useCallback(() => {
        const searchParams = new URLSearchParams(search);
        const obj: Record<string, string> = {};

        for (const [key, value] of searchParams) {
            obj[key] = value;
        }

        return obj;
    }, [search]);

    return {
        navigate,
        parse,
    };
};
