import React from "react";
import {View, Stack} from "@unibuddy/patron";
import {Rect} from "react-native-svg";

import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {Shimmer} from "ubcommunity-shared/src/General/Shimmer/Shimmer";

import {ShimmerListMember} from "../Chat/ChatDrawer/FullMembersList/ShimmerLoader";
import ActivityIndicator from "../General/ActivityIndicator/ActivityIndicator";

export const ListFooter = ({loading}: {loading: boolean}) => {
    return loading ? (
        <View padding="small">
            <ActivityIndicator accessibilityLabel="Loading more users ..." />
        </View>
    ) : null;
};

export const PeopleLoading = React.memo(({isDmsEnabled = false}: {isDmsEnabled?: boolean}) => {
    const {isDesktop} = useIsDesktop();
    if (isDesktop)
        return (
            <View alignItems="center">
                <View padding="small" marginLeft="xlarge">
                    <Stack space="xlarge">
                        <Stack space="medium">
                            <Shimmer width={700} height={370}>
                                <Rect x="0" y="0" rx="12" ry="12" width="200" height="370" />
                                <Rect x="230" y="0" rx="12" ry="12" width="200" height="370" />
                                <Rect x="460" y="0" rx="12" ry="12" width="200" height="370" />
                            </Shimmer>
                        </Stack>

                        <Stack space="medium">
                            <Shimmer width={700} height={370}>
                                <Rect x="0" y="0" rx="12" ry="12" width="200" height="370" />
                                <Rect x="230" y="0" rx="12" ry="12" width="200" height="370" />
                                <Rect x="460" y="0" rx="12" ry="12" width="200" height="370" />
                            </Shimmer>
                        </Stack>
                    </Stack>
                </View>
            </View>
        );
    return (
        <>
            {Array(5)
                .fill(0)
                .map((_, i) => (
                    <View key={i} padding={"small-medium"}>
                        <ShimmerListMember isDmsEnabled={isDmsEnabled} />
                    </View>
                ))}
        </>
    );
});
