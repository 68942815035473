import {Form, FormGroup, TextArea, Text, Stack} from "@unibuddy/patron";

import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

import {QuestionProps} from "./types";
import {CTAWrapper} from "../../General/CTAWrapper";
import {CTAFooter} from "../../General/CTAFooter";

/**
 * ENROLMENT SURVEY
 * Question (2 of 2) - if answered No to question 1 and answered question 3:
 * What was the main reason for your decision?
 */
export const Question4 = ({data, error, handleSubmit, handleDismiss}: QuestionProps) => (
    <Form initialValues={{[data.id]: ""}} onSubmit={handleSubmit}>
        <CTAWrapper
            tagName="Survey"
            handleDismiss={handleDismiss}
            footer={<CTAFooter footerText="" buttonText="Submit" isDisabled={false} />}
        >
            <Stack space="medium">
                <Text style={{...boldFontStyles}} size="large" color="grey900">
                    {data.text}
                </Text>
                <FormGroup>
                    <TextArea
                        name={data.id}
                        rows={4}
                        style={{
                            borderRadius: 5,
                            backgroundColor: "white",
                            resize: "vertical",
                        }}
                        aria-label={data.text}
                        accessibilityLabel={data.text}
                    />
                </FormGroup>
            </Stack>

            {error ? (
                <MutationErrorHandler
                    error={error}
                    message={`Oh no! Something went wrong. Please try again later.`}
                    meta={{
                        component: "StudentEnrolledSurveyCTA.Q4",
                        mutation: "useSaveUniversityQuestionAnswerMutation",
                    }}
                />
            ) : null}
        </CTAWrapper>
    </Form>
);
