import {Platform} from "react-native";

export const boldFontStyles =
    Platform.select({
        android: {
            fontFamily: "Nunito Bold",
        },
        ios: {
            fontWeight: "bold",
        },
        web: {
            fontWeight: "bold",
        },
    }) || {};

export const semiBoldFontStyles =
    Platform.select({
        android: {
            fontFamily: "Nunito SemiBold",
        },
        ios: {
            fontWeight: "600",
        },
        web: {
            fontWeight: "600",
        },
    }) || {};
