import React from "react";
import styled, {keyframes} from "styled-components";
import {View, Box} from "@unibuddy/patron";

const EmojiTouchable = styled.button`
    background-color: transparent;
    border: none;
    position: relative;
    width: 60px;
    padding: 5px;
    height: 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 50px;
    border-radius: 999px;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: #cfc8ff63;
    }
`;

export const EmojiButton = ({
    value,
    rating,
    onPress,
    children,
}: {
    value: number;
    rating: number;
    onPress: () => void;
    children: React.ReactNode;
}) => {
    return (
        <View alignItems="center" flex={1}>
            <EmojiTouchable onClick={onPress}>
                <Box
                    position="absolute"
                    top={0}
                    bottom={0}
                    right={0}
                    left={0}
                    borderRadius="full"
                    bgColor="#CFC8FF"
                    transform={rating === value ? "scale(1)" : "scale(0)"}
                    transition="transform 0.2s ease-in-out"
                    zIndex={-1}
                ></Box>
                {children}
            </EmojiTouchable>
        </View>
    );
};

export const FeedbackAnimatedContainer = ({children}) => {
    return (
        <View paddingY="medium" paddingX="large" paddingBottom="xlarge">
            {children}
        </View>
    );
};

const scaleIn = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }

`;

export const FeedbackSuccessAnimatedContainer = styled.div`
    animation: ${scaleIn} 0.2s ease;
`;
