import {useCallback} from "react";
import {NetworkStatus} from "@apollo/client";
import {useSequenceFilterMentorListQuery} from "ubcommunity-shared/src/types";
import {
    SpaceType,
    useSpaces,
} from "ubcommunity-shared/src/StudentApp/SpacesProvider/SpacesProvider";

export const AMBASSADOR_LIST_LIMIT = 15;
export const MENTOR_SEED = 1729572053;

export const useMentorList = () => {
    const {selectedSpace, loading: spaceLoading} = useSpaces();
    const {data, networkStatus, loading, error, refetch, fetchMore} =
        useSequenceFilterMentorListQuery({
            variables: {
                universitySlug: selectedSpace?.slug,
                limit: AMBASSADOR_LIST_LIMIT,
                offset: 0,
                mentorSeed: MENTOR_SEED,
            },
            notifyOnNetworkStatusChange: true,
            skip: !selectedSpace || selectedSpace.type !== SpaceType.UNIVERSITY || spaceLoading,
        });

    const isLoading = loading && networkStatus !== NetworkStatus.fetchMore;
    const isFetchingNext = networkStatus === NetworkStatus.fetchMore;

    const fetchNext = useCallback(() => {
        fetchMore({
            variables: {
                offset: data?.sequenceFilterMentorList?.mentors?.length ?? 0,
                limit: AMBASSADOR_LIST_LIMIT,
            },
        });
    }, [fetchMore, data?.sequenceFilterMentorList?.mentors?.length]);

    return {
        loading: isLoading,
        fetchNextLoading: isFetchingNext,
        error,
        data: data?.sequenceFilterMentorList?.mentors ?? [],
        refetch,
        fetchNext,
    };
};
