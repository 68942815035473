import {useApolloClient} from "@apollo/client";
import {
    PublicMentorFieldProfileCountryFragment,
    PublicMentorFieldProfileCountryFragmentDoc,
    PublicMentorFieldProfileDataFragment,
    PublicMentorFieldProfileDataFragmentDoc,
    UserFieldProfileCountryFragment,
    UserFieldProfileCountryFragmentDoc,
    UserFieldProfileDataFragment,
    UserFieldProfileDataFragmentDoc,
    PublicMentorFieldFlagsFragment,
    PublicMentorFieldFlagsFragmentDoc,
    PublicBuddyFieldProfileDataFragment,
    PublicBuddyFieldProfileDataFragmentDoc,
    PublicStaffFieldsFragment,
    PublicStaffFieldsFragmentDoc,
} from "ubcommunity-shared/src/types";

/**
 * This hooks is used to get partial user data from cache
 * based on either UserField or PublicMentorField, whichever is available
 * @param id
 * @returns { userData, countryData, mentorFlagsData}
 */
export const useGetPartialUserSummaryData = (id: string) => {
    const client = useApolloClient();

    const partialUserData = {
        ...client.readFragment<UserFieldProfileDataFragment>({
            id: `UserField:${id}`,
            fragment: UserFieldProfileDataFragmentDoc,
        }),
        ...client.readFragment<PublicMentorFieldProfileDataFragment>({
            id: `PublicMentorField:${id}`,
            fragment: PublicMentorFieldProfileDataFragmentDoc,
        }),
        ...client.readFragment<PublicBuddyFieldProfileDataFragment>({
            id: `BuddyField:${id}`,
            fragment: PublicBuddyFieldProfileDataFragmentDoc,
        }),
    };

    const partialCountryData = {
        ...client.readFragment<UserFieldProfileCountryFragment>({
            id: `UserField:${id}`,
            fragment: UserFieldProfileCountryFragmentDoc,
        }),
        ...client.readFragment<PublicMentorFieldProfileCountryFragment>({
            id: `PublicMentorField:${id}`,
            fragment: PublicMentorFieldProfileCountryFragmentDoc,
        }),
    };

    const partialMentorflagData = client.readFragment<PublicMentorFieldFlagsFragment>({
        id: `PublicMentorField:${id}`,
        fragment: PublicMentorFieldFlagsFragmentDoc,
    });

    const partialStaffFields = client.readFragment<PublicStaffFieldsFragment>({
        id: `BuddyField:${id}`,
        fragment: PublicStaffFieldsFragmentDoc,
    });

    return {
        userData: partialUserData,
        countryData: partialCountryData,
        mentorFlagsData: partialMentorflagData,
        staffFields: partialStaffFields,
    };
};
