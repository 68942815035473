import {Button, Column, Text, Dialog, View, Box, Stack, Columns} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {TrackEvents} from "ubcommunity-shared/src/constants";

import AppStore from "ubcommunity-web/src/General/MobileAppDownloadPrompt/images/app-store-badge.png";
import PlayStore from "ubcommunity-web/src/General/MobileAppDownloadPrompt/images/google-play-badge.png";
import PlayStoreQr from "ubcommunity-web/src/General/MobileAppDownloadPrompt/images/play-store-qr-community.png";
import AppStoreQr from "ubcommunity-web/src/General/MobileAppDownloadPrompt/images/app-store-qr-community.png";

export const ANDROID_APP_LINK =
    "https://play.google.com/store/apps/details?id=com.unibuddy.community&hl=en";
export const IOS_APP_LINK = "https://apps.apple.com/in/app/unibuddy-community/id1548743285";

export const MobileAppDownloadDialog = ({
    isOpen,
    onDismiss,
}: {
    isOpen: boolean;
    onDismiss: () => void;
}) => {
    const {trackEvent} = useAnalytics();

    return (
        <Dialog isOpen={isOpen} onDismiss={onDismiss} size="small">
            <View padding="large" paddingTop="xlarge">
                <Box paddingTop="medium">
                    <Stack space="medium">
                        <Text size="large" align="center" weight="700">
                            Get the Unibuddy app
                        </Text>

                        <Text align="center">Scan the QR code to get the app</Text>

                        <Box marginY="large">
                            <Columns>
                                <Column>
                                    <Box borderRightWidth={1} borderColor="#F0F2F2">
                                        <Stack space="large">
                                            <View alignItems="center">
                                                <Button
                                                    clear
                                                    onClick={() => {
                                                        trackEvent(
                                                            TrackEvents.APP_INSTALL_BANNER_CLICKED,
                                                        );

                                                        window.open(
                                                            IOS_APP_LINK,
                                                            "_blank",
                                                            "noopener",
                                                        );
                                                    }}
                                                    style={{height: 50, width: 130, padding: 0}}
                                                >
                                                    <Box as="img" src={AppStore} alt="App store" />
                                                </Button>
                                            </View>
                                            <View alignItems="center">
                                                <Box
                                                    as="img"
                                                    src={AppStoreQr}
                                                    alt="Unibuddy ios app"
                                                    w="85%"
                                                />
                                            </View>
                                        </Stack>
                                    </Box>
                                </Column>
                                <Column>
                                    <Box>
                                        <Stack space="large">
                                            <View alignItems="center">
                                                <Button
                                                    clear
                                                    onClick={() => {
                                                        trackEvent(
                                                            TrackEvents.APP_INSTALL_BANNER_CLICKED,
                                                        );
                                                        window.open(
                                                            ANDROID_APP_LINK,
                                                            "_blank",
                                                            "noopener",
                                                        );
                                                    }}
                                                    style={{height: 50, width: 130, padding: 0}}
                                                >
                                                    <Box
                                                        as="img"
                                                        src={PlayStore}
                                                        alt="Google play store"
                                                        height="40px"
                                                    />
                                                </Button>
                                            </View>
                                            <View alignItems="center">
                                                <Box
                                                    as="img"
                                                    src={PlayStoreQr}
                                                    alt="Unibuddy android app"
                                                    w="85%"
                                                />
                                            </View>
                                        </Stack>
                                    </Box>
                                </Column>
                            </Columns>
                        </Box>
                    </Stack>
                </Box>
            </View>
        </Dialog>
    );
};
