import {Form} from "@unibuddy/patron";

import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";

import {QuestionProps} from "./types";
import {CTAWrapper} from "../../General/CTAWrapper";
import {CTAFooter} from "../../General/CTAFooter";
import {SurveyQuestionLabel} from "./Question1";
import {DID_COMMUNITY_HELPED_FIELD_NAME} from "../StudentEnrolledQuestionsCTA";
import {Rating} from "../../StudentConfidenceSurvey/Rating/Rating";

/**
 * ENROLMENT SURVEY
 * Question (2 of 2) - if answered Yes to question 1 and DID_COMMUNITY_HELPED_QUESTION feature flag enabled:
 * Did Community (this app) make you more confident in your decision to enroll?
 */
export const Question5 = ({data, error, handleSubmit, handleDismiss}: QuestionProps) => (
    <Form initialValues={{[data.id]: ""}} onSubmit={handleSubmit}>
        {({values}) => (
            <CTAWrapper
                tagName="Survey"
                handleDismiss={handleDismiss}
                footer={
                    <CTAFooter
                        footerText="Question 2 of 2"
                        buttonText="Submit"
                        isDisabled={!values[DID_COMMUNITY_HELPED_FIELD_NAME]}
                    />
                }
            >
                <Rating
                    name={DID_COMMUNITY_HELPED_FIELD_NAME}
                    options={data.options}
                    label={<SurveyQuestionLabel>{data?.text}</SurveyQuestionLabel>}
                />
                {error ? (
                    <MutationErrorHandler
                        error={error}
                        message={`Oh no! Something went wrong. Please try again later.`}
                        meta={{
                            component: "Question5",
                            mutation: "useSaveUniversityQuestionAnswerMutation",
                        }}
                    />
                ) : null}
            </CTAWrapper>
        )}
    </Form>
);
