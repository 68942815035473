import React from "react";
import {View} from "@unibuddy/patron";
import styled, {keyframes} from "styled-components";

export const AnimatedChatTitleContainer = ({isLoaded, children}) => {
    return (
        <View transform={isLoaded > 0 ? "none" : "translateY(29%)"} transition=".4s ease transform">
            {children}
        </View>
    );
};

const Count = styled.div`
    animation: ${keyframes`
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    `} 0.4s ease;
`;

export const AnimatedChatMemberCount = ({children}) => {
    return <Count>{children}</Count>;
};
