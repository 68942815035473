import React from "react";

import {Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {useGetAllDegreesQuery} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {AutocompleteModal} from "ubcommunity-shared/src/General/AutocompleteModal/AutocompleteModal";

type EditCourseProps = {
    universitySlug?: string;
};

export const EditCourse = ({universitySlug}: EditCourseProps) => {
    const {university} = useCommunity();
    const {trackEvent} = useAnalytics();
    const {data} = useGetAllDegreesQuery({
        variables: {
            universitySlug: university?.slug || universitySlug,
        },
    });

    const handleOnSelect = (courseSelected) => {
        if (courseSelected.length) {
            const areaOfStudyId = courseSelected[0].id;
            trackEvent(TrackEvents.AREA_OF_STUDY_SELECTED, {
                areaOfStudyId,
            });
        }
    };

    const handleOnDropdownOpened = () => {
        trackEvent(TrackEvents.AREA_OF_STUDY_SELECT_OPEN);
    };

    return (
        <AutocompleteModal
            name="selectCourse"
            label="I am interested in studying"
            data={data?.allDegrees ?? []}
            renderItem={(item) => <Text size="medium">{item.name}</Text>}
            resultKey="name"
            onSelect={handleOnSelect}
            onDropdownOpened={handleOnDropdownOpened}
            showResultsOnRender
            required
        />
    );
};
