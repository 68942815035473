import {useContext, useEffect, useState} from "react";
import {Channel} from "pusher-js";
import {SocketChannel, SocketContext} from "../SocketProvider";

export function useSocketChannel(socketChannel: SocketChannel, channelId: string) {
    const {socket, ready} = useContext(SocketContext);
    const [channel, setChannel] = useState<Channel>(null);
    useEffect(() => {
        if (ready && channelId) {
            const pusherChannel = socket.subscribeChannel(socketChannel, channelId);
            const channelAlreadySubscribed =
                pusherChannel.subscribed && !pusherChannel.subscriptionPending;

            if (channelAlreadySubscribed) {
                setChannel(pusherChannel);
                return;
            }
            const onSub = () => {
                setChannel(pusherChannel);
            };

            pusherChannel.bind("pusher:subscription_succeeded", onSub);

            return () => {
                if (!pusherChannel) return;
                pusherChannel.unbind_all();
                pusherChannel.unsubscribe();
            };
        }
    }, [channelId, ready, socket, socketChannel]);

    return channel;
}
