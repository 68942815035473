import React from "react";
import {Box, Heading, Inline, View} from "@unibuddy/patron";

import {CommunityChatGroup} from "ubcommunity-shared/src/types";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {CreateGroupSection} from "ubcommunity-shared/src/Community/CreateGroup/CreateGroupSection";

import {Page, PageBody, PageHeader} from "../../Layout/Page/Page";
import {BackButton} from "../../Layout/BackButton/BackButton";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";

type ChatGroup = Pick<CommunityChatGroup, "id" | "title" | "description" | "isPrivate">;

export const CreateGroupPage = () => {
    const {push} = useRelativeRouter();

    usePageTitle(PageTitles.CREATE_GROUP);

    const handlePrivateGroupSuccess = React.useCallback(
        ({id, title, description, isPrivate}: ChatGroup) => {
            push(`/chat/${id}`, {title, description, isPrivate});
        },
        [push],
    );

    const handlePublicGroupSuccess = React.useCallback(() => {
        push("/chat");
    }, [push]);

    return (
        <Page>
            <PageHeader>
                <Inline space="small" verticalAlign="center" wrap="nowrap">
                    <BackButton to="/chat" />
                    <Heading level="1" size="small" weight="400">
                        Back
                    </Heading>
                </Inline>
            </PageHeader>
            <PageBody>
                <View overflow="auto" width="100%">
                    <Box paddingX="small" paddingY="large" maxW={650}>
                        <CreateGroupSection
                            handlePrivateGroupSuccess={handlePrivateGroupSuccess}
                            handlePublicGroupSuccess={handlePublicGroupSuccess}
                        />
                    </Box>
                </View>
            </PageBody>
        </Page>
    );
};
