import React from "react";
import {Stack, Text} from "@unibuddy/patron";
import {Link} from "ubcommunity-shared/src/General/Link/Link";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";

export const SecurityAndPrivacy = ({university}) => {
    usePageTitle(PageTitles.SECURITY_AND_PRIVACY);

    return (
        <Stack space="large">
            <Link external href="https://unibuddy.com/privacy-policy">
                <Text color="primaryColor">Unibuddy Privacy Policy</Text>
            </Link>
            <Link external href={university?.privacyPolicyUrl}>
                <Text color="primaryColor">Institution Privacy Policy</Text>
            </Link>
            <Link external href="https://unibuddy.com/terms-of-use">
                <Text color="primaryColor">Terms & Conditions</Text>
            </Link>
            <Link external href="mailto:contact@unibuddy.com">
                <Text color="primaryColor">Contact Unibuddy</Text>
            </Link>
        </Stack>
    );
};
