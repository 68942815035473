import {useCallback, useRef} from "react";
import {FlatList} from "react-native";
import {Text, View} from "@unibuddy/patron";
import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";

import {useCommunityInboxQuery, UniversalInboxConversationFragment, Product} from "../types";
import ActivityIndicator from "../General/ActivityIndicator/ActivityIndicator";
import {
    compareNewsFeedAndLastMessage,
    InboxListFooter,
    UNIVERSAL_INBOX_LIMIT,
    UniversalInboxProps,
} from "../UniversalInbox/UniversalInbox";
import {NewsFeedUniversalInboxItem} from "../UniversalInbox/NewsFeedUniversalInboxItem";
import {CommunityUniversalInboxItem} from "../UniversalInbox/CommunityUniversalInboxItem";
import {QueryErrorHandler} from "../General/Errors/QueryErrorHandler";

export enum AdminInboxStrings {
    LoadingConversations = "Loading conversations",
    CommunityNotSelected = "Please select a community to show conversations",
    NoConversations = "There are no conversation to show",
    SelectCommunityInbox = "Select Community",
    JoiningCommunity = "Joining community",
    InboxError = "There was an error loading the inbox",
    JoinCommunityError = "There was an error joining the community! Please try again later.",
}

export const AdminInbox = ({onSelect, activeConversationId}: UniversalInboxProps) => {
    const {selectedCommunity} = useCommunity();
    const previousItemsCount = useRef(0);

    const {data, loading, fetchMore, error, refetch} = useCommunityInboxQuery({
        errorPolicy: "all",
        fetchPolicy: "cache-and-network",
        skip: !selectedCommunity,
        variables: {
            communityId: selectedCommunity?.id ?? "",
            offsetPagination: {
                limit: UNIVERSAL_INBOX_LIMIT,
                offset: 0,
            },
        },
    });

    const keyExtractor = useCallback((item: {id: string}) => `item-${item.id}`, []);

    const onEndReached = () => {
        const currentItems = data?.getUsersCommunityConversations ?? [];
        const currentItemsCount = currentItems.length;

        const difference = currentItemsCount - previousItemsCount.current;
        if (currentItemsCount !== 0 && difference < UNIVERSAL_INBOX_LIMIT) {
            return;
        }

        if (difference === 0) {
            return;
        }

        previousItemsCount.current = currentItemsCount;

        fetchMore({
            variables: {
                offsetPagination: {
                    limit: UNIVERSAL_INBOX_LIMIT,
                    offset: currentItemsCount,
                },
            },
        });
    };

    const renderItem = ({item}: {item: UniversalInboxConversationFragment}) => {
        const props = {
            item,
            onSelect,
            activeConversationId,
        };

        if (item.isNewsFeed) {
            return <NewsFeedUniversalInboxItem {...props} />;
        }

        return <CommunityUniversalInboxItem {...props} />;
    };

    if (loading) {
        return (
            <View paddingTop="large">
                <ActivityIndicator accessibilityLabel={AdminInboxStrings.LoadingConversations} />
            </View>
        );
    }

    const sortedInboxData = [...(data?.getUsersCommunityConversations ?? [])].sort(
        compareNewsFeedAndLastMessage,
    );

    const newsFeedGroupId = selectedCommunity?.newsFeedGroups
        ? selectedCommunity?.newsFeedGroups[0]?.conversationId
        : null;
    const updatedInbox = [...sortedInboxData];
    if (newsFeedGroupId) {
        updatedInbox.unshift({
            id: newsFeedGroupId,
            isNewsFeed: true,
            lastMessage: null,
            product: Product.Community,
            mutedBy: [],
            university: selectedCommunity.institutions?.length
                ? selectedCommunity.institutions[0]
                : {},
        });
    }

    const EmptyComponent = () => {
        if (loading) {
            return null;
        }

        if (!selectedCommunity) {
            return (
                <View paddingTop="large">
                    <Text align="center">{AdminInboxStrings.CommunityNotSelected}</Text>
                </View>
            );
        }

        return (
            <View paddingTop="large">
                <Text align="center">{AdminInboxStrings.NoConversations}</Text>
            </View>
        );
    };

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                errorMessage={AdminInboxStrings.InboxError}
                retryCallback={refetch}
                meta={{
                    component: "AdminInbox",
                    query: "useCommunityInboxQuery",
                }}
            />
        );
    }

    return (
        <FlatList<UniversalInboxConversationFragment>
            data={updatedInbox}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReached={onEndReached}
            onEndReachedThreshold={0.5}
            ListEmptyComponent={EmptyComponent}
            ListFooterComponent={<InboxListFooter loading={loading} />}
        />
    );
};
