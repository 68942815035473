import {Pressable} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {Column, Columns, Text, TextLine, usePatronTheme, View} from "@unibuddy/patron";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {useIsStudentAppExperience} from "ubcommunity-shared/src/Hooks/useIsStudentAppExperience";
import {MatchFilters} from "./MatchesComponent";
import {semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

type MatchesHeaderProps = {
    title: string;
    name: string;
    filter: MatchFilters;
    handleViewMatches: (filter: MatchFilters) => void;
    onEditPressed?: () => void;
};

export const MatchesHeader = ({
    title,
    name,
    filter,
    handleViewMatches,
    onEditPressed = () => {},
}: MatchesHeaderProps) => {
    const {trackEvent} = useAnalytics();
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();

    const {loading} = useIsStudentAppExperience();

    const handleEditPressed = () => {
        trackEvent(TrackEvents.EXPLORE_PAGE_EDIT_MATCHES_CLICKED, {
            name,
        });
        onEditPressed();
    };

    return (
        <View w="100%" paddingLeft="small-medium" paddingBottom="xsmall" flexGrow={1}>
            <Columns verticalAlign="center">
                <Column>
                    <Pressable
                        accessibilityRole="button"
                        style={{
                            flexGrow: 1,
                        }}
                        onPress={() => {
                            trackEvent(TrackEvents.VIEW_MORE_CARD_CLICKED, {
                                source: "Explore page",
                            });
                            handleViewMatches(filter);
                        }}
                    >
                        <View paddingRight="small-medium">
                            <Text weight="400">
                                {title}{" "}
                                <TextLine
                                    weight="700"
                                    color={
                                        darkModeEnabled
                                            ? theme?.colors?.textLinkColor
                                            : theme?.colors?.primaryColor
                                    }
                                >
                                    {name}
                                </TextLine>
                            </Text>
                        </View>
                    </Pressable>
                </Column>
                {!loading ? (
                    <Column width="content">
                        <Pressable
                            onPress={handleEditPressed}
                            accessibilityRole="button"
                            accessibilityLabel={`Edit ${name}`}
                        >
                            <Text
                                color="blue600"
                                style={{
                                    ...semiBoldFontStyles,
                                }}
                            >
                                Edit
                            </Text>
                        </Pressable>
                    </Column>
                ) : null}
            </Columns>
        </View>
    );
};
