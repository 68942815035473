import useAuth from "../Auth/hooks/useAuth";
import {useGetAccountInformationQuery} from "../types";

export const useUserProfile = () => {
    const {user} = useAuth();
    const {data} = useGetAccountInformationQuery();

    const userProfile = {
        ...user,
        bio: data?.getUserProfile?.bio,
        pronouns: data?.getUserProfile?.pronouns,
        interests: data?.getInterestsForUser,
    };

    const requiredValues = [
        userProfile.firstName,
        userProfile.lastName,
        userProfile.country,
        userProfile.bio,
        userProfile.pronouns,
        userProfile.interests,
    ];

    const POINTS_PER_VALUE = 100 / requiredValues.length;

    const profileCompletion = Math.floor(
        requiredValues.reduce((acc, value) => {
            if (Array.isArray(value) && value.length > 0) {
                return acc + POINTS_PER_VALUE;
            }
            return value ? acc + POINTS_PER_VALUE : acc;
        }, 0),
    );

    return {
        isUserProfileComplete: profileCompletion === 100,
        userProfile,
        profileCompletion,
    };
};
