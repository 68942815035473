import React from "react";
import {Pressable} from "react-native";
import styled from "styled-components";
import {Column, Columns, Stack, Text, View} from "@unibuddy/patron";

import {Attachment} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

import {convertToMb} from "../useAttachments";
import PdfIcon from "./PdfIcon";
import {DownloadButton} from "./FileDownloadButton";

type Props = {
    files: Attachment[];
    onPreview: (file: Attachment) => void;
    onLongPress: () => void;
};

const Container = styled(View)`
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: ${({theme}) => theme?.colors?.groupBgColor};
    }
`;

export const FileThumbnail = ({files, onPreview, onLongPress}: Props) => {
    const {darkModeEnabled} = useCommunityTheme();

    return (
        <Stack space="small">
            {files.map((file) => (
                <Container
                    position="relative"
                    overflow="hidden"
                    borderRadius="xsmall"
                    borderWidth={1}
                    borderColor="#D0D9D9"
                    padding="small"
                    justifyContent="center"
                    w={260}
                    h={72}
                    key={file.fileName}
                    bgColor={darkModeEnabled ? undefined : "white"}
                >
                    <Columns verticalAlign="center">
                        <Column>
                            <Pressable
                                onLongPress={onLongPress}
                                onPress={() => onPreview(file)}
                                accessibilityLabel={`Preview file ${file.fileName}`}
                                accessibilityRole="button"
                            >
                                <Columns space="small" verticalAlign="center">
                                    <Column width="content">
                                        <PdfIcon />
                                    </Column>
                                    <Column>
                                        <Stack space="small">
                                            <View width="90%">
                                                <Text
                                                    truncate
                                                    size="medium"
                                                    {...{style: {...boldFontStyles}}}
                                                    numberOfLines={1}
                                                >
                                                    {file.fileName}
                                                </Text>
                                            </View>
                                            <Text color="altTextColor" size="small">
                                                {convertToMb(parseFloat(file.size))} MB
                                            </Text>
                                        </Stack>
                                    </Column>
                                </Columns>
                            </Pressable>
                        </Column>
                        <Column width="content">
                            <DownloadButton ghost file={file} />
                        </Column>
                    </Columns>
                </Container>
            ))}
        </Stack>
    );
};
