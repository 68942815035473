import {UserAccountRoles} from "ubcommunity-shared/src/constants";
import {StudentProfile, StudentProfileProps} from "./Profile/StudentProfile";
import {AmbassadorProfile} from "./Profile/AmbassadorProfile";
import {StaffProfile} from "./Profile/StaffProfile";
import {useGetPartialUserSummaryData} from "./useGetPartialUserSummaryData";

export interface MixpanelMetadataProps {
    openSource?: string;
    isOnline?: boolean;
    buttonViewed?: string;
    type?: string;
}

/**
 * Container to display different types of user profiles
 * Currently caters to student and ambassador profiles
 * @param senderId - "id" of the user the profile is associated with
 * @param conversationId - "id" of the conversation the profile is associated with if applicable
 * @param isStatic - hides back button (only used on desktop for 1:1 conversations and student profile)
 * @param closeDrawer - function to close the drawer
 * @param mixpanelMetadata - metadata to be sent to mixpanel
 */
export const UserSummary = ({
    senderId,
    conversationId = null,
    // infer this boolean from isDesktop
    isStatic = false, // hides back button (only used on desktop for 1:1 conversations) - code smell
    closeDrawer,
    mixpanelMetadata = {},
}: StudentProfileProps) => {
    const {userData: partialUserData, staffFields} = useGetPartialUserSummaryData(senderId);

    if (partialUserData?.accountRole === UserAccountRoles.MENTOR)
        return <AmbassadorProfile id={senderId} mixpanelMetadata={mixpanelMetadata} />;

    if (staffFields) {
        return <StaffProfile id={senderId} />;
    }

    return (
        <StudentProfile
            senderId={senderId}
            conversationId={conversationId}
            isStatic={isStatic}
            closeDrawer={closeDrawer}
            mixpanelMetadata={mixpanelMetadata}
        />
    );
};
