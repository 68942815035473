import * as React from "react";
import Svg, {Path, Rect, G, Defs, ClipPath} from "react-native-svg";

export default function PdfIcon(props) {
    const {...rest} = props;

    return (
        <Svg
            width={props.size || 40}
            height={props.size || 41}
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <G clipPath="url(#clip0_93_770)">
                <Path
                    d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H27.1716C28.9826 0.5 30.7194 1.21942 32 2.5L38 8.5C39.2806 9.78058 40 11.5174 40 13.3284V32.5C40 36.9183 36.4183 40.5 32 40.5H8C3.58172 40.5 0 36.9183 0 32.5V8.5Z"
                    fill="#DC4847"
                />
                <Path
                    d="M10.4692 23.5V17.6818H12.8727C13.3083 17.6818 13.6842 17.767 14.0005 17.9375C14.3187 18.1061 14.5639 18.3419 14.7363 18.6449C14.9086 18.946 14.9948 19.2964 14.9948 19.696C14.9948 20.0975 14.9067 20.4489 14.7306 20.75C14.5564 21.0492 14.3073 21.2812 13.9835 21.446C13.6596 21.6108 13.2751 21.6932 12.83 21.6932H11.3471V20.5852H12.5687C12.7808 20.5852 12.9579 20.5483 13.0999 20.4744C13.2439 20.4006 13.3528 20.2973 13.4266 20.1648C13.5005 20.0303 13.5374 19.8741 13.5374 19.696C13.5374 19.5161 13.5005 19.3608 13.4266 19.2301C13.3528 19.0975 13.2439 18.9953 13.0999 18.9233C12.956 18.8513 12.7789 18.8153 12.5687 18.8153H11.8755V23.5H10.4692ZM19.4386 23.5H17.288V17.6818H19.4357C20.0285 17.6818 20.5389 17.7983 20.967 18.0312C21.3969 18.2623 21.7283 18.5956 21.9613 19.0312C22.1942 19.465 22.3107 19.9839 22.3107 20.5881C22.3107 21.1941 22.1942 21.715 21.9613 22.1506C21.7302 22.5862 21.3997 22.9205 20.9698 23.1534C20.5399 23.3845 20.0295 23.5 19.4386 23.5ZM18.6942 22.3011H19.3846C19.7103 22.3011 19.9859 22.2462 20.2113 22.1364C20.4386 22.0246 20.61 21.8438 20.7255 21.5938C20.8429 21.3419 20.9016 21.0066 20.9016 20.5881C20.9016 20.1695 20.8429 19.8362 20.7255 19.5881C20.6081 19.3381 20.4348 19.1581 20.2056 19.0483C19.9783 18.9366 19.698 18.8807 19.3647 18.8807H18.6942V22.3011ZM24.7317 23.5V17.6818H28.7033V18.8239H26.138V20.017H28.4505V21.1619H26.138V23.5H24.7317Z"
                    fill="white"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30 1.11333C30.7374 1.44889 31.4161 1.91611 32 2.5L38 8.5C38.5839 9.08388 39.0511 9.76262 39.3867 10.5H34C31.7909 10.5 30 8.70914 30 6.5V1.11333Z"
                    fill="#AF2D2C"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_93_770">
                    <Rect y="0.5" width="40" height="40" rx="8" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
