import {Box, Heading, View} from "@unibuddy/patron";
import React, {FC} from "react";
import styled from "styled-components";
import {ChatMessage} from "ubcommunity-shared/src/types";
import {ReportMessageForm} from "ubcommunity-shared/src/Chat/ReportMessageForm";

interface Props {
    message: ChatMessage;
    conversationId: string;
    onDismiss: () => void;
    size?: string;
}

const ReportForm = styled(ReportMessageForm)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ReportMessageModal: FC<Props> = ({onDismiss, message, conversationId}) => {
    return (
        <View height="100%" paddingTop="large">
            <Box paddingX="medium" paddingBottom="large">
                <Heading level="1" size="xsmall">
                    Report Message
                </Heading>
            </Box>
            <ReportForm
                onSuccess={onDismiss}
                onDismiss={onDismiss}
                message={message}
                conversationId={conversationId}
            />
        </View>
    );
};
