import React, {useEffect, useMemo, useState} from "react";
import {ScrollView, Platform} from "react-native";

import {gql, useApolloClient} from "@apollo/client";
import {Box, View, Text} from "@unibuddy/patron";
import {IconUbInbox} from "@unibuddy/icons";
import {useAnalytics} from "@unibuddy/tracking";

import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {DIRECT_MESSAGING_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {useGetUserByUserIdQuery} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";
import {useCreateDirectConversation} from "ubcommunity-shared/src/Hooks/graphql/useCreateDirectConversation";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {useIsUserBlock} from "ubcommunity-shared/src/Chat/useIsUserBlock";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {ProfileItems} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/ProfileItems";
import {UserInfo} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummaryUserInfo";
import {UserSummaryGroupInvite} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummaryGroupInvite";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {GroupTypes, useGetGroupType} from "ubcommunity-shared/src/Hooks/useGetGroupType";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {UserSummaryActions} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummaryActions";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";
import {LoadingButtonWithIcon} from "ubcommunity-shared/src/Patron/Button/LoadingButtonWithIcon";

export type StudentProfileProps = {
    senderId: string;
    // conversationId is only used for tracking purposes
    conversationId?: string | null;
    isStatic?: boolean;
    closeDrawer?: () => void;
    mixpanelMetadata?: MixpanelMetadataProps;
};

export const enum AvailableViews {
    PRIVATE_GROUPS_VIEW = "privateGroups",
    DEFAULT_VIEW = "default",
}

export enum EnrichedProfileItems {
    BIO = "bio",
    NATIONALITY = "nationality",
    PROFILE_PICTURE = "profile picture",
    AREA_OF_STUDY = "area of study",
    INTERESTS = "interests",
    PRONOUNS = "pronouns",
    HOUSING = "housing",
}

export const StudentProfile = ({
    senderId,
    conversationId = null,
    isStatic = false, // hides back button (only used on desktop for 1:1 conversations) - code smell
    closeDrawer,
    mixpanelMetadata = {},
}: StudentProfileProps) => {
    const [view, setView] = useState<AvailableViews>(null);
    const client = useApolloClient();
    const {trackEvent} = useAnalytics();

    const dmFeatureFlag = useIsFeatureEnabledBoolean(DIRECT_MESSAGING_FEATURE_FLAG);
    const dmAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.DIRECT_MESSAGES_SETTINGS);

    const isDmEnabled = dmFeatureFlag && dmAdminEnabled;

    const [navigate] = useNavigate();
    const [isBlocked] = useIsUserBlock({senderId, conversationId});
    const {closeUserSummary} = useOpenUserSummary({});
    const {user: meUser} = useAuth();
    const isWeb = Platform.OS === "web";
    const {createDirectConversation, loading: dmLoading} = useCreateDirectConversation({senderId});

    const {data, loading, error, refetch} = useGetUserByUserIdQuery({
        variables: {
            id: senderId,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    // attempt to fetch data if it already exists in cache
    const partialUserData = client.readFragment({
        id: `UserField:${senderId}`,
        fragment: gql`
            fragment UserSummaryUserFieldPartialDataFragment on UserField {
                id
                firstName
                accountRole
                communityProfile {
                    profilePhoto
                }
            }
        `,
    });

    const userCountryData = client.readFragment({
        id: `UserField:${senderId}`,
        fragment: gql`
            fragment UserSummaryUserFieldCountryFragment on UserField {
                country {
                    name
                    code
                }
            }
        `,
    });

    const groupType = useGetGroupType({conversationId});

    const commonProps = useMemo(
        () => ({
            conversationId: conversationId,
            selectedUserId: senderId,
            accountType: data?.getUser.accountRole,
            groupType,
            ...mixpanelMetadata,
        }),
        [conversationId, senderId, data?.getUser.accountRole, groupType, mixpanelMetadata],
    );

    useEffect(() => {
        if (!loading) {
            const hasBio = !!data?.getUser?.communityProfile?.bio;
            const hasPronouns = !!data?.getUser?.communityProfile?.pronouns;
            const hasCountry = !!data?.getUser?.country;
            const hasProfilePic = !!data?.getUser?.communityProfile?.profilePhoto;
            const hasAreaofStudy = data?.getUser?.communityProfile?.areaOfStudy?.length > 0;
            const hasInterests = data?.getUser?.interests?.length > 0;
            const hasHousing = data?.getUser?.communityProfile?.housing;

            const isEnriched =
                hasBio || hasCountry || hasProfilePic || hasAreaofStudy || hasHousing;
            const profileItems = [
                ...(hasBio ? [EnrichedProfileItems.BIO] : []),
                ...(hasCountry ? [EnrichedProfileItems.NATIONALITY] : []),
                ...(hasProfilePic ? [EnrichedProfileItems.PROFILE_PICTURE] : []),
                ...(hasAreaofStudy ? [EnrichedProfileItems.AREA_OF_STUDY] : []),
                ...(hasInterests ? [EnrichedProfileItems.INTERESTS] : []),
                ...(hasPronouns ? [EnrichedProfileItems.PRONOUNS] : []),
                ...(hasHousing ? [EnrichedProfileItems.HOUSING] : []),
            ];

            isEnriched
                ? trackEvent(TrackEvents.USER_PROFILE_ENRICHED_OPENED, {
                      ...commonProps,
                      profileItems,
                  })
                : trackEvent(TrackEvents.USER_PROFILE_OPENED, commonProps);
        }
    }, [loading, data, trackEvent, commonProps]);

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{component: "UserSummary", query: "useGetUserByUserIdQuery"}}
                layout="center"
            />
        );
    }

    let user = {
        ...partialUserData,
        ...userCountryData,
    };

    if (data && data.getUser) {
        user = {
            ...user,
            ...data.getUser,
        };
    }

    const handleDirectMessage = async () => {
        trackEvent(TrackEvents.USER_PROFILE_ACTION_DIRECT_MESSAGE, commonProps);
        const response = await createDirectConversation();
        const newConversationId = response?.data?.createDirectConversation?.id;

        navigate({
            path: Routes.CHAT,
            options: {
                screen: isWeb ? newConversationId : Routes.CHAT_HOME,
                params: {
                    conversationId: newConversationId,
                },
            },
        });

        if (closeDrawer) {
            closeDrawer();
        }
    };

    if (view === AvailableViews.PRIVATE_GROUPS_VIEW) {
        return (
            <View flex="1" minH={0} maxH="100%">
                <BackButton callback={() => setView(AvailableViews.DEFAULT_VIEW)} />
                <ScrollView>
                    <UserSummaryGroupInvite senderId={senderId} conversationId={conversationId} />
                </ScrollView>
            </View>
        );
    }

    const isMe = meUser?.id === senderId;
    const isThisConversationDirect = groupType === GroupTypes.DIRECT;

    return (
        <View flex="1" minH={0} height="100%">
            {isStatic ? null : <BackButton paddingBottom="small" callback={closeUserSummary} />}
            <ScrollView>
                <View paddingX="medium" paddingTop="large" flex={1}>
                    <View
                        borderBottomWidth={1}
                        borderColor="groupBgColor"
                        flex={1}
                        paddingBottom="medium"
                        marginBottom="medium"
                    >
                        <UserInfo user={user} isBlocked={isBlocked} />
                        {isDmEnabled && !isThisConversationDirect && !isMe ? (
                            <Box paddingTop="medium">
                                <LoadingButtonWithIcon
                                    color="primary"
                                    onClick={handleDirectMessage}
                                    block
                                    size="xs"
                                    loading={dmLoading}
                                    icon={<IconUbInbox color="white" width={18} height={18} />}
                                >
                                    <Text color="white" size="small">
                                        Send message
                                    </Text>
                                </LoadingButtonWithIcon>
                            </Box>
                        ) : null}
                    </View>

                    {!isBlocked ? <ProfileItems user={user} loading={loading} /> : null}
                </View>
                {!loading && !isMe ? (
                    <UserSummaryActions
                        conversationId={conversationId}
                        senderId={senderId}
                        setView={setView}
                        mixpanelMetadata={mixpanelMetadata}
                    />
                ) : null}
            </ScrollView>
        </View>
    );
};
