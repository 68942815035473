import React from "react";
import {Image, Pressable} from "react-native";
import {Box, Stack, Text, View, usePatronTheme, LoadingButton} from "@unibuddy/patron";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {boldFontStyles, semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import AvatarBeam from "ubcommunity-shared/src/General/AvatarBeam/AvatarBeam";
import {CountryFlagWithName} from "ubcommunity-shared/src/General//CountryFlagWithName";
import {shadowStyleCards} from "ubcommunity-shared/src/Styles/shadowStyles";

import OverlayPhotoGradient from "ubcommunity-shared/src/General/BuddyCardV2/OverlayPhotoGradient";
import {IUserDetails} from "./PeopleCell";
import {truncateString} from "../Utils";

const PROFILE_IMAGE_SIZE = 200;
export const CARD_MAX_WIDTH = 200;
export const CARD_MAX_HEIGHT = 370;

const cardColors = {
    border: "#CFC8FF",
    backgroundPhoto: "#3C3B3B",
    bioEmptyState: "#606467",
};

export interface ICardUserDetails extends IUserDetails {
    areaOfStudy?: string;
    bio: string;
    onClick?: (senderId: string) => void;
}

const ProfilePhoto = ({
    profilePhoto,
    id,
    firstName,
}: {
    profilePhoto: string;
    id: string;
    firstName: string;
}) => {
    return profilePhoto ? (
        <Image
            style={{
                width: PROFILE_IMAGE_SIZE,
                height: PROFILE_IMAGE_SIZE,
            }}
            source={{
                uri: profilePhoto,
            }}
            accessibilityLabel={firstName}
        />
    ) : (
        <Box position="absolute" top={10} left={20}>
            <AvatarBeam
                size={PROFILE_IMAGE_SIZE}
                id={id}
                name={firstName}
                colors={["#000000", "#E2CDFE", "#A5FDFC", "#01D4CF", "#5C02D4"]}
            />
        </Box>
    );
};

export const CardContainer = ({children, ...props}: {children: React.ReactNode}) => {
    const {darkModeEnabled} = useCommunityTheme();
    return (
        <View position="relative">
            <View
                width={CARD_MAX_WIDTH}
                height={CARD_MAX_HEIGHT}
                borderRadius="small"
                backgroundColor="navbarColor"
                marginRight="small"
                marginBottom="small"
                style={{
                    ...shadowStyleCards,
                }}
                {...props}
            >
                {children}
            </View>
            <View
                position="absolute"
                width={CARD_MAX_WIDTH}
                height={CARD_MAX_HEIGHT}
                borderRadius="small"
                backgroundColor="transparent"
                borderColor={darkModeEnabled ? "#606467" : cardColors.border}
                borderWidth={1}
                pointerEvents="none"
            />
        </View>
    );
};

export const PeopleCard = ({
    user: {firstName, profilePhoto, id, country, bio, areaOfStudy},
    dmLoading = false,
    isDmsEnabled = false,
    handleDirectMessage,
    openProfile,
}: {
    user: ICardUserDetails;
    isDmsEnabled?: boolean;
    dmLoading?: boolean;
    openProfile: () => void;
    handleDirectMessage: () => void;
}) => {
    const theme = usePatronTheme();
    const truncatedName = truncateString(firstName, 10);

    return (
        <CardContainer
            accessible
            accessibilityActions={[
                {
                    name: "startPrivateConversation",
                    label: `Start a private conversation with ${firstName}`,
                },
                {name: "viewProfile", label: `Open ${firstName}'s profile`},
            ]}
            onAccessibilityAction={(event) => {
                switch (event.nativeEvent.actionName) {
                    case "viewProfile":
                        openProfile();
                        break;
                    case "startPrivateConversation":
                        handleDirectMessage();
                        break;
                }
            }}
        >
            <Pressable onPress={openProfile}>
                <Box position="absolute" w={PROFILE_IMAGE_SIZE} h={PROFILE_IMAGE_SIZE} zIndex={50}>
                    <Box position="absolute" zIndex={50} bottom={20} left={16} width="90%">
                        <Stack space="medium">
                            <Text color="white" style={{...boldFontStyles, fontSize: 20}}>
                                {truncatedName}
                            </Text>
                            {areaOfStudy ? (
                                <Text size="medium" color="white" nowrap truncate>
                                    {areaOfStudy}
                                </Text>
                            ) : null}
                        </Stack>
                    </Box>
                </Box>
                <OverlayPhotoGradient imageSize={PROFILE_IMAGE_SIZE} />
                <Box
                    backgroundColor={cardColors.backgroundPhoto}
                    position="relative"
                    w={PROFILE_IMAGE_SIZE}
                    h={PROFILE_IMAGE_SIZE}
                    style={{
                        borderTopRightRadius: parseInt(theme.space.small, 10),
                        borderTopLeftRadius: parseInt(theme.space.small, 10),
                    }}
                    overflow="hidden"
                >
                    <ProfilePhoto profilePhoto={profilePhoto} id={id} firstName={firstName} />
                </Box>
            </Pressable>
            <View style={{padding: 16, paddingTop: 0}} flex={2} flexDirection="column">
                <Pressable onPress={openProfile} style={{flex: 2, paddingTop: 16}}>
                    {country ? (
                        <View>
                            <CountryFlagWithName country={country} flagSize={10} textSize="small" />
                        </View>
                    ) : null}
                    <Box flex={2}>
                        {bio ? (
                            <Text size="small" style={{...semiBoldFontStyles}}>
                                {bio.length > 60 ? `${bio.slice(0, 60)}...` : bio}
                                {/**need to truncate manually because numberOfLines is not working on Patron Text web  */}
                            </Text>
                        ) : (
                            <Text size="small">{`${firstName} hasn't provided a bio yet. Get to know them by starting a chat!`}</Text>
                        )}
                    </Box>
                </Pressable>

                <Box>
                    <LoadingButton
                        color="primary"
                        block
                        onClick={isDmsEnabled ? handleDirectMessage : openProfile}
                        disabled={dmLoading}
                        loading={dmLoading}
                        style={{
                            height: 33,
                        }}
                    >
                        <Text style={{...boldFontStyles}} size="small" color="white">
                            {isDmsEnabled ? `Chat to ${truncatedName}` : `View profile`}
                        </Text>
                    </LoadingButton>
                </Box>
            </View>
        </CardContainer>
    );
};
