import {useEffect} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {useGetStaffProfileQuery} from "ubcommunity-shared/src/types";
import {} from "ubcommunity-shared/src/types";

import {TrackEvents, TrackSources, UserAccountRoles} from "ubcommunity-shared/src/constants";

export const useGetStaffProfileFields = (id: string) => {
    const {trackEvent} = useAnalytics();

    const {data, loading, error, refetch} = useGetStaffProfileQuery({
        variables: {
            id,
        },
    });

    const user = {...data?.buddy, accountRole: UserAccountRoles.STAFF};

    useEffect(() => {
        if (!loading) {
            trackEvent(TrackEvents.USER_PROFILE_OPENED, {
                accountType: UserAccountRoles.STAFF,
                source: TrackSources.STAFF_LIST,
            });
        }
    }, [trackEvent, loading]);

    return {
        loading,
        error,
        refetch,
        data: user,
    };
};
