import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {MatchesComponent} from "./MatchesComponent";

export const Matches = () => {
    const {push} = useRelativeRouter();

    const handleNavigation = (url: string, payload: Record<string, any>) => {
        push(`${url}/${payload.params?.conversationId}`);
    };

    const handleMatchFilter = (filter) => {
        push(`${Routes.MATCHES}?filter=${filter}`);
    };

    return (
        <MatchesComponent
            handleNavigation={handleNavigation}
            handleMatchFilter={handleMatchFilter}
        />
    );
};
