import {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {useApolloClient, gql} from "@apollo/client";

import {
    FullCommunityChatMessageFragmentDoc,
    Poll,
    PollOption,
    useAddVoteMutation,
    useRemoveVoteMutation,
} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

export const usePoll = (messageId: string) => {
    const {user} = useAuth();
    const client = useApolloClient();
    const [addVote] = useAddVoteMutation();
    const [removeVote] = useRemoveVoteMutation();
    const lastResponseRef = useRef(null);
    const [errorOccurred, setErrorOccurred] = useState(false);

    // reducer for managing the queue of actions.
    // it can enqueue or dequeue actions, each action being an object with an option and action type.
    const queueReducer = (
        state: {
            option: string;
            action: "add" | "remove";
        }[],
        action: {
            type: "ENQUEUE" | "DEQUEUE";
            payload?: {option: string; action: "add" | "remove"};
        },
    ) => {
        switch (action.type) {
            case "ENQUEUE":
                return [...state, action.payload];
            case "DEQUEUE":
                return state.slice(1);
            default:
                return state;
        }
    };
    const [queue, dispatch] = useReducer(queueReducer, []);

    const processingRef = useRef(false);

    const cacheStruct = useMemo(
        () => ({
            id: `ChatMessage:${messageId}`,
            fragment: gql`
                fragment FullCommunityChatMessage on ChatMessage {
                    ...FullCommunityChatMessage
                }
                ${FullCommunityChatMessageFragmentDoc}
            `,
            fragmentName: "FullCommunityChatMessage",
        }),
        [messageId],
    );

    const optimisticCacheUpdate = useCallback(
        (option: string, action: "add" | "remove") => {
            const existingData = client.readFragment({
                ...cacheStruct,
            });

            if (!existingData || !existingData.richContent || !existingData.richContent.poll) {
                return;
            }

            const voteOption = existingData.richContent.poll.options.find((op: PollOption) => {
                return op.option === option;
            });
            const votes = voteOption?.votes || [];

            if (!voteOption) return;

            let newVotes: string[];
            if (action === "add") {
                newVotes = [...votes, user.id];
            } else if (action === "remove") {
                newVotes = votes.filter((vote: string) => vote !== user.id);
            }

            const updatedOptions = existingData.richContent.poll.options.map((op: PollOption) => {
                if (op.option === option) {
                    return {
                        ...op,
                        votes: newVotes,
                    };
                }

                return op;
            });

            client.writeFragment({
                ...cacheStruct,
                data: {
                    ...existingData,
                    richContent: {
                        ...existingData.richContent,
                        poll: {
                            ...existingData.richContent.poll,
                            options: updatedOptions,
                        },
                    },
                },
            });
        },
        [client, cacheStruct, user.id],
    );

    const updateCache = useCallback(
        (data: Poll) => {
            const existingData = client.readFragment({
                ...cacheStruct,
            });
            client.writeFragment({
                ...cacheStruct,
                data: {
                    ...existingData,
                    richContent: {
                        ...existingData.richContent,
                        poll: data,
                    },
                },
            });
        },
        [cacheStruct, client],
    );

    const handleAdd = useCallback(
        (option: string) => {
            optimisticCacheUpdate(option, "add");
            dispatch({type: "ENQUEUE", payload: {option, action: "add"}});
        },
        [optimisticCacheUpdate],
    );

    const handleRemove = useCallback(
        (option: string) => {
            optimisticCacheUpdate(option, "remove");
            dispatch({type: "ENQUEUE", payload: {option, action: "remove"}});
        },
        [optimisticCacheUpdate],
    );

    // executes the appropriate mutation for each action in the queue and updates the lastResponseRef.
    useEffect(() => {
        let response: any = null;
        const processQueue = async () => {
            if (processingRef.current || !queue.length) return;
            processingRef.current = true;

            const {option, action} = queue[0];
            try {
                if (action === "add") {
                    response = await addVote({variables: {option, chatMessageId: messageId}});
                } else if (action === "remove") {
                    response = await removeVote({
                        variables: {option, chatMessageId: messageId},
                    });
                }
                lastResponseRef.current = response;
            } catch (error) {
                console.error(`Failed to ${action} vote for option ${option}:`, error);
                setErrorOccurred(true);
            } finally {
                dispatch({type: "DEQUEUE"});
                processingRef.current = false;
            }
        };

        processQueue();
    }, [queue, addVote, removeVote, messageId, updateCache]);

    // if an error occurred and the queue is empty, it updates the cache with the data from the last response.
    useEffect(() => {
        if (errorOccurred && !queue.length) {
            const responseData =
                lastResponseRef.current?.data?.addVote || lastResponseRef.current?.data?.removeVote;
            if (responseData) {
                updateCache(responseData);
                setErrorOccurred(false);
            }
        }
    }, [errorOccurred, queue.length, updateCache]);

    return {
        handleAdd,
        handleRemove,
    };
};
