import React from "react";
import {Stack, Columns, Column, View, Button, Text, usePatronTheme} from "@unibuddy/patron";
import InfoIcon from "ubcommunity-shared/src/Icons/InfoIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

type Props = {
    handleUserUnblock: () => void;
};

export const SenderBlockedState = ({handleUserUnblock}: Props) => {
    const theme = usePatronTheme();

    return (
        <View width="100%" bgColor="#CFC8FF" padding="medium" borderRadius="xxsmall">
            <Stack space={"medium"}>
                <Columns space="small">
                    <Column width="content">
                        <InfoIcon color={theme?.colors.primaryColor} height={20} width={20} />
                    </Column>
                    <Column>
                        <Text color="primaryColor">
                            You have blocked this user. If you would like to start chatting again
                            you can remove the block.
                        </Text>
                    </Column>
                </Columns>
                <View>
                    <Button block color="primary" onClick={handleUserUnblock}>
                        <Text style={{...boldFontStyles}} color="primary">
                            Unblock
                        </Text>
                    </Button>
                </View>
            </Stack>
        </View>
    );
};
