import {useApolloClient} from "@apollo/client";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {Inline, Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import React from "react";
import {Pressable} from "react-native";
import {triggerHaptic} from "ubcommunity-shared/src/General/Haptic/triggerHaptic";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {useUpdateInboxQuery} from "ubcommunity-shared/src/Hooks/graphql/useUpdateInboxQuery";
import ExitIcon from "ubcommunity-shared/src/Icons/ExitIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {
    useGetCommunityConversationQuery,
    useLeaveCommunityChatGroupMutation,
} from "ubcommunity-shared/src/types";

export const GroupActions = ({
    isPrivate,
    conversationId,
    onLeaveGroupSuccess,
}: {
    isPrivate: boolean;
    conversationId: string;
    onLeaveGroupSuccess: () => void;
}) => {
    const {open} = usePrompt();
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();
    const {darkModeEnabled} = useCommunityTheme();
    const [leaveGroupMutation] = useLeaveCommunityChatGroupMutation();
    const {cache} = useApolloClient();
    const {removeConversationFromInbox} = useUpdateInboxQuery();

    const {updateQuery: conversationUpdateQuery} = useGetCommunityConversationQuery({
        fetchPolicy: "cache-only",
        variables: {
            id: conversationId,
        },
    });

    const onLeaveGroup = async () => {
        try {
            await leaveGroupMutation({
                variables: {
                    conversationId,
                },
            });
            cache.evict({id: "ROOT_QUERY", fieldName: "getConversationPermission"});
            removeConversationFromInbox(conversationId);

            // we manually update the cache here because we need to implement fragments
            // so that shared data is cleared from the cache when leaving a group
            conversationUpdateQuery((cacheData) => {
                if (!cacheData?.getChatConversation) return cacheData;
                return {getChatConversation: {}};
            });
            triggerHaptic("impactLight");
            trackEvent("User left group", {
                groupType: isPrivate ? "Private" : "Public",
                conversationId,
            });
            onLeaveGroupSuccess();
        } catch (error) {
            reportError(error);
            console.error(error);
        }
    };

    const handleLeaveGroup = () => {
        open({
            title: "Leave Group?",
            message:
                "Are you sure you want to leave this group? Once you leave a group you will no longer be able to see the conversations.",
            buttons: [
                {
                    text: "Leave Group",
                    style: "destructive",
                    onPress: () => onLeaveGroup(),
                },
                {text: "Cancel"},
            ],
        });
    };

    return (
        <Pressable onPress={handleLeaveGroup} accessibilityRole="button">
            <Inline space="xxsmall" verticalAlign="center">
                <ExitIcon color={darkModeEnabled ? "#fff" : "#212121"} />
                <Text style={{...boldFontStyles}}>Leave Group</Text>
            </Inline>
        </Pressable>
    );
};
