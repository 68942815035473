import * as React from "react"
import Svg, { G, Path, Defs, SvgProps } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function NoMatchesIcon(props: SvgProps) {
  return (
    <Svg
      width={90}
      height={73}
      viewBox="0 0 90 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G filter="url(#filter0_d_220_3919)" fill="#fff">
        <Path d="M6.077 6.251L4.872 7.676c-.86 1.231-1.077 2.433-.51 4.682l7.146 33.755c.554 3.12 1.402 3.047 2.448 3l33-1.992c.491-.021 1.048-.339 1.344-1.514l.741-2.869" />
        <Path d="M13.711 52.404c-3.881 0-4.945-2.997-5.425-5.658L1.163 13.098C.415 10.078.72 7.892 2.183 5.8c.057-.081.118-.16.183-.239l1.206-1.425A3.282 3.282 0 018.59 8.37L7.494 9.666c-.178.28-.268.608.055 1.894l.03.124 7.145 33.755c.008.034.013.07.02.105.017.084.03.162.046.236l30.555-1.845.52-2.016a3.283 3.283 0 016.358 1.64l-.74 2.87c-.88 3.487-3.49 3.922-4.339 3.97L14.158 52.39c-.019 0-.037 0-.056.002h-.036c-.118.008-.237.01-.35.01h-.005z" />
        <Path d="M47.694 44.092l-33.232 1.326c-.73.028-1.441-.859-1.71-2.129L5.589 9.345c-.357-1.698.216-3.335 1.19-3.39l33.163-1.893c.736-.042 1.458.85 1.726 2.134l7.232 34.51s.436 1.985.205 2.607c0 0-.094.727-1.41.78z" />
        <Path d="M14.423 48.698c-2.33 0-4.278-1.879-4.88-4.732L2.378 10.022C1.936 7.929 2.319 5.835 3.4 4.42c.804-1.05 1.935-1.666 3.19-1.737L39.756.79c2.445-.144 4.504 1.763 5.126 4.737l7.23 34.495c.562 2.567.31 3.73.137 4.254-.441 1.509-1.915 2.997-4.423 3.097l-33.231 1.325-.174.003.003-.003zm31.43-5.816v.001zm0-.005v0zm0-.005v0zm0-.005v0zm0-.005v0zm0-.006v0zm0-.005v0zm0-.005v0zM8.898 9.12l6.956 32.957 29.731-1.186-7.014-33.464L8.895 9.12h.003z" />
        <Path d="M33.851 16.153c.47 4.097-2.487 7.59-6.618 7.802-4.146.213-7.902-2.955-8.377-7.078-.473-4.122 2.516-7.616 6.668-7.804 4.136-.187 7.857 2.983 8.33 7.08h-.003z" />
        <Path d="M26.845 27.244c-5.62 0-10.605-4.375-11.25-9.994-.334-2.902.503-5.7 2.355-7.873 1.857-2.178 4.493-3.451 7.424-3.582 5.814-.263 11.075 4.217 11.739 9.983.33 2.89-.499 5.676-2.337 7.85-1.841 2.172-4.46 3.453-7.375 3.603a9.91 9.91 0 01-.556.013zm-.98-14.899c-.066 0-.129 0-.194.005-1.093.05-2.06.504-2.724 1.284-.659.774-.956 1.792-.832 2.87.273 2.389 2.54 4.302 4.948 4.176 1.084-.055 2.043-.514 2.702-1.291.657-.774.948-1.79.825-2.86-.266-2.31-2.406-4.181-4.727-4.181l.002-.003z" />
        <Path d="M17.094 35.907c-.717-6.249 3.858-11.629 10.14-11.951 6.247-.323 11.917 4.48 12.629 10.67" />
        <Path d="M17.088 39.187a3.28 3.28 0 01-3.256-2.907c-.452-3.927.683-7.719 3.193-10.684 2.516-2.968 6.08-4.716 10.04-4.921 7.952-.417 15.153 5.68 16.06 13.57a3.277 3.277 0 01-2.887 3.633 3.279 3.279 0 01-3.635-2.884c-.509-4.44-4.721-8-9.199-7.768-2.13.11-4.036 1.036-5.365 2.605-1.327 1.567-1.923 3.588-1.681 5.695a3.277 3.277 0 01-3.264 3.654l-.006.007z" />
        <Path d="M11.371 39.46a3.282 3.282 0 01-3.274-3.112 3.282 3.282 0 013.112-3.443l36.309-1.848a3.282 3.282 0 11.334 6.553l-36.31 1.848c-.058.002-.113.005-.17.005v-.003z" />
        <Path d="M20.382 43.242a3.282 3.282 0 01-3.275-3.113 3.282 3.282 0 013.112-3.443l18.716-.953a3.282 3.282 0 11.334 6.553l-18.717.953c-.057.003-.113.006-.17.006v-.003zM80.182 23.079l1.206 1.425c.858 1.23 1.076 2.433.509 4.682L74.751 62.94c-.554 3.12-1.402 3.047-2.447 3l-33-1.992c-.492-.021-1.049-.34-1.345-1.515l-.741-2.868" />
        <Path d="M72.548 69.229c-.116 0-.231 0-.35-.008h-.036c-.019 0-.037-.003-.055-.005L39.12 67.224c-.849-.048-3.46-.483-4.344-3.99l-.738-2.85a3.28 3.28 0 012.358-3.996 3.285 3.285 0 014 2.356l.52 2.016 30.555 1.845c.016-.074.03-.152.045-.236.005-.035.013-.071.02-.105l7.147-33.755c.007-.042.018-.082.028-.121.324-1.286.237-1.617.056-1.895l-1.095-1.296a3.28 3.28 0 01.39-4.624 3.286 3.286 0 014.628.39l1.206 1.426c.065.076.126.155.183.238 1.463 2.092 1.768 4.278 1.02 7.299l-7.123 33.647c-.48 2.661-1.544 5.658-5.425 5.658l-.003-.002z" />
        <Path d="M38.563 60.917l33.231 1.325c.73.03 1.442-.858 1.71-2.128l7.164-33.944c.357-1.698-.216-3.335-1.19-3.39l-33.163-1.893c-.735-.042-1.458.85-1.725 2.134l-7.233 34.51s-.436 1.985-.205 2.607c0 0 .095.727 1.41.78z" />
        <Path d="M71.836 65.523c-.058 0-.115 0-.173-.002L38.43 64.195c-1.843-.073-3.947-1.107-4.456-3.204-.168-.57-.355-1.743.17-4.144l7.23-34.495c.623-2.974 2.687-4.876 5.127-4.735l33.16 1.893c1.253.07 2.387.687 3.19 1.737 1.085 1.415 1.466 3.512 1.025 5.603l-7.164 33.944c-.601 2.853-2.55 4.732-4.88 4.732l.003-.003zM38.69 57.64l-.126 3.277.13-3.278h-.004zm1.717 2.068v0zm0-.006v0zm0-.005v0zm0-.005v0zm0-.005v0zm.27-1.969l29.731 1.184 6.957-32.957-29.675-1.693-7.015 33.464.003.002z" />
        <Path d="M52.408 32.978c-.47 4.097 2.487 7.59 6.618 7.802 4.146.213 7.902-2.955 8.377-7.078.473-4.122-2.516-7.616-6.668-7.804-4.136-.187-7.857 2.983-8.33 7.08h.003z" />
        <Path d="M59.414 44.072c-.184 0-.37-.006-.556-.014-2.915-.15-5.534-1.43-7.375-3.603-1.838-2.17-2.67-4.96-2.337-7.85.662-5.768 5.932-10.242 11.739-9.983 2.93.132 5.567 1.404 7.424 3.583 1.854 2.173 2.69 4.97 2.356 7.873-.646 5.619-5.63 9.993-11.25 9.993zM55.67 33.352c-.123 1.071.171 2.087.825 2.861.657.777 1.618 1.233 2.702 1.291 2.409.124 4.672-1.787 4.948-4.175.123-1.076-.17-2.097-.833-2.869-.664-.78-1.63-1.233-2.723-1.283-2.392-.105-4.648 1.806-4.919 4.178v-.003z" />
        <Path d="M69.165 52.732c.717-6.249-3.858-11.629-10.14-11.951-6.247-.323-11.917 4.48-12.628 10.67" />
        <Path d="M69.17 56.015a3.28 3.28 0 01-3.264-3.653c.242-2.108-.354-4.128-1.68-5.695-1.332-1.572-3.239-2.496-5.366-2.606-4.477-.231-8.687 3.328-9.2 7.768a3.28 3.28 0 01-3.634 2.884 3.28 3.28 0 01-2.886-3.632c.906-7.891 8.112-13.98 16.059-13.57 3.96.204 7.524 1.95 10.04 4.92 2.51 2.963 3.645 6.758 3.193 10.684a3.284 3.284 0 01-3.256 2.908l-.005-.008z" />
        <Path d="M74.888 56.288c-.055 0-.113 0-.17-.005l-36.31-1.848a3.28 3.28 0 11.334-6.553L75.05 49.73a3.28 3.28 0 01-.163 6.555v.003z" />
        <Path d="M65.877 60.07c-.055 0-.112 0-.17-.005l-18.717-.953a3.282 3.282 0 11.334-6.553l18.716.952a3.28 3.28 0 01-.163 6.556v.003zM50.606 14.405c.849-1.155 1.697-2.31 2.542-3.464l-2.542 3.464z" />
        <Path d="M50.604 17.686a3.268 3.268 0 01-1.938-.635 3.276 3.276 0 01-.704-4.585l2.544-3.464a3.283 3.283 0 014.588-.704 3.276 3.276 0 01.704 4.585l-2.542 3.464a3.278 3.278 0 01-2.65 1.341l-.002-.002z" />
        <Path d="M51.499 16.515a5.187 5.187 0 012.676-.659l-2.676.66z" />
        <Path d="M51.502 19.796a3.281 3.281 0 01-1.615-6.141 8.469 8.469 0 014.388-1.079 3.282 3.282 0 013.18 3.38 3.289 3.289 0 01-3.382 3.178 1.902 1.902 0 00-.964.236 3.278 3.278 0 01-1.607.423v.003zM48.366 14.468a5.143 5.143 0 00-.218-2.745l.218 2.745z" />
        <Path d="M48.369 17.751a3.277 3.277 0 01-3.225-3.908 1.868 1.868 0 00-.079-.989 3.283 3.283 0 016.166-2.257 8.405 8.405 0 01.358 4.504 3.285 3.285 0 01-3.218 2.653l-.002-.003z" />
      </G>
      <Path
        d="M5.988 6.351L4.782 7.776c-.859 1.231-1.076 2.433-.51 4.682l7.147 33.755c.554 3.12 1.402 3.047 2.447 3l33-1.992c.492-.021 1.048-.339 1.345-1.515l.74-2.868"
        fill="#131622"
      />
      <Path
        d="M47.605 44.192l-33.232 1.325c-.73.03-1.442-.858-1.71-2.128L5.5 9.445c-.357-1.698.216-3.335 1.19-3.39l33.163-1.893c.736-.042 1.458.85 1.726 2.134l7.232 34.51s.436 1.985.205 2.607c0 0-.095.727-1.41.78z"
        fill="#fff"
      />
      <Path
        d="M14.339 45.845c-.88 0-1.697-.97-1.996-2.388L5.18 9.511c-.257-1.226-.071-2.48.48-3.2.276-.359.625-.561 1.012-.585l33.163-1.892c.911-.047 1.76.932 2.067 2.394l7.232 34.51c.074.339.433 2.068.2 2.764-.048.202-.32.96-1.715 1.015l-33.232 1.326h-.047v.002zM39.902 4.49h-.032L6.708 6.382c-.191.01-.37.121-.528.328-.433.565-.577 1.638-.36 2.667l7.165 33.944c.228 1.086.817 1.892 1.376 1.866l33.232-1.326c.99-.039 1.097-.485 1.1-.503a.512.512 0 01.016-.06c.136-.368-.045-1.639-.218-2.423L41.257 6.36c-.226-1.078-.803-1.873-1.355-1.873v.002z"
        fill="#131622"
      />
      <Path
        d="M33.762 16.253c.47 4.096-2.487 7.59-6.618 7.802-4.147.213-7.902-2.955-8.377-7.078-.473-4.123 2.515-7.616 6.667-7.805 4.136-.186 7.858 2.984 8.33 7.08h-.002z"
        fill="#131622"
      />
      <Path
        d="M17.002 36.006c-.717-6.248 3.858-11.628 10.14-11.951 6.247-.323 11.917 4.48 12.629 10.67"
        fill="#131622"
      />
      <Path
        d="M11.285 36.608a.33.33 0 01-.329-.313.327.327 0 01.313-.344l36.31-1.847a.326.326 0 11.03.653L11.3 36.605h-.015v.003zM20.295 40.39a.33.33 0 01-.328-.313.327.327 0 01.312-.344l18.717-.952a.327.327 0 11.032.654l-18.717.952h-.016v.002zM80.093 23.178l1.205 1.425c.859 1.231 1.077 2.433.51 4.682L74.662 63.04c-.554 3.12-1.403 3.047-2.448 3l-33-1.992c-.491-.02-1.048-.339-1.345-1.514l-.74-2.869"
        fill="#131622"
      />
      <Path
        d="M38.473 61.017l33.232 1.325c.73.03 1.442-.858 1.71-2.128l7.164-33.944c.357-1.698-.216-3.335-1.19-3.39l-33.163-1.893c-.736-.042-1.458.85-1.725 2.134l-7.233 34.51s-.436 1.985-.205 2.607c0 0 .095.727 1.41.78z"
        fill="#fff"
      />
      <Path
        d="M71.739 62.67h-.047L38.46 61.345c-1.394-.055-1.667-.814-1.715-1.016-.233-.693.126-2.422.203-2.766l7.232-34.508c.307-1.462 1.153-2.443 2.067-2.393l33.163 1.892c.386.02.735.223 1.011.585.552.72.738 1.976.48 3.2l-7.163 33.943c-.3 1.415-1.117 2.388-1.996 2.388h-.003zM38.486 60.69l33.232 1.325c.551.024 1.148-.78 1.376-1.866l7.164-33.944c.218-1.028.074-2.102-.36-2.666-.157-.207-.336-.317-.527-.328l-33.164-1.892h-.031c-.552 0-1.127.795-1.355 1.874l-7.233 34.51c-.173.788-.354 2.058-.218 2.425a.709.709 0 01.019.071c0 .008.107.454 1.097.493v-.002z"
        fill="#131622"
      />
      <Path
        d="M52.318 33.078c-.47 4.096 2.487 7.59 6.618 7.802 4.147.213 7.902-2.955 8.378-7.078.473-4.123-2.516-7.616-6.668-7.805-4.136-.186-7.858 2.984-8.33 7.08h.002z"
        fill="#fff"
      />
      <Path
        d="M59.33 41.219c-.136 0-.273-.003-.412-.01-2.09-.109-3.963-1.02-5.27-2.565-1.308-1.543-1.897-3.532-1.658-5.603.489-4.257 4.386-7.563 8.672-7.37 2.104.095 3.99 1 5.307 2.549 1.316 1.543 1.91 3.54 1.67 5.621-.475 4.15-4.16 7.38-8.308 7.38v-.002zm.972-14.902c-3.82 0-7.22 2.984-7.658 6.797-.218 1.89.318 3.704 1.505 5.105 1.19 1.404 2.897 2.233 4.803 2.33 3.974.205 7.582-2.84 8.034-6.786.218-1.898-.32-3.72-1.518-5.12-1.2-1.41-2.918-2.231-4.838-2.318a6.893 6.893 0 00-.33-.008h.002z"
        fill="#131622"
      />
      <Path
        d="M69.076 52.832c.717-6.25-3.858-11.63-10.14-11.952-6.247-.323-11.917 4.48-12.629 10.67"
        fill="#fff"
      />
      <Path
        d="M69.076 53.16h-.037a.327.327 0 01-.289-.363c.337-2.926-.501-5.744-2.36-7.938-1.865-2.2-4.517-3.496-7.47-3.648-6.084-.313-11.591 4.343-12.285 10.38a.327.327 0 11-.651-.074c.733-6.375 6.555-11.296 12.97-10.96 3.136.163 5.954 1.538 7.937 3.88 1.978 2.332 2.87 5.33 2.513 8.437a.327.327 0 01-.325.29l-.003-.004z"
        fill="#131622"
      />
      <Path
        d="M74.796 53.435h-.016l-36.31-1.847a.33.33 0 01-.312-.344.328.328 0 01.344-.31l36.31 1.848a.329.329 0 01-.016.656v-.003zM65.785 57.217h-.015l-18.717-.953a.33.33 0 01-.312-.344.328.328 0 01.344-.31l18.716.953a.329.329 0 01-.016.656v-.002z"
        fill="#131622"
      />
      <Path
        d="M50.517 14.502c.848-1.154 1.696-2.309 2.542-3.464l-2.542 3.464z"
        fill="#fff"
      />
      <Path
        d="M50.517 14.833a.328.328 0 01-.265-.522l2.542-3.464a.328.328 0 11.53.388l-2.542 3.464a.327.327 0 01-.265.134z"
        fill="#131622"
      />
      <Path
        d="M51.41 16.615a5.186 5.186 0 012.676-.659l-2.676.659z"
        fill="#fff"
      />
      <Path
        d="M51.41 16.943a.327.327 0 01-.286-.168.326.326 0 01.125-.446 5.459 5.459 0 012.847-.7.328.328 0 01.318.338c-.005.18-.152.333-.339.317a4.828 4.828 0 00-2.505.614.318.318 0 01-.16.042v.003z"
        fill="#131622"
      />
      <Path
        d="M48.277 14.568a5.142 5.142 0 00-.218-2.745l.218 2.745z"
        fill="#fff"
      />
      <Path
        d="M48.277 14.896c-.021 0-.042 0-.063-.005a.329.329 0 01-.26-.386 4.785 4.785 0 00-.205-2.57.327.327 0 11.614-.225 5.45 5.45 0 01.232 2.921.328.328 0 01-.323.265h.005z"
        fill="#131622"
      />
      <Defs></Defs>
    </Svg>
  )
}

export default NoMatchesIcon
