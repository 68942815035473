import {gql, useApolloClient} from "@apollo/client";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {TrackEvents, TrackSources} from "ubcommunity-shared/src/constants";
import {useCreateDirectConversationMutation} from "ubcommunity-shared/src/types";
import {useUpdateInboxQuery} from "./useUpdateInboxQuery";

export const CONVERSATION_CREATION_SOURCE = "userInitiatedDirectConversation";
// Instead use useCreateConversation hook
export const useCreateDirectConversation = ({
    senderId,
    source,
}: {
    senderId: string;
    source?: TrackSources;
}) => {
    const {selectedCommunity} = useCommunity();
    const {user} = useAuth();
    const {trackEvent} = useAnalytics();
    const client = useApolloClient();

    const [createDirectConversationMutation, {loading, error}] =
        useCreateDirectConversationMutation();
    const {addConversationToInbox} = useUpdateInboxQuery();

    const userData = client.readFragment({
        id: `UserField:${senderId}`,
        fragment: gql`
            fragment UserFieldFragment on UserField {
                accountRole
                id
                firstName
            }
        `,
    });

    const {reportError} = useErrorReporting();

    const createDirectConversation = async () => {
        try {
            const response = await createDirectConversationMutation({
                variables: {
                    communityId: selectedCommunity?.id,
                    userToConverseWith: userData?.id,
                    source: CONVERSATION_CREATION_SOURCE,
                },
            });

            if (response) {
                addConversationToInbox(response.data?.createDirectConversation);
            }

            trackEvent(TrackEvents.CLICK_SEND_DIRECT_MESSAGE, {
                conversationId: response.data?.createDirectConversation.id,
                senderId: userData.id,
                isDirect: true,
                userId: user.id,
                source,
            });

            return response;
        } catch (error) {
            console.log(error);
            reportError(error);
        }
    };

    return {createDirectConversation, loading, error};
};
