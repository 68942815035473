import React from "react";
import {useLocation} from "react-router-dom";

import {View} from "@unibuddy/patron";

import {MatchesList} from "ubcommunity-shared/src/Community/MatchesList/MatchesList";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes} from "ubcommunity-shared/src/constants/routes";

export const MobileMatchesListPage = () => {
    const {closeUserSummary} = useOpenUserSummary({});
    const [navigate] = useNavigate();
    const location = useLocation();
    const filter = new URLSearchParams(location.search).get("filter");

    const handleBack = () => {
        closeUserSummary();
        navigate({path: Routes.EXPLORE});
    };

    return (
        <View flex="1" bgColor="white">
            <BackButton callback={handleBack} />
            <MatchesList filter={filter} />
        </View>
    );
};
