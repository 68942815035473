import React, {useEffect, useRef} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Box, Stack, Text} from "@unibuddy/patron";
import {DegreeField} from "ubcommunity-shared/src/types";
import {AutocompleteV2} from "ubcommunity-shared/src/Patron/AutocompleteV2";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {usePeopleFilters} from "../PeopleFiltersProvider";

type Props = {
    callback?: () => void;
    degreesData: (DegreeField | null)[];
};

export const CourseSelector = ({callback, degreesData}: Props) => {
    const {filters, setFilters} = usePeopleFilters();
    const ref = useRef(null);
    const {trackEvent} = useAnalytics();

    useEffect(() => {
        if (!filters.areaOfStudy?.length && ref.current?.selected?.length) {
            ref.current?.onClear();
        }
    }, [filters.areaOfStudy]);

    const onSelect = (courseSelected: DegreeField[]) => {
        const degree = courseSelected[0];
        const degreeId = courseSelected.length ? courseSelected[0].id : undefined;
        if (!degreeId) {
            trackEvent(TrackEvents.PEOPLE_AREA_OF_STUDY_FILTER_DESELECTED);
            setFilters({
                ...filters,
                areaOfStudy: [],
            });
            return;
        }
        const oldFilters = filters.areaOfStudy || [];
        trackEvent(TrackEvents.PEOPLE_AREA_OF_STUDY_FILTER_SELECTED, {
            degreeId: degree.id,
            degreeName: degree.name,
        });
        setFilters({
            ...filters,
            areaOfStudy: [...oldFilters, degreeId],
        });
    };

    return (
        <Stack space="small">
            <Box
                as="label"
                // @ts-ignore
                htmlFor="filter-course-selector"
            >
                <Text size="large" weight="bold">
                    Area of study
                </Text>
            </Box>
            <AutocompleteV2
                ref={ref}
                id="filter-course-selector"
                // @ts-ignore
                data={degreesData}
                placeholder="Select area of study"
                // @ts-ignore
                renderItem={(item) => <Text size="medium">{item.name}</Text>}
                resultKey="name"
                onSelect={onSelect}
                resultsContainerHeight={300}
            />
        </Stack>
    );
};
