import {usePatronTheme, View} from "@unibuddy/patron";
import React, {useRef, useState} from "react";
import {Platform, Pressable, ScrollView} from "react-native";
import {CARD_MAX_WIDTH, BuddyCardV2} from "ubcommunity-shared/src/General/BuddyCardV2";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {MatchedUser} from "ubcommunity-shared/src/types";
import ChevronRightIcon from "ubcommunity-shared/src/Icons/ChevronRightIcon";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {DIRECT_MESSAGING_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";
import {NoMatches} from "./EmptyStates/NoMatches";

export const transformRotationIcon = (deg: number) => {
    if (Platform.OS === "web") {
        return {transform: `rotate(${deg}deg)`};
    }
    return {transform: [{rotate: `${deg}deg`}]};
};
export enum MatchesType {
    Aos,
    Country,
}

export type MatchesCarouselProps = {
    viewMore: React.ReactNode;
    matches: MatchedUser[];
    handleCreateDirectConversation: (position: number) => (senderId: string) => void;
    header: React.ReactNode;
    openSource: string;
    type: MatchesType;
};

export const MatchesCarousel = ({
    viewMore,
    matches,
    handleCreateDirectConversation,
    header,
    openSource,
    type,
}: MatchesCarouselProps) => {
    const scrollViewRef = useRef<ScrollView>();
    const {isDesktop} = useIsDesktop();
    const theme = usePatronTheme();

    const isDmsAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.DIRECT_MESSAGES_SETTINGS);
    const isDmsFFEnabled = useIsFeatureEnabledBoolean(DIRECT_MESSAGING_FEATURE_FLAG);
    const isDmsEnabled = isDmsFFEnabled && isDmsAdminEnabled;

    const [scrollViewInfo, setScrollViewInfo] = useState<{
        pos: number;
        totalWidth: number;
        layoutMeasurement: number;
    }>({
        pos: 0,
        totalWidth: CARD_MAX_WIDTH,
        layoutMeasurement: 0,
    });
    const CARD_MARGIN_RIGHT = parseInt(theme.margin.small, 10);

    const handleScrollNavigationButton = (pos: number) => {
        scrollViewRef?.current.scrollTo({
            animated: true,
            x: pos,
        });
    };

    if (matches?.length > 0) {
        return (
            <View>
                <View flexDirection="row" paddingRight="small">
                    {header}

                    {isDesktop && matches?.length > 2 ? (
                        <View paddingBottom="xsmall" flexDirection="row">
                            <Pressable
                                accessibilityRole="button"
                                accessibilityLabel="Previous slide button"
                                onPress={() =>
                                    handleScrollNavigationButton(
                                        scrollViewInfo.pos - CARD_MAX_WIDTH - CARD_MARGIN_RIGHT,
                                    )
                                }
                                disabled={scrollViewInfo.pos > 5 ? false : true}
                                style={{
                                    justifyContent: "center",
                                    marginLeft: 12,
                                }}
                            >
                                <ChevronRightIcon
                                    color={
                                        scrollViewInfo.pos > 5
                                            ? theme?.colors?.primaryColor
                                            : theme?.colors.grey
                                    }
                                    style={transformRotationIcon(180)}
                                />
                            </Pressable>
                            <Pressable
                                accessibilityRole="button"
                                accessibilityLabel="Next slide button"
                                onPress={() =>
                                    handleScrollNavigationButton(
                                        scrollViewInfo.pos + CARD_MAX_WIDTH + CARD_MARGIN_RIGHT,
                                    )
                                }
                                disabled={
                                    scrollViewInfo.pos + scrollViewInfo.layoutMeasurement >
                                    scrollViewInfo.totalWidth
                                        ? true
                                        : false
                                }
                                style={{
                                    justifyContent: "center",
                                    marginLeft: 12,
                                }}
                            >
                                <ChevronRightIcon
                                    color={
                                        scrollViewInfo.pos + scrollViewInfo.layoutMeasurement <
                                        scrollViewInfo.totalWidth
                                            ? theme?.colors?.primaryColor
                                            : theme?.colors.grey
                                    }
                                />
                            </Pressable>
                        </View>
                    ) : null}
                </View>

                <View paddingLeft="small-medium">
                    <ScrollView
                        horizontal
                        pagingEnabled={false}
                        showsHorizontalScrollIndicator={false}
                        style={{
                            marginTop: 5,
                        }}
                        ref={scrollViewRef}
                        onScroll={(event) => {
                            setScrollViewInfo({
                                pos: event.nativeEvent.contentOffset.x,
                                totalWidth: event.nativeEvent.contentSize.width,
                                layoutMeasurement: event.nativeEvent.layoutMeasurement.width,
                            });
                        }}
                        scrollEventThrottle={10}
                    >
                        {matches?.map(({user}, index) => (
                            <BuddyCardV2
                                key={`buddy-card-${user.id}`}
                                id={user.id}
                                firstName={user.firstName}
                                profilePhoto={user.communityProfile?.profilePhoto}
                                country={user.country}
                                areaOfStudy={user.communityProfile?.areaOfStudy[0]?.name}
                                onClick={handleCreateDirectConversation(index + 1)}
                                openSource={openSource}
                                bio={user.communityProfile?.bio ?? ""}
                                isDmsEnabled={isDmsEnabled}
                            />
                        ))}

                        {viewMore}
                    </ScrollView>
                </View>
            </View>
        );
    }

    // For AOS matches, we want to show the header even if there are no matches
    if (type === MatchesType.Aos) {
        return (
            <View flex={1}>
                <View flexDirection="row" marginRight="small-medium">
                    {header}
                </View>
                <NoMatches />
            </View>
        );
    }
    return null;
};
