import React, {useState} from "react";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {LoadingButton} from "@unibuddy/patron";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {Attachment} from "ubcommunity-shared/src/types";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";

import {useDownloadFile} from "../useDownloadFiles";
import DownloadIcon from "./DownloadIcon";

type Props = {
    file: Attachment;
    size?: number;
    [key: string]: any;
};

export const DownloadButton = ({file, size = 14, ...rest}: Props) => {
    const [loading, setLoading] = useState(false);

    const {darkModeEnabled} = useCommunityTheme();
    const {reportError} = useErrorReporting();
    const {downloadFile} = useDownloadFile();
    const {open} = usePrompt();

    const onDownload = async (file: Attachment) => {
        try {
            setLoading(true);
            await downloadFile(file);
        } catch (error) {
            open({
                title: "Download failed",
                message: `File ${file.fileName} could not be downloaded.`,
                buttons: [{text: "OK"}],
            });
            console.error(error);
            reportError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LoadingButton
            accessibilityLabel={`Download file ${file.fileName}`}
            aria-label={`Download file ${file.fileName}`}
            onClick={() => onDownload(file)}
            iconOnly
            round
            size="sm"
            loading={loading}
            style={{
                borderColor: "#D0D9D9",
            }}
            {...rest}
        >
            <DownloadIcon color={darkModeEnabled ? "white" : "grey"} size={size} />
        </LoadingButton>
    );
};
