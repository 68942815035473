import React, {forwardRef, useContext} from "react";
import {NavLink} from "react-router-dom";
import {RelativeRouterContext} from "../RelativeRouter/RelativeRouter";
import getToProp from "../RelativeLink/getToProp";

/**
 * RelativeNavLink appends the `to` prop to the path from the RelativeRouterContext.
 * It can be used outside of the RelativeRouter, in that case it will behave like the
 * normal NavLink component.
 *
 * @export
 * @param {*} {to, children, ...props}
 * @returns
 */
function RelativeNavLink({to, children, ...props}, ref) {
    const {url} = useContext(RelativeRouterContext);
    return (
        <NavLink ref={ref} {...props} to={getToProp(to, url)}>
            {children}
        </NavLink>
    );
}

export default forwardRef(RelativeNavLink);
