import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AppState} from "react-native";
import {NetworkStatus} from "@apollo/client";

type useRefetchProps = {
    networkStatus: NetworkStatus;
    refetch: () => void;
    shouldRefetch?: boolean;
};
export const useRefetch = ({networkStatus, refetch, shouldRefetch}: useRefetchProps) => {
    const [isRefetching, setIsRefetching] = useState(false);
    const appState = useRef("active");

    const triggerRefetch = useCallback(() => {
        setIsRefetching(true);
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (networkStatus !== NetworkStatus.refetch && isRefetching) {
            setIsRefetching(false);
        }
    }, [isRefetching, networkStatus]);

    useEffect(() => {
        if (shouldRefetch && !isRefetching) {
            triggerRefetch();
        }
    }, [isRefetching, refetch, shouldRefetch, triggerRefetch]);

    /**
     * In browser app when window comes back to active state, it will refetch the data
     */
    useEffect(() => {
        const handleAppStateChange = (nextAppState: string) => {
            if (appState.current.match(/inactive|background/) && nextAppState === "active") {
                setIsRefetching(true);
                refetch();
            }

            appState.current = nextAppState;
        };
        const sub = AppState.addEventListener("change", handleAppStateChange);

        return () => {
            if (sub) sub.remove();
        };
    }, [refetch]);

    return useMemo(
        () => ({
            triggerRefetch,
            isRefetching,
        }),
        [isRefetching, triggerRefetch],
    );
};
