import React from "react";
import {Platform} from "react-native";

import {Stack, Heading, View, Box} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import {CardButton} from "ubcommunity-shared/src/General/CardButton/CardButton";
import GroupIcon from "ubcommunity-shared/src/Icons/GroupIcon";
import LockIcon from "ubcommunity-shared/src/Icons/LockIcon";
import {CreatePrivateGroupV2} from "ubcommunity-shared/src/Community/CreatePrivateGroup/CreatePrivateGroupV2";
import {RequestPublicGroup} from "ubcommunity-shared/src/Community/RequestPublicGroup/RequestPublicGroup";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";

enum CreateGroupTypes {
    Private = "PRIVATE",
    Public = "PUBLIC",
}

export const CreateGroupSection = ({handlePublicGroupSuccess, handlePrivateGroupSuccess}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const [groupType, setGroupType] = React.useState<CreateGroupTypes>(null);
    const {isDesktop} = useIsDesktop();
    const {trackEvent} = useAnalytics();

    const handlePrivateButtonClick = () => {
        setGroupType(CreateGroupTypes.Private);
        trackEvent(TrackEvents.PRIVATE_CARD_BUTTON_CLICKED);
    }

    const handlePublicButtonClick = () => {
        setGroupType(CreateGroupTypes.Public);
        trackEvent(TrackEvents.PUBLIC_CARD_BUTTON_CLICKED);
    }

    return (
        <Stack space="large" align="center">
            {!isDesktop ? (
                <Heading size="xsmall" weight="600" level="2">
                    Create a new group
                </Heading>
            ) : null}

            <View
                flexDirection="row"
                justifyContent="center"
                width={
                    isDesktop
                        ? 400
                        : Platform.select({
                              web: 362,
                              native: undefined,
                          })
                }
            >
                <View width="47%" paddingRight="small">
                    <CardButton
                        icon={<GroupIcon color={darkModeEnabled ? "white" : "black"} />}
                        title="Public"
                        subtitle="Anyone can join"
                        onClick={handlePublicButtonClick}
                        active={groupType === CreateGroupTypes.Public}
                        accessibilityLabel="Create public group"
                    />
                </View>

                <View width="47%">
                    <CardButton
                        icon={<LockIcon color={darkModeEnabled ? "white" : "black"} />}
                        title="Private"
                        subtitle="Invite only"
                        onClick={handlePrivateButtonClick}
                        active={groupType === CreateGroupTypes.Private}
                        accessibilityLabel="Create private group"
                    />
                </View>
            </View>

            {groupType && (
                <Box
                    padding={isDesktop ? "small" : "medium"}
                    width={
                        isDesktop
                            ? 400
                            : Platform.select({
                                  web: "376px",
                                  native: "100%",
                              })
                    }
                >
                    {groupType === CreateGroupTypes.Public ? (
                        <RequestPublicGroup handleSuccess={handlePublicGroupSuccess} />
                    ) : (
                        <CreatePrivateGroupV2 handleSuccess={handlePrivateGroupSuccess} />
                    )}
                </Box>
            )}
        </Stack>
    );
};
