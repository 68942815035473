export enum PermissionErrors {
    CAMERA_PERMISSION = "You need to grant access to your camera to upload images. You can change these permissions in your phone settings.",
    GALLERY_PERMISSION = "You need to grant access to your photo library to upload images. You can change these permissions in your phone settings.",
}

// TODO: Add validation error messages
// EG: FIST_NAME_REQUIRED = "First name is required",
export enum ValidationErrors {}


export enum GraphQLErrors {
    NOT_FOUND = "NOT_FOUND",
}
