import React, {useEffect, useRef} from "react";
import {useLocation, useParams} from "react-router";
import styled from "styled-components";
import {useFormikContext, FormikHelpers} from "formik";
import {string, array} from "yup";
import {
    Stack,
    Heading,
    View,
    Box,
    Form,
    TextInput,
    FormGroup,
    CloseIcon,
    Button,
    Text,
    Columns,
    Column,
    VisuallyHidden,
    FieldArray,
    SubmitButton,
    Switch,
    ErrorMessage,
    RequiredFormIndicator,
    Inline,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import InfoIcon from "ubcommunity-shared/src/Icons/InfoIcon";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useSendCommunityChatMessageMutation} from "ubcommunity-shared/src/types";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {useGetNewsFeedGroupId} from "ubcommunity-shared/src/NewsFeed/useGetNewsFeedGroupId";

const Label = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LabelText = styled.span`
    font-weight: 700;
`;

const OptionsContainer = styled.fieldset`
    position: relative;
    padding: 0;
    border: 0;
    margin: 0;
`;

const OptionsLabel = styled.legend`
    padding: 0;
    font-weight: 700;
`;

const CustomError = styled(ErrorMessage).attrs({role: "alert"})`
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    color: ${({theme}) => theme?.FormFieldError?.color};
    background-color: ${({theme}) => theme?.FormFieldError?.backgroundColor};
    display: inline-block;

    &::first-letter {
        text-transform: uppercase;
    }
`;

const ValidationError = ({name}: {name: string}) => {
    const {errors} = useFormikContext<PollFormValues>();
    /*
     * A bug in Formik causes the options error to sometimes be an array
     * which breaks the UI so we discard anything that's not a string
     * https://github.com/jaredpalmer/formik/issues/3352
     */
    if (typeof errors.options !== "string") return;
    return <CustomError name={name} />;
};

type Option = {
    id: string;
    option: string;
};

interface PollFormValues {
    question: string;
    options: Option[];
    allowMultipleAnswers: boolean;
}

const Options = () => {
    const {values} = useFormikContext<PollFormValues>();
    const arrayHelpersRef = useRef(null);

    const emptyInputs = values.options
        .map((obj) => obj.option.length)
        .filter((length) => length === 0);

    /*
     * The poll options logic attempts to mimic how whatsapp does it
     * The effect is used so that we can react to changes in the formik state
     * and add new inputs when needed.
     * The blur is more complicated.
     * When the currently active input blurs we control if an input needs to be removed.
     * This is fairly complex and would probably be better as a state machine!
     */
    useEffect(() => {
        // allow max 8 inputs - only 1 empty input at a time
        if (values.options.length > 7 || emptyInputs.length > 0) return;

        arrayHelpersRef.current.push({id: `${values.options.length + 1}`, option: ""});
    }, [emptyInputs.length, values.options]);

    const handleBlur = (index: number) => {
        // always show at least two inputs
        if (values.options.length < 3) return;

        // don't remove the input if it's the last one unless there is another empty input available
        if (emptyInputs.length === 1 && values.options.length === index + 1) return;

        // don't remove an input if we can't add more and it's the only empty one
        if (values.options.length === 8 && emptyInputs.length === 1) return;

        // remove the first empty one
        if (values.options[index].option.length === 0) {
            arrayHelpersRef.current.remove(
                values.options.findIndex((obj) => obj.option.length === 0),
            );
        }
    };

    return (
        <FieldArray
            name="options"
            render={(arrayHelpers) => {
                arrayHelpersRef.current = arrayHelpers;

                return (
                    <Stack space="small">
                        <Stack>
                            {values.options.map((option, index) => (
                                <View position="relative" key={option.id}>
                                    <FormGroup
                                        label={
                                            <VisuallyHidden>{`option ${index + 1}`}</VisuallyHidden>
                                        }
                                    >
                                        <TextInput
                                            name={`options.${index}.option`}
                                            placeholder="Add"
                                            onBlur={() => handleBlur(index)}
                                        />
                                    </FormGroup>
                                </View>
                            ))}
                        </Stack>
                    </Stack>
                );
            }}
        />
    );
};

const CustomSwitch = ({value, onChange, onBlur, name}: {name: string}) => {
    return (
        <Label>
            <LabelText>Allow multiple answers</LabelText>
            <Switch
                name="multiple"
                onBlur={onBlur}
                onChange={(value) => {
                    onChange({
                        target: {value, name},
                    });
                }}
                checked={value}
            />
        </Label>
    );
};

export function CreatePoll() {
    const {user} = useAuth();
    const {push} = useRelativeRouter();
    const {trackEvent} = useAnalytics();
    const {reportError} = useErrorReporting();
    const {id: conversationId} = useParams<{id: string}>();
    const [sendMessage, {error}] = useSendCommunityChatMessageMutation();
    const {id: newsFeedId} = useGetNewsFeedGroupId();
    const {state} = useLocation<{isNews: boolean}>();

    const isNews = state.isNews;

    const handleClose = () => {
        const NEWS_ROUTE = "/news";
        const CHAT_ROUTE = `/chat/${conversationId}`;

        push(isNews ? NEWS_ROUTE : CHAT_ROUTE);
    };

    const handleSubmit = async (
        {question, options, allowMultipleAnswers}: PollFormValues,
        {setSubmitting, setFieldError}: FormikHelpers<PollFormValues>,
    ) => {
        const formattedOptions = options
            .filter((obj) => obj.option.length > 0)
            .map((obj) => ({option: obj.option}));
        const variables = {
            messageDto: {
                conversationId: isNews ? newsFeedId : conversationId,
                text: "",
                richContent: {
                    poll: {
                        question,
                        allowMultipleAnswers,
                        options: formattedOptions,
                    },
                },
            },
        };

        try {
            await sendMessage({variables});
            trackEvent(TrackEvents.POLL_CREATED, {
                conversationId,
                accountRole: user.accountRole,
                question,
                options: formattedOptions.map((obj) => obj.option),
                allowMultipleAnswers,
            });
            handleClose();
        } catch (error) {
            setFieldError("serverError", error.message);
            reportError(error);
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        question: "",
        options: [
            {id: "1", option: ""},
            {id: "2", option: ""},
        ],
        allowMultipleAnswers: true,
        serverError: "",
    };

    const validationSchema = {
        question: string().required("Poll question is a required field"),
        options: array()
            .test("min-options", "Poll requires at least two options", (options) => {
                const validOptions = options.filter((obj: Option) => obj.option?.length > 0);
                return validOptions.length > 1;
            })
            .test("unique-options", "All options must be unique", (options) => {
                const validOptions = new Set(options.map((obj: Option) => obj.option));
                return validOptions.size === options.length;
            }),
    };

    return (
        <View flexGrow="1" backgroundColor="white">
            <Box overflow="scroll" flexGrow="1">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingY="medium"
                    paddingX="large"
                >
                    <Heading size="small" weight="400" level="2">
                        Create a poll
                    </Heading>
                    <Button
                        onClick={handleClose}
                        iconOnly
                        round
                        clear
                        aria-label="Close create poll"
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <View maxW="400px" marginX="auto" paddingY="xlarge">
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        <Stack space="medium">
                            <FormGroup label="Poll question">
                                <TextInput name="question" required maxLength={250} />
                            </FormGroup>
                            <OptionsContainer>
                                <Inline space="xxsmall">
                                    <OptionsLabel>Options</OptionsLabel>
                                    <RequiredFormIndicator />
                                </Inline>
                                <Stack space="xsmall">
                                    <Options />
                                    <ValidationError name="options" />
                                </Stack>
                            </OptionsContainer>

                            <FormGroup>
                                <CustomSwitch name="allowMultipleAnswers" />
                            </FormGroup>

                            <Stack space="xlarge">
                                <View
                                    padding="small-medium"
                                    borderRadius="xxsmall"
                                    borderWidth={1}
                                    borderColor="grey100"
                                >
                                    <Columns space="small">
                                        <Column width="content">
                                            <InfoIcon />
                                        </Column>
                                        <Column>
                                            <Text>
                                                When toggled on, respondents can select more than
                                                one answer. When toggled off, respondents can only
                                                select one answer.
                                            </Text>
                                        </Column>
                                    </Columns>
                                </View>
                                {error && (
                                    <MutationErrorHandler
                                        error={error}
                                        message="There was a problem creating the poll, please try again. If the problem persists please contact support."
                                        meta={{
                                            component: "CreatePoll",
                                            mutation: "useSendCommunityChatMessageMutation",
                                        }}
                                    />
                                )}

                                <SubmitButton block color="primary">
                                    Create poll
                                </SubmitButton>
                            </Stack>
                        </Stack>
                    </Form>
                </View>
            </Box>
        </View>
    );
}
