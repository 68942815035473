import React, {memo} from "react";
import {Platform} from "react-native";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {Box, Stack, Text, VisuallyHidden} from "@unibuddy/patron";
import {
    MessageRow,
    MessageBubbleDesktop,
    MessageBubbleMobile,
    MessageText,
} from "@unibuddy/chat-ui";
import {Position} from "@unibuddy/chat-ui/dist/Message/types";

import {ChatAvatar} from "../ChatAvatar";
import {MessageTop} from "../MessageTop";
import {ChatMessage} from "../../types";
import {shouldShowAvatar} from "./Message";

export type SimpleMessageProps = {
    item: ChatMessage;
    avatarSize: number;
    position: Position;
    isTouchDevice: boolean;
    userId: string;
};

export const SimpleMessage = memo<SimpleMessageProps>(
    ({item, avatarSize, position, isTouchDevice, userId}) => {
        const ChatBubbleComponent = isTouchDevice ? MessageBubbleMobile : MessageBubbleDesktop;
        const isMe = item.sender.id === userId;
        const align = isMe ? "right" : "left";
        const created = new Date(item.created);
        const replyText = `${item.sender.firstName ?? ""} says: `;

        const messageTime = formatDistanceToNow(created, {
            addSuffix: true,
        });
        return (
            <MessageRow
                as={Platform.OS === "web" ? "li" : undefined}
                avatar={shouldShowAvatar({
                    align,
                    avatar: (
                        <Box flexShrink={0} paddingX={["xxsmall", "xxsmall", "xsmall"]}>
                            <ChatAvatar
                                size={avatarSize}
                                sender={{
                                    id: item.sender.id ?? "",
                                    firstName: item.sender.firstName ?? "",
                                    accountRole: item.sender.accountRole ?? "applicant",
                                    profilePhoto: item.sender.profilePhoto ?? "",
                                    communityProfile: item.sender.communityProfile ?? undefined,
                                }}
                            />
                        </Box>
                    ),
                    position,
                })}
                align={align}
                position={position}
                addonTop={
                    !isMe && (position === "top" || position === "orphan") ? (
                        <Box aria-hidden>
                            <MessageTop sender={item.sender} />
                        </Box>
                    ) : null
                }
                addonBottom={
                    <Stack>
                        {position === "bottom" || position === "orphan" ? (
                            <Box aria-hidden paddingTop="xxsmall">
                                <Text size="xxsmall">
                                    {messageTime === "less than a minute ago"
                                        ? "a few seconds ago"
                                        : messageTime}
                                </Text>
                            </Box>
                        ) : null}
                    </Stack>
                }
            >
                {Platform.OS === "web" ? (
                    <VisuallyHidden>
                        <Text>{isMe ? "I say: " : replyText}</Text>
                    </VisuallyHidden>
                ) : null}
                <ChatBubbleComponent deleted={item.deleted} position={position} align={align}>
                    {item.text ? (
                        <MessageText deleted={item.deleted} align={align}>
                            {item.text}
                        </MessageText>
                    ) : null}
                </ChatBubbleComponent>
            </MessageRow>
        );
    },
);
