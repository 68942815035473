import {useEffect} from "react";
import * as yup from "yup";
import {ApolloError} from "@apollo/client";
import {Form, View, Text, useLocalStorage, Stack} from "@unibuddy/patron";
import {Rect} from "react-native-svg";
import {differenceInCalendarDays} from "date-fns";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import ThumbsUp from "ubcommunity-shared/src/Community/Images/ThumbsUp";
import {
    UniversityField,
    UniversityQuestion,
    useSaveUniversityQuestionAnswerMutation,
    useUniversityQuestionsQuery,
} from "ubcommunity-shared/src/types";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {STUDENT_CONFIDENCE_CTA_RECOMMENDATION} from "ubcommunity-shared/src/featureFlags";
import {Shimmer} from "ubcommunity-shared/src/General/Shimmer/Shimmer";
import {CTAWrapper} from "../General/CTAWrapper";
import {CTAFooter} from "../General/CTAFooter";
import {FeelConfidentQuestion} from "./StudentConfidenceQuestions";
import {SurveyQuestionLabel} from "../StudentEnrolledQuestions/questions/Question1";
import {Rating} from "./Rating/Rating";

const CONFIDENCE_RATING_QUESTION_ID = "111100000000000000000010";
const FEEL_CONFIDENT_QUESTION_ID = "111100000000000000000020";
const OTHER_OPTION_ID = "111100000000000000000026";
const CONFIDENCE_RATING = "confidenceRating";

type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

type QuestionProps = {
    data: DeepPartial<UniversityQuestion>;
    error: ApolloError;
    university?: UniversityField;
    handleSubmit: (...args: any[]) => any;
    handleDismiss: (...args: any[]) => any;
};

/**
 * CONFIDENCE SURVEY
 * Question (1 of 2):
 * How confident do you feel about enrolling at {UniversityName}?
 */
const Question1 = ({data, university, error, handleSubmit, handleDismiss}: QuestionProps) => {
    return (
        <Form
            initialValues={{confidenceRating: null}}
            validationSchema={{
                confidenceRating: yup.string().nullable().required("Please select a score"),
            }}
            onSubmit={handleSubmit}
        >
            {({values}: {values: {confidenceRating: string}}) => (
                <CTAWrapper
                    tagName="Survey"
                    handleDismiss={handleDismiss}
                    footer={
                        <CTAFooter
                            footerText="Question 1 of 2"
                            buttonText="Next"
                            isDisabled={values?.confidenceRating === null}
                        />
                    }
                >
                    <Stack space="medium">
                        <Rating
                            name={CONFIDENCE_RATING}
                            options={data.options}
                            label={
                                <SurveyQuestionLabel>
                                    {data?.text?.replace(
                                        "{universityName}",
                                        university?.name ?? "",
                                    )}
                                </SurveyQuestionLabel>
                            }
                        />
                        <View width="100%" justifyContent="space-between" flexDirection="row">
                            <Text size="small">Less confident</Text>
                            <Text size="small">Very confident</Text>
                        </View>
                    </Stack>
                    {error ? (
                        <MutationErrorHandler
                            error={error}
                            message={`Oh no! Something went wrong. Please try again later.`}
                            meta={{
                                component: "StudentConfidenceSurveyCTA.Q1",
                                mutation: "useSaveUniversityQuestionAnswerMutation",
                            }}
                        />
                    ) : null}
                </CTAWrapper>
            )}
        </Form>
    );
};

/**
 * CONFIDENCE SURVEY
 * Question (2 of 2):
 * What would make you feel more confident in your decision? Information about...
 */
const Question2 = ({data, handleSubmit, error, handleDismiss}: QuestionProps) => {
    const filteredOptions = data.options?.filter((option) => option?.id !== OTHER_OPTION_ID);
    const initialValues = filteredOptions?.reduce((mem, current) => {
        mem[current.id] = false;
        return mem;
    }, {});

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
            {({values}: {values: keyof typeof initialValues}) => (
                <CTAWrapper
                    tagName="Survey"
                    handleDismiss={handleDismiss}
                    footer={
                        <CTAFooter
                            footerText="Question 2 of 2"
                            buttonText="Submit"
                            isDisabled={Object.values(values).every((value) => !value)}
                        />
                    }
                >
                    <FeelConfidentQuestion question={data?.text ?? ""} options={filteredOptions} />
                    {error ? (
                        <MutationErrorHandler
                            error={error}
                            message={`Oh no! Something went wrong. Please try again later.`}
                            meta={{
                                component: "StudentConfidenceSurveyCTA.Q2",
                                mutation: "useSaveUniversityQuestionAnswerMutation",
                            }}
                        />
                    ) : null}
                </CTAWrapper>
            )}
        </Form>
    );
};

const SuccessModal = ({handleDismiss}: {handleDismiss: () => void}) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            handleDismiss();
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [handleDismiss]);

    return (
        <CTAWrapper tagName="Survey" handleDismiss={handleDismiss} footer={null}>
            <View w="100%" alignItems="center" justifyContent={"center"}>
                <View paddingBottom={"medium"}>
                    <ThumbsUp />
                </View>
                <Text style={{...boldFontStyles}} color="grey900">
                    Thanks for your feedback!
                </Text>
            </View>
        </CTAWrapper>
    );
};

export const StudentConfidenceSurveyCTA = () => {
    const SHOW_AGAIN_AFTER = 30; // in days

    const {university, selectedCommunity} = useCommunity();
    const isStudentConfidenceCTAFeatureEnabled = useIsFeatureEnabledBoolean(
        STUDENT_CONFIDENCE_CTA_RECOMMENDATION,
    );
    const [value, setValue] = useLocalStorage(LocalStorageNames.HAS_SHOWN_STUDENT_CONFIDENCE_CTA, {
        q1Submit: null,
        q2Submit: null,
        dismissed: null,
    });

    const {data, loading} = useUniversityQuestionsQuery({
        variables: {
            confidenceRatingQuestionInput: {
                context: {
                    type: "community",
                },
                questionId: CONFIDENCE_RATING_QUESTION_ID,
                universityId: university.id,
            },
            feelConfidentQuestionInput: {
                context: {
                    type: "community",
                },
                questionId: FEEL_CONFIDENT_QUESTION_ID,
                universityId: university.id,
            },
        },
    });

    const [saveAnswer, {error}] = useSaveUniversityQuestionAnswerMutation();

    const handleDismiss = () => {
        setValue({q1Submit: false, q2Submit: false, dismissed: new Date()});
    };

    if (!data) return;

    const handleNext = async (values: {confidenceRating: string}) => {
        const answer = data?.question1?.options?.find(
            (option) => option.id === values.confidenceRating,
        );
        await saveAnswer({
            variables: {
                saveUniversityQuestionAnswerInput: {
                    answers: [{id: answer?.id, text: answer?.text ?? ""}],
                    context: {
                        id: selectedCommunity?.id,
                        type: "community",
                    },
                    questionId: CONFIDENCE_RATING_QUESTION_ID,
                    universityId: university?.id,
                    universitySlug: university?.slug,
                },
            },
        });
        setValue({...value, q1Submit: true});
    };

    const normalizeValues = (values) => {
        // the web form provides the selections in a different
        // shape so we need to normalize it
        // answers shape needs to be [{id: "", text: ""}]
        let answers: {id: string; text: string}[] = [];
        if (values.hasOwnProperty("webVersionAnswers")) {
            values.webVersionAnswers.forEach((option: string) => {
                answers.push({
                    id: option,
                    text: data?.question2?.options?.find((o) => o.id === option)?.text ?? "",
                });
            });
        } else {
            answers = data?.question2?.options
                ?.filter((option) => values[option.id])
                .map((option) => ({id: option.id, text: option.text}));
        }

        if (values.others) {
            answers.push({id: OTHER_OPTION_ID, text: values.others});
        }

        return answers;
    };

    const handleSubmit = async (values) => {
        const answers = normalizeValues(values);
        await saveAnswer({
            variables: {
                saveUniversityQuestionAnswerInput: {
                    answers,
                    context: {
                        id: selectedCommunity?.id,
                        type: "community",
                    },
                    questionId: FEEL_CONFIDENT_QUESTION_ID,
                    universityId: university?.id,
                    universitySlug: university?.slug,
                },
            },
        });
        setValue({...value, q2Submit: true});
    };

    const shouldShowModel = (lastShowDate: string) => {
        if (!lastShowDate) {
            return true;
        }
        const differenceInDays = differenceInCalendarDays(
            new Date(Date.now()),
            new Date(lastShowDate),
        );

        if (differenceInDays < SHOW_AGAIN_AFTER) {
            return false;
        }
        return true;
    };

    if (isStudentConfidenceCTAFeatureEnabled && shouldShowModel(value.dismissed)) {
        if (loading) {
            return (
                <View
                    paddingX={["small", "none"]}
                    // @ts-ignore valid in RN
                    accessibilityLabel="Loading confidence survey"
                >
                    <Shimmer height={300}>
                        <Rect x="0" y="30" rx="8" ry="8" width="100%" height="250" />
                    </Shimmer>
                </View>
            );
        } else {
            return (
                <>
                    {!value.q1Submit ? (
                        <Question1
                            data={data.question1}
                            error={error}
                            university={university}
                            handleSubmit={handleNext}
                            handleDismiss={handleDismiss}
                        />
                    ) : null}
                    {value.q1Submit && !value.q2Submit ? (
                        <Question2
                            data={data.question2}
                            error={error}
                            handleSubmit={handleSubmit}
                            handleDismiss={handleDismiss}
                        />
                    ) : null}
                    {value.q2Submit ? <SuccessModal handleDismiss={handleDismiss} /> : null}
                </>
            );
        }
    }
    return null;
};
