import {IconUserCircle} from "@unibuddy/icons";
import {Box} from "@unibuddy/patron";
import React from "react";
import {UserAvatar} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {useUserProfile} from "ubcommunity-shared/src/Hooks/useUserProfile";
import theme from "ubcommunity-shared/src/theme";

type BottomTabUserAvatarProps = {
    isActive?: boolean;
};

type RingColor = "purple600" | "yellow500" | "transparent";

const getRingColor = (isActive: boolean, isUserProfileComplete: boolean): RingColor => {
    if (isActive) {
        return "purple600";
    }
    if (!isUserProfileComplete) {
        return "yellow500";
    }
    return "transparent";
};

export const BottomTabUserAvatar = ({isActive = false}: BottomTabUserAvatarProps) => {
    const {isUserProfileComplete, userProfile} = useUserProfile();

    if (!userProfile) {
        return (
            <Box
                width={25}
                height={25}
                bgColor="transparent"
                borderRadius="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <IconUserCircle
                    color={
                        isActive ? theme.colors?.textLinkColor : theme.colors?.textLinkColorInactive
                    }
                    data-testid="bottom-tab-avatar-icon"
                />
            </Box>
        );
    }

    return (
        <Box
            width={25}
            height={25}
            bgColor={getRingColor(isActive, isUserProfileComplete)}
            borderRadius="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
            data-testid="bottom-tab-avatar-outer"
        >
            {isActive || !isUserProfileComplete ? (
                <Box
                    width={21}
                    height={21}
                    bgColor={theme.colors.navbarColor}
                    borderRadius="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <UserAvatar size={19} user={userProfile} />
                </Box>
            ) : (
                <UserAvatar size={19} user={userProfile} />
            )}
        </Box>
    );
};
