import React from "react";
import styled from "styled-components";
import {Inline, Stack, Text, View} from "@unibuddy/patron";

import {Interest} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

type Props = {
    interests: Interest[];
};

const Chip = styled(View)`
    background: ${({theme}) => {
        return theme.TextInput.backgroundColor;
    }};
`;

export const UserInterests = ({interests}: Props) => {
    return (
        <Stack space="small">
            <Text
                size="small"
                // @ts-ignore valid in RN
                style={{...boldFontStyles}}
            >
                My interests
            </Text>
            <Inline space="xsmall">
                {interests.map((interest) => {
                    return (
                        <Chip borderRadius="full" padding="small">
                            <Text size="small" key={interest.id}>
                                {interest.name}
                            </Text>
                        </Chip>
                    );
                })}
            </Inline>
        </Stack>
    );
};
