import {AnalyticsValue} from "@unibuddy/tracking";

import {AnalyticsValues} from "ubcommunity-shared/src/constants/trackingConstants";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {SocketProvider} from "ubcommunity-shared/src/Sockets/SocketProvider";
import {SideDrawerProvider} from "ubcommunity-shared/src/General/SideDrawerProvider/SideDrawerProvider";
import RelativeSwitch from "ubcommunity-shared/src/Navigation/RelativeSwitch/RelativeSwitch";
import {PopupManager} from "ubcommunity-shared/src/General/PopupManager";
import RelativeRoute from "ubcommunity-shared/src/Navigation/RelativeRoute/RelativeRoute";
import {Routes} from "ubcommunity-shared/src/constants/routes";

import {DesktopPageLayout, DesktopPageWrapper, MobilePageLayout} from "../Layout/Page/Page";
import {DesktopInboxLayout, InboxLayout} from "../Layout/Inbox";
import {ChatPage} from "../Community/ChatPage/ChatPage";
import {HomePage} from "../StudentApp/HomePage";
import RelativeRedirect from "ubcommunity-shared/src/Navigation/RelativeRedirect/RelativeRedirect";
import {SettingsPage} from "../Profile/SettingsPage/SettingsPage";
import {MatchesListPage} from "../Community/MatchesListPage";
import {JoinGroupPage} from "../Community/JoinGroupPage/JoinGroupPage";
import {ChatPreview} from "../Community/GroupPreview/ChatPreview";
import {DiscoverPage} from "../Discover/DiscoverPage";
import {SpacesProvider} from "ubcommunity-shared/src/StudentApp/SpacesProvider/SpacesProvider";
import {PrivateGroupsFFWrapper} from "ubcommunity-shared/src/FeatureFlags/Wrappers/PrivateGroupsFFWrapper";
import {CreateGroupPage} from "../Community/CreateGroupPage/CreateGroupPage";
import {SignUpStepTwoPage} from "../SignUpStepTwo/SignUpStepTwoPage";
import {ToDoPage} from "../ToDo/ToDoPage";

export const StudentAppRoutes = () => {
    const {isDesktop} = useIsDesktop();

    return (
        <SocketProvider>
            <AnalyticsValue
                name={AnalyticsValues.APP_EXPERIENCE_KEY}
                value={AnalyticsValues.STUDENT_APP_EXPERIENCE}
            />
            {isDesktop ? (
                <SpacesProvider>
                    <DesktopPageWrapper>
                        <DesktopPageLayout>
                            <SideDrawerProvider>
                                <RelativeSwitch>
                                    <RelativeRoute path={Routes.HOME}>
                                        <HomePage />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.CREATE_GROUP}>
                                        <PrivateGroupsFFWrapper>
                                            <CreateGroupPage />
                                        </PrivateGroupsFFWrapper>
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.CHAT}>
                                        <DesktopInboxLayout />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.SETTINGS}>
                                        <SettingsPage />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.MATCHES}>
                                        <MatchesListPage />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.CHAT_PREVIEW}>
                                        <ChatPreview />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.JOIN_GROUP}>
                                        <JoinGroupPage />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.TODO}>
                                        <ToDoPage />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.DISCOVER}>
                                        <DiscoverPage />
                                    </RelativeRoute>
                                    <RelativeRoute path={Routes.SIGNUP_STEP_2}>
                                        <SignUpStepTwoPage />
                                    </RelativeRoute>
                                    <RelativeRoute path="/">
                                        <RelativeRedirect to={Routes.CHAT} />
                                    </RelativeRoute>
                                </RelativeSwitch>
                            </SideDrawerProvider>
                        </DesktopPageLayout>
                    </DesktopPageWrapper>
                </SpacesProvider>
            ) : (
                <SpacesProvider>
                    <SideDrawerProvider>
                        <PopupManager />
                        <RelativeSwitch>
                            <RelativeRoute path={Routes.CREATE_GROUP}>
                                <MobilePageLayout hideHeader>
                                    <PrivateGroupsFFWrapper>
                                        <CreateGroupPage />
                                    </PrivateGroupsFFWrapper>
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.CHAT_GROUP}>
                                <ChatPage />
                            </RelativeRoute>
                            <RelativeRoute path={Routes.HOME}>
                                <MobilePageLayout hideHeader>
                                    <HomePage />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.CHAT}>
                                <MobilePageLayout hideHeader>
                                    <InboxLayout />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.SETTINGS}>
                                <MobilePageLayout hideHeader>
                                    <SettingsPage />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.MATCHES}>
                                <MatchesListPage />
                            </RelativeRoute>
                            <RelativeRoute path={Routes.JOIN_GROUP}>
                                <MobilePageLayout>
                                    <JoinGroupPage />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.TODO}>
                                <MobilePageLayout hideHeader>
                                    <ToDoPage />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.DISCOVER}>
                                <MobilePageLayout hideHeader>
                                    <DiscoverPage />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path={Routes.SIGNUP_STEP_2}>
                                <MobilePageLayout hideHeader>
                                    <SignUpStepTwoPage />
                                </MobilePageLayout>
                            </RelativeRoute>
                            <RelativeRoute path="/">
                                <RelativeRedirect to={Routes.CHAT} />
                            </RelativeRoute>
                        </RelativeSwitch>
                    </SideDrawerProvider>
                </SpacesProvider>
            )}
        </SocketProvider>
    );
};
