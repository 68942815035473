import {View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {ErrorBoundary} from "@unibuddy/error-reporting";

import {useBlogPostsQueryQuery, useGetUniversityFeaturesQuery} from "ubcommunity-shared/src/types";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {COMMUNITY_APP_URL, TrackEvents} from "ubcommunity-shared/src/constants";
import {CONTENT} from "ubcommunity-shared/src/featureFlags";

import {ContentShimmer} from "./ContentShimmer";
import {ContentCards, ContentViewMoreCard} from "./ContentCards";
import {Carousel} from "./Carousel";

const FEATURED_CONTENT = "userGeneratedContent";
const CONTENT_LIMIT = 4;

export function ContentFlagged() {
    const [navigate] = useNavigate();
    const {university} = useCommunity();
    const {trackEvent} = useAnalytics();
    const {data: features} = useGetUniversityFeaturesQuery({
        variables: {
            universitySlug: university.slug,
        },
    });
    const {data, loading} = useBlogPostsQueryQuery({
        variables: {
            universitySlug: university.slug,
            offset: 0,
            limit: 5,
            topics: [],
            randomise: true,
        },
    });

    if (loading) {
        return <ContentShimmer />;
    }

    const universityContentValues = features?.university.config.features.find(
        (item) => item.feature.name === FEATURED_CONTENT,
    );
    const isUniversityContentEnabled =
        universityContentValues?.enabledByUnibuddy && universityContentValues?.enabledByUniversity;

    const contentLength = data?.allPostsInfo.blogPosts.length || 0;

    if (!data || !isUniversityContentEnabled || !contentLength) {
        return null;
    }

    const handleHeaderLink = () => {
        trackEvent(TrackEvents.CONTENT_VIEW_ALL_CLICKED, {
            type: "Header",
        });
        navigate({
            path: Routes.CONTENT_VIEW,
            options: {
                url: `${COMMUNITY_APP_URL}/u/${university.slug}/native/blog`,
            },
        });
    };

    const isMore = contentLength > CONTENT_LIMIT;
    const blogPosts = data?.allPostsInfo.blogPosts.slice(0, CONTENT_LIMIT);

    return (
        <ErrorBoundary fallback={null}>
            <View width="100%" marginTop="xlarge">
                <Carousel
                    data={blogPosts}
                    renderItem={(item) => <ContentCards key={item.id} post={item} />}
                    renderLastItem={() => (isMore ? <ContentViewMoreCard /> : null)}
                    title="Suggested content"
                    offset="small-medium"
                    itemGap="medium"
                    headerLink={
                        data.allPostsInfo.blogPosts.length > 3 ? handleHeaderLink : undefined
                    }
                />
            </View>
        </ErrorBoundary>
    );
}

export const Content = () => {
    const isFeatureEnabled = useIsFeatureEnabledBoolean(CONTENT);

    if (!isFeatureEnabled) {
        return null;
    }

    return <ContentFlagged />;
};
