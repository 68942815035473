import React, {useState} from "react";
import {Pressable} from "react-native";

import {Inline, Stack, Text, View} from "@unibuddy/patron";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";

import {UserInterests} from "./UserInterests";
import {HousingStatus} from "./HousingStatus";
import {HousingCategory} from "ubcommunity-shared/src/types";

export const ProfileItem = ({
    title,
    text,
    trimAfter,
    textIcon,
}: {
    title: string;
    text: string;
    textIcon?: React.ReactNode;
    trimAfter?: number;
}) => {
    const [viewMore, setViewMore] = useState(false);

    const isTextLong = () => {
        return trimAfter && text.length > trimAfter;
    };

    const trimmedText = () => {
        let finalText = text;

        if (isTextLong() && !viewMore) {
            finalText = `${text.substring(0, trimAfter)}...`;
        }

        return finalText.trim();
    };

    return (
        <Stack space="small">
            <Text
                size="small"
                // @ts-ignore valid in RN
                style={{...boldFontStyles}}
            >
                {title}
            </Text>
            <Inline space="xsmall" verticalAlign="bottom" wrap="nowrap">
                <Text size="medium" weight="400">
                    {trimmedText()}
                </Text>
                {textIcon}
            </Inline>
            {isTextLong() ? (
                <View w="100%" alignItems="flex-end">
                    <Pressable
                        accessibilityLabel={!viewMore ? "View more" : "View less"}
                        onPress={() => setViewMore(!viewMore)}
                    >
                        <Text
                            size="small"
                            // @ts-ignore valid in RN
                            style={{
                                textDecorationLine: "underline",
                            }}
                        >
                            {!viewMore ? "View more" : "View less"}
                        </Text>
                    </Pressable>
                </View>
            ) : null}
        </Stack>
    );
};

export const ProfileItems = ({user, loading}: {user: any; loading: boolean}) => {
    if (loading)
        return (
            <View paddingY="large" w="100%">
                <ActivityIndicator accessibilityLabel="Loading user profile" />
            </View>
        );

    const areaOfStudy = user?.communityProfile?.areaOfStudy
        ? user?.communityProfile?.areaOfStudy[0]?.name
        : null;

    const bio = user.communityProfile?.bio ? user.communityProfile?.bio : null;

    const country = user.country?.name ? user.country?.name : null;

    const pronouns = user.communityProfile?.pronouns ? user.communityProfile?.pronouns : null;

    const interests = user.interests?.length ? user.interests : null;

    const housing =
        user.communityProfile?.housing &&
        user.communityProfile?.housing?.category !== HousingCategory.Na
            ? user.communityProfile?.housing
            : null;

    return (
        <Stack space="large">
            {bio ? <ProfileItem title="A bit about me" text={bio} trimAfter={120} /> : null}

            {areaOfStudy ? <ProfileItem title="I'm studying" text={areaOfStudy} /> : null}

            {housing ? <HousingStatus housing={housing} /> : null}

            {country ? <ProfileItem title="I'm from" text={country} /> : null}

            {pronouns ? <ProfileItem title="Pronouns" text={pronouns} /> : null}

            {interests ? <UserInterests interests={interests} /> : null}
        </Stack>
    );
};
