import React from "react";

const ChevronDownIcon = (props) => (
    <svg
        fill="currentColor"
        preserveAspectRatio="xMidYMid meet"
        width={props.size || 18}
        height={props.size || 18}
        viewBox="0 0 36 40"
        {...props}
    >
        <g>
            <path d="m17.5 23.3l13.6-13.1c0.3-0.3 0.9-0.3 1.2 0l2.5 2.4c0.3 0.3 0.3 0.9 0 1.2l-16.7 16c-0.1 0.1-0.4 0.2-0.6 0.2s-0.5-0.1-0.6-0.2l-16.7-16c-0.3-0.3-0.3-0.9 0-1.2l2.5-2.4c0.3-0.3 0.9-0.3 1.2 0z"></path>
        </g>
    </svg>
);

export default ChevronDownIcon;
