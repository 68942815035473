/**
 * @deprecated This file is deprecated and will be removed in the future. Please don't use any exports
 * Please use [useConversation] to infer the same information from it
 *
 * Reason: ConversationProvider now sits as the central information holder for all conversation related information
 */

import {useGetCommunityConversationQuery} from "../types";

export enum GroupTypes {
    DIRECT = "Direct",
    PRIVATE = "Private",
    NEWS_FEED = "NewsFeed",
    PUBLIC = "Public",
}

type Props = {
    conversationId: string;
};

/**
 * @deprecated This hook is deprecated. Please use [useConversation] to infer the same information from it
 */
export const useGetGroupType = ({conversationId}: Props) => {
    const {data} = useGetCommunityConversationQuery({
        variables: {id: conversationId},
        fetchPolicy: "cache-only",
    });

    const isPrivate = data?.getChatConversation?.isPrivate;
    const isDirect = data?.getChatConversation?.isDirect;
    const isNewsFeed = data?.getChatConversation?.isNewsFeed;

    return isDirect
        ? GroupTypes.DIRECT
        : isPrivate
        ? GroupTypes.PRIVATE
        : isNewsFeed
        ? GroupTypes.NEWS_FEED
        : GroupTypes.PUBLIC;
};
