import React, {useEffect, useState} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {View, Text, Stack, Heading, Button, Box} from "@unibuddy/patron";
import {differenceInCalendarMonths} from "date-fns";

import {useGetUserCreatedByUserIdQuery} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {
    useSaveUserDecisionStageMutation,
    useSaveNotInterestedStageMutation,
    DecisionStageEnum,
    NotInterestedReasonEnum,
    DecisionStageLocationEnum,
} from "ubcommunity-shared/src/types";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";

import {DefaultPopupWrapper} from "../DefaultPopup/DefaultPopupWrapper";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {WaveHand} from "ubcommunity-shared/src/Svgs/WaveHand";
import {WaveHandDark} from "ubcommunity-shared/src/Svgs/WaveHandDark";

export const popUpCopy = {
    title: "Hey! Quick question from us...",
    text: "How is your search for the right university going? This info will help shape your Community experience.",
    options: [
        {
            text: "I’m at this university now",
            decision: DecisionStageEnum.Admitted,
            reason: null,
        },
        {text: "I’m still waiting to start", decision: DecisionStageEnum.Applied, reason: null},
        {
            text: "I’m going to a different university",
            decision: DecisionStageEnum.NotInterested,
            reason: NotInterestedReasonEnum.NotInterestedOther,
        },
        {
            text: "I decided not to go to university",
            decision: DecisionStageEnum.NotInterested,
            reason: NotInterestedReasonEnum.NotInterestedInApplyingToUni,
        },
    ],
};
export const successText = "Thanks for letting us know!";

export const DATE_NOW = new Date(Date.now());

export const MONTH_USAGE_TIME = 2;

const hasTimeElapsed = (dateCreated: string): boolean => {
    if (differenceInCalendarMonths(DATE_NOW, new Date(dateCreated)) >= MONTH_USAGE_TIME) {
        return true;
    }
    return false;
};

type FeedbackPopupProps = {
    handleLastShowPopup: () => void;
    setIsPopupActive: (p: boolean) => void;
    handleNextPopup: () => void;
};

export const StudentEnrollmentStage = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: FeedbackPopupProps) => {
    const auth = useAuth();

    const {data, loading} = useGetUserCreatedByUserIdQuery({
        variables: {
            id: auth.user.id,
        },
    });

    if (!data || loading) {
        return null;
    }

    const HAS_TIME_ELAPSED = hasTimeElapsed(data.getUser.created);

    if (!HAS_TIME_ELAPSED) {
        handleNextPopup();
        return null;
    }

    return (
        <StudentEnrollmentStageActive
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );
};

/**
 * @author
 * @function @StudentEnrollmentStage
 **/
export const StudentEnrollmentStageActive = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: FeedbackPopupProps) => {
    const {
        value: hasShown,
        setValue,
        loading,
    } = useAsyncLocalStorage(LocalStorageNames.HAS_SHOWN_STUDENT_ENROLLMENT_STAGE, false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [saveDecisions] = useSaveUserDecisionStageMutation();
    const [saveNotInterested] = useSaveNotInterestedStageMutation();
    const {university} = useCommunity();
    const {trackEvent} = useAnalytics();
    const {darkModeEnabled} = useCommunityTheme();

    useEffect(() => {
        if (!loading && !hasShown) {
            setIsPopupActive(true);
        }
        if (!loading && hasShown) {
            handleNextPopup();
        }
    }, [hasShown, loading]);

    const handleSubmit = async (decision: DecisionStageEnum, reason: NotInterestedReasonEnum) => {
        if (!reason) {
            await saveDecisions({
                variables: {
                    decisionStageInput: {
                        decisionStage: decision,
                        universityId: university.id,
                        decisionStageLocation: DecisionStageLocationEnum.CommunityPopUp,
                    },
                },
            });
        } else {
            await saveNotInterested({
                variables: {
                    notInterestedStageInput: {
                        decisionStage: decision,
                        notInterestedReason: reason,
                        universityId: university.id,
                        decisionStageLocation: DecisionStageLocationEnum.CommunityPopUp,
                    },
                },
            });
        }
        trackEvent(TrackEvents.STUDENT_HAS_SUBMITTED_ENROLLMENT_STAGE, {
            enrollmentStage: decision,
        });
        setIsSubmitted(true);
    };

    const handleClick = () => {
        setValue(true);
        handleLastShowPopup();
    };

    return (
        <DefaultPopupWrapper
            isContextMenuOpen={!loading && !hasShown}
            setIsContextMenuOpen={handleClick}
        >
            <View padding="large" marginTop="large">
                {isSubmitted ? (
                    <Box paddingBottom="medium">
                        <Heading level="4" align="center">
                            {successText}
                        </Heading>
                    </Box>
                ) : (
                    <Stack space="medium">
                        <View alignItems="center" flex={1}>
                            {darkModeEnabled ? <WaveHandDark size={42} /> : <WaveHand size={42} />}
                        </View>
                        <Heading level="4" align="center">
                            {popUpCopy.title}
                        </Heading>
                        <Text size="medium" align="center">
                            {popUpCopy.text}
                        </Text>
                        <Stack space="small">
                            {popUpCopy.options.map((item, index) => (
                                <Button
                                    name={item.text}
                                    ghost
                                    block
                                    key={`button-student-enrollment-option-${index}`}
                                    color="primary"
                                    onClick={() => handleSubmit(item.decision, item.reason)}
                                    style={{
                                        borderColor: darkModeEnabled ? "#CFC8FF" : "primary",
                                    }}
                                >
                                    <Text
                                        size="large"
                                        style={{...boldFontStyles}}
                                        color={darkModeEnabled ? "#CFC8FF" : "primary"}
                                    >
                                        {item.text}
                                    </Text>
                                </Button>
                            ))}
                        </Stack>
                    </Stack>
                )}
            </View>
        </DefaultPopupWrapper>
    );
};
