import {RequestInstitution, RequestInstitutionProps} from "./RequestInstitution";

export function RequestModal({searchText, handleSubmit, handleDismiss}: RequestInstitutionProps) {
    return (
        <RequestInstitution
            searchText={searchText}
            handleSubmit={handleSubmit}
            handleDismiss={handleDismiss}
        />
    );
}
