import React, {FC} from "react";
import PropTypes from "prop-types";
import {Image} from "react-native";
import {getDynamicImageUrlV2} from "ubcommunity-shared/src/Patron/getDynamicUrlV2";

function getMentorImageSrc(imageUrl?: string) {
    let profilePhoto = "";
    if (typeof imageUrl === "string") {
        const urlParts = imageUrl.split("/");
        profilePhoto = urlParts[urlParts.length - 1];
    }
    return profilePhoto;
}

export const MentorAvatar: FC<{
    user: {firstName?: string; profilePhoto?: string};
    width: number;
    height: number;
    [key: string]: any;
}> = ({user, width, height, ...props}) => {
    const profilePhoto = getMentorImageSrc(user.profilePhoto);

    return (
        <Image
            {...props}
            width={width}
            height={height}
            style={{...props.style, width, height, backgroundColor: "#fff"}}
            aria-label={user.firstName}
            accessibilityLabel={user.firstName}
            source={{
                width,
                height,
                uri: getDynamicImageUrlV2(profilePhoto, {
                    width,
                    height,
                }),
            }}
        />
    );
};

MentorAvatar.propTypes = {
    imageUrl: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

MentorAvatar.defaultProps = {
    width: 35,
    height: 35,
};
