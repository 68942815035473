import {useCallback, useEffect} from "react";
import {FlashList} from "@shopify/flash-list";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {View, usePatronTheme} from "@unibuddy/patron";

import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";
import {Member, IMember} from "ubcommunity-shared/src/Chat/ChatDrawer/Member";
import {ListFooter, PeopleLoading} from "ubcommunity-shared/src/People/PeopleLoading";
import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {DIRECT_MESSAGING_FEATURE_FLAG} from "ubcommunity-shared/src/featureFlags";
import {AdminFeatureFlags, TrackSources} from "ubcommunity-shared/src/constants";

import {PeopleEmptyResults} from "./PeopleEmptyResults";
import {usePeopleFilters} from "./PeopleFiltersProvider";
import type {UserField} from "./PeopleFiltersProvider";

export const PeopleListing = ({
    onShowUserSummary,
}: {
    onShowUserSummary?: (userId: string, mixpanelMetadata: MixpanelMetadataProps) => void;
}) => {
    const keyExtractor = useCallback(
        (item: IMember, index: number) => `item-${item.id}-${index}`,
        [],
    );
    const {loading, results, totalCount, fetchNext, fetchNextLoading, fetch} = usePeopleFilters();
    const {bottom} = useSafeAreaInsets();
    const theme = usePatronTheme();

    const isDmsAdminEnabled = useIsFeatureAdminEnabled(AdminFeatureFlags.DIRECT_MESSAGES_SETTINGS);
    const isDmsFFEnabled = useIsFeatureEnabledBoolean(DIRECT_MESSAGING_FEATURE_FLAG);
    const isDmsEnabled = isDmsFFEnabled && isDmsAdminEnabled;

    useEffect(() => {
        fetch();
    }, [fetch]);

    if (loading) return <PeopleLoading isDmsEnabled={isDmsEnabled} />;

    return (
        <FlashList
            keyExtractor={keyExtractor}
            data={results ?? []}
            estimatedItemSize={65}
            contentContainerStyle={{paddingBottom: bottom}}
            onEndReached={() => {
                if (results.length < totalCount) {
                    fetchNext();
                }
            }}
            onEndReachedThreshold={0.5}
            ListEmptyComponent={<PeopleEmptyResults />}
            ListFooterComponent={<ListFooter loading={fetchNextLoading} />}
            renderItem={({item}: {item: UserField}) => (
                <View
                    marginX="small-medium"
                    paddingY="medium"
                    borderBottomWidth={1}
                    borderColor={theme.Divider?.bgColor}
                >
                    <Member
                        member={item as IMember}
                        onShowUserSummary={onShowUserSummary}
                        isDmsEnabled={isDmsEnabled}
                        source={TrackSources.PEOPLE_LIST}
                    />
                </View>
            )}
        />
    );
};
