import React from "react";
import {Platform, ScrollView} from "react-native";
import styled from "styled-components/native";
import {Box, View, Stack, Inline, Text, VisuallyHidden, Column, Columns} from "@unibuddy/patron";
import {MessageText} from "@unibuddy/chat-ui";
import {format} from "date-fns";
import {useSafeAreaInsets} from "react-native-safe-area-context";

import {useHasOneOfRoles} from "ubcommunity-shared/src/Auth/hooks/useHasRole";
import {ChatAvatar} from "../ChatAvatar";
import PinnedMessageIcon from "ubcommunity-shared/src/Icons/PinnedMessage";
import {usePin} from "../usePin";
import {ChatMessage} from "ubcommunity-shared/src/types";
import {Gif} from "../Gif/Gif";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export const adminAccountRoles = ["admin", "university", "mentor", "staff"];

const PinnedMessageEmptyState = () => {
    return (
        <View flex="1" padding="medium">
            <Stack space="medium">
                <Text size="medium" style={{...boldFontStyles}}>
                    There are no Pinned Messages
                </Text>
                <Text size="medium">
                    Messages will be visible here when they have been pinned by an administrator.
                </Text>
            </Stack>
        </View>
    );
};

const PinnedButton = styled.TouchableOpacity`
    color: ${({theme}) => theme?.colors?.darkGrey};
`;

export const PinnedMessagesComponent = ({conversationId}: {conversationId: string}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const {bottom} = useSafeAreaInsets();
    const {pinnedMessages, loading, onPin} = usePin(conversationId);

    const canUnPin = useHasOneOfRoles(adminAccountRoles);

    if (!pinnedMessages?.length && !loading) {
        return <PinnedMessageEmptyState />;
    }

    const formatDate = (date: string) => format(new Date(date), "h:mmbbb, EEEE do MMMM");

    return (
        <View
            {...Platform.select({
                web: {
                    height: "100%",
                    overflow: "auto",
                    padding: "medium",
                },
                native: {
                    as: ScrollView,
                    flex: 1,
                    contentContainerStyle: {flexGrow: 1, paddingBottom: bottom + 20},
                    padding: "medium",
                },
            })}
        >
            {loading ? (
                <VisuallyHidden>
                    <Text>Loading Pinned Messages</Text>
                </VisuallyHidden>
            ) : (
                <Stack space="medium">
                    {pinnedMessages?.map((message: ChatMessage) => (
                        <Box
                            key={`pinned-message-${message.id}`}
                            display="flex"
                            flex="1"
                            borderBottomWidth={1}
                            borderColor="navbarBorderColor"
                            paddingBottom="small"
                        >
                            <Stack space="medium">
                                <Inline space={["small", "small"]} verticalAlign="center">
                                    {message.sender ? (
                                        <ChatAvatar size={40} sender={message.sender} />
                                    ) : null}
                                    <Text>{message.sender.firstName}</Text>
                                </Inline>
                                {message.richContent?.giphy && !message.deleted ? (
                                    <View borderRadius="xsmall" overflow="hidden">
                                        <Gif
                                            id={message.richContent?.giphy.id}
                                            aspectRatio={message.richContent?.giphy.aspectRatio}
                                        />
                                    </View>
                                ) : null}
                                {message.text ? (
                                    <MessageText align="center">{message.text}</MessageText>
                                ) : null}

                                <Stack space="xsmall">
                                    <Text
                                        color={!darkModeEnabled ? "lightGrey" : "white"}
                                        size="xxsmall"
                                    >
                                        {formatDate(message.created)}
                                    </Text>
                                    {canUnPin ? (
                                        <PinnedButton
                                            data-test-id={`un-pin-message-${message.id}`}
                                            onPress={() => {
                                                onPin(message);
                                            }}
                                        >
                                            <Columns space="xsmall" verticalAlign="center">
                                                <Column width="content">
                                                    <PinnedMessageIcon
                                                        height={20}
                                                        width={20}
                                                        color={
                                                            !darkModeEnabled ? "#404446" : "white"
                                                        }
                                                    />
                                                </Column>
                                                <Column width="content">
                                                    <Text size="xsmall">Un-pin message</Text>
                                                </Column>
                                            </Columns>
                                        </PinnedButton>
                                    ) : null}
                                </Stack>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            )}
        </View>
    );
};

export const PinnedMessages = React.memo(PinnedMessagesComponent);
