import {Form} from "@unibuddy/patron";

import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {RadioButtonFormGroup} from "ubcommunity-shared/src/Patron/RadioButtonFormGroup";
import {RadioButtonGroupItem} from "ubcommunity-shared/src/Patron/RadioButtonGroupItem";
import {UniversityQuestion} from "ubcommunity-shared/src/types";
import {DeepPartial} from "ubcommunity-shared/src/Explore/StudentEnrolledQuestions/questions/types";

import {QuestionProps} from "./types";

import {CTAWrapper} from "../../General/CTAWrapper";
import {CTAFooter} from "../../General/CTAFooter";
import {ENROLLED_MORE_INFO} from "../StudentEnrolledQuestionsCTA";
import {SurveyQuestionLabel} from "./Question1";

type optionsType = {
    __typename?: "UniversityQuestionOption";
    id?: any;
    text?: string;
    active?: boolean;
};

export const ExtraEnrolledQuestions = ({
    question,
    options,
}: {
    question: DeepPartial<UniversityQuestion>;
    options: optionsType[];
}) => {
    return (
        <RadioButtonFormGroup
            name="currentStatus"
            label={<SurveyQuestionLabel>{question.text}</SurveyQuestionLabel>}
            accessibilityLabel={question}
            aria-labelledby={question}
        >
            {options.map((option: optionsType) => (
                <RadioButtonGroupItem
                    value={option}
                    key={`question-3-options-${option.id}`}
                    accessibilityLabel={option.text}
                >
                    {option.text}
                </RadioButtonGroupItem>
            ))}
        </RadioButtonFormGroup>
    );
};

/**
 * ENROLMENT SURVEY
 * Question (2 of 2) - if answered No to question 1:
 * What's your current status?
 */
export const Question3 = ({data, error, handleSubmit, handleDismiss}: QuestionProps) => {
    const filteredOptions = data?.options?.filter((option) => option.id !== ENROLLED_MORE_INFO);
    const initialValues: {currentStatus: string | null} = {
        currentStatus: null,
    };

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
            <CTAWrapper
                tagName="Survey"
                handleDismiss={handleDismiss}
                footer={
                    <CTAFooter footerText="Question 2 of 2" buttonText="Next" isDisabled={false} />
                }
            >
                <ExtraEnrolledQuestions question={data} options={filteredOptions} />
                {error ? (
                    <MutationErrorHandler
                        error={error}
                        message={`Oh no! Something went wrong. Please try again later.`}
                        meta={{
                            component: "StudentEnrolledSurveyCTA.Q3",
                            mutation: "useSaveUniversityQuestionAnswerMutation",
                        }}
                    />
                ) : null}
            </CTAWrapper>
        </Form>
    );
};
