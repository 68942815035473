import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {TrackEvents, UserAccountRoles} from "ubcommunity-shared/src/constants";
import {FeatureFlags} from "ubcommunity-shared/src/featureFlags";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useStringFeatureFlagWrapper} from "../FeatureFlags/StringFlag/useStringFeatureFlagWrapper";
import {SpaceType, useSpaces} from "../StudentApp/SpacesProvider";
import {useIsStudentAppExperience} from "../Hooks/useIsStudentAppExperience";
import {useEffect, useMemo} from "react";

export const useShowDiscoverTab = () => {
    const {user} = useAuth();
    const {spaces} = useSpaces();
    const {loading} = useIsStudentAppExperience();
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    // feature flag is on
    const isFeatureFlagEnabled = useIsFeatureEnabledBoolean(FeatureFlags.DISCOVER);

    // user is an applicant
    const isApplicant = user?.accountRole === UserAccountRoles.APPLICANT;

    // university is not being a bitch about their prospects seeing this feature
    const unisInKillSwitchStrings: string = useStringFeatureFlagWrapper(
        FeatureFlags.STUDENT_APP_DISCOVER_UNIVERSITY_KILL_SWITCH,
        "[]",
    );
    let unisInKillSwitch: Array<string> = useMemo(() => [], []);
    try {
        unisInKillSwitch = JSON.parse(unisInKillSwitchStrings);
    } catch (error) {
        console.log(error);
        reportError(error);
    }
    const unisInSpaces = spaces.map((space) => space.slug);
    let isNotBlockedByUni = !unisInKillSwitch.some((uni) => unisInSpaces.includes(uni));
    if (spaces.length > 1) {
        isNotBlockedByUni = true;
    }

    // user is not part of any communities and has no invites
    const isPartOfACommunity = spaces.some((space) => space.type === SpaceType.COMMUNITY);
    const hasInvite = spaces.some((space) => space.type === SpaceType.INVITE);
    const isNotAnOfferHolder = !(isPartOfACommunity || hasInvite);

    const showDiscoverTab =
        isFeatureFlagEnabled && isApplicant && isNotBlockedByUni && isNotAnOfferHolder && !loading;

    useEffect(() => {
        if (!isNotBlockedByUni) {
            trackEvent(TrackEvents.DISCOVER_HIDDEN_BASED_ON_UNIVERSITY);
        }
    }, [isNotBlockedByUni, trackEvent]);

    return {
        showDiscoverTab,
    };
};
