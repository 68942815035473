import React from "react";
import {ScrollView, TouchableOpacity, ViewStyle} from "react-native";
import {View, colors, Text} from "@unibuddy/patron";
import {IconXMark} from "@unibuddy/icons";

type Item = {
    id: string;
    label: string;
    type?: string;
};

type PillProps = {
    item: Item;
    onSelect: (id: string, options?: Record<string, any>) => void;
    maxLength?: number;
};

const trimContent = (text: string, maxLength?: number) => {
    if (!maxLength) return text;
    return text.length > maxLength ? `${text.substring(0, maxLength)} ...` : text;
};

const Pill = ({item, onSelect, maxLength}: PillProps) => (
    <TouchableOpacity
        accessibilityRole="button"
        onPress={() => onSelect(item.id, {type: item.type})}
        style={{
            backgroundColor: colors.teal100,
            borderColor: colors.teal800,
            borderWidth: 1,
            borderRadius: 50,
        }}
    >
        <View
            flexDirection="row"
            alignItems="center"
            paddingY="xxsmall"
            paddingX="small"
            gap="xxsmall"
        >
            <IconXMark width="16px" height="16px" color={colors.teal900} />
            <Text color="teal900" size="small">
                {trimContent(item.label, maxLength)}
            </Text>
        </View>
    </TouchableOpacity>
);

type PillsProps = {
    items: Item[];
    onSelect: (id: string, options?: Record<string, any>) => void;
    labelMaxLength?: number;
    containerStyle?: ViewStyle;
};

export const Pills = ({items, onSelect, containerStyle = {}, labelMaxLength}: PillsProps) => {
    return (
        <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
                ...containerStyle,
                marginTop: 2,
                marginLeft: 2,
                marginBottom: 2,
            }}
        >
            {items.map((item) => (
                <View key={item.id} style={{marginRight: 6}}>
                    <Pill item={item} onSelect={onSelect} maxLength={labelMaxLength} />
                </View>
            ))}
        </ScrollView>
    );
};
