import {View} from "@unibuddy/patron";
import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {Community, UniversityField, useGetSpacesQuery} from "ubcommunity-shared/src/types";
import {IdentifyUser} from "./IdentifyUser";

type SpaceValue = Space | null;
type SpacesContextType = {
    spaces: Space[];
    selectedSpace: SpaceValue;
    refetch: () => void;
    setSelectedSpace: (space: SpaceValue) => void;
    selectCommunitySpace: (communityId: string) => void;
};

export enum SpaceType {
    COMMUNITY = "COMMUNITY",
    UNIVERSITY = "UNIVERSITY",
    INVITE = "INVITE",
}
export type Space = {
    id: string;
    type: SpaceType;
    name: string;
    slug: string;
    logo?: string;
    title?: string;
};

export const SpacesContext = createContext<SpacesContextType>({
    spaces: [],
    selectedSpace: null,
    refetch: () => {},
    setSelectedSpace: (_: SpaceValue) => {},
    selectCommunitySpace: (_: string) => {},
});

export enum SpacesProviderStrings {
    loadingSpaces = "Loading your spaces ...",
}

/**
 * State management for the select space the user is in.
 * Applicable for university and community spaces.
 *
 * @returns A state setter "setSelectedSpace" and the selected space "selectedSpace".
 */
export const useSpaces = () => {
    return useContext(SpacesContext);
};

const validateUniversity = (university: UniversityField) => {
    if (!university) {
        return null;
    }
    const {id, slug, name} = university;

    if (!id || !slug || !name) {
        return null;
    }

    return {
        id,
        slug,
        name,
        squareLogo: university.squareLogo ?? "",
    };
};

export const SpacesProvider = ({children}: PropsWithChildren) => {
    const {user} = useAuth();
    const {select, selectedCommunity} = useCommunity();
    const [selectedSpace, setSelectedSpace] = useState<SpaceValue>(null);
    const [spaces, setSpaces] = useState<Space[]>([]);
    const [communities, setCommunities] = useState<Community[]>();

    const selectCommunitySpace = useCallback(
        (communityId: string) => {
            const community = communities?.find((community) => community.id === communityId);

            if (community) {
                select(community);
            }
        },
        [select, communities],
    );

    const {loading, error, refetch} = useGetSpacesQuery({
        skip: !user,
        variables: {
            userId: user?.id ?? "",
            email: user?.email ?? "",
        },
        onCompleted: (data) => {
            const links = data.me?.applicant?.applicantUniversities;
            const communities = data.getUser?.communities ?? [];
            const invites = data.getInvitesForEmail ?? [];

            const universities = links?.map((link) => link?.university);
            const validUniversities = universities?.map(validateUniversity);
            const validSpaces = validUniversities?.filter((uni) => !!uni);

            const validUniversitySpaces =
                validSpaces?.map((university) => ({
                    id: university.id,
                    type: SpaceType.UNIVERSITY,
                    name: university.name,
                    slug: university.slug,
                    logo: university.squareLogo ?? "",
                })) ?? [];

            const validCommunitySpaces =
                communities?.map((community) => {
                    const university = community.institutions ? community.institutions[0] : null;

                    return {
                        id: community.id,
                        type: SpaceType.COMMUNITY,
                        name: university?.name ?? "",
                        slug: university?.slug ?? "",
                        logo: university?.squareLogo ?? "",
                        title: community.title ?? "",
                    };
                }) ?? [];

            const validInviteSpaces =
                invites?.map((invite) => {
                    const university = invite?.community?.institutions
                        ? invite.community.institutions[0]
                        : null;

                    return {
                        id: invite?.community?.id ?? "",
                        type: SpaceType.INVITE,
                        name: university?.name ?? "",
                        slug: university?.slug ?? "",
                        logo: university?.squareLogo ?? "",
                        title: invite?.community?.title ?? "",
                    };
                }) ?? [];

            // prioritize invite spaces if they exist
            let firstSpace = validUniversitySpaces.length > 0 ? validUniversitySpaces[0] : null;
            firstSpace = validCommunitySpaces.length > 0 ? validCommunitySpaces[0] : firstSpace;
            firstSpace = validInviteSpaces.length > 0 ? validInviteSpaces[0] : firstSpace;
            //

            if (selectedCommunity) {
                // select community space if it exists
                const community = [...validCommunitySpaces, ...validInviteSpaces].find(
                    (space) => space.id === selectedCommunity.id,
                );

                if (community) {
                    setSelectedSpace(community);
                }
                //
            } else {
                // Auto select the first space
                setSelectedSpace(firstSpace);
                //
            }

            setSpaces([...validInviteSpaces, ...validCommunitySpaces, ...validUniversitySpaces]);
            // merge invites and communities
            const lobby = [...communities, ...invites.map((invite) => invite.community)];
            const commonCommunityFields = {
                archived: false,
                requestedPublicGroups: 0,
                inviteLinks: [],
                communityChatGroups: [],
            };
            setCommunities(
                lobby?.map((community) => ({
                    ...community,
                    ...commonCommunityFields,
                })),
            );

            if (firstSpace?.type === SpaceType.COMMUNITY) {
                const community = lobby.find((community) => community.id === firstSpace.id);
                if (community) {
                    select({
                        ...community,
                        ...commonCommunityFields,
                    });
                }
            }
        },
    });

    const value = useMemo(
        () => ({spaces, selectedSpace, refetch, setSelectedSpace, selectCommunitySpace}),
        [spaces, selectedSpace, refetch, selectCommunitySpace],
    );

    if (error) {
        return (
            <View flex="1" justifyContent="center" alignItems="center">
                <QueryErrorHandler error={error} retryCallback={refetch} hideRedirectButton />
            </View>
        );
    }

    if (loading) {
        return (
            <View flex="1" justifyContent="center" alignItems="center">
                <ActivityIndicator accessibilityLabel={SpacesProviderStrings.loadingSpaces} />
            </View>
        );
    }

    return (
        <SpacesContext.Provider value={value}>
            <IdentifyUser />
            {children}
        </SpacesContext.Provider>
    );
};
