import React, {useState} from "react";
import {Modal, Platform, TouchableOpacity, useWindowDimensions} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {useFormikContext} from "formik";
import {
    Column,
    Columns,
    FormGroup,
    Inline,
    Stack,
    Text,
    View,
    usePatronTheme,
} from "@unibuddy/patron";

import {
    AutocompleteV2,
    IAutocompleteProps,
    SelectionType,
} from "ubcommunity-shared/src/Patron/AutocompleteV2";
import ChevronDownIcon from "ubcommunity-shared/src/Icons/ChevronDownIcon";
import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {FieldErrorContainer} from "ubcommunity-shared/src/Patron/FieldError";
import {FieldErrorContent} from "ubcommunity-shared/src/Patron/FieldError";

import {useIsDesktop} from "../useIsDesktop/useIsDesktop";

interface IAutocompleteModalProps {
    label: string;
    emptySelectionText?: string;
    onDismiss?: () => void;
    onOpen?: () => void;
}

export const AutocompleteModal = ({
    name,
    data,
    placeholder,
    renderItem,
    resultKey,
    onSelect = () => {},
    onDropdownOpened,
    required,
    showResultsOnRender = false,
    resultsRenderItem = undefined,
    // modal props
    label,
    emptySelectionText,
    onDismiss = () => {},
    onOpen = () => {},
}: IAutocompleteProps & IAutocompleteModalProps) => {
    const theme = usePatronTheme();
    const {isDesktop} = useIsDesktop();
    const {height} = useWindowDimensions();

    const [isOpen, setIsOpen] = useState(false);

    const {values, errors} = useFormikContext();
    // assumes the key to pull value from is "name"
    const value = values[name].map((item) => item["name"]).join(", ");

    if (isDesktop) {
        return (
            <FormGroup id={name} label={label} name={name}>
                <AutocompleteV2
                    id={name}
                    name={name}
                    data={data}
                    placeholder={placeholder}
                    renderItem={renderItem}
                    resultKey={resultKey}
                    onSelect={onSelect}
                    onDropdownOpened={onDropdownOpened}
                    required={required}
                    resultsRenderItem={resultsRenderItem}
                />
            </FormGroup>
        );
    }

    const onDialogDismissed = () => {
        setIsOpen(false);
        onDismiss();
    };

    const onDialogOpened = () => {
        setIsOpen(true);
        onOpen();
    };

    const handleOnSelect = (e, type) => {
        if (type === SelectionType.ADD) {
            setIsOpen(false);
        }
        onSelect(e, type);
    };

    return (
        <>
            <Stack space="small">
                <Inline space="xsmall">
                    <Text size="medium" style={{...boldFontStyles}}>
                        {label}
                    </Text>
                    <Text size="medium" color="red">
                        {required ? "*" : ""}
                    </Text>
                </Inline>
                <TouchableOpacity
                    onPress={onDialogOpened}
                    style={{
                        backgroundColor: theme.TextInput?.backgroundColor,
                        height: parseInt(theme.TextInput?.height.slice(0, 2)),
                        borderRadius: parseInt(theme.TextInput?.borderRadius.slice(0, 1)),
                        width: "100%",
                    }}
                >
                    <View justifyContent="center" paddingX="small" h={theme.TextInput?.height}>
                        <Columns verticalAlign="center">
                            <Column>
                                <View
                                    style={{
                                        opacity: value === "" ? 0.5 : 1,
                                    }}
                                >
                                    <Text size="medium">{value || emptySelectionText}</Text>
                                </View>
                            </Column>
                            <Column width="content">
                                <ChevronDownIcon
                                    color={theme?.TextBlock?.color}
                                    size={12}
                                    style={Platform.select({
                                        web: {
                                            transform: `rotate(-90deg)`,
                                        },
                                        native: {
                                            transform: [{rotate: "-90deg"}],
                                        },
                                    })}
                                />
                            </Column>
                        </Columns>
                    </View>
                </TouchableOpacity>
                {errors[name] ? (
                    <FieldErrorContainer>
                        <FieldErrorContent size="small">{errors[name]}</FieldErrorContent>
                    </FieldErrorContainer>
                ) : null}
            </Stack>
            <Modal visible={isOpen} animationType="slide">
                <SafeAreaView
                    style={{
                        flex: 1,
                        backgroundColor: theme.colors.body,
                    }}
                >
                    <View display="flex" alignItems="flex-end" padding="medium">
                        <TouchableOpacity onPress={onDialogDismissed}>
                            <CloseIcon color={theme?.TextBlock?.color} size={24} />
                        </TouchableOpacity>
                    </View>
                    <View paddingX="small" paddingTop="medium">
                        <Stack space="small">
                            <FormGroup label={label} name={name}>
                                <AutocompleteV2
                                    name={name}
                                    data={data}
                                    placeholder={placeholder}
                                    renderItem={renderItem}
                                    resultKey={resultKey}
                                    onSelect={handleOnSelect}
                                    onDropdownOpened={onDropdownOpened}
                                    required={required}
                                    showResultsOnRender={showResultsOnRender}
                                    resultsContainerHeight={height - 260}
                                    resultsRenderItem={resultsRenderItem}
                                />
                            </FormGroup>
                        </Stack>
                    </View>
                </SafeAreaView>
            </Modal>
        </>
    );
};
