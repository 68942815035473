import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useGetAllDegreesQuery, useGetInterestsQuery} from "ubcommunity-shared/src/types";

export const useGetFilters = () => {
    const {selectedCommunity} = useCommunity();

    const university = selectedCommunity.institutions[0];
    const {data: degreesData, loading: degreesLoading} = useGetAllDegreesQuery({
        variables: {
            universitySlug: university.slug,
        },
    });

    const {data: interestsData, loading: interestsLoading} = useGetInterestsQuery();

    return {
        degreesData: degreesData?.allDegrees || [],
        degreesLoading,
        interestsData: interestsData?.getInterests || [],
        interestsLoading,
    };
};
