import React, {useEffect} from "react";
import {Button, View, usePatronTheme} from "@unibuddy/patron";

import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles, TrackSources} from "ubcommunity-shared/src/constants";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {SidePanel} from "ubcommunity-shared/src/General/SidePanel/SidePanel";
import {ModalTypes, useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {PeopleFilters} from "ubcommunity-shared/src/People/PeopleFilters";
import {FilterHorizontalIcon} from "ubcommunity-shared/src/Icons/FilterHorizontalIcon";
import {PeopleSubHeader} from "ubcommunity-shared/src/People/PeopleSubHeader";
import {PeopleSubHeader as PeopleSubHeaderMobile} from "ubcommunity-shared/src/People/PeopleSubHeader.mobile";
import {PeopleListing as PeopleListingDesktop} from "ubcommunity-shared/src/People/PeopleListing";
import {PeopleListing as PeopleListingMobile} from "ubcommunity-shared/src/People/PeopleListing.mobile";
import {usePeopleFilters} from "ubcommunity-shared/src/People/PeopleFiltersProvider";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {FilterPills} from "ubcommunity-shared/src/General/FilterPills";

export const PeoplePage = () => {
    const {isDesktop} = useIsDesktop();
    const {openModal} = useModal();
    const {error, refetch, fetch} = usePeopleFilters();
    const {openUserSummary} = useOpenUserSummary({openSource: TrackSources.PEOPLE_LIST});
    const theme = usePatronTheme();

    usePageTitle(PageTitles.PEOPLE);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const handleFilterClick = () => {
        openModal({
            component: <PeopleFilters />,
            type: ModalTypes.BOTTOM_SHEET,
        });
    };

    const handleProfileOpen = (userId: string) => {
        openUserSummary(userId, null, {type: "cardV2"});
    };

    // desktop
    if (isDesktop) {
        return (
            <View bgColor="white" flex="1" flexDirection="row-reverse">
                <SidePanel>
                    <PeopleFilters />
                </SidePanel>
                <View flex="1">
                    <View w="100%" alignItems="center" paddingLeft="medium">
                        <PeopleSubHeader noOfPeople="NA" />
                    </View>
                    <View flex="1">
                        {error ? (
                            <QueryErrorHandler
                                error={error}
                                retryCallback={refetch}
                                layout="center"
                                hideRedirectButton
                                meta={{
                                    component: "PeoplePage",
                                    query: "useGetCommunityUserDirectoryQuery",
                                }}
                            />
                        ) : (
                            <PeopleListingDesktop />
                        )}
                    </View>
                </View>
            </View>
        );
    }

    return (
        <View flex="1">
            <PeopleSubHeaderMobile
                noOfPeople="NA"
                addOnRight={
                    <Button onClick={handleFilterClick} iconOnly clear aria-label="Open filters">
                        <FilterHorizontalIcon color={theme.colors?.primaryColor} />
                    </Button>
                }
                addonBottom={<FilterPills />}
            />
            <PeopleListingMobile onShowUserSummary={handleProfileOpen} />
        </View>
    );
};
