export enum PageTitles {
    ACCOUNT_INFORMATION = "Account Information",
    CHANGE_PASSWORD = "Change Password",
    CHAT = "Chat",
    CREATE_GROUP = "Create Group",
    EXPLORE = "Explore",
    INBOX = "Inbox",
    JOIN_GROUP = "Join Group",
    LOGIN = "Login",
    LOBBY = "Lobby",
    MATCHES = "Matches",
    REQUEST_PASSWORD_RESET = "Request Password Reset",
    SETTINGS = "Settings",
    SSO_LOGIN = "Login",
    SIGNUP_STEP_1 = "Signup",
    SIGNUP_STEP_2 = "Signup",
    SECURITY_AND_PRIVACY = "Security and Privacy",
    TODO = "To-do",
    UPDATES = "Updates",
    PEOPLE = "People",
    DISCOVER = "Discover",
}
