import {PropsWithChildren} from "react";
import {usePatronTheme, View} from "@unibuddy/patron";
import {useMediaQuery} from "../Hooks/useMediaQuery";
import {MediaQueries} from "../constants";

export const HomeGradient = ({children}: PropsWithChildren) => {
    const theme = usePatronTheme();
    const matches = useMediaQuery(MediaQueries.HEIGHT_GREATER_THAN_420PX);

    return (
        <View
            flexDirection="row"
            paddingX="small-medium"
            paddingY={matches ? "small" : "xxsmall"}
            style={{
                background: `linear-gradient(180deg, ${theme.colors.purple800} 34.58%, ${theme.colors.purple900} 100%)`,
            }}
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
        >
            <View flex={1} alignItems="center">
                {children}
            </View>
        </View>
    );
};
