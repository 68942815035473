import React, {memo} from "react";
import {Gif as GiphyGif} from "@giphy/react-components";
import {useFetchGif} from "./useGif";

export const Gif = memo<{id: string; aspectRatio: number; width?: number; autoPlay?: boolean}>(
    ({id, aspectRatio, width = 200, autoPlay = true}) => {
        const gif = useFetchGif({id});
        const height = aspectRatio ? width / aspectRatio : undefined;

        return (
            <div style={{width, height}} data-testid="gif-container">
                {gif ? (
                    autoPlay === true ? (
                        <GiphyGif
                            data-testid="gif"
                            hideAttribution
                            noLink
                            width={width}
                            height={height}
                            gif={gif}
                        />
                    ) : (
                        <img
                            alt={gif.title}
                            src={gif.images.downsized_still.url}
                            style={{maxWidth: "100%"}}
                        />
                    )
                ) : null}
            </div>
        );
    },
);
