import React from "react";
import {NewsEmptyState} from "ubcommunity-shared/src/General/NewsEmptyState";
import {GroupTypes} from "../useChat";
import DirectGroupEmptyState from "../DirectGroupEmptyState";
import PrivateGroupEmptyState from "../PrivateGroupEmptyState";

export const EmptyState = ({handleSend, groupType}) => {
    if (groupType === GroupTypes.DIRECT) return <DirectGroupEmptyState handleSend={handleSend} />;
    if (groupType === GroupTypes.PRIVATE) return <PrivateGroupEmptyState />;
    if (groupType === GroupTypes.NEWS_FEED) return <NewsEmptyState />;
};
