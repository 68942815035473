import React from "react";
import {AnyUserField, PublicMentorField, UserField} from "ubcommunity-shared/src/types";
import {capitalize as cap} from "ubcommunity-shared/src/Utils";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {HIDE_LAST_NAME} from "ubcommunity-shared/src/featureFlags";

type GetFullNameProps = {
    user: AnyUserField | UserField | PublicMentorField;
    capitalize?: boolean;
};

export const GetFullName = ({user, capitalize}: GetFullNameProps) => {
    const shouldHideLastName = useIsFeatureEnabledBoolean(HIDE_LAST_NAME);

    const firstName = user.firstName ?? "";
    const lastName = user.lastName ?? "";

    if (shouldHideLastName) {
        return <>{capitalize ? cap(firstName) : firstName}</>;
    }

    return <>{capitalize ? `${cap(firstName)} ${cap(lastName)}` : `${firstName} ${lastName}`}</>;
};
