import {Platform} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {usePatronTheme, View} from "@unibuddy/patron";

import {PublicMentorField} from "ubcommunity-shared/src/types";
import {PeopleCell} from "ubcommunity-shared/src/People/PeopleCell";
import {PeopleCard} from "ubcommunity-shared/src/People/PeopleCard";
import {getDynamicImageUrlV2} from "ubcommunity-shared/src/Patron/getDynamicUrlV2";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes, TrackEvents, TrackSources} from "ubcommunity-shared/src/constants";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {AnalyticsValues} from "ubcommunity-shared/src/constants/trackingConstants";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {useCreateConversation} from "ubcommunity-shared/src/Hooks/useCreateConversation";

type AmbassadorItemProps = {
    ambassador: PublicMentorField | null;
    isDmsEnabled?: boolean;
    onError?: (error?: Error) => void;
};

export function AmbassadorItem({
    ambassador,
    isDmsEnabled = false,
    onError = () => {},
}: AmbassadorItemProps): JSX.Element {
    const {trackEvent} = useAnalytics();
    const {
        loading: chatGroupCreationLoading,
        error: chatGroupCreationError,
        createConversation,
    } = useCreateConversation({senderId: ambassador?.id});

    const [navigate] = useNavigate();
    const {openUserSummary} = useOpenUserSummary({
        openSource: "Student App - People List",
    });

    const isWeb = Platform.OS === "web";
    const {isDesktop} = useIsDesktop();
    const theme = usePatronTheme();

    const handleDirectMessage = async () => {
        try {
            if (!ambassador?.id) throw new Error("Ambassador ID not found");
            const newConversationId = await createConversation();

            trackEvent(TrackEvents.USER_PROFILE_ACTION_DIRECT_MESSAGE, {
                conversationId: newConversationId,
                selectedUserId: ambassador?.id,
                accountType: ambassador?.accountRole,
                source: TrackSources.MENTOR_LIST,
            });

            navigate({
                path: Routes.CHAT,
                options: {
                    screen: isWeb ? newConversationId : Routes.CHAT_HOME,
                    params: {
                        conversationId: newConversationId,
                    },
                },
            });
        } catch (error) {
            onError(error);
        }
    };

    const openProfile = () => {
        if (ambassador?.id) {
            openUserSummary(ambassador?.id, undefined, {
                isOnline: ambassador?.isOnline ?? false,
                openSource: AnalyticsValues.STUDENT_APP_EXPERIENCE,
            });
        }
    };

    if (chatGroupCreationError) {
        onError(chatGroupCreationError);
    }

    if (isDesktop) {
        return (
            <View paddingY="small">
                <PeopleCard
                    user={{
                        id: ambassador?.id ?? "",
                        firstName: ambassador?.firstName ?? "",
                        profilePhoto: getDynamicImageUrlV2(ambassador?.profilePhoto ?? ""),
                        country: ambassador?.mentorFields?.primaryFlag ?? undefined,
                        bio: ambassador?.bio ?? "",
                    }}
                    key={`buddy-card-${ambassador?.id}`}
                    isDmsEnabled={isDmsEnabled}
                    handleDirectMessage={handleDirectMessage}
                    openProfile={openProfile}
                    dmLoading={chatGroupCreationLoading}
                />
            </View>
        );
    }
    return (
        <View
            marginX="small-medium"
            paddingY="medium"
            borderBottomWidth={1}
            borderColor={theme.Divider?.bgColor}
        >
            <PeopleCell
                user={{
                    id: ambassador?.id ?? "",
                    firstName: ambassador?.firstName ?? "",
                    accountRole: ambassador?.accountRole ?? undefined,
                    profilePhoto: ambassador?.profilePhoto ?? "",
                    country: ambassador?.mentorFields?.primaryFlag ?? undefined,
                    isOnline: ambassador?.isOnline ?? false,
                    areaOfStudy: ambassador?.degree?.name ?? "",
                    department: ambassador?.department ?? "",
                }}
                handleDirectMessage={handleDirectMessage}
                openProfile={openProfile}
                isDmsEnabled={isDmsEnabled}
                dmLoading={chatGroupCreationLoading}
            />
        </View>
    );
}
