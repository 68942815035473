import React from "react";
import {View, usePatronTheme} from "@unibuddy/patron";
import {useApolloClient} from "@apollo/client";

import {
    Filter,
    FilterGroups,
    usePeopleFilters,
} from "ubcommunity-shared/src/People/PeopleFiltersProvider";
import {Pills} from "ubcommunity-shared/src/General/Pills";
import {DegreeFragmentDoc, HousingCategory, HousingInput} from "ubcommunity-shared/src/types";
import {themeUnitToNativeValue} from "ubcommunity-shared/src/Utils";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";

type PillData = {
    id: string;
    label: string;
    type: FilterGroups;
}[];

export function FilterPills() {
    const client = useApolloClient();
    const theme = usePatronTheme();
    const {selectedCommunity} = useCommunity();
    const {filters, setFilters, refetch} = usePeopleFilters();

    const onRemove = ({
        idToRemove,
        filterGroup,
    }: {
        idToRemove: string;
        filterGroup: FilterGroups;
    }) => {
        let currentFilters = filters[filterGroup] || [];
        let idFound: boolean;

        if (filterGroup === FilterGroups.HOUSING) {
            const housingFilters = (currentFilters as HousingInput[]) ?? [];
            idFound = housingFilters.some((filter: HousingInput) => filter.category === idToRemove);
            currentFilters = housingFilters.filter(
                (filter: HousingInput) => filter.category !== idToRemove,
            );
        } else {
            const areaOfStudyFilters = (currentFilters as Filter[FilterGroups.AREA_OF_STUDY]) ?? [];
            idFound = areaOfStudyFilters.includes(idToRemove);
            currentFilters = areaOfStudyFilters.filter((filter: string) => filter !== idToRemove);
        }

        const updatedFilters = {
            ...filters,
            [filterGroup]: currentFilters,
        };

        if (currentFilters.length === 0) {
            delete updatedFilters[filterGroup];
        }
        if (idFound) {
            setFilters(updatedFilters);
        }
        refetch({
            filterUsers: {
                communityId: selectedCommunity.id,
                ...updatedFilters,
            },
        });
    };

    const getDegreeName = (id: string) => {
        const {name} = client.readFragment({
            id: `DegreeField:${id}`,
            fragment: DegreeFragmentDoc,
        });

        return name;
    };

    const getHousingName = (housing: HousingInput) => {
        if (housing.name) {
            return housing.name;
        }
        if (housing.category === HousingCategory.University) {
            return "University housing";
        }
        return "Private housing";
    };

    const pillFilters = Object.entries(filters).reduce<PillData>((acc, [filterKey, value]) => {
        let flattenedArray: PillData = [];
        if (filterKey === FilterGroups.AREA_OF_STUDY) {
            const areaOfStudyValues = (value as Filter[FilterGroups.AREA_OF_STUDY]) ?? [];
            flattenedArray = flattenedArray.concat(
                areaOfStudyValues.map((str) => ({
                    id: str,
                    label: getDegreeName(str),
                    type: FilterGroups.AREA_OF_STUDY,
                })),
            );
        }
        if (filterKey === FilterGroups.HOUSING) {
            const housingValues = (value as Filter[FilterGroups.HOUSING]) ?? [];
            flattenedArray = flattenedArray.concat(
                housingValues.map((housing: HousingInput) => ({
                    id: housing.category,
                    label: getHousingName(housing),
                    type: FilterGroups.HOUSING,
                })),
            );
        }
        return acc.concat(flattenedArray);
    }, []);

    return pillFilters.length ? (
        <View paddingBottom="small-medium">
            <Pills
                containerStyle={{
                    paddingLeft: themeUnitToNativeValue(theme.space["small-medium"]),
                }}
                items={pillFilters}
                onSelect={(id, options) =>
                    onRemove({
                        idToRemove: id,
                        filterGroup: options?.type,
                    })
                }
                labelMaxLength={20}
            />
        </View>
    ) : null;
}
