import * as yup from "yup";
import {Form, Stack, Text, View} from "@unibuddy/patron";

import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {QuestionProps} from "./types";
import {Rating} from "../../StudentConfidenceSurvey/Rating/Rating";
import {CTAWrapper} from "../../General/CTAWrapper";
import {CTAFooter} from "../../General/CTAFooter";
import {QUESTION_TWO_FIELD_NAME} from "../StudentEnrolledQuestionsCTA";
import {SurveyQuestionLabel} from "./Question1";

/**
 * ENROLMENT SURVEY
 * Question (2 of 2) - if answered Yes to question 1 and DID_COMMUNITY_HELPED_QUESTION feature flag disabled:
 * How much did Unibuddy Community (this app) influence your decision?
 */
export const Question2 = ({data, error, handleSubmit, handleDismiss}: QuestionProps) => (
    <Form
        initialValues={{[QUESTION_TWO_FIELD_NAME]: null}}
        onSubmit={handleSubmit}
        validationSchema={{
            [QUESTION_TWO_FIELD_NAME]: yup.string().nullable().required("Please select an option"),
        }}
    >
        {({values}) => (
            <CTAWrapper
                tagName="Survey"
                handleDismiss={handleDismiss}
                footer={
                    <CTAFooter
                        footerText="Question 2 of 2"
                        buttonText="Submit"
                        isDisabled={values[QUESTION_TWO_FIELD_NAME] === null}
                    />
                }
            >
                <Stack space="medium">
                    <Rating
                        name={QUESTION_TWO_FIELD_NAME}
                        options={data.options}
                        label={<SurveyQuestionLabel>{data.text}</SurveyQuestionLabel>}
                    />
                    <View width="100%" justifyContent="space-between" flexDirection="row">
                        <Text size="small">Not much</Text>
                        <Text size="small">A lot</Text>
                    </View>
                </Stack>
                {error ? (
                    <MutationErrorHandler
                        error={error}
                        message={`Oh no! Something went wrong. Please try again later.`}
                        meta={{
                            component: "StudentEnrolledSurveyCTA.Q2",
                            mutation: "useSaveUniversityQuestionAnswerMutation",
                        }}
                    />
                ) : null}
            </CTAWrapper>
        )}
    </Form>
);
