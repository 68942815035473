import React from "react";
import {Text, View, Inline, FontSizeProp} from "@unibuddy/patron";
import {CountryFlag} from "ubcommunity-shared/src/General/CountryFlag";
import {CountryField, FlagField} from "ubcommunity-shared/src/types";

type CountryFlagWithNameProps = {
    country: CountryField | FlagField;
    flagSize: number;
    textSize?: FontSizeProp;
};
export const CountryFlagWithName = ({
    country,
    flagSize,
    textSize = "medium",
}: CountryFlagWithNameProps) => {
    return (
        <View
            data-testid="country-flag"
            overflow="hidden"
            marginBottom="small"
            paddingBottom="xxsmall"
        >
            <Inline verticalAlign="center">
                <CountryFlag
                    size={flagSize}
                    label={country.name ?? ""}
                    code={country.code ?? ""}
                    isRound={false}
                />
                <Text size={textSize}>{country.name}</Text>
            </Inline>
        </View>
    );
};
