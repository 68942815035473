import {PropsWithChildren} from "react";
import {Box, Button, Stack, Text, View} from "@unibuddy/patron";
import {IconChevronLeft, IconChevronRight} from "@unibuddy/icons";
import {Image, ScrollView, TouchableOpacity} from "react-native";
import {boldFontStyles} from "../Styles/fontStyles";
import {Space} from "../StudentApp/SpacesProvider";
import {SelectionDirection} from "./HomeBar";

type HomeBarContentProps = {
    selectedSpace: Space | null;
    handleSpaceChangerToggle: (show: boolean) => void;
    handleSpaceSelection: (direction: SelectionDirection) => void;
    hasOnlyOneSpace: boolean;
};

const NavigationButton = ({
    children,
    onPress,
    disabled,
    ariaLabel,
    hide,
}: PropsWithChildren<{
    onPress: () => void;
    disabled?: boolean;
    ariaLabel?: string;
    hide?: boolean;
}>) => {
    if (hide) {
        return null;
    }

    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled}
            accessibilityRole="button"
            accessibilityLabel={ariaLabel}
        >
            {children}
        </TouchableOpacity>
    );
};

export const HomeBarContent = ({
    selectedSpace,
    handleSpaceChangerToggle,
    handleSpaceSelection,
    hasOnlyOneSpace,
}: HomeBarContentProps) => {
    return (
        <Stack space="small-medium">
            <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                <NavigationButton
                    onPress={() => handleSpaceSelection(SelectionDirection.PREVIOUS)}
                    hide={hasOnlyOneSpace}
                    ariaLabel="Previous space"
                >
                    <IconChevronLeft color="white" />
                </NavigationButton>
                <View maxW="80%" margin="auto">
                    <View flexDirection="row" alignItems="center" gap="xsmall">
                        <Box
                            flexShrink={0}
                            borderWidth={0.5}
                            borderColor="grey150"
                            style={{
                                borderRadius: 6,
                                overflow: "hidden",
                            }}
                        >
                            <Image
                                style={{width: 34, height: 34, borderRadius: 6}}
                                source={{uri: selectedSpace?.logo ?? undefined}}
                                width={80}
                                height={80}
                                accessibilityLabel="University logo"
                            />
                        </Box>
                        <ScrollView
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            contentContainerStyle={{
                                paddingTop: 4,
                                paddingBottom: 4,
                            }}
                        >
                            <Text
                                style={{
                                    ...boldFontStyles,
                                }}
                                color="white"
                            >
                                {selectedSpace?.name}
                            </Text>
                        </ScrollView>
                    </View>
                </View>
                <NavigationButton
                    onPress={() => handleSpaceSelection(SelectionDirection.NEXT)}
                    hide={hasOnlyOneSpace}
                    ariaLabel="Next space"
                >
                    <IconChevronRight color="white" />
                </NavigationButton>
            </Box>
            <Box display="flex" width="100%" justifyContent="center">
                <View maxW="80%">
                    <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        contentContainerStyle={{paddingTop: 4, paddingBottom: 4}}
                    >
                        <Text color="white" align="center" size="small">
                            {selectedSpace?.title ? selectedSpace.title : "Chat to ambassadors"}
                        </Text>
                    </ScrollView>
                </View>
            </Box>
            <View width="100%" alignItems="center">
                <Button
                    round
                    ghost
                    size="sm"
                    style={{
                        borderColor: "white",
                        height: 30,
                    }}
                    onClick={() => handleSpaceChangerToggle(false)}
                >
                    <Text size="xxsmall" color="white">
                        Hide details
                    </Text>
                </Button>
            </View>
        </Stack>
    );
};
