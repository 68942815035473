import React from "react";

export default function MessageIcon(props) {
    return (
        <svg
            width={props.size || 20}
            height={props.size || 20}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            {...props}
        >
            <path
                d="M12.0614 4.503C13.938 4.50157 15.748 5.19842 17.1391 6.45792C18.5302 7.71741 19.403 9.44945 19.5875 11.317C19.772 13.1845 19.2551 15.0538 18.1374 16.5612C17.0196 18.0686 15.381 19.1062 13.5404 19.472C13.0531 19.5673 12.5579 19.6155 12.0614 19.616C10.8965 19.6163 9.74758 19.3444 8.70638 18.822C8.50279 18.7199 8.27814 18.6668 8.05038 18.667C7.88846 18.6671 7.72766 18.6938 7.57438 18.746L5.01338 19.169L5.50138 16.743C5.61519 16.3639 5.57458 15.9552 5.38838 15.606C4.82496 14.5511 4.52016 13.3774 4.4991 12.1816C4.47804 10.9859 4.74131 9.8022 5.26723 8.72809C5.79316 7.65398 6.56669 6.72015 7.52415 6.0035C8.4816 5.28685 9.59559 4.80787 10.7744 4.606C11.1992 4.53608 11.6289 4.50063 12.0594 4.5L12.0614 4.503ZM12.0594 3C11.5478 3.00049 11.0372 3.04229 10.5324 3.125C9.11853 3.36577 7.78212 3.93905 6.63328 4.79759C5.48443 5.65614 4.55603 6.77538 3.92458 8.0631C3.29312 9.35082 2.97668 10.7701 3.00134 12.2041C3.02599 13.6381 3.39103 15.0458 4.06638 16.311H4.06038L3.40238 19.581C3.37277 19.7261 3.37574 19.8759 3.41107 20.0197C3.44641 20.1634 3.51322 20.2976 3.6067 20.4124C3.70017 20.5272 3.81797 20.6198 3.9516 20.6836C4.08522 20.7473 4.23133 20.7806 4.37938 20.781C4.43533 20.7807 4.49117 20.776 4.54638 20.767L8.06138 20.187L8.03838 20.165C9.28754 20.7895 10.6648 21.1151 12.0614 21.116C12.6538 21.1155 13.2449 21.0582 13.8264 20.945C16.0317 20.5064 17.995 19.2633 19.3346 17.4574C20.6741 15.6515 21.2941 13.4119 21.074 11.1742C20.8539 8.93654 19.8093 6.86072 18.1436 5.35046C16.4778 3.8402 14.3099 3.00347 12.0614 3.003L12.0594 3Z"
                fill="currentColor"
            />
            <path
                d="M15.5046 11.292H8.60059C8.40167 11.292 8.21091 11.213 8.07026 11.0723C7.9296 10.9317 7.85059 10.7409 7.85059 10.542C7.85059 10.3431 7.9296 10.1523 8.07026 10.0117C8.21091 9.87102 8.40167 9.792 8.60059 9.792H15.5006C15.6995 9.792 15.8903 9.87102 16.0309 10.0117C16.1716 10.1523 16.2506 10.3431 16.2506 10.542C16.2506 10.7409 16.1716 10.9317 16.0309 11.0723C15.8903 11.213 15.6995 11.292 15.5006 11.292H15.5046Z"
                fill="currentColor"
            />
            <path
                d="M11.5316 14.327H8.60059C8.40167 14.327 8.21091 14.248 8.07026 14.1073C7.9296 13.9667 7.85059 13.7759 7.85059 13.577C7.85059 13.3781 7.9296 13.1873 8.07026 13.0467C8.21091 12.906 8.40167 12.827 8.60059 12.827H11.5316C11.7305 12.827 11.9213 12.906 12.0619 13.0467C12.2026 13.1873 12.2816 13.3781 12.2816 13.577C12.2816 13.7759 12.2026 13.9667 12.0619 14.1073C11.9213 14.248 11.7305 14.327 11.5316 14.327Z"
                fill="currentColor"
            />
        </svg>
    );
}
