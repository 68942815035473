import {useCallback} from "react";
import {useRouteMatch} from "react-router";
import {Box, View} from "@unibuddy/patron";

import RelativeSwitch from "ubcommunity-shared/src/Navigation/RelativeSwitch/RelativeSwitch";
import {UniversalInbox} from "ubcommunity-shared/src/UniversalInbox/UniversalInbox";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";
import RelativeRoute from "ubcommunity-shared/src/Navigation/RelativeRoute/RelativeRoute";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {UniversalInboxHeader} from "ubcommunity-shared/src/UniversalInbox/UniversalInboxHeader";
import {AdminInbox} from "ubcommunity-shared/src/AdminInbox/AdminInbox";

import {ChatPage} from "../../Community/ChatPage/DesktopChatPage";
import {CreatePoll} from "ubcommunity-shared/src/Community/CreatePoll/CreatePoll";
import {useIsAdminExperience} from "ubcommunity-shared/src/AdminInbox/useIsAdminExperience";

/*
    Needs to be extracted into its own component to get the context value
    from RelativeRoute
*/
const UniversalInboxWrapper = () => {
    const {push} = useRelativeRouter();
    const {params} = useRouteMatch<{id?: string}>();
    const {isAdminExperience} = useIsAdminExperience();

    const handleSelectChatGroup = useCallback(
        ({id}: {id: string}) => {
            push(`${Routes.CHAT}/${id}`);
        },
        [push],
    );

    // Explicit type because we know user can only have one of these roles
    // And we only want to show the admin inbox to these roles
    if (isAdminExperience) {
        return <AdminInbox onSelect={handleSelectChatGroup} activeConversationId={params?.id} />;
    }

    return (
        <View flex="1">
            <UniversalInboxHeader />
            <UniversalInbox onSelect={handleSelectChatGroup} activeConversationId={params?.id} />
        </View>
    );
};

export function DesktopInboxLayout() {
    usePageTitle(PageTitles.INBOX);

    return (
        <Box display="flex" flex="1">
            <View
                width={["40%", "40%", "40%", "33%"]}
                borderRightWidth={1}
                borderColor="grey50"
                shadow="medium"
                zIndex={1}
            >
                <RelativeSwitch>
                    <RelativeRoute path={Routes.CHAT_GROUP}>
                        <UniversalInboxWrapper />
                    </RelativeRoute>
                    <RelativeRoute path={Routes.CHAT}>
                        <UniversalInboxWrapper />
                    </RelativeRoute>
                </RelativeSwitch>
            </View>
            <View width={["60%", "60%", "60%", "77%"]} backgroundColor="#F2F5F7">
                <RelativeSwitch>
                    <RelativeRoute path={Routes.CREATE_POLL}>
                        <CreatePoll />
                    </RelativeRoute>
                    <RelativeRoute path={Routes.CHAT_GROUP}>
                        <ChatPage />
                    </RelativeRoute>
                </RelativeSwitch>
            </View>
        </Box>
    );
}
