import {PropsWithChildren, ReactNode} from "react";
import {Button, Box, Text, View, usePatronTheme, Stack} from "@unibuddy/patron";

import NotificationIcon from "ubcommunity-shared/src/Icons/NotificationIcon.icon";
import CloseIcon from "ubcommunity-shared/src/Icons/CloseIcon";
import {semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";

type TagProps = {
    text: string;
    icon: ReactNode;
    bgColor: string;
    textColor: string;
};

const Tag = ({text, icon, bgColor, textColor}: TagProps) => (
    <View
        bgColor={bgColor}
        color={textColor}
        paddingX="xsmall"
        paddingY="xxsmall"
        borderRadius="xsmall"
        flexDirection="row"
        alignItems="center"
    >
        <Box marginRight="xxsmall">{icon}</Box>
        <Text size="small" color={textColor} style={{...semiBoldFontStyles}}>
            {text}
        </Text>
    </View>
);

type CTAWrapperProps = {
    tagName: string;
    handleDismiss: () => void;
    footer: React.ReactNode;
};

export const CTAWrapper = ({
    tagName,
    children,
    handleDismiss,
    footer,
}: PropsWithChildren<CTAWrapperProps>) => {
    const theme = usePatronTheme();
    const {isDesktop} = useIsDesktop();

    return (
        <View
            borderRadius="xsmall"
            backgroundColor={theme.colors.groupBgColor}
            overflow="hidden"
            marginTop="small-medium"
            marginRight={!isDesktop ? "small-medium" : undefined}
            marginLeft="small-medium"
        >
            <View marginBottom="medium" paddingX="medium">
                <Stack space="medium">
                    <View
                        flex={1}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginY="small"
                        marginTop="medium"
                    >
                        <Tag
                            text={tagName}
                            icon={<NotificationIcon width={18} height={18} color="white" />}
                            bgColor={theme.colors.primaryColor}
                            textColor="white"
                        />
                        <Button
                            aria-label={`Dismiss ${tagName.toLowerCase()}`}
                            // @ts-ignore valid in RN
                            accessibilityLabel={`Dismiss ${tagName.toLowerCase()}`}
                            onClick={() => handleDismiss()}
                            clear
                            iconOnly
                            round
                            style={{width: 24, height: 24, padding: 0}}
                        >
                            <CloseIcon color={theme.colors.lightGrey} width={24} height={24} />
                        </Button>
                    </View>

                    {children}
                </Stack>
            </View>
            {footer}
        </View>
    );
};
