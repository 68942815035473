import React from "react";

function ReplyIcon(props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.71487 4.62407C7.88 4.62451 8.03825 4.6903 8.15502 4.80707C8.27179 4.92384 8.33758 5.08209 8.33802 5.24722L8.33802 7.00453L10.0563 7.00453C14.2223 7.00453 16.0494 6.11798 17.5109 4.78692C17.6292 4.67921 17.7837 4.6199 17.9438 4.62074C18.1095 4.62339 18.268 4.68946 18.3865 4.80537C18.5051 4.92129 18.5747 5.07823 18.5811 5.2439C18.5793 7.49709 17.6837 9.65753 16.0907 11.2511C14.4978 12.8446 12.3377 13.7411 10.0845 13.7438L8.33968 13.7438L8.33968 15.462C8.34037 15.5876 8.30312 15.7104 8.23281 15.8144C8.16251 15.9184 8.06242 15.9988 7.94566 16.0449C7.82891 16.0911 7.70094 16.1009 7.57851 16.0731C7.45608 16.0453 7.3449 15.9811 7.25955 15.8891L2.50362 10.8299C2.39552 10.7152 2.33498 10.5637 2.33421 10.4061C2.33344 10.2485 2.3925 10.0965 2.49946 9.98072L7.25539 4.82929C7.31353 4.76506 7.38442 4.71364 7.46352 4.67831C7.54263 4.64297 7.62823 4.6245 7.71487 4.62407ZM10.0845 12.4974C11.7707 12.4951 13.4036 11.9062 14.7031 10.8317C16.0026 9.75713 16.8878 8.26395 17.2068 6.6082C15.8009 7.53545 13.7562 8.25084 10.0563 8.25084L7.71487 8.25084C7.5496 8.25084 7.39109 8.18519 7.27423 8.06832C7.15736 7.95146 7.09171 7.79295 7.09171 7.62768L7.09171 6.84251L3.81058 10.3995L7.09171 13.8892L7.09171 13.1231C7.09171 12.9578 7.15736 12.7993 7.27423 12.6825C7.39109 12.5656 7.5496 12.4999 7.71487 12.4999L10.0845 12.4974Z"
                fill="currentColor"
            />
        </svg>
    );
}

const MemoReplyIcon = React.memo(ReplyIcon);
export default MemoReplyIcon;
