import {useCallback, useRef} from "react";
import {View} from "@unibuddy/patron";
import {FlatList, RefreshControl} from "react-native";
import {InboxShimmer} from "ubcommunity-shared/src/Community/Inbox/components/InboxShimmer";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {
    ChatConversation,
    Product,
    UniversalInboxConversationFragment,
    useUniversalInboxQuery,
} from "ubcommunity-shared/src/types";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {UniversalInboxEmptyState} from "ubcommunity-shared/src/Community/InboxEmptyState/UniversalInboxEmptyState";

import {CommunityUniversalInboxItem} from "./CommunityUniversalInboxItem";
import {WidgetUniversalInboxItem} from "./WidgetUniversalInboxItem";
import {NewsFeedUniversalInboxItem} from "./NewsFeedUniversalInboxItem";
import {useRefetch} from "../Community/Inbox/useRefetch";

export enum UniversalInboxStrings {
    loading = "Loading more conversations",
}

export const InboxListFooter = ({loading}: {loading: boolean}) => {
    return loading ? (
        <View padding="small">
            <ActivityIndicator accessibilityLabel={UniversalInboxStrings.loading} />
        </View>
    ) : null;
};

export type UniversalInboxProps = {
    onSelect: ({id}: {id: string}) => void;
    activeConversationId?: string; // desktop only
};

export const UNIVERSAL_INBOX_LIMIT = 10;

export const compareNewsFeedAndLastMessage = (a: ChatConversation, b: ChatConversation) => {
    // Prioritize community newsfeed conversations
    const isACommunityNewsFeed = a.product === Product.Community && a.isNewsFeed;
    const isBCommunityNewsFeed = b.product === Product.Community && b.isNewsFeed;

    if (isACommunityNewsFeed && !isBCommunityNewsFeed) return -1;
    if (!isACommunityNewsFeed && isBCommunityNewsFeed) return 1;

    // For other cases, sort by lastMessage.created
    const dateA = a.lastMessage ? new Date(a.lastMessage.created).valueOf() : 0;
    const dateB = b.lastMessage ? new Date(b.lastMessage.created).valueOf() : 0;

    return dateB - dateA; // Newer messages come first
};

export function UniversalInbox({onSelect, activeConversationId}: UniversalInboxProps) {
    const {isDesktop} = useIsDesktop();
    const previousItemsCount = useRef(0);

    const {data, loading, error, refetch, fetchMore, networkStatus} = useUniversalInboxQuery({
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all",
        fetchPolicy: "cache-and-network",
        variables: {
            offsetPagination: {
                limit: UNIVERSAL_INBOX_LIMIT,
                offset: 0,
            },
        },
    });

    const refetchWithParams = useCallback(() => {
        refetch({
            offsetPagination: {
                limit: UNIVERSAL_INBOX_LIMIT + previousItemsCount.current,
                offset: 0,
            },
        });
    }, [refetch]);

    const {triggerRefetch, isRefetching} = useRefetch({networkStatus, refetch: refetchWithParams});

    const keyExtractor = useCallback((item: {id: string}) => `item-${item.id}`, []);

    const onEndReached = () => {
        const currentItems = data?.getChatConversationsForUser ?? [];
        const currentItemsCount = currentItems.length;

        const difference = currentItemsCount - previousItemsCount.current;
        if (currentItemsCount !== 0 && difference < UNIVERSAL_INBOX_LIMIT) {
            return;
        }

        if (difference === 0) {
            return;
        }

        previousItemsCount.current = currentItemsCount;

        fetchMore({
            variables: {
                offsetPagination: {
                    limit: UNIVERSAL_INBOX_LIMIT,
                    offset: currentItemsCount,
                },
            },
        });
    };

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{component: "UniversalInbox", query: "useUniversalInboxQuery"}}
                layout="center"
            />
        );
    }

    if (!data && loading) {
        return <InboxShimmer />;
    }

    // If there is no active conversation, select the first one
    if (isDesktop && !activeConversationId && data?.getChatConversationsForUser?.length) {
        onSelect({
            id: data.getChatConversationsForUser[0].id,
        });
    }

    const renderItem = ({item}: {item: UniversalInboxConversationFragment}) => {
        const isWidget = item.product === Product.Widget;

        const props = {
            item,
            onSelect,
            activeConversationId,
        };

        if (isWidget) {
            return <WidgetUniversalInboxItem {...props} />;
        }

        if (item.isNewsFeed) {
            return <NewsFeedUniversalInboxItem {...props} />;
        }

        return <CommunityUniversalInboxItem {...props} />;
    };

    const sortedInboxData = [...(data?.getChatConversationsForUser ?? [])].sort(
        compareNewsFeedAndLastMessage,
    );

    return (
        <FlatList<UniversalInboxConversationFragment>
            data={sortedInboxData}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReached={onEndReached}
            onEndReachedThreshold={0.5}
            ListEmptyComponent={loading ? null : <UniversalInboxEmptyState />}
            ListFooterComponent={<InboxListFooter loading={loading} />}
            refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={triggerRefetch} />}
        />
    );
}
