import React, {memo, useEffect, useState} from "react";
import {TouchableOpacity} from "react-native";
import noop from "lodash/noop";
import {Column, Columns, Stack, Text} from "@unibuddy/patron";
import {gql, useApolloClient} from "@apollo/client";

import {ScreenTitle} from "ubcommunity-shared/src/General/ScreenTitle/ScreenTitle";
import {ChatMembersAvatars} from "../ChatMembersAvatars/ChatMembersAvatars";
import {AnimatedChatMemberCount, AnimatedChatTitleContainer} from "./AnimatedChatTitle";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {GetChatConversation} from "ubcommunity-shared/@types/chat";
import {IsTyping} from "ubcommunity-shared/src/Chat/IsTyping/IsTyping";
import {useIsTyping} from "../IsTyping/useIsTyping";
import {useGetChatTitleInfo} from "./useGetChatTitleInfo";

import {Product} from "ubcommunity-shared/src/types";

export const fetchSender = (userId, chatMembers: any[] | undefined | null = []) => {
    return chatMembers.filter((member) => userId !== member.id);
};

export const ChatTitle = memo<{
    getChatConversation?: GetChatConversation;
    loading: boolean;
    conversationId: string;
    onPress?: () => void;
}>(({getChatConversation, conversationId, loading, onPress = noop}) => {
    const {user} = useAuth();
    const client = useApolloClient();
    const [initialState, setInitialState] = useState();
    const {isTyping, typingMessage} = useIsTyping({
        conversationId,
    });
    const {title} = useGetChatTitleInfo();

    const isWidgetConversation = getChatConversation?.product === Product.Widget;

    useEffect(() => {
        const data = client.readFragment({
            id: `ChatConversation:${conversationId}`,
            fragment: gql`
                fragment CommunityConversationTitleFragment on ChatConversation {
                    id
                    communityChatTitle
                    communityChatDescription
                    isDirect
                    communityChatMembers(offsetPagination: {offset: 0, limit: 2}) {
                        id
                        firstName
                        accountRole
                        profilePhoto
                        communityProfile {
                            profilePhoto
                        }
                    }
                }
            `,
        });
        setInitialState(data);
    }, [client, conversationId]);

    const totalNumberOfMembers = getChatConversation?.totalMemberCount ?? 0;
    const chatConversation = initialState ? initialState : getChatConversation;

    const HeaderInfo = () => {
        if (isTyping) {
            return <IsTyping isTyping={isTyping} typingMessage={typingMessage} />;
        }

        const showMemberCountInHeader =
            !chatConversation?.isDirect && !isWidgetConversation && totalNumberOfMembers > 0;

        if (showMemberCountInHeader && !loading) {
            return (
                <AnimatedChatMemberCount>
                    <Text size="small" color="textLinkColor">
                        {totalNumberOfMembers} {totalNumberOfMembers > 1 ? "people" : "person"}
                    </Text>
                </AnimatedChatMemberCount>
            );
        }

        if (loading) {
            return (
                <Text
                    size="small"
                    color="primaryColor"
                    style={{opacity: 0}}
                    aria-hidden
                    accessible={false}
                >
                    {/* We do this to preserve structure and prevent layout shifts */}
                    Loading
                </Text>
            );
        }

        return null;
    };

    return (
        <TouchableOpacity
            onPress={onPress}
            style={{
                width: "100%",
            }}
            accessible
            accessibilityRole="button"
            accessibilityHint="Press to open group information"
        >
            <Columns verticalAlign="center" space="small">
                <Column width="content">
                    <ChatMembersAvatars
                        size="small"
                        members={
                            chatConversation?.isDirect || isWidgetConversation
                                ? fetchSender(user?.id, chatConversation?.communityChatMembers)
                                : chatConversation?.communityChatMembers
                        }
                    />
                </Column>
                <Column>
                    <AnimatedChatTitleContainer isLoaded={!loading}>
                        <Stack space="xsmall">
                            <ScreenTitle>{title}</ScreenTitle>
                            <HeaderInfo />
                        </Stack>
                    </AnimatedChatTitleContainer>
                </Column>
            </Columns>
        </TouchableOpacity>
    );
});
