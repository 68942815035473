import React, {useCallback} from "react";
import {FlatList, TouchableOpacity} from "react-native";

import {Stack, Text, View, Box, Inline} from "@unibuddy/patron";
import {NetworkStatus} from "@apollo/client";

import {
    MatchingCriteriaEnum,
    useGetRecommendedUsersByCriterionQuery,
    useGetUserProfileQuery,
    UserField,
} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {UserAvatarWithFlag} from "ubcommunity-shared/src/General/UserAvatar/UserAvatarWithFlag";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

import {MemberCount} from "../MemberCount/MemberCount";
import {MatchFilters} from "../Matches/MatchesComponent";
import {GetFullName} from "ubcommunity-shared/src/General/GetFullName";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";

export const MATCHES_LIST_QUERY_LIMIT = 15;

export enum MatchesListStrings {
    title = "These students",
    subtitle = "Tap on someone’s name to learn more about them",
    loadingLabel = "Loading matched users",
    loadingMoreLabel = "Loading more matched users",
    errorLabel = "An error occurred! Please try again later.",
}

export const MatchesList = ({filter = MatchFilters.ALL}: {filter?: MatchFilters}) => {
    const {selectedCommunity} = useCommunity();
    const {user} = useAuth();

    const {openUserSummary} = useOpenUserSummary({
        openSource: "Expanded matches list",
    });

    const {data: meUser} = useGetUserProfileQuery({
        fetchPolicy: "cache-only",
    });
    const {data, networkStatus, error, fetchMore, updateQuery, refetch} =
        useGetRecommendedUsersByCriterionQuery({
            variables: {
                communityId: selectedCommunity.id,
                ...(filter === MatchFilters.ALL
                    ? {}
                    : {
                          matchingCriterion:
                              filter === MatchFilters.COUNTRY
                                  ? MatchingCriteriaEnum.Nationality
                                  : MatchingCriteriaEnum.AreaOfStudy,
                      }),
                pagination: {
                    limit: MATCHES_LIST_QUERY_LIMIT,
                    offset: 0,
                },
            },
            nextFetchPolicy: "cache-only",
            returnPartialData: true,
            notifyOnNetworkStatusChange: true,
        });

    const loading = networkStatus === NetworkStatus.loading;
    const loadingMore = networkStatus === NetworkStatus.fetchMore;

    const allMatches = data?.recommendedUsers?.matches ?? [];
    const users = allMatches.map((match) => match.user);
    const count = data?.recommendedUsers?.totalMatches ?? 0;

    const areaOfStudy = meUser?.getUserProfile?.areaOfStudy
        ? meUser?.getUserProfile?.areaOfStudy[0]?.name
        : null;

    const pageIntroduction = {
        [MatchFilters.COUNTRY]: `are from ${user?.country?.name}`,
        [MatchFilters.AREA_OF_STUDY]: `are studying ${areaOfStudy}`,
        [MatchFilters.ALL]: "have something in common with you",
    };

    const fetchNext = useCallback(async () => {
        if (loadingMore) {
            return;
        }

        const result = await fetchMore({
            variables: {
                pagination: {
                    limit: MATCHES_LIST_QUERY_LIMIT,
                    offset: allMatches.length,
                },
            },
        });

        updateQuery((prev) => {
            return {
                recommendedUsers: {
                    ...prev.recommendedUsers,
                    matches: [
                        ...prev.recommendedUsers.matches,
                        ...result.data.recommendedUsers.matches,
                    ],
                },
            };
        });
    }, [allMatches.length, fetchMore, updateQuery, loadingMore]);

    if (loading) {
        return (
            <Box padding="large" justifyContent="center">
                <ActivityIndicator accessibilityLabel={MatchesListStrings.loadingLabel} />
            </Box>
        );
    }

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                errorMessage={MatchesListStrings.errorLabel}
            />
        );
    }

    return (
        <View flex="1" paddingX="medium">
            <FlatList
                testID="matches-list"
                data={users as {id: string}[] & UserField[]}
                keyExtractor={(item) => item.id}
                onEndReached={() => {
                    if (users.length < count) {
                        fetchNext();
                    }
                }}
                onEndReachedThreshold={0.5}
                renderItem={({item}) => (
                    <View paddingY="small">
                        <TouchableOpacity
                            onPress={() => openUserSummary(item.id)}
                            accessible
                            accessibilityRole="button"
                        >
                            <Inline space="small" verticalAlign="center">
                                <UserAvatarWithFlag
                                    user={item}
                                    size={36}
                                    flagOptions={{
                                        right: -4,
                                        top: 28,
                                        size: 12,
                                    }}
                                />
                                <Text size="medium">
                                    <GetFullName user={item} capitalize />
                                </Text>
                            </Inline>
                        </TouchableOpacity>
                    </View>
                )}
                ListHeaderComponent={
                    <View paddingBottom="small" paddingTop="large">
                        <Stack space="medium">
                            <Text
                                // @ts-ignore
                                style={{...boldFontStyles}}
                                size="xlarge"
                            >
                                {MatchesListStrings.title} {pageIntroduction[filter]}
                            </Text>
                            <Text size="large">{MatchesListStrings.subtitle}</Text>
                            <Box display="flex">
                                <MemberCount memberCount={count} color="primary">
                                    Student{count === 1 ? "" : "s"}
                                </MemberCount>
                            </Box>
                        </Stack>
                    </View>
                }
                ListFooterComponent={
                    loadingMore ? (
                        <ActivityIndicator
                            accessibilityLabel={MatchesListStrings.loadingMoreLabel}
                        />
                    ) : null
                }
            />
        </View>
    );
};
