import {Box} from "@unibuddy/patron";
import {PropsWithChildren} from "react";

export const TodoListItemWrapper = ({children}: PropsWithChildren) => {
    return (
        <>
            {children}
            <Box data-testid="todo-listitem-divider" h={1} bgColor="grey50" w="100%" />
        </>
    );
};
