import React, {useEffect, useState} from "react";
import {Image} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {
    Box,
    CheckBox,
    Form,
    FormGroup,
    Heading,
    Stack,
    SubmitButton,
    Text,
    TextArea,
    View,
} from "@unibuddy/patron";

import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {TrackEvents, LocalStorageNames} from "ubcommunity-shared/src/constants";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {
    useInboxPromptQuestionsQuery,
    useSaveInboxPromptAnswerMutation,
} from "ubcommunity-shared/src/types";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {differenceInCalendarDays} from "date-fns";

import headerImage from "./image/feelPreparedQuestionsImage.png";
import thumbUp from "./image/thumbUp.png";
import {DefaultPopupWrapper} from "../DefaultPopup/DefaultPopupWrapper";

export const popUpCopy = {
    title: "Is there anything you need to know more about to pick this university as your top choice?",
    text: "",
    buttonText: "Submit",
    imageSize: 280,
};

type FeelPreparedQuestionsProps = {
    handleLastShowPopup: () => void;
    setIsPopupActive: (p: boolean) => void;
    handleNextPopup: () => void;
};

const initialValues = {};
export const successText = "Thank you for your feedback!";

/**
 * @author
 * @function @FeelPreparedQuestions
 **/
export const FeelPreparedQuestions = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: FeelPreparedQuestionsProps) => {
    const {value, setValue, loading} = useAsyncLocalStorage(
        LocalStorageNames.HAS_SHOW_FEEL_PREPARED_QUESTIONS,
        {
            hasShown: false,
            dismissedDate: null,
            isFirstTime: true,
            date: new Date(),
        },
    );

    /**
     * the popup will not be triggered in first login time,
     * so, we will be tracking the date of first set
     */
    if (value.isFirstTime) {
        setValue(() => ({...value, isFirstTime: false}));
        handleNextPopup();
        return null;
    }

    const dismissedDate = value.dismissedDate
        ? differenceInCalendarDays(new Date(), new Date(value.dismissedDate))
        : null;

    /**
     * it will dismisse when the the value has been set to hasShown (completed) or it was dismissed
     * more than 14 days ago.
     */
    if (value.hasShown || (dismissedDate && dismissedDate < 14)) {
        handleNextPopup();
        return null;
    }

    /**
     * it will trigger the modal after 2 days from the first set on localstorage.
     */
    const interval = differenceInCalendarDays(new Date(), new Date(value.date));
    if (interval < 2) {
        handleNextPopup();
        return null;
    }

    if (loading) {
        return null;
    }

    return (
        <FeelPreparedQuestionsActive
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
            hasShown={value.hasShown}
            loading={loading}
            setValue={setValue}
        />
    );
};

/**
 * @author
 * @function @FeelPreparedQuestionsActive
 **/
export const FeelPreparedQuestionsActive = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
    hasShown,
    loading,
    setValue,
}: FeelPreparedQuestionsProps & {
    hasShown: boolean;
    loading: boolean;
    setValue: (setter: () => void) => void;
}) => {
    const {university} = useCommunity();
    const {isDesktop} = useIsDesktop();
    const {trackEvent} = useAnalytics();
    const [isSubmitted, seIsSubmitted] = useState<boolean>(false);

    const [saveInboxPromptAnswer] = useSaveInboxPromptAnswerMutation();
    const {data: questions} = useInboxPromptQuestionsQuery({
        variables: {
            inboxPromptInput: {
                universityId: university.id,
                tags: ["community"],
            },
        },
    });

    useEffect(() => {
        if (!loading && !hasShown) {
            trackEvent(TrackEvents.FEEL_PREPARED_QUESTIONS_VIEWED);
            setIsPopupActive(true);
        }
        if (!loading && hasShown) {
            handleNextPopup();
        }
    }, [hasShown, loading]);

    const handleClose = () => {
        let dismissedDate = null;
        if (!isSubmitted) {
            trackEvent(TrackEvents.FEEL_PREPARED_QUESTIONS_DISMISSED);
            dismissedDate = new Date();
        }
        setValue(() => ({hasShown: false, isFirstTime: false, date: new Date(), dismissedDate}));
        handleLastShowPopup();
    };

    const handleSubmit = async (values, formik) => {
        formik.setSubmitting(true);
        const valuesCompleted = [];
        questions?.inboxPromptQuestions[0]?.options.forEach((anwser) => {
            const text = anwser.text === "Other" ? values["more-info"] : anwser.text;
            if (values[anwser.text]) {
                valuesCompleted.push({
                    id: anwser.id,
                    text: text,
                });
            }
        });

        try {
            await saveInboxPromptAnswer({
                variables: {
                    inboxPromptAnswerInput: {
                        universityId: university.id,
                        answers: valuesCompleted,
                        questionId: questions.inboxPromptQuestions[0]?.id,
                    },
                },
            });
            seIsSubmitted(true);
        } catch (error) {
            console.log(error);
            setValue(() => ({
                hasShown: true,
                isFirstTime: false,
                date: new Date(),
                dismissedDate: null,
            }));
            handleLastShowPopup();
        } finally {
            formik.setSubmitting(false);
        }
    };

    questions?.inboxPromptQuestions[0]?.options.map(
        (question) => (initialValues[question.text] = false),
    );

    return (
        <DefaultPopupWrapper isContextMenuOpen={true} setIsContextMenuOpen={handleClose}>
            <View
                padding={isDesktop ? "xlarge" : "medium"}
                marginTop={isDesktop ? "xsmall" : "large"}
            >
                {isSubmitted ? (
                    <Box paddingBottom={isDesktop ? "small" : "large"}>
                        <Stack space="large">
                            <View alignItems="center" paddingTop="small">
                                <Image
                                    accessibilityLabel={`popup central image of ${popUpCopy.title}`}
                                    source={thumbUp}
                                    style={{
                                        height: 92,
                                        width: 82,
                                    }}
                                />
                            </View>
                            <Heading level="4" align="center">
                                {successText}
                            </Heading>
                        </Stack>
                    </Box>
                ) : (
                    <Form onSubmit={handleSubmit} initialValues={initialValues}>
                        {({values, dirty}) => (
                            <Stack space="large">
                                {isDesktop ? (
                                    <View alignItems="center" paddingTop="small">
                                        <Image
                                            accessibilityLabel={`popup central image of ${popUpCopy.title}`}
                                            source={headerImage}
                                            style={{
                                                height: 115,
                                                width: 129,
                                            }}
                                        />
                                    </View>
                                ) : null}

                                <Heading level="4" align="left" style={{fontSize: 23}}>
                                    {popUpCopy.title}
                                </Heading>

                                <Stack space="medium">
                                    {questions?.inboxPromptQuestions[0]?.options.map((question) => (
                                        <CheckBox name={question.text} key={question.id}>
                                            <Text size="large">{question.text}</Text>
                                        </CheckBox>
                                    ))}
                                    {values["Other"] ? (
                                        <Box paddingTop="small">
                                            <FormGroup label="Tell us more">
                                                <TextArea name="more-info" />
                                            </FormGroup>
                                        </Box>
                                    ) : null}
                                    <View alignItems="center" paddingTop="small">
                                        <SubmitButton
                                            block={isDesktop ? false : true}
                                            color="primary"
                                            size="lg"
                                            padding={98}
                                            disabled={!dirty}
                                            style={{
                                                paddingRight: 98,
                                                paddingLeft: 98,
                                            }}
                                        >
                                            <Text
                                                size="large"
                                                style={{...boldFontStyles}}
                                                color="white"
                                            >
                                                {popUpCopy.buttonText}
                                            </Text>
                                        </SubmitButton>
                                    </View>
                                </Stack>
                            </Stack>
                        )}
                    </Form>
                )}
            </View>
        </DefaultPopupWrapper>
    );
};
