import React, {useEffect, useState} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {string} from "yup";
import {
    View,
    Text,
    Stack,
    Columns,
    Column,
    Heading,
    TextArea,
    Form,
    FormGroup,
    SubmitButton,
    Box,
} from "@unibuddy/patron";
import {Keyboard, LayoutAnimation} from "react-native";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {BottomSheet} from "../BottomSheet/BottomSheet";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useSendCommunityFeedbackMutation} from "ubcommunity-shared/src/types";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import AvoidKeyboard from "./AvoidKeyboard/AvoidKeyboard";
import {
    EmojiButton,
    FeedbackAnimatedContainer,
    FeedbackSuccessAnimatedContainer,
} from "./FeedbackUtils";
import BadEmoji from "./BadEmoji";
import NeutralEmoji from "./NeutralEmoji";
import GoodEmoji from "./GoodEmoji";
import {requestInAppReview} from "./InAppReview";
import {TrackEvents} from "ubcommunity-shared/src/constants";

const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;
export const minUsageTime = SEVEN_DAYS;

const hasTimeElapsed = (lastLogin: number) => {
    const diff = Date.now() - new Date(lastLogin).valueOf();
    return diff > minUsageTime;
};

type FeedbackPopupProps = {
    handleLastShowPopup: () => void;
    setIsPopupActive: (p: boolean) => void;
    handleNextPopup: () => void;
};

export const FeedbackPopup = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: FeedbackPopupProps) => {
    const {meta} = useAuth();

    if (!meta.lastLogin || !hasTimeElapsed(meta.lastLogin)) {
        handleNextPopup();
        return null;
    }

    return (
        <FeedbackPopupActive
            handleLastShowPopup={handleLastShowPopup}
            setIsPopupActive={setIsPopupActive}
            handleNextPopup={handleNextPopup}
        />
    );
};

export const question = "How satisfied are you with this app?";

const FeedbackPopupActive = ({
    handleLastShowPopup,
    setIsPopupActive,
    handleNextPopup,
}: FeedbackPopupProps) => {
    const {
        value: isOpen,
        setValue: setIsOpen,
        loading,
    } = useAsyncLocalStorage("showFeedbackPopup.v1", true);
    const {selectedCommunity, university} = useCommunity();
    const {darkModeEnabled} = useCommunityTheme();
    const [sendFeedback] = useSendCommunityFeedbackMutation();
    const {trackEvent} = useAnalytics();
    // 0 no feedback, 1 - 3 rating
    const [rating, setRating] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = ({comment}) => {
        Keyboard.dismiss();
        sendFeedback({
            variables: {
                rating,
                question,
                feedback: comment,
                universitySlug: university.slug,
                communityId: selectedCommunity.id,
            },
        });
        trackEvent("User Feedback Submitted", {
            rating,
            question,
            feedback: comment,
        });
        LayoutAnimation.easeInEaseOut();
        setIsSubmitted(true);
        setTimeout(() => {
            setIsOpen(false);
            handleLastShowPopup();
        }, 3000);
        setTimeout(() => {
            if (rating > 2) {
                trackEvent(TrackEvents.IN_APP_REVIEW_TRIGGERED);
                requestInAppReview();
            }
        }, 5000);
    };

    const handleDismiss = () => {
        Keyboard.dismiss();
        if (rating) {
            sendFeedback({
                variables: {
                    rating,
                    question,
                    universitySlug: university?.slug ?? "",
                    communityId: selectedCommunity?.id ?? "",
                },
            });
            trackEvent("User Feedback Submitted", {
                rating,
                question,
                feedback: null,
            });
            setIsOpen(false);
            setTimeout(() => {
                if (rating > 2) {
                    trackEvent(TrackEvents.IN_APP_REVIEW_TRIGGERED);
                    requestInAppReview();
                }
            }, 5000);
            handleLastShowPopup();
        } else {
            trackEvent("User Feedback Dismissed");
            setIsOpen(false);
            handleLastShowPopup();
        }
    };

    useEffect(() => {
        if (!loading && !isOpen) {
            handleNextPopup();
        }
        if (loading || !isOpen) return;
        trackEvent("User Feedback Opened");
        setIsPopupActive(true);
    }, [isOpen, trackEvent, loading]);

    return (
        <BottomSheet isOpen={!loading && isOpen} onDismiss={handleDismiss}>
            {!isSubmitted ? (
                <FeedbackAnimatedContainer>
                    <Stack space="large">
                        <Box width="100%" justifyContent="center">
                            <Box maxWidth="70%">
                                <Text size="large" align="center">
                                    {question}
                                </Text>
                            </Box>
                        </Box>
                        <Columns>
                            <Column>
                                <EmojiButton
                                    rating={rating}
                                    value={1}
                                    onPress={() => {
                                        LayoutAnimation.easeInEaseOut();
                                        setRating(1);
                                    }}
                                >
                                    <BadEmoji color={darkModeEnabled ? "#fff" : "#424b5a"} />
                                </EmojiButton>
                            </Column>
                            <Column>
                                <EmojiButton
                                    value={2}
                                    rating={rating}
                                    onPress={() => {
                                        LayoutAnimation.easeInEaseOut();
                                        setRating(2);
                                    }}
                                >
                                    <NeutralEmoji color={darkModeEnabled ? "#fff" : "#424b5a"} />
                                </EmojiButton>
                            </Column>
                            <Column>
                                <EmojiButton
                                    value={3}
                                    rating={rating}
                                    onPress={() => {
                                        LayoutAnimation.easeInEaseOut();
                                        setRating(3);
                                    }}
                                >
                                    <GoodEmoji color={darkModeEnabled ? "#fff" : "#424b5a"} />
                                </EmojiButton>
                            </Column>
                        </Columns>
                        {rating > 0 ? (
                            <Form
                                initialValues={{comment: ""}}
                                validationSchema={{comment: string()}}
                                onSubmit={handleSubmit}
                            >
                                <AvoidKeyboard>
                                    <Stack space="medium" align="center">
                                        <FormGroup label="What's the most important feature that you think is missing?">
                                            <TextArea name="comment" />
                                        </FormGroup>
                                        <SubmitButton block>Send to the product team</SubmitButton>
                                    </Stack>
                                </AvoidKeyboard>
                            </Form>
                        ) : null}
                    </Stack>
                </FeedbackAnimatedContainer>
            ) : (
                <View padding="xlarge" alignItems="center" justifyContent="center">
                    <FeedbackSuccessAnimatedContainer>
                        <View accessible>
                            <Stack space="xlarge">
                                <Heading level="1" size="xxlarge" align="center">
                                    🎉
                                </Heading>
                                <Text>
                                    Thank you for helping other students like you get the most out
                                    of this app
                                </Text>
                            </Stack>
                        </View>
                    </FeedbackSuccessAnimatedContainer>
                </View>
            )}
        </BottomSheet>
    );
};
