import {useCallback} from "react";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";

/**
 * @deprecated This hook is deprecated. Please use useNavigate from packages/shared/src/Hooks/useNavigate.ts
 */
export function useNavigate() {
    const {push} = useRelativeRouter();

    const navigate = useCallback(
        ({path, options}: {path: string; options?: Record<string, any>}) => {
            if (options?.screen) {
                push(`${path}/${options.screen}`);
                return;
            }
            push(path);
        },
        [push],
    );

    return [navigate];
}
