import * as React from "react"
const CopyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.375 1.5A.375.375 0 0 0 6 1.875v12.75c0 .207.168.375.375.375h9.75a.375.375 0 0 0 .375-.375v-4.5A2.625 2.625 0 0 0 13.875 7.5h-1.5A1.875 1.875 0 0 1 10.5 5.625v-1.5A2.625 2.625 0 0 0 7.875 1.5h-1.5Zm5.132.668c.314.582.493 1.249.493 1.957v1.5c0 .207.168.375.375.375h1.5c.708 0 1.375.178 1.957.493a8.28 8.28 0 0 0-4.325-4.325ZM18 14.625c0 1.036-.84 1.875-1.875 1.875H13.5v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 0 19.125V6.375C0 5.339.84 4.5 1.875 4.5H3.75c.252 0 .502.01.75.028V1.875C4.5.839 5.34 0 6.375 0H8.25a9.81 9.81 0 0 1 1.624.135C14.486.908 18 4.918 18 9.75v4.875ZM4.5 6.034A8.365 8.365 0 0 0 3.75 6H1.875a.375.375 0 0 0-.375.375v12.75c0 .207.168.375.375.375h9.75a.375.375 0 0 0 .375-.375V16.5H6.375A1.875 1.875 0 0 1 4.5 14.625V6.034Z"
      clipRule="evenodd"
    />
  </svg>
)
export default CopyIcon
