import React from "react";
import {View, Text} from "@unibuddy/patron";
import {Flag} from "ubcommunity-shared/src/Patron/Flag";
import {getAccountRoleName} from "../General/getAccountRoleName";

const CountryFlag = ({sender}) => (
    <Flag size={10} label={sender.country.name} code={sender.country.code} />
);

export const MessageTop = ({sender}) => {
    if (sender.accountRole === "applicant") {
        return (
            <View flexDirection="row" alignItems="center">
                <Text size="xxsmall">{sender.firstName}</Text>
                {sender.country ? (
                    <View marginLeft="xxsmall">
                        <CountryFlag sender={sender} />
                    </View>
                ) : null}
            </View>
        );
    }
    return (
        <View flexDirection="row" alignItems="center">
            <Text size="xxsmall">{sender.firstName}</Text>
            {sender.country ? (
                <View marginX="xxsmall">
                    <CountryFlag sender={sender} />
                </View>
            ) : (
                <View marginX="xxsmall">
                    <Text size="xxsmall">·</Text>
                </View>
            )}
            <Text color="textLinkColor" size="xxsmall" weight="600">
                {getAccountRoleName(sender.accountRole)}
            </Text>
        </View>
    );
};
