import * as React from "react";
import {Pressable} from "react-native";
import {Text, Stack, TextLine} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useInitiateUserDeletionMutation} from "ubcommunity-shared/src/types";
import {useLogoutWithoutSideEffects} from "ubcommunity-shared/src/Auth/hooks/useLogout";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {Link} from "ubcommunity-shared/src/General/Link/Link";

export const SUPPORT_EMAIL = "support@unibuddy.com";

export const UserDeletionContainer = () => {
    const [initiateUserDeletion, {loading, error}] = useInitiateUserDeletionMutation();
    const {logout} = useLogoutWithoutSideEffects();

    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();
    const {open} = usePrompt();

    const onDelete = async () => {
        try {
            trackEvent("User Deletion: Initiated");
            await initiateUserDeletion();
            logout();
        } catch (err) {
            reportError(err);
        }
    };

    const handleAccountDelete = () => {
        open({
            title: "Are you sure you want to permanently delete your Unibuddy account?",
            message:
                "You will be logged out of the Unibuddy app and your Unibuddy account will be deleted within 72 hours. You will no longer have access to any Unibuddy products. This is not reversible.",
            buttons: [
                {
                    text: "Delete",
                    style: "destructive",
                    onPress: () => onDelete(),
                },
                {text: "Cancel"},
            ],
        });
    };

    return (
        <Stack space="medium">
            {!loading ? (
                <Pressable onPress={handleAccountDelete} accessibilityRole="button">
                    <Text size="small" color="#757575">
                        If you would like to permanently delete your Unibuddy account, please{" "}
                        <TextLine color="primaryColor">click here</TextLine>.
                    </Text>
                </Pressable>
            ) : null}
            {error ? (
                <Text size="small">
                    An error occurred. Please try again. If the problem persists you can reach out
                    to{" "}
                    <Link external href={`mailto:${SUPPORT_EMAIL}`}>
                        <TextLine color="primaryColor">{SUPPORT_EMAIL}</TextLine>
                    </Link>
                </Text>
            ) : null}
        </Stack>
    );
};
