import {Routes} from "ubcommunity-shared/src/constants/routes.native";

export enum AccountInformationSource {
    PANEL = "panel",
    PROFILE_PICTURE = "profile picture",
}
export type SettingsStackParamList = {
    [Routes.ACCOUNT_INFORMATION]?: {source?: AccountInformationSource};
    [Routes.USER_SETTINGS]: undefined;
    [Routes.DEVELOPER_OPTIONS]: undefined;
    [Routes.PROFILE_PICTURE]: undefined;
};
