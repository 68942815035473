import React, {useCallback, useState} from "react";
import {Box, View} from "@unibuddy/patron";
import noop from "lodash/noop";
import {ChatData} from "ubcommunity-shared/src/Chat/ChatData";
import {useGetCommunityConversationQuery} from "ubcommunity-shared/src/types";
import {ChatSideDrawer} from "../ChatPage/ChatSideDrawer";
import {UserBlockModal} from "../ChatPage/UserBlockModal";
import {SpotlightModal} from "ubcommunity-web/src/General/Spotlight/SpotlightModal";
import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import {useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {UserSummary} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary";
import useFindSenderInPair from "ubcommunity-shared/src/Auth/hooks/useFindSenderInPair";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {useParams} from "react-router";
import {useIsGroupMember} from "ubcommunity-shared/src/Hooks/useIsGroupMember";

export const ChatPreview = () => {
    const {findSenderInPair} = useFindSenderInPair();
    const [spotlight, setSpotlight] = useState<{
        images: IImagePreview[];
        selectedImage: IImagePreview;
    }>({images: [], selectedImage: null});
    const {openModal, dismissModal} = useModal();
    const {openUserSummary, closeUserSummary} = useOpenUserSummary({
        openSource: "Chat page",
    });
    const {id} = useParams<any>();
    const [navigate] = useNavigate();

    const {data, loading, error, refetch} = useGetCommunityConversationQuery({
        variables: {
            id,
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const {isMember, loading: membersLoading} = useIsGroupMember(id);

    const handleUserBlock = useCallback(
        ({senderId, type}) => {
            closeUserSummary();
            openModal({
                component: <UserBlockModal senderId={senderId} onDismiss={dismissModal} />,
            });
        },
        [openModal, dismissModal, closeUserSummary],
    );

    const handleShowUserSummary = useCallback(
        (userId: string, mixpanelMetadata?: MixpanelMetadataProps): void => {
            openUserSummary(userId, id, mixpanelMetadata);
        },
        [openUserSummary, id],
    );

    const handleSpotlight = useCallback((images: IImagePreview[], selectedImage: IImagePreview) => {
        setSpotlight({images, selectedImage});
    }, []);

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{
                    component: "DesktopChatPreviewPage",
                    query: "useGetCommunityConversationQuery",
                }}
                layout="center"
            />
        );
    }

    const Header = ({children}) => {
        return (
            <View flex="1">
                <BackButton
                    callback={() => {
                        navigate({
                            path: Routes.EXPLORE,
                        });
                    }}
                />
                {children}
            </View>
        );
    };

    return (
        <Box bgColor="white" display="flex" flex="1">
            <Header>
                <ChatData
                    key={id}
                    onBlockOrReport={noop}
                    conversationId={id}
                    onShowUserSummary={handleShowUserSummary}
                    onSpotlight={handleSpotlight}
                    onUserBlock={handleUserBlock}
                    isMember={isMember}
                    membersLoading={membersLoading}
                    onPreview={noop}
                />
            </Header>

            <SpotlightModal
                isOpen={!!spotlight.selectedImage}
                onDismiss={() => setSpotlight({images: [], selectedImage: null})}
                images={spotlight.images}
                selectedImage={spotlight.selectedImage}
            />

            <View
                w={320}
                shadow="medium"
                display={["none", "none", "none", "flex"]}
                zIndex={1}
                position="relative"
            >
                {data?.getChatConversation.isDirect ? (
                    <UserSummary
                        senderId={
                            findSenderInPair(data?.getChatConversation.communityChatMembers).id
                        }
                        conversationId={id}
                        openSource="Chat preview page"
                        isStatic
                    />
                ) : (
                    <ChatSideDrawer
                        conversation={data?.getChatConversation}
                        loading={loading}
                        onShowUserSummary={(userId, mixpanelMetadata = {}) => {
                            mixpanelMetadata.openSource = "Members List";
                            handleShowUserSummary(userId, mixpanelMetadata);
                        }}
                    />
                )}
            </View>
        </Box>
    );
};
