import {
    UniversalInboxItem,
    UniversalInboxItemTitle,
} from "ubcommunity-shared/src/UniversalInbox/UniversalInboxItem";
import {UniversalInboxConversationFragment} from "ubcommunity-shared/src/types";
import {UniversalInboxProps} from "./UniversalInbox";
import {InstitutionLogo} from "../General/InstitutionLogo/InstitutionLogo";

export type InboxItem = {
    item: UniversalInboxConversationFragment;
} & UniversalInboxProps;

export function NewsFeedUniversalInboxItem({item, onSelect, activeConversationId}: InboxItem) {
    let a11yLabel = "Updates channel";
    if (item.university?.name) {
        a11yLabel += ` for ${item.university.name}`;
    }

    return (
        <UniversalInboxItem
            a11yLabel={a11yLabel}
            onPress={() => onSelect({id: item.id})}
            item={item}
            avatar={
                <InstitutionLogo src={item.university?.squareLogo} name={item.university?.name} />
            }
            title={<UniversalInboxItemTitle>Updates 📣</UniversalInboxItemTitle>}
            isActive={activeConversationId === item.id}
        />
    );
}
