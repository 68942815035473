import {IconUbStudent, IconUbStaff} from "@unibuddy/icons";
import {useAnalytics} from "@unibuddy/tracking";
import {TrackEvents, UniversityFeatureFlags} from "ubcommunity-shared/src/constants";
import {useUniversityFeature} from "ubcommunity-shared/src/Hooks/useUniversityFeature";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {FeatureFlags} from "ubcommunity-shared/src/featureFlags";
import {TabValues} from "ubcommunity-shared/src/constants/trackingConstants";
import {AmbassadorList} from "./AmbassadorList";
import {StaffList} from "./StaffList";
import {TabNav} from "../TabbedNavigation/TabNav";

export function Ambassadors() {
    const {trackEvent} = useAnalytics();

    const {isFeatureEnabled} = useUniversityFeature();
    const chatToStaffFeatureFlag = useIsFeatureEnabledBoolean(
        FeatureFlags.STUDENT_APP_CHAT_TO_STAFF,
    );
    const isStaffAmbosEnabled = isFeatureEnabled(UniversityFeatureFlags.STAFF_PROFILES);

    return isStaffAmbosEnabled && chatToStaffFeatureFlag ? (
        <TabNav
            tabs={[
                {
                    label: "Students",
                    icon: IconUbStudent,
                    component: AmbassadorList,
                    onTabChange: () => {
                        trackEvent(TrackEvents.TAB_PRESSED, {
                            tab: TabValues.STUDENTS,
                        });
                    },
                },
                {
                    label: "Staff",
                    icon: IconUbStaff,
                    component: StaffList,
                    onTabChange: () => {
                        trackEvent(TrackEvents.TAB_PRESSED, {
                            tab: TabValues.STAFF,
                        });
                    },
                },
            ]}
        />
    ) : (
        <AmbassadorList />
    );
}
