import {useIsFeatureAdminEnabled} from "ubcommunity-shared/src/Hooks/useIsFeatureAdminEnabled";
import {AdminFeatureFlags} from "ubcommunity-shared/src/constants";

export const PrivateGroupsFFWrapper = ({children}) => {
    const isPrivateGroupsAdminEnabled = useIsFeatureAdminEnabled(
        AdminFeatureFlags.PRIVATE_GROUPS_SETTINGS,
    );
    const isPrivateGroupsEnabled = isPrivateGroupsAdminEnabled;

    if (!isPrivateGroupsEnabled) return null;

    return children;
};
