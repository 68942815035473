import {InMemoryCache, FieldPolicy} from "@apollo/client";

export const getUsersCommunityConversationsTypePolicy: FieldPolicy = {
    keyArgs: ["communityId"],
    merge(existing, incoming, {args: {offsetPagination}}) {
        let offset = 0;
        if (offsetPagination) {
            offset = offsetPagination.offset;
        }
        const merged = existing ? existing.slice(0) : [];
        for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
        }
        return merged;
    },
};

export const communityUserSearchTypePolicy: FieldPolicy = {
    keyArgs: [
        "filterUsersDTO",
        ["areaOfStudy", "communityId", "interests", "housingTypes", "country", "hideSelf"],
    ],
    merge(existing, incoming, {args: {pagination}}) {
        let offset = 0;
        if (pagination) {
            offset = pagination.offset;
        }
        if (!incoming.result) {
            return existing || {result: [], totalUsers: 0};
        }
        const merged = existing?.result ? existing.result.slice(0) : [];
        for (let i = 0; i < incoming.result.length; ++i) {
            merged[offset + i] = incoming.result[i];
        }
        return {...incoming, result: merged};
    },
};

export const sequenceFilterMentorListTypePolicy: FieldPolicy = {
    keyArgs: ["universitySlug"],
    merge(existing, incoming, {args: params}) {
        const offset = params?.offset ?? 0;

        if (!incoming.mentors) {
            return existing || {mentors: []};
        }
        const merged = existing?.mentors ? existing.mentors.slice(0) : [];
        for (let i = 0; i < incoming.mentors.length; ++i) {
            merged[offset + i] = incoming.mentors[i];
        }
        return {...incoming, mentors: merged};
    },
};

export const buddiesTypePolicy: FieldPolicy = {
    keyArgs: ["universitySlug"],
    merge(existing, incoming, {args: params}) {
        const offset = params?.offset ?? 0;
        if (!incoming.users) {
            return existing || {users: []};
        }
        const merged = existing?.users ? existing.users.slice(0) : [];
        for (let i = 0; i < incoming.users.length; ++i) {
            merged[offset + i] = incoming.users[i];
        }
        return {...incoming, users: merged};
    },
};

export const discoverableUniversitiesTypePolicy: FieldPolicy = {
    keyArgs: [""],
    merge(existing, incoming, {args: params}) {
        const offset = params?.offset ?? 0;

        if (!incoming) {
            return existing || [];
        }
        const merged = existing ? existing.slice(0) : [];
        for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
        }
        return merged;
    },
};

export const GetRecommendedUsersByCriterionQueryTypePolicy: FieldPolicy = {
    keyArgs: ["matchingCriterion"],
    merge(existing, incoming, {args: {pagination}}) {
        let offset = 0;
        if (pagination) {
            offset = pagination.offset;
        }
        if (!incoming.matches) {
            return existing || {matches: [], totalMatches: 0};
        }

        const uniqueUsers = new Set();
        const merged = existing?.matches ? existing.matches.slice(0) : [];

        for (const match of merged) {
            uniqueUsers.add(match.user.__ref);
        }

        for (let i = 0; i < incoming.matches.length; ++i) {
            const incomingMatch = incoming.matches[i];
            if (!uniqueUsers.has(incomingMatch.user.__ref)) {
                merged[offset + i] = incomingMatch;
                uniqueUsers.add(incomingMatch.user.__ref);
            }
        }

        const final = {...incoming, matches: merged};
        return final;
    },
};

export const GetChatConversationsForUserQueryTypePolicy: FieldPolicy = {
    keyArgs: [],
    merge(existing = [], incoming, {args: {offsetPagination}}) {
        if (existing.length === 0) return incoming;

        let offset = 0;
        if (offsetPagination) {
            offset = offsetPagination.offset;
        }

        // initialize merged with everything before the start offset in existing
        const merged = existing.slice(0, offset);

        // go through existing items, and see if it matches the incoming ref
        // if so add it to merged, if not add the incoming
        for (let i = 0; i < incoming.length; i++) {
            const index = offset + i;
            if (existing[index] && existing[index].__ref === incoming[i].__ref) {
                merged[index] = existing[index];
            } else {
                merged[index] = incoming[i];
            }
        }
        return merged;
    },
};

export const apolloCache = new InMemoryCache({
    // This doesn’t work correctly with the manual updates to the cache as we are doing it
    // currently inside useChat. We will need to change those to use `cache.modify()`.
    // typePolicies: {
    //     Query: {
    //         fields: {
    //             getChatConversationMessages: {
    //                 // By excluding pagination args in cache key creation we ensure
    //                 // a single cache key per conversationId
    //                 keyArgs: ({getMessageDto: {conversationId}}) => {
    //                     return JSON.stringify({getMessageDto: {conversationId}});
    //                 },
    //                 merge(existing, incoming, {variables}) {
    //                     // console.log(variables);
    //                     // if (!variables.offsetId) return incoming;
    //                     if (!existing) return incoming;
    //                     return {
    //                         ...incoming,
    //                         messages: [...incoming.messages, ...existing.messages],
    //                     };
    //                 },
    //             },
    //         },
    //     },
    // },
    typePolicies: {
        Query: {
            fields: {
                getCommunityChatMembers: {
                    keyArgs: ["conversationId"],
                    merge(existing, incoming) {
                        return existing ? [...existing, ...incoming] : incoming;
                    },
                },
                communityUserSearch: communityUserSearchTypePolicy,
                getUsersCommunityConversations: getUsersCommunityConversationsTypePolicy,
                getRecommendedUsers: GetRecommendedUsersByCriterionQueryTypePolicy,
                getChatConversationsForUser: GetChatConversationsForUserQueryTypePolicy,
                sequenceFilterMentorList: sequenceFilterMentorListTypePolicy,
                buddies: buddiesTypePolicy,
                discoverableUniversities: discoverableUniversitiesTypePolicy,
            },
        },
    },
});
