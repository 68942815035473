import {Column, Columns, Label, Select, Stack, View} from "@unibuddy/patron";
import {useEffect, useState} from "react";
import useAuth from "../Auth/hooks/useAuth";
import {
    useAdminLobbyQuery,
    useGetCommunitiesQuery,
    useJoinCommunityForAdminsMutation,
} from "../types";
import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";
import useRelativeRouter from "../Navigation/useRelativeRouter/useRelativeRouter";
import {Routes} from "../constants";
import ActivityIndicator from "../General/ActivityIndicator/ActivityIndicator";
import {AdminInboxStrings} from "./AdminInbox";
import {MutationErrorHandler} from "../General/Errors/MutationErrorHandler";

export const AdminInboxHeader = () => {
    const {user} = useAuth();
    const [error, setError] = useState();
    const {select, selectedCommunity} = useCommunity();
    const {data} = useAdminLobbyQuery({
        variables: {
            institutionId: user?.university?.id ?? "",
        },
    });
    const {updateQuery} = useGetCommunitiesQuery({
        fetchPolicy: "cache-only",
        variables: {
            userId: user?.id ?? "",
        },
    });
    const [joinCommunity, {loading}] = useJoinCommunityForAdminsMutation();
    const {push} = useRelativeRouter();
    const [value, setValue] = useState<string>();

    useEffect(() => {
        if (!selectedCommunity) return;
        setValue(selectedCommunity.id);
    }, [selectedCommunity]);

    const onChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const communityToSelect = data?.listCommunitiesForAnInstitution?.find(
            (community) => community.id === e.target.value,
        );

        if (!communityToSelect) return;

        const {members, ...communityWithoutMembers} = communityToSelect;
        const isPartOfCommunity = members?.some((member) => member.id === user?.id);

        try {
            if (!isPartOfCommunity) {
                const res = await joinCommunity({
                    variables: {communityId: communityWithoutMembers.id},
                });
                const community = res.data?.joinCommunityForAdmins.community;
                updateQuery((cache: any) => {
                    if (!cache?.getUser?.communities) return cache;
                    return {
                        getUser: {
                            ...cache.getUser,
                            communities: [...cache.getUser.communities, community],
                        },
                    };
                });
            }
        } catch (e) {
            setError(e);
            return;
        }

        setValue(e.target.value);
        push(Routes.CHAT);
        select({
            ...communityWithoutMembers,
            archived: false,
            requestedPublicGroups: 0,
            inviteLinks: [],
            communityChatGroups: [],
        });
    };

    return (
        <View padding="small">
            <Columns verticalAlign="center">
                <Column>
                    <Stack space="xsmall">
                        <Label id="select-community">
                            {AdminInboxStrings.SelectCommunityInbox}
                        </Label>
                        <Select
                            id="select-community"
                            formatValue={(e) => e}
                            value={value}
                            onChange={onChange}
                        >
                            <option value="">Select a community</option>
                            {data?.listCommunitiesForAnInstitution?.map((community) => (
                                <option key={community.id} value={community.id}>
                                    {community.title}
                                </option>
                            ))}
                        </Select>
                        {error ? (
                            <MutationErrorHandler
                                error={error}
                                message={AdminInboxStrings.JoinCommunityError}
                            />
                        ) : null}
                    </Stack>
                </Column>
                <Column width="content">
                    {loading ? (
                        <View paddingLeft="small">
                            <ActivityIndicator
                                accessibilityLabel={AdminInboxStrings.JoiningCommunity}
                            />
                        </View>
                    ) : null}
                </Column>
            </Columns>
        </View>
    );
};
