import React from "react";
import {FormGroup, Stack, TextInput} from "@unibuddy/patron";

export function EditName() {
    return (
        <Stack space="medium-large">
            <FormGroup label="First name">
                <TextInput name="firstName" required />
            </FormGroup>
            <FormGroup label="Last name">
                <TextInput name="lastName" required />
            </FormGroup>
        </Stack>
    );
}
