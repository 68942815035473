import React from "react";

export default function EditIcon(props) {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            xmlns="http://www.w3.org/2000/svg"
            fill="current"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                d="M16.9641 4.5C17.0964 4.50003 17.2234 4.55253 17.3171 4.646L18.7311 6.061C18.8248 6.15476 18.8775 6.28192 18.8775 6.4145C18.8775 6.54708 18.8248 6.67424 18.7311 6.768L7.06409 18.435L4.58909 18.789L4.93909 16.314L16.6101 4.646C16.6566 4.5996 16.7118 4.56282 16.7726 4.53777C16.8333 4.51272 16.8984 4.49988 16.9641 4.5ZM16.9641 3C16.701 2.99999 16.4406 3.05203 16.1977 3.15312C15.9549 3.2542 15.7344 3.40234 15.5491 3.589L3.76409 15.371C3.61117 15.5238 3.51188 15.722 3.48109 15.936L3.01009 19.236C2.98812 19.3895 3.00214 19.546 3.05106 19.6932C3.09998 19.8403 3.18245 19.9741 3.29196 20.0839C3.40146 20.1937 3.535 20.2765 3.68203 20.3258C3.82905 20.3751 3.98553 20.3896 4.13909 20.368L7.43909 19.896C7.65342 19.8654 7.85204 19.7661 8.00509 19.613L19.7921 7.828C20.167 7.45294 20.3777 6.94433 20.3777 6.414C20.3777 5.88367 20.167 5.37506 19.7921 5L18.3781 3.589C18.1926 3.403 17.9722 3.25549 17.7296 3.15492C17.4869 3.05436 17.2268 3.00273 16.9641 3.003V3Z"
                fill={props.color}
            />
            <path
                d="M19.8081 20.614H11.3081C11.1092 20.614 10.9184 20.535 10.7778 20.3943C10.6371 20.2537 10.5581 20.0629 10.5581 19.864C10.5581 19.6651 10.6371 19.4743 10.7778 19.3337C10.9184 19.193 11.1092 19.114 11.3081 19.114H19.8081C20.007 19.114 20.1978 19.193 20.3384 19.3337C20.4791 19.4743 20.5581 19.6651 20.5581 19.864C20.5581 20.0629 20.4791 20.2537 20.3384 20.3943C20.1978 20.535 20.007 20.614 19.8081 20.614Z"
                fill={props.color}
            />
            <path
                d="M13.6178 5.63298L12.5571 6.69364L16.7411 10.8776L17.8017 9.81693L13.6178 5.63298Z"
                fill={props.color}
            />
        </svg>
    );
}
