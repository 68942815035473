import React from "react";
import {
    OptionFormGroup as PatronOptionFormGroup,
    OptionItem as PatronOptionItem,
    OptionLabel as PatronOptionLabel,
} from "@unibuddy/patron";
export {useOption} from "@unibuddy/patron";

export const OptionLabel = (props) => {
    return <PatronOptionLabel {...props} />;
};

export const OptionItem = (props) => {
    return <PatronOptionItem {...props} />;
};

export const OptionFormGroup = (props) => {
    return <PatronOptionFormGroup {...props} />;
};
