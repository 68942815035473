import {PropsWithChildren} from "react";
import {Box, Button, Inline, Stack, View, Text} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {
    SpaceType,
    useSpaces,
} from "ubcommunity-shared/src/StudentApp/SpacesProvider/SpacesProvider";
import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import {LocalStorageNames, TrackEvents} from "../constants";
import {HomeBarContent} from "./HomeBarContent";
import {HomeGradient} from "./HomeGradient";

export const enum SelectionDirection {
    NEXT = "next",
    PREVIOUS = "previous",
}

const DotsWrapper = ({children, hide}: PropsWithChildren<{hide: boolean}>) => {
    if (hide) return null;

    return (
        <View alignItems="center" w="full">
            {children}
        </View>
    );
};

export const HomeBar = () => {
    const {spaces, selectedSpace, setSelectedSpace, selectCommunitySpace} = useSpaces();
    const {trackEvent} = useAnalytics();
    const currentIndex = spaces.findIndex((space) => space.id === selectedSpace?.id);
    const {
        value: showSpaceChanger,
        setValue: setShowSpaceChanger,
        loading,
    } = useAsyncLocalStorage(LocalStorageNames.SHOW_SPACE_CHANGER, true);

    const handleSpaceSelection = (direction: SelectionDirection) => {
        const nextIndex =
            direction === SelectionDirection.NEXT ? currentIndex + 1 : currentIndex - 1;

        const spaceToSelect = spaces[(nextIndex + spaces.length) % spaces.length];

        setSelectedSpace(spaceToSelect);

        if (spaceToSelect.type === SpaceType.COMMUNITY) {
            selectCommunitySpace(spaceToSelect.id);
        }

        trackEvent(TrackEvents.SPACE_SELECTED, {
            id: spaceToSelect.id,
            name: spaceToSelect.name,
            type: spaceToSelect.type,
            slug: spaceToSelect.slug,
            direction,
        });
    };

    const handleSpaceChangerToggle = (visibility: boolean) => {
        setShowSpaceChanger(visibility);
        trackEvent(TrackEvents.SPACE_CHANGER_TOGGLED, {
            showSpaceChanger: visibility,
        });
    };

    const hasOnlyOneSpace = spaces.length === 1;
    const hasNoSpaces = spaces.length === 0;

    if (loading || hasNoSpaces) {
        return null;
    }

    return (
        <HomeGradient>
            <View w="100%" maxW={768} paddingLeft={["none", "none", "small-medium"]}>
                {showSpaceChanger ? (
                    <Stack space="small-medium">
                        <HomeBarContent
                            selectedSpace={selectedSpace}
                            handleSpaceChangerToggle={handleSpaceChangerToggle}
                            handleSpaceSelection={handleSpaceSelection}
                            hasOnlyOneSpace={hasOnlyOneSpace}
                        />
                        <DotsWrapper hide={hasOnlyOneSpace}>
                            <Inline space="xxsmall">
                                {spaces.map((space, index) => {
                                    const active = selectedSpace?.id === space.id;
                                    const label = `${space.name}${active ? " selected." : "."} ${
                                        index + 1
                                    }/${spaces.length}`;

                                    return (
                                        <Box
                                            key={space.id}
                                            h={6}
                                            w={active ? 12 : 6}
                                            bgColor={active ? "white" : "rgba(255, 255, 255, 0.8)"}
                                            borderRadius="full"
                                            role="presentation"
                                            aria-label={label}
                                            aria-current={active ? "true" : "false"}
                                        />
                                    );
                                })}
                            </Inline>
                        </DotsWrapper>
                    </Stack>
                ) : (
                    <View alignItems="center">
                        <Button
                            round
                            ghost
                            size="sm"
                            style={{
                                borderColor: "white",
                                height: 30,
                            }}
                            onClick={() => handleSpaceChangerToggle(true)}
                        >
                            <Text size="xxsmall" color="white">
                                Show details
                            </Text>
                        </Button>
                    </View>
                )}
            </View>
        </HomeGradient>
    );
};
