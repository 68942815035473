import {useParams} from "react-router";
import {useGetNewsFeedGroupId} from "../NewsFeed/useGetNewsFeedGroupId";

export const useGetConversationId = () => {
    const {id} = useParams<{id: string}>();

    const {id: newsFeedConversationId} = useGetNewsFeedGroupId();

    return {
        id: id || newsFeedConversationId,
    };
};
