import React, {useEffect, useState, PropsWithChildren} from "react";
import styled from "styled-components";
import {CloseButton} from "@unibuddy/patron";

const Backdrop = styled.div<{isOpenDelayed: boolean; isOpen: boolean}>`
    background: #a1aeb7;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 98;
    opacity: ${({isOpen}) => (isOpen ? 0.2 : 0)};
    transition: 0.3s ease opacity;
    pointer-events: ${({isOpenDelayed}) => (isOpenDelayed ? "auto" : "none")};
`;

const Container = styled.div<{isOpen: boolean}>`
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: ${({isOpen}) => (isOpen ? "none" : "translate3d(0, 100%, 0)")};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding-top: 8px;
    z-index: 99;
    transition: 0.3s ease transform;

    @media (min-width: 768px) {
        max-width: 420px;
        left: 50%;
        border-radius: 12px;
        transform: ${({isOpen}) =>
            isOpen ? "translate3d(-50%, -10px, 0)" : "translate3d(-50%, 100%, 0)"};
    }
`;

type BottomSheetProps = PropsWithChildren<{
    isOpen: boolean;
    hideCloseButton?: boolean;
    onDismiss: () => void;
}>;

// DEPRECATED: Consider using the new ChatContextMenu.native instead
export const BottomSheet = ({
    isOpen,
    children,
    onDismiss,
    hideCloseButton = false,
}: BottomSheetProps) => {
    const [isOpenDelayed, setIsOpenDelayed] = useState(false);
    const [isClosedDelayed, setIsClosedDelayed] = useState(true);

    // If we toggle pointerEvents to auto as soon as isOpen is true the de-press on the chat bubble
    // will register a press on the backdrop and immediately close the menu. This is why we use "isOpenDelayed"
    // variable to toggle pointerEvents so that backdrop is not clickable for half a second.
    useEffect(() => {
        if (!isOpen) {
            setIsOpenDelayed(isOpen);
            return;
        }
        const id = setTimeout(() => {
            setIsOpenDelayed(isOpen);
        }, 600);
        return () => {
            clearTimeout(id);
        };
    }, [isOpen]);

    // We want to remove the children from the DOM after the closing animation has run
    // so we wait 300ms and flip the isOpen. When isOpen is true we set isClosed is false instantly
    // because we want to render the buttons on screen and run the opening animation as soon as possible.
    useEffect(() => {
        if (isOpen) {
            setIsClosedDelayed(!isOpen);
            return;
        }
        const id = setTimeout(() => {
            setIsClosedDelayed(!isOpen);
        }, 300);
        return () => {
            clearTimeout(id);
        };
    }, [isOpen]);

    return (
        <>
            <Backdrop
                aria-hidden
                isOpen={isOpen}
                onClick={onDismiss}
                isOpenDelayed={isOpenDelayed}
            />
            <Container
                aria-hidden={!isOpen}
                id="bottom-sheet"
                // We wait for isClosedDelayed to be false so we know the contents are rendered on screen
                // before we run the opening animation
                isOpen={isOpen && !isClosedDelayed}
            >
                {/* @ts-ignore */}
                {!hideCloseButton ? (
                    <CloseButton
                        aria-expanded={isOpen}
                        aria-controls="bottom-sheet"
                        onClick={onDismiss}
                        aria-label="Close menu"
                    />
                ) : null}
                {isClosedDelayed ? null : children}
            </Container>
        </>
    );
};
