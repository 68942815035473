import React from "react";
import {
    UniversalInboxItem,
    UniversalInboxItemTitle,
} from "ubcommunity-shared/src/UniversalInbox/UniversalInboxItem";
import {ChatMembersAvatars} from "ubcommunity-shared/src/Chat/ChatMembersAvatars/ChatMembersAvatars";
import {UniversalInboxConversationFragment, UserField} from "ubcommunity-shared/src/types";
import {UserAvatarUser} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {UniversalInboxProps} from "./UniversalInbox";
import {UniversalInboxUserRole, getUniversalInboxUserRoleTag} from "./utils";
import useFindSenderInPair from "../Auth/hooks/useFindSenderInPair";

export type InboxItem = {
    item: UniversalInboxConversationFragment;
} & UniversalInboxProps;

export function CommunityUniversalInboxItem({item, onSelect, activeConversationId}: InboxItem) {
    const {findSenderInPair} =
        useFindSenderInPair<Omit<UserField, "interests" | "communityProfile">>();

    const groupMembers = item.members?.slice(-3) ?? [];
    let members = groupMembers;
    const isDirect = item.isDirect;

    let tag: UniversalInboxUserRole | undefined;
    let allyLabel = `Group conversation ${item.communityChatTitle ?? ""}`;

    if (isDirect) {
        const sender = findSenderInPair(groupMembers);
        if (sender) {
            members = [sender];
        }
        tag = sender?.accountRole ? getUniversalInboxUserRoleTag(sender?.accountRole) : undefined;
        allyLabel = `Conversation with ${item.communityChatTitle ?? ""}${tag ? ", " + tag : ""}`;
    }
    members = members.filter((member) => member);

    return (
        <UniversalInboxItem
            a11yLabel={allyLabel}
            onPress={() => onSelect({id: item.id})}
            item={item}
            avatar={<ChatMembersAvatars members={members as UserAvatarUser[]} />}
            title={
                <UniversalInboxItemTitle>{item.communityChatTitle ?? ""}</UniversalInboxItemTitle>
            }
            tag={tag}
            isActive={activeConversationId === item.id}
        />
    );
}
