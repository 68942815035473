import React, {useMemo} from "react";
import type {AlertButton} from "react-native";
import {Stack, Button, Text} from "@unibuddy/patron";
import {useDialog} from "../useDialog/useDialog";

interface Config {
    title: string;
    buttons: AlertButton[];
    message?: string;
}

export const usePrompt = () => {
    const {openPrompt, dismissPrompt} = useDialog();
    const hookValue = useMemo(
        () => ({
            open: ({title, message, buttons}: Config) => {
                openPrompt({
                    title,
                    message,
                    body: (
                        <Stack dividers>
                            {buttons.map((options) => {
                                return (
                                    <Button
                                        key={options.text}
                                        full
                                        clear
                                        onClick={() => {
                                            dismissPrompt();
                                            if (options.onPress) options.onPress();
                                        }}
                                        color={
                                            options.style === "destructive" ? "danger" : "default"
                                        }
                                    >
                                        <Text
                                            color={
                                                options.style === "destructive"
                                                    ? "#A62A26"
                                                    : undefined
                                            }
                                            size="large"
                                        >
                                            {options.text}
                                        </Text>
                                    </Button>
                                );
                            })}
                        </Stack>
                    ),
                });
            },
        }),
        [dismissPrompt, openPrompt],
    );
    return hookValue;
};
