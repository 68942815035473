import {
    Box,
    CheckBox,
    Heading,
    Stack,
    Text,
    TextLine,
    usePatronTheme,
    View,
} from "@unibuddy/patron";
import {boolean} from "yup";
import Form from "ubcommunity-shared/src/Forms/Form/Form";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton.native";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {Community, InviteLinkInfoDto} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {Image} from "react-native";
import {Link} from "ubcommunity-shared/src/General/Link/Link";

export const Confirm = ({community, onConfirm}: {community: Community; onConfirm: () => void}) => {
    const {isDesktop} = useIsDesktop();
    const theme = usePatronTheme();
    return (
        <Form
            footer={
                <View flexDirection="row" justifyContent="center">
                    <SubmitButton color="primary" block={!isDesktop}>
                        <Box paddingX="large">
                            <Text style={{...boldFontStyles}} size="large" color="white">
                                Join now
                            </Text>
                        </Box>
                    </SubmitButton>
                </View>
            }
            onSubmit={onConfirm}
            initialValues={{confirmPrivacy: false}}
            validationSchema={{
                confirmPrivacy: boolean()
                    .required()
                    .oneOf([true], "Privacy policy must be checked"),
            }}
        >
            <View flex="1">
                <View padding="large" flex="1">
                    <Stack space="xlarge" align="center">
                        <Image
                            source={{
                                width: 100,
                                height: 100,
                                uri: community.institutions[0].squareLogo,
                            }}
                            style={{borderRadius: 6}}
                        />
                        <Stack space="medium">
                            <Heading level="1" size="xxsmall" weight="700" align="center">
                                {community.institutions[0].name}
                            </Heading>
                            <Text size="medium" align="center">
                                Welcome to {community.title}
                            </Text>
                        </Stack>
                    </Stack>
                </View>
                <View padding="medium">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom="medium"
                    >
                        <CheckBox name="confirmPrivacy" required>
                            I agree to the institution's{" "}
                            <Link
                                external
                                href={community.institutions[0].privacyPolicyUrl}
                                style={{
                                    textDecorationLine: "underline",
                                    textDecorationColor: theme?.colors?.textLinkColor,
                                }}
                            >
                                <TextLine color="textLinkColor">Privacy Policy</TextLine>
                            </Link>
                        </CheckBox>
                    </Box>
                </View>
            </View>
        </Form>
    );
};

interface TermsPromptProps {
    onJoin: () => void;
    linkInfo: InviteLinkInfoDto;
}

export const TermsPrompt = ({onJoin, linkInfo}: TermsPromptProps) => {
    return (
        <Confirm
            onConfirm={onJoin}
            community={{
                title: linkInfo.communityTitle,
                institutions: [
                    {
                        name: linkInfo.universityName,
                        squareLogo: linkInfo.squareLogo,
                        privacyPolicyUrl: linkInfo.privacyPolicyUrl,
                    },
                ],
            }}
        />
    );
};
