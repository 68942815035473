import React from "react";
import {Box} from "@unibuddy/patron";
import {Flag} from "ubcommunity-shared/src/Patron/Flag";

export const CountryFlag = ({
    label,
    code,
    size,
    isRound = true,
}: {
    label: string;
    code: string;
    size: number;
    isRound?: boolean;
}) => (
    <Box
        borderColor="grey"
        borderWidth={0.5}
        borderRadius={isRound ? "xxsmall" : "none"}
        overflow="hidden"
        marginRight="xsmall"
    >
        <Flag label={label} code={code} size={size} />
    </Box>
);
