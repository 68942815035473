import {Box, Heading, View} from "@unibuddy/patron";
import React, {FC} from "react";
import styled from "styled-components";
import {ChatMessage} from "ubcommunity-shared/src/types";
import {BlockModalForm} from "ubcommunity-shared/src/Chat/BlockModalForm";

interface Props {
    message: ChatMessage;
    conversationId: string;
    onDismiss: () => void;
    size?: string;
}

const BlockForm = styled(BlockModalForm)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const BlockModal: FC<Props> = ({onDismiss, message, conversationId}) => {
    return (
        <View height="100%" padding="medium" paddingTop="large">
            <Box paddingBottom="large">
                <Heading level="1" size="xsmall">
                    Block User
                </Heading>
            </Box>
            <BlockForm
                onSuccess={onDismiss}
                onDismiss={onDismiss}
                message={message}
                conversationId={conversationId}
            />
        </View>
    );
};
