import {useState} from "react";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";
import {useApolloClient, gql} from "@apollo/client";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import UploadService from "ubcommunity-shared/src/Utils/UploadService/UploadService";
import {useSetUserProfileMutation} from "ubcommunity-shared/src/types";

// this hook exposes the business logic (mutation / upload service)
// for the profile photo component (for now)
export const useProfilePhoto = () => {
    const {user} = useAuth();

    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    const client = useApolloClient();
    const uploadService = new UploadService(client);

    const [uploading, setUploading] = useState(false);

    const [setUserProfile] = useSetUserProfileMutation();

    const updateCache = (url: string | null) => {
        client.writeFragment({
            id: `UserField:${user.id}`,
            fragment: gql`
                fragment CommunityProfile on UserField {
                    id
                    communityProfile {
                        profilePhoto
                    }
                }
            `,
            data: {
                communityProfile: {
                    profilePhoto: url,
                },
            },
        });
    };

    const onUpload = async (image) => {
        setUploading(true);
        try {
            const {fileName, mediaUrl} = await uploadService.uploadFileSafe(image);
            const url = mediaUrl + fileName;
            await setUserProfile({
                variables: {
                    data: {
                        profilePhoto: url,
                    },
                },
            });
            updateCache(url);
        } catch (err) {
            reportError(err);
        } finally {
            trackEvent("Community Profile: Profile image uploaded");
            setUploading(false);
        }
    };

    const onRemove = async () => {
        setUploading(true);
        try {
            await setUserProfile({
                variables: {
                    data: {
                        profilePhoto: null,
                    },
                },
            });
            updateCache(null);
        } catch (err) {
            reportError(err);
        } finally {
            trackEvent("Community Profile: Profile image removed");
            setUploading(false);
        }
    };

    return {user, loading: uploading, onUpload, onRemove};
};
