import React from "react";
import {Pressable} from "react-native";

import {View, Text, usePatronTheme} from "@unibuddy/patron";

import CheckIcon from "ubcommunity-shared/src/Icons/CheckIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import CircleIcon from "ubcommunity-shared/src/Icons/CircleIcon";

type CardButtonProps = {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    onClick: () => void;
    active: boolean;
    accessibilityLabel: string;
};

export const CardButton = ({
    icon,
    title,
    subtitle,
    onClick,
    active,
    accessibilityLabel,
}: CardButtonProps) => {
    const theme = usePatronTheme();

    return (
        <Pressable
            onPress={onClick}
            accessibilityLabel={accessibilityLabel}
            accessibilityRole="button"
        >
            <View
                paddingX="xsmall"
                alignItems="center"
                borderColor={active ? "textLinkColor" : "darkGrey"}
                borderWidth={active ? 2 : 1}
                borderRadius="small"
                position="relative"
                paddingTop="small"
            >
                <View paddingBottom="small">{icon}</View>

                <View
                    position="absolute"
                    right={15}
                    top={15}
                    color={active ? "textLinkColor" : null}
                >
                    {active ? (
                        <CheckIcon color={theme.colors.textLinkColor} size={24} />
                    ) : (
                        <CircleIcon />
                    )}
                </View>

                <Text size="medium" style={{...boldFontStyles}}>
                    {title}
                </Text>

                <View paddingY="small" paddingBottom="medium">
                    <Text size="medium">{subtitle}</Text>
                </View>
            </View>
        </Pressable>
    );
};
