import {Column, Columns, Heading} from "@unibuddy/patron";
import {CreateGroupButton} from "ubcommunity-shared/src/Community/CreateGroup/CreateGroupButton";
import {PrivateGroupsFFWrapper} from "ubcommunity-shared/src/FeatureFlags/Wrappers/PrivateGroupsFFWrapper";

export const ExploreGroupsListHeader = ({handleCreate}: {handleCreate: () => void}) => {
    return (
        <Columns verticalAlign="center">
            <Column>
                <Heading level="2" size="xxsmall" weight="bold" color="grey900">
                    Explore group chats
                </Heading>
            </Column>
            <PrivateGroupsFFWrapper>
                <Column width="content">
                    <CreateGroupButton title="Create group" handleCreate={handleCreate} />
                </Column>
            </PrivateGroupsFFWrapper>
        </Columns>
    );
};
