import styled from "styled-components";
import {View} from "@unibuddy/patron";

export const FieldErrorContainer = styled(View)`
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({theme}) => theme?.FormFieldError?.backgroundColor};
`;

export const FieldErrorContent = styled.span`
    color: ${({theme}) => theme?.FormFieldError?.color};
    font-size: 14px;
    font-family: ${({theme}) => theme?.TextBlock?.fontFamily};
`;
