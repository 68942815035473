import {PropsWithChildren} from "react";
import * as yup from "yup";
import {Form, Stack, Text, View} from "@unibuddy/patron";

import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";

import {QuestionProps} from "./types";
import {CTAWrapper} from "../../General/CTAWrapper";
import {CTAFooter} from "../../General/CTAFooter";
import {QUESTION_ONE_FIELD_NAME} from "../StudentEnrolledQuestionsCTA";
import {Rating} from "../../StudentConfidenceSurvey/Rating/Rating";

export const SurveyQuestionLabel = ({children}: PropsWithChildren) => (
    <View paddingBottom="medium">
        <Text style={{...boldFontStyles}} size="large" color="grey900">
            {children}
        </Text>
    </View>
);

/**
 * ENROLMENT SURVEY
 * Question (1 of 2):
 * Have you started at {UniversityName}?
 */
export const Question1 = ({data, error, handleSubmit, handleDismiss}: QuestionProps) => {
    const {university} = useCommunity();
    return (
        <Form
            initialValues={{hasEnrolled: null}}
            validationSchema={{
                [QUESTION_ONE_FIELD_NAME]: yup
                    .string()
                    .nullable()
                    .required("Please select an option"),
            }}
            onSubmit={handleSubmit}
        >
            {({values}: {values: {hasEnrolled: string}}) => (
                <CTAWrapper
                    tagName="Survey"
                    handleDismiss={handleDismiss}
                    footer={
                        <CTAFooter
                            footerText="Question 1 of 2"
                            buttonText="Next"
                            isDisabled={values[QUESTION_ONE_FIELD_NAME] === null}
                        />
                    }
                >
                    <Stack space="medium">
                        <Rating
                            name={QUESTION_ONE_FIELD_NAME}
                            options={data.options}
                            label={
                                <SurveyQuestionLabel>
                                    {data?.text?.replace("{UniversityName}", university.name)}
                                </SurveyQuestionLabel>
                            }
                        />
                        {error ? (
                            <MutationErrorHandler
                                error={error}
                                message={`Oh no! Something went wrong. Please try again later.`}
                                meta={{
                                    component: "StudentEnrolledSurveyCTA.Q1",
                                    mutation: "useSaveUniversityQuestionAnswerMutation",
                                }}
                            />
                        ) : null}
                    </Stack>
                </CTAWrapper>
            )}
        </Form>
    );
};
