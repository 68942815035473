import {Text, View} from "@unibuddy/patron";
import React, {memo} from "react";
import {useIsTyping} from "./useIsTyping";

type Props = ReturnType<typeof useIsTyping>;

export const IsTyping = memo(({isTyping, typingMessage}: Props) => {
    return (
        <View
            as="section"
            aria-live="polite"
            aria-hidden={!isTyping}
            opacity={isTyping ? 1 : 0}
            pointerEvents="none"
            transition=".3s ease all"
            marginBottom={4}
        >
            <Text size="xsmall">{typingMessage}</Text>
        </View>
    );
});
