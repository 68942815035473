import React, {FC} from "react";
import {string} from "yup";
import {ScrollView} from "react-native";
import {
    Stack,
    Button,
    Text,
    TextArea,
    Form,
    FormGroup,
    Box,
    View,
    Columns,
    Column,
    usePatronTheme,
} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {ChatMessage, useCreateChatReportMutation, Product} from "ubcommunity-shared/src/types";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton";
import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";
import {RadioButtonFormGroup} from "../Patron/RadioButtonFormGroup";
import {RadioButtonGroupItem} from "../Patron/RadioButtonGroupItem";
import InfoIcon from "../Icons/InfoIcon";
import {MutationErrorHandler} from "../General/Errors/MutationErrorHandler";

interface Props {
    message: ChatMessage;
    conversationId: string;
    onDismiss: () => void;
    onSuccess: () => void;
}

export const validationSchema = {
    reporterReason: string().required("Why are you reporting this message is a required field"),
};

export const ReportMessageForm: FC<Props> = ({
    message,
    conversationId,
    onDismiss,
    onSuccess,
    ...props
}) => {
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    const theme = usePatronTheme();
    const {university} = useCommunity();

    const [reportMessage, {error}] = useCreateChatReportMutation();
    const handleSubmit = async ({reporterComment, reporterReason}, {setSubmitting}) => {
        try {
            await reportMessage({
                variables: {
                    chatReportUserInputDTO: {
                        conversationId,
                        messageId: message.id,
                        reporteeId: message.sender.id,
                        universityId: university.id,
                        reporterComment,
                        reporterReason,
                        product: Product.Community,
                    },
                },
            });

            trackEvent("Moderation: Message Reported", {
                conversationId,
                messageId: message.id,
                userId: message.sender.id,
            });

            onSuccess();
        } catch (error) {
            console.log(error);
            reportError(error);
        } finally {
            setSubmitting(false);
        }
    };

    const getReportedContent = (message) => {
        if (message.text) {
            return <Text>{message.text}</Text>;
        }

        const imageLength = message?.richContent?.images?.length;
        if (imageLength) {
            return (
                <Text fontStyle="italic">{`${
                    imageLength === 1 ? "an image" : `${imageLength} images`
                }`}</Text>
            );
        }
        if (message?.richContent?.giphy) {
            return <Text fontStyle="italic">a gif</Text>;
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{reporterComment: "", reporterReason: ""}}
            validationSchema={validationSchema}
            {...props}
        >
            <ScrollView>
                <View padding="medium">
                    <Stack space="large">
                        <Text>
                            We're sorry to hear you've had a bad experience in Community. We will
                            contact the institution's admin so that they can take further action if
                            required.
                        </Text>
                        <Stack space="small">
                            <Text>You are reporting the following message:</Text>
                            <View width="100%" padding="small" borderWidth={1} borderColor="grey">
                                {getReportedContent(message)}
                            </View>
                        </Stack>

                        <RadioButtonFormGroup
                            label="Why are you reporting this message?"
                            name="reporterReason"
                        >
                            <RadioButtonGroupItem value="Inappropriate / Unpleasant">
                                Inappropriate / Unpleasant
                            </RadioButtonGroupItem>
                            <RadioButtonGroupItem value="Abusive or threatening">
                                Abusive or threatening
                            </RadioButtonGroupItem>
                            <RadioButtonGroupItem value="Spam">Spam</RadioButtonGroupItem>
                            <RadioButtonGroupItem value="Other">Other</RadioButtonGroupItem>
                        </RadioButtonFormGroup>

                        <View
                            width="100%"
                            bgColor="#ECE9FF"
                            padding="medium"
                            borderRadius="xxsmall"
                        >
                            <Columns space="small">
                                <Column width="content">
                                    <InfoIcon
                                        color={theme?.colors.primaryColor}
                                        height={20}
                                        width={20}
                                    />
                                </Column>
                                <Column>
                                    <Text color="primaryColor">
                                        Your report will not be visible to the sender of this
                                        message. Only your Community Admin will have visibility of
                                        the report.
                                    </Text>
                                </Column>
                            </Columns>
                        </View>

                        <FormGroup label="Additional Comments">
                            <TextArea name="reporterComment" />
                        </FormGroup>
                    </Stack>
                    <Box paddingTop="large">
                        <Stack space="medium">
                            {error ? (
                                <MutationErrorHandler
                                    error={error}
                                    meta={{
                                        component: "ReportMessageForm",
                                        mutation: "useCreateChatReportMutation",
                                    }}
                                />
                            ) : null}
                            <SubmitButton color="primary" block>
                                Report Message
                            </SubmitButton>
                            <Button color="light" block onClick={onDismiss}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </View>
            </ScrollView>
        </Form>
    );
};
