import {Svg, Path, G, Defs, Rect, ClipPath} from "react-native-svg";

type BalloonsSvgProps = {
    width?: number;
    height?: number;
};

export const BalloonsSvg = ({width, height}: BalloonsSvgProps) => {
    return (
        <Svg
            width={width ?? 110}
            height={height ?? 149}
            viewBox="0 0 110 149"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <G clip-path="url(#clip0_1607_18055)">
                <Path
                    d="M9.45666 72.6782L0.5 91.9873L41.9258 110.631L49.9252 89.8615L9.45666 72.6782Z"
                    fill="#131622"
                />
                <Path
                    d="M90.1827 72.6782L99.2115 91.7575L58.2956 110.631L49.9258 89.8615L90.1827 72.6782Z"
                    fill="#131622"
                />
                <Path
                    d="M49.9251 149C49.8626 149 49.7952 148.986 49.7375 148.962L9.78366 131.994C9.60568 131.917 9.49023 131.745 9.49023 131.553V96.2484C9.49023 95.9851 9.7067 95.7697 9.97126 95.7697C10.2358 95.7697 10.4523 95.9851 10.4523 96.2484V131.237L49.4441 147.798V61.8723C49.4441 61.7239 49.5162 61.5802 49.6365 61.4893C49.7567 61.3983 49.9107 61.3696 50.055 61.4079L89.826 72.4245C90.081 72.4964 90.2301 72.7597 90.1627 73.0134C90.0906 73.2672 89.826 73.4156 89.5711 73.3486L50.4109 62.5043V148.526C50.4109 148.689 50.3292 148.837 50.1945 148.923C50.1127 148.976 50.0213 149.005 49.9299 149.005L49.9251 149Z"
                    fill="#131622"
                />
                <Path
                    d="M49.9244 149C49.7368 149 49.5588 148.89 49.4819 148.708C49.3808 148.464 49.4963 148.186 49.7416 148.081L89.3491 131.592V96.0856C89.3491 95.8223 89.5656 95.6068 89.8301 95.6068C90.0947 95.6068 90.3112 95.8223 90.3112 96.0856V131.912C90.3112 132.104 90.1957 132.281 90.0177 132.353L50.112 148.966C50.0495 148.99 49.9869 149.005 49.9244 149.005V149Z"
                    fill="#131622"
                />
                <Path
                    d="M9.94249 73.3629C9.73084 73.3629 9.53843 73.2241 9.48071 73.0134C9.40855 72.7597 9.56248 72.4963 9.81742 72.4245L49.8001 61.4079C50.0551 61.3409 50.3196 61.4845 50.3918 61.743C50.4639 61.9968 50.31 62.2601 50.0551 62.3319L10.0724 73.3486C10.0291 73.3581 9.98578 73.3677 9.94249 73.3677V73.3629Z"
                    fill="#131622"
                />
                <Path
                    d="M18.4429 56.7925C18.5295 57.2665 18.8806 58.6741 20.0543 59.3492C20.165 59.4114 20.1842 59.5598 20.0976 59.6556L18.1158 61.7191C18.0581 61.7766 17.9715 61.7957 17.8993 61.767C17.3317 61.5324 15.0565 60.3737 15.0469 56.8356C15.0469 56.7255 15.1383 56.6393 15.2441 56.6393H18.2505C18.3467 56.6393 18.4285 56.7063 18.4429 56.8021V56.7925Z"
                    fill="#131622"
                />
                <Path
                    d="M1.44776 21.4587C1.85663 21.7124 3.41034 22.5168 5.29114 21.4348C5.32481 21.4156 5.37291 21.4348 5.37772 21.4731C5.43545 21.8944 5.68077 23.8143 5.37291 24.925C5.37291 24.9394 5.35848 24.9538 5.34405 24.9633C5.0843 25.0926 2.62146 26.2513 0.601158 24.566C0.572296 24.542 0.572296 24.4894 0.605968 24.4702C0.85129 24.3218 1.61131 23.6754 1.35155 21.5209C1.34674 21.4731 1.39966 21.4395 1.43814 21.4635L1.44776 21.4587Z"
                    fill="#131622"
                />
                <Path
                    d="M36.7973 4.6202L39.2602 4.19888C39.3275 4.18931 39.39 4.22282 39.4093 4.28506C39.5873 4.84523 40.304 7.46414 38.5338 8.59405C38.5098 8.60841 38.4857 8.61799 38.4569 8.61799H36.831C36.7252 8.61799 36.6578 8.49829 36.7204 8.41211C37.11 7.8711 37.9806 6.3438 36.7107 4.83565C36.6482 4.75905 36.6915 4.63936 36.7925 4.62499L36.7973 4.6202Z"
                    fill="#131622"
                />
                <Path
                    d="M95.4499 25.265L96.4601 27.5439C96.4889 27.6062 96.4649 27.6732 96.4119 27.7067C95.9069 28.0179 93.5306 29.3441 92.001 27.9078C91.9817 27.8887 91.9673 27.8647 91.9625 27.8408L91.568 26.2704C91.544 26.1651 91.645 26.0741 91.746 26.1172C92.3713 26.3614 94.0694 26.8306 95.2286 25.241C95.2863 25.1596 95.4162 25.174 95.4547 25.2698L95.4499 25.265Z"
                    fill="#131622"
                />
                <Path
                    d="M93.8278 64.9843C93.3467 65.0514 91.9181 65.3339 91.1869 66.4638C91.1196 66.5691 90.9657 66.5835 90.8791 66.4925L88.9021 64.4242C88.8444 64.3667 88.8299 64.2805 88.8636 64.2039C89.1282 63.6534 90.3981 61.4462 93.948 61.5994C94.0587 61.5994 94.1404 61.6999 94.1356 61.8053L93.9961 64.7928C93.9961 64.8886 93.9192 64.9652 93.8278 64.9796V64.9843Z"
                    fill="#131622"
                />
                <Path
                    d="M109.485 47.0973C109.211 47.4133 108.312 48.6437 109.004 50.3961C109.019 50.4296 108.995 50.4679 108.956 50.4679C108.581 50.4679 106.888 50.42 105.969 50.013C105.954 50.0082 105.945 49.9987 105.94 49.9843C105.863 49.7449 105.18 47.4851 106.902 45.9722C106.926 45.9482 106.969 45.9578 106.984 45.9913C107.08 46.2211 107.542 46.9537 109.437 47.0159C109.48 47.0159 109.504 47.0686 109.476 47.0973H109.485Z"
                    fill="#131622"
                />
                <Path
                    d="M49.9162 91.3936L49.0936 90.9005C49.1177 90.8574 51.5805 86.3664 45.255 73.248C45.1733 73.0421 38.7035 55.9211 50.806 42.5919L51.518 43.2335C39.8147 56.1126 46.068 72.7022 46.1353 72.865C52.7301 86.534 50.0364 91.1973 49.9162 91.3936Z"
                    fill="#131622"
                />
                <Path
                    d="M50.3583 97.6465L49.4107 97.4837C49.4107 97.4837 49.7041 95.7027 49.0162 93.2082C48.3861 90.9101 46.765 87.5012 42.6523 84.3174C42.4743 84.1881 24.027 70.2892 28.2408 50.7743L29.1836 50.9754C25.0997 69.8823 43.0563 83.4173 43.2391 83.5561C51.5945 90.0244 50.416 97.3401 50.3631 97.6465H50.3583Z"
                    fill="#131622"
                />
                <Path
                    d="M51.1625 39.906L49.9551 41.1891C49.6617 41.462 49.8348 41.8355 50.1138 42.1658C50.5804 42.7164 51.2635 43.0468 51.9898 43.1042C52.5526 43.1473 53.1491 43.0516 53.2068 42.4866V40.4901"
                    fill="white"
                />
                <Path
                    d="M52.2063 43.5878C52.1245 43.5878 52.0379 43.5878 51.9465 43.5782C51.0855 43.516 50.2822 43.109 49.7386 42.4723C48.9738 41.5674 49.4548 40.9928 49.6184 40.8396L50.8017 39.5804L51.504 40.2364L50.2966 41.5195C50.2966 41.5195 50.2774 41.6344 50.4698 41.8594C50.845 42.2999 51.4078 42.5824 52.0138 42.6255C52.2688 42.6446 52.6969 42.6446 52.7161 42.434V40.4853H53.683V42.4818C53.6397 42.8553 53.4281 43.5878 52.2014 43.5878H52.2063Z"
                    fill="#131622"
                />
                <Path
                    d="M53.2131 40.4853C53.2131 40.4853 82.0745 36.3726 81.4781 14.5691C81.4781 14.5691 81.0307 0.478735 65.6812 0.478735C65.6812 0.478735 54.204 0.330315 49.1388 11.6055C49.1388 11.6055 44.3959 21.1427 51.164 39.9108L53.2131 40.4901V40.4853Z"
                    fill="white"
                />
                <Path
                    d="M53.1787 40.9785L50.7928 40.3034L50.711 40.0736C43.9574 21.3295 48.5127 11.7923 48.71 11.3949C53.8281 -0.00957575 65.5458 2.69136e-08 65.6853 2.69136e-08C81.3138 2.69136e-08 81.9535 14.4112 81.9583 14.5548C82.2085 23.6659 77.3068 31.1253 67.7922 36.1285C60.6586 39.8773 53.3518 40.9497 53.2797 40.9593L53.1787 40.9737V40.9785ZM51.5336 39.5182L53.2412 40.0018C54.1311 39.8629 60.8221 38.7186 67.3641 35.2715C76.6526 30.3832 81.2368 23.4217 80.9963 14.5835C80.977 14.0281 80.371 0.957553 65.6805 0.957553H65.6709C65.3245 0.957553 54.3957 1.07246 49.5758 11.7971C49.5229 11.9072 45.0494 21.3439 51.5288 39.5134L51.5336 39.5182Z"
                    fill="#131622"
                />
                <Path
                    d="M28.6199 49.0555L28.288 50.104C28.1966 50.3386 28.3987 50.5014 28.644 50.6163C29.0577 50.803 29.5387 50.8078 29.962 50.6402C30.2939 50.5109 30.6018 50.2955 30.4815 49.9604L29.9428 48.8304"
                    fill="white"
                />
                <Path
                    d="M29.323 51.2387C29.02 51.2387 28.7169 51.1764 28.4427 51.052C27.7934 50.7599 27.702 50.2811 27.8366 49.9316L28.1589 48.907L29.0777 49.1943L28.7746 50.1423C28.7746 50.1423 28.8131 50.1614 28.8372 50.171C29.1258 50.3003 29.4673 50.3099 29.78 50.1854C29.9002 50.1375 29.9676 50.0992 30.0013 50.0705L29.501 49.0267L30.3716 48.615L30.9104 49.7449C31.0018 49.9891 31.1557 50.6785 30.1311 51.0759C29.8714 51.1764 29.5924 51.2291 29.323 51.2291V51.2387ZM28.7458 50.2428V50.2524C28.7458 50.2524 28.7458 50.2524 28.7458 50.2428Z"
                    fill="#131622"
                />
                <Path
                    d="M29.94 48.8304C29.94 48.8304 45.1596 38.7139 38.8822 26.529C38.8822 26.529 34.7887 18.6723 26.1014 22.8137C26.1014 22.8137 19.5643 25.8252 19.7663 33.5766C19.7663 33.5766 19.6797 40.2555 28.622 49.0554L29.94 48.8304Z"
                    fill="white"
                />
                <Path
                    d="M28.458 49.5725L28.28 49.4002C19.2945 40.5572 19.28 33.8591 19.28 33.5766C19.0732 25.6241 25.8268 22.4163 25.8941 22.3876C33.0951 18.9548 37.8139 23.4553 39.3051 26.3184C45.7027 38.7378 30.358 49.1321 30.2041 49.2374L30.1175 49.2949L28.4532 49.5773L28.458 49.5725ZM30.9593 22.0908C29.6028 22.0908 28.0539 22.4211 26.3078 23.2542C26.0481 23.3739 20.0545 26.2418 20.2469 33.5718C20.2469 33.6532 20.2998 40.1119 28.7851 48.548L29.7519 48.3852C30.9304 47.5665 44.2259 37.9622 38.4537 26.7588C38.3286 26.5242 35.9379 22.0956 30.9593 22.0956V22.0908Z"
                    fill="#131622"
                />
                <Path
                    d="M66.6061 72.9799L65.7354 74.1529C65.519 74.4066 65.697 74.6987 65.9615 74.9428C66.3993 75.3546 66.9957 75.5605 67.597 75.5365C68.0684 75.5174 68.5494 75.3833 68.5398 74.9093L68.3522 73.2623"
                    fill="white"
                />
                <Path
                    d="M67.5017 76.0153C66.8042 76.0153 66.1259 75.7568 65.6305 75.2876C64.8609 74.5646 65.2361 73.9949 65.3707 73.8417L66.2222 72.6926L66.9966 73.2624L66.1404 74.4162C66.1596 74.4497 66.2029 74.512 66.2943 74.5934C66.631 74.9094 67.0928 75.0769 67.5835 75.0578C67.9779 75.0434 68.0645 74.9429 68.0645 74.9429L67.8817 73.315L68.8389 73.2097L69.0313 74.9046C69.0313 75.1535 68.9448 75.9627 67.6267 76.0153C67.5883 76.0153 67.5498 76.0153 67.5113 76.0153H67.5017Z"
                    fill="#131622"
                />
                <Path
                    d="M68.3541 73.2576C68.3541 73.2576 91.7799 67.1005 89.1824 49.156C89.1824 49.156 87.4507 37.5696 74.7805 39.0394C74.7805 39.0394 65.2948 40.0161 62.2018 49.8071C62.2018 49.8071 59.2098 58.133 66.6079 72.9751L68.3541 73.2576Z"
                    fill="white"
                />
                <Path
                    d="M68.3781 73.7508L66.2857 73.4108L66.175 73.1906C58.7721 58.3342 61.6197 49.9939 61.7448 49.6444C64.8907 39.6858 74.6314 38.5751 74.7276 38.5607C87.696 37.0525 89.6346 48.9645 89.6538 49.0842C92.285 67.2394 68.7148 73.655 68.4743 73.7172L68.3733 73.7412L68.3781 73.7508ZM66.9302 72.549L68.33 72.7741C69.9463 72.3144 91.1305 65.9659 88.7062 49.2326C88.6388 48.7826 86.8879 38.125 74.8383 39.523C74.7421 39.5326 65.6218 40.5955 62.6636 49.9556C62.6299 50.0561 59.8688 58.2336 66.935 72.549H66.9302Z"
                    fill="#131622"
                />
                <Path
                    d="M50.3644 97.565H49.4023L49.4504 89.1051L50.1335 89.4258C55.1602 91.8006 61.4087 85.0546 64.3045 80.693C65.1751 79.3859 65.5599 78.0405 65.9303 76.7335C66.065 76.2595 66.1949 75.8142 66.344 75.3785L67.2531 75.685C67.1136 76.1015 66.9886 76.5372 66.8539 76.9968C66.4787 78.3039 66.0554 79.7881 65.103 81.2196C64.2852 82.4501 57.1372 92.8395 50.4029 90.5653L50.3644 97.5603V97.565Z"
                    fill="#131622"
                />
                <Path
                    d="M75.9249 26.4571C75.8479 26.4571 75.7661 26.438 75.694 26.3997C75.4631 26.2704 75.3765 25.9832 75.5016 25.7486C83.0537 11.9981 73.3514 5.41971 73.2504 5.35747C73.0291 5.21384 72.9666 4.917 73.1109 4.69676C73.2552 4.47652 73.5534 4.41428 73.7747 4.55791C73.8805 4.62973 84.3139 11.6917 76.3386 26.2082C76.252 26.3662 76.0836 26.4571 75.9153 26.4571H75.9249Z"
                    fill="#131622"
                />
                <Path
                    d="M83.6366 61.2451C83.5596 61.2451 83.4778 61.226 83.4057 61.1877C83.1748 61.0584 83.0882 60.7711 83.2133 60.5365C85.9407 55.5764 86.4602 50.9371 84.7141 47.1164C83.4057 44.2486 81.2892 42.8409 81.2699 42.8266C81.0487 42.683 80.9861 42.3861 81.1304 42.1659C81.2747 41.9456 81.573 41.8834 81.7942 42.027C81.8856 42.0893 91.0347 48.2846 84.055 60.9962C83.9685 61.1542 83.8001 61.2451 83.6317 61.2451H83.6366Z"
                    fill="#131622"
                />
                <Path
                    d="M35.0425 39.9108C34.9559 39.9108 34.8693 39.8868 34.7876 39.8389C34.5615 39.7001 34.4941 39.4033 34.6336 39.1782C36.6347 35.9944 37.3129 33.0499 36.6539 30.4358C35.7977 27.0173 32.926 25.3416 32.8971 25.3224C32.6662 25.1884 32.5893 24.8963 32.7192 24.6665C32.849 24.4367 33.1473 24.3601 33.3782 24.4894C33.5128 24.566 36.6347 26.3949 37.5871 30.2012C38.3087 33.0834 37.5871 36.2721 35.4466 39.6857C35.3552 39.8294 35.1964 39.9108 35.0377 39.9108H35.0425Z"
                    fill="#131622"
                />
                <Path
                    d="M54.6161 52.4069L52.1484 52.7947C52.0811 52.8042 52.0185 52.7659 51.9993 52.7037C51.8261 52.1387 51.1479 49.5103 52.9325 48.4043C52.9565 48.3899 52.9806 48.3851 53.0095 48.3851L54.6353 48.4091C54.7411 48.4091 54.8037 48.5336 54.7411 48.6197C54.3419 49.156 53.452 50.6737 54.7027 52.1962C54.7652 52.2728 54.7171 52.3925 54.6209 52.4069H54.6161Z"
                    fill="#131622"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1607_18055">
                    <Rect width="109" height="149" fill="white" transform="translate(0.5)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};
