import React from "react";
import {Box, Text, View} from "@unibuddy/patron";

import {progressionBarStyle} from "./AvatarWithCircularProgressionBar";
import {CircularProgressionBar} from "./CircularProgressionBar";

type CircularProgressionBarWithCounterProps = {
    progressPercentage: number;
    size: number;
    progressionBarStyle: progressionBarStyle;
};

export function CircularProgressionBarWithCounter({
    progressPercentage,
    size,
    progressionBarStyle,
}: CircularProgressionBarWithCounterProps) {
    const SIZE = size + 18;

    return (
        <Box position="absolute" zIndex={200}>
            <Box
                position="absolute"
                backgroundColor={progressionBarStyle.activeLineColor}
                padding="xsmall"
                borderRadius="large"
                bottom={-18}
                left={SIZE / 2 - 20}
                zIndex={100}
            >
                <Text size="small" color="#5C02D4" weight="bold">
                    {progressPercentage}%
                </Text>
            </Box>
            <View
                position="relative"
                alignItems="center"
                justifyContent="center"
                height={SIZE}
                width={SIZE}
            >
                <CircularProgressionBar
                    progressPercentage={progressPercentage}
                    progressionBarStyle={progressionBarStyle}
                    size={SIZE}
                />
            </View>
        </Box>
    );
}
