import React from "react";

import {View} from "@unibuddy/patron";

import {MatchesList} from "ubcommunity-shared/src/Community/MatchesList/MatchesList";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {Routes} from "ubcommunity-shared/src/constants/routes";

import {useLocationQuery} from "ubcommunity-shared/src/Hooks/useLocationQuery";

export const DesktopMatchesListPage = () => {
    const {closeUserSummary} = useOpenUserSummary({});
    const [navigate] = useNavigate();
    const query = useLocationQuery();
    const filter = query.get("filter");

    const handleBack = () => {
        closeUserSummary();
        navigate({path: Routes.EXPLORE});
    };

    return (
        <View flex="1" alignItems="center">
            <View
                width="50%"
                height="100%"
                style={{alignSelf: "center"}}
                padding="medium"
                paddingTop="large"
            >
                <BackButton callback={handleBack} />
                <View paddingTop="medium" overflow="auto" flex="1">
                    <MatchesList filter={filter} />
                </View>
            </View>
        </View>
    );
};
