import React, {useRef, useState} from "react";
import {
    Box,
    Button,
    Collapsible,
    Column,
    Columns,
    Dialog,
    Heading,
    Logo,
    Stack,
    Switch,
    Text,
} from "@unibuddy/patron";
import {v4 as uuidv4} from "uuid";
import {useSaveCookieConsentPreferencesMutation} from "ubcommunity-shared/src/types";

export function CookiesManager({
    onSelection,
    hasSelection,
}: {
    hasSelection?: boolean;
    onSelection: (selection: {analytics: boolean}, uniqueId: string) => void;
}) {
    const ref = useRef<HTMLButtonElement>(null);
    const [analyticsSelected, setAnalyticsSelected] = useState(false);
    const [managed, setManaged] = useState(false);
    const [savePreferences] = useSaveCookieConsentPreferencesMutation();

    const onUserSelection = async (input) => {
        const uniqueId: string = uuidv4();

        const payload = {
            uniqueId,
            preferences: {analyticsCookies: input.analytics as boolean},
        };

        try {
            savePreferences({
                variables: {input: payload},
            });
            onSelection(input, uniqueId);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Dialog
            isOpen={!hasSelection}
            verticalAlign="center"
            label="Cookie Preferences"
            initialFocusRef={ref}
        >
            <Box paddingY="medium" paddingX="large">
                <Stack space="medium" dividers>
                    <Stack space="medium">
                        <Box display="flex" justifyContent="center" paddingBottom="small">
                            <Logo />
                        </Box>

                        <Heading level="1" size="small">
                            Cookie Preferences
                        </Heading>

                        <Text size="small">
                            When you visit any website, it may store or retrieve information on your
                            browser, mostly in the form of cookies. This information might be about
                            you, your preferences or your device and is mostly used to make the site
                            work as you expect it to. The information does not usually directly
                            identify you, but it can give you a more personalized web experience.
                            Because we respect your right to privacy, you can choose not to allow
                            some types of cookies.
                        </Text>

                        {managed ? (
                            <Stack space="medium">
                                <Stack dividers>
                                    <Collapsible trigger="Strictly Necessary Cookies">
                                        <Columns space="large">
                                            <Column>
                                                <Text size="small">
                                                    These cookies are necessary for the website to
                                                    function and cannot be switched off in our
                                                    systems. They are usually only set in response
                                                    to actions made by you which amount to a request
                                                    for services, such as setting your privacy
                                                    preferences, logging in or filling in forms. You
                                                    can set your browser to block or alert you about
                                                    these cookies, but some parts of the site will
                                                    not then work. These cookies do not store any
                                                    personally identifiable information.
                                                </Text>
                                            </Column>
                                            <Column width="content">
                                                <Switch checked disabled />
                                            </Column>
                                        </Columns>
                                    </Collapsible>
                                    <Collapsible trigger="Analytics Cookies">
                                        <Columns space="large">
                                            <Column>
                                                <Text id="analytics-description" size="small">
                                                    Unibuddy Analytics Cookies
                                                </Text>
                                            </Column>
                                            <Column width="content">
                                                <Switch
                                                    aria-labelledby="analytics-description"
                                                    checked={analyticsSelected}
                                                    onChange={() => {
                                                        setAnalyticsSelected(!analyticsSelected);
                                                    }}
                                                />
                                            </Column>
                                        </Columns>
                                    </Collapsible>
                                </Stack>

                                <Text size="small">
                                    Click on the different category headings to find out more and
                                    change our default settings. However, blocking some types of
                                    cookies may impact your experience of the site and the services
                                    we are able to offer. More information
                                </Text>
                            </Stack>
                        ) : null}
                    </Stack>

                    <Columns space="medium" collapseBelow="tablet">
                        <Column>
                            <Button
                                size="sm"
                                color="primary"
                                ghost
                                block
                                round
                                onClick={() => {
                                    if (managed) {
                                        onUserSelection({analytics: true});
                                    } else {
                                        setManaged(true);
                                    }
                                }}
                            >
                                {managed ? "Consent to all" : "Manage options"}
                            </Button>
                        </Column>
                        <Column>
                            <Button
                                ref={ref}
                                size="sm"
                                color="primary"
                                block
                                round
                                onClick={() => {
                                    if (!managed) {
                                        onUserSelection({analytics: true});
                                    } else {
                                        onUserSelection({analytics: analyticsSelected});
                                    }
                                }}
                            >
                                {!managed ? "Consent to all" : "Confirm choices"}
                            </Button>
                        </Column>
                    </Columns>
                </Stack>
            </Box>
        </Dialog>
    );
}
