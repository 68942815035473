import React, {useState} from "react";
import {Image, Platform} from "react-native";
import {Text, View, Heading, Stack, Button} from "@unibuddy/patron";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {DefaultPopupWrapper} from "./DefaultPopupWrapper";

const isWeb = Platform.OS === "web";

const ImageWrapper = ({
    imageSize,
    image,
    title,
    defaultHeight,
}: {
    imageSize: number;
    image: any;
    title: string;
    defaultHeight?: number;
}) => {
    const [imageHeight, setImageHeight] = useState(null);

    if (isWeb) {
        Image.getSize(image, (w: number, h: number) => {
            if (!imageHeight) {
                const height = Platform.OS === "web" ? {height: (imageSize * h) / w} : null;
                setImageHeight(height);
            }
        });
    } else if (defaultHeight && !imageHeight) {
        setImageHeight({height: defaultHeight});
    }

    return (
        <View alignItems="center" borderColor="red" flex={1}>
            <Image
                style={{width: imageSize, ...imageHeight}}
                aria-label={`popup central image of ${title}`}
                accessibilityLabel={`popup central image of ${title}`}
                source={image}
                resizeMode="contain"
            />
        </View>
    );
};

const IMAGE_SIZE = 280;

export enum IMAGE_POSITION {
    top = "top",
    center = "center",
    bottom = "bottom",
}

type imagePositionType = keyof typeof IMAGE_POSITION;

type DefaultPopupProps = {
    title: string;
    text: string;
    buttonText: string;
    image: any;
    isContextMenuOpen: boolean;
    onClick: () => void;
    setIsContextMenuOpen: (p: boolean) => void;
    imageSize?: number;
    imagePosition?: imagePositionType;
    defaultHeight?: number;
};

/**
 * @author
 * @function @defaultPopup
 **/
export const DefaultPopup = ({
    title,
    text,
    buttonText,
    onClick,
    image,
    imageSize = IMAGE_SIZE,
    isContextMenuOpen,
    setIsContextMenuOpen,
    imagePosition = IMAGE_POSITION.bottom,
    defaultHeight,
}: DefaultPopupProps) => (
    <DefaultPopupWrapper
        isContextMenuOpen={isContextMenuOpen}
        setIsContextMenuOpen={setIsContextMenuOpen}
    >
        <View padding="large" marginTop="large">
            <Stack space="large">
                {imagePosition === IMAGE_POSITION.top ? (
                    <ImageWrapper
                        imageSize={imageSize}
                        defaultHeight={defaultHeight}
                        image={image}
                        title={title}
                    />
                ) : null}
                <Heading level="4" align="center">
                    {title}
                </Heading>
                {imagePosition === IMAGE_POSITION.center ? (
                    <ImageWrapper
                        imageSize={imageSize}
                        defaultHeight={defaultHeight}
                        image={image}
                        title={title}
                    />
                ) : null}
                <Text size="large" align="center">
                    {text}
                </Text>
                {imagePosition === IMAGE_POSITION.bottom ? (
                    <ImageWrapper
                        imageSize={imageSize}
                        defaultHeight={defaultHeight}
                        image={image}
                        title={title}
                    />
                ) : null}

                <Button block onClick={onClick} color="primary">
                    <Text size="large" style={{...boldFontStyles}} color="white">
                        {buttonText}
                    </Text>
                </Button>
            </Stack>
        </View>
    </DefaultPopupWrapper>
);
