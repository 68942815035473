import {UserAccountRoles} from "../constants";

export enum UniversalInboxUserRole {
    Ambassador = "Student",
    Admin = "Admin",
    Staff = "Staff",
}

export const getUniversalInboxUserRoleTag = (
    accountRole: UserAccountRoles | string,
): UniversalInboxUserRole | undefined => {
    let tag: UniversalInboxUserRole | undefined;
    if (accountRole === UserAccountRoles.MENTOR) {
        tag = UniversalInboxUserRole.Ambassador;
    } else if (accountRole === UserAccountRoles.STAFF) {
        tag = UniversalInboxUserRole.Staff;
    } else if (accountRole === UserAccountRoles.UNIVERSITY) {
        tag = UniversalInboxUserRole.Admin;
    }
    return tag;
};
