import {NetworkStatus} from "@apollo/client";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {Button, Divider, LoadingButton, Stack, Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import React from "react";
import {Image, Platform} from "react-native";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {triggerHaptic} from "ubcommunity-shared/src/General/Haptic/triggerHaptic";
import {useUpdateInboxQuery} from "ubcommunity-shared/src/Hooks/graphql/useUpdateInboxQuery";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {
    useGetJoinGroupDataQuery,
    useJoinCommunityConversationMutation,
} from "ubcommunity-shared/src/types";
import {parseFirstGQLError} from "ubcommunity-shared/src/Utils/errorHandlers";
import {SquareGrid} from "../../Svgs/SquareGrid";
import {Squares} from "../../Svgs/Squares";
import {useCommunity} from "../CommunityProvider/CommunityProvider";

const Wrapper = ({children, darkModeEnabled}) => {
    return (
        <View position="relative">
            <View position="absolute" style={{zIndex: 0}} bottom="-60px" left="-60px">
                <SquareGrid />
            </View>
            <View position="absolute" style={{zIndex: 0}} top="-50px" right="-20px">
                <Squares />
            </View>
            <View
                {...Platform.select({
                    web: {
                        backgroundColor: "white",
                    },
                    ios: {
                        borderRadius: "xsmall",
                        borderColor: darkModeEnabled ? "navbarColor" : "grey",
                        backgroundColor: darkModeEnabled ? "navbarColor" : "white",
                    },
                    android: {
                        borderRadius: "xsmall",
                        borderColor: darkModeEnabled ? "navbarColor" : "grey",
                        backgroundColor: darkModeEnabled ? "navbarColor" : "white",
                    },
                })}
                shadow="medium"
                maxW="360px"
                style={{zIndex: 1}}
            >
                {children}
            </View>
        </View>
    );
};

export const JoinGroupContainer = ({conversationId, communityId, onJoin, onDecline}) => {
    const {select} = useCommunity();
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();
    const {darkModeEnabled} = useCommunityTheme();

    const [
        join,
        {loading: loadingJoinCommunityConversation, error: errorJoinCommunityConversation},
    ] = useJoinCommunityConversationMutation();
    const {
        data: dataGetJoinGroup,
        loading: loadingGetJoinGroup,
        error: errorGetJoinGroup,
        networkStatus,
    } = useGetJoinGroupDataQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            communityId,
            conversationId,
        },
        onCompleted: (data) => {
            const community = data.getCommunityForUser;
            select(community);
            trackEvent("Group invite: Link opened", {
                communityId,
                conversationId,
            });
        },
        onError: (error) => {
            reportError(error);
        },
    });
    const {addConversationToInbox} = useUpdateInboxQuery();

    if (errorGetJoinGroup) {
        const errorObj = JSON.parse(JSON.stringify(errorGetJoinGroup));
        const {code} = parseFirstGQLError(errorObj.graphQLErrors);

        reportError(errorGetJoinGroup);

        if (code === "FORBIDDEN")
            return (
                <Wrapper darkModeEnabled={darkModeEnabled}>
                    <View padding="medium">
                        <Stack space="medium">
                            <Text
                                size="large"
                                align="center"
                                style={{
                                    ...boldFontStyles,
                                }}
                            >
                                Oops !
                            </Text>
                            <Text align="center">
                                It looks like you have not been invited to this community yet.
                                Please get in touch with a university admin who can invite you or
                                share a link with you so that you can join this community.
                            </Text>
                        </Stack>
                    </View>
                </Wrapper>
            );

        return (
            <Wrapper darkModeEnabled={darkModeEnabled}>
                <View padding="medium">
                    <Text color="red">
                        An error occurred. If the problem persists please contact support.
                    </Text>
                </View>
            </Wrapper>
        );
    }

    // the loading param is not reliable hence using both measures
    if (loadingGetJoinGroup || networkStatus === NetworkStatus.loading)
        return <ActivityIndicator loading={loadingGetJoinGroup} />;

    const community = dataGetJoinGroup.getCommunityForUser;
    const conversation = dataGetJoinGroup.getCommunityConversation;

    const onJoinGroup = async () => {
        try {
            const {data} = await join({
                variables: {
                    id: conversationId,
                },
            });

            if (data) {
                addConversationToInbox(data.joinCommunityConversation);
            }

            triggerHaptic("notificationSuccess");

            onJoin({
                id: conversation.id,
                title: conversation.communityChatTitle,
                description: conversation.communityChatDescription,
                isPrivate: conversation.isPrivate,
            });

            trackEvent("Group invite: Group selected", {
                communityId,
                conversationId,
            });
        } catch (err) {
            reportError(err);
        }
    };

    return (
        <Wrapper darkModeEnabled={darkModeEnabled}>
            <View padding="medium">
                <Stack space="medium">
                    <View w="100%" display="flex" justifyContent="center" alignItems="center">
                        <Image
                            style={{width: 70, height: 70, borderRadius: 6}}
                            source={{
                                uri: community.institutions[0]?.squareLogo,
                            }}
                            width={70}
                            height={70}
                        />
                    </View>

                    <View paddingBottom="small">
                        <Stack space="medium">
                            <Text align="center">
                                You have been invited to{" "}
                                {conversation.isPrivate ? "a private" : "a"} group
                            </Text>

                            <Divider />

                            <Text align="center" size="large" style={{...boldFontStyles}}>
                                {conversation.communityChatTitle}
                            </Text>

                            <Text align="center">{conversation.communityChatDescription}</Text>
                        </Stack>
                    </View>

                    <Stack space="small">
                        <LoadingButton
                            block
                            loading={loadingJoinCommunityConversation}
                            color="primary"
                            onClick={onJoinGroup}
                        >
                            <Text
                                color="white"
                                style={{
                                    ...boldFontStyles,
                                }}
                            >
                                Join Group
                            </Text>
                        </LoadingButton>
                        <Button color="primary" ghost block onClick={onDecline}>
                            <Text
                                color={darkModeEnabled ? "white" : "primaryColor"}
                                style={{
                                    ...boldFontStyles,
                                }}
                            >
                                Maybe Later
                            </Text>
                        </Button>
                    </Stack>
                    {errorJoinCommunityConversation ? (
                        <MutationErrorHandler
                            error={errorJoinCommunityConversation}
                            meta={{
                                component: "JoinGroupContainer",
                                mutation: "useJoinCommunityConversationMutation",
                            }}
                        />
                    ) : null}
                </Stack>
            </View>
        </Wrapper>
    );
};
