import {PropsWithChildren} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Inline, Stack, Text, Heading, Box, View, Button} from "@unibuddy/patron";
import RelativeSwitch from "ubcommunity-shared/src/Navigation/RelativeSwitch/RelativeSwitch";
import RelativeRoute from "ubcommunity-shared/src/Navigation/RelativeRoute/RelativeRoute";
import {AccountInformation} from "ubcommunity-shared/src/Settings/AccountInformation/AccountInformation";
import {SecurityAndPrivacy} from "ubcommunity-shared/src/Settings/SecurityAndPrivacy/SecurityAndPrivacy";
import {DeveloperOptions} from "ubcommunity-shared/src/Settings/DeveloperOptions/DeveloperOptions";
import SwitcherIcon from "ubcommunity-shared/src/Icons/SwitcherIcon";
import PrivacyIcon from "ubcommunity-shared/src/Icons/PrivacyIcon";
import SettingsIcon from "ubcommunity-shared/src/Icons/SettingsIcon";
import AccountInfoIcon from "ubcommunity-shared/src/Icons/AccountInfoIcon";
import {useLogout} from "ubcommunity-shared/src/Auth/hooks/useLogout";
import {Page, PageBody, PageHeader} from "../../Layout/Page/Page";
import {ProfileHero} from "ubcommunity-shared/src/Settings/ProfileHero/ProfileHero";
import {SettingsLink} from "ubcommunity-shared/src/Settings/SettingsLink/SettingsLink";
import {AccountInformationSource} from "ubcommunity-shared/src/nativeNavigationParamsList/SettingsStackParamList";
import {
    LabelProps,
    mapSettingsEvents,
} from "ubcommunity-shared/src/Settings/SettingsLink/SettingsLinkTracking";

import {BackButton} from "../../Layout/BackButton/BackButton";
import useRelativeRouter from "ubcommunity-shared/src/Navigation/useRelativeRouter/useRelativeRouter";
import {CookiesSettings} from "../CookiesSettings/CookiesSettings";
import {SettingsStudentAppWrapper} from "ubcommunity-shared/src/Settings/AccountInformation/SettingsStudentAppWrapper/SettingsStudentAppWrapper";

function SettingsPageLayout({title, children}: PropsWithChildren<{title: string}>) {
    return (
        <Page>
            <PageHeader>
                <Inline space="small" verticalAlign="center" wrap="nowrap">
                    <BackButton to="/settings" />
                    <Stack space="xsmall">
                        <Heading level="1" size="small" weight="400">
                            {title}
                        </Heading>
                        <Text color="primaryColor" size="xxsmall">
                            User Settings
                        </Text>
                    </Stack>
                </Inline>
            </PageHeader>
            <View flex="1" minH={0} overflow="hidden" position="relative" flexDirection="column">
                <View padding="medium" paddingY="large" overflow="auto">
                    {children}
                </View>
            </View>
        </Page>
    );
}

export function MobileSettingsPage({user, university, select}) {
    const {push} = useRelativeRouter();

    const onHeroPress = () => {
        push("/settings/account");
    };

    return (
        <RelativeSwitch>
            <RelativeRoute path="/settings/account">
                <SettingsPageLayout title="Your Profile">
                    <AccountInformation />
                </SettingsPageLayout>
            </RelativeRoute>
            <RelativeRoute path="/settings/notifications">
                <SettingsPageLayout title="Notifications" />
            </RelativeRoute>
            <RelativeRoute path="/settings/privacy">
                <SettingsPageLayout title="Security and Privacy">
                    <Stack space="xlarge">
                        <SecurityAndPrivacy university={university} />
                        <CookiesSettings />
                    </Stack>
                </SettingsPageLayout>
            </RelativeRoute>
            <RelativeRoute path="/settings/developer">
                <SettingsPageLayout title="Developer Options">
                    <DeveloperOptions />
                </SettingsPageLayout>
            </RelativeRoute>

            <RelativeRoute path="/settings/manage">
                <SettingsPageLayout title="Manage account">
                    <Stack space="large">
                        <Text>
                            Deleting your account will remove all of your data from Unibuddy’s
                            system, as well as removing any data related to your account that is
                            being stored with an Institution. This action is irreversible.
                        </Text>
                        <Text weight="700">This action is irreversible.</Text>
                        <Button block color="danger">
                            Delete my account
                        </Button>
                    </Stack>
                </SettingsPageLayout>
            </RelativeRoute>

            <RelativeRoute>
                <Page>
                    <PageHeader>
                        <Inline space="small" verticalAlign="center" wrap="nowrap">
                            <BackButton to="/" />
                            <Heading level="1" size="small" weight="400">
                                User Settings
                            </Heading>
                        </Inline>
                    </PageHeader>
                    <PageBody>
                        <View flex="1" paddingX="xsmall" paddingY="xlarge" overflow="auto">
                            <View paddingX="medium" flexShrink={0}>
                                <ProfileHero user={user} onPress={onHeroPress} />
                            </View>
                            <Navigation user={user} select={select} />
                        </View>
                    </PageBody>
                </Page>
            </RelativeRoute>
        </RelativeSwitch>
    );
}

function Navigation({select, user}) {
    const {logout} = useLogout();
    const {trackEvent} = useAnalytics();

    return (
        <>
            <View marginBottom="medium" flexShrink={0}>
                <Stack space="small">
                    <SettingsLink
                        to={`/settings/account?source=${AccountInformationSource.PANEL}`}
                        label="Your profile"
                        icon={<AccountInfoIcon />}
                    />
                    <SettingsLink
                        to="/settings/privacy"
                        label="Security and privacy"
                        icon={<PrivacyIcon />}
                    />
                    {user.email.endsWith("@unibuddy.com") ? (
                        <SettingsLink
                            to="/settings/developer"
                            label="Developer options"
                            icon={<SettingsIcon />}
                        />
                    ) : null}

                    <SettingsStudentAppWrapper>
                        <SettingsLink
                            to="/"
                            label="Switch community"
                            icon={<SwitcherIcon />}
                            onClick={() => {
                                trackEvent(mapSettingsEvents[LabelProps.SWITCH_COMMUNITY]);
                                select();
                            }}
                        />
                    </SettingsStudentAppWrapper>
                </Stack>
            </View>
            <Box marginTop="auto" paddingX="medium">
                <Button color="light" block onClick={logout}>
                    Logout
                </Button>
            </Box>
        </>
    );
}
