import {View, useLocalStorage} from "@unibuddy/patron";
import {Rect} from "react-native-svg";
import {differenceInCalendarDays} from "date-fns";

import {
    useSaveUniversityQuestionAnswerMutation,
    useEnrolledUniversityQuestionsQuery,
} from "ubcommunity-shared/src/types";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useIsFeatureEnabledBoolean} from "ubcommunity-shared/src/Hooks/useIsFeatureEnabledBoolean";
import {
    DID_COMMUNITY_HELPED_QUESTION,
    STUDENT_HAS_ENROLLED_CTA,
} from "ubcommunity-shared/src/featureFlags";
import {Shimmer} from "ubcommunity-shared/src/General/Shimmer/Shimmer";

import {Question1, Question2, Question3, Question4} from "./questions";
import {SuccessModal} from "./SuccessModal";
import {Question5} from "./questions/Question5";

export const HAVE_ENROLLED_QUESTION_ID = "111100000000000000000030";
export const ENROLLED_COMMUNITY_INFLUENCE_RATING_QUESTION_ID = "111100000000000000000040";
export const ENROLLED_MORE_INFO = "111100000000000000000050";
export const ENROLLED_WHAT_INFLUENCED_DECISION = "111100000000000000000060";
export const DID_COMMUNITY_HELPED = "111100000000000000000061";
export const STEP_FOUR_TEXT_AREA_FIELD_NAME = "decisionFactors";
export const QUESTION_ONE_FIELD_NAME = "hasEnrolled";
export const QUESTION_TWO_FIELD_NAME = "influenceRating";
export const DID_COMMUNITY_HELPED_FIELD_NAME = "didCommunityHelped";

enum Q1_SUBMIT_VALUES {
    YES = "yes",
    NO = "no",
}

export const StudentEnrolledQuestionsCTA = () => {
    const SHOW_AGAIN_AFTER = 30; // in days

    const {university, selectedCommunity} = useCommunity();
    const isStudentConfidenceCTAFeatureEnabled =
        useIsFeatureEnabledBoolean(STUDENT_HAS_ENROLLED_CTA);
    const [value, setValue] = useLocalStorage(LocalStorageNames.HAS_SHOWN_HAS_ENROLLED_CTA, {
        haveCompleted: false,
        q1Submit: null,
        q2Submit: null,
        q3Submit: null,
        q4Submit: null,
        dismissed: null,
    });

    const {data, loading} = useEnrolledUniversityQuestionsQuery({
        variables: {
            haveEnrolledQuestionInput: {
                context: {
                    type: "community",
                },
                questionId: HAVE_ENROLLED_QUESTION_ID,
                universityId: university.id,
            },
            enrolledCommunityInfluenceRatingQuestionInput: {
                context: {
                    type: "community",
                },
                questionId: ENROLLED_COMMUNITY_INFLUENCE_RATING_QUESTION_ID,
                universityId: university.id,
            },
            enrolledMoreInfo: {
                context: {
                    type: "community",
                },
                questionId: ENROLLED_MORE_INFO,
                universityId: university.id,
            },
            enroledWhatInfluencedDecision: {
                context: {
                    type: "community",
                },
                questionId: ENROLLED_WHAT_INFLUENCED_DECISION,
                universityId: university.id,
            },
            didCommunityHelpedInDecision: {
                context: {
                    type: "community",
                },
                questionId: DID_COMMUNITY_HELPED,
                universityId: university.id,
            },
        },
    });

    const isQuestion5Enabled = useIsFeatureEnabledBoolean(DID_COMMUNITY_HELPED_QUESTION);

    const [saveAnswer, {error}] = useSaveUniversityQuestionAnswerMutation();

    const handleDismiss = () =>
        setValue({
            q1Submit: null,
            q2Submit: null,
            q3Submit: null,
            q4Submit: null,
            haveCompleted: false,
            dismissed: new Date(),
        });

    const handleQuestionOne = async (values) => {
        const answer = data?.question1?.options.find(
            (option) => option.id === values[QUESTION_ONE_FIELD_NAME],
        );

        await saveAnswer({
            variables: {
                saveUniversityQuestionAnswerInput: {
                    answers: [{id: answer.id, text: answer.text}],
                    context: {
                        id: selectedCommunity?.id,
                        type: "community",
                    },
                    questionId: HAVE_ENROLLED_QUESTION_ID,
                    universityId: university?.id,
                    universitySlug: university?.slug,
                },
            },
        });

        setValue({...value, q1Submit: answer.text.toLowerCase()});
    };

    const handleQuestionTwo = async (values) => {
        const answer = data?.question2?.options.find(
            (option) => option.id === values[QUESTION_TWO_FIELD_NAME],
        );

        await saveAnswer({
            variables: {
                saveUniversityQuestionAnswerInput: {
                    answers: [{id: answer.id, text: answer.text}],
                    context: {
                        id: selectedCommunity?.id,
                        type: "community",
                    },
                    questionId: ENROLLED_COMMUNITY_INFLUENCE_RATING_QUESTION_ID,
                    universityId: university?.id,
                    universitySlug: university?.slug,
                },
            },
        });

        setValue({...value, q2Submit: true, haveCompleted: true});
    };

    const handleDidCommunityHelpedQuestion = async (values) => {
        const answer = data?.question5?.options.find(
            (option) => option.id === values[DID_COMMUNITY_HELPED_FIELD_NAME],
        );

        await saveAnswer({
            variables: {
                saveUniversityQuestionAnswerInput: {
                    answers: [{id: answer?.id, text: answer?.text}],
                    context: {
                        id: selectedCommunity?.id,
                        type: "community",
                    },
                    questionId: DID_COMMUNITY_HELPED,
                    universityId: university?.id,
                    universitySlug: university?.slug,
                },
            },
        });

        setValue({...value, q2Submit: true, haveCompleted: true});
    };

    const handleQuestionThree = async (values) => {
        if (values.currentStatus) {
            const answers = [{id: values.currentStatus.id, text: values.currentStatus?.text}];
            await saveAnswer({
                variables: {
                    saveUniversityQuestionAnswerInput: {
                        answers: answers,
                        context: {
                            id: selectedCommunity?.id,
                            type: "community",
                        },
                        questionId: ENROLLED_MORE_INFO,
                        universityId: university?.id,
                        universitySlug: university?.slug,
                    },
                },
            });
        }

        setValue({...value, q3Submit: true});
    };

    const handleQuestionFour = async (values) => {
        const answers = [
            {
                id: data?.question4.id,
                text: values[data?.question4.id],
            },
        ];

        await saveAnswer({
            variables: {
                saveUniversityQuestionAnswerInput: {
                    answers,
                    context: {
                        id: selectedCommunity?.id,
                        type: "community",
                    },
                    questionId: ENROLLED_MORE_INFO,
                    universityId: university?.id,
                    universitySlug: university?.slug,
                },
            },
        });
        setValue({...value, q4Submit: true, haveCompleted: true});
    };

    const shouldShowModel = (lastShowDate: string) => {
        if (!lastShowDate) {
            return true;
        }
        const differenceInDays = differenceInCalendarDays(
            new Date(Date.now()),
            new Date(lastShowDate),
        );

        if (differenceInDays < SHOW_AGAIN_AFTER) {
            return false;
        }
        return true;
    };
    if (isStudentConfidenceCTAFeatureEnabled && shouldShowModel(value.dismissed) && data) {
        if (loading) {
            return (
                <View
                    paddingX="small-medium"
                    //@ts-ignore valid in RN
                    accessibilityLabel="Loading have enrolled survey"
                >
                    <Shimmer height={300}>
                        <Rect x="0" y="30" rx="8" ry="8" width="100%" height="250" />
                    </Shimmer>
                </View>
            );
        } else {
            return (
                <>
                    {!value.q1Submit && !value.haveCompleted ? (
                        <Question1
                            data={data.question1}
                            error={error}
                            handleSubmit={handleQuestionOne}
                            handleDismiss={handleDismiss}
                        />
                    ) : null}
                    {value.q1Submit === Q1_SUBMIT_VALUES.YES && !value.haveCompleted ? (
                        isQuestion5Enabled ? (
                            <Question5
                                data={data.question5}
                                error={error}
                                handleSubmit={handleDidCommunityHelpedQuestion}
                                handleDismiss={handleDismiss}
                            />
                        ) : (
                            <Question2
                                data={data.question2}
                                error={error}
                                handleSubmit={handleQuestionTwo}
                                handleDismiss={handleDismiss}
                            />
                        )
                    ) : null}
                    {value.q1Submit === Q1_SUBMIT_VALUES.NO &&
                    !value.q3Submit &&
                    !value.haveCompleted ? (
                        <Question3
                            data={data.question3}
                            error={error}
                            handleSubmit={handleQuestionThree}
                            handleDismiss={handleDismiss}
                        />
                    ) : null}
                    {value.q1Submit === Q1_SUBMIT_VALUES.NO &&
                    value.q3Submit &&
                    !value.haveCompleted ? (
                        <Question4
                            data={data.question4}
                            error={error}
                            handleSubmit={handleQuestionFour}
                            handleDismiss={handleDismiss}
                        />
                    ) : null}
                    {value.haveCompleted ? <SuccessModal handleDismiss={handleDismiss} /> : null}
                </>
            );
        }
    }
    return null;
};
