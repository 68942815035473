import {useErrorReporting} from "@unibuddy/error-reporting";

import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {SpaceType, useSpaces} from "ubcommunity-shared/src/StudentApp/SpacesProvider";
import {useCreateDirectConversation} from "ubcommunity-shared/src/Hooks/graphql/useCreateDirectConversation";
import {useGetOrCreateWidgetConversation} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/Profile/useGetOrCreateWidgetConversation";

/**
 * Custom hook to create a conversation based on the selected SpaceType.
 * If the selected space is a university, it will create a widget conversation.
 * If the selected space is a community, it will create a direct conversation.
 *
 */
export const useCreateConversation = ({senderId}: {senderId: string}) => {
    const {selectedSpace} = useSpaces();
    const {open} = usePrompt();
    const {reportError} = useErrorReporting();

    const {
        loading: wLoading,
        error: wError,
        createWidgetConversation,
    } = useGetOrCreateWidgetConversation();

    const {
        createDirectConversation,
        loading: dmLoading,
        error: dmError,
    } = useCreateDirectConversation({senderId});

    const createConversation = async (): Promise<string | undefined> => {
        if (!selectedSpace) {
            open({
                title: "Error",
                message: "You must select a University to create a conversation.",
                buttons: [{text: "Ok"}],
            });
            return;
        }

        try {
            if (selectedSpace?.type === SpaceType.UNIVERSITY) {
                const response = await createWidgetConversation(senderId);

                if (!response || !response?.data?.getOrCreateWidgetConversation?.id)
                    throw new Error("Invalid response while creating widget conversation");

                return response?.data?.getOrCreateWidgetConversation?.id;
            }

            if (selectedSpace?.type === SpaceType.COMMUNITY) {
                const response = await createDirectConversation();

                if (!response || !response?.data?.createDirectConversation?.id)
                    throw new Error("Invalid response while creating Direct conversation");

                return response?.data?.createDirectConversation?.id;
            }
        } catch (err) {
            open({
                title: "Error",
                message: "Conversation creation failed, Please try again.",
                buttons: [{text: "OK"}],
            });

            reportError(err);
        }
        return;
    };

    return {
        createConversation,
        loading: wLoading || dmLoading,
        error: wError || dmError,
    };
};
