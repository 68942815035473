import React from "react";
import {
    UniversalInboxItem,
    UniversalInboxItemTitle,
} from "ubcommunity-shared/src/UniversalInbox/UniversalInboxItem";
import useFindSenderInPair from "ubcommunity-shared/src/Auth/hooks/useFindSenderInPair";
import {ChatMembersAvatars} from "ubcommunity-shared/src/Chat/ChatMembersAvatars/ChatMembersAvatars";
import {UserAvatarUser} from "ubcommunity-shared/src/General/UserAvatar/UserAvatar";
import {UserField} from "ubcommunity-shared/src/types";
import {getUniversalInboxUserRoleTag} from "./utils";
import {InboxItem} from "./CommunityUniversalInboxItem";

export function WidgetUniversalInboxItem({item, onSelect, activeConversationId}: InboxItem) {
    const {findSenderInPair} =
        useFindSenderInPair<Omit<UserField, "interests" | "communityProfile">>();
    const sender = findSenderInPair(item.members ?? []);
    const tag = sender?.accountRole ? getUniversalInboxUserRoleTag(sender?.accountRole) : undefined;

    return (
        <UniversalInboxItem
            a11yLabel={`Conversation with ${sender?.firstName ?? ""} ${sender?.lastName ?? ""}${
                tag ? ", " + tag : ""
            }`}
            onPress={() => onSelect({id: item.id})}
            item={item}
            avatar={<ChatMembersAvatars members={[sender as UserAvatarUser]} />}
            title={
                <UniversalInboxItemTitle>
                    {`${sender?.firstName ?? ""} ${sender?.lastName ?? ""}`}
                </UniversalInboxItemTitle>
            }
            tag={tag}
            isActive={activeConversationId === item.id}
        />
    );
}
