import React from "react";
import {Stack, Text, View, usePatronTheme} from "@unibuddy/patron";

import AddIcon from "ubcommunity-shared/src/Icons/AddIcon";

export const DropzoneOverlay = () => {
    const theme = usePatronTheme();

    return (
        <View
            bgColor="white"
            w="100%"
            h="100%"
            zIndex={100}
            alignItems="center"
            justifyContent="center"
        >
            <View
                w="95%"
                h="95%"
                borderRadius="gutter"
                borderWidth={6}
                borderColor="grey"
                padding="large"
                margin="small"
                style={{
                    borderStyle: "dashed",
                }}
                alignItems="center"
                justifyContent="center"
            >
                <Stack space="large" align="center">
                    <AddIcon size={50} color={theme.colors.grey} />
                    <Text weight="bold" size="xxlarge" color="grey">
                        Drop files here
                    </Text>
                </Stack>
            </View>
        </View>
    );
};
