import {Platform} from "react-native";
import {Button, Text, View, usePatronTheme} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import PlusIcon from "ubcommunity-shared/src/Icons/PlusIcon";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {TrackEvents} from "ubcommunity-shared/src/constants/trackingConstants";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

type CreateGroupButtonProps = {
    handleCreate: () => void;
    title?: string;
};

export const CreateGroupButton = ({handleCreate, title = "New Group"}: CreateGroupButtonProps) => {
    const {darkModeEnabled} = useCommunityTheme();
    const {trackEvent} = useAnalytics();
    const theme = usePatronTheme();

    const handleClick = () => {
        trackEvent(TrackEvents.NEW_GROUP_BUTTON_CLICKED);
        handleCreate();
    };

    const color = darkModeEnabled ? theme.colors.textLinkColor : theme.colors.primaryColor;

    return (
        <Button
            onClick={handleClick}
            color="primary"
            style={{height: 40}}
            accessibilityLabel="New Group"
            style={{
                backgroundColor: theme?.colors?.body,
                borderWidth: 1,
                borderColor: color,
            }}
        >
            <View
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                paddingTop={Platform.OS === "ios" ? "xxsmall" : "none"}
            >
                <View paddingRight="xxsmall">
                    <PlusIcon size={20} color={color} />
                </View>

                <Text
                    size="medium"
                    color={color}
                    style={{...boldFontStyles, verticalAlign: "middle"}}
                >
                    {title}
                </Text>
            </View>
        </Button>
    );
};
