import React from "react";
import {Stack, Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {ApolloError} from "@apollo/client";
import {INVITE_CONTAINER_ERROR_MESSAGE_STRINGS} from "../../strings";
import {TrackEvents} from "ubcommunity-shared/src/constants";

const errorBackground = "#F5F5F4";

export const InviteLinkError = ({error, inviteId}: {error?: ApolloError; inviteId: string}) => {
    const {trackEvent} = useAnalytics();
    let customErrorTitle = INVITE_CONTAINER_ERROR_MESSAGE_STRINGS.DEFAULT_ERROR_MESSAGE;
    let customErrorDescription =
        INVITE_CONTAINER_ERROR_MESSAGE_STRINGS.DEFAULT_CONTACT_SUPPORT_MESSAGE;

    if (error?.graphQLErrors[0]?.extensions?.exception?.status === 404) {
        trackEvent(TrackEvents.INVITE_LINK_NOT_FOUND, {
            inviteId,
            message: error?.graphQLErrors[0]?.message,
        });
        customErrorTitle = INVITE_CONTAINER_ERROR_MESSAGE_STRINGS.COMMUNITY_NOT_FOUND_MESSAGE;
        customErrorDescription = INVITE_CONTAINER_ERROR_MESSAGE_STRINGS.CONTACT_UNIVERSITY_MESSAGE;
    } else if (error?.graphQLErrors[0]?.extensions?.code === "BAD_USER_INPUT") {
        trackEvent(TrackEvents.INVITE_LINK_INVALID, {
            inviteId,
        });
        customErrorTitle = INVITE_CONTAINER_ERROR_MESSAGE_STRINGS.INVITE_CODE_INVALID_MESSAGE;
        customErrorDescription =
            INVITE_CONTAINER_ERROR_MESSAGE_STRINGS.CONTACT_UNIVERSITY_NEW_LINK_MESSAGE;
    }

    return (
        <View bgColor={errorBackground} padding="large">
            <Stack space="medium-large" align="center">
                <Text size="large" align="center">
                    {customErrorTitle}
                </Text>
                <Text align="center">{customErrorDescription}</Text>
            </Stack>
        </View>
    );
};
