import {TrackEvents} from "ubcommunity-shared/src/constants";

export enum LabelProps {
    YOUR_PROFILE = "Your profile",
    SECURITY_PRIVACY = "Security and privacy",
    SWITCH_COMMUNITY = "Switch community",
    DEVELOPER_OPTIONS = "Developer options",
}
export const mapSettingsEvents = {
    [LabelProps.YOUR_PROFILE]: TrackEvents.SETTINGS_YOUR_PROFILES_CLICKED,
    [LabelProps.SECURITY_PRIVACY]: TrackEvents.SETTINGS_SECURITY_PRIVACY_CLICKED,
    [LabelProps.SWITCH_COMMUNITY]: TrackEvents.SETTINGS_SWITCH_COMMUNITY_CLICKED,
    [LabelProps.DEVELOPER_OPTIONS]: "",
};
