import React from "react";
import {Platform} from "react-native";
import {Reaction} from "@unibuddy/chat-ui";
import {Box, Text, usePatronTheme} from "@unibuddy/patron";

import {ChatMessage} from "ubcommunity-shared/src/types";

import {useCommunityTheme} from "../../Theme/CommunityThemeProvider";
import {ReactionsList, ReactionsMap} from "./useReaction";
import {useReactionUtils} from "./utils";

type Props = {
    item: ChatMessage;
    pinnedMessageIds: Array<string>;
    userId: string;
    onReacted: (
        message: ChatMessage,
        reaction: typeof ReactionsList[0],
        action: "reacted" | "unreacted",
    ) => void;
};

const MessageEmojiReactions = ({
    reaction,
    color,
    item,
    userId,
    onReacted,
}: {
    reaction: typeof ReactionsList[0];
    color: string;
} & Pick<Props, "item" | "userId" | "onReacted">) => {
    const {getUserIdsForLikes, getUserIdsForReaction} = useReactionUtils();

    // transform likes into emojis
    let userIdsForReaction = [];
    const isLike = reaction.label === ReactionsMap["Like"].label;
    if (isLike) {
        userIdsForReaction = getUserIdsForLikes(item);
    } else {
        userIdsForReaction = getUserIdsForReaction(item, reaction.reactionUnicode);
    }

    if (!userIdsForReaction?.length) return null;

    const hasReacted = userIdsForReaction.includes(userId);

    return (
        // we should be using the `Reaction` component from `chat-ui` here
        // but it uses `Inline` internally which has a bug causing ghost
        // spacing on `null` children
        <Box marginLeft="xxsmall">
            <Reaction.Emoji
                onClick={() =>
                    !hasReacted
                        ? onReacted(item, reaction, "reacted")
                        : onReacted(item, reaction, "unreacted")
                }
                count={userIdsForReaction.length}
                hasReacted={hasReacted}
                key={isLike ? ReactionsMap["Like"].reactionUnicode : reaction.reactionUnicode}
                label={isLike ? ReactionsMap["Like"].label : reaction.label}
                color={color}
                borderRadius="large"
                padding="large"
            >
                <Text size="xxsmall">{isLike ? ReactionsMap["Like"].emoji : reaction.emoji}</Text>
            </Reaction.Emoji>
        </Box>
    );
};

export const MessageReactions = ({item, pinnedMessageIds, userId, onReacted}: Props) => {
    const theme = usePatronTheme();
    const {darkModeEnabled} = useCommunityTheme();

    const color = theme?.TextBlock?.color;

    const isPinned = pinnedMessageIds?.includes(item?.id);

    return (
        <Reaction space="none">
            <Reaction.Pinned
                isPinned={isPinned}
                borderRadius="large"
                iconProps={{
                    size: Platform.OS === "web" ? 14 : 12,
                    color: darkModeEnabled
                        ? theme?.colors?.textLinkColor
                        : theme?.colors?.primaryColor,
                }}
            />
            {ReactionsList.map((reaction) => (
                <MessageEmojiReactions
                    key={reaction.reactionUnicode}
                    reaction={reaction}
                    color={color}
                    item={item}
                    userId={userId}
                    onReacted={onReacted}
                />
            ))}
        </Reaction>
    );
};
