import React from "react";

import {Filters as FiltersWeb} from "./Filters/Filters";
import {Filters as FiltersMobile} from "./Filters/Filters.mobile";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";

export const PeopleFilters = () => {
    const {isDesktop} = useIsDesktop();

    if (isDesktop) {
        return <FiltersWeb />;
    }

    return <FiltersMobile />;
};
