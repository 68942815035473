import {useCallback, useState} from "react";
import {useParams} from "react-router";
import {Columns, Column, Button, MoreIcon, Drawer, CloseIcon, View} from "@unibuddy/patron";
import {ChatData} from "ubcommunity-shared/src/Chat/ChatData";
import {Attachment} from "ubcommunity-shared/src/types";
import {ChatTitle, fetchSender} from "ubcommunity-shared/src/Chat/ChatTitle/ChatTitle";
import {UniversitySlugAnalyticsValue} from "ubcommunity-shared/src/Utils/Analytics/UniversitySlugAnalyticsValue";
import {Page, PageBody, PageHeader} from "../../Layout/Page/Page";
import {ChatSideDrawer} from "./ChatSideDrawer";
import {BackButton} from "../../Layout/BackButton/BackButton";
import {BlockModal} from "./BlockModal";
import {UserBlockModal} from "./UserBlockModal";
import {ReportMessageModal as ReportModal} from "./ReportMessageModal";
import {UserSummary} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary";
import {SpotlightModal} from "../../General/Spotlight/SpotlightModal";
import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useModal} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import useFindSenderInPair from "ubcommunity-shared/src/Auth/hooks/useFindSenderInPair";
import {MixpanelMetadataProps} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummary";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {useIsGroupMember} from "ubcommunity-shared/src/Hooks/useIsGroupMember";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";
import {
    useConversation,
    ConversationType,
} from "ubcommunity-shared/src/Conversation/useConversation";
import {handleBlockOrReportProps, handleUserBlockProps} from "ubcommunity-shared/src/Chat/types";
import useSetActiveCommunity from "ubcommunity-shared/src/Hooks/useSetActiveCommunity";

export const ChatPage = () => {
    const {findSenderInPair} = useFindSenderInPair();
    const {authState} = useAuth();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {id} = useParams<{id: string}>();
    const [spotlight, setSpotlight] = useState<{
        images: IImagePreview[];
        selectedImage: IImagePreview | null;
    }>({images: [], selectedImage: null});
    const {openModal, dismissModal} = useModal();
    const {openUserSummary, closeUserSummary} = useOpenUserSummary({
        openSource: "Chat page",
    });
    const {conversationType, data, loading, error, refetch} = useConversation();
    useSetActiveCommunity();

    const isWidgetConversation = conversationType === ConversationType.WIDGET;

    usePageTitle(PageTitles.CHAT);

    const {isMember, loading: membersLoading} = useIsGroupMember(id);

    const handleBlockOrReport = useCallback(
        ({conversationId, message, type}: handleBlockOrReportProps) => {
            if (type === "block") {
                openModal({
                    component: (
                        <BlockModal
                            onDismiss={dismissModal}
                            message={message}
                            conversationId={conversationId}
                        />
                    ),
                });
            } else {
                openModal({
                    component: (
                        <ReportModal
                            onDismiss={dismissModal}
                            message={message}
                            conversationId={conversationId}
                        />
                    ),
                });
            }
        },
        [openModal, dismissModal],
    );

    const handleUserBlock = useCallback(
        ({senderId}: handleUserBlockProps) => {
            closeUserSummary();
            openModal({
                component: <UserBlockModal senderId={senderId} onDismiss={dismissModal} />,
            });
        },
        [openModal, dismissModal, closeUserSummary],
    );

    const handleShowUserSummary = useCallback(
        (userId: string, mixpanelMetadata?: MixpanelMetadataProps): void => {
            openUserSummary(userId, id, mixpanelMetadata);
        },
        [openUserSummary, id],
    );

    const handleSpotlight = useCallback((images: IImagePreview[], selectedImage: IImagePreview) => {
        setSpotlight({images, selectedImage});
    }, []);

    const handlePreview = useCallback((file: Attachment) => {
        // open pdf in new tab
        window.open(file.link, "_blank");
    }, []);

    const handleOnTitlePress = () => {
        if (data?.getChatConversation?.isDirect || isWidgetConversation) {
            const sender = fetchSender(
                authState?.me?.anyUser?.id,
                data?.getChatConversation.communityChatMembers,
            )[0];
            handleShowUserSummary(sender?.id);
        } else {
            setIsDrawerOpen(!isDrawerOpen);
        }
    };

    if (!data && error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                meta={{component: "ChatPage", query: "useGetCommunityConversationQuery"}}
                layout="center"
            />
        );
    }

    return (
        <Page>
            <UniversitySlugAnalyticsValue
                universitySlug={data?.getChatConversation?.university?.slug}
            />
            <PageHeader>
                <Columns space="xxsmall" verticalAlign="center">
                    <Column width="content">
                        <BackButton to="/chat" />
                    </Column>
                    <Column>
                        <View>
                            <ChatTitle
                                conversationId={id}
                                getChatConversation={data?.getChatConversation}
                                loading={loading}
                                onPress={() => handleOnTitlePress()}
                            />
                        </View>
                    </Column>
                    <Column width="content">
                        <Button
                            aria-label={isDrawerOpen ? "Close side bar" : "Open side bar"}
                            onClick={() => handleOnTitlePress()}
                            iconOnly
                            clear
                        >
                            {isDrawerOpen ? (
                                <CloseIcon />
                            ) : (
                                <MoreIcon style={{transform: "rotate(90deg)"}} />
                            )}
                        </Button>
                    </Column>
                </Columns>
            </PageHeader>
            <PageBody>
                <ChatData
                    conversationId={id}
                    onBlockOrReport={handleBlockOrReport}
                    onShowUserSummary={handleShowUserSummary}
                    onSpotlight={handleSpotlight}
                    onPreview={handlePreview}
                    onUserBlock={handleUserBlock}
                    isMember={isMember}
                    membersLoading={membersLoading}
                />
                <SpotlightModal
                    isOpen={!!spotlight.selectedImage}
                    onDismiss={() => setSpotlight({images: [], selectedImage: null})}
                    images={spotlight.images}
                    selectedImage={spotlight.selectedImage}
                />
                <Drawer
                    id="chat-information"
                    position="fixed"
                    isOpen={isDrawerOpen}
                    onDismiss={() => setIsDrawerOpen(false)}
                >
                    {data?.getChatConversation.isDirect ? (
                        <UserSummary
                            senderId={
                                findSenderInPair(data?.getChatConversation.communityChatMembers).id
                            }
                            conversationId={id}
                            mixpanelMetadata={{openSource: "Chat Page"}}
                        />
                    ) : (
                        <ChatSideDrawer
                            conversation={data?.getChatConversation}
                            loading={loading}
                            onShowUserSummary={(userId, mixpanelMetadata = {}) => {
                                mixpanelMetadata.openSource = "Members List";
                                handleShowUserSummary(userId, mixpanelMetadata);
                            }}
                        />
                    )}
                </Drawer>
            </PageBody>
        </Page>
    );
};
