import React from "react";
import {Platform} from "react-native";

import {Text, Columns, Column, LoadingButton, usePatronTheme, View} from "@unibuddy/patron";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export const UserSummaryActionButton = ({
    onClick,
    title,
    Icon,
    loading = false,
    disabled = false,
}: {
    onClick: () => void;
    title: string;
    Icon: any;
    loading?: boolean;
    disabled?: boolean;
}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();
    return (
        <LoadingButton
            style={
                Platform.OS === "web"
                    ? {display: "block"}
                    : {display: "flex", alignItems: "flex-start"}
            }
            block
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            clear
            accessibilityLabel={title}
        >
            <Columns verticalAlign="center">
                <Column width="content">
                    <Icon
                        size={24}
                        color={darkModeEnabled ? theme?.colors?.white : theme?.colors?.darkGrey}
                    />
                </Column>
                <Column>
                    <View paddingLeft="small">
                        <Text
                            color={darkModeEnabled ? "white" : "darkGrey"}
                            style={{
                                ...boldFontStyles,
                            }}
                        >
                            {title}
                        </Text>
                    </View>
                </Column>
            </Columns>
        </LoadingButton>
    );
};
