import {FC, ReactNode, PropsWithChildren} from "react";
import {Box, Heading, LayoutSpaceProp, ResponsiveProp, View} from "@unibuddy/patron";
import {Image} from "react-native";
import styled from "styled-components";
import {useAnalytics} from "@unibuddy/tracking";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import RelativeLink from "ubcommunity-shared/src/Navigation/RelativeLink/RelativeLink";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {AvatarWithCircularProgressionBar} from "ubcommunity-shared/src/General/CircularProgressionBar";
import {useGetUserProfile} from "ubcommunity-shared/src/Hooks/useGetUserProfile";
import {NavigationTabs, BottomNavigation} from "../NavigationTabs/NavigationTabs";
import {MobileAppDownloadPrompt} from "../../General/MobileAppDownloadPrompt/MobileAppDownloadPrompt";
import {AccountInformationSource} from "ubcommunity-shared/src/nativeNavigationParamsList/SettingsStackParamList";
import {UBLogo} from "ubcommunity-shared/src/Svgs/UBLogo";
import {AdminInboxHeader} from "ubcommunity-shared/src/AdminInbox/AdminInboxHeader";
import {useIsAdminExperience} from "ubcommunity-shared/src/AdminInbox/useIsAdminExperience";

export const PageHeader = ({
    children,
    padding = "small",
}: {
    children: ReactNode;
    padding?: ResponsiveProp<LayoutSpaceProp>;
}) => {
    return (
        <View padding={padding} shadow="large" zIndex={1} flexShrink={0}>
            {children}
        </View>
    );
};

export const Page: FC<PropsWithChildren<Record<never, never>>> = ({children}) => {
    return (
        <View flex="1" minH={0} maxH="100%" zIndex={0}>
            {children}
        </View>
    );
};

export const PageBody: FC<PropsWithChildren<Record<never, never>>> = ({children}) => {
    return (
        <View flex="1" minH={0} overflow="hidden" position="relative" flexDirection="row">
            {children}
        </View>
    );
};

const FlexRelativeLink = styled(RelativeLink)`
    display: flex;
    justify-content: end;
`;

const AvatarWithCircularProgressionBarWrapper = () => {
    const {user} = useAuth();
    const {profileCompletion} = useGetUserProfile();

    return (
        <AvatarWithCircularProgressionBar
            avatarSize={36}
            circularProgressionBarSize={46}
            user={user}
            progressPercentage={profileCompletion()}
            progressionBarStyle={{
                lineColor: "#F3EBFE",
                activeLineColor: "#FED401",
                strokeWidth: 1,
                activeStrokeWidth: 3,
            }}
        />
    );
};

export const DesktopPageWrapper = ({children}: PropsWithChildren) => {
    return (
        <View flex="1" bgColor="#d0d6dc" justifyContent="center" flexDirection="row">
            <View
                bgColor="white"
                shadow="large"
                borderRadius="xsmall"
                overflow="hidden"
                margin={["none", "none", "none", "medium"]}
                maxW={1600}
                flex="1"
                position="relative"
            >
                {children}
            </View>
        </View>
    );
};

const HeaderContent = () => {
    const {isAdminExperience} = useIsAdminExperience();

    if (isAdminExperience) {
        return (
            <View maxW={400}>
                <AdminInboxHeader />
            </View>
        );
    }

    return (
        <Heading level="1" size="xxsmall" weight="400">
            Unibuddy
        </Heading>
    );
};

export const DesktopPageLayout: FC<
    PropsWithChildren<{
        desktopBanner?: ReactNode | null;
    }>
> = ({children, desktopBanner = <MobileAppDownloadPrompt />}) => {
    const {trackEvent} = useAnalytics();
    return (
        <Page>
            <View flex="1">
                <PageHeader padding="none">
                    <Box
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="nowrap"
                        display="flex"
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            paddingX="small"
                        >
                            <UBLogo size={40} />
                        </Box>

                        <HeaderContent />

                        <Box padding="small" flex="1">
                            <FlexRelativeLink
                                aria-label="User settings"
                                to={`/settings/account?source=${AccountInformationSource.PROFILE_PICTURE}`}
                                onClick={() => {
                                    trackEvent(
                                        TrackEvents.COMMUNITY_HEADER_PROFILE_PICTURE_CLICKED,
                                    );
                                }}
                            >
                                <AvatarWithCircularProgressionBarWrapper />
                            </FlexRelativeLink>
                        </Box>
                    </Box>
                </PageHeader>

                <PageBody>
                    <View w={96} borderRightWidth={1} borderColor="#f6f6f6">
                        <NavigationTabs />
                    </View>

                    <View flex="1">
                        {desktopBanner}

                        {children}
                    </View>
                </PageBody>
            </View>
        </Page>
    );
};

export const MobilePageLayout: FC<PropsWithChildren<{hideHeader?: boolean}>> = ({
    children,
    hideHeader = false,
}) => {
    const {university} = useCommunity();

    return (
        <Page>
            <View flex="1">
                {hideHeader ? null : (
                    <PageHeader>
                        <Box
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="nowrap"
                            display="flex"
                        >
                            <Image
                                style={{width: 40, height: 40, borderRadius: 6}}
                                source={{uri: university?.squareLogo}}
                                width={40}
                                height={40}
                            />

                            <Heading align="center" level="1" size="xxsmall" weight="400">
                                {university?.name}
                            </Heading>

                            <RelativeLink aria-label="User settings" to="/settings">
                                <AvatarWithCircularProgressionBarWrapper />
                            </RelativeLink>
                        </Box>
                    </PageHeader>
                )}

                <MobileAppDownloadPrompt />

                <PageBody>{children}</PageBody>
                <View>
                    <BottomNavigation />
                </View>
            </View>
        </Page>
    );
};
