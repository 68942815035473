import {Attachment} from "ubcommunity-shared/src/types";

export const useDownloadFile = () => {
    const downloadFile = async (file: Attachment) => {
        const response = await fetch(file.link);
        const blob = await response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = file.fileName;
        alink.click();
    };

    return {
        downloadFile,
    };
};
