import React from "react";

function ReportIcon(props) {
    return (
        <svg
            width={props.size || 20}
            height={props.size || 20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.7022 3.44295C12.0471 3.44295 13.3618 3.84176 14.48 4.58896C15.5983 5.33615 16.4699 6.39816 16.9845 7.6407C17.4992 8.88324 17.6339 10.2505 17.3715 11.5696C17.1091 12.8886 16.4615 14.1003 15.5105 15.0513C14.5595 16.0023 13.3478 16.6499 12.0288 16.9123C10.7097 17.1747 9.34244 17.04 8.0999 16.5253C6.85737 16.0107 5.79535 15.1391 5.04816 14.0208C4.30096 12.9026 3.90215 11.5879 3.90215 10.2429C3.90427 8.44013 4.62138 6.71175 5.89616 5.43696C7.17095 4.16217 8.89933 3.44507 10.7022 3.44295ZM10.7022 2.24295C9.1199 2.24295 7.57318 2.71214 6.25759 3.59119C4.942 4.47024 3.91662 5.71967 3.31112 7.18148C2.70561 8.64329 2.54719 10.2518 2.85587 11.8037C3.16455 13.3555 3.92648 14.781 5.0453 15.8998C6.16412 17.0186 7.58958 17.7805 9.14143 18.0892C10.6933 18.3979 12.3018 18.2395 13.7636 17.634C15.2254 17.0285 16.4749 16.0031 17.3539 14.6875C18.233 13.3719 18.7021 11.8252 18.7021 10.2429C18.7021 9.19237 18.4952 8.15209 18.0932 7.18148C17.6911 6.21088 17.1019 5.32896 16.359 4.5861C15.6161 3.84323 14.7342 3.25395 13.7636 2.85191C12.793 2.44988 11.7527 2.24295 10.7022 2.24295Z"
                fill="currentColor"
            />
            <path
                d="M5.69224 15.5129C5.57365 15.5128 5.45775 15.4775 5.35918 15.4116C5.26061 15.3457 5.18378 15.252 5.13842 15.1424C5.09305 15.0329 5.08116 14.9123 5.10427 14.796C5.12738 14.6797 5.18444 14.5728 5.26824 14.4889L14.9307 4.82644C14.986 4.76911 15.0522 4.72337 15.1254 4.69189C15.1986 4.66041 15.2773 4.64382 15.357 4.64309C15.4366 4.64236 15.5156 4.65751 15.5894 4.68764C15.6631 4.71777 15.7302 4.76229 15.7865 4.8186C15.8429 4.87491 15.8875 4.94188 15.9177 5.0156C15.9479 5.08933 15.9631 5.16833 15.9624 5.24799C15.9618 5.32766 15.9453 5.4064 15.9139 5.47962C15.8825 5.55284 15.8368 5.61906 15.7795 5.67444L6.11625 15.3377C6.0606 15.3934 5.99449 15.4375 5.92173 15.4676C5.84896 15.4977 5.77098 15.513 5.69224 15.5129Z"
                fill="currentColor"
            />
        </svg>
    );
}

const MemoReportIcon = React.memo(ReportIcon);
export default MemoReportIcon;
