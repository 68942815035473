import React from "react";
import {useWindowDimensions, Platform} from "react-native";
import {View, usePatronTheme} from "@unibuddy/patron";
import {Circle, Rect} from "react-native-svg";

import {Shimmer} from "ubcommunity-shared/src/General/Shimmer";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

export const ChatShimmer = () => {
    const numberOfRepetitions = 4;

    return (
        <View
            paddingX="xsmall"
            paddingTop="xsmall"
            flex={1}
            width="100%"
            accessible
            accessibilityLabel="loading"
            access
            accessitilityRole="progressbar"
        >
            {Array(numberOfRepetitions)
                .fill(0)
                .map((_, i) => (
                    <View paddingY="xsmall" marginBottom="small" key={i}>
                        <ChatShimmerItem index={i} />
                    </View>
                ))}
        </View>
    );
};

const ChatShimmerItem = ({index}: {index: number}) => {
    const {width} = useWindowDimensions();
    const theme = usePatronTheme();
    const isWeb = Platform.OS === "web";
    const containerWidth = isWeb ? 500 : width;
    const {darkModeEnabled} = useCommunityTheme();
    return (
        <Shimmer
            height={index % 2 ? 140 : 180}
            backgroundColor={darkModeEnabled ? "#2B2D3F" : theme.colors.grey100}
            foregroundColor={darkModeEnabled ? "#424157" : theme.colors.grey90}
        >
            <Circle cx="20" cy="20" r="20" />
            <Rect x="50" y="0" rx="2" ry="2" width={80} height={12} />
            <Rect
                x="50"
                y="20"
                rx="8"
                ry="8"
                width={containerWidth / 1.3}
                height={index % 2 ? 120 : 160}
            />
        </Shimmer>
    );
};
