import React from "react";

const ReportIcon = (props) => (
    <svg
        width={props.size || 20}
        height={props.size || 20}
        viewBox="-2 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6621 1.59091L21.1096 17.9545C21.2846 18.2576 21.0659 18.6364 20.716 18.6364H1.82089C1.47099 18.6364 1.25229 18.2576 1.42725 17.9545L10.8748 1.59091C11.0498 1.28788 11.4871 1.28788 11.6621 1.59091ZM9.69385 0.90909C10.3937 -0.303031 12.1432 -0.30303 12.843 0.909092L22.2906 17.2727C22.9904 18.4848 22.1156 20 20.716 20H1.82089C0.421256 20 -0.453516 18.4848 0.246303 17.2727L9.69385 0.90909ZM11.25 5C11.7678 5 12.1875 5.41973 12.1875 5.9375V12.1875C12.1875 12.7053 11.7678 13.125 11.25 13.125C10.7322 13.125 10.3125 12.7053 10.3125 12.1875V5.9375C10.3125 5.41973 10.7322 5 11.25 5ZM12.5 15.9375C12.5 16.6279 11.9404 17.1875 11.25 17.1875C10.5596 17.1875 10 16.6279 10 15.9375C10 15.2471 10.5596 14.6875 11.25 14.6875C11.9404 14.6875 12.5 15.2471 12.5 15.9375Z"
            fill="currentColor"
        />
    </svg>
);

const MemoReportIcon = React.memo(ReportIcon);
export default MemoReportIcon;
