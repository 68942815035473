import {Platform} from "react-native";
import type {HapticFeedbackTypes} from "react-native-haptic-feedback";

export const hapticTypes = {
    contextClick: Platform.select<number | number[] | HapticFeedbackTypes>({
        web: 10,
        native: "contextClick",
    }),
    impactMedium: Platform.select<number | number[] | HapticFeedbackTypes>({
        web: 40,
        ios: "impactMedium",
        android: "impactLight",
    }),
    impactLight: Platform.select<number | number[] | HapticFeedbackTypes>({
        web: 20,
        ios: "impactLight",
        android: "impactLight",
    }),
    notificationSuccess: Platform.select<number | number[] | HapticFeedbackTypes>({
        web: [10, 40, 20],
        native: "notificationSuccess",
    }),
};
