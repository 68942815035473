import React from "react";

export default function AddIcon(props) {
    return (
        <svg
            width={props.size || 20}
            height={props.size || 20}
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.99046 1.25413C10.3873 1.25413 11.7527 1.66833 12.9141 2.44436C14.0756 3.22039 14.9808 4.32339 15.5153 5.61389C16.0498 6.90438 16.1897 8.3244 15.9172 9.69438C15.6447 11.0644 14.9721 12.3228 13.9844 13.3105C12.9967 14.2982 11.7383 14.9708 10.3683 15.2433C8.99829 15.5158 7.57827 15.376 6.28778 14.8414C4.99729 14.3069 3.89429 13.4017 3.11825 12.2402C2.34222 11.0788 1.92802 9.71338 1.92802 8.31657C1.93022 6.44417 2.675 4.64908 3.99899 3.32509C5.32298 2.00111 7.11806 1.25632 8.99046 1.25413ZM8.99046 0.0078125C7.34714 0.0078125 5.74073 0.495112 4.37436 1.40809C3.008 2.32107 1.94304 3.61872 1.31417 5.13694C0.685302 6.65517 0.520761 8.32578 0.841356 9.93752C1.16195 11.5493 1.95328 13.0297 3.11528 14.1917C4.27728 15.3537 5.75776 16.1451 7.3695 16.4657C8.98124 16.7863 10.6519 16.6217 12.1701 15.9929C13.6883 15.364 14.986 14.299 15.8989 12.9327C16.8119 11.5663 17.2992 9.95988 17.2992 8.31657C17.2992 7.22544 17.0843 6.14501 16.6667 5.13694C16.2492 4.12888 15.6372 3.21293 14.8656 2.44139C14.0941 1.66985 13.1781 1.05783 12.1701 0.640279C11.162 0.222725 10.0816 0.0078125 8.99046 0.0078125Z"
                fill="currentColor"
            />
            <path
                d="M5.4548 8.36133L12.5303 8.36074"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.00776 4.80767L9.00717 11.8831"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
