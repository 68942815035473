import * as React from "react";

function AccountInfoIconImpl(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.237 7.057a4.234 4.234 0 11-8.465 0"
                stroke="#3E3E48"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.065 15.435a3.5 3.5 0 013.5 3.5v1.076a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-1.076a3.5 3.5 0 013.5-3.5h4zm0-1.5h-4a5 5 0 00-5 5v1.076a2 2 0 002 2h10a2 2 0 002-2v-1.076a5 5 0 00-5-5zM12.005 2.445c.195 0 .39.023.58.068l3.468.827-3.453.846a2.507 2.507 0 01-1.193 0l-3.45-.846 3.468-.827c.19-.045.385-.068.58-.068zm0-1.5c-.313 0-.624.036-.928.11L3.4 2.884a.453.453 0 000 .88l7.66 1.878a4.01 4.01 0 001.906 0l7.66-1.878a.453.453 0 000-.88l-7.683-1.83a3.981 3.981 0 00-.927-.11h-.01z"
                fill="#3E3E48"
            />
            <path
                d="M3.736 3.57v5.112"
                stroke="#3E3E48"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const AccountInfoIcon = React.memo(AccountInfoIconImpl);
export default AccountInfoIcon;
