import React from "react";
import {Image, ImageSourcePropType} from "react-native";
import {View, Text} from "@unibuddy/patron";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";

import MagnifyingGlass from "./images/MagnifyingGlass.png";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export enum PeopleEmptyResultsStrings {
    title = "No results match your filters.",
    description = "Try adjusting or clearing them for more options.",
}

const NoResultContent = ({
    title,
    description,
    image,
}: {
    title?: string;
    description?: string;
    image?: ImageSourcePropType;
}) => {
    return (
        <View margin="small-medium" flexDirection="row" alignItems="center" justifyContent="center">
            <View flexShrink={0}>
                <Image
                    style={{
                        width: 74,
                        height: 83,
                    }}
                    source={image ?? MagnifyingGlass}
                    accessibilityLabel="No results found"
                />
            </View>
            <View margin="xsmall" maxW="75%">
                <Text size="standard" style={{...boldFontStyles}}>
                    {title ?? PeopleEmptyResultsStrings.title}
                </Text>
                <View marginTop="small">
                    <Text size="standard">
                        {description ?? PeopleEmptyResultsStrings.description}
                    </Text>
                </View>
            </View>
        </View>
    );
};
export const PeopleEmptyResults = ({
    title,
    description,
}: {
    title?: string;
    description?: string;
}) => {
    const {isDesktop} = useIsDesktop();
    if (isDesktop) {
        return (
            <View
                borderColor="placeholderColor"
                borderRadius="small-medium"
                borderWidth={1}
                marginTop={"medium"}
            >
                <NoResultContent title={title} description={description} />
            </View>
        );
    }
    return (
        <View
            margin="small-medium"
            borderColor="placeholderColor"
            borderRadius="small-medium"
            borderWidth={1}
        >
            <NoResultContent title={title} description={description} />
        </View>
    );
};
