import {Platform, ScrollView} from "react-native";
import {Stack, Text, View} from "@unibuddy/patron";
import {IconUbInbox} from "@unibuddy/icons";
import {useAnalytics} from "@unibuddy/tracking";

import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {useIsUserBlock} from "ubcommunity-shared/src/Chat/useIsUserBlock";
import {useGetConversationId} from "ubcommunity-shared/src/Conversation/useGetConversationId";
import {UserInfo} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/UserSummaryUserInfo";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {
    ConversationType,
    useConversation,
} from "ubcommunity-shared/src/Conversation/useConversation";
import {ProfileItem} from "ubcommunity-shared/src/Chat/ChatDrawer/UserSummary/ProfileItems";
import {Routes, TrackEvents} from "ubcommunity-shared/src/constants";
import {useNavigate} from "ubcommunity-shared/src/Hooks/navigate";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {LoadingButtonWithIcon} from "ubcommunity-shared/src/Patron/Button/LoadingButtonWithIcon";
import {useGetStaffProfileFields} from "./useGetStaffProfileFields";
import {useCreateConversation} from "ubcommunity-shared/src/Hooks/useCreateConversation";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

type StaffProfileProps = {
    id: string;
};

export enum StaffProfileStrings {
    loading = "Loading Staff ambassador profile",
    loadingFields = "Loading Staff ambassador profile information",
    errorMessage = "Failed to load Staff ambassador profile",
    sendMessage = "Send message",
}

// FIXME If possible lets remove the duplication of the AmbassadorProfile and StaffProfile components

export const StaffProfile = ({id}: StaffProfileProps) => {
    const {data: user, loading, error, refetch} = useGetStaffProfileFields(id);
    const {closeUserSummary} = useOpenUserSummary({});
    const {id: conversationId} = useGetConversationId();
    const {conversationType} = useConversation();
    const [isBlocked] = useIsUserBlock({senderId: id, conversationId});
    const {trackEvent} = useAnalytics();
    const [navigate] = useNavigate();
    const {user: meUser} = useAuth();
    const {
        loading: wLoading,
        error: wError,
        createConversation,
    } = useCreateConversation({senderId: id});

    const isWeb = Platform.OS === "web";
    const isMe = meUser === id;

    const handleDirectMessage = async () => {
        const newConversationId = await createConversation();
        trackEvent(TrackEvents.USER_PROFILE_ACTION_DIRECT_MESSAGE, {
            conversationId: newConversationId,
            selectedUserId: id,
            accountType: user.accountRole,
            groupType: conversationType,
        });

        navigate({
            path: Routes.CHAT,
            options: {
                screen: isWeb ? newConversationId : Routes.CHAT_HOME,
                params: {
                    conversationId: newConversationId,
                },
            },
        });
    };

    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                retryCallback={refetch}
                errorMessage={StaffProfileStrings.errorMessage}
                meta={{
                    component: "StaffProfile",
                    query: "GetStaffProfile",
                }}
            />
        );
    }

    const hideBackButton =
        (conversationType === ConversationType.DIRECT ||
            conversationType === ConversationType.WIDGET) &&
        isWeb;

    const hideMessageButton =
        conversationType === ConversationType.DIRECT ||
        conversationType === ConversationType.WIDGET ||
        isMe;

    return (
        <View flex="1" minH={0} height="100%">
            {hideBackButton ? null : (
                <BackButton paddingBottom="small" callback={closeUserSummary} />
            )}
            <ScrollView>
                <View paddingX="medium" paddingTop="large" flex={1}>
                    <View flex={1} paddingBottom="xsmall" marginBottom="medium">
                        {loading ? (
                            <ActivityIndicator
                                accessibilityLabel={StaffProfileStrings.loading}
                                style={{
                                    // approx height of UserInfo
                                    minHeight: 300,
                                }}
                            />
                        ) : (
                            <Stack space="gutter">
                                <UserInfo user={user} isBlocked={isBlocked} />
                                {hideMessageButton ? null : (
                                    <LoadingButtonWithIcon
                                        color="primary"
                                        onClick={handleDirectMessage}
                                        block
                                        size="xs"
                                        loading={wLoading}
                                        icon={<IconUbInbox color="white" width={18} height={18} />}
                                    >
                                        <Text color="white" size="small">
                                            {StaffProfileStrings.sendMessage}
                                        </Text>
                                    </LoadingButtonWithIcon>
                                )}

                                {wError ? <MutationErrorHandler error={wError} /> : null}
                            </Stack>
                        )}
                    </View>
                    <Stack space="medium">
                        {loading ? (
                            <View flex="1" alignItems="center">
                                <ActivityIndicator
                                    accessibilityLabel={StaffProfileStrings.loadingFields}
                                />
                            </View>
                        ) : (
                            <Stack space="medium">
                                {user?.customAttributes?.map((item, index) => (
                                    <ProfileItem
                                        key={index}
                                        title={item?.attribute?.name}
                                        text={item?.option?.value}
                                    />
                                ))}
                            </Stack>
                        )}

                        {user?.staffFields?.bio ? (
                            <ProfileItem
                                title="About me"
                                text={user?.staffFields?.bio}
                                trimAfter={150}
                            />
                        ) : null}
                    </Stack>
                </View>
            </ScrollView>
        </View>
    );
};
