import {MatchesCarousel, MatchesCarouselProps, MatchesType} from "./MatchesCarousel";
import {
    QueryErrorHandler,
    QueryErrorHandlerProps,
} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";

type ErrorMetaData = Pick<
    QueryErrorHandlerProps,
    "error" | "retryCallback" | "meta" | "errorMessage"
>;
type MatchesSectionProps = MatchesCarouselProps & {
    hasData: boolean;
} & ErrorMetaData;

export const MatchesSection = ({
    openSource,
    viewMore,
    matches,
    handleCreateDirectConversation,
    header,
    hasData,
    error,
    retryCallback,
    meta,
    errorMessage,
    type,
}: MatchesSectionProps) => {
    if (error) {
        return (
            <QueryErrorHandler
                error={error}
                errorMessage={errorMessage}
                retryCallback={retryCallback}
                meta={meta}
                hideRedirectButton
                hideIllustration
            />
        );
    }

    if (hasData || type === MatchesType.Aos) {
        return (
            <MatchesCarousel
                openSource={openSource}
                viewMore={viewMore}
                matches={matches}
                handleCreateDirectConversation={handleCreateDirectConversation}
                header={header}
                type={type}
            />
        );
    }

    return null;
};
