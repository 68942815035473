import React, {
    PropsWithChildren,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import styled from "styled-components";
import {Box} from "@unibuddy/patron";
import "@reach/tabs/styles.css";

import {
    Tabs as RTabs,
    TabList as RTabList,
    Tab as RTab,
    TabPanels as RTabPanels,
    TabPanel as RTabPanel,
} from "@reach/tabs";

type TabContextType = {
    tabIndex: number;
};

const TabContext = createContext<TabContextType>({} as TabContextType);

function useTabContext(): TabContextType {
    return useContext(TabContext);
}

const TabContainer = styled(RTabs)`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
`;

export const TabLink = styled(RTab)`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    max-height: 65px;
`;

export const TabNav = styled(RTabList)`
    &[data-reach-tab-list] {
        background-color: transparent;
    }

    & > [data-reach-tab] {
        align-items: center;
        color: inherit;
        line-height: 1;
        display: flex;
        padding: 1rem 1rem;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-bottom-color: transparent;

        &,
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:hover {
            border-bottom-color: #f1f1f1;
        }

        &[data-selected] {
            font-weight: 600;
            border-color: ${({theme}) => theme.colors?.primaryColor};
            color: ${({theme}) => theme.colors?.primaryColor};
        }
    }
`;

const TabBody = styled(RTabPanels)`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
`;

const TabPanel = styled(RTabPanel)<{$activeTab?: boolean}>`
    display: ${({$activeTab}) => ($activeTab ? "flex" : "none")};
    flex-direction: column;
    flex: 1;
    min-height: "fit-content";
    overflow-y: auto;
`;

export const TabContent = ({children, index}: PropsWithChildren<{index?: number}>) => {
    const {tabIndex} = useTabContext();

    return <TabPanel $activeTab={tabIndex === index}>{children}</TabPanel>;
};

export const Tabs = ({
    children,
    tabs,
    numberOfTabs,
    defaultTabIndex = 0,
    callback,
}: PropsWithChildren<{
    tabs: ReactNode;
    numberOfTabs: number;
    defaultTabIndex?: number;
    callback?: (index: number) => void;
}>) => {
    const [tabIndex, setTabIndex] = useState<number>(defaultTabIndex);

    useEffect(() => {
        setTabIndex(defaultTabIndex);
    }, [numberOfTabs, defaultTabIndex]);

    const handleOnChange = (index: number) => {
        setTabIndex(index);
        callback(index);
    };

    return (
        <TabContext.Provider value={{tabIndex}}>
            <TabContainer onChange={handleOnChange} index={tabIndex}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    borderBottomWidth={1}
                    borderColor="#F0F2F2"
                >
                    {tabs}
                </Box>
                <TabBody>
                    {React.Children.map(children, (child, index) => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, {index});
                        }
                        return child;
                    })}
                </TabBody>
            </TabContainer>
        </TabContext.Provider>
    );
};
