import React, {useEffect, useMemo, useRef, useState} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Stack, Text, View} from "@unibuddy/patron";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import {OptionGroup, OptionItem} from "ubcommunity-shared/src/Patron/OptionGroup/OptionGroupv2";

import {usePoll} from "./usePoll";
import {PollOption} from "./PollOption";
import {PollProps} from "./types";

export const Poll = ({poll, messageId}: PollProps) => {
    const {user} = useAuth();
    const {darkModeEnabled} = useCommunityTheme();
    const {trackEvent} = useAnalytics();

    // compute initial selected options based on the provided poll options.
    const initialSelected = useMemo(
        () =>
            new Set(
                poll.options?.reduce<string[]>((acc, option) => {
                    if (option.votes?.includes(user.id)) {
                        acc.push(option.option);
                    }
                    return acc;
                }, []),
            ),
        [poll.options, user.id],
    );
    // local state to hold current selected options, initialized with initialSelected.
    // and synced with the OptionGroup.
    const [selected, setSelected] = useState<Set<string>>(initialSelected);
    // ref to hold previous selected options
    const prevSelectedRef = useRef<Set<string>>(new Set());

    const {handleAdd, handleRemove} = usePoll(messageId);

    const pollCountDict = poll.options?.reduce<{[key: string]: number}>((acc, option) => {
        acc[option.option] = option.votes ? option.votes.length : 0;
        return acc;
    }, {});

    const totalPollCount = poll.options?.reduce((acc, option) => {
        acc += option.votes ? option.votes.length : 0;
        return acc;
    }, 0);

    // set prevSelectedRef to initialSelected on initial render.
    // this is to avoid unnecessary add and remove mutations on initial render.
    useEffect(() => {
        prevSelectedRef.current = initialSelected;
    }, [initialSelected]);

    // effect for handling add and remove mutations based on the selected state.
    useEffect(() => {
        const prevSelected = prevSelectedRef.current;

        if (poll.allowMultipleAnswers) {
            selected.forEach((option) => {
                // an option is in selected but not in prevSelected, handle its addition.
                if (!prevSelected.has(option)) {
                    handleAdd(option);
                    trackEvent(TrackEvents.POLL_ANSWER_CLICKED);
                }
            });
            prevSelected.forEach((option) => {
                // an option is in prevSelected but not in selected, handle its removal.
                if (!selected.has(option)) {
                    handleRemove(option);
                }
            });
        } else {
            const lastSelected = Array.from(selected)[selected.size - 1];
            prevSelectedRef.current.forEach((option) => {
                if (option !== lastSelected) {
                    // option is not the last selected one, handle its removal.
                    handleRemove(option);
                }
            });
            // lastSelected is not in prevSelected, handle its addition.
            if (lastSelected && !prevSelectedRef.current.has(lastSelected)) {
                handleAdd(lastSelected);
                trackEvent(TrackEvents.POLL_ANSWER_CLICKED);
            }
        }
        // update prevSelectedRef for future comparisons in subsequent renders.
        prevSelectedRef.current = selected;
    }, [selected, poll.allowMultipleAnswers, handleAdd, handleRemove]);

    return (
        <View minW={180}>
            <Stack space="medium">
                <Text style={{...boldFontStyles}}>{poll.question}</Text>
                <Text color={darkModeEnabled ? "grey150" : "grey600"} size="small">
                    {poll.allowMultipleAnswers ? "Select one or more" : "Select one"}
                </Text>
                <Stack space="xsmall">
                    <View>
                        <OptionGroup
                            value={Array.from(selected)}
                            onChange={(value: string[]) => {
                                if (poll.allowMultipleAnswers) {
                                    setSelected(new Set(value));
                                } else {
                                    const lastSelected = value[value.length - 1];
                                    setSelected(new Set([lastSelected]));
                                }
                            }}
                            multiple
                        >
                            <Stack space="xsmall">
                                {poll.options?.map((option, index) => (
                                    <OptionItem
                                        value={option.option}
                                        key={`poll-option-${option.option}-${index}`}
                                    >
                                        <PollOption
                                            totalCount={totalPollCount}
                                            pollCount={pollCountDict[option.option]}
                                            maxValue={Math.max(...Object.values(pollCountDict))}
                                            option={option}
                                            multiple={poll.allowMultipleAnswers}
                                        />
                                    </OptionItem>
                                ))}
                            </Stack>
                        </OptionGroup>
                    </View>
                </Stack>
                <Text
                    size="small"
                    align="right"
                    color={darkModeEnabled ? "grey150" : "textColor"}
                    weight="400"
                >
                    {totalPollCount} {totalPollCount === 1 ? "vote" : "votes"}
                </Text>
            </Stack>
        </View>
    );
};
