import React, {cloneElement} from "react";
import {useAnalytics} from "@unibuddy/tracking";
import {Box, ButtonLink, Inline, Text, View} from "@unibuddy/patron";

import ChevronDownIcon from "ubcommunity-shared/src/Icons/ChevronDownIcon";
import RelativeLink from "ubcommunity-shared/src/Navigation/RelativeLink/RelativeLink";

import {LabelProps, mapSettingsEvents} from "./SettingsLinkTracking";

export const SettingsLink = ({
    label,
    icon,
    ...props
}: {
    label: LabelProps;
    icon: React.ReactElement;
}) => {
    const {trackEvent} = useAnalytics();

    return (
        <ButtonLink
            block
            color="light"
            clear
            as={RelativeLink}
            onClick={() => {
                trackEvent(mapSettingsEvents[label]);
            }}
            {...props}
        >
            <Box w="100%">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Inline
                        space={["medium", "small", "small", "medium"]}
                        verticalAlign="center"
                        wrap="nowrap"
                    >
                        {cloneElement(icon, {
                            width: "28px",
                            height: "28px",
                            size: 28,
                            color: "#424242",
                            style: {margin: 0, fontSize: 28},
                        })}
                        <Text size="large" weight="600">
                            {label}
                        </Text>
                    </Inline>
                    <View flexShrink={0}>
                        <ChevronDownIcon
                            color="#424242"
                            size={16}
                            width={16}
                            height={16}
                            style={{transform: "rotate(-90deg)"}}
                        />
                    </View>
                </Box>
            </Box>
        </ButtonLink>
    );
};
