import React from "react";
import {Stack, Text, View, colors} from "@unibuddy/patron";
import {IconUbHousingSearch, IconUbHousingComplete} from "@unibuddy/icons";

import {HousingCategory, HousingInput} from "ubcommunity-shared/src/types";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export const HousingStatus = ({housing}: {housing: HousingInput}) => {
    return (
        <Stack space="small">
            <Text
                size="small"
                // @ts-ignore valid in RN
                style={{...boldFontStyles}}
            >
                My student housing
            </Text>
            <View width="full" gap="xsmall" flexDirection="row" alignItems="center">
                <View
                    width={32}
                    height={32}
                    flexShrink="0"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor={
                        housing.category === HousingCategory.University ? "green100" : "orange100"
                    }
                    borderRadius="full"
                >
                    {housing.category === HousingCategory.University ? (
                        <IconUbHousingComplete color={colors.green900} />
                    ) : (
                        <IconUbHousingSearch color={colors.orange800} />
                    )}
                </View>
                <View flex="1">
                    <Text>
                        {housing.category === HousingCategory.University
                            ? housing.name
                            : "Looking for private housing"}
                    </Text>
                </View>
            </View>
        </Stack>
    );
};
