import React, {useEffect} from "react";
import {Switch as NativeSwitch, Platform} from "react-native";
import {View, Text, Columns, Column, Stack, colors, TextInput, Switch, Box} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {IconSparkles} from "@unibuddy/icons";
import {HousingSelectorProps} from "./HousingSelector";
import {boldFontStyles, semiBoldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {HousingCategory} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {usePeopleFilters} from "../../PeopleFiltersProvider";

const PlatformSwitch = ({...props}) => {
    if (Platform.OS === "web") {
        return <Switch {...props} />;
    }
    return <NativeSwitch {...props} />;
};

export enum HousingFilterStrings {
    PRIVATE_HOUSING = "Private housing",
    LOOKING_FOR_PRIVATE_HOUSING = "Students looking for private housing",
    UNIVERSITY_HOUSING = "University housing",
    ASSIGNED_UNIVERSITY_HOUSING = "Students who have been assigned university housing",
    FIND_STUDENTS = "Find students who are in the same university housing as you by typing the name below",
    NAME_OF_UNIVERSITY_HOUSING = "Name of university housing",
    SEARCH_WITH_UNIVERSITY_HOUSING_NAME = "Search with university housing name",
}

export const HousingFilter = ({scrollFilterListToEnd}: HousingSelectorProps) => {
    const {filters, setFilters} = usePeopleFilters();
    const {trackEvent} = useAnalytics();

    const isUniversityHousingSelected = filters?.housingTypes?.some(
        (housingType) => housingType.category === HousingCategory.University,
    );
    const isPrivateHousingSelected = filters?.housingTypes?.some(
        (housingType) => housingType.category === HousingCategory.Private,
    );
    const universityHousingName = filters?.housingTypes?.find(
        (housingType) => housingType.category === HousingCategory.University,
    )?.name;

    useEffect(() => {
        if (isUniversityHousingSelected) {
            scrollFilterListToEnd?.();
        }
    }, [isUniversityHousingSelected, scrollFilterListToEnd]);

    const handlePrivateHousingChange = () => {
        // when the toggle changes if private housing already exists in the filters array
        // remove it, otherwise add it and in both cases track the appropriate change
        trackEvent(
            isPrivateHousingSelected
                ? TrackEvents.PEOPLE_PRIVATE_HOUSING_FILTER_DESELECTED
                : TrackEvents.PEOPLE_PRIVATE_HOUSING_FILTER_SELECTED,
        );
        setFilters({
            ...filters,
            housingTypes: isPrivateHousingSelected
                ? filters?.housingTypes?.filter(
                      (housingType) => housingType.category !== HousingCategory.Private,
                  )
                : [...(filters?.housingTypes ?? []), {category: HousingCategory.Private}],
        });
    };

    const handleUniversityHousingChange = () => {
        // when the toggle changes if university housing already exists in the filters array
        // remove it, otherwise add it and in both cases track the appropriate change
        trackEvent(
            isUniversityHousingSelected
                ? TrackEvents.PEOPLE_UNIVERSITY_HOUSING_FILTER_DESELECTED
                : TrackEvents.PEOPLE_UNIVERSITY_HOUSING_FILTER_SELECTED,
        );
        setFilters({
            ...filters,
            housingTypes: isUniversityHousingSelected
                ? filters?.housingTypes?.filter(
                      (housingType) => housingType.category !== HousingCategory.University,
                  )
                : [...(filters?.housingTypes ?? []), {category: HousingCategory.University}],
        });
    };

    const handleSearchChange = ({searchValue}: {searchValue: string}) => {
        if (!isUniversityHousingSelected) return;

        setFilters({
            ...filters,
            housingTypes: filters?.housingTypes?.map((housingType) =>
                housingType.category === HousingCategory.University
                    ? {...housingType, name: searchValue}
                    : housingType,
            ),
        });
    };

    return (
        <View flexGrow={1}>
            <Stack space="large">
                <View flex="1" gap="xsmall">
                    <Box
                        as={Platform.OS === "web" ? "label" : Box}
                        // @ts-ignore
                        htmlFor="private-housing-switch"
                    >
                        <Text
                            // @ts-ignore
                            style={{...boldFontStyles}}
                        >
                            {HousingFilterStrings.PRIVATE_HOUSING}{" "}
                        </Text>
                    </Box>
                    <Columns verticalAlign="center" space="xsmall">
                        <Column>
                            <Text size="small">
                                {HousingFilterStrings.LOOKING_FOR_PRIVATE_HOUSING}
                            </Text>
                        </Column>
                        <Column width="content">
                            <PlatformSwitch
                                id="private-housing-switch"
                                checked={isPrivateHousingSelected}
                                onChange={handlePrivateHousingChange}
                                value={isPrivateHousingSelected}
                                onValueChange={handlePrivateHousingChange}
                                accessibilityLabel={`Toggle to filter ${HousingFilterStrings.LOOKING_FOR_PRIVATE_HOUSING}`}
                            />
                        </Column>
                    </Columns>
                </View>
                <View flex="1" gap="small">
                    <Box
                        as={Platform.OS === "web" ? "label" : Box}
                        // @ts-ignore
                        htmlFor="university-housing-switch"
                    >
                        <Text
                            // @ts-ignore
                            style={{...boldFontStyles}}
                        >
                            {HousingFilterStrings.UNIVERSITY_HOUSING}
                        </Text>
                    </Box>
                    <Columns verticalAlign="center" space="xsmall">
                        <Column>
                            <Text size="small">
                                {HousingFilterStrings.ASSIGNED_UNIVERSITY_HOUSING}
                            </Text>
                        </Column>
                        <Column width="content">
                            <PlatformSwitch
                                id="university-housing-switch"
                                checked={isUniversityHousingSelected}
                                onChange={handleUniversityHousingChange}
                                value={isUniversityHousingSelected}
                                onValueChange={handleUniversityHousingChange}
                                accessibilityLabel={`Toggle to filter ${HousingFilterStrings.ASSIGNED_UNIVERSITY_HOUSING}`}
                            />
                        </Column>
                    </Columns>
                </View>
                {isUniversityHousingSelected ? (
                    <Stack space="medium">
                        <View
                            flexDirection="row"
                            gap="xsmall"
                            width="full"
                            backgroundColor="blue50"
                            borderRadius="xsmall"
                            padding="small"
                        >
                            <View flexShrink="0">
                                <IconSparkles color={colors.blue800} />
                            </View>
                            <View flex="1" paddingY="xxsmall">
                                <Text
                                    color="blue800"
                                    size="small"
                                    // @ts-ignore
                                    style={{...semiBoldFontStyles}}
                                >
                                    {HousingFilterStrings.FIND_STUDENTS}
                                </Text>
                            </View>
                        </View>
                        <Stack space="small">
                            <Text
                                // @ts-ignore
                                style={{...boldFontStyles}}
                            >
                                {HousingFilterStrings.NAME_OF_UNIVERSITY_HOUSING}
                            </Text>
                            <TextInput
                                style={{
                                    borderRadius: 4,
                                    paddingLeft: 13,
                                    width: "100%",
                                    height: 50,
                                }}
                                onChangeText={(searchValue) => handleSearchChange({searchValue})}
                                value={universityHousingName || ""}
                                placeholder="E.g. Bailey Halls of Residence"
                                aria-label={
                                    HousingFilterStrings.SEARCH_WITH_UNIVERSITY_HOUSING_NAME
                                }
                            />
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
        </View>
    );
};
