import React from "react";

export const SquareGrid = () => {
    return (
        <svg
            width="275"
            height="276"
            viewBox="0 0 275 276"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M127.113 0H110.89V16.5185H127.113V0Z" fill="#CFC8FF" />
            <path d="M127.113 37.0835H110.89V53.602H127.113V37.0835Z" fill="#CFC8FF" />
            <path d="M127.113 74.1411H110.89V90.6597H127.113V74.1411Z" fill="#CFC8FF" />
            <path d="M127.113 111.199H110.89V127.718H127.113V111.199Z" fill="#CFC8FF" />
            <path d="M127.113 148.282H110.89V164.801H127.113V148.282Z" fill="#CFC8FF" />
            <path d="M127.113 185.34H110.89V201.859H127.113V185.34Z" fill="#CFC8FF" />
            <path d="M127.113 222.424H110.89V238.942H127.113V222.424Z" fill="#CFC8FF" />
            <path d="M127.113 259.481H110.89V276H127.113V259.481Z" fill="#CFC8FF" />
            <path d="M53.169 0H36.9467V16.5185H53.169V0Z" fill="#CFC8FF" />
            <path d="M53.169 37.0835H36.9467V53.602H53.169V37.0835Z" fill="#CFC8FF" />
            <path d="M53.169 74.1411H36.9467V90.6597H53.169V74.1411Z" fill="#CFC8FF" />
            <path d="M53.169 111.199H36.9467V127.718H53.169V111.199Z" fill="#CFC8FF" />
            <path d="M53.169 148.282H36.9467V164.801H53.169V148.282Z" fill="#CFC8FF" />
            <path d="M53.169 185.34H36.9467V201.859H53.169V185.34Z" fill="#CFC8FF" />
            <path d="M53.169 222.424H36.9467V238.942H53.169V222.424Z" fill="#CFC8FF" />
            <path d="M53.169 259.481H36.9467V276H53.169V259.481Z" fill="#CFC8FF" />
            <path d="M201.056 0H184.834V16.5185H201.056V0Z" fill="#CFC8FF" />
            <path d="M201.056 37.0835H184.834V53.602H201.056V37.0835Z" fill="#CFC8FF" />
            <path d="M201.056 74.1411H184.834V90.6597H201.056V74.1411Z" fill="#CFC8FF" />
            <path d="M201.056 111.199H184.834V127.718H201.056V111.199Z" fill="#CFC8FF" />
            <path d="M201.056 148.282H184.834V164.801H201.056V148.282Z" fill="#CFC8FF" />
            <path d="M201.056 185.34H184.834V201.859H201.056V185.34Z" fill="#CFC8FF" />
            <path d="M201.056 222.424H184.834V238.942H201.056V222.424Z" fill="#CFC8FF" />
            <path d="M201.056 259.481H184.834V276H201.056V259.481Z" fill="#CFC8FF" />
            <path d="M16.2223 0H0V16.5185H16.2223V0Z" fill="#CFC8FF" />
            <path d="M16.2223 37.0835H0V53.602H16.2223V37.0835Z" fill="#CFC8FF" />
            <path d="M16.2223 74.1411H0V90.6597H16.2223V74.1411Z" fill="#CFC8FF" />
            <path d="M16.2223 111.225H0V127.743H16.2223V111.225Z" fill="#CFC8FF" />
            <path d="M16.2223 148.282H0V164.801H16.2223V148.282Z" fill="#CFC8FF" />
            <path d="M16.2223 185.34H0V201.859H16.2223V185.34Z" fill="#CFC8FF" />
            <path d="M16.2223 222.424H0V238.942H16.2223V222.424Z" fill="#CFC8FF" />
            <path d="M16.2223 259.481H0V276H16.2223V259.481Z" fill="#CFC8FF" />
            <path d="M164.084 0H147.862V16.5185H164.084V0Z" fill="#CFC8FF" />
            <path d="M164.084 37.0835H147.862V53.602H164.084V37.0835Z" fill="#CFC8FF" />
            <path d="M164.084 74.1411H147.862V90.6597H164.084V74.1411Z" fill="#CFC8FF" />
            <path d="M164.084 111.199H147.862V127.718H164.084V111.199Z" fill="#CFC8FF" />
            <path d="M164.084 148.282H147.862V164.801H164.084V148.282Z" fill="#CFC8FF" />
            <path d="M164.084 185.34H147.862V201.859H164.084V185.34Z" fill="#CFC8FF" />
            <path d="M164.084 222.424H147.862V238.942H164.084V222.424Z" fill="#CFC8FF" />
            <path d="M164.084 259.481H147.862V276H164.084V259.481Z" fill="#CFC8FF" />
            <path d="M90.1408 0H73.9185V16.5185H90.1408V0Z" fill="#CFC8FF" />
            <path d="M90.1408 37.0835H73.9185V53.602H90.1408V37.0835Z" fill="#CFC8FF" />
            <path d="M90.1408 74.1411H73.9185V90.6597H90.1408V74.1411Z" fill="#CFC8FF" />
            <path d="M90.1408 111.199H73.9185V127.718H90.1408V111.199Z" fill="#CFC8FF" />
            <path d="M90.1408 148.282H73.9185V164.801H90.1408V148.282Z" fill="#CFC8FF" />
            <path d="M90.1408 185.34H73.9185V201.859H90.1408V185.34Z" fill="#CFC8FF" />
            <path d="M90.1408 222.424H73.9185V238.942H90.1408V222.424Z" fill="#CFC8FF" />
            <path d="M90.1408 259.481H73.9185V276H90.1408V259.481Z" fill="#CFC8FF" />
            <path d="M238.028 0H221.806V16.5185H238.028V0Z" fill="#CFC8FF" />
            <path d="M275 0H258.778V16.5185H275V0Z" fill="#CFC8FF" />
            <path d="M238.028 37.0835H221.806V53.602H238.028V37.0835Z" fill="#CFC8FF" />
            <path d="M275 37.0835H258.778V53.602H275V37.0835Z" fill="#CFC8FF" />
            <path d="M238.028 74.1411H221.806V90.6597H238.028V74.1411Z" fill="#CFC8FF" />
            <path d="M275 74.1411H258.778V90.6597H275V74.1411Z" fill="#CFC8FF" />
            <path d="M238.028 111.199H221.806V127.718H238.028V111.199Z" fill="#CFC8FF" />
            <path d="M275 111.225H258.778V127.743H275V111.225Z" fill="#CFC8FF" />
            <path d="M238.028 148.282H221.806V164.801H238.028V148.282Z" fill="#CFC8FF" />
            <path d="M275 148.282H258.778V164.801H275V148.282Z" fill="#CFC8FF" />
            <path d="M238.028 185.34H221.806V201.859H238.028V185.34Z" fill="#CFC8FF" />
            <path d="M275 185.34H258.778V201.859H275V185.34Z" fill="#CFC8FF" />
            <path d="M238.028 222.424H221.806V238.942H238.028V222.424Z" fill="#CFC8FF" />
            <path d="M238.028 259.481H221.806V276H238.028V259.481Z" fill="#CFC8FF" />
            <path d="M275 222.424H258.778V238.942H275V222.424Z" fill="#CFC8FF" />
            <path d="M275 259.481H258.778V276H275V259.481Z" fill="#CFC8FF" />
        </svg>
    );
};
