import {Text, View, usePatronTheme} from "@unibuddy/patron";
import React, {memo} from "react";
import {useIsTyping} from "./useIsTyping";

interface Props {
    conversationId: string;
}

export const IsTyping = memo(({conversationId}: Props) => {
    const theme = usePatronTheme();
    const {isTyping, typingMessage} = useIsTyping({
        conversationId,
    });

    return (
        <View
            as="section"
            aria-live="polite"
            aria-hidden={!isTyping}
            position="absolute"
            bottom={"calc(100% + 1px)"}
            right={theme.space.medium}
            zIndex={100}
            opacity={isTyping ? 1 : 0}
            pointerEvents="none"
            transition=".3s ease all"
            backgroundColor="white"
            padding="xsmall"
            shadow="large"
            style={{
                borderTopLeftRadius: theme.space.small,
                borderTopRightRadius: theme.space.small,
            }}
        >
            <Text size="xsmall">{typingMessage}</Text>
        </View>
    );
});
