import * as React from "react";
import {SVGProps} from "react";

const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", ...rest} = props;
    return (
        <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M10.465 1.5a8.5 8.5 0 1 1-8.5 8.5 8.51 8.51 0 0 1 8.5-8.5Zm0-1.5a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z"
                fill={color}
            />
            <path
                d="M10.465 15.978a.75.75 0 0 1-.75-.75V9.206a.75.75 0 0 1 1.5 0v6.022a.75.75 0 0 1-.75.75ZM10.465 6.771a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                fill={color}
            />
        </svg>
    );
};

export default InfoIcon;
