import React, {FC, PropsWithChildren, memo, useCallback, useMemo} from "react";
import {LayoutAnimation, Platform} from "react-native";
import {NetworkStatus} from "@apollo/client";
import {Stack, Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";

import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {usePrompt} from "ubcommunity-shared/src/General/usePrompt/usePrompt";
import {Image as IImagePreview} from "ubcommunity-shared/src/types";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {QueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";

import {ChatList} from "../ChatList";
import {MixpanelMetadataProps} from "../ChatDrawer/UserSummary/UserSummary";
import {useReaction} from "../Reactions/useReaction";
import {Attachment, ChatMessage} from "../../types";
import {GroupTypes, useChat} from "../useChat";
import {usePin} from "../usePin";
import {ChatShimmer} from "./ChatShimmer";
import {withProfiler} from "ubcommunity-shared/src/Utils/Telemetry/withProfiler";

const ChatWrapper: FC<PropsWithChildren> = ({children}) => (
    <View
        bgColor="body"
        flex="1"
        position="relative"
        alignItems="center"
        justifyContent="center"
        padding={["medium", "medium", "xlarge", "xlarge"]}
    >
        {children}
    </View>
);

interface Props {
    conversationId: string;
    onBlockOrReport(params: {
        message: ChatMessage;
        conversationId: string;
        type: "block" | "report";
    }): void;
    onShowUserSummary: (senderId: string, mixpanelMetadata?: MixpanelMetadataProps) => void;
    onSpotlight: (images: IImagePreview[], selectedImage: IImagePreview) => void;
    onPreview: (file: Attachment) => void;
    onUserBlock: (params: {senderId: string; type: "block" | "unblock"}) => void;
    isMember: boolean;
    membersLoading: boolean;
}

const adminAccountRoles = ["admin", "university", "mentor", "staff"];

export const ChatData: FC<Props> = memo(
    ({
        conversationId,
        onBlockOrReport,
        onShowUserSummary,
        onSpotlight,
        onPreview,
        onUserBlock,
        isMember,
        membersLoading,
    }) => {
        const {user} = useAuth();
        const {trackEvent} = useAnalytics();
        const {university} = useCommunity();
        const {open} = usePrompt();
        const {onPin, pinnedMessageIds} = usePin(conversationId);
        const {
            error,
            messages: queryMessages,
            onSend,
            hasMore,
            fetchMore,
            networkStatus,
            onDelete,
            like,
            unlike,
            groupType,
            refetch,
        } = useChat(conversationId, user);
        const {addReactionToMessage, removeReactionFromMessage} = useReaction({
            conversationId,
            user,
        });

        const messages = useMemo(
            () =>
                Platform.select({
                    web: queryMessages,
                    native: [...queryMessages].reverse(),
                }),
            [queryMessages],
        );
        const handleBlockOrReport = useCallback(
            (message, type) => {
                onBlockOrReport({message, conversationId: conversationId, type});
            },
            [conversationId, onBlockOrReport],
        );
        const handleOnShowUserSummary = useCallback(
            (senderId) => {
                trackEvent(TrackEvents.CLICK_VIEW_USER_PROFILE, {
                    conversationId: conversationId,
                    senderId: senderId,
                    userId: user.id,
                    groupType,
                });
                onShowUserSummary(senderId);
            },
            [onShowUserSummary, conversationId, groupType, user.id, trackEvent],
        );
        const handleDelete = useCallback(
            (message) => {
                open({
                    title: "Delete message?",
                    message: message.text
                        ? message.text.length > 90
                            ? `"${message.text.slice(0, 90)}..."`
                            : `"${message.text}"`
                        : message.richContent?.giphy?.type || "Media",
                    buttons: [
                        {
                            text: "Delete",
                            style: "destructive",
                            onPress: () => {
                                LayoutAnimation.easeInEaseOut();
                                onDelete(message);
                            },
                        },
                        {text: "Cancel"},
                    ],
                });
            },
            [onDelete, open],
        );

        const handleLike = useCallback(
            (message) => {
                LayoutAnimation.easeInEaseOut();
                like({message, conversationId, userId: user.id});
            },
            [conversationId, like, user.id],
        );

        const handleUnlike = useCallback(
            (message) => {
                LayoutAnimation.easeInEaseOut();
                unlike({message, conversationId, userId: user.id});
            },
            [conversationId, unlike, user.id],
        );

        const handleReacted = useCallback(
            (message, reaction, action) => {
                if (action === "reacted") {
                    addReactionToMessage(message, reaction);
                } else {
                    removeReactionFromMessage(message, reaction);
                }
            },
            [addReactionToMessage, removeReactionFromMessage],
        );

        const fetchMoreMessages = useCallback(async () => {
            if (!hasMore) return;

            return fetchMore({
                variables: {
                    getMessageDto: {
                        conversationId,
                        limit: 30,
                        offsetId: Platform.select({
                            web: messages[0].id,
                            native: messages[messages.length - 1].id,
                        }),
                    },
                },
            });
        }, [conversationId, fetchMore, hasMore, messages]);

        if ((!messages.length && networkStatus === NetworkStatus.loading) || membersLoading) {
            return (
                <View role="progressbar" aria-label="Loading conversation" flex={1}>
                    <ChatShimmer />
                </View>
            );
        }

        if (error?.message.includes("User is not authorised")) {
            return (
                <ChatWrapper>
                    <Stack space="medium">
                        <Text align="center">
                            Sorry, you don't currently have access to this group. Use the explore
                            page to discover and join groups.
                        </Text>
                    </Stack>
                </ChatWrapper>
            );
        }

        if (!messages.length && error) {
            return (
                <ChatWrapper>
                    <QueryErrorHandler
                        error={error}
                        retryCallback={refetch}
                        meta={{component: "ChatData", query: "useCommunityChatMessagesQuery"}}
                        layout="center"
                    />
                </ChatWrapper>
            );
        }

        return (
            <ChatList
                conversationId={conversationId}
                hasAdminPrivileges={
                    adminAccountRoles.includes(user.accountRole) &&
                    university?.id === user?.university?.id
                }
                key={conversationId}
                fetchMore={fetchMoreMessages}
                hasMore={hasMore}
                loading={
                    networkStatus === NetworkStatus.fetchMore ||
                    networkStatus === NetworkStatus.setVariables
                }
                messages={messages}
                //Message related props can be converted to a context
                onSend={onSend}
                onDelete={handleDelete}
                onLike={handleLike}
                onUnlike={handleUnlike}
                canPin={
                    !(groupType === GroupTypes.PRIVATE) &&
                    adminAccountRoles.includes(user.accountRole)
                }
                onPin={onPin}
                pinnedMessageIds={pinnedMessageIds}
                onReacted={handleReacted}
                // User Related
                userId={user.id}
                user={user}
                onShowUserSummary={handleOnShowUserSummary}
                onBlockOrReport={handleBlockOrReport}
                groupType={groupType}
                onSpotlight={onSpotlight}
                onPreview={onPreview}
                onUserBlock={onUserBlock}
                isMember={isMember}
            />
        );
    },
);

export const ProfiledChatData = withProfiler(ChatData, {name: "ChatData"});
