import React from "react";
import {View, Stack, Heading, Columns, Column, Box, Text, TextLine} from "@unibuddy/patron";
import {useChatTheme} from "@unibuddy/chat-ui";
import {useCommunityTheme} from "../Theme/CommunityThemeProvider";
import {useIsDesktop} from "../General/useIsDesktop/useIsDesktop";
import AddToGroupIcon from "../Icons/AddToGroupIcon";
import CopyLinkIcon from "../Icons/CopyLinkIcon";

const PrivateGroupEmptyState = () => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = useChatTheme();
    const {isDesktop} = useIsDesktop();

    return (
        <View flex="1" justifyContent="flex-end" paddingX="large" paddingTop="large">
            <View
                backgroundColor={
                    darkModeEnabled ? theme?.chat?.message?.altBackgroundColor : "#fff"
                }
                borderRadius="xsmall"
                padding="medium"
            >
                <Stack space="large">
                    <Heading level="5" align="center">
                        Yay, a new private group!
                    </Heading>
                    <Text weight="600">To add people into this group you can:</Text>
                    <Stack space="medium">
                        <Columns space="xxsmall">
                            <Column width="content">
                                <CopyLinkIcon
                                    color={darkModeEnabled ? "#fff" : theme?.colors?.darkGrey}
                                />
                            </Column>
                            <Column>
                                <Box paddingTop="xxsmall">
                                    {isDesktop ? (
                                        <Text>
                                            Invite people via a shareable invite link (this can be
                                            found in the side bar on the right)
                                        </Text>
                                    ) : (
                                        <Text>
                                            Invite people via a shareable invite link (just hit the
                                            three dots in the top right hand corner)
                                        </Text>
                                    )}
                                </Box>
                            </Column>
                        </Columns>
                        <Columns space="xsmall">
                            <Column width="content"></Column>
                            <AddToGroupIcon
                                color={darkModeEnabled ? "#fff" : theme?.colors?.darkGrey}
                            />
                            <Column>
                                <Box paddingTop="xxsmall">
                                    <Text>
                                        Add people into the group by clicking on their profile
                                        picture and selecting{" "}
                                        <TextLine weight="700">Add to private group</TextLine>
                                    </Text>
                                </Box>
                            </Column>
                        </Columns>
                    </Stack>
                </Stack>
            </View>
        </View>
    );
};

export default PrivateGroupEmptyState;
