import {useEffect} from "react";
import {useGetCommunitiesQuery} from "../types";
import useAuth from "../Auth/hooks/useAuth";
import {useConversation} from "../Conversation/useConversation";
import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";

const useSetActiveCommunity = () => {
    const {user} = useAuth();
    const {select, selectedCommunity} = useCommunity();
    const {data} = useConversation();

    const {data: communities} = useGetCommunitiesQuery({
        fetchPolicy: "cache-first",
        variables: {
            userId: user?.id ?? "",
        },
    });

    const currentCommunity = communities?.getUser?.communities?.find(
        (community) => community.id === data?.getChatConversation.communityId,
    );

    useEffect(() => {
        if (!data?.getChatConversation) return;
        if (selectedCommunity?.id !== currentCommunity?.id) {
            const commonFields = {
                archived: false,
                requestedPublicGroups: 0,
                inviteLinks: [],
                communityChatGroups: [],
            };
            if (currentCommunity) {
                select({
                    ...currentCommunity,
                    ...commonFields,
                });
            } else {
                select(undefined);
            }
        }
    }, [currentCommunity, select, selectedCommunity?.id, data?.getChatConversation]);
};

export default useSetActiveCommunity;
