import useMyId from "./useMyId";

/**
 * The purpose of this hook is to find the opponent user object
 * from an array of two user objects.
 *
 * @export
 * @returns opponent user object
 */

export default function useFindSenderInPair<T extends {id?: string | null}>() {
    const userId = useMyId();

    const findSenderInPair = (chatMembers: T[] = []) => {
        if (chatMembers.length !== 2) return undefined;
        return chatMembers
            .filter((member) => member.id != null)
            .find((member) => userId !== member.id);
    };

    return {findSenderInPair};
}
