import {usePatronTheme, Box, View, Text, SubmitButton} from "@unibuddy/patron";
import React from "react";
import {Platform} from "react-native";

import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

type CTAFooterProps = {
    footerText: string;
    buttonText: string;
    isDisabled?: boolean;
};

export const CTAFooter = ({footerText, buttonText, isDisabled = false}: CTAFooterProps) => {
    const theme = usePatronTheme();
    const isWeb = Platform.OS === "web";
    const {darkModeEnabled} = useCommunityTheme();
    return (
        <>
            <Box position="relative" w="100%">
                <Box
                    position="absolute"
                    width="100%"
                    height={1}
                    bgColor={darkModeEnabled ? "darkGrey" : "gray88"}
                />
            </Box>
            <View
                width="100%"
                marginY="small"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="medium"
                paddingRight="medium"
            >
                <Text size="small">{footerText}</Text>
                <SubmitButton
                    size="sm"
                    clear
                    color={isWeb ? "primary" : darkModeEnabled ? "primary" : "default"}
                    style={{
                        borderColor: darkModeEnabled
                            ? theme.colors.textLinkColor
                            : theme.colors.primaryColor,
                        opacity: isDisabled ? 0.4 : 1,
                    }}
                    disabled={isDisabled}
                >
                    <Text
                        style={{
                            ...boldFontStyles,
                            color: darkModeEnabled
                                ? theme.colors.textLinkColor
                                : theme.colors.primaryColor,
                        }}
                    >
                        {buttonText}
                    </Text>
                </SubmitButton>
            </View>
        </>
    );
};
