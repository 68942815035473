import React from "react";
import {useIsUserBlock} from "ubcommunity-shared/src/Chat/useIsUserBlock";
import BlockIcon from "ubcommunity-shared/src/Icons/BlockIcon";
import {ChatConversation, PublicMentorField} from "ubcommunity-shared/src/types";
import {useOpenUserSummary} from "ubcommunity-shared/src/Hooks/useOpenUserSummary";
import {UserSummaryActionButton} from "../UserSummaryActionButton";
import {UserSummaryActionsStrings} from "../UserSummaryActions";

type Props = {
    id: PublicMentorField["id"];
    conversationId?: ChatConversation["id"];
};

export const AmbassadorProfileActions = ({id, conversationId}: Props) => {
    const [isBlocked] = useIsUserBlock({senderId: id, conversationId: conversationId});
    const {handleBlock} = useOpenUserSummary({});

    const handleBlockUser = async () => {
        if (!id) return;
        handleBlock(id);
    };

    return (
        <UserSummaryActionButton
            title={
                isBlocked
                    ? UserSummaryActionsStrings.unblockUser
                    : UserSummaryActionsStrings.blockUser
            }
            onClick={handleBlockUser}
            Icon={BlockIcon}
            disabled={false}
        />
    );
};
