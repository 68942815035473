import React, {memo, ReactNode} from "react";
import {ChatScroll} from "@unibuddy/chat-ui";
import {ChatMessage} from "../types";

export const MessageListImpl = ({
    messages,
    renderMessage,
    loading,
    hasMore,
    fetchMore,
}: {
    messages: ChatMessage[];
    renderMessage({item: Message, index: number}): ReactNode;
    loading?: boolean;
    hasMore?: boolean;
    fetchMore?(): Promise<any>;
}) => {
    return (
        <ChatScroll
            loading={loading}
            messages={messages}
            hasMoreMessages={hasMore}
            fetchMore={fetchMore}
            blur={false}
        >
            {messages.map((item, index) => renderMessage({item, index}))}
        </ChatScroll>
    );
};

export const MessageList = memo(MessageListImpl);
