import React, {FC} from "react";
import {LinkProps} from "react-router-dom";
import styled from "styled-components";
import {ArrowLeft, ButtonLink} from "@unibuddy/patron";
import RelativeLink from "ubcommunity-shared/src/Navigation/RelativeLink/RelativeLink";

const StyledRelativeLink = styled(RelativeLink)`
    color: #424b5a;
`;

export const BackButton: FC<LinkProps> = (props) => {
    return (
        <ButtonLink as={StyledRelativeLink} iconOnly clear aria-label="Navigate back" {...props}>
            <ArrowLeft size={28} />
        </ButtonLink>
    );
};
